<form [formGroup]="folliclestudyform" (submit)="save_folliclestudy()">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-4"><label>Date</label><span style="color:red;">&nbsp;*</span></div>
            <div class="col-lg-5">
                <div class="input-group calendaralign" style="width: 190px;">
                    <input type="date" style="font-size: 14px;" onkeypress="return event.charCode == 8" class="form-control" [max]="gynecfolliclemaxDate" [(ngModel)]='follicledate' formControlName="follicledates" required />
                </div>
            </div>
            </div>
        </div>
    
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>Day</label></div>
            <div class="col-lg-5">
                <input type="text" maxlength="2" onkeypress="return event.keyCode >= 48 && event.keyCode <= 57 " [(ngModel)]='follicleday' formControlName="follicledays" class="form-control" >
            </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-4"><label>RT Ovary</label></div>
            <div class="col-lg-5">
                <input type="text" maxlength="30" class="form-control" [(ngModel)]='folliclertovary' formControlName="folliclertovarys"  style="width: 150px;">
            </div>
            </div>
        </div>
    
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>LT Ovary</label></div>
            <div class="col-lg-5">
                <input type="text" maxlength="30" class="form-control" [(ngModel)]='follicleltovary' formControlName="follicleltovarys" >
            </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-4"><label>Endometrium</label></div>
            <div class="col-lg-5">
                <input type="text" maxlength="30" class="form-control"  style="width: 150px;" [(ngModel)]='follicleendometrium' formControlName="follicleendometriums">
            </div>
            </div>
        </div>
    
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>Comments</label></div>
            <div class="col-lg-5">
                <textarea class="form-control" cols="30" rows="3" style="width: 260px;" maxlength="150" [(ngModel)]='folliclecomment' formControlName="folliclecomments"
                        placeholder="General Notes here"></textarea>
            </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container" style="border: none;">
        <div class="row" style="text-align: center;">
            <div class="col-lg-12">
                <button type="button" (click)="openpopupforexit()" class="btn btn-default">Cancel</button>&nbsp;
                &nbsp; &nbsp; &nbsp;
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</form>