<div class="row">
    <div class="col-md-12">
        <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
    </div>
    <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
        <table class="table table-bordered">
            <thead style="background-color: #adccff;">
                <tr>
                    <th class="text-center" style="width: 50px;">S.No</th>
                    <th class="text-center" style="width: 500px;">Date of Change</th>
                    <th class="text-center" style="width: 500px;">Risk changed from</th>
                    <th class="text-center" style="width: 500px;">Risk changed to</th>
                </tr>
            </thead>
            <tbody >
                <tr class="text-center" style="border: tomato;" *ngFor="let value of getarchivedcpRisk; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{value.date}}</td>
                    <td>{{value.risk_from}}</td>
                    <td>{{value.risk_to}}</td>
                </tr>
            </tbody>
        </table>
        <!-- <div class=" text-center"  style="color: red;"  ng-if="showpastriskdetails == false">No data found</div> -->
    </div>
</div>