<div class="contaniner">
    <div class="row titleContainer" style="padding-bottom: 1rem;">
        <div class="col-sm-3 col-md-3 col-lg-3 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true"
                        style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 containerTop">
            <p class="headText" style="margin-top: 0.5rem;">Growth</p>
        </div>

        <div class="col-sm-4 col-md-4 col-lg-4 containerTop">
            <button type="button" class="btn btn-outline-info1 ml-3" [class.filter_highlight]="!showTable"
                (click)="chartToggle(1)">CHARTS</button>

            <button type="button" class="btn btn-outline-info1 ml-3" [class.filter_highlight]="showTable"
                (click)="chartToggle(2)">DATA</button>

        </div>
        <div class="col-sm-1 col-md-1 col-lg-1 containerTop" style="float:right;display: flex;">
            <img matTooltip="share" src="assets/share-icon.svg" style="cursor: pointer;    width: 2rem;
            margin-right: 1rem;" (click)="shareCharts()" />
            <img matTooltip="Print" src="assets/print icon.svg" style="cursor: pointer;    width: 2rem;
            margin-right: 1rem;" (click)="printGraph('overlay1')" />
            <div style="display: flex;"><img style="cursor: pointer;" (click)="openForm()" src="assets/plus.svg"></div>
        </div>
    </div>

    <div style="margin-top: 60px;margin-top: 6rem;">

        <div style="margin-top: 1rem;" *ngIf=!showTable>
            <div style="display: flex;cursor: pointer;margin-left: 2rem;margin-bottom: 1rem;"
                *ngIf="this.recordAge.years>=5">
                <input type="radio" class="radio" name="age" [checked]="showType==1" (change)="setShowType(1)"
                    id="age1">
                <label for="age1" style="font-size: 1rem;">0-5 years</label>
                <input type="radio" style="margin-left:0.5rem" class="radio" [checked]="showType==2"
                    (change)="setShowType(2)" id="age2" name="age"> <label for="age2" style="font-size: 1rem;">5 - 18
                    years</label>
            </div>
                <!-- Defult Small charts view-->
            <div style="display: flex;" *ngIf="showChart&&showType==1&&record.gender=='male'">
                <div class="malebox" (click)="openDetail(1)">
                    <app-weight-for-height-length-male-small [chartData]="chartData">
                    </app-weight-for-height-length-male-small>
                </div>

                <div class="malebox"  (click)="openDetail(2)" style="margin-left: 20px;">
                    <app-wlhc-male-small [chartData]="chartData"></app-wlhc-male-small>
                </div>
            </div>


            <div style="display: flex;" *ngIf="showChart&&showType==2&&record.gender=='male'">
                <div class="malebox1"  (click)="openDetail(3)">
                    <app-combined-chart-male-small [chartData]="chartData"></app-combined-chart-male-small>
                </div>

                <div class="malebox1"  (click)="openDetail(4)" style="margin-left: 20px;">
                    <app-iap-bmi-chart-male-small [chartData]="chartData"></app-iap-bmi-chart-male-small>
                </div>
            </div>

            <div style="display: flex;" *ngIf="showChart&&showType==1&&record.gender=='female'">
                <div class="femalebox"  (click)="openDetail(5)">

                    <app-weight-for-height-length-female-small [chartData]="chartData">
                    </app-weight-for-height-length-female-small>
                </div>
                <div class="femalebox" (click)="openDetail(6)" style="margin-left: 20px;">

                    <app-wlhc-female-small [chartData]="chartData"></app-wlhc-female-small>
                </div>
            </div>

            <div style="display: flex;" *ngIf="showChart&&showType==2&&record.gender=='female'">
                <div class="femalebox1" (click)="openDetail(7)">
                    <app-combined-chart-female-small [chartData]="chartData"></app-combined-chart-female-small>
                </div>

                <div class="femalebox1" (click)="openDetail(8)" style="margin-left: 20px;">
                    <app-iap-bmi-chart-female-small [chartData]="chartData"></app-iap-bmi-chart-female-small>
                </div>
            </div>
        </div>

    <!--Print charts view-->
        <div id="overlay1" *ngIf="showType==1">
            <div id="text" class="row" *ngIf="growthPrint==true"
                style="height: 1400px;width: 1075px; page-break-after: always"
                [class.male-chart]="record.gender=='male'" [class.female-chart]="record.gender=='female'">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="position:absolute;margin-left:30%;margin-top:2%;text-align: center;font-size: 20px;">
                    <label *ngIf="record.gender=='male'">WHO Boys Weight for Height/Length
                        Charts</label>
                    <label *ngIf="record.gender=='female'">WHO Girls Weight for Height/Length Charts</label><br>
                    <label>(Z Scores are in Parenthesis)</label>

                </div><br><br>
                <div style="position:absolute;margin-left:6%;margin-top:5%;font-size:20px;width:100%;">
                    <div style="display:flex;margin-top: 10px;">
                        <label style="width:25%">Name : {{record.name_given}}</label>
                        <label style="width:25%">MR : {{record.mr_number}}</label>
                        <label style="width:25%">Gender : {{record.gender | titlecase}}</label>
                        <label style="width:25%">DOB : {{record.bday | date:'dd-MMM-yyyy'}}</label>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <label  style="width:25%">Age : {{years}}Y{{month}}M</label>
                        <label  style="width:25%">Height : {{heightdata}}</label>
                        <label  style="width:25%">weight : {{weightdata}}</label>
                        <label  style="width:25%">BMI / BSI : {{bmidata}} / {{bsidata}}</label>
                    </div>
                </div>
                <br><br>
                <app-weight-for-height-length-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;" *ngIf="record.gender=='male'"
                    class="chart"></app-weight-for-height-length-male-print>

                <app-weight-for-height-length-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;"
                    *ngIf="record.gender=='female'" class="chart"></app-weight-for-height-length-female-print>
            </div><br>
            <div id="text" class="row" *ngIf="growthPrint==true"
                style="height: 1400px;width: 1075px; page-break-after: always"
                ng-class="{'male-chart':record.gender=='male','female-chart':record.gender=='female'}">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="position:absolute;margin-left:20%;margin-top:2%;font-size:20px;text-align: center;">
                    <label *ngIf="record.gender=='male'">0 to 5 Years : WHO Boys Length/Height, Weight and
                        Head Circumference Charts</label>
                    <label *ngIf="record.gender=='female'">0 to 5 Years : WHO Girls Length/Height, Weight and
                        Head Circumference Charts</label><br>
                    <label>(Z Scores are in Parenthesis)</label>

                </div><br><br><br>
                <div style="position:absolute;margin-left:6%;margin-top:5%;font-size:20px;width:100%;">
                    <div style="display:flex;margin-top: 10px;">
                        <label style="width:25%">Name : {{record.name_given}}</label>
                        <label style="width:25%">MR : {{record.mr_number}}</label>
                        <label style="width:25%">Gender : {{record.gender | titlecase}}</label>
                        <label style="width:25%">DOB : {{record.bday | date:'dd-MMM-yyyy'}}</label>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <label  style="width:25%">Age : {{years}}Y{{month}}M</label>
                        <label  style="width:25%">Height : {{heightdata}}</label>
                        <label  style="width:25%">weight : {{weightdata}}</label>
                        <label  style="width:25%">BMI / BSI : {{bmidata}} / {{bsidata}}</label>
                    </div>
                </div>
                <br><br>
                <app-wlhc-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;" *ngIf="record.gender=='male'"
                    class="chart">
                </app-wlhc-male-print>

                <app-wlhc-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;"
                    *ngIf="record.gender=='female'" class="chart">
                </app-wlhc-female-print>

            </div>
        </div>

        <div id="overlay1" *ngIf="showType==2">
            <div id="text" class="row" *ngIf="growthPrint==true"
                style="height: 1400px;width: 1075px; page-break-after: always"
                [class.male-chart]="record.gender=='male'" [class.female-chart]="record.gender=='female'">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="position:absolute;margin-left:6%;margin-top:2%;font-size: 20px;">
                    <label *ngIf="record.gender=='male'">5 to 18 Years : IAP Boys Height and Weight Charts</label>
                    <label *ngIf="record.gender=='female'">5 to 18 Years : IAP Girls Height and Weight Charts</label><br>
                </div>
                <div style="position:absolute;margin-left:6%;margin-top:6%;font-size:20px;width:100%">
                    <div style="display:flex;margin-top: 10px;">
                        <label style="width:25%">Name : {{record.name_given}}</label>
                        <label style="width:25%">MR : {{record.mr_number}}</label>
                        <label style="width:25%">Gender : {{record.gender | titlecase}}</label>
                        <label style="width:25%">DOB : {{record.bday | date:'dd-MMM-yyyy'}}</label>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <label  style="width:25%">Age : {{years}}Y{{month}}M</label>
                        <label  style="width:25%">Height : {{heightdata}}</label>
                        <label  style="width:25%">weight : {{weightdata}}</label>
                        <label  style="width:25%">BMI / BSI : {{bmidata}} / {{bsidata}}</label>
                    </div>
                    <!-- <div style="margin-top: 3%;">
                        <label>Father's Height ______________, Mother's Height ______________, Target Height ______________</label>
                    </div> -->
                </div>
                <br><br><br><br><br><br>

                <app-combined-chart-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;" *ngIf="record.gender=='male'"
                    class="chart"></app-combined-chart-male-print>

                <app-combined-chart-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;"
                    *ngIf="record.gender=='female'" class="chart"></app-combined-chart-female-print>
            </div><br>
            <div id="text" class="row" *ngIf="growthPrint==true"
                style="height: 1400px;width: 1075px; page-break-after: always"
                ng-class="{'male-chart':record.gender=='male','female-chart':record.gender=='female'}">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="position:absolute;margin-left:6%;margin-top:2%;font-size: 20px;width:100%">
                    <label *ngIf="record.gender=='male'">5 to 18 Years : IAP Boys Body Mass Index Charts</label>
                    <label *ngIf="record.gender=='female'">5 to 18 Years : IAP Girls Body Mass Index Charts</label><br>
                    <br><br>
                    <div style="display:flex;">
                        <label style="width:25%">Name : {{record.name_given}}</label>
                        <label style="width:25%">MR : {{record.mr_number}}</label>
                        <label style="width:25%">Gender : {{record.gender | titlecase}}</label>
                        <label style="width:25%">DOB : {{record.bday | date:'dd-MMM-yyyy'}}</label>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <label  style="width:25%">Age : {{years}}Y{{month}}M</label>
                        <label  style="width:25%">Height : {{heightdata}}</label>
                        <label  style="width:25%">weight : {{weightdata}}</label>
                        <label  style="width:25%">BMI / BSI : {{bmidata}} / {{bsidata}}</label>
                    </div>
                   
                </div><br><br><br><br><br>
             

                <app-iap-bmi-chart-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;" *ngIf="record.gender=='male'"
                    class="chart"></app-iap-bmi-chart-male-print>

                <app-iap-bmi-chart-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 12%;"
                    *ngIf="record.gender=='female'" class="chart"></app-iap-bmi-chart-female-print>

            </div>
        </div>

        <!--Share Charts View 0 to 5 years -->
        <div id="takesnap_0to5" style="display:none;">
          <div style="display: flex;height: 1200px;width:1200px">
            <div style="width: 580px;"
                [class.male-chart]="record.gender=='male'" [class.female-chart]="record.gender=='female'">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="margin-top:2%;text-align: center;font-size: 20px;">
                    <label *ngIf="record.gender=='male'">WHO Boys Weight for Height/Length
                        Charts</label>
                    <label *ngIf="record.gender=='female'">WHO Girls Weight for Height/Length Charts</label><br>
                    <label>(Z Scores are in Parenthesis)</label>

                </div><br><br>
                <app-weight-for-height-length-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;" *ngIf="record.gender=='male'"
                    class="chart"></app-weight-for-height-length-male-print>

                <app-weight-for-height-length-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;"
                    *ngIf="record.gender=='female'" class="chart"></app-weight-for-height-length-female-print>
            </div>
            <div style="width: 580px;margin-left: 10%;"
                ng-class="{'malechart':record.gender=='male','femalechart':record.gender=='female'}">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="margin-top:2%;margin-left:5%;font-size:20px;text-align: center;">
                    <label *ngIf="record.gender=='male'">0 to 5 Years : WHO Boys Length/Height, Weight and
                        Head Circumference Charts</label>
                    <label *ngIf="record.gender=='female'">0 to 5 Years : WHO Girls Length/Height, Weight and
                        Head Circumference Charts</label><br>
                    <label>(Z Scores are in Parenthesis)</label>

                </div><br>
                
                <app-wlhc-male-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;" *ngIf="record.gender=='male'"
                    class="chart">
                </app-wlhc-male-print>

                <app-wlhc-female-print [chartData]="chartData"
                    style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;"
                    *ngIf="record.gender=='female'" class="chart">
                </app-wlhc-female-print>

            </div>
          </div>
        </div>

        <!--Share Charts View 5 to 18 years -->
        <div id="takesnap__5to18" style="display:none;">
            <div style="display: flex;height: 1200px;width:1200px">
              <div style="width: 580px;"
                  [class.male-chart]="record.gender=='male'" [class.female-chart]="record.gender=='female'">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div style="margin-top:2%;text-align: center;font-size: 20px;">
                    <label *ngIf="record.gender=='male'">5 to 18 Years : IAP Boys Height and Weight Charts</label>
                    <label *ngIf="record.gender=='female'">5 to 18 Years : IAP Girls Height and Weight Charts</label>
                  </div><br><br>
                  <app-combined-chart-male-print [chartData]="chartData"
                      style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;" *ngIf="record.gender=='male'"
                      class="chart"></app-combined-chart-male-print>
  
                  <app-combined-chart-female-print [chartData]="chartData"
                      style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;"
                      *ngIf="record.gender=='female'" class="chart"></app-combined-chart-female-print>
              </div>
              <div style="width: 580px;margin-left: 10%;"
                  ng-class="{'malechart':record.gender=='male','femalechart':record.gender=='female'}">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div style="margin-top:2%;margin-left:5%;font-size:20px;text-align: center;">
                    <label *ngIf="record.gender=='male'">5 to 18 Years : IAP Boys Body Mass Index Charts</label>
                    <label *ngIf="record.gender=='female'">5 to 18 Years : IAP Girls Body Mass Index Charts</label>
                 </div><br><br>
                  
                  <app-iap-bmi-chart-male-print [chartData]="chartData"
                      style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;" *ngIf="record.gender=='male'"
                      class="chart">
                  </app-iap-bmi-chart-male-print>
  
                  <app-iap-bmi-chart-female-print [chartData]="chartData"
                      style="height: 1150px; width: 920px;margin-left: 6%;margin-top: 10%;"
                      *ngIf="record.gender=='female'" class="chart">
                  </app-iap-bmi-chart-female-print>
  
              </div>
            </div>
        </div>

    <!--Table view-->

        <div *ngIf="showTable">
            <table class="table" *ngIf="vitalsDataResponse.length>0" style="margin-top: 1rem;border-top:1px solid ;">
                <tbody>

                    <tr style="background-color: #E5F0FF;text-align: center;">
                        <td>S.No</td>
                        <td>Entry Date</td>
                        <td>Age</td>
                        <td>Weight (kg)</td>
                        <td>Height (cm)</td>
                        <td>BMI (kg/m2)</td>
                        <td>Head (cm)</td>
                        <td>Chest (cm)</td>
                        <td>Arm (cm)</td>

                    </tr>

                    <tr style="text-align: center;" *ngFor="let v of vitalinterpolData;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{v.date | date:'dd MMM yyyy'}}</td>
                        <td>{{v.age}}</td>
                        <!-- <td>{{v.y}} years, {{v.m}} months</td> -->
                       
                        <td>{{v.weight}} &nbsp;
                            <span *ngIf="v.weight_interpretation=='over weight'" style="color:red"><i class="fa fa-arrow-up" aria-hidden="true" style="color:red"></i> {{v.weight_interpretation}}</span>
                            <span *ngIf="v.weight_interpretation=='low weight'" style="color:orange"><i class="fa fa-arrow-down" aria-hidden="true" style="color:orange"></i> {{v.weight_interpretation}}</span>
                            <span *ngIf="v.weight_interpretation=='normal weight'" style="color:green"><i class="fa fa-check" aria-hidden="true" style="color:green"></i> {{v.weight_interpretation}}</span>
                        </td>
                        <td>{{v.height}} &nbsp;
                            <span *ngIf="v.height_interpretation=='over height'" style="color:red"><i class="fa fa-arrow-up" aria-hidden="true" style="color:red"></i> {{v.height_interpretation}}</span>
                            <span *ngIf="v.height_interpretation=='low height'" style="color:orange"><i class="fa fa-arrow-down" aria-hidden="true" style="color:orange"></i> {{v.height_interpretation}}</span>
                            <span *ngIf="v.height_interpretation=='normal height'" style="color:green"><i class="fa fa-check" aria-hidden="true" style="color:green"></i> {{v.height_interpretation}}</span>
                        </td>
                       
                        <td *ngIf="v.bmi =='nan'"> </td>
                        <td *ngIf="v.bmi !='nan'">{{v.bmi}}</td>
                       
                        <td>{{v.head_circumference}} &nbsp;
                            <span *ngIf="v.head_circumference_interpretation=='over hc'" style="color:red"><i class="fa fa-arrow-up" aria-hidden="true" style="color:red"></i> {{v.head_circumference_interpretation}}</span>
                            <span *ngIf="v.head_circumference_interpretation=='low hc'" style="color:orange"><i class="fa fa-arrow-down" aria-hidden="true" style="color:orange"></i> {{v.head_circumference_interpretation}}</span>
                            <span *ngIf="v.head_circumference_interpretation=='normal hc'" style="color:green"><i class="fa fa-check" aria-hidden="true" style="color:green"></i> {{v.head_circumference_interpretation}}</span>
                        </td>
                        <td>{{v.chest_circumference}}</td>
                        <td>{{v.arm_circumference}}</td>

                    </tr>

                </tbody>
            </table>
        </div>

    </div>
    
    <div id="pause" *ngIf="loader" class="d-flex align-items-center justify-content-center">
        <div id="spinner"></div>
    </div>
</div>
