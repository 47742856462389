<div class="container-width">
    <div class="page">
        <div class="marvel-device nexus5">
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="volume"></div>
          <div class="camera"></div>
          <div class="screen">
            <div class="screen-container">
              <!-- <div class="status-bar">
                <div class="time"></div>
                <div class="battery">
                  <i class="zmdi zmdi-battery"></i>
                </div>
                <div class="network">
                  <i class="zmdi zmdi-network"></i>
                </div>
                <div class="wifi">
                  <i class="zmdi zmdi-wifi-alt-2"></i>
                </div>
                <div class="star">
                  <i class="zmdi zmdi-star"></i>
                </div>
              </div> -->
              <div class="chat">
                <div class="chat-container">
                  <div class="user-bar">
                    <!-- <div class="back">
                        <div><mat-icon (click)="goback()" style="cursor:pointer">arrow_back</mat-icon> </div>
                    </div> -->
                    <div class="avatar">
                        <span> 
                            <img *ngIf="selectedpatientImage == null || selectedpatientImage == '' || selectedpatientImage == 'None'" src="assets/default-avatar-.jpg" class="profileavatar"> 
                            <img *ngIf="selectedpatientImage != null && selectedpatientImage != '' && selectedpatientImage != 'None'" src="{{imageUploadUrl}}{{selectedpatientImage}}" class="profileavatar"> 
                        </span>
                    </div>
                    <div class="name">
                        <span class="panel-title" style="font-weight: 600;font-size: 18px;">{{selectedPatientfname}}</span>
                        <!-- <div style="margin-left:85px;margin-top: -15px;">{{selectedPatientGender | titlecase}} / {{selectedPatientMrNum}}<br>
                            {{selectedPatientphone}} 
                        </div> -->
                        <span class="status">
                            <span>{{selectedPatientphone}}</span> <span> - {{selectedPatientGender | titlecase}} / {{selectedPatientMrNum}}</span>
                        </span>
                    </div>
                    <div class="actions more">
                      <i class="zmdi zmdi-more-vert"></i>
                    </div>
                    <div class="actions attachment">
                      <i class="zmdi zmdi-attachment-alt"></i>
                    </div>
                    <div class="actions">
                      <i class="zmdi zmdi-phone"></i>
                    </div>
                  </div>
                  <div class="conversation">
                    <div class="conversation-container" #scrollMe>
                        <div *ngFor="let message of chathistoryList">
                            <div *ngIf="message.imagemessage" class="message" [ngClass]="uuid==message.sender ? 'sent' : 'received'">
                                <div *ngFor="let image of message.imagemessage">
                                    <img src="{{image}}" style="height: 120px; width: 100px;cursor:pointer" (click)="openImg(image,1)" />
                                    <span class="metadata">
                                        <span class="time">{{message.messaged_date}}</span><br/>
                                        <span class="time"><b>- {{message.doctor_name}}</b></span>
                                        &nbsp;<span *ngIf="!message.payment && uuid==message.sender"><i data-toggle="tooltip" data-placement="top" title="Payment exempted by doctor" class="fa fa-times-circle"  style="color:#f08782;"></i> </span>
                                        &nbsp;<span *ngIf="message.payment && uuid==message.sender"> <i data-toggle="tooltip" data-placement="top" title="Payment charged" class="fa fa-check-circle" style="color:#9ccfa6;"></i></span>
                                        &nbsp;<span *ngIf="uuid==message.sender&&message.payment&&refundOption"><div  style="margin-top:-20px"><mat-icon style="float:right;cursor:pointer" (click)="refund(message)">compare_arrows</mat-icon></div></span>
                                    </span>
                                </div>        
                            </div>

                            <div *ngIf="message.videomessage" class="message" [ngClass]="uuid==message.sender ? 'sent' : 'received'">
                                <div *ngFor="let video of message.videomessage">
                                    <img src="assets/video-play.png"  (click)="openImg(video,0)" style="height:80px; width: 80px;cursor: pointer;" />
                                    <span class="metadata">
                                        <span class="time">{{message.messaged_date}}</span><br/>
                                        <span class="time"><b>- {{message.doctor_name}}</b></span>
                                        &nbsp;<span *ngIf="!message.payment && uuid==message.sender"><i data-toggle="tooltip" data-placement="top" title="Payment exempted by doctor" class="fa fa-times-circle"  style="color:#f08782;"></i> </span>
                                        &nbsp;<span *ngIf="message.payment && uuid==message.sender"> <i data-toggle="tooltip" data-placement="top" title="Payment charged" class="fa fa-check-circle" style="color:#9ccfa6;"></i></span>
                                        &nbsp;<span *ngIf="uuid==message.sender&&message.payment&&refundOption"><div  style="margin-top:-20px"><mat-icon style="float:right;cursor:pointer" (click)="refund(message)">compare_arrows</mat-icon></div></span>
                                    </span>
                                </div>        
                            </div>
                            
                            <div class="message" [ngClass]="uuid==message.sender ? 'sent' : 'received'" *ngIf="message.audiomessage"><i class="fa fa-file-audio-o fa-3x" aria-hidden="true"></i> Patient has sent you an voice mail</div> 
                            
                            <div class="message" [ngClass]="uuid==message.sender ? 'sent' : 'received'" *ngIf="!message.imagemessage && !message.videomessage && !message.audiomessage">{{message.message}}
                                <span class="metadata">
                                    <span class="time">{{message.messaged_date}}</span><br/>
                                    <span class="time"><b>- {{message.doctor_name}}</b></span>
                                    &nbsp;<span *ngIf="!message.payment && uuid==message.sender"><i data-toggle="tooltip" data-placement="top" title="Payment exempted by doctor" class="fa fa-times-circle"  style="color:#f08782;"></i> </span>
                                    &nbsp;<span *ngIf="message.payment && uuid==message.sender"> <i data-toggle="tooltip" data-placement="top" title="Payment charged" class="fa fa-check-circle" style="color:#9ccfa6;"></i></span>
                                    <span *ngIf="uuid==message.sender&&message.payment&&refundOption"><div style="margin-top:-20px"><mat-icon style="float:right;cursor:pointer" (click)="refund(message)">compare_arrows</mat-icon></div>
                                  </span>
                                  </span>
                            </div>
                           
                        </div>
                        
                      <!-- <div class="message sent">
                        What happened last night?
                        <span class="metadata">
                            <span class="time"></span><span class="tick"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                        </span>
                      </div>
                      <div class="message received">
                        You were drunk.
                        <span class="metadata"><span class="time"></span></span>
                      </div>
                      <div class="message sent">
                        No I wasn't.
                        <span class="metadata">
                            <span class="time"></span><span class="tick"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                        </span>
                      </div>
                      <div class="message received">
                        <span id="random">You were hugging an old man with a beard screaming "DUMBLEDORE YOU'RE ALIVE!"</span>
                        <span class="metadata"><span class="time"></span></span>
                      </div> -->
                    </div>
                    <div class="progress" *ngIf="progressStatus" style="margin-left:5px;margin-right:5px">
                      <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                    </div>
                    <form class="conversation-compose">
                      <div class="emoji">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley" x="3147" y="3209"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489"/></svg> -->
                      </div>
                      <input class="input-msg" name="input" placeholder="Type a message"  (keypress)="keyPress($event)" [(ngModel)]="messageContent.message" autocomplete="off" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="160"/>
                      <div class="photo">
                        <input type="file" name="cropimage" id="fileone" class="inputfile" accept="image/*" (change)="uploadAttachment($event);" />
                        <label for="fileone"><div><mat-icon>attach_file</mat-icon></div></label>
                      </div>
                      <button class="send">
                          <div class="circle">
                            <label style="margin-top:12px;margin-left: 5px;"><mat-icon style="cursor:pointer;" (click)="sendMessage()">send</mat-icon></label>
                          </div>
                        </button>
                     </form>
                     <div style="padding-bottom: 5px;">
                      <!-- &nbsp;&nbsp;{{160 - messageContent.message.length}} characters remaining -->
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
