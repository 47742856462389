import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';

import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent
} from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
declare let alasql;


@Component({
  selector: 'app-walletTransaction',
  templateUrl: './walletTransaction.component.html',
  styleUrls: ['./walletTransaction.component.scss']
})
export class WallettransactionComponent implements OnInit {
  
  @Input() headerTitle: string;
  paymentCategoryForm: FormGroup;
  
  params = {};
  getWalletList:any;
  getWalletTransList:any;

  dmail;
  doc_id ;
  token;
  drId: number;
  dr_name:any;
  hos_name: any;
  private sub: any;

  todayDate:any;
  startdateInFormat = '-';
  enddateInFormat = '-';
  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  
  
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private appService: AppService,private http: HttpClient,private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.hos_name = getauthdetails.hospital_name;
    this.dr_name = getauthdetails.doctor_name;
    this.token = getauthdetails.token;
    
    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    let sendDate = momDate.format('YYYY-MM-DD')

    this. getWalletReport(sendDate,sendDate);
  }

  getWalletReport(filterstartdate,filterenddate){ 

   this.getWalletList = '';
   this.getWalletTransList = '';
  

    this.params = {
      "account_email": this.dmail,
      "from_date": filterstartdate,
      "to_date": filterenddate
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.walletTransReport, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        if(response.users[0].success == 0){
          this.getWalletList = [];
        }else{
          this.getWalletList = response.users[0].total;
          this.getWalletTransList = response.users[0].transaction;
        }
      });
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
    //console.log('[rangeClicked] range is : ', range);
  }

  datesUpdated(range) {
   
   const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
   
   let sendstartDate = startDate.format('YYYY-MM-DD');
   let sendendDate = endDate.format('YYYY-MM-DD');
   this. getWalletReport(sendstartDate,sendendDate);

  }

  downloadPDF(){
     var element = document.getElementById('mytable');
//   var opt = {
//     margin: 0.1,
//     filename: 'WalletTransaction.pdf',
//     image: { type: 'jpeg', quality: 0.98 },
//     html2canvas: { scale: 1 },
//     jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
//    
// };

var opt = {
  margin:       0.6,
  filename:     'WalletTransaction_Statement.pdf',
  enableLinks:  false,
  pagebreak:    { mode: 'avoid-all' },
  image:        { type: 'jpeg', quality: 0.98 },
  html2canvas:  { scale: 1 },
  jsPDF:        { unit: 'in', format: 'a4', orientation: 'landscape' }
};

html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
  var totalPages = pdf.internal.getNumberOfPages(); 
  //print current pdf width & height to console
 
  
  for (var i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.setTextColor(150);
    
    if(i==totalPages){
      pdf.text('----- End of Report/Statement ----- ', pdf.internal.pageSize.getWidth()/2.5, 
      pdf.internal.pageSize.getHeight()-0.8);
    }
    //divided by 2 to go center
    pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth()/2.2, 
    pdf.internal.pageSize.getHeight()-0.5);
    
  } 
  }).save();
  // New Promise-based usage:
  //html2pdf().from(element).set(opt).save();
  }

  

  downloadCSV(){
    let filename = 'WalletTransaction'
    alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#mytable',{headers:true,separator:','})");
  };

  downloadXLS(){
    var blob = new Blob([document.getElementById('mytable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "WalletTransaction.xls");
  }

}
