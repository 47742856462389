import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  urlPoints: any;
  params;
  vaccineparams;
  vitalparams;
  printData: string[] = [];
  vitalData;
  vaccineData;
  vaccionDetails: string[] = [];
  getaccEmail;
  receiptparams;
  receiptData;
  lmpedd;
  currentPrequency;
  follicleData;
  riskdata;
  smsparam;
  sendpresurl;
 drID;
  
  constructor(private appService: AppService, private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  printPresDocument(documentPath: string, documentemail: string, documentoverviewId: string, documentdoctId: string,
    mrNum:any, vitDate: any, vaccMsg: any, VaccDate: any, authToken: any, appointID: any, follicurStatus: any, 
    riskStatus: any, patientEmail: any) {
    
    this.isPrinting = true;
    this.vaccionDetails.push(vaccMsg,VaccDate);
    this.getaccEmail = documentemail;
    this.drID = documentdoctId;

    this.smsparam = {
      account_email: documentemail,
      overview_id: documentoverviewId,
      doctor_id: documentdoctId,
      patient_email: patientEmail,
      follicular: follicurStatus,
      risk: riskStatus
    }

    this.params = {
      account_email: documentemail,
      overview_id: documentoverviewId,
      doctor_id: documentdoctId,
      follicular: follicurStatus,
      risk: riskStatus
    }

    this.vaccineparams = {
      account_email: documentemail,
      appt_id: appointID,
    }

    this.vitalparams = {
      account_email: documentemail,
      mr_number: mrNum,
      date: vitDate
    }
    
    this.appService.getData(this.urlPoints.vaccineList, this.vaccineparams, authToken).subscribe((response: any) => {
      this.vaccineData = response.data;
    })

    this.appService.getData(this.urlPoints.vitalList, this.vitalparams, authToken).subscribe((response: any) => {
      this.vitalData = response;
    })

    if(documentemail == 'rx15@rxcarenet.org' || documentemail == 'rx122340@rxcarenet.org' ) {
        this.sendpresurl = this.urlPoints.pressendmangal;
    }else { 
      this.sendpresurl = this.urlPoints.pressendmail;
    }


    this.appService.getData(this.sendpresurl, this.smsparam, authToken).subscribe((response: any) => {
    })

     this.appService.getData(this.urlPoints.presprint, this.params, authToken).subscribe((response: any) => {
      
      let getprintData = response.detailed_prescription;
      this.printData.push(getprintData);
      this.lmpedd = response.lmpedd;
      this.currentPrequency = response.current_pregnancy_data;
      this.follicleData = response.follicle_data;
      this.riskdata = response.risk_data;
      
      if((documentemail == 'rx4@rxcarenet.org' && documentdoctId == '3') || (documentemail == 'rx136720@rxcarenet.org' && documentdoctId == '1') ) {
        setTimeout(() => {
          this.router.navigate(['/print/obsGynpres']);
        },300);
        }
        else if((documentemail == 'rx4@rxcarenet.org' && documentdoctId == '1') || (documentemail == 'rx4@rxcarenet.org' && documentdoctId == '7') || 
                (documentemail == 'rx120049@rxcarenet.org' && documentdoctId == '1') || (documentemail == 'rx120049@rxcarenet.org' && documentdoctId == '2') ) {
          setTimeout(() => {
            this.router.navigate(['/print/vedhaprescription']);
          },300);
          }
        else{
          setTimeout(() => {
            this.router.navigate(['/print/prescription']);
          },300);
        
      }
    })
    
}

 printReceiptDocument(documentPath: string, documentemail: string,patientemail: string,billnum: string, authToken: any, douctorId: any, ) {

  this.receiptparams = {
    account_email: documentemail,
    patient_email: patientemail,
    bill_no: billnum,
    doctor_id: douctorId,
    status: 0
  }

  this.appService.getData(this.urlPoints.receipt, this.receiptparams, authToken).subscribe((response: any) => {
    this.receiptData = response.data;
    setTimeout(() => {
      this.router.navigate(['/print/cashReceipt']);
    },300);
    //this.router.navigate(['/print/cashReceipt'])
  })
  
}

getaccountEmail() { 
  return this.getaccEmail;
 }
 getdoctorID() { 
  return this.drID;
  ;
 }

getvitalPrintData() { 
 return this.vitalData;
}

getTodayVaccineData(){ 
  return this.vaccineData;
 }

getvaccionPrintData() { 
  return this.vaccionDetails;
 }

 getreceiptData() { 
 return this.receiptData;
 }

getPrintData() { 
  return this.printData;
}

getlmpeddData() { 
  return this.lmpedd;
}

getfollicleData() { 
  return this.follicleData;
}

getriskData() { 
  return this.riskdata;
}

getprequencyData() { 
  return this.currentPrequency;
}

  onDataReady(filefirstName,filesecondName) {
    setTimeout(() => {
      var tempTitle = document.title;
      document.title = filefirstName+"_"+filesecondName+".pdf";
      window.print();
      document.title = filefirstName+"_"+filesecondName+".pdf";
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    });
  }
}
