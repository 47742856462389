<div class="d-flex" id="wrapper">
    <!-- Left Sidebar -->
    
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
          <h2 style="margin-top:10px">Vitall</h2>
          
        </div>
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
                <img style="height: 30px;cursor: pointer;" title="Appointments" src="assets/appointment active.svg" (click)="goToAppointment();">
            </li>
        </ul>
      </nav>
        <!-- /#footer -->
    </div>
  </div>

  <div class="bodycontainer">
    <div *ngIf="loader" style="text-align: center;margin-top: 14%;">
      <img src="assets/loader.gif" style="height:120px">
    </div>
    <div *ngIf="!loader">
      <iframe *ngIf="getVitallAccess_status==1 || multiaccesscall_status" [src]="vitallurl" style="width: 100%; height: 90vh;border:0px solid" frameborder='0'  allow="camera;microphone"></iframe>
      <div *ngIf="getVitallAccess_status==3 && !multiaccesscall_status" class="row passBox">
        <div class="col-lg-5 col-md-5">
          <img src="assets/Mail_Image.jpg">
        </div>
        <div class="col-lg-7 col-md-7" style="text-align: center;margin-top: 12%;">
          <p style="font-weight: 600;font-size: 1rem;">Choose Access Code</p>
          <p style="margin-top: 20px;"> 
              <mat-radio-group 
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      [color]="'primary'">
                      <div *ngFor="let multiaccess of getVitallMultiAccess;let i= index;" style="margin-top: 10px;">
                        <mat-radio-button  class="example-radio-button" (click)="checkpass(multiaccess.code)" [value]="i">{{multiaccess.code}}</mat-radio-button>
                      </div>
                </mat-radio-group><br/>
                <button *ngIf="passChooseStatus" class="btn btn-primary customButton" (click)="procced()" style="color:#ffff;float:right;margin-right: 150px;box-shadow: 2px 3px 2px 0px grey;"> Procced</button>
          </p>
        </div>
        
      </div>
    </div>
 </div>


