<form [formGroup]="scanreportfindings" (submit)="save_scanreportfindingdata()">
    <div class="row">
        <div class="col-lg-3"><label>Scan Report Findings</label></div>
            <div class="col-lg-5">
                <textarea class="form-control" name="" cols="30" rows="3" style="width: 285px;" maxlength="250"
                        placeholder="Scan Report Findings" [(ngModel)]='scanreportnote' formControlName="scanreportnotes"></textarea>
            </div>
    </div>
<br>
    <div class="row">
        <div class="col-lg-3"><label>Scanned On</label></div>
            <div class="col-lg-3">
                <div class="input-group calendaralign">
                    <input type="date" onkeypress="return event.charCode == 8" class="form-control" [(ngModel)]='scanondate' formControlName="scanondates"/>
                </div>
            </div>
    </div>
<br>
    <div class="row">
        <div class="col-lg-3"><label>Next Scan Date</label></div>
            <div class="col-lg-3">
                <div class="input-group calendaralign">
                    <input type="date" onkeypress="return event.charCode == 8" class="form-control" [min]="obsnextscanminDate"  [(ngModel)]='nextscandate' formControlName="nextscandates"/>
                </div>
            </div>
    </div>

<br>
   
        <div class="row" style="text-align: center;">
            <div class="col-lg-12">
                <button type="button" (click)="openpopupforexit()" class="btn btn-default">Cancel</button> &nbsp; &nbsp; &nbsp; &nbsp;
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </div>
        <br>
</form>