<div style="display: flex;flex-direction: row;justify-content: space-between;">
    <p style="font-size: 1rem;font-weight: 600;color: #0063FF;">Patient Info</p>
    <img (click)="close()" style="width: 1rem;cursor: pointer;" src="assets/cancel.svg">
</div>

<div style="display: flex;justify-content: center;">
    <img class="dp"
        style="border-radius: 50%;border: 2px solid #0063FF;padding: 3px 3px 3px 3px;width:5rem;height:5rem"
        [src]="data.url">
</div>

<div>
    <p style="font-size: 1rem;font-weight: 600;text-align: center;margin-top: 0.5rem;">{{data.profile.name_given}}</p>

    <p style="font-size: 0.8rem;text-align: center;margin-top: 0.5rem; color: #0063FF;font-weight: 600;">{{data.profile.mr_number}} | {{y}}Y{{m}}M, {{data.profile.gender}} | {{data.profile.father_tel_number}}</p>

</div>

<div>
    <table style="width: 100%;">
        <tr>
            <td style="font-weight: 600;">Arrival time</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">{{arrival || "N/A"}}</td>
        </tr>

        <tr>
            <td style="font-weight: 600;">Last visited</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">{{last_visit || "N/A"}}</td>
        </tr>

        <tr>
            <td style="font-weight: 600;">Last mobile login</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">{{last_login || "N/A"}}</td>
        </tr>

        <tr>
            <td style="font-weight: 600;">Last vaccination name</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">{{last_vaccine || "N/A"}}</td>
        </tr>

        <tr st>
            <td style="font-weight: 600;">Last vaccination date</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">{{last_vaccine_date  | date:'dd-MMM-yyyy' || "N/A"}}</td>
        </tr>

        <tr>
            <td style="font-weight: 600;cursor: pointer;" (click)="openVacc()" (click)="picker.close()">Next vaccination name</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;cursor: pointer;" (click)="openVacc()" (click)="picker.close()">{{next_vaccine || "N/A"}}</td>
        </tr>

        <tr>
            <td style="font-weight: 600;">Next vaccination date</td>
            <td style="color: #0063FF;"> : </td>
            <td style="color: #0063FF;">
                <img style="height: 1rem;width: 1rem;margin-right: 0.5rem;cursor: pointer;" *ngIf="next_vaccine_date"  (click)="picker.open()"  src="assets/metro-calendar.svg">
                {{next_vaccine_date  | date:'dd-MMM-yyyy' || "N/A"}}
        

                  <mat-form-field style="width:0px !important;visibility:hidden;">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="next_vaccine_date"  [matDatepickerFilter]="weekendsDatesFilter" (dateChange)="onDateChange($event)" [min]="minDate">
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  
            </td>
        </tr>
    </table>
</div>