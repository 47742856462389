<div class="row" style="width: 100%;margin: 0;margin-bottom: 1rem;">
    <div style="display: flex;flex-direction: row;overflow: auto;">
        <!-- <img class="mute icon_menu" [class.shrink]="!full" style="margin-left: 0.5rem;" [class.disabled]="!started" (click)="started?toggle_key():false" data-toggle="tooltip" data-placement="bottom" title="On screen keyboard"
            src="assets/keyboard.svg"> -->
        <div style="margin-left: 0.5rem;">
            <div style="display: flex;justify-content: center;">
                <img class="mute icon_menu" [class.shrink]="!full" style="margin-left: 0.5rem;" [class.disabled]="!started" src="assets/sticky notes.svg" (click)="doctorNotes()" data-toggle="tooltip" data-placement="bottom" title="Doctor Notes">

            </div>
            <p style="font-size: 0.75rem;">Doctor Notes</p>
        </div>

        <div style="margin-left: 0.5rem;">
            <div style="display: flex;justify-content: center;">
                <img class="mute icon_menu" [class.shrink]="!full" style="margin-left: 0.5rem;" [class.disabled]="!started" src="assets/significant labs.svg" (click)="labNotes()"  data-toggle="tooltip" data-placement="bottom" title="Lab Notes">

            </div>
            <p style="font-size: 0.75rem;">Lab Notes</p>
        </div>


        <!-- <img class="mute icon_menu" [class.shrink]="!full" style="margin-left: 0.5rem;"  *ngIf="supported" [src]="muteButton" data-toggle="tooltip" data-placement="bottom" title="Mute / Unmute" (mousedown)="mousedown()" (mouseup)="mouseup()" (mouseleave)="mouseup()"> -->
    </div>

    <!-- <div>
        <div style="display: flex;justify-content: center;" class="inputtop" id="inputtop">
            <input [(ngModel)]="text"  (keydown.enter)="enter()" style="max-width: 100%;height: 1.5rem;margin-left: 0.5rem;" [class.input1]="!full" (click)="inputClick()" (input)="input($event)">
        </div>
    </div> -->
</div>