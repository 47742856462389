import { Component, OnInit } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = '';
  isConnectinterent:boolean;
  checkConnectionStatus:boolean;
  checkConnection:string = '';
  
  constructor(private appService: AppService){

    this.appService.getDicom().subscribe((response: any) => {
      console.log(response)
    });
    
    this.createOnline$().subscribe(isOnline => { 
    this.isConnectinterent = isOnline;
    console.log(this.isConnectinterent);
     //checking internet connection
      if(this.isConnectinterent)
      { //if internet is working
        this.checkConnection = 'Internet is connected';
        console.log("*******"+this.checkConnection);
        this.checkConnectionStatus = false;
      }
      else{ //if internet not working
      this.checkConnection = 'Currently Offline. Internet Connection is required.';
      console.log("############"+this.checkConnection);
      this.checkConnectionStatus = true;

     
    }
  });
  }
  ngOnInit(): void {
    this.appService.startTrackingInactivity();
    sessionStorage.setItem('consultation','false')
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
      }));
    }    
}
