<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div [ngStyle]="mode?{'visibility': 'hidden'} : {'visibility': 'visible'}" class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider1">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;"
            [class.oral]="slider_value==0" [class.inhale]="slider_value==45"
            [class.subcutaneous]="slider_value==90" [class.intravenous]="slider_value==135"
            [class.perrectal]="slider_value==180" [class.topical]="slider_value==225"
            [class.pervaginal]="slider_value==270" [class.other]="slider_value==315"

                [src]="imgSrc">
        </div>
    
        <div class='container container_'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(45)">
                <div style="display: flex;justify-content: center;"> <img [src]="slider_value==45?'assets/route2.svg':' assets/inhalator.svg'">
                </div>
                <span [class.bold]="slider_value==45">Inhale</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(90)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==90?'assets/route3.svg':' assets/intramascular_&_subcutaneous.svg'">
                </div>
    
                <span [class.bold]="slider_value==90">Subcutaneous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(135)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==135?'assets/route4.svg':' assets/patient.svg'">

                </div>
    
                <span  [class.bold]="slider_value==135">Intravenous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(180)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==180?'assets/route5.svg':'assets/Butt.svg'">

                </div>
    
                <span  [class.bold]="slider_value==180">Perrectal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(225)">
                <div style="display: flex;justify-content: center;">
                     <!-- <img src="assets/cream.svg"> -->
                     <img [src]="slider_value==225?'assets/route6.svg':' assets/cream.svg'">

                </div>
    
                <span  [class.bold]="slider_value==225">Topical</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(270)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==270?'assets/route7.svg':' assets/vagina.svg'">

                </div>
    
                <span [class.bold]="slider_value==270">Pervaginal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(315)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==315?'assets/route8.svg':' assets/menu.svg'">

                </div>
    
                <span [class.bold]="slider_value==315">Other</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(0)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==0?'assets/route1.svg':' assets/mouth.svg'">

                </div>
    
                <span [class.bold]="slider_value==0">Oral</span>
            </span>
        </div>
    </div>
<!-- </div> -->

<!-- only for srinivas doc -->

<!-- <div  style="height: 27.5rem;width: 30rem;overflow: hidden;"> -->
    <div [ngStyle]="mode?{'visibility': 'visible'} : {'visibility': 'hidden'}" class="slider-wrapper" style="width: 5rem;height: 5rem;bottom:5rem">
        <div id="slider1" class="rs-chrome rs-control rs-classic-mode">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;"
            [class.oral_srini]="slider_value==0" [class.inhale_srini]="slider_value==32.7"
            [class.subcutaneous_srini]="slider_value==65.4" [class.intravenous_srini]="slider_value==98.1"
            [class.perrectal_srini]="slider_value==130.8" [class.topical_srini]="slider_value==163.5"
            [class.pervaginal_srini]="slider_value==196.2" [class.other_srini]="slider_value==228.9" 
            [class.other_new_srini]="slider_value==261.6" [class.item1_srini]="slider_value==294.3"
            [class.item2_srini]="slider_value==327"

                [src]="imgSrc_srini">
        </div>
    
        <div class='container container_srini'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(32.7)">
                <div style="display: flex;justify-content: center;"> <img [src]="slider_value==32.7?'assets/route2.svg':' assets/inhalator.svg'">
                </div>
                <span [class.bold]="slider_value==32.7">Inhale</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(65.4)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==65.4?'assets/route3.svg':' assets/intramascular_&_subcutaneous.svg'">
                </div>
    
                <span [class.bold]="slider_value==65.4">Subcutaneous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(98.1)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==98.1?'assets/route4.svg':' assets/patient.svg'">

                </div>
    
                <span  [class.bold]="slider_value==98.1">Intravenous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(130.8)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==130.8?'assets/route5.svg':'assets/Butt.svg'">

                </div>
    
                <span  [class.bold]="slider_value==130.8">Perrectal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(163.5)">
                <div style="display: flex;justify-content: center;">
                     <!-- <img src="assets/cream.svg"> -->
                     <img [src]="slider_value==163.5?'assets/route6.svg':'assets/cream.svg'">

                </div>
    
                <span  [class.bold]="slider_value==163.5">Topical</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(196.2)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==196.2?'assets/route7.svg':' assets/vagina.svg'">

                </div>
    
                <span [class.bold]="slider_value==196.2">Pervaginal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(228.9)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==228.9?'assets/route8.svg':' assets/menu.svg'">

                </div>
    
                <span [class.bold]="slider_value==228.9">Other</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(261.6)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==261.6?'assets/sublingual_white.png':' assets/sublingual_blue.png'">

                </div>
    
                <span [class.bold]="slider_value==261.6">Sublingual</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(294.3)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==294.3?'assets/Over_the_tongue_white.png':' assets/Over_the_tongue_blue.png'">

                </div>
    
                <span [class.bold]="slider_value==294.3">Over the tongue</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(327)">
                <div style="display: flex;justify-content: center;"> 
                    <!-- <img [src]="slider_value==320?'assets/route8.svg':' assets/menu.svg'"> -->

                </div>
    
                <span [class.bold]="slider_value==327" style="visibility:hidden">empty</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(0)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==0?'assets/route1.svg':' assets/mouth.svg'">

                </div>
    
                <span [class.bold]="slider_value==0">Oral</span>
            </span>
        </div>
    </div>
</div>

<div style="display: flex;justify-content: space-around; justify-content: space-around;position: relative">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>