<div class="row" *ngIf="state==1&&slotArray" style="justify-content: center;">
    <table>
        <tr *ngFor="let slot of slotArray">
            <td class="group">{{slot[0].start | date : 'shortTime':'GMT+11'}} - {{slot[slot.length-1].start | date :
                'shortTime':'GMT+11'}}</td>
            <td class="group">{{slot[0].token}} - {{slot[slot.length-1].token}}</td>
            <td *ngFor="let s of slot">
                <button [class.booked]="s.title!=null" [class.past]="s.title==null&&s.past" (click)="select(s)"
                    [class.selected]="s.selected" class="btn slot">{{s.token}}</button>
            </td>
        </tr>
    </table>

    <p style="width: 100%;text-align: center;font-size: 1.25rem;" *ngIf="slots&&slots.length==0">No slots available</p>


    <div style="display: flex;flex-direction: row;width: 100%;justify-content: center;margin-top: 1rem;">
        <div style="display: flex;">
            <img class="slot_img" src="assets/green.svg">
            <p class="slot_p">Available</p>
        </div>
        <div style="display: flex;">
            <img class="slot_img" src="assets/red.svg">
            <p class="slot_p">Booked</p>
        </div>
        <div style="display: flex;">
            <img class="slot_img" src="assets/blue.svg">
            <p class="slot_p">Selected</p>
        </div>
    </div>

    <div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
        <button class="cancel btn" (click)="cancel()" style="width: fit-content;">Cancel</button>
        <button class="next btn" (click)="next()" [class.disabled]="slots&&slots.length==0" style="width: fit-content;">OK</button>
    </div>


</div>

<div>
    <p *ngIf="data.amount>0" style="text-align: center;font-size: 1rem;margin-top: 0.5rem;">Note: Rs. {{data.amount}}
        will be deducted from your wallet </p>
</div>

<div class="row" *ngIf="state==2">
    <p style="width: 100%;text-align: center;">You have selected token number {{apptTokens}} for {{type}} appointment on
        {{date | date : 'dd-MM-yyyy'}} at {{time | date :'shortTime':'GMT+11' }}</p>
    <div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;width: 100%;">
        <button class="cancel btn" (click)="cancel()" style="width: fit-content;">Cancel</button>
        <button class="next btn" (click)="next()" [disabled]="disabled" style="width: fit-content;">Next</button>
    </div>
</div>

<div class="row" *ngIf="state==3">
    <p style="width: 100%;text-align: center;font-size: 1rem;">You have Booked token number {{apptTokens}} for {{type}}
        appointment on {{date | date : 'dd-MM-yyyy'}} at {{time | date :'shortTime':'GMT+11'}}</p>
    <div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;width: 100%;">
        <button class="next btn" (click)="cancel()" style="width: fit-content;">OK</button>
    </div>
</div>
