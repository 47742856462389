<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>Past Medical History</p>


    <div style="display: flex;">

        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary" [disabled]="disabled"
            (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close" (click)="close()"></i>

    </div>


</div>

<div style="max-height: 15rem;overflow: auto;">
    <div *ngFor="let p of pmhdata;let i=index" style="width: 100%;">

        <div *ngIf="p.question_type=='checkbox'">
            <p>{{p.description}} *</p>

            <div style="display: flex;flex-wrap: wrap;">
                <div *ngFor="let opt of p.options;let j=index" style="width: 50%;display: flex;margin-bottom: 0.25rem;">
                    <mat-checkbox [checked]="opt.checkbox_value==1" (change)="optionToggle(i,j)"
                        (change)="opt.checkbox_title=='None'?none(i,j):''">
                    </mat-checkbox>
                    <p style="color: #000;margin-left: 0.5rem;line-height: 16px;cursor: pointer;"
                        (click)="optionToggle(i,j)" (click)="opt.checkbox_title=='None'?none(i,j):''">
                        {{opt.checkbox_title}}</p>

                </div>
            </div>


        </div>

        <div *ngIf="p.question_type=='text'">
            <p>{{p.description | titlecase }}</p>
            <textarea [(ngModel)]="p.answer" maxlength="1000" style="height: 5rem;width: 100%;">
            </textarea>
        </div>

    </div>
</div>
    