<div class="bodycontentRightView">
    <div class="grid-table">
      <form [formGroup]="engagementSettings">
        <div class="row">
            <div class="col-3" style="margin-top: 15px;">
              <div style="display: flex;">
                <div style="margin-left: 10px;font-size: 15px; font-family: poppins;font-weight: bold;">Engagement Settings</div>   
                <div style="margin-left: 10px;"><i class="fa fa-plus-circle" style="color: #0063FF; cursor: pointer;" (click)="openBottomSheet()"></i></div>
              </div>
            </div>

            <div class="col-6"></div>
            <div class="col-3" style="margin-top: 15px;">
                <mat-radio-group aria-label="Select an option"
                [color]="'primary'"
                style="color: #617595;border: #0063FF;"
                formControlName="allActiveInactive">
                    <mat-radio-button class="example-radio-button" (change)="searchChange('0')" [value]="0">All</mat-radio-button>
                    <mat-radio-button class="example-radio-button" (click)="searchChange('1')" [value]="1" style="margin-left: 15px;">Active</mat-radio-button>
                    <mat-radio-button class="example-radio-button" (change)="searchChange('2')" [value]="2" style="margin-left: 15px;">Inactive</mat-radio-button>
                </mat-radio-group>                  
            </div>
        </div> </form>
         <hr>

        <div class="row">

            <table mat-table [dataSource]="dataSource" style="width:100%;height: 10vh;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Message name Column -->
                <ng-container matColumnDef="messagining">
                  <th mat-header-cell *matHeaderCellDef> Message Name </th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.messagining}} </td>
                </ng-container>
              
                <!-- Targeted users Column -->
                <ng-container matColumnDef="target">
                  <th mat-header-cell *matHeaderCellDef> Target User </th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.target}} </td>
                </ng-container>
              
                <!-- Frequency Column -->
                <ng-container matColumnDef="frequency">
                  <th mat-header-cell *matHeaderCellDef> Frequency </th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.frequency}} </td>
                </ng-container>
              
                <!-- Scheduled date Column -->
                <ng-container matColumnDef="Scheduled">
                  <th mat-header-cell *matHeaderCellDef> Scheduled Date </th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.Scheduled}} </td>
                </ng-container>
              
                <!-- Next run date Column -->
                <ng-container matColumnDef="next">
                    <th mat-header-cell *matHeaderCellDef> Next Run Date </th>
                    <td mat-cell *matCellDef="let element" style="width:20%"> {{element.next}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
              
        </div>
    </div>
</div>

