<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Questionnaire</p>
        </div>
    </div>
    

  <div class="row" style="margin-left: 6px;margin-top: 50px;width:99%">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div  style="width:95%;margin-top: 30px;">
          <div style="font-weight: 800;"><span>1)</span> <span style="margin-left: 10px;">Past Medical History Questionnnaire</span></div>
          <hr class="hrStyle">
          <div *ngIf="pastmedicalQuest" class="row" style="margin-left: 20px;">
            <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
               <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                <div>
                   <img src="assets/pdf-file-format.svg" style="width:35px;"  />{{pastmedicalQuest_FileName}}
                 </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(pastmedicalQuest_FileName)"/>
                     </div>
                </div>
               </div> 
            </div>
          </div>
        <div *ngIf="!pastmedicalQuest" style="width:95%;margin-top: 30px;;text-align: center;">
          <div style="color:red;">No Data Available</div>
        </div>
        </div>
        

      </div>

      <div class="col-sm-6 col-md-6 col-lg-6">
        <div style="width:99%;margin-top: 30px;">
            <div style="font-weight: 800;"><span>2)</span> <span style="margin-left: 10px;">Personal Health History</span></div>
            <hr class="hrStyle">
            <div  *ngIf="persHealth" class="row" style="margin-left: 20px;">
              <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
                 <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                  <div>
                     <img src="assets/pdf-file-format.svg" style="width:35px;"  />
                     {{persHealth_FileName}}
                   </div>
                  <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                          <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(persHealth_FileName)"/>
                       </div>
                  </div>
                 </div> 
              </div>
            </div>
            <div  *ngIf="!persHealth" style="width:95%;margin-top: 30px;;text-align: center;">
              <div style="color:red;">No Data Available</div>
            </div>
          
        </div>
      </div>
  </div>

  <div class="row" style="margin-left: 6px;margin-top: 30px;width:99%">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div style="width:95%;margin-top: 20px;">
          <div style="font-weight: 800;"><span>3)</span> <span style="margin-left: 10px;"> Past Medical History</span></div>
          <hr class="hrStyle">
          <div *ngIf="pastmedicalHis" class="row" style="margin-left: 20px;">
            <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
               <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                <div>
                   <img src="assets/pdf-file-format.svg" style="width:35px;"  />
                   {{pastmedicalHis_FileName}}
                 </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(pastmedicalHis_FileName)"/>
                     </div>
                </div>
               </div> 
            </div>
          </div>
          <div *ngIf="!pastmedicalHis" style="width:95%;margin-top: 30px;;text-align: center;">
            <div style="color:red;">No Data Available</div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-6">
        <div style="width:99%;margin-top: 20px;">
          <div style="font-weight: 800;"><span>4)</span> <span style="margin-left: 10px;">Medication History</span></div>
          <hr class="hrStyle">
          <div *ngIf="medication" class="row" style="margin-left: 20px;">
            <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
               <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                <div>
                   <img src="assets/pdf-file-format.svg" style="width:35px;"  />
                   {{medication_FileName}}
                 </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(medication_FileName)"/>
                     </div>
                </div>
               </div> 
            </div>
          </div>
        <div *ngIf="!medication" style="width:95%;margin-top: 30px;;text-align: center;">
          <div style="color:red;">No Data Available</div>
        </div>
        </div>
        
      </div>
      
  </div>

  <div class="row" style="margin-left: 6px;margin-top: 30px;width:99%">

    <div class="col-sm-6 col-md-6 col-lg-6">
      <div style="width:95%;margin-top: 20px;">
        <div style="font-weight: 800;"><span>5)</span> <span style="margin-left: 10px;">Family Medical History</span></div>
        <hr class="hrStyle">
        <div *ngIf="familymedical" class="row" style="margin-left: 20px;">
          <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
             <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
              <div>
                 <img src="assets/pdf-file-format.svg" style="width:35px;"  />
                 {{familymedical_FileName}}
               </div>
              <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                      <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(familymedical_FileName)"/>
                   </div>
              </div>
             </div> 
          </div>
        </div>
        <div *ngIf="!familymedical" style="width:95%;margin-top: 30px;;text-align: center;">
          <div style="color:red;">No Data Available</div>
        </div>
      </div>
      
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6">
      <div style="width:99%;margin-top: 20px;">
        <div style="font-weight: 800;"><span>6)</span> <span style="margin-left: 10px;">Social and Personal History</span></div>
        <hr class="hrStyle">
        <div *ngIf="socialpersonal" class="row" style="margin-left: 20px;">
          <div class="col-sm-9 col-md-9 col-lg-9" style="margin-top: 15px;">
             <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
              <div>
                 <img src="assets/pdf-file-format.svg" style="width:35px;"  />
                 {{socialpersonal_FileName}}
               </div>
              <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                      <img  src="assets/eye.svg" matTooltip="View PDF" style="float:right;width:20px;cursor: pointer;" (click)="showattachmentPdf(socialpersonal_FileName)"/>
                   </div>
              </div>
             </div> 
          </div>
        </div>
        <div *ngIf="!socialpersonal" style="width:95%;margin-top: 30px;;text-align: center;">
          <div style="color:red;">No Data Available</div>
        </div>
      </div>
      
    </div>
     
  </div>
  <br>
</div>


