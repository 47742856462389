<div style="display: flex;">

    <p style="color: #1A73FF;font-weight:600;font-size:1rem">Patient Details</p>

    <div style="border: 1px solid #D9D9D9;border-radius: 2px;display: flex;margin-left:auto;padding-left: 5px;padding-right: 5px;">
        <input [(ngModel)]="file_no" placeholder="Enter file ID">

        <i class="fa fa-search" style="align-self: center;margin-left:0.25rem;cursor: pointer;" (click)="search()"></i>

        <!-- <button class="btn btn-primary" (click)="search()">Search</button> -->
    </div>

    <img (click)="close()" style="margin-left: 0.5rem;width:1rem;cursor: pointer;" src="assets/close-copy.svg">
</div>

<div style="display: flex;justify-content: space-between;margin-top: 00.5rem;">
    
</div>

<div style="height: 10rem;overflow-y: auto;">
    <p style="margin-top: 0.5rem;" *ngIf="list && list.length==0">No data found for the patient</p>

    <div *ngIf="list" style="display: flex;justify-content: space-between;flex-wrap: wrap;width: 100%;">
        <div  style="display:flex;justify-content: space-between;width: 100%;cursor: pointer;border: 1px solid #D9D9D9;padding: 0.25rem;" (click)="setData(l)" *ngFor="let l of list">
            <div style="width: 30%;">
                <p style="font-size:0.7rem">Name</p>
                <p style="font-size:0.9rem">{{l.MainDicomTags.PatientName}}</p>
            </div>

            <div style="width: 30%;">
                <p style="font-size:0.7rem">Gender</p>
                <p style="font-size:0.9rem">{{l.MainDicomTags.PatientSex}}</p>
            </div>

            <div style="width: 30%;">
                <p style="font-size:0.7rem">ID</p>
                <p style="font-size:0.9rem">{{l.MainDicomTags.PatientID}}</p>
            </div>
        </div>
        
        <!-- <div (click)="setData(l)" style="margin-top: 0.5rem;border: 1px solid;cursor: pointer;padding-left: 00.5rem;" *ngFor="let l of list">
            <p>Name : {{l.MainDicomTags.PatientName}}</p>
            <p>Gender : {{l.MainDicomTags.PatientSex}}</p>
            
            <p>ID : {{l.MainDicomTags.PatientID}}</p>

        </div> -->
    </div>
</div>