import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-iap-bmi-chart-male-print',
  templateUrl: './iap-bmi-chart-male-print.component.html',
  styleUrls: ['./iap-bmi-chart-male-print.component.scss']
})
export class IapBmiChartMalePrintComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }
  @Input() chartData;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.drawChart(this.chartData)
  }

  drawChart(data) {
    var vitalData: any = data

    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 1000 - margin.top - margin.bottom;
    var width = 870 - margin.left - margin.right;

    var bmiData = [];

    if (vitalData.bmi) {
      for (var i = 0; i < vitalData.bmi.length; i++) {
        if (vitalData.bmi[i].x > 59 && vitalData.bmi[i].x < 217) {
          bmiData.push(vitalData.bmi[i]);
        }
      }
    }

    var set1 = [{ x: 60, y: 12.1 }, { x: 66, y: 12.15 }, { x: 72, y: 12.2 }, { x: 78, y: 12.25 }, { x: 84, y: 12.3 }, { x: 90, y: 12.4 }, { x: 96, y: 12.5 }, { x: 102, y: 12.6 },
    { x: 108, y: 12.7 }, { x: 114, y: 12.8 }, { x: 120, y: 12.9 }, { x: 126, y: 13 }, { x: 132, y: 13.1 }, { x: 138, y: 13.2 },
    { x: 144, y: 13.3 }, { x: 150, y: 13.4 }, { x: 156, y: 13.55 }, { x: 162, y: 13.7 }, { x: 168, y: 13.8 }, { x: 174, y: 14 },
    { x: 180, y: 14.2 }, { x: 186, y: 14.4 }, { x: 192, y: 14.6 }, { x: 198, y: 14.9 }, { x: 204, y: 15.1 }, { x: 210, y: 15.4 }, { x: 216, y: 15.6 }];

    var set2 = [{ x: 60, y: 12.4 }, { x: 66, y: 12.4 }, { x: 72, y: 12.5 }, { x: 78, y: 12.5 }, { x: 84, y: 12.6 }, { x: 90, y: 12.7 }, { x: 96, y: 12.8 }, { x: 102, y: 12.9 },
    { x: 108, y: 13 }, { x: 114, y: 13.1 }, { x: 120, y: 13.2 }, { x: 126, y: 13.3 }, { x: 132, y: 13.5 }, { x: 138, y: 13.6 },
    { x: 144, y: 13.8 }, { x: 150, y: 13.9 }, { x: 156, y: 14 }, { x: 162, y: 14.2 }, { x: 168, y: 14.3 }, { x: 174, y: 14.5 },
    { x: 180, y: 14.7 }, { x: 186, y: 14.9 }, { x: 192, y: 15.1 }, { x: 198, y: 15.4 }, { x: 204, y: 15.6 }, { x: 210, y: 15.9 }, { x: 216, y: 16.2 }];

    var set3 = [{ x: 60, y: 12.8 }, { x: 66, y: 12.9 }, { x: 72, y: 12.9 }, { x: 78, y: 13 }, { x: 84, y: 13.1 }, { x: 90, y: 13.2 }, { x: 96, y: 13.3 }, { x: 102, y: 13.4 },
    { x: 108, y: 13.5 }, { x: 114, y: 13.7 }, { x: 120, y: 13.8 }, { x: 126, y: 14 }, { x: 132, y: 14.1 }, { x: 138, y: 14.3 },
    { x: 144, y: 14.5 }, { x: 150, y: 14.6 }, { x: 156, y: 14.8 }, { x: 162, y: 14.9 }, { x: 168, y: 15.1 }, { x: 174, y: 15.3 },
    { x: 180, y: 15.5 }, { x: 186, y: 15.8 }, { x: 192, y: 16 }, { x: 198, y: 16.3 }, { x: 204, y: 16.6 }, { x: 210, y: 16.8 }, { x: 216, y: 17.1 }];

    var set4 = [{ x: 60, y: 13.6 }, { x: 66, y: 13.7 }, { x: 72, y: 13.7 }, { x: 78, y: 13.8 }, { x: 84, y: 13.9 }, { x: 90, y: 14.1 }, { x: 96, y: 14.2 }, { x: 102, y: 14.4 },
    { x: 108, y: 14.5 }, { x: 114, y: 14.7 }, { x: 120, y: 14.9 }, { x: 126, y: 15.1 }, { x: 132, y: 15.4 }, { x: 138, y: 15.6 },
    { x: 144, y: 15.8 }, { x: 150, y: 16 }, { x: 156, y: 16.3 }, { x: 162, y: 16.5 }, { x: 168, y: 16.7 }, { x: 174, y: 16.9 },
    { x: 180, y: 17.2 }, { x: 186, y: 17.4 }, { x: 192, y: 17.7 }, { x: 198, y: 18 }, { x: 204, y: 18.3 }, { x: 210, y: 18.6 }, { x: 216, y: 18.9 }];

    var set5 = [{ x: 60, y: 14.7 }, { x: 66, y: 14.8 }, { x: 72, y: 14.9 }, { x: 78, y: 15 }, { x: 84, y: 15.1 }, { x: 90, y: 15.3 }, { x: 96, y: 15.5 }, { x: 102, y: 15.7 },
    { x: 108, y: 15.9 }, { x: 114, y: 16.2 }, { x: 120, y: 16.4 }, { x: 126, y: 16.7 }, { x: 132, y: 17 }, { x: 138, y: 17.3 },
    { x: 144, y: 17.7 }, { x: 150, y: 17.9 }, { x: 156, y: 18.2 }, { x: 162, y: 18.5 }, { x: 168, y: 18.7 }, { x: 174, y: 19 },
    { x: 180, y: 19.3 }, { x: 186, y: 19.6 }, { x: 192, y: 19.9 }, { x: 198, y: 20.2 }, { x: 204, y: 20.5 }, { x: 210, y: 20.8 }, { x: 216, y: 21.1 }];

    var set6 = [{ x: 60, y: 15.7 }, { x: 66, y: 15.8 }, { x: 72, y: 16 }, { x: 78, y: 16.1 }, { x: 84, y: 16.3 }, { x: 90, y: 16.5 }, { x: 96, y: 16.7 }, { x: 102, y: 17 },
    { x: 108, y: 17.3 }, { x: 114, y: 17.6 }, { x: 120, y: 18 }, { x: 126, y: 18.3 }, { x: 132, y: 18.7 }, { x: 138, y: 19.1 },
    { x: 144, y: 19.5 }, { x: 150, y: 19.9 }, { x: 156, y: 20.2 }, { x: 162, y: 20.5 }, { x: 168, y: 20.8 }, { x: 174, y: 21.1 },
    { x: 180, y: 21.4 }, { x: 186, y: 21.7 }, { x: 192, y: 22 }, { x: 198, y: 22.3 }, { x: 204, y: 22.6 }, { x: 210, y: 22.9 }, { x: 216, y: 23.2 }];

    var set7 = [{ x: 60, y: 17.5 }, { x: 66, y: 17.6 }, { x: 72, y: 17.8 }, { x: 78, y: 18 }, { x: 84, y: 18.2 }, { x: 90, y: 18.5 }, { x: 96, y: 18.8 }, { x: 102, y: 19.2 },
    { x: 108, y: 19.6 }, { x: 114, y: 20.1 }, { x: 120, y: 20.5 }, { x: 126, y: 21 }, { x: 132, y: 21.5 }, { x: 138, y: 22.1 },
    { x: 144, y: 22.6 }, { x: 150, y: 23 }, { x: 156, y: 23.4 }, { x: 162, y: 23.8 }, { x: 168, y: 24.2 }, { x: 174, y: 24.5 },
    { x: 180, y: 24.9 }, { x: 186, y: 25.2 }, { x: 192, y: 25.5 }, { x: 198, y: 25.8 }, { x: 204, y: 26 }, { x: 210, y: 26.3 }, { x: 216, y: 26.6 }];

    var rawSvg = this.elementRef.nativeElement.querySelector('svg');

    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    var wrap = d3.select(rawSvg).selectAll('d.d3line').data([bmiData]);
    var gEnter = wrap.enter().append('g').attr('class', 'd3line').append('g');


    gEnter.append('g').attr('class', 'lines');
    gEnter.append('g').attr('class', 'point-clips');
    gEnter.append('g').attr('class', 'point-paths');

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveBundle.beta(.8));

    var datalineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xScale0 = d3.scaleLinear().domain([216, 60]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([216, 60]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([10, 35]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([10, 35]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return d / 12 % 1 ? '' : (d / 12) as any;
    }).tickSizeInner(-height).tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-height).tickSizeOuter(0);
    var yAxisLeft = d3.axisLeft(yScale1).ticks(26).tickValues(d3.range(10, 35, 2)).tickFormat(function (d: any) {
      return d % 2 == 0 ? d : '';
    }).tickSizeInner(-width).tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(26).tickValues(d3.range(10, 35, 2)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-width).tickSizeOuter(0);

    var age = function (data) {
      var mon = data / 12;
      var result = mon.toFixed(1).split('.');
      var mod = data % 12;
      var res = mod.toString().split('.');
      return result[0].toString() + " years ";
    };

    // var heightpoint = wrap.selectAll('circle')
    // .data(function (d) { return d; })
    // .enter().append('circle')
    // .attr('cx', function (d) { return xScale0(d.x); })
    // .attr('cy', function (d) { return yScale0(d.y); })
    // .attr('r', 5)
    // .attr('fill','green')
    // .on("mouseover", function(d) {    
    //     div.transition()    
    //         .duration(200)    
    //         .style("opacity", .9);    
    //     div .html(age(d.x) + "<br/>"  + (d.y).toString())
    //         .style("left", (d3.event.pageX) + "px")  
    //         .style("top", (d3.event.pageY - 28) + "px");  
    //     })          
    // .on("mouseout", function(d) {  
    //     div.transition()    
    //         .duration(500)    
    //         .style("opacity", 0);
    // });

    svg.selectAll("circle")
      .data(bmiData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'green').on("mouseover", function (event, d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html(age(d.x) + "<br/>" + (d.y).toString())
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 30)
      .style("text-anchor", "middle")
      .text("Age in Years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -7)
      .style("text-anchor", "middle")
      .text("IAP Boys BMI Chart 5 - 18 years");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 745)
      .style("text-anchor", "start")
      .text("3");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 725)
      .style("text-anchor", "start")
      .text("5");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 690)
      .style("text-anchor", "start")
      .text("10");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 630)
      .style("text-anchor", "start")
      .text("25");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 540)
      .style("text-anchor", "start")
      .text("50");

    svg.append("g")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);

      // svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set1),
      //               "stroke": "gray",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set1)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "gray")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set2),
      //               "stroke": "gray",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set2)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "gray")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set3),
      //               "stroke": "gray",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set3)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "gray")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set4),
      //               "stroke": "black",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });
      
      svg.append("path")
      .datum(set4)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set5),
      //               "stroke": "black",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set5)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set6),
      //               "stroke": "orange",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set6)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "orange")
      .style("stroke-width", "2");

      //       svg.append("svg:path")
      //             .attr({
      //               d: lineFunc(set7),
      //               "stroke": "red",
      //               "stroke-width": 2,
      //               "fill": "none",
      //               "class": "path"
      //             });

      svg.append("path")
      .datum(set7)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");
                 
      // svg.append("svg:path")
      // .attr({
      //   d: datalineFunc(bmiData),
      //   "stroke": "green",
      //   "stroke-width": 2,
      //   "fill": "none",
      //   "class": "path"
      // });

      svg.append("path")
      .datum(bmiData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");
      
  }

}
