<!--0 for obst-->

<div class="conTaniner" *ngIf="data.openFrom==0">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Obst & Gynec</p>
        </div>
    </div>

    <div class="row titleContainer" style="margin-top: 40px;">
        <div class="col-sm-5 col-md-5 col-lg-5"></div>
        <div class="col-sm-5 col-md-7 col-lg-7" style="display: flex;">
            <div class="obstab" id="obstab_0" (click)="viewObstCurrentTab(true,0,'static')" style="cursor:pointer;color:#007bff;padding: 6px;">History</div>
            <div class="obstab" id="obstab_1" (click)="viewObstCurrentTab(false,1,'static')" style="margin-left: 15px;cursor:pointer;color:#007bff;padding: 6px;">Pregnancy</div>
        </div>
    </div>

   <div class="row" *ngIf="viewObstStatus" style="width:100%;margin-top: 80px;margin-left: 5px;">
       <form [formGroup]="obstetricHistory"  (submit)="validateobst()" style="width:100%;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div style="margin-top: 10px;">
                    <div class="heading">OBSTETRICS HISTORY</div>
                    <button type="submit" style="float:right;margin-top:-45px;color:#fff;box-shadow: 2px 3px 2px 0px grey;"
                      class="btn btn-primary"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
                      <hr class="hrStyle">
                </div>
                <div class="row" style="margin-top: 30px;">
                    <div class="col-lg-4 col-md-4 obsgynecsubheading">
                        <span class="sub_headings">LMP</span> 
                        <span class="float-right text-primary sub_headings_right">Select Date</span>
                        <input
                          [ngClass]="{'highlightlmpmissing': lmpmissing == true}"
                            class="form-control dateInput" type="date"
                            onkeypress="return event.charCode == 8" (change)="getEdd()"
                            [max]="obslmpmaxDate" [ngModel]='obslmp' formControlName="obslmps"
                            >
                    </div>
                    <div class="col-lg-4 col-md-4 obsgynecsubheading">
                        <span class="sub_headings">EDD</span>
                        <span class="float-right text-primary sub_headings_right">Select Date</span>
                        <input class="form-control dateInput" type="date"
                            onkeypress="return event.charCode == 8"
                            [min]="obseddminDate" [max]="obseddmaxDate"
                            (change)="changeEdd()" [(ngModel)]='obsedd'
                           formControlName="obsedds">
                    </div>
                    <div class="col-lg-4 col-md-4 obsgynecsubheading">
                        <span class="sub_headings">Actual Delivery</span>
                        <span class="float-right text-primary sub_headings_right">Select Date</span>
                        <input class="form-control dateInput" type="date"
                        [min]="obsactualminDate" [max]="obsactualmaxDate"
                        [(ngModel)]='obsactualdelivery'
                         formControlName="obsactualdeliverys">
                    </div>
                </div>
                <br>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Gravida</span>
                        <input class="form-control dateInput" type="number"
                        [(ngModel)]='obsgravida'
                           formControlName="obsgravidas">
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Para</span>
                        <input class="form-control dateInput" name="para" type="number"
                            min="0"
                            max="10"
                            (keyup)="preventInput($event);addabortpara()"
                            (keypress)="numberOnly($event)"
                            [(ngModel)]='obspara'
                             formControlName="obsparas">
                        &nbsp; &nbsp;<span class="obsgynecaddview" style="margin-top:5px" (click)="openaddpara()"
                            ><img
                                style="cursor: pointer;height: 12px; width: 17px;"
                                src="assets/obsadd.svg">&nbsp;Add</span> &nbsp;
                        &nbsp;
                        <span class="obsgynecaddview" (click)="openviewpara()"
                            ><img
                                style="cursor: pointer;height: 10px; width: 17px;"
                                src="assets/obsview.svg">&nbsp;View</span>
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Abortion</span>
                        <input class="form-control dateInput" name="abortions"
                            type="number" 
                            min="0" max="10"
                            (keyup)="preventInput1($event);addabortpara()"
                            (keypress)="numberOnly($event)"
                            [(ngModel)]='obsabortion'
                            formControlName="obsabortions">
                        &nbsp; &nbsp;<span class="obsgynecaddview" (click)="openaddAbort()"
                            ><img
                                style="cursor: pointer;height: 12px; width: 17px;"
                                src="assets/obsadd.svg">&nbsp;Add</span> &nbsp;
                        &nbsp;
                        <span class="obsgynecaddview" (click)="openviewAbort()"
                            ><img
                                style="cursor: pointer;height: 10px; width: 17px;"
                                src="assets/obsview.svg">&nbsp;View</span>
                    </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-lg-12 obsgynecsubheading">
                        <span class="obsgynecsubheading">General Notes</span>
                            <textarea class="form-control" name="obshistory" cols="50"
                                rows="7" maxlength="2000" style="margin-top: 7px;height: 100px;"
                                [(ngModel)]='obsgeneralnotes'
                                formControlName="obsgeneralnotess"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="!viewObstStatus" style="width:100%;margin-top: 80px;margin-left: 5px;">
        <form [formGroup]="currentpreg" class="row" style="width:100%;" (submit)="save_currentpregnancydetails();save_currentpregnancyRiskdetails();updatescanreport()">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <div style="margin-top: 10px;">
                     <div class="heading">CURRENT PREGNANCY</div>
                     <button type="submit" style="float:right;margin-top:-45px;color:#fff;box-shadow: 2px 3px 2px 0px grey;"
                      class="btn btn-primary"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
                     <hr class="hrStyle">
                 </div>
                 <div class="row" style="margin-top: 30px;">
                    <div class="col-lg-6 col-md-6 leftBorder" style="display: flex;">
                        <div style='width:60%'>
                            <span class="sub_headings">Pregnancy risk</span>
                            <span class="float-right text-primary sub_headings_right" style="margin-right: 100px !important;">Select risk</span>
                            <select class="form-control dateInput" (change)="riskstatusmodifiedfunc()"
                            formControlName="obspregrisks">
                                <option value="" disabled selected>Select</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </select>
                      </div>
                      <div style='width:30%;margin-top: 10px;'>
                        &nbsp;<span class="obsgynecaddview" (click)="get_currentpregnancyRiskdetails();openviewRiskhistory()"><img
                                style="cursor: pointer;height: 10px; width: 17px;"
                                src="assets/obsview.svg">&nbsp;View Change
                            History</span><br><br>
                        &nbsp;<span class="obsgynecaddview" (click)="openviewarchivedcpRisk()"><img
                                style="cursor: pointer;height: 10px; width: 17px;"
                                src="assets/obsview.svg">&nbsp;View Past records</span>
                        </div>

                    </div>
                    <div class="col-lg-1 col-md-1"></div>
                    <div class="col-lg-5 col-md-5">
                       <span class="obsgynecsubheading">General Notes</span>
                        <textarea class="form-control" name="obshistory" cols="50"
                            rows="7" maxlength="150" style="margin-top: 7px;height: 100px;"
                            [(ngModel)]='obsgeneralnotes1'
                            formControlName="obsgeneralnotess1"></textarea>
                    </div>
                </div>
             </div>
        </form>
        
        <div class="row" style="width:100%;margin-top: 30px;">
                    <div class="col-lg-12">
                        <div style="margin-top: 10px;">
                            <div class="heading">CURRENT PREGNANCY RECORD</div>
                            <hr class="hrStyle">
                        </div>
                        <table style="width:100%;margin-top: 10px;">
                            <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                                <tr style="height: 30px;">
                                    <th style="border: 2px solid #c8e2ff;width: 6%;"><input
                                            type="checkbox" [(ngModel)]="masterSelected"
                                            name="list_name" value="m1"
                                            (change)="checkUncheckAll()"></th>
                                    <th style="border: 2px solid #c8e2ff;width: 6%;">S.No
                                    </th>
                                    <th style="border: 2px solid #c8e2ff;width: 13%;">Date
                                    </th>
                                    <th style="border: 2px solid #c8e2ff;width: 13%;">BP
                                    </th>
                                    <th style="border: 2px solid #c8e2ff;width: 13%;">P/A
                                    </th>
                                    <th style="border: 2px solid #c8e2ff">Remarks</th>
                                    <th
                                        style="width: 5% !important;border: 2px solid #c8e2ff">
                                        <span class="obsgynecaddview"><img
                                                style="cursor: pointer;height: 12px; width: 17px;"
                                                src="assets/obsadd.svg"  (click)="openaddcurrentpregRecords()"
                                                ></span>
                                    </th>
                                </tr>

                            </thead>
                            <tbody style="font-size: 15px; text-align: center;">
                                <tr style="height: 40px;"
                                    *ngFor="let data of viewcurrentpregRecords; let i=index;">
                                    <td style="border: 2px solid #e3edf8"><input
                                            type="checkbox" [(ngModel)]="data.isSelected"
                                            name="list_name" (change)="isAllSelected()">
                                    </td>
                                    <td style="border: 2px solid #e3edf8">{{i+1}}</td>
                                    <td style="border: 2px solid #e3edf8">{{data.date}}</td>
                                    <td style="border: 2px solid #e3edf8">{{data.bp}}</td>
                                    <td style="border: 2px solid #e3edf8">{{data.pa}}</td>
                                    <td
                                        style="border: 2px solid #e3edf8;padding-left: 15px;">
                                        {{data.remarks}}</td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                </tr>
                                <tr style="height: 40px;">
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td style="border: 2px solid #e3edf8"></td>
                                    <td
                                        style="width: 50px !important;border: 2px solid #e3edf8">
                                        <span class="obsgynecaddview"><img
                                                style="cursor: pointer;height: 12px; width: 17px;"
                                                src="assets/obsadd.svg"
                                                (click)="openaddcurrentpregRecords()"></span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
        </div><br>

        <div class="row">
            <div class="col-lg-11" style="margin-left: 95px;">
                <span class="obsgynecaddview"
                    style="float: right;font-weight: bold;"
                    (click)="openviewarchivedcpRecords()"><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;View past records</span>
                <span class="obsgynecaddview"
                    style="float: right;font-weight: bold;"
                    (click)="archivecpRecords()"><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                    &nbsp;
                    &nbsp;</span>
            </div>
        </div>

        <div class="row" style="width:100%;margin-top: 30px;">
            <div class="col-lg-12">
                <div style="margin-top: 10px;">
                    <div class="heading">SCAN REPORT FINDINGS</div>
                    <hr class="hrStyle">
                </div>
                <table style="width: 100%;">
                    <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                        <tr style="height: 30px;">
                            <th class="obsttableheadcolor" style="width: 6%;"><input
                                    type="checkbox" [(ngModel)]="masterSelected2"
                                    name="list_name2" value="m3"
                                    (change)="checkUncheckAll2()"
                            ></th>
                            <th class="obsttableheadcolor" style="width: 6%;">S.No
                            </th>
                            <th class="obsttableheadcolor" style="width: 13%;">Date
                            </th>
                            <th class="obsttableheadcolor" style="width: 13%;">
                                Scanned On
                            </th>
                            <th class="obsttableheadcolor" style="width: 29%;">Scan
                                Report
                                Findings</th>
                            <th class="obsttableheadcolor" style="width: 13%;">Next
                                Scan
                                Date</th>
                            <th class="obsttableheadcolor" style="width: 15%;">Set
                                Followup
                                Date</th>
                            <th class="obsttableheadcolor"
                                style="width: 5% !important">
                                <span class="obsgynecaddview"><img
                                        style="cursor: pointer;height: 12px; width: 17px;"
                                        (click)="openaddscanreport()"
                                        src="assets/obsadd.svg"></span>
                            </th>
                        </tr>

                    </thead>
                    <tbody style="font-size: 15px; text-align: center;"
                        *ngIf="emptygetscan == false">
                        <tr style="height: 40px;"
                            *ngFor="let data of viewscanreportfindingdata; let i=index;">
                            <td class="obsttablerowcolor"><input type="checkbox"
                                    [(ngModel)]="data.isSelected" name="list_name2"
                                    (change)="isAllSelected2()"></td>
                            <td class="obsttablerowcolor">{{i+1}}</td>
                            <td class="obsttablerowcolor">{{data.date}}</td>
                            <td class="obsttablerowcolor">{{data.scan_date}}</td>
                            <td class="obsttablerowcolor"
                                style="text-align: left;padding-left: 15px;">
                                {{data.scan_findings}}</td>
                            <td class="obsttablerowcolor">{{data.next_scan_date}}
                            </td>
                            <td class="obsttablerowcolor"><input type="checkbox"
                                    [disabled]="enablescandetail(data.id)"
                                    (click)="setfollowupdate(i+1,data.next_scan_date,data.date,data.scan_date,data.scan_findings,data.isScanSelected);togglescanfollowup(data.isScanSelected);"
                                    [checked]="data.follow_up == 1"></td>
                            <td class="obsttablerowcolor"></td>

                        </tr>
                        <tr style="height: 40px;">
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"
                                style="width: 50px !important">
                                <span class="obsgynecaddview"><img
                                        style="cursor: pointer;height: 12px; width: 17px;"
                                        (click)="openaddscanreport()"
                                        src="assets/obsadd.svg"></span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody style="font-size: 15px; text-align: center;"
                        *ngIf="emptygetscan == true">
                        <tr style="height: 40px;">
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"
                                style="width: 50px !important">
                                <span class="obsgynecaddview"><img
                                        style="cursor: pointer;height: 12px; width: 17px;"
                                        (click)="openaddscanreport()"
                                        src="assets/obsadd.svg"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><br>

        <div class="row">
            <div class="col-lg-11" style="margin-left: 95px;">
                <span class="obsgynecaddview"
                    style="float: right;font-weight: bold;"
                    (click)="openviewarchivedcpscan()"><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;View past records</span>
                <span class="obsgynecaddview" (click)="archivecpscan()"
                    style="float: right;font-weight: bold;"><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                    &nbsp;
                    &nbsp;</span>
            </div>
        </div>
            <br>
     </div>

</div>

<!-- 1 for Gyncec-->

<div class="conTaniner" *ngIf="data.openFrom==1">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Obst & Gynec</p>
        </div>
    </div>

    <div class="row titleContainer" style="margin-top: 38px;">
        <div class="col-sm-5 col-md-5 col-lg-5"></div>
        <div class="col-sm-5 col-md-7 col-lg-7" style="display: flex;">
            <div class="gynectab" id="gynectab_0" (click)="viewgynecCurrentTab(true,0,'static')" style="cursor:pointer;color:#007bff;padding: 6px;">History</div>
            <div class="gynectab" id="gynectab_1" (click)="viewgynecCurrentTab(false,1,'static');get_folliclestudydetails();" style="margin-left: 15px;cursor:pointer;color:#007bff;padding: 6px;">Follicle Study</div>
        </div>
    </div>

    <div *ngIf="viewgynecStatus">
        <div class="row" style="margin-top: 80px;margin-left:6px;width:100%">
            <form [formGroup]="gynecologyHistory" (submit)="save_gynecologyhistory()" style="width:100%">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div style="margin-top: 10px;">
                        <div class="heading">GYNAECOLOGY HISTORY</div>
                        <button type="submit" style="float:right;margin-top:-45px;color:#fff;box-shadow: 2px 3px 2px 0px grey;"
                      class="btn btn-primary"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
                        <hr class="hrStyle">
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 obsgynecsubheading">
                            <span class="sub_headings">LMP</span>
                            <span class="float-right text-primary sub_headings_right" style="margin-right: 100px !important;">Select Date</span>
                            <input class="form-control dateInput" type="date"
                                onkeypress="return event.charCode == 8"
                                [max]="gyneclmpmaxDate" [(ngModel)]='gyneclmp'
                                formControlName="gyneclmps">
                        </div>
                        <div class="col-lg-4 col-lg-4 obsgynecsubheading">
                            <span class="sub_headings">Date of Entry</span>
                            <input class="form-control dateInput" disabled type="date"
                                onkeypress="return event.charCode == 8" [(ngModel)]='gynecdoe'
                                formControlName="gynecdoes">
                        </div>
                        <div class="col-lg-4 col-lg-4 obsgynecsubheading">
                            <span class="sub_headings">Menstrual History</span>
                            <span class="float-right text-primary sub_headings_right" style="margin-right: 100px !important;">Select option</span>
                            <select class="form-control dateInput" (change)="togglemenstrualcomplaint()"
                            [(ngModel)]='gynecmenstrualhistory'
                                formControlName="gynecmenstrualhistorys">
                                <option value="" selected>Select</option>
                                <option value="premenopausal">Premenopausal</option>
                                <option value="postmenopausal">Postmenopausal
                                </option>
                            </select>
                        </div>
                    </div><br><br>

                    <div class="row">
                        <div class="col-lg-4 col-lg-4 obsgynecsubheading" *ngIf="showpremenopausalcomp == true">
                                <span class="sub_headings">Premenopausal
                                    Complaint</span>
                                    <span class="float-right text-primary sub_headings_right" style="margin-right: 100px !important;">Select Complaint</span>
                                <select class="form-control dateInput" [(ngModel)]='gynecmenstrualcomplaint'
                                    formControlName="gynecmenstrualcomplaints1">
                                    <option value="" selected>Select</option>
                                    <option value="Subfertility">Subfertility</option>
                                    <option value="Polymenorrhea">Polymenorrhea</option>
                                    <option value="Oligomenorrhea">Oligomenorrhea
                                    </option>
                                    <option value="Menorrhagia">Menorrhagia</option>
                                    <option value="Dysmenorrhea">Dysmenorrhea</option>
                                    <option value="Dyspareunia">Dyspareunia</option>
                                    <option value="Pelvic Organ Descent">Pelvic Organ
                                        Descent
                                    </option>
                                    <option value="Pruritus">Pruritus</option>
                                    <option value="Vaginal Discharge">Vaginal Discharge
                                    </option>
                                    <option value="Suprapubic Pain">Suprapubic Pain
                                    </option>
                                    <option value="Intermenstrual Bleeding">
                                        Intermenstrual
                                        Bleeding</option>
                                </select>
                        </div>
                        <div class="col-lg-4 col-lg-4 obsgynecsubheading" *ngIf="showpremenopausalcomp == false">
                                <span class="sub_headings">Postmenopausal
                                    Complaint</span>
                                    <span class="float-right text-primary sub_headings_right" style="margin-right: 100px !important;">Select Complaint</span>
                                <select class="form-control dateInput" [(ngModel)]='gynecmenstrualcomplaint'
                                    formControlName="gynecmenstrualcomplaints2">
                                    <option value="" selected>Select</option>
                                    <option value="Bleeding">Bleeding</option>
                                    <option value="Vaginal Discharge">Vaginal Discharge
                                    </option>
                                    <option value="Pruritus">Pruritus</option>
                                    <option value="Vulval burning">Vulval burning
                                    </option>
                                    <option value="Pelvic Organ Descent">Pelvic Organ
                                        Descent
                                    </option>
                                </select>
                        </div>
                    </div><br>

                    <div class="row">
                        <div class="col-lg-11 col-lg-11 obsgynecsubheading">
                            <span class="obsgynecsubheading">General Notes</span>
                            <textarea class="form-control" name="obshistory" cols="50"
                                rows="7" maxlength="250" [(ngModel)]='gynecnote'
                                formControlName="gynecnotes"
                                style="margin-top: 7px;height: 100px;"></textarea>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="row" style="margin-top: 100px;margin-left:6px;width:100%">
            <div class="col-lg-12 col-md-12">
                <div style="margin-top: 10px;">
                    <div class="heading">GYNECOLOGY RECORD</div>
                    <hr class="hrStyle">
                </div>
                <table style="margin-top: 10px;">
                    <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                        <tr style="height: 30px;">
                            <th class="obsttableheadcolor" style="width: 2%;">S.No
                            </th>
                            <th class="obsttableheadcolor" style="width: 6%;">Date
                                of Entry
                            </th>
                            <th class="obsttableheadcolor" style="width: 6%;">LMP
                            </th>
                            <th class="obsttableheadcolor" style="width: 10%;">
                                Menstrual
                                History</th>
                            <th class="obsttableheadcolor" style="width: 12%;">
                                Pre/Post
                                menopausal Complaint </th>
                            <th class="obsttableheadcolor" style="width: 17%;">Notes
                            </th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 15px; text-align: center;">
                        <tr style="height: 40px;"
                            *ngFor="let data of viewfgynechistorydata; let i=index;">
                            <td class="obsttablerowcolor">{{i+1}}</td>
                            <td class="obsttablerowcolor">{{data.date}}</td>
                            <td class="obsttablerowcolor">{{data.lmp}}</td>
                            <td class="obsttablerowcolor">{{data.mensural_history}}
                            </td>
                            <td class="obsttablerowcolor">{{data.complaint}}</td>
                            <td class="obsttablerowcolor"
                                style="text-align: left;padding-left: 15px;">
                                {{data.notes}}
                            </td>
                        </tr>
                        <!-- <tr style="height: 40px;">
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                            <td class="obsttablerowcolor"></td>
                        </tr> -->
                    </tbody> 
                </table>
            </div>
        
        </div>
        <br>
   </div>

   <div *ngIf="!viewgynecStatus">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div style="margin-top: 80px;">
                    <div class="heading">Follicle Study</div>
                    <hr class="hrStyle">
                </div>
            </div>
            <table style="width: 95%; margin-left: 20px;">
                <thead style="background-color: #E5F0FF;" class="tableheader">
                    <tr style="height: 30px;">
                        <th class="obsttableheadcolor" style="width: 50px;">
                            <input type="checkbox" 
                            [(ngModel)]="masterSelected3"
                            name="list_name3" value="m4"
                            (change)="checkUncheckAll3()"
                            ></th>
                        <th class="obsttableheadcolor" style="width: 50px">S.No
                        </th>
                        <th class="obsttableheadcolor" style="width: 125px;">
                            Date</th>
                        <th class="obsttableheadcolor" style="width: 125px;">Day
                        </th>
                        <th class="obsttableheadcolor" style="width: 100px;">RT
                            Ovary</th>
                        <th class="obsttableheadcolor" style="width: 100px;">LT
                            Ovary</th>
                        <th class="obsttableheadcolor" style="width: 100px;">
                            Endometrium
                        </th>
                        <th class="obsttableheadcolor" style="width: 350px;">
                            Remarks</th>
                        <th class="obsttableheadcolor"
                            style="width: 6% !important">
                            <span class="obsgynecaddview"><img
                                    style="cursor: pointer;height: 12px; width: 17px;"
                                    (click)="openaddfolliclestudy()"
                                    src="assets/obsadd.svg"></span>
                        </th>
                    </tr>
                </thead>
                <tbody style="font-size: 15px; text-align: center;">
                    <tr style="height: 40px;"
                        *ngFor="let data of viewfolliclestudydata; let i=index;">
                        <td class="obsttablerowcolor"><input type="checkbox"
                                [(ngModel)]="data.isSelected" name="list_name3"
                                (change)="isAllSelected3()"></td>
                        <td class="obsttablerowcolor">{{i+1}}</td>
                        <td class="obsttablerowcolor">{{data.date}}</td>
                        <td class="obsttablerowcolor">{{data.day}}</td>
                        <td class="obsttablerowcolor">{{data.rt_ovary}}</td>
                        <td class="obsttablerowcolor">{{data.lt_ovary}}</td>
                        <td class="obsttablerowcolor">{{data.endometrium}}</td>
                        <td class="obsttablerowcolor"
                            style="text-align: left;padding-left: 15px;">
                            {{data.comments}}</td>
                        <td class="obsttablerowcolor"
                            style="width: 50px !important">
                            <span class="obsgynecaddview"><img
                                    style="cursor: pointer;height: 12px; width: 17px;"
                                    (click)="openaddfolliclestudy()"
                                    src="assets/obsadd.svg"></span>
                        </td>
                    </tr>
                    <tr style="height: 40px;">
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"></td>
                        <td class="obsttablerowcolor"
                            style="width: 50px !important">
                            <span class="obsgynecaddview"><img
                                    style="cursor: pointer;height: 12px; width: 17px;"
                                    (click)="openaddfolliclestudy()"
                                    src="assets/obsadd.svg"></span>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div><br>

        <div class="row">
            <div class="col-lg-12">
                <span class="obsgynecaddview"
                    style="float: right;font-weight: bold;" (click)="openviewarchivedfollicle()"
                    ><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;View past records</span>
                <span class="obsgynecaddview" (click)="archivefollicle()"
                    style="float: right;font-weight: bold;"><img
                        style="cursor: pointer;height: 10px; width: 17px;"
                        src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                    &nbsp;
                    &nbsp;</span>
            </div>
        </div>
   </div>
</div>