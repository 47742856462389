<div class="row">
    <p class="header">Add Growth Data</p>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Height (cm)</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="height" placeholder="Enter height (in cm)"/>
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Weight (kg)</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="weight" value="0">
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Arm Circumference</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="arm" placeholder="Arm circumference (in cm)">
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Head Circumference</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="head" placeholder="Head circumference (in cm)">
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Chest Circumference</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="chest" placeholder="Chest circumference (in cm)">
    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <p>Measurement Date</p>
    </div>

    <div class="col-lg-6">
        <input type="number" maxlength="3" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="date" id="date">
    </div>
</div>

<div class="row">
    <button (click)="save()" class="btn btn-primary">Save</button>
</div>