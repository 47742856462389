<div class="row row1">
    <p class="col-lg-11 col-md-11  header">Confirmation</p>
    <img class="col-lg-1 col-md-1 img" (click)="close()" src="assets/cancel.svg">
</div>

<div class="row">
    <p class="col-lg-12 col-md-12">{{data.name}}
    </p>
</div>

<div class="row">
    <div class="col-lg-9 col-md-9"></div>
    <button class="btn btn-success col-lg-1 col-md-1" (click)="yes()">Yes</button>
    <button class="btn btn-danger col-lg-1 col-md-1" (click)="no()">No</button>
</div>