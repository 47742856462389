<div class="container">
    <div class="closeIcon" style="float:right">
        <i class="fa fa-times" aria-hidden="true" (click)="close()" style="position: relative;top: -32px; left: 18px;"></i>
    </div>

<div class="p-0 heading_top" style="margin-top: 20px;">
    <div class="head_top">&nbsp;<img src="assets/images/growth.svg" height="20"/>&nbsp; Growth</div>

<div class="row mt-1" style="padding:10px">
    <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="custominputbox">
            <div class="toptxt">Weight<span style="color:red">*</span></div>
            <div>
                <!-- <input class="inputstyle" type="number" maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">Kg</span></div> -->
                <input class="inputstyle" type="number"  [(ngModel)]="weight" (ngModelChange)="weight_height($event)" aria-label="Default" aria-describedby="inputGroup-sizing-sm" maxlength="3"> <span class="unittxt">Kg</span></div>
        </div>
      
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="custominputbox">
            <div class="toptxt" style="width:135px; margin-left: 5px;">Arm Circumference</div>
            <div>
                <!-- <input class="inputstyle" type="number" maxlength="3" [(ngModel)]="vitals.arm_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="arm_circumference" (keypress)="weightChange($event)" maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
        </div>

    </div>
</div>

<div class="row mt-1" style="padding:10px;">
    <div class="col">
        <div class="custominputbox">
            <div class="toptxt">Height</div>
            <div>
                <!-- <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.height" (input)="heightChange()" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="height" (ngModelChange)="weight_height($event)"  maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
        </div>
        
    </div>
    <div class="col" style="margin-top: 20px;">
        <div class="custominputbox">
            <div class="toptxt" style="width:142px">Head Circumference</div>
            <div>
                <!-- <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.head_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="head_circumference" (keypress)="weightChange($event)"  maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
        </div>

    </div>
</div>

<div class="row mt-1" style="padding:10px">
    <div class="col">
        <div class="custominputbox">
            <div class="toptxt" style="width:40px">BMI</div>
            <div>
                <!-- <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.bmi" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt"></span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="bmi" (keypress)="weightChange($event)" maxlength="3"  aria-label="Default" aria-describedby="inputGroup-sizing-sm" disabled> <span class="unittxt"></span></div>
        </div>
       
    </div>
    <div class="col" style="margin-top: 20px;">
        <div class="custominputbox">
            <div class="toptxt" style="width:142px">Chest Circumference</div>
            <div>
                <!-- <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.chest_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="chest_circumference" (keypress)="weightChange($event)" maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
        </div>
    </div>
</div>

<div class="row mt-1" style="padding:10px">
    <div class="col">
        <div class="custominputbox">
            <div class="toptxt" style="width:40px">BSA</div>
            <div>
                <!-- <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.bsa" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt"></span></div> -->
                <input class="inputstyle" type="number" [(ngModel)]="bsa"  maxlength="3" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt"></span></div>
        </div>
    </div>
    <div class="col">
        
    </div>
</div>

</div>

<div class="row" style="margin-top: 12px;">
    <div class="col-3"></div>
    <div class="col-3">
        <button class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
    <div class="col-3">
        <button class="btn btn-primary" (click)="postVitalsApi()"> Update</button>
    </div>
    <div class="col-3"></div>
</div>

</div>