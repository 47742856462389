<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-12">
            <h2><img src="./assets/images/Group 2/Group 2.png" class="imgcustom"> <span class="headerClr"> Mobile Access Report</span></h2>
        </div>
    </div>
    <div class="row" style="margin-top:20px">
        <div class="col-md-12">
            <div style="display:flex;float: right;margin-right:10px;">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr>
    <div id="printtable">
        <div class="row">
            <div class="col-md-12">
                <div style="text-align: center;font-weight: bold;"><label><h4>Mobile Access Report</h4></label></div>
            </div>
            <div class="col-md-12">
                <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
            </div>   
        </div>
    <div class="row" style="margin-top:41px;margin-left:250px">
        <div class="panel panel-primary">
            <div class="panel-body">
                <div style="text-align: center;"><span class="alert alert-success">Total Access Given: &nbsp;&nbsp;&nbsp;&nbsp;{{report.ytd}}</span></div>
                <div class="row" style="margin-left:20px">
                    <div class="col col-md-4" style="margin-top: 20px;">
                        <table>
                            <tr>
                                <td class="alert alert-info">January:</td>
                                <td *ngIf="report.monthly.jan!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.jan}}</td>
                                <td *ngIf="report.monthly.jan==null" class="alert alert-danger">0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">February:</td>
                                <td *ngIf="report.monthly.feb!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.feb}}</td>
                                <td *ngIf="report.monthly.feb==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">March:</td>
                                <td *ngIf="report.monthly.mar!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.mar}}</td>
                                <td *ngIf="report.monthly.mar==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">April:</td>
                                <td *ngIf="report.monthly.apr!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.apr}}</td>
                                <td *ngIf="report.monthly.apr==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">May:</td>
                                <td *ngIf="report.monthly.may!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.may}}</td>
                                <td *ngIf="report.monthly.may==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">June:</td>
                                <td *ngIf="report.monthly.jun!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.jun}}</td>
                                <td *ngIf="report.monthly.jun==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col col-md-4" style="margin-top: 20px;margin-left:25px">
                        <table>
                            <tr>
                                <td class="alert alert-info">July:</td>
                                <td *ngIf="report.monthly.jul!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.jul}}</td>
                                <td *ngIf="report.monthly.jul==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">August:</td>
                                <td *ngIf="report.monthly.aug!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.aug}}</td>
                                <td *ngIf="report.monthly.aug==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">September:</td>
                                <td *ngIf="report.monthly.sep!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.sep}}</td>
                                <td *ngIf="report.monthly.sep==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">October:</td>
                                <td *ngIf="report.monthly.oct!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.oct}}</td>
                                <td *ngIf="report.monthly.oct==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">November:</td>
                                <td *ngIf="report.monthly.nov!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.nov}}</td>
                                <td *ngIf="report.monthly.nov==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                            <tr>
                                <td class="alert alert-info">December:</td>
                                <td *ngIf="report.monthly.dec!=null" class="alert alert-success">&nbsp;&nbsp;&nbsp;&nbsp;{{report.monthly.dec}}</td>
                                <td *ngIf="report.monthly.dec==null" class="alert alert-danger">&nbsp;&nbsp;&nbsp;&nbsp;0</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
    
  </div>
</div>

