<div class="comments div1">
    <div class="div1" *ngFor="let visithistoryList of visitHistorydetails;let i=index">

        <div style="display: flex;">
            <div style="width: 80%;">
                <p style="font-size: 0.9rem;margin: 0;">{{visithistoryList.complaintString || 'None'}}</p>

                <p style="color: #A3A3A6;font-size: 0.9rem;margin: 0;">{{visithistoryList.date | date: 'dd-MMM-yyyy'}}</p>
            </div>

            <div style="width: 15%;margin-left: 5%;">
                <p style="color: #FFF;background-color: #01C034;padding: 2px;font-size: 0.8rem;text-align: center;cursor: pointer;"
                    (click)="view(i)">View</p>
            </div>
        </div>
    </div>