<div style="display: flex;flex-wrap: wrap;">
    <p *ngFor="let f of freq_arr" class="tag" [class.selected]="f==value" (click)="set(f)">{{f}}</p>
</div>

<div>
    <input [(ngModel)]="valueInput" (input)="input()" placeholder="Type frequency" style="width: 100%;margin-top: 1rem;height:2rem;margin-left: 0.5rem;margin-right: 0.5rem;">
</div>

<div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
    <button class="btn-primary btn" (click)="save()"  [disabled]="value==''"  style="width: fit-content;margin-right: 0.5rem;">Save</button>
    <button class="btn-primary btn" (click)="cancel()"  style="width: fit-content;">Cancel</button>

</div>
