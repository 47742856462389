
<div class="row" style="display: flex;justify-content: space-between;">
    <div>
        <span><img class="mute" data-toggle="tooltip" data-placement="top" title="Vitals" style="margin-left: 0.5rem;" src="assets/images/vitals.svg">&nbsp;&nbsp;<span style="font-size:20px;font-weight:bold;">Vitals</span></span>

    </div>

    <div>
        <div (click)="changeView(1)" class="btn" [class.ok]="view==1" [class.ok1]="view==1"  style="color: #2e6da4;">View Today Entries</div>
        <div (click)="changeView(2)" [class.ok1]="view==2"  style="margin-left: 0.5rem;color: #2e6da4;"  [class.ok]="view==2" class="btn">Update Vitals</div>

    </div>
</div>
<br>

<p  *ngIf="view==1&&entryLength==0" style="text-align: center;font-size: 1rem;width: 100%;min-height: 50vh;">No entries found for today</p>


<div class="row" style="height: 98%;overflow: auto;min-height: 50vh;" *ngIf="view==1&&entryLength>0">

        <div class="col-8" style="border-radius: 5px;">
            <div class="p-0 m-0 heading_top">
                <div class="head_top">&nbsp;<img src="assets/images/growth.svg" height="20"/>&nbsp; Growth</div>
        
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                                <td class="tableheader">Weight</td>
                                <td class="pl-3 ml-3">{{vitals.weight}}</td>
                                <td class="pl-3 ml-3">Kg</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                               <td class="tableheader">Arm Circumference</td>
                                <td class="pl-3 ml-3">{{vitals.arm_circumference}}</td>
                                <td class="pl-3 ml-3">CM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div class="row mt-1" style="padding:10px;">
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                                <td class="tableheader">Height</td>
                                <td class="pl-3 ml-3">{{vitals.height * 100 | number:'1.2-2'}}</td>
                                <td class="pl-3 ml-3">CM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                               <td class="tableheader">Head Circumference</td>
                                <td class="pl-3 ml-3">{{vitals.head_circumference}}</td>
                                <td class="pl-3 ml-3">CM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                                <td class="tableheader">BMI</td>
                                <td class="pl-3 ml-3">{{vitals.bmi}}</td>
                                <td class="pl-3 ml-3"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                               <td class="tableheader">Chest Circumference</td>
                                <td class="pl-3 ml-3">{{vitals.chest_circumference}}</td>
                                <td class="pl-3 ml-3">CM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                                <td class="tableheader">BSA</td>
                                <td class="pl-3 ml-3">{{vitals.bsa}}</td>
                                <td class="pl-3 ml-3"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    
                </div>
            </div>
    
            </div>
    
          
            <div class="row p-0 m-0">
                <div class="col p-0 mt-2 heading_top" style="margin-right:10px;margin-top: 15px !important;">
                    <div class="head_top col" style="margin-right:5px !important">&nbsp;<img src="assets/images/heartrate.svg" height="20"/>&nbsp; Heart Rate</div>
                    <div class="row mt-1" style="padding:10px">
                        <div class="col">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="tableheader">HR</td>
                                        <td class="pl-3 ml-3">{{vitals.heart_rate}}</td>
                                        <td class="pl-3 ml-3">BPM</td>
                                    </tr>
    
    
                                </tbody>
                            </table>
    
                            <p *ngIf="!HRValid">Heart rate can not be above 100</p>
    
                        </div>
                    </div>
                </div>
                
                <div class="col p-0 mt-2 heading_top" style="margin-left:10px;margin-top: 15px !important;">
                    <div class="head_top col">&nbsp;<img src="assets/images/respiratoryrate.svg" height="20"/>&nbsp; Respiratory Rate</div>    
                    <div class="row mt-1" style="padding:10px">
                        <div class="col">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="tableheader">RR</td>
                                        <td class="pl-3 ml-3">{{vitals.respiratory_rate}}</td>
                                        <td class="pl-3 ml-3">RPM</td>
                                    </tr>
    
    
                                </tbody>
                            </table>
    
                            <p *ngIf="!RRValid">Respiratory rate can not be above 60</p>
    
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="p-0 mt-2 heading_top" style="margin-top: 15px !important;">
                <div class="head_top">&nbsp;<img src="assets/images/glucometer.svg" height="20"/>&nbsp; Gulcometer</div>
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <div class="row m-0 p-0">
                           
    
                        </div>
                        <div class="row">

                        </div>
                        
                       
                        <table style="margin-top: 7px;">
                            <tbody>
                                <tr>
                                    <td class="tableheader">Gulcometer</td>
                                    <td class="pl-3 ml-3">{{gulcometer_mmol}}</td>
                                    <td class="pl-3 ml-3">mg/dl</td>
                                </tr>
                            </tbody>
                        </table>
        
                        <table style="margin-top: 7px;">
                            <tbody>
                                <tr>
                                    <td class="tableheader">Gulcometer</td>
                                    <td class="pl-3 ml-3">{{gulcometer_mgdl}}</td>
                                    <td class="pl-3 ml-3"> mmol/l</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

            </div>
            
        </div>
        <div class="col-4" style="border-radius: 5px;">
    
            <div class="p-0 m-0 heading_top">
                <div class="head_top">&nbsp;<img src="assets/images/temperature.svg" height="20"/>&nbsp; Temperature</div>
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="tableheader">Temperature</td>
                                    <td class="pl-3 ml-3">{{vitals.temperature}}</td>
                                    <td class="pl-3 ml-3">°F</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <table>
                            <tbody>
                                <tr>
                                   <td class="tableheader">Temperature</td>
                                   <td class="pl-3 ml-3">{{tempC}}</td>
                                   <td class="pl-3 ml-3">°F</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
    
            </div>
            
            <div class="p-0 mt-2 heading_top" style="margin-top: 15px !important;">
                <div class="head_top">&nbsp;<img src="assets/images/bloodpressure.svg" height="20"/>&nbsp; Blood Pressure</div>
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="tableheader">Systolic</td>
                                    <td class="pl-3 ml-3">{{vitals.bp_systolic}}</td>
                                    <td class="pl-3 ml-3">mmHg</td>
                                </tr>
                            </tbody>
                        </table>
    
                        <!-- <p *ngIf="!systolicValid">Systolic blood pressure can not be lesser than Disatolic blood pressure</p> -->
    
                    </div>
                </div>
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="tableheader">Disatolic</td>
                                    <td class="pl-3 ml-3">{{vitals.bp_diastolic}}</td>
                                    <td class="pl-3 ml-3">mmHg</td>
                                </tr>
                            </tbody>
                        </table>
    
                        <!-- <p *ngIf="!diastolicValid">Systolic blood pressure can not be lesser than Disatolic blood pressure</p> -->
    
                    </div>
                </div>
            </div>
            
            <div class="p-0 mt-2 heading_top" style="margin-top: 15px !important;">
                <div class="head_top">&nbsp;<img src="assets/images/spo2.svg" height="20"/>&nbsp; Spo2</div>
                <div class="row mt-1" style="padding:10px">
                    <div class="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="tableheader">spo2</td>
                                    <td class="pl-3 ml-3">{{vitals.oxygen_saturation}}</td>
                                    <td class="pl-3 ml-3">%</td>
                                </tr>
    
                            </tbody>
                        </table>
    
                        <!-- <p *ngIf="!spo2Valid">spo2 can not be above 100</p> -->
    
                    </div>
                </div>
               
    
            </div>
            
        </div>
</div>

<div class="row" style="height: 98%;overflow: auto" *ngIf="view==2">
    <div class="col-12" style="border-radius: 5px;">
        <div class="p-0 m-0 heading_top">
            <div class="head_top">&nbsp;<img src="assets/images/growth.svg" height="20"/>&nbsp; Growth</div>
    
        <div class="row mt-1" style="padding:10px">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="custominputbox">
                    <div class="toptxt">Weight<span style="color:red">*</span></div>
                    <div>
                        <input class="inputstyle" type="number" maxlength="3" [(ngModel)]="vitals.weight" (input)="weightChange()" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">Kg</span></div>
                </div>
              
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="custominputbox">
                    <div class="toptxt" style="width:135px; margin-left: 5px;">Arm Circumference</div>
                    <div>
                        <input class="inputstyle" type="number" maxlength="3" [(ngModel)]="vitals.arm_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
                </div>

            </div>
        </div>

        <div class="row mt-1" style="padding:10px;">
            <div class="col">
                <div class="custominputbox">
                    <div class="toptxt">Height</div>
                    <div>
                        <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="getHeight" (input)="heightChange()" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
                </div>
                
            </div>
            <div class="col" style="margin-top: 20px;">
                <div class="custominputbox">
                    <div class="toptxt" style="width:142px">Head Circumference</div>
                    <div>
                        <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.head_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
                </div>

            </div>
        </div>

        <div class="row mt-1" style="padding:10px">
            <div class="col">
                <div class="custominputbox">
                    <div class="toptxt" style="width:40px">BMI</div>
                    <div>
                        <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.bmi" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt"></span></div>
                </div>
               
            </div>
            <div class="col" style="margin-top: 20px;">
                <div class="custominputbox">
                    <div class="toptxt" style="width:142px">Chest Circumference</div>
                    <div>
                        <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.chest_circumference" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">cm</span></div>
                </div>
            </div>
        </div>

        <div class="row mt-1" style="padding:10px">
            <div class="col">
                <div class="custominputbox">
                    <div class="toptxt" style="width:40px">BSA</div>
                    <div>
                        <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="vitals.bsa" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt"></span></div>
                </div>
            </div>
            <div class="col">
                
            </div>
        </div>

        </div>

        <div class="row p-0 m-0" style="height: 158px;">
            <div class="col p-0 mt-2 heading_top" style="margin-right:10px;margin-top: 15px !important;">
                <div class="head_top col" style="margin-right:5px !important">&nbsp;<img src="assets/images/heartrate.svg" height="20"/>&nbsp; Heart Rate</div>
                <div class="row" style="padding:10px;margin-top: 18px;">
                    <div class="col">
                        <div class="custominputbox">
                            <div class="toptxt" style="width:35px">HR</div>
                            <div>
                                <input class="inputstyle" type="number"  maxlength="3" (input)="HRChange()" [(ngModel)]="vitals.heart_rate" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">BPM</span></div>
                        </div>
                       

                        <p *ngIf="!HRValid">Heart rate can not be above 100</p>

                    </div>
                </div>
            </div>
            
            <div class="col p-0 mt-2 heading_top" style="margin-left:10px;margin-top: 15px !important;">
                <div class="head_top col">&nbsp;<img src="assets/images/respiratoryrate.svg" height="20"/>&nbsp; Respiratory Rate</div>    
                <div class="row" style="padding:10px;margin-top: 18px;">
                    <div class="col">
                        <div class="custominputbox">
                            <div class="toptxt" style="width:35px">RR</div>
                            <div>
                                <input class="inputstyle" type="number"  maxlength="3" (input)="RRChange()" [(ngModel)]="vitals.respiratory_rate" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">RPM</span></div>
                        </div>
                       

                        <p *ngIf="!RRValid">Respiratory rate can not be above 60</p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12" style="border-radius: 5px;margin-top: 15px;">

        <div class="p-0 m-0 heading_top">
            <div class="head_top">&nbsp;<img src="assets/images/temperature.svg" height="20"/>&nbsp; Temperature</div>
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <div class="custominputbox">
                        <div class="toptxt" style="width:95px">Temperature</div>
                        <div>
                            <input class="inputstyle" type="number"  maxlength="3" (input)="tempChange()" [(ngModel)]="vitals.temperature" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">°F</span></div>
                    </div>
                    
                </div>
            </div>
            
            <div class="row mt-0" style="padding:10px">
                <div class="col">
                    <table>
                        <tbody>
                            <tr>
                               <td class="tableheader">Temperature</td>
                               <td class="pl-3 ml-3 unitclr">{{tempC}} °C</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            

        </div>
        
        <div class="p-0 mt-2 heading_top" style="margin-top: 15px !important;">
            <div class="head_top">&nbsp;<img src="assets/images/bloodpressure.svg" height="20"/>&nbsp; Blood Pressure</div>
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <div class="custominputbox">
                        <div class="toptxt" style="width:65px">Systolic</div>
                        <div>
                            <input class="inputstyle" type="number"  maxlength="3" (input)="systolicChange()" [(ngModel)]="vitals.bp_systolic" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">mmHg</span></div>
                    </div>
                    

                    <p *ngIf="!systolicValid">Systolic blood pressure can not be lesser than Disatolic blood pressure</p>

                </div>
            </div>
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <div class="custominputbox">
                        <div class="toptxt" style="width:65px">Disatolic</div>
                        <div>
                            <input class="inputstyle" type="number"  maxlength="3" (input)="diastolicChange()" [(ngModel)]="vitals.bp_diastolic" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">mmHg</span></div>
                    </div>
                    

                    <p *ngIf="!diastolicValid">Systolic blood pressure can not be lesser than Disatolic blood pressure</p>

                </div>
            </div>
        </div>
        
        <div class="p-0 mt-2 heading_top" style="margin-top: 15px !important;">
            <div class="head_top">&nbsp;<img src="assets/images/spo2.svg" height="20"/>&nbsp; Spo2</div>
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <div class="custominputbox">
                        <div class="toptxt" style="width:45px">spo2</div>
                        <div>
                            <input class="inputstyle" type="number"  maxlength="3" (input)="spo2Change()" (keypress)="omit_special_char($event)" [(ngModel)]="vitals.oxygen_saturation" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">%</span></div>
                    </div>
                   

                    <p *ngIf="!spo2Valid">spo2 can not be above 100</p>

                </div>
            </div>
           

        </div>
        
    </div>

    <div class="col-12" style="margin-top: 15px !important;">
       <div class="p-0 m-0 heading_top">
            <div class="head_top">&nbsp;<img src="assets/images/glucometer.svg" height="20"/>&nbsp; Gulcometer</div>
            <div class="row mt-1" style="padding:10px">
                <div class="col">
                    <div class="row m-0 p-0">
                        <mat-radio-group aria-label="Select an option" [color]="'primary'" [(ngModel)]="gulcovalue">
                            <mat-radio-button value="Fasting" (click)="getGulcoVal('Fasting')">Fasting</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Post Break Fast" (click)="getGulcoVal('Post Break Fast')">Post Break Fast</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Pre Lunch" (click)="getGulcoVal('Pre Lunch')">Pre Lunch</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Post Lunch" (click)="getGulcoVal('Post Lunch')">Post Lunch</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Pre Dinner" (click)="getGulcoVal('Pre Dinner')">Pre Dinner</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Post Dinner" (click)="getGulcoVal('Post Dinner')">Post Dinner</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="Random" (click)="getGulcoVal('Random')">Random</mat-radio-button>
                        </mat-radio-group>

                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-5">

                            <mat-form-field class="field" appearance="outline">
                                <!-- <mat-label>Choose a date</mat-label> -->
                                <input class="formBorder" matInput [max]="maxDate"  [ngModel]="gulco_date | date:'yyyy-MM-dd'"
                                    [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        
                    <div class="col-md-2">
                        <div class="custominputbox">
                            <div class="toptxt" style="width:45px; margin-left: 5px;">Hours</div>
                            <div>
                                <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="hours" id="tentacles" name="tentacles" min="0" max="23"> <span class="unittxt"></span></div>
                        </div>
                        
                    </div>
                    <div class="col-md-2">
                        <div class="custominputbox">
                            <div class="toptxt" style="width:45px; margin-left: 5px;">Mins</div>
                            <div>
                                <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="min" id="tentacles" name="tentacles" min="0" max="59"> <span class="unittxt"></span></div>
                        </div>
                    </div>
                    </div>

                    <div class="row" style="margin-top:20px;padding:10px">
                        <div class="col">
                            <div class="custominputbox">
                                <div class="toptxt" style="width:90px">Gulcometer</div>
                                <div>
                                    <input class="inputstyle" type="number"  maxlength="3" [(ngModel)]="gulcometer_mmol" (input)="tommol()" aria-label="Default" aria-describedby="inputGroup-sizing-sm"> <span class="unittxt">mg/dl</span></div>
                            </div>
                        </div>
                        <div class="col">
                            <table style="margin-top: 10px;">
                                <tbody>
                                    <tr>
                                        <td class="tableheader">Gulcometer</td>
                                        <td class="pl-3 ml-3 unitclr">{{gulcometer_mgdl}} mmol/l</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    
                
                </div>
                
            </div>
            <div class="row" style="padding:10px">
                <div class="col-lg-6 col-md-6">
                <div>
                        <span>Pills</span>
                    </div> 
                    <div style="display: flex;float: right;margin-top: -18px;">
                        <div><i class="fa fa-minus-circle" aria-hidden="true" (click)="removepills()" style="cursor:pointer;"></i></div>
                        <div style="margin-left:10px"><i class="fa fa-plus-circle" (click)="addpills()" aria-hidden="true" style="cursor:pointer;"></i></div>
                    </div>

                    <div class="row" *ngFor="let pillsval of pills" style="margin-top:10px">
                        <div class="col" style="margin-top: 10px;">
                            <div class="custominputbox">
                                <div class="toptxt" style="width:55px">Name</div>
                                <div>
                                    <input class="inputstyle" type="number"  maxlength="3" name="Respiratory" min="0" max="100" [(ngModel)]="pillsval.name" style="margin-left: 10px;" 
                                    onkeypress="return(event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"> <span class="unittxt"></span></div>
                            </div>
                        </div>
                        <div class="col" style="margin-top: 10px;">
                            <div class="custominputbox">
                                <div class="toptxt" style="width:70px">Strength</div>
                                <div>
                                    <input class="inputstyle" type="number"  maxlength="3" name="Respiratory" min="0" max="100" [(ngModel)]="pillsval.strength" style="margin-left: 30px;" numbers-only> <span class="unittxt">mg</span></div>
                            </div>
                        </div>
                      
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div>
                        <span>Injection</span>
                    </div> 
                    <div style="display: flex;float: right;margin-top: -18px;">
                        <div><i class="fa fa-minus-circle" aria-hidden="true" (click)="removeinj()" style="cursor:pointer;"></i></div> 
                        <div style="margin-left:10px"><i class="fa fa-plus-circle" (click)="addinj()" aria-hidden="true" style="cursor:pointer;"></i></div>
                    </div>
                    <div class="row" *ngFor="let inj of injication" style="margin-top:10px">
                        <div class="col" style="margin-top: 10px;">
                            <div class="custominputbox">
                                <div class="toptxt" style="width:55px">Name</div>
                                <div>
                                    <input class="inputstyle" type="number"  maxlength="3" name="Respiratory" min="0" max="100" [(ngModel)]="inj.name" style="margin-left: 10px;" 
                                    onkeypress="return(event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"> <span class="unittxt"></span></div>
                            </div>
                        </div>
                        <div class="col" style="margin-top: 10px;">
                            <div class="custominputbox">
                                <div class="toptxt" style="width:70px">Strength</div>
                                <div>
                                    <input class="inputstyle" type="number"  maxlength="3" name="Respiratory" min="0" max="100" [(ngModel)]="inj.strength" style="margin-left: 30px;" numbers-only> <span class="unittxt">mg</span></div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    
</div>

<div class="row" style="margin-top: 10px;margin-bottom: 45px;">
    <div class="col"></div>
    <div class="col"></div>
    <div class="col"></div>
    <div class="col">
        <div style="display: flex;justify-content: space-around;">
          <button (click)="close()" class="btn cancel">Cancel</button>
          <button (click)="updateVital()" [disabled]="!valid" *ngIf="view==2" class="btn ok2" style="margin-left: 0.5rem;">Update</button>
        </div>
    </div>
</div>

