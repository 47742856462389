import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';

export interface DialogData_full {
  data: any,
}

@Component({
  selector: 'app-past-prescriptions',
  templateUrl: './past-prescriptions.component.html',
  styleUrls: ['./past-prescriptions.component.scss']
})
export class PastPrescriptionsComponent implements OnInit {

  urlPoints: any;
  config = AppConfig.settings;
  constants = AppConfig.constants;
  @Input() dmail;
  @Input() token;
  @Input() email;
  visitHistorydetails;
  complaints = []
  constructor(private appService: AppService,  public dialog: MatDialog) { 
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {


    let params = {
      account_email: this.dmail,
      patient_email: this.email
    }
    this.appService.getData(this.urlPoints.simplevisithistory, params, this.token).subscribe((response: any) => {

      this.visitHistorydetails = response.visit_history;
      
      for(var i=0;i<this.visitHistorydetails.length;i++)
      {
        if(this.visitHistorydetails[i].complaint)
        for(var j=0;j<this.visitHistorydetails[i].complaint.length;j++)
        {

          if( !this.complaints.includes(this.visitHistorydetails[i].complaint[j].complaint_category))
          this.complaints.push(this.visitHistorydetails[i].complaint[j].complaint_category)
        }

        this.visitHistorydetails[i].complaintString=this.complaints.join(', ')
        this.complaints = []
      }


    });

  }

  view(i)
  {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_full, {
      width: '800px',
      data: { data: this.visitHistorydetails[i] }
    });
  }

}


@Component({
  selector: 'full-prescription',
  templateUrl: 'full-prescription.html',
  styleUrls: ['full-prescription.scss']
})

export class DialogOverviewExampleDialog_full {

  diagnosis;
  diagnosisString;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_full>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_full, public router: Router) 
    {}

  ngOnInit(): void {

    this.diagnosis=this.data.data.diagnosis.split('\n')


    this.diagnosisString = this.diagnosis.join(' ,')

    this.data.data.date=moment(this.data.data.date).format('DD-MM-YYYY')
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}