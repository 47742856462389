<div><button class="close" mat-button (click)="onNoClick()">X</button></div>
<div class="row">
  <div class="col-lg-4"></div>
  <div class="col-lg-4" style="margin-bottom: auto;color: black;font-size: larger;text-align: center;padding: 28px">
    <span style="font-weight: bolder;font-family: Poppins;">{{patientprofilename}}'s Gallery</span></div>
  <div class="col-lg-4" style="margin-bottom: auto;">
  </div>
</div>

<div class="container" *ngIf="nomilestonepic == false" style="margin-top: 25px;margin-bottom: 30px;">
  <main class="grid">
    <article style="text-align: center;border-radius: 6px;height: 283px;position: relative;"
      *ngFor="let item of viewmilestonepicdetailsnew; index as i">

      <!-- <img src="assets/images/milestonepicdelete.svg" style="position: absolute;right: 10px;top: 8px;cursor: pointer;"
        (click)="deletebabypic(item.id)"> -->

      <img [src]="item.image1" style="height: 227px;width: auto;">
      <div class="row text" style="text-align: left;">
        <div class="col-lg-6">
          <p style="font-size: 13px;float: left;"><span><img src="assets/images/milestonepopupdate.svg"
                style="margin-top: -3px;"></span>&nbsp;&nbsp;{{item.date}}</p>
        </div>
        <div class="col-lg-6">
          <p style="font-size: 13px;float: right;"><span><img src="assets/images/milestonepopuptime.svg"
                style="margin-top: -3px;"></span>&nbsp;&nbsp;{{item.time}}</p>
        </div>
        <!-- <div class="col-lg-5">
                     <button>jn</button>
                 </div> -->

      </div>
    </article>
    <!-- <article style="text-align: center;">
                <img src="https://i.pinimg.com/736x/f3/10/0c/f3100c7d165dba833efa140bc7e9f73c.jpg" height="280px" width="300px" alt="tshirt photo">
                <div class="text">
                  <h3>Police Unit K-9</h3>
                  <p></p>
                
                </div>
              </article> -->

  </main>
</div>
<div class="container" *ngIf="nomilestonepic == true">
  <div class="row">
    <div class="col-lg-12" style="text-align: center;
    color: red;
    padding: 20px 20px 50px 20px;
    font-size: 16px;">
      <span>Milestone Image not uploaded</span>
    </div>
  </div>
</div>