<div class="d-flex" id="wrapper">
    <!-- Left Sidebar -->
    
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
          <h2 style="margin-top:10px">Version Release Notes</h2>
          
        </div>
        <!-- <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item active" style="margin-right: 1rem;">
            <button class="btn btn-primary" id="menu-toggle" (click)="logout()">Logout</button>
          </li>
        </ul> -->
      </nav>
        <!-- /#footer -->
    </div>
</div>

<!-- <table style="border: #0000 1px solid;width: 100%;">
    <tr>
        <th>App Name</th>
        <th>package Name</th>
        <th>Release Date</th>
        <th>Release Notes</th>
        <th>Version</th>
    </tr>
    <tr *ngFor="let data of releaseNotesArray">
        <th>{{data.app_name | titlecase}}</th>
        <th>{{data.package_name}}</th>
        <th>{{data.release_date | date: 'dd/MM/yyyy'}}</th>
        <th>{{data.release_notes | titlecase}}</th>
        <th>{{data.version }}</th>
    </tr>
</table> -->
<ul *ngFor="let data of releaseNotesArray">
    <span><span style="font-size: 20px;font-weight: bold;">Version</span> - {{data.version}}</span>
    <li>{{data.app_name | titlecase}}</li>
    <li>{{data.package_name}}</li>
    <li>{{data.release_date | date: 'dd/MM/yyyy'}}</li>
    <li>{{data.version}}</li>
    <li><a [attr.href]="data.image" target="_blank">{{data.image}}</a></li>
    <li><a href="{{data.video}}" target="_blank">{{data.video}}</a></li>
    <span style="font-size: 15px;font-weight: bold;">Release Notes</span>
    <li>{{data.release_notes}}</li>
</ul>
