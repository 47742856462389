import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class StoredService {
  isPrinting = false;
  urlPoints: any;
  params;
  vaccineparams;

  authentication = {
    isAuth: false,
    token:"",
    account_id: "",
    account_info: {},
    docRecord_id: "",
    doctor_id: "",   
    doctor_name: "",
    doctor_image: "",  
    doctor_cover_image: "",
    doctor_degree: "",
    doctor_speciality: "",
    hospital_name: "",
    doctor_mobile: "",
    doctor_speciality_type:''
}; 
  
  
  constructor(private appService: AppService, private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  storedAuthdetails(authdetails) {
    
    this.authentication = {
      isAuth: true,
      token: authdetails.info.token,
      account_id: authdetails.info.account_id,
      account_info: authdetails.info.account_info,
      docRecord_id: authdetails.info.record_id,
      doctor_id: authdetails.doctor.doctor_id,   
      doctor_name: authdetails.doctor.display_name,
      doctor_image: authdetails.doctor.avatar,  
      doctor_cover_image: authdetails.doctor.cover_art,
      doctor_degree: authdetails.doctor.degree,
      doctor_speciality: authdetails.doctor.speciality,
      doctor_speciality_type: authdetails.doctor.speciality_type,
      hospital_name: authdetails.doctor.hospital_name,
      doctor_mobile: authdetails.doctor.mobile
  }; 
  sessionStorage.setItem("authDetails",JSON.stringify(this.authentication));
  sessionStorage.setItem('consultation','false')
}

 getAuthdetails(){
  return this.authentication;
 }






 
}
