
<div class="row">
    <p class="col-lg-11 col-md-11"></p>
    <img class="col-lg-1 col-md-1 img" style="cursor: pointer;" (click)="close()" src="assets/cancel.svg">
</div>

<p>Selected template: {{selectedPrintTemplateName}}</p>

<div style="display: flex;flex-wrap: wrap;">
    <p  *ngFor="let t of data.temps"  (click)="set(t)" [class.selected]="selectedPrintTemplate==t.template_id" class="tag">{{t.template_name}}</p>
</div>

<p style="color: #ff0000;" *ngIf="submitted&&selectedPrintTemplate == ''">Please select a template</p>

<div style="display: flex;flex-direction: row;justify-content: space-around;margin-top: 1rem;">
    <button class="btn btn-success" *ngIf="!data.mode" (click)="save()">Print & Send SMS</button>
    <button class="btn btn-success" (click)="save2()">Print</button>

    <button class="btn btn-success" *ngIf="!data.mode" (click)="save1()">Send SMS</button>
</div>