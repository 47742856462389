import { Component,AfterViewChecked, OnInit,ElementRef,ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpEvent,HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { Toast, ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  image: string,
  status: number
}

@Component({
  selector: 'app-appt-chatmsges',
  templateUrl: './appt-chatmsges.component.html',
  styleUrls: ['./appt-chatmsges.component.scss'],
  providers: [PubNubAngular]

})
export class ApptChatmsgesComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  routeURL:any;
  getaccount_email:any;
  getdrID:number;
  getauthToken:any;
  getdrName:string;
 // setaccount_email:any;
  // setdrID:any;
  // setdrName:any;
  channel:any;
  uuid:any;
  pubnub: PubNubAngular;
  param:any;
  

  chatParam;
  chatList;
  chatId = '';
  unrespond = 0;
  unread = 0;
  urlPoints;
  setauthToken;
  select_patient_name:string;
  chathistoryList = [];
  selectedPatient:any; 
  selectedPatientfname:string;
  selectedPatientlaname:string;
  selectedPatientGender:string;
  selectedPatientMrNum:any;
  selectedPatientdob:any;
  selectedPatientuhid:any;
  selectedPatientemail:any;
  selectedPatientphone:number;
  selectedPatientpayment:any;
  selectedpatientImage:any;
  messageContent = {message: ''};
  searchContent = {searchTxt: ''};
  chatMessages = [];
  imgArray = [];
  vdoArray = [];
  imageUploadUrl:any;
  vhnimageUploadUrl;
  uploadImageFile;
  msg: any;
  doctor_charge = 0;
  refundOption = false;
  loaded2 = false;
  progressStatus =false;
  progress: number;
  sender_details;
  sender_mail;
  sender_image;
  senderName;
  senderMRN;
  @Input() senderDetails:any;
  @Input() sendermail:any;
  @Input() senderImage:any;
  @Input() senderData:any;
  @Input() setaccount_email:any;
  @Input() id:any;
  @Input() setdrName:any;
  @Input() setdrID:any;

  @Output() someEvent_close = new EventEmitter<any>();

 // senderData;

  constructor(private router:Router,private route: ActivatedRoute, public dialog: MatDialog,  pubnub: PubNubAngular,private toastr: ToastrService,  private appService: AppService) 
  {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/vhn/upload/';
  }

  ngOnInit(): void {
  
    this.setauthToken = localStorage.getItem('TOKEN');

    this.sender_details = localStorage.getItem('sender_details');
    
    this.sender_details = JSON.parse(this.sender_details);
        
    this.sender_mail = localStorage.getItem('sender_mail');
    this.sender_image = localStorage.getItem('sender_image');

    this.channel = this.setaccount_email.replace('@','_').replace('.org', '');
    this.channel = this.channel + '_' + this.id

    this.uuid = this.setaccount_email;

    this.autoRefresh(this.channel);
    this.getMessages(this.sender_details, this.sender_mail, this.sender_image);

    this.refundOptionCheck();
    this.scrollToBottom();
  
  }

  ngOnChanges(){
    this.sender_details = this.senderDetails;
    this.sender_details = JSON.parse(this.sender_details);
    this.sender_mail = this.sendermail;
    this.sender_image = this.senderImage;
    this.getMessages(this.sender_details, this.sender_mail, this.sender_image);
    this.refundOptionCheck();
    this.scrollToBottom();

  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

  autoRefresh(channelName:any)
  {
   this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: this.uuid
  });
    this.pubnub.subscribe({
      channels: [channelName],
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channelName, (msg) => {
    //  alert('got message')

      console.log('p2')

      this.msg = msg;
      this.msg = this.msg.message;
      if (this.msg.sender===this.selectedPatientemail){
        this.getMessages(this.msg.message, this.msg.sender,'');
      }
      
    });
    
  }


  getMessages(data,senderMail,patientImage) {
    
    this.selectedpatientImage = patientImage;
 
      if(data.first_name){
        this.select_patient_name = data.first_name;
    } else {
        this.select_patient_name = "Patient"; 
    }

    if(data.email) {
      data.email = data.email;
      //data.payment = this.selectedPatient.payment;
      this.selectedPatient = data;
      this.selectedPatientfname = data.full_name;
      this.selectedPatientemail = data.email;
      this. selectedPatientlaname= data.last_name;
      this.selectedPatientGender= data.gender;
      this.selectedPatientdob= data.dob;
      this.selectedPatientMrNum= data.mr_number;
      this.selectedPatientuhid= data.uhid;
      this.selectedPatientphone= data.phone;
      this.selectedPatientpayment= data.payment;
      }

      this.chatParam = {
        account_email: this.setaccount_email,
        appointment_id: this.id,
      }

      this.appService.getData(this.urlPoints.getApptChat, this.chatParam, this.setauthToken).subscribe((response: any) => {
         this.chathistoryList = response.data;
        
        this.chatMessages = this.chathistoryList.map(res => {

          var Utc = moment.utc(res.date).toDate();
          var local = moment(res.created).add(5, 'hours').add(30, 'minutes').format('DD-MMM-YYYY hh:mm a');
          res.messaged_date = local;

          res.message = decodeURIComponent(res.message);
          if (res.message.endsWith(".png") || res.message.endsWith(",")) {
            if (res.message.startsWith("img_")) {

                var temp = res.message.split(",")
                this.imgArray = [];
                temp.map(imgres => {
                  if(imgres != ""){
                      this.imgArray.push( this.imageUploadUrl + imgres);
                    }
                 });
                res.imagemessage = this.imgArray;
            } else {
                var temp = res.message.split(",")
                this.imgArray = [];
                temp.map(imgres => {
                  this.imgArray.push( this.vhnimageUploadUrl + imgres.replace(" ",""));
                 });
                res.imagemessage = this.imgArray;
            }
        }

        if (res.message.endsWith(".mp4")) {
            if(res.message.startsWith("vid_")) {  // equals to if(res.message_type == 0){}
            
                var temp = res.message.split(",")
                this.vdoArray = [];
                temp.map(vidres => {
                  this.vdoArray.push( this.imageUploadUrl+ vidres );
                 });
               res.videomessage = this.vdoArray;
            } else {
                var temp = res.message.split(",")
                this.vdoArray = [];
                temp.map(vidres => {
                  this.vdoArray.push( this.imageUploadUrl + "/VHN/upload/" + vidres.replace(" ",""));
                 });
                res.videomessage = this.vdoArray;
            }
        }
        
        if(res.message.startsWith("http://220.226.2.177")) {
            res.audiomessage = res.message;
        }

        if(res.message.endsWith(".pdf")) {
            res.pdfmessage = environment.HOST + '/helyxonapi/cloud/pdf/upload/' + res.message;

        }

        });

       console.log(this.chathistoryList)
      })

  }

  
  openPDF(pdf)
  {
    window.open(pdf)
  }

  uploadAttachment(event){
    
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadImageFile = file;

      console.log(this.uploadImageFile.type)
    
      if(this.uploadImageFile.type == 'image/jpeg' || this.uploadImageFile.type == 'image/png')
      this.uploadImagee();

      if(this.uploadImageFile.type == 'application/pdf')
      this.uploadPDF();
  }

  uploadImagee(){
    this.appService
    .postwithoutPortImgFile(AppConfig.settings.EndPoints.uploadImage, this.uploadImageFile, localStorage.getItem('TOKEN'))
    .subscribe((event: HttpEvent<any>) => { 
      switch (event.type) {
        case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressStatus = true;
            //this.progress = Math.round(event.loaded / event.total * 100);
            this.progress = Math.round((100 / event.total) * event.loaded);
            break;
        case HttpEventType.Response:
          this.messageContent.message = event.body.image[0].filename;
          setTimeout(() => {
            this.progressStatus = false;
            this.progress = 0;
          }, 1500);
        }
        
  })
  }

  uploadPDF(){
    this.appService
    .postwithoutPortPDFFile(AppConfig.settings.EndPoints.uploadPDF, this.uploadImageFile, localStorage.getItem('TOKEN'))
    .subscribe((event: HttpEvent<any>) => { 
      switch (event.type) {
        case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressStatus = true;
            //this.progress = Math.round(event.loaded / event.total * 100);
            this.progress = Math.round((100 / event.total) * event.loaded);
            break;
        case HttpEventType.Response:
          this.messageContent.message = event.body.pdf[0].filename;
          setTimeout(() => {
            this.progressStatus = false;
            this.progress = 0;
          }, 1500);
        }
        
  })
  }

  close()
  {
    this.someEvent_close.next()
  }

  openImg(imgPath,checkstatus){
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_chat1, {
      width: '30%',
      data: { image: imgPath, status:checkstatus},
      panelClass: 'my-dialog',
    });
  
  }

  goback() {
    this.router.navigate(['/chat'], { queryParams: {account_email: this.setaccount_email,doctor_id: this.setdrID,doctor_name: this.setdrName,auth_token: this.setauthToken}});
  }

  refundOptionCheck(){
    this.param = {
      account_email: this.setaccount_email,
      doctor_id: this.setdrID,
    }
    this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
      this.selectedPatient = {payment: false};
      response.map(res => {
          if (res.module == "chat"){
            this.doctor_charge = parseInt(res.doctor_charge);
            this.selectedPatient = {payment: true};
            this.refundOption = true;
          }
      });
    });
  }
  //raise invoice
  raiseInvoice(chatID) {
    this.param = {
      account_email: this.setaccount_email,
      doctor_id: this.setdrID,
    }
    this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
      response.map(res => {
          if (res.module == "chat"){
            this.doctor_charge = parseInt(res.doctor_charge);
          }
      });
    
          var data = {
            account_email: this.setaccount_email,
            patient_email: this.selectedPatientemail,
            category: "chat",
            description: "fee charged for chat",
            doctor_charges: this.doctor_charge,
            user_type: "doctor",
            type: 3,
            appointment_id:"",
            doctor_id: this.setdrID,
            chat_id: chatID
          }
          this.appService.postData(this.urlPoints.raiseinvoice, data, this.setauthToken).subscribe((response: any) => {
            this.toastr.success('Payment raised successfully!');
          });
      
        });
  }
  
  //send message

  sendMessage(){
    
    if (!this.messageContent.message || this.messageContent.message === '') {
      return;
     }

    var data = {
      appointment_id:this.id,
      account_email : this.uuid,
      sender:  this.uuid,
      receiver: this.selectedPatientemail,
      message: this.messageContent.message,
      type: "doctor",
      message_type: "message",
    //  date: new Date(),
    //  payment: this.selectedPatientpayment,
      doctor_name: this.setdrName,
      doctor_id: this.setdrID
  }
  
  this.appService.postData(this.urlPoints.saveApptMessage, data, this.setauthToken).subscribe((response: any) => {
    

      this.getMessages(this.sender_details, this.sender_mail, this.sender_image)

      this.messageContent = {message: ''};
      this.toastr.success('Message sent successfully!');
      
  });

}

keyPress(event) {
  let code = event.keyCode || event.which;
  if (code === 13) {
    event.preventDefault();
    this.sendMessage();
  }
}

refund(data){
      if (data=='admin'){
        this.toastr.error('Contact admin for payment');
        return;
    }
        data.account_email = this.setaccount_email;
        data.patient_email = data.receiver;
        data.created_at = data.date;
        data.type = 2;
        data.category = "refund";

        this.appService.postData(this.urlPoints.waivepayment, data, this.setauthToken).subscribe((response: any) => {
          this.toastr.success(response.message);

          this.param = {
            account_email: this.setaccount_email,
            doctor_id: this.setdrID,
          }
          
          this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
            response.map(res => {
                if (res.module == "chat"){
                  this.doctor_charge = parseInt(res.doctor_charge);
                }
            });
            var raiseData = {
              account_email: this.setaccount_email,
              patient_email: data.receiver,
              category: "refund",
              description: "",
              doctor_charges: this.doctor_charge,
              fee_paid: this.doctor_charge,
              user_type: "doctor",
              type: 2,
              appointment_id:"",
              doctor_id: this.setdrID,
              chat_id: data.id
          }
          
          this.appService.postData(this.urlPoints.chatrefundinvoice, raiseData, this.setauthToken).subscribe((response: any) => {
            if(response){
              this.toastr.success('Raised refund invoice');
              this.getMessages(response,data.receiver,this.selectedpatientImage);
            }
          });
        });
      },err => {
        this.toastr.error('Date Expired.Payment waive off not allowed');
       // check error status code is 500, if so, do some action
      });
}

}

@Component({
  selector: 'chatimage1',
  templateUrl: 'chatimage.html',
  styleUrls: ['chatimage.scss']
})

export class DialogOverviewExampleDialog_chat1 {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_chat1>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router) {

  }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
