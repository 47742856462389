<style>
    .wrapword {
   word-break: break-word;
   white-space: pre-wrap;
   -moz-white-space: pre-wrap;      
}
</style>

<form name="addcprec">
    <div class="row">
        <div class="col-md-12">
            <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
        </div>
        <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
            <table class="table table-bordered">
                <thead style="background-color: #adccff;">
                    <tr>
                        <th class="text-center" style="width: 50px;">S.No</th>
                        <th class="text-center" style="width: 120px;">Date</th>
                        <th class="text-center" style="width: 120px;">Scanned On</th>
                        <th class="text-center" style="width: 360px;">Scan Report Findings</th>
                        <th class="text-center" style="width: 120px;">Next Scan Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" style="border: tomato;" *ngFor="let value of getarchivedcpscan; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{value.date}}</td>
                        <td>{{value.scan_date}}</td>
                        <td class="wrapword">{{value.scan_findings}}</td>
                        <td>{{value.next_scan_date}}</td>
                        
                    </tr>
                </tbody>
            </table>
            <!-- <div class=" text-center"  style="color: red;"  ng-if="showpastcpScandetails == false">No data found</div> -->
        </div>
    </div>
</form>
