<div id="sidebar-wrapper2">
    <div>
        <p id="menu-close1" style="float: right;color: #0063FF;padding: 5px;cursor: pointer;" (click)="closeToggle()">
            Close</p>
    </div>

    <app-past-prescriptions [token]="token" [dmail]="dmail" [email]="email"></app-past-prescriptions>

</div>

<div class="row pofilerow" *ngIf="row==1" id="profileRow">
    <div *ngIf="profile" class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <div style="display: flex;">
            <div>
                <div style="width: 5rem;height: 5rem;position: relative;">
                    <img class="dp"
                        style="width:100%;height: 100%;border-radius: 50%;border: 2px solid #0063FF;padding: 3px 3px 3px 4px;margin-left: 4px;margin-top:3px;position: relative;"
                        [src]="url">

                    <img style="cursor:pointer;width: 1rem;height: 1.5rem;position: absolute;right: 0;bottom: 0;background-color: #fff;"
                        (click)="resend()" *ngIf="mobile_access==true" src="assets/access.svg">
                    <img style="cursor:pointer;width: 1rem;height: 1.5rem;position: absolute;right: 0;bottom: 0;background-color: #fff;"
                        (click)="confirm()" *ngIf="mobile_access==false" src="assets/noaccess.svg">
                </div>

            </div>
            <div style="margin-left: 15px;">
                <div style="width: 100%;margin-left:5px;display: flex;">
                    <p class="last">{{profile.name_given}}</p>
                    <p class="last" style="margin-left:5px;"> <span>{{y}}Y{{m}}M,
                        {{profile.gender}}</span>
                    </p>
                </div>

                <!-- <div style="width: 100%;margin-left:5px">
                    <p class="last"> <span>{{y}}Y{{m}}M,
                            {{profile.gender}}</span>
                    </p>
                </div> -->
                <div style="width: 100%;margin-left:5px;display: flex;">
                    <p class="last"><span>{{profile.mr_number}}</span> <span> |
                        </span><span>{{profile.father_tel_number}}</span></p>

                </div>

                <div style="width: 100%;margin-left:5px;display: flex;">
                    <p class="last"><span>DOB</span> <span> :
                        </span><span>{{dob}}</span></p>

                </div>

                <div style="width: 100%;margin-left:5px;display: flex;" *ngIf="abha">
                    <p class="last"><span>ABHA Number</span> <span style="margin-right: 20px;"> :
                        </span><span>{{abhaDetails.abha_number}}</span></p>

                </div>

                <div>
                    <button class="btn btn-primary" (click)="join()" *ngIf="appt_type.type == 'video_call' && (appt_type.participant_connect_status == 1) && (appt_type.status != 'closed')">Join</button>
                </div>
            </div>
        </div>
    </div>
    <div (click)="docmentApi()" style="display: flex;flex-direction: column;text-align: center;" class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
       
        <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;">
                <img class="vital_icon" src="assets/height1.svg">
                &nbsp;
                <span class="last">{{height | number:'1.2-2'}} cm</span>
            </div>

            <div style="display: flex;">
                <img class="vital_icon" src="assets/weight1.svg">
                &nbsp;
                <span class="last">{{weight}} kg</span>
            </div>

        </div>

        <div *ngIf="speciality==1" style="display: flex;justify-content: space-between;">
            <div style="display: flex;">
                <img class="vital_icon" src="assets/temperature1.svg">
                &nbsp;
                <span class="last">{{temperature}} &#176;C</span>
            </div>

            <div>
                <span class="blue last" style=" color: #0063FF;">BMI :</span>
                &nbsp;
                <span class="last">{{bmi}} kg/m&#178;</span>
            </div>

        </div>
        <div *ngIf="speciality==1" style="display: flex;justify-content: space-between;">
            <div style="display: flex;">
                <p class="last" style="text-align: left;"><span class="blue">BSA :</span> {{bsa}}</p>
            </div>
            <div style="display: flex;">
                <img alt="Head Circumference" class="vital_icon" src="assets/head circumference-01-01.svg">
                &nbsp;
                <span class="last">{{headC}} cm</span>
            </div>  
        </div>

        <p *ngIf="speciality==2&&profile.gender == 'Female'" style="text-align: start;"  class="last">Pregnancy Risk: <span [class.low]="obspregrisksprofile=='low'" [class.medium]="obspregrisksprofile=='medium'" [class.high]="obspregrisksprofile=='high'">{{obspregrisksprofile || 'N/A'}}</span></p>
        <p *ngIf="speciality==2&&profile.gender == 'Female'" style="text-align: start;" class="last">EDD: {{obsteddprofile || 'N/A'}}</p>

    </div>

    <div style="display: flex;flex-direction: column;text-align: center;" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <p  (click)="openDetails()" style=" color: #0063FF;background: #eff5ff;padding: 5px;cursor: pointer;"><i class="fa fa-info-circle"
                aria-hidden="true"></i>
            Patient Info</p>
            <div style="display: flex;flex-wrap: no-wrap;overflow: hidden;">
                <div class="cc_item example z-depth-2 mr-1"
                    style="display: flex; align-items:center; top:5px;border:none;background-color: #eff5ff !important;padding-bottom: 5px;position: relative;color:#0063FF; flex-grow: 1;"
                    *ngFor="let data of elite;let i = index">
                    {{data || 'CARENET'}}
                </div>
                <div style="display: flex; align-items:center; top:5px;border:none;background-color: #eff5ff !important;padding-bottom: 5px;position: relative;color:#0063FF; cursor:pointer; flex-grow: 1;"
                    class="cc_item example z-depth-2 mr-1" (click)="careNet()">...</div>
            </div>
    </div>

    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1" style="display: flex;flex-direction: column;">
        <div style="display: flex;justify-content: center;">
            <img style="cursor:pointer;width: 2rem;height: 2rem;" id="menu-toggle1" (click)="rightToggle()"
                src="assets/past_prescription.svg">
        </div>

        <p class="last" style=" color: #0063FF;text-align: center;font-size: 00.6rem;">Past Consultation</p>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="display: flex;flex-direction: row;justify-content: space-between;">
        <button class="btn btn-primary last" *ngIf="status!='End Consultation' " style="color:#fff" (click)="changeStatus()">
            {{status}}
        </button>

        <div style="border:1px solid #7D7D7D;padding: 5px;" *ngIf="status=='End Consultation' " >
            <div>
                <p style="text-align: center;">END CONSULTATION <countdown format="mm:ss"
                    [config]="{leftTime: 360,format: 'mm:ss'}"></countdown></p>
            </div>

            <div style="display: flex;justify-content: space-between;">

                

                <button class="btn" [class.btn-primary]="!danger" [class.btn-danger]="danger" id="addCharge" (click)="payment()">Additional Charges</button>
                <button class="btn" [class.btn-primary]="!danger" [class.btn-danger]="danger" id="end" style="margin-left: 0.5rem;" (click)="changeStatus()">End & Waive</button>

            </div>
        </div>

        &nbsp;&nbsp;<img src="{{fullscrImgSrc}}" (click)="toggle_full()" style="cursor:pointer;width: 1.3rem;height: 1.5rem;margin-top: 20px;">

    </div>

</div>

<div class="row pofilerow" style="width: 5rem;padding-left: 0.5rem;padding-right: 0.5rem;" *ngIf="row==2">

    <div style="display: flex;flex-direction: row;justify-content: space-between;float:right"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 spacetop">
       
    </div>

</div>

<div class="row" *ngIf="row==3">

    <div style="display: flex;flex-direction: column;text-align: center;" class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <img class="mute" data-toggle="tooltip" data-placement="top" title="Allergies" style="margin-left: 0.5rem;"
            *ngIf="allergyExists&&readStatus==1" (click)="allergy()" src="assets/allergy.svg">
        <img class="mute" data-toggle="tooltip" data-placement="top" title="Allergies" style="margin-left: 0.5rem;"
            *ngIf="allergyExists&&readStatus==0" (click)="allergy()" src="assets/Allergy1.gif">
        <img class="mute" data-toggle="tooltip" data-placement="top" title="Allergies" style="margin-left: 0.5rem;"
            *ngIf="!allergyExists" (click)="allergy()" src="assets/no_allergy.svg">
        <p class="last" [class.red]="allergyExists" *ngIf="allergyExists">Allergies</p>
        <p class="last" [class.green]="!allergyExists" *ngIf="!allergyExists">Allergies</p>

    </div>

    <div style="display: flex;flex-direction: row;text-align: center;" class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <div style="display: flex;flex-direction: column;" *ngIf="documentId==undefined">
            <img class="mute" data-toggle="tooltip" data-placement="top" title="Vitals" style="margin-left: 0.3rem;"
                (click)="vitals()" src="assets/images/vitals.svg">
            <p class="last">Vitals</p>
        </div>

        <div style="display: flex;flex-direction: column;" *ngIf="documentId!==undefined">
            <img class="mute" data-toggle="tooltip" data-placement="top" title="Vitals" style="margin-left: 0.3rem;"
                (click)="vitals()" src="assets/images/vitals_icon_green.svg">
            <p class="last" style="color:green;font-weight: 500;">Vitals</p>
        </div>
    </div>

    <div style="display: flex;flex-direction: row;text-align: center;margin-left: -10px;border-right: 1px solid  #ABCCFF;"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <div style="display: flex;flex-direction: column;">
            <img (click)="openChronicExists()"
                style="cursor: pointer; width: 1.5rem; height: 1.5rem;margin-left: 0.5rem;"
                [src]="chronicDataExists.length==0?'assets/chronic.svg':'assets/chronic1.svg'">
            <p class="last">Chronic</p>

        </div>
    </div>


</div>