import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentComponent } from './appointment/appointment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PatientsComponent } from './patients/patients.component';
import { SettingsComponent } from './settings/settings.component';
import { ChatComponent } from './chat/chat.component';
import { VideoCallComponent } from './video-call/video-call.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { ObstgynprescrComponent } from './obstGynprescription/obstGynpres.component';
import { CashreceiptComponent } from './cashReceipt/cashreceipt.component';
import { VedhprescriptionComponent } from './vedhaprescription/vedhaprescription.component';
import { ChiefComplaintComponent } from './chief-complaint/chief-complaint.component';
import { ActivityDashboardComponent } from './Doctordashboard/ActivityDashboard/activitydashboard.component';
import { VaccinationComponent } from './vaccination/vaccination.component';
import { AdminGuard } from './guards/admin.guard';
import { DrprofileComponent } from './drProfile/drProfile.component';
import { ChatmsgesComponent } from './chat/chatmsges/chatmsges.component';
import { ReportsComponent } from './reports/reportDetails.component';
import { DailycollectionComponent } from './reports/dailyCollection/dailycollection.component';
import { DailyrangecollectionComponent } from './reports/dailyrangeCollection/dailyrangecollection.component';
import { WallettransactionComponent } from './reports/walletTransaction/walletTransaction.component';
import { BabyhealthComponent } from './reports/babyhealth/babyhealth.component';
import { BabygrowthComponent } from './reports/babygrowth/babygrowth.component';
import { MobileaccessComponent } from './reports/mobileaccess/mobileaccess.component';
import { VaccinationreportComponent } from './reports/vaccination/vaccination.component';
import { CovidreportComponent } from './reports/covid/covid.component';
import { MhusersReportComponent } from './reports/mhusers/mhusers.component';
 import { IvrsReportComponent } from './reports/ivrs/ivrs.component';
 import { FeedbackReportComponent } from './reports/feedback/feedback.component';
 import { HomevisitreportComponent } from './reports/homevisit/homevisit.component';
 import { VideocallReportComponent } from './reports/Videocall/videocall.component';
import { FollowupReportComponent } from './reports/followup/followup.component';
import { RegistrationreportComponent } from './reports/registration/registration.component';
import { VhnperformanceComponent } from './reports/vhnPerformance/vhnPerformance.component';
import { TwofactorGuard } from './guards/twofactor.guard';
import { TwofactauthComponent } from './twofactAuth/twofactAuth.component';
import { ActivityReportsComponent } from './Doctordashboard/ActivityReports/activityreports.component';
import { VitallComponent } from './vitall/vitall.component';
import { EngagementComponent } from './settings/engagement/engagement.component';
import { VersionListComponent } from './dashboard/version-list/version-list.component';
import { CashCollectionComponent } from './cash-collection/cash-collection.component';
import { paymentsettingsComponent } from './settings/payment/paymentsettings.component';
import { AptconfiqComponent } from './settings/aptconfiq/aptconfiq.component';
import { DrugsComponent } from './settings/drugs/drugs.component';
import { AdvicesettingsComponent } from './settings/advice/advicesettings.component';
import { VaccinationsettingsComponent } from './settings/vaccination/vaccinationsettings.component';
import { LocationsettingsComponent } from './settings/location/locationsettings.component';
import { ChatsettingsComponent } from './settings/chat/chatsettings.component';
import { SubscriptionComponent } from './settings/subscription/subscription.component';
import { FrontdeskComponent } from './settings/frontdesk/frontdesk.component';
import { consulttabComponent } from './settings/consulttab/consulttab.component';
import { ComplaintComponent } from './settings/complaint/complaint.component';
import { CommonComponent } from './settings/common/common.component';
import { OthersComponent } from './settings/others/others.component';
import { InvesticationComponent } from './settings/investication/investication.component';
import { payoutComponent } from './settings/payout/payout.component';
import { MilestonesettingsComponent } from './settings/milestone/milestone.component';
import { drinchargesettingsComponent } from './settings/drincharge/drincharge.component';
import { drinoutsettingsComponent } from './settings/drinout/drinout.component';
import { HomevisitFollowupComponent } from './reports/homevisit-followup/homevisit-followup.component';
import { OrdersreportComponent } from './ordersreport/ordersreport.component';
import { BannerComponent } from './settings/banner/banner.component';
import { PatientReportsComponent } from './reports/patient-reports/patient-reports.component';

const routes: Routes = [
  {
    path:'',
    component : LoginComponent,
  },
  {
    path:'twofactAuth',
    component : TwofactauthComponent,
  },
  {
    path:'epicare',
    component:DashboardComponent,
    canActivate: [AdminGuard],

    children:[
      {
        path: 'version',
        component: VersionListComponent
      },
      {
        path:'appointment',
        component:AppointmentComponent,
        canActivate: [TwofactorGuard],
      },
      {
        path:'appointment/:id',
        component:AppointmentComponent
      },
      {
        path:'drProfile',
        component:DrprofileComponent
      },
      {
        path:'drDashboard',
        component:ActivityDashboardComponent
      },
      {
        path:'vitall',
        component:VitallComponent
      },
      {
        path: 'activityReports',
        component:ActivityReportsComponent
      },
    
      {
        path:'patients',
        component:PatientsComponent,
        children:[]
      },
      {
        path:'chat',
        component:ChatComponent,
       children:[]
      },
      { 
        path: 'chathistory',
        component: ChatmsgesComponent,
      },
      {
        path:'reports',
        component:ReportsComponent,
        children:[
          {
            path: 'dailycollection',
            component: DailycollectionComponent,
          },
          {
            path: 'orderhistory',
            component:OrdersreportComponent
          },
          {
            path: 'dailyRangecollection',
            component: DailyrangecollectionComponent,
          },
          {
             path: 'registration',
             component: RegistrationreportComponent,
          },
          {
            path: 'walletTransaction',
            component: WallettransactionComponent,
          },
          {
            path: 'videocall',
            component: VideocallReportComponent,
          },
          {
            path: 'followup',
            component: FollowupReportComponent,
          },
          {
            path: 'homeVisit',
            component: HomevisitreportComponent,
          },
          {
            path: 'vhnperformance',
            component: VhnperformanceComponent,
          },
          {
            path: 'feedback',
            component: FeedbackReportComponent,
          },
          {
            path: 'babyHealth',
            component: BabyhealthComponent,
          },
          {
            path: 'babyGrowth',
            component: BabygrowthComponent,
          },
          {
            path: 'mobileAccess',
            component: MobileaccessComponent,
          },
          {
            path: 'vaccination',
            component: VaccinationreportComponent,
          },
          {
            path: 'covid',
            component: CovidreportComponent,
          },
          {
            path: 'mhusers',
            component: MhusersReportComponent
          },
          {
            path: 'ivrs',
            component: IvrsReportComponent
          },
          {
            path: 'cashcollection',
            component: CashCollectionComponent
          },
          {
            path: 'homevisitfollowup',
            component: HomevisitFollowupComponent
          },
          {
            path: 'overallpatient',
            component: PatientReportsComponent
          }

        ]
      },
      {
        path:'settings',
        component:SettingsComponent,
        children:[
          {
            path: 'engagement',
            component: EngagementComponent
          },
          {
            path:'payment',
            component:paymentsettingsComponent
          },
          {
            path:'payout',
            component:payoutComponent
          },
          {
            path:'aptConfiq',
            component:AptconfiqComponent
          },
          {
            path:'bannersettings',
            component:BannerComponent
          },
          {
            path:'milestoneConfiq',
            component:MilestonesettingsComponent
          },
          {
            path:'drincharge',
            component:drinchargesettingsComponent
          },
          {
            path:'drinout',
            component:drinoutsettingsComponent
          },
          {
            path:'advice',
            component:AdvicesettingsComponent
          },
          {
            path:'investication',
            component:InvesticationComponent
          },
          {
            path:'drugs',
            component:DrugsComponent
          },
          {
            path:'vac-master',
            component:VaccinationsettingsComponent
          },
          {
            path:'frontdesk',
            component:FrontdeskComponent
          },
          {
            path:'consulttab',
            component:consulttabComponent
          },
          {
            path:'common',
            component:CommonComponent
          },
          {
            path:'compliant',
            component:ComplaintComponent
          },
          {
            path:'chat',
            component:ChatsettingsComponent
          },
          {
            path:'subscription',
            component:SubscriptionComponent
          },
          {
            path:'location',
            component:LocationsettingsComponent
          },
          {
            path:'others',
            component:OthersComponent
          },
        ]
      }
    ]
  },
  { 
    path: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'prescription', component: PrescriptionComponent },
      { path: 'vedhaprescription', component: VedhprescriptionComponent },
      { path: 'obsGynpres', component: ObstgynprescrComponent },
      { path: 'cashReceipt', component: CashreceiptComponent }
    ]
  },
  {
    path: 'chiefcomplaint',
    component:ChiefComplaintComponent
  },
  {
    path: 'vaccination',
    component:VaccinationComponent
  },
  { 
    path: 'DrDashboard',
    component: ActivityDashboardComponent,
    children: [
      { path: 'prescription', component: PrescriptionComponent },
    ]
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
