<div style="display: flex;flex-direction: row;justify-content: space-between;">
    <p>Mobile Acces</p>
    <img (click)="close()" src="assets/cancel.svg">
</div>

<p class="name">{{data.name}}</p>
<p>Note:</p>
<p>Your registered Mobile no. will be set as User Name.
</p>

<p>You will receive your password via SMS, after first login the password can be changed from the mobile application.
</p>

<div style="display: flex;flex-direction: row;justify-content: space-evenly;">
    <button (click)="enable()" class="btn btn-primary">Enable</button>
    <button (click)="close()" class="btn btn-danger">Cancel</button>
</div>