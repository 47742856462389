<h3 mat-dialog-title>
   Warning!! You will not be able to edit this vaccine after marking as Not Required. Do you want to proceed?
</h3>

<br>
<div class="float-right">
   <span><button class="btn btn-danger" (click)="defferyes('no')">No</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button class="btn btn-primary" (click)="defferyes('yes')">Yes</button></span>
</div>


