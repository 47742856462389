import { Component,AfterViewChecked, OnInit,ElementRef,ViewChild, Inject } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpEvent,HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { Toast, ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  image: string,
  status: number
}


@Component({
  selector: 'app-patient',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [PubNubAngular]
})
export class ChatComponent implements OnInit {
  
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  routeURL:any;
  getaccount_email:any;
  getdrID:number;
  getauthToken:any;
  getdrName:string;
  setaccount_email:any;
  setdrID:any;
  setdrName:any;
  channel:any;
  uuid:any;
  pubnub: PubNubAngular;
  param:any;
  onsearch=false;
  showChatScreen = false;
  messageType = 'all';
  totalUnread = 0;

  chatParam;
  chatList = [];
  unrespond = 0;
  unread = 0;
  urlPoints;
  setauthToken;
  select_patient_name:string;
  chathistoryList = [];
  selectedPatient:any; 
  selectedPatientfname:string;
  selectedPatientlaname:string;
  selectedPatientGender:string;
  selectedPatientMrNum:any;
  selectedPatientdob:any;
  selectedPatientuhid:any;
  selectedPatientemail:any;
  selectedPatientphone:number;
  selectedPatientpayment:any;
  selectedpatientImage:any;
  messageContent = {message: ''};
  searchContent = {searchTxt: ''};
  chatMessages = [];
  imgArray = [];
  vdoArray = [];
  imageUploadUrl:any;
  vhnimageUploadUrl;
  uploadImageFile;
  msg: any;
  doctor_charge = 0;
  refundOption = false;
  loaded2 = false;
  progressStatus =false;
  progress: number;
  imgcheck;
  dmail;
  doc_id;
  doc_name;
  token;
  senderDetails;
  sendermail;
  senderImage;

  constructor(private router:Router,private route: ActivatedRoute, public dialog: MatDialog,  pubnub: PubNubAngular,private toastr: ToastrService,  private appService: AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/vhn/upload/';
   }

  ngOnInit(): void {
    this.getURLData();
    this.getPatientList(this.messageType);
  }


  getURLData() {
    // this.routeURL = this.route.queryParams
    //   .subscribe(params => {
    //     this.getaccount_email = params['account_email'];
    //     this.getdrID = params['doctor_id'];
    //     this.getdrName = params['doctor_name'];
    //     this.getauthToken = params['auth_token'];
    //   })
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.doc_name = getauthdetails.doctor_name;
    this.token = getauthdetails.token;

        this.setaccount_email = this.dmail;
        this.setdrID = this.doc_id;
        this.setdrName = this.doc_name;
        this.setauthToken = this.token;

        localStorage.setItem('auth_token',this.setauthToken);
        localStorage.setItem('account_email',this.setaccount_email);
        localStorage.setItem('dr_id',this.setdrID);
        localStorage.setItem('doctor_name',this.setdrName);
        

        this.channel = this.setaccount_email.replace('@','_').replace('.org', '');
        this.uuid = this.setaccount_email;
        this.autoRefresh(this.channel)
        //this.getdashboardCount();
  }

  autoRefresh(channelName:any)
  {
   this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: this.uuid
  });
    this.pubnub.subscribe({
      channels: [channelName],
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channelName, (msg) => {
      this.msg = msg;
      this.msg = this.msg.message;
    
      // if (this.msg.sender===this.selectedPatientemail){
      //   this.markAsRead(this.msg.message);
      // }
      this.getPatientList(this.messageType);
    });
    
  }

searchPatient(seartchTxt:any){
  this.onsearch = true;
  if(seartchTxt) {
    this.param = {
      account_email: this.setaccount_email,
      full_name: seartchTxt.target.value,
    }

      this.appService.postData(this.urlPoints.search, this.param, this.setauthToken).subscribe((response: any) => {
        this.chatList = response;
        
      });
} else {
    this.chatList = [];
}
   
    
}

  getPatientList(selectmesType) {
    for (var i = 0; i < 3; i++) {
      const el =  document.getElementsByClassName('tabselect')[i] as HTMLElement;
            el.style.borderBottom = "0px solid #000000";
            el.style.color = "#000000";
            el.style.fontWeight = "normal";
      }
      document.getElementById('id_'+selectmesType).style.borderBottom  = "2px solid #0063ff";
      document.getElementById('id_'+selectmesType).style.color  = "#0063ff";
      document.getElementById('id_'+selectmesType).style.fontWeight  = "bold";
    this.loaded2 = true;
    //this.setauthToken = 'b2F1dGhfdG9rZW49b3dVWHZoQ3BMVjAwNGRsaXBSa0kmb2F1dGhfdG9rZW5fc2VjcmV0PXFzODNsRUdjRW9YU3VFWXhQalpEJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw==';
    this.chatParam = {
      account_email: this.setaccount_email,
      messages: selectmesType,
    }

    this.appService.getData(this.urlPoints.chatList, this.chatParam, this.setauthToken).subscribe((response: any) => {
      this.loaded2 = false;
      this.chatList = response.history;
      this.unrespond = response.unresponded;
      this.unread = response.total_unread;

      this.chatList.map(res => {
        var Utc = moment.utc(res.last_messaged_on).toDate();
        var local = moment(Utc).local().format('DD-MMM-YYYY hh:mm a');
        res.last_messaged_on_Local = local;
       });
     
    })

  }

  goToChathistory(data,senderMail,patientImage) {

    console.log(data)
    console.log(senderMail)
    console.log(patientImage)

    this.senderDetails = JSON.stringify(data);
    this.sendermail = senderMail;
    this.senderImage = patientImage;
    localStorage.setItem('sender_details',JSON.stringify(data));
    localStorage.setItem('sender_mail',senderMail);
    localStorage.setItem('sender_image',patientImage);
   // this.router.navigate(['epicare/chathistory']);
  }

  getUnreadCount() {
    this.chatParam = {
      account_email: this.setaccount_email,
    }

    this.appService.getData(this.urlPoints.unread, this.chatParam, this.setauthToken).subscribe((response: any) => {
      this.totalUnread = response.total_unread;
    });
  }

  markAsRead(data){
    this.chatParam = {
      patient: data.email,
      account_email: this.setaccount_email,
    }
    this.appService.getData(this.urlPoints.setasread, this.chatParam, this.setauthToken).subscribe((response: any) => {
      data.unread = false;
      this.getPatientList(this.messageType);
    });
};

}

