<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true"
                        style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Medical History</p>
        </div>
    </div>

    <div class="row" *ngIf="!add_update_OpenState" style="margin-left:6px;margin-top: 50px;width:99%">

        <div style="width:100%;">
            <div class="addnewButton"><span style="color:#fff;cursor: pointer;" (click)="addUpdateScreenopen('add')"><i
                        class="fa fa-plus" aria-hidden="true" style="color:#fff"></i> Add New</span></div>
        </div>
        <table class="listTable" style="width:100%;margin-top: 10px;">
            <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                <tr style="height: 30px;">
                    <th class="obsttableheadcolor" style="width: 3%;">S.No</th>
                    <th class="obsttableheadcolor" style="width: 5%;">Date</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Type</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Hospital Name</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Doctor Name</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Diagnosis</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Prolonged Illness</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Admission</th>
                    <th class="obsttableheadcolor" style="width: 10%;">Comments</th>
                    <th class="obsttableheadcolor" style="width: 5%;">Action</th>
                </tr>
            </thead>

            <tbody *ngIf="listofmedicalhis != ''">
                <tr *ngFor="let listmedicalhistory of listofmedicalhis;let i=index"
                    style="border-bottom: 2px dotted #e3edf8;text-align: center;">
                    <td style="width: 2%;padding-top: 10px;
                        line-height: 25px;">{{i+1}}</td>
                    <td style="width: 5%;padding-top: 10px;
                        line-height: 25px;word-break: break-word;">{{listmedicalhistory.date | date: 'dd-MMM-yyyy'}}</td>

                    <td style="width: 10%;padding:10px;line-height: 25px;">{{listmedicalhistory.problem_type |
                        titlecase}}</td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.hospital_name}}
                    </td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.doctor_first_name | titlecase}}
                    </td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.diagnosis }}
                    </td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.prolonged_illness}}
                    </td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.admission}}
                    </td>
                    <td style="width: 10%;padding:10px;line-height: 25px;">
                        {{listmedicalhistory.comments}}
                    </td>
                    <td style="width: 3%;padding-top: 10px;
                        line-height: 25px;">
                        <i class="fa fa-pencil" title="Edit" style="cursor: pointer;color:#007bff;font-size: 20px;"
                            (click)="addUpdateScreenopen('update');updateMedicalData(listmedicalhistory)"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="listofmedicalhis == ''" style="width:99%;margin-top:5%;text-align: center;">
            <div>No Data Found</div>
        </div>
    </div>

    <div class="row" *ngIf="add_update_OpenState" style="margin-left:6px;margin-top: 50px;width:99%">
        <form [formGroup]="medicalHistory" (submit)="validatemedicaldata()" style="width:100%;">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div style="margin-top: 10px;">
                    <div *ngIf="formcheckFlag == 'add'" class="heading">Add New Medical HISTORY</div>
                    <div *ngIf="formcheckFlag == 'update'" class="heading">Update Medical HISTORY</div>
                    <button *ngIf="formcheckFlag == 'add'" type="submit" style="float:right;margin-top:-48px;"
                        class="btn btn-primary customButton"><i class="fa fa-save" aria-hidden="true"
                            style="color:#fff"></i> Save</button>
                    <button *ngIf="formcheckFlag == 'update'" type="submit" style="float:right;margin-top:-48px;"
                        class="btn btn-primary customButton"><i class="fa fa-save" aria-hidden="true"
                            style="color:#fff"></i> Update</button>
                    <button style="float:right;margin-top:-48px;margin-right: 110px;"
                        class="btn btn-primary customButton" (click)="cancelMedicalHistory()"><i class="fa fa-times"
                            aria-hidden="true" style="color:#fff"></i> Cancel</button>
                    <hr class="hrStyle">
                </div>
                <div class="row" style="margin-top: 30px;">
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Hospital Name</span>
                        <input class="form-control dateInput" type="text" placeholder="Hospital name" maxlength="50"
                            formControlName="hospitalName">
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Doctor Name</span>
                        <input class="form-control dateInput" type="text" placeholder="Doctor name"
                            formControlName="drName" maxlength="30" (keypress)="omit_special_char($event)">
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Date</span>
                        <span class="float-right text-primary sub_headings_right">Select Date</span>
                        <input class="form-control dateInput" type="date" [min]="hosmindate" [max]="hosmaxdate"
                            (keydown)="disableDate()" formControlName="date">
                    </div>
                </div>

                <div class="row" style="margin-top: 30px;">
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Type</span>
                        <input class="form-control dateInput" type="text" placeholder="Complaint Type" maxlength="100"
                            formControlName="type">
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Prolonged Illness</span>
                        <input class="form-control dateInput" type="text" placeholder="Illness" maxlength="100"
                            formControlName="illness">
                    </div>
                    <div class="col-lg-4 obsgynecsubheading">
                        <span class="sub_headings">Admission</span>
                        <input class="form-control dateInput" type="text" placeholder="Admission" maxlength="100"
                            formControlName="admission">
                    </div>
                </div>

                <div class="row" style="margin-top: 30px;">
                    <div class="col-lg-6 obsgynecsubheading">
                        <span class="sub_headings">Diagnosis</span>
                        <textarea class="form-control" name="obshistory" cols="50" rows="7" maxlength="100"
                            style="margin-top: 7px;height: 100px;" formControlName="diagnosis"></textarea>
                    </div>
                    <div class="col-lg-6 obsgynecsubheading">
                        <span class="sub_headings">Comments</span>
                        <textarea class="form-control" name="obshistory" cols="50" rows="7" maxlength="100"
                            style="margin-top: 7px;height: 100px;" formControlName="comments"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>