import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { StoredService } from 'src/app/wrapper/services/stored';
import { AppConfig } from '../app.config';
import { Md5 } from 'ts-md5/dist/md5';
import { DeviceDetectorService } from 'ngx-device-detector';
StoredService


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  submitted = false;
  fail = false
  p_icon = "remove_red_eye"
  osName;
  browserName;
  browserVersion;
  urlPoints: any;
  getipAddress ='';
  ipaddress = "255.255.255.255";
  public deviceInfo = null;

  constructor(private router: Router, private fb: FormBuilder,private appService: AppService,private deviceDetect: DeviceDetectorService,private storedService:StoredService) {
    this.urlPoints = AppConfig.settings.EndPoints;

    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });

    
  }
  ngOnInit(): void {

    this.deviceInfo = this.deviceDetect.getDeviceInfo();
   
    
    this.appService.getIPAddress().subscribe((res:any)=>{  
      this.ipaddress = res.ip;
    });
    setTimeout (() => {
      this.ipaddress = this.ipaddress;
    },200);

    
   this.getOS()
    
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }

    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }

    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }

    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }

    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }

    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    this.browserName = browserName

    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    this.browserVersion = fullVersion;
    
}

getIP(){
  
}

  login() {
    this.submitted = true;
   
    if (this.loginForm.valid) {

      const md5 = new Md5();

      var params= {
        //device_details:this.osName+ ' ' + this.browserName,
        device_details: (this.deviceInfo.os).toLowerCase() + ' ' + (this.deviceInfo.browser).toLowerCase(),
        password:md5.appendStr(this.loginForm.value.password).end(),
        username:this.loginForm.value.username,
        ip_address: this.ipaddress
      }

      this.appService.postData(this.urlPoints.docLogin, params,'').subscribe((response: any) => {
        localStorage.setItem('TOKEN',response.doctors.login.info.token);
        localStorage.setItem('twofact_auth',response.doctors.login.auth_status);
        localStorage.setItem('speciality',response.doctors.login.doctor.speciality_type);

        //localStorage.setItem('twofact_auth','false');
        this.router.navigate(['/epicare/drDashboard']);
        this.storedService.storedAuthdetails(response.doctors.login);
      },
      error => {
        this.fail = true;
      }
      );
    }
  }

  toggle() {
    if ((document.getElementById("password") as HTMLInputElement).type == "password") {
      (document.getElementById("password") as HTMLInputElement).type = "text"
    }
    else {
      (document.getElementById("password") as HTMLInputElement).type = "password"
    }
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    this.osName = os

    return os;
  }
}
