import { Component, OnInit, ElementRef, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-combined-chart-female-small',
  templateUrl: './combined-chart-female-small.component.html',
  styleUrls: ['./combined-chart-female-small.component.scss']
})
export class CombinedChartFemaleSmallComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }
  @Input() chartData;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
   
    this.drawChart(this.chartData)
  }

  drawChart(data) {
    var vitalData: any = data

    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 250 - margin.top - margin.bottom;
    var width = 370 - margin.left - margin.right;

    var heightData = [];
    var weightData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.height.length; i++) {
        if (vitalData.height[i].x > 59 && vitalData.height[i].x < 217) {
          heightData.push(vitalData.height[i]);
        }
      }
    }

    if (vitalData.weight) {
      for (var i = 0; i < vitalData.weight.length; i++) {
        if (vitalData.weight[i].x > 59 && vitalData.weight[i].x < 217) {
          weightData.push(vitalData.weight[i]);
        }
      }
    }

    var set1 = [{ x: 60, y: 97.2 }, { x: 66, y: 99.8 }, { x: 72, y: 102.3 }, { x: 78, y: 104.9 }, { x: 84, y: 107.4 }, { x: 90, y: 110 },
    { x: 96, y: 112.6 }, { x: 102, y: 115.2 }, { x: 108, y: 117.8 }, { x: 114, y: 120.5 }, { x: 120, y: 123.3 }, { x: 126, y: 126.1 }, { x: 132, y: 128.8 },
    { x: 138, y: 131.5 }, { x: 144, y: 134 }, { x: 150, y: 136.3 }, { x: 156, y: 138.2 }, { x: 162, y: 139.9 }, { x: 168, y: 141.3 }, { x: 174, y: 142.4 },
    { x: 180, y: 143.3 }, { x: 186, y: 144.1 }, { x: 192, y: 144.7 }, { x: 198, y: 145.2 }, { x: 204, y: 145.7 }, { x: 210, y: 146.2 }, { x: 216, y: 146.6 }];

    var set2 = [{ x: 60, y: 100.5 }, { x: 66, y: 103.2 }, { x: 72, y: 106 }, { x: 78, y: 108.7 }, { x: 84, y: 111.4 }, { x: 90, y: 114.1 }, { x: 96, y: 116.8 },
    { x: 102, y: 119.6 }, { x: 108, y: 122.4 }, { x: 114, y: 125.2 }, { x: 120, y: 128.1 }, { x: 126, y: 130.9 }, { x: 132, y: 133.7 },
    { x: 138, y: 136.4 }, { x: 144, y: 138.9 }, { x: 150, y: 141.1 }, { x: 156, y: 142.9 }, { x: 162, y: 144.5 }, { x: 168, y: 145.8 }, { x: 174, y: 146.8 },
    { x: 180, y: 147.5 }, { x: 186, y: 148.1 }, { x: 192, y: 148.6 }, { x: 198, y: 149.1 }, { x: 204, y: 149.5 }, { x: 210, y: 149.8 }, { x: 216, y: 150.2 }];

    var set3 = [{ x: 60, y: 103.9 }, { x: 66, y: 106.8 }, { x: 72, y: 109.7 }, { x: 78, y: 112.5 }, { x: 84, y: 115.4 }, { x: 90, y: 118.2 }, { x: 96, y: 121.1 },
    { x: 102, y: 124 }, { x: 108, y: 126.9 }, { x: 114, y: 129.9 }, { x: 120, y: 132.8 }, { x: 126, y: 135.7 }, { x: 132, y: 138.6 },
    { x: 138, y: 141.2 }, { x: 144, y: 143.7 }, { x: 150, y: 145.8 }, { x: 156, y: 147.6 }, { x: 162, y: 149.1 }, { x: 168, y: 150.2 }, { x: 174, y: 151.1 },
    { x: 180, y: 151.8 }, { x: 186, y: 152.3 }, { x: 192, y: 152.7 }, { x: 198, y: 153.1 }, { x: 204, y: 153.4 }, { x: 210, y: 153.6 }, { x: 216, y: 153.9 }];

    var set4 = [{ x: 60, y: 107.5 }, { x: 66, y: 110.5 }, { x: 72, y: 113.5 }, { x: 78, y: 116.5 }, { x: 84, y: 119.4 }, { x: 90, y: 122.4 }, { x: 96, y: 125.4 },
    { x: 102, y: 128.4 }, { x: 108, y: 131.4 }, { x: 114, y: 134.4 }, { x: 120, y: 137.4 }, { x: 126, y: 140.4 }, { x: 132, y: 143.3 },
    { x: 138, y: 145.9 }, { x: 144, y: 148.4 }, { x: 150, y: 150.5 }, { x: 156, y: 152.2 }, { x: 162, y: 153.6 }, { x: 168, y: 154.7 }, { x: 174, y: 155.5 },
    { x: 180, y: 156.1 }, { x: 186, y: 156.6 }, { x: 192, y: 156.9 }, { x: 198, y: 157.2 }, { x: 204, y: 157.4 }, { x: 210, y: 157.6 }, { x: 216, y: 157.8 }];

    var set5 = [{ x: 60, y: 111.3 }, { x: 66, y: 114.4 }, { x: 72, y: 117.4 }, { x: 78, y: 120.5 }, { x: 84, y: 123.5 }, { x: 90, y: 126.6 }, { x: 96, y: 129.6 },
    { x: 102, y: 132.7 }, { x: 108, y: 135.8 }, { x: 114, y: 138.9 }, { x: 120, y: 142 }, { x: 126, y: 145 }, { x: 132, y: 147.9 },
    { x: 138, y: 150.6 }, { x: 144, y: 153 }, { x: 150, y: 155.1 }, { x: 156, y: 156.8 }, { x: 162, y: 158.2 }, { x: 168, y: 159.2 }, { x: 174, y: 160 },
    { x: 180, y: 160.5 }, { x: 186, y: 160.9 }, { x: 192, y: 161.2 }, { x: 198, y: 161.4 }, { x: 204, y: 161.6 }, { x: 210, y: 161.7 }, { x: 216, y: 161.9 }];

    var set6 = [{ x: 60, y: 115.2 }, { x: 66, y: 118.3 }, { x: 72, y: 121.5 }, { x: 78, y: 124.6 }, { x: 84, y: 127.7 }, { x: 90, y: 130.8 }, { x: 96, y: 133.9 },
    { x: 102, y: 137 }, { x: 108, y: 140.2 }, { x: 114, y: 143.3 }, { x: 120, y: 146.4 }, { x: 126, y: 149.5 }, { x: 132, y: 152.4 },
    { x: 138, y: 155.1 }, { x: 144, y: 157.5 }, { x: 150, y: 159.6 }, { x: 156, y: 161.3 }, { x: 162, y: 162.7 }, { x: 168, y: 163.7 }, { x: 174, y: 164.5 },
    { x: 180, y: 165 }, { x: 186, y: 165.3 }, { x: 192, y: 165.6 }, { x: 198, y: 165.7 }, { x: 204, y: 165.9 }, { x: 210, y: 166 }, { x: 216, y: 166.1 }];

    var set7 = [{ x: 60, y: 119.3 }, { x: 66, y: 122.5 }, { x: 72, y: 125.6 }, { x: 78, y: 128.7 }, { x: 84, y: 131.9 }, { x: 90, y: 135 }, { x: 96, y: 138.1 },
    { x: 102, y: 141.3 }, { x: 108, y: 144.5 }, { x: 114, y: 147.6 }, { x: 120, y: 150.8 }, { x: 126, y: 153.9 }, { x: 132, y: 156.8 },
    { x: 138, y: 159.6 }, { x: 144, y: 162 }, { x: 150, y: 164.1 }, { x: 156, y: 165.9 }, { x: 162, y: 167.2 }, { x: 168, y: 168.2 }, { x: 174, y: 169 },
    { x: 180, y: 169.5 }, { x: 186, y: 169.8 }, { x: 192, y: 170.1 }, { x: 198, y: 170.2 }, { x: 204, y: 170.4 }, { x: 210, y: 170.5 }, { x: 216, y: 170.6 }];

    var set8 = [{ x: 60, y: 12.3 }, { x: 66, y: 13 }, { x: 72, y: 13.7 }, { x: 78, y: 14.4 }, { x: 84, y: 15.1 }, { x: 90, y: 15.9 }, { x: 96, y: 16.7 },
    { x: 102, y: 17.5 }, { x: 108, y: 18.5 }, { x: 114, y: 19.5 }, { x: 120, y: 20.7 }, { x: 126, y: 22 }, { x: 132, y: 23.3 },
    { x: 138, y: 24.8 }, { x: 144, y: 26.2 }, { x: 150, y: 27.6 }, { x: 156, y: 28.9 }, { x: 162, y: 30.2 }, { x: 168, y: 31.3 }, { x: 174, y: 32.3 },
    { x: 180, y: 33.1 }, { x: 186, y: 34 }, { x: 192, y: 34.7 }, { x: 198, y: 35.5 }, { x: 204, y: 36.2 }, { x: 210, y: 36.9 }, { x: 216, y: 37.6 }];

    var set9 = [{ x: 60, y: 13.4 }, { x: 66, y: 14.3 }, { x: 72, y: 15.1 }, { x: 78, y: 15.9 }, { x: 84, y: 16.8 }, { x: 90, y: 17.7 }, { x: 96, y: 18.7 },
    { x: 102, y: 19.7 }, { x: 108, y: 20.9 }, { x: 114, y: 22.1 }, { x: 120, y: 23.5 }, { x: 126, y: 25.1 }, { x: 132, y: 26.7 },
    { x: 138, y: 28.4 }, { x: 144, y: 30 }, { x: 150, y: 31.6 }, { x: 156, y: 33.1 }, { x: 162, y: 34.4 }, { x: 168, y: 35.6 }, { x: 174, y: 36.6 },
    { x: 180, y: 37.5 }, { x: 186, y: 38.3 }, { x: 192, y: 39.1 }, { x: 198, y: 39.8 }, { x: 204, y: 40.5 }, { x: 210, y: 41.1 }, { x: 216, y: 41.8 }];

    var set10 = [{ x: 60, y: 14.8 }, { x: 66, y: 15.7 }, { x: 72, y: 16.7 }, { x: 78, y: 17.7 }, { x: 84, y: 18.7 }, { x: 90, y: 19.9 }, { x: 96, y: 21.1 },
    { x: 102, y: 22.3 }, { x: 108, y: 23.7 }, { x: 114, y: 25.3 }, { x: 120, y: 26.9 }, { x: 126, y: 28.8 }, { x: 132, y: 30.7 },
    { x: 138, y: 32.6 }, { x: 144, y: 34.5 }, { x: 150, y: 36.3 }, { x: 156, y: 37.9 }, { x: 162, y: 39.4 }, { x: 168, y: 40.6 }, { x: 174, y: 41.7 },
    { x: 180, y: 42.5 }, { x: 186, y: 43.3 }, { x: 192, y: 44 }, { x: 198, y: 44.7 }, { x: 204, y: 45.3 }, { x: 210, y: 46 }, { x: 216, y: 46.6 }];

    var set11 = [{ x: 60, y: 16.4 }, { x: 66, y: 17.6 }, { x: 72, y: 18.7 }, { x: 78, y: 19.9 }, { x: 84, y: 21.2 }, { x: 90, y: 22.5 }, { x: 96, y: 24 },
    { x: 102, y: 25.5 }, { x: 108, y: 27.2 }, { x: 114, y: 29 }, { x: 120, y: 31 }, { x: 126, y: 33.2 }, { x: 132, y: 35.4 },
    { x: 138, y: 37.6 }, { x: 144, y: 39.8 }, { x: 150, y: 41.8 }, { x: 156, y: 43.6 }, { x: 162, y: 45.1 }, { x: 168, y: 46.4 }, { x: 174, y: 47.5 },
    { x: 180, y: 48.4 }, { x: 186, y: 49.1 }, { x: 192, y: 49.7 }, { x: 198, y: 50.3 }, { x: 204, y: 50.9 }, { x: 210, y: 51.5 }, { x: 216, y: 52 }];

    var set12 = [{ x: 60, y: 18.5 }, { x: 66, y: 19.9 }, { x: 72, y: 21.3 }, { x: 78, y: 22.7 }, { x: 84, y: 24.2 }, { x: 90, y: 25.9 }, { x: 96, y: 27.6 },
    { x: 102, y: 29.5 }, { x: 108, y: 31.5 }, { x: 114, y: 33.6 }, { x: 120, y: 36 }, { x: 126, y: 38.4 }, { x: 132, y: 41 },
    { x: 138, y: 43.6 }, { x: 144, y: 46 }, { x: 150, y: 48.2 }, { x: 156, y: 50.2 }, { x: 162, y: 51.8 }, { x: 168, y: 53.2 }, { x: 174, y: 54.3 },
    { x: 180, y: 55.1 }, { x: 186, y: 55.8 }, { x: 192, y: 56.3 }, { x: 198, y: 56.9 }, { x: 204, y: 57.3 }, { x: 210, y: 57.8 }, { x: 216, y: 58.2 }];

    var set13 = [{ x: 60, y: 21.3 }, { x: 66, y: 22.9 }, { x: 72, y: 24.6 }, { x: 78, y: 26.3 }, { x: 84, y: 28.2 }, { x: 90, y: 30.1 }, { x: 96, y: 32.2 },
    { x: 102, y: 34.4 }, { x: 108, y: 36.7 }, { x: 114, y: 39.3 }, { x: 120, y: 42 }, { x: 126, y: 44.8 }, { x: 132, y: 47.7 },
    { x: 138, y: 50.6 }, { x: 144, y: 53.4 }, { x: 150, y: 55.8 }, { x: 156, y: 57.9 }, { x: 162, y: 59.7 }, { x: 168, y: 61.1 }, { x: 174, y: 62.2 },
    { x: 180, y: 62.9 }, { x: 186, y: 63.5 }, { x: 192, y: 64 }, { x: 198, y: 64.4 }, { x: 204, y: 64.7 }, { x: 210, y: 65 }, { x: 216, y: 65.3 }];

    var set14 = [{ x: 60, y: 25 }, { x: 66, y: 27 }, { x: 72, y: 29.1 }, { x: 78, y: 31.2 }, { x: 84, y: 33.4 }, { x: 90, y: 35.7 }, { x: 96, y: 38.1 },
    { x: 102, y: 40.7 }, { x: 108, y: 43.4 }, { x: 114, y: 46.3 }, { x: 120, y: 49.4 }, { x: 126, y: 52.6 }, { x: 132, y: 55.9 },
    { x: 138, y: 59.1 }, { x: 144, y: 62.1 }, { x: 150, y: 64.8 }, { x: 156, y: 67.1 }, { x: 162, y: 69 }, { x: 168, y: 70.4 }, { x: 174, y: 71.4 },
    { x: 180, y: 72.1 }, { x: 186, y: 72.5 }, { x: 192, y: 72.8 }, { x: 198, y: 73.1 }, { x: 204, y: 73.3 }, { x: 210, y: 73.4 }, { x: 216, y: 73.5 }];


    var rawSvg = this.elementRef.nativeElement.querySelector('svg');

    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    var wrapheight = d3.select(rawSvg).selectAll('d.d3line').data([heightData]);
    var wrapweight = d3.select(rawSvg).selectAll('d.d3line').data([weightData]);
    var gEnterheight = wrapheight.enter().append('g').attr('class', 'd3line').append('g');
    var gEnterweight = wrapweight.enter().append('g').attr('class', 'd3line').append('g');

    gEnterheight.append('g').attr('class', 'lines');
    gEnterheight.append('g').attr('class', 'point-clips');
    gEnterheight.append('g').attr('class', 'point-paths');

    gEnterheight.append('g').attr('class', 'lines');
    gEnterheight.append('g').attr('class', 'point-clips');
    gEnterheight.append('g').attr('class', 'point-paths');


    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveCardinal);

    var datalineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xVal = [5, 5.6, 6, 6.6, 7, 7.6, 8, 8.6, 9, 9.6, 10, 10.6, 11, 11.6, 12, 12.6, 13, 13.6, 14, 14.6, 15, 15.6, 16, 16.6, 17, 17.6, 18];

    var xScale0 = d3.scaleLinear().domain([216, 60]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([216, 60]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([0, 190]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([0, 190]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return d / 12 % 1 ? '' : (d / 12) as any;
    }).tickSizeInner(-height).tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-height).tickSizeOuter(0);
    var yAxisLeft = d3.axisLeft(yScale1).ticks(26).tickValues(d3.range(0, 190, 20)).tickFormat(function (d: any) {
      return d % 20 == 0 ? d : '';
    }).tickSizeInner(-width).tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(26).tickValues(d3.range(0, 190, 20)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-width).tickSizeOuter(0);

    var age = function (data) {
      var mon = data / 12;
      var result = mon.toFixed(1).split('.');
      var mod = data % 12;
      var res = mod.toString().split('.');
      return result[0].toString() + " years ";
    };


    svg.selectAll("circle.heightData")
      .data(heightData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'green').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + " cm"});
      // .on("mouseover", function (event, d) {
      //   div.transition()
      //     .duration(200)
      //     .style("opacity", .9);
      //   div.html(age(d.x) + "<br/>" + (d.y).toString() + " cm")
      //     .style("left", (event.pageX) + "px")
      //     .style("top", (event.pageY - 28) + "px");
      // })
      // .on("mouseout", function (d) {
      //   div.transition()
      //     .duration(500)
      //     .style("opacity", 0);
      // });

    svg.selectAll("circle.weightData")
      .data(weightData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'red').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + "Kg"});
      // .on("mouseover", function (event, d) {
      //   div.transition()
      //     .duration(200)
      //     .style("opacity", .9);
      //   div.html(age(d.x) + "<br/>" + (d.y).toString() + "Kg")
      //     .style("left", (event.pageX) + "px")
      //     .style("top", (event.pageY - 28) + "px");
      // })
      // .on("mouseout", function (d) {
      //   div.transition()
      //     .duration(500)
      //     .style("opacity", 0);
      // });

    // x-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 30)
      .style("text-anchor", "middle")
      .text("Age in Years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -7)
      .style("text-anchor", "middle")
      .text("IAP Girls Height & Weight Chart");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height)
      .attr("transform", "translate(-970,1250) rotate(-90)")
      .style("text-anchor", "middle")
      .text("WEIGHT(Kg)");

    svg.append("text")
      .attr("x", 300)
      .attr("y", height - 10)
      .attr("transform", "rotate(90,575,1150)")
      .style("text-anchor", "middle")
      .text("WEIGHT(Kg)");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height)
      .attr("transform", "translate(-970,450) rotate(-90)")
      .style("text-anchor", "middle")
      .text("STATURE(CM)");

    svg.append("text")
      .attr("x", -500)
      .attr("y", height - 10)
      .attr("transform", "rotate(90,575,1150)")
      .style("text-anchor", "middle")
      .text("STATURE(CM)");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 760)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("3");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 735)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("10");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 710)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("25");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 680)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("50");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 650)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("75");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 615)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("90");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 575)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("97");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 215)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("3");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 195)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("10");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 175)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("25");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 155)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("50");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 135)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("75");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 115)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("90");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 90)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text("97");

    // y-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);


    svg.append("path")
      .datum(set1)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set2)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

      svg.append("path")
      .datum(set3)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set4)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set5)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set6)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set7)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set8)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set9)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set10)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set11)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set12)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set13)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set14)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(heightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(weightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

  }



}
