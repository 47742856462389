<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Family History</p>
        </div>
    </div>

    <div class="row" style="margin-top: 50px;">
        <div *ngFor="let q of questionData;let i = index" class="col-lg-6 col-md-6 col-sm-6" style="margin-bottom: 10px;">
            <p><span style="font-weight: 600;">Question : </span> <span>{{q.checkbox_title}}</span></p>
            <p><span style="font-weight: 600;">Question : </span> <span>{{q.answer}}</span></p>

        </div>
    </div>
</div>

