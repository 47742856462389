import { Component,AfterViewChecked, OnInit,ElementRef,ViewChild, Inject, Input } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { HttpEvent,HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { Toast, ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  image: string,
  status: number
}


@Component({
  selector: 'app-chatmsges',
  templateUrl: './chatmsges.component.html',
  styleUrls: ['./chatmsges.component.scss'],
  providers: [PubNubAngular]
})
export class ChatmsgesComponent implements OnInit, AfterViewChecked {
  
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  routeURL:any;
  getaccount_email:any;
  getdrID:number;
  getauthToken:any;
  getdrName:string;
  setaccount_email:any;
  setdrID:any;
  setdrName:any;
  channel:any;
  uuid:any;
  pubnub: PubNubAngular;
  param:any;
  

  chatParam;
  chatList;
  chatId = '';
  unrespond = 0;
  unread = 0;
  urlPoints;
  setauthToken;
  select_patient_name:string;
  chathistoryList = [];
  selectedPatient:any; 
  selectedPatientfname:string;
  selectedPatientlaname:string;
  selectedPatientGender:string;
  selectedPatientMrNum:any;
  selectedPatientdob:any;
  selectedPatientuhid:any;
  selectedPatientemail:any;
  selectedPatientphone:number;
  selectedPatientpayment:any;
  selectedpatientImage:any;
  messageContent = {message: ''};
  searchContent = {searchTxt: ''};
  chatMessages = [];
  imgArray = [];
  vdoArray = [];
  imageUploadUrl:any;
  vhnimageUploadUrl;
  uploadImageFile;
  msg: any;
  doctor_charge = 0;
  refundOption = false;
  loaded2 = false;
  progressStatus =false;
  progress: number;
  sender_details;
  sender_mail;
  sender_image;

  @Input() senderDetails:any;
  @Input() sendermail:any;
  @Input() senderImage:any;

  constructor(private router:Router,private route: ActivatedRoute, public dialog: MatDialog,  pubnub: PubNubAngular,private toastr: ToastrService,  private appService: AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/vhn/upload/';
   }

  ngOnInit(): void {

    this.setaccount_email = localStorage.getItem('account_email');
    this.setdrID = localStorage.getItem('dr_id');
    this.setdrName = localStorage.getItem('doctor_name');
    this.setauthToken = localStorage.getItem('auth_token');

    this.sender_details = localStorage.getItem('sender_details');
    this.sender_details = JSON.parse(this.sender_details);
    this.sender_mail = localStorage.getItem('sender_mail');
    this.sender_image = localStorage.getItem('sender_image');
    

    this.channel = this.setaccount_email.replace('@','_').replace('.org', '');
    this.uuid = localStorage.getItem('account_email');

    this.autoRefresh(this.channel);
    this.getMessages(this.sender_details, this.sender_mail, this.sender_image);
   // this.getURLData();
    this.refundOptionCheck();
    this.scrollToBottom();
  }

  ngOnChanges(){
    this.sender_details = this.senderDetails;
    this.sender_details = JSON.parse(this.sender_details);
    this.sender_mail = this.sendermail;
    this.sender_image = this.senderImage;
    this.getMessages(this.sender_details, this.sender_mail, this.sender_image);
    this.refundOptionCheck();
    this.scrollToBottom();
  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

  autoRefresh(channelName:any)
  {
   this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: this.uuid
  });
    this.pubnub.subscribe({
      channels: [channelName],
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channelName, (msg) => {
      console.log(msg);
      this.msg = msg;
      console.log(this.msg.message.sender);
      this.msg = this.msg.message;
      console.log(this.msg);
      console.log(this.msg.sender);
      if (this.msg.sender===this.selectedPatientemail){
        this.getMessages(this.msg.message, this.msg.sender,'');
      }
      
    });
    
  }


  getMessages(data,senderMail,patientImage) {
    
    this.selectedpatientImage = patientImage;
 
      if(data.first_name){
        this.select_patient_name = data.first_name;
    } else {
        this.select_patient_name = "Patient"; 
    }

    if(data.email) {
      data.email = data.email;
      //data.payment = this.selectedPatient.payment;
      this.selectedPatient = data;
      this.selectedPatientfname = data.full_name;
      this.selectedPatientemail = data.email;
      this. selectedPatientlaname= data.last_name;
      this.selectedPatientGender= data.gender;
      this.selectedPatientdob= data.dob;
      this.selectedPatientMrNum= data.mr_number;
      this.selectedPatientuhid= data.uhid;
      this.selectedPatientphone= data.phone;
      this.selectedPatientpayment= data.payment;
      }

      this.chatParam = {
        account_email: this.setaccount_email,
        patient: senderMail,
      }

      this.appService.getData(this.urlPoints.chatpatienthistory, this.chatParam, this.setauthToken).subscribe((response: any) => {
         this.chathistoryList = response;
         this.chathistoryList = this.chathistoryList.reverse()
        console.log(this.chathistoryList);
        
        this.chatMessages = this.chathistoryList.map(res => {

          var Utc = moment.utc(res.date).toDate();
          var local = moment(Utc).local().format('DD-MMM-YYYY hh:mm a');
          res.messaged_date = local;

          res.message = decodeURIComponent(res.message);
          console.log(res.message);
          if (res.message.endsWith(".png") || res.message.endsWith(",")) {
            if (res.message.startsWith("img_")) {

                var temp = res.message.split(",")
                console.log('temp image',temp);
                this.imgArray = [];
                temp.map(imgres => {
                  if(imgres != ""){
                      console.log(' this.imageUploadUrl', this.imageUploadUrl); 
                      this.imgArray.push( this.imageUploadUrl + imgres);
                    }
                 });
                res.imagemessage = this.imgArray;
                console.log('1. res.imagemessage ', this.imageUploadUrl+res.imagemessage );
            } else {
                var temp = res.message.split(",")
                this.imgArray = [];
                temp.map(imgres => {
                  this.imgArray.push( this.vhnimageUploadUrl + imgres.replace(" ",""));
                 });
                res.imagemessage = this.imgArray;
                console.log('2. res.imagemessage ', res.imagemessage );
            }
        }

        if (res.message.endsWith(".mp4")) {
            if(res.message.startsWith("vid_")) {  // equals to if(res.message_type == 0){}
            
                var temp = res.message.split(",")
                this.vdoArray = [];
                temp.map(vidres => {
                  this.vdoArray.push( this.imageUploadUrl+ vidres );
                 });
               res.videomessage = this.vdoArray;
            } else {
                var temp = res.message.split(",")
                this.vdoArray = [];
                temp.map(vidres => {
                  this.vdoArray.push( this.imageUploadUrl + "/VHN/upload/" + vidres.replace(" ",""));
                 });
                res.videomessage = this.vdoArray;
            }
        }
        
        if(res.message.startsWith("http://220.226.2.177")) {
            res.audiomessage = res.message;
        }

        //this.loaded=false;
        });

        console.log(this.chatMessages);
      })
      console.log(this.chatMessages);

  }

  

  uploadAttachment(event){
    
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadImageFile = file;
      this.uploadImagee();
  }

  uploadImagee(){
    this.appService
    .postwithoutPortImgFile(AppConfig.settings.EndPoints.uploadImage, this.uploadImageFile, localStorage.getItem('TOKEN'))
    .subscribe((event: HttpEvent<any>) => { 
      switch (event.type) {
        case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progressStatus = true;
            //this.progress = Math.round(event.loaded / event.total * 100);
            this.progress = Math.round((100 / event.total) * event.loaded);
            console.log(`Uploaded! ${this.progress}%`);
            break;
        case HttpEventType.Response:
          this.messageContent.message = event.body.image[0].filename;
          setTimeout(() => {
            this.progressStatus = false;
            this.progress = 0;
          }, 1500);
        }
        
  })
  }

  openImg(imgPath,checkstatus){
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_chat, {
      width: '30%',
      data: { image: imgPath, status:checkstatus},
      panelClass: 'my-dialog',
    });
  
  }

  goback() {
    this.router.navigate(['/chat'], { queryParams: {account_email: this.setaccount_email,doctor_id: this.setdrID,doctor_name: this.setdrName,auth_token: this.setauthToken}});
  }

  refundOptionCheck(){
    this.param = {
      account_email: this.setaccount_email,
      doctor_id: this.setdrID,
    }
    this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
      this.selectedPatient = {payment: false};
      response.map(res => {
          if (res.module == "chat"){
            this.doctor_charge = parseInt(res.doctor_charge);
            this.selectedPatient = {payment: true};
            this.refundOption = true;
          }
      });
    });
  }
  //raise invoice
  raiseInvoice(chatID) {
    // alert()
    this.param = {
      account_email: this.setaccount_email,
      doctor_id: this.setdrID,
    }
    this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
      console.log(response);
      this.doctor_charge = response.filter((item) => item.module === "chat");
      console.log('doctor',this.doctor_charge);
      // response.map(res => {
      //     if (res.module == "chat"){
      //       this.doctor_charge = parseInt(res.doctor_charge);
    
      var data = {
        account_email: this.setaccount_email,
        patient_email: this.selectedPatientemail,
        category: "chat",
        description: "fee charged for chat",
        doctor_charges: this.doctor_charge[0].doctor_charge,
        user_type: "doctor",
        type: 3,
        appointment_id:"",
        doctor_id: this.setdrID,
        chat_id: chatID
      }
      console.log(data)
      this.appService.postData(this.urlPoints.raiseInvoice, data, this.setauthToken).subscribe((response: any) => {
        console.log(response);
        this.toastr.success('Payment raised successfully!');
      });
      
      //     }
      //   });

      });
        
  }
  
  //send message

  sendMessage(){
    
    if (!this.messageContent.message || this.messageContent.message === '') {
      return;
     }

    var data = {
      sender:  this.uuid,
      receiver: this.selectedPatientemail,
      message: this.messageContent.message,
      type: "doctor",
      message_type: 1,
      date: new Date(),
      payment: this.selectedPatientpayment,
      doctor_name: this.setdrName,
      doctor_id: this.setdrID
  }
  this.appService.postData(this.urlPoints.sendmessage, data, this.setauthToken).subscribe((response: any) => {
      this.chatId = response.id;
      this.pubnub.publish(
        {
          message: data,
          channel: this.channel
        },
        function (status, response) {
          if (status.error) {
            console.log(status)
          } else {
            console.log("message published w/ timetoken", response.timetoken)
          }
        }
      )
      this.messageContent = {message: ''};
      this.getMessages(response,data.receiver,this.selectedpatientImage);
      this.toastr.success('Message sent successfully!');
      this.raiseInvoice(this.chatId);
      
  });

}

keyPress(event) {
  let code = event.keyCode || event.which;
  if (code === 13) {
    event.preventDefault();
    this.sendMessage();
  }
}

refund(data){
  console.log(data);
      if (data=='admin'){
        this.toastr.error('Contact admin for payment');
        return;
    }
        data.account_email = this.setaccount_email;
        data.patient_email = data.receiver;
        data.created_at = data.date;
        data.type = 2;
        data.category = "refund";

        this.appService.postData(this.urlPoints.waivepayment, data, this.setauthToken).subscribe((response: any) => {
        console.log(response);
          this.toastr.success(response.message);

          this.param = {
            account_email: this.setaccount_email,
            doctor_id: this.setdrID,
          }
          
          this.appService.getData(this.urlPoints.getpaymentmaster, this.param, this.setauthToken).subscribe((response: any) => {
            response.map(res => {
                if (res.module == "chat"){
                  this.doctor_charge = parseInt(res.doctor_charge);
                  console.log(this.doctor_charge);
                }
            });
            var raiseData = {
              account_email: this.setaccount_email,
              patient_email: data.receiver,
              category: "refund",
              description: "",
              doctor_charges: this.doctor_charge,
              fee_paid: this.doctor_charge,
              user_type: "doctor",
              type: 2,
              appointment_id:"",
              doctor_id: this.setdrID,
              chat_id: data.id
          }
          
          this.appService.postData(this.urlPoints.chatrefundinvoice, raiseData, this.setauthToken).subscribe((response: any) => {
            if(response){
              this.toastr.success('Raised refund invoice');
              this.getMessages(response,data.receiver,this.selectedpatientImage);
            }
          });
        });
      },err => {
        console.log(err);
        this.toastr.error('Date Expired.Payment waive off not allowed');
       // check error status code is 500, if so, do some action
      });
}

}

@Component({
  selector: 'chatimage',
  templateUrl: 'chatimage.html',
  styleUrls: ['chatimage.scss']
})

export class DialogOverviewExampleDialog_chat {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_chat>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router) {

  }
  ngOnInit(): void {
console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
