<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-6">
            <h2> <img src="./assets/images/daily_collection/daily_collection.png" class="imgcustom"> <span class="headerClr"> Daily Range Collection Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-right: -20px;margin-top:15px;">
        <div class="col-md-8">  
            <div style="display:flex">
            <span style="margin-top: 3px;">Date :</span>
            <input style="margin-left: 10px;" type="text"
            ngxDaterangepickerMd
            [locale]="{format: 'DD-MM-YYYY'}"
            [(ngModel)]="selected"
            [maxDate]='maxDate'
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="true"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            placeholder="Select Date"/>
        </div>   
        </div>
        <div class="col-md-4">
            <div style="display:flex;float: right;margin-right:10px;margin-top:-5px">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr />
    <div id="mytable">
    <div class="row">
        <div class="col-md-12">
            <div style="text-align: center;font-weight: bold;"><label><h4>Daily Range Collection Report</h4></label></div>
        </div>
        <div class="col-md-8">
            <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label>
        </div>
        <div class="col-md-4">
            <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
        </div>   
    </div>
    <div class="row">
        <div class="col-md-2">
            <h5>Consolidated:</h5>
                <table class="table table-bordered" style="width:350px">
                    <thead>
                        <tr>
                            <th>Fees Charged</th>
                            <th>Fees Paid</th>
                            <th>Fees Pending</th>
                            <th>Fees Refund</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{feesCharged | currency:"&#8360;.&nbsp;"}}</td>
                            <td>{{feesPaid | currency:"&#8360;.&nbsp;"}}</td>
                            <td>{{feesPending | currency:"&#8360;.&nbsp;"}}</td> 
                            <td>{{feesRefund | currency:"&#8360;.&nbsp;"}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 search-table-outter">
            <h5>Break Up:</h5>
            <table class="table table-bordered">
                 <thead>
                        <tr class="text-center" >
                            <th>Voice Call</th>                                     
                             <th>IVRS</th>
                             <th>Emergency Video Call Consultation </th>
                             <th style="width:80px">Chat</th>                                     
                             <th>Consultation</th>
                             <th>Emergency Video Call Appointment</th>
                             <th>Video Call Appointment</th>
                             <th>Emergency Voice Call</th> 
                             <th>Registration </th>
                             <th>Appointment </th>
                         </tr> 
                    </thead>
                     <tbody>
                        <tr>
                            <td>{{feesVoicecall | currency:"&#8360;.&nbsp;"}}</td>                          
                            <td>{{feesIvrs | currency:"&#8360;.&nbsp;"}}</td>  
                            <td>{{feesEmerVideoConsu | currency:"&#8360;.&nbsp;"}}</td>     
                            <td width:40px>{{feesChat | currency:"&#8360;.&nbsp;"}}</td>                                    
                            <td>{{feesConsu | currency:"&#8360;.&nbsp;"}}</td>               
                            <td>{{feesEmerVideoAppo | currency:"&#8360;.&nbsp;"}}</td>               
                            <td>{{feesVideoCall | currency:"&#8360;.&nbsp;"}}</td>  
                            <td>{{feesEmerVoicecall | currency:"&#8360;.&nbsp;"}}</td>                                                                                  
                            <td>{{feesRegistration | currency:"&#8360;.&nbsp;"}}</td>   
                            <td>{{feesAppo | currency:"&#8360;.&nbsp;"}}</td>   
                        </tr>
                        <tr>  
                            <td colspan="4"></td> 
                            <th>Total Pending</th>
                            <td>{{totalPending | currency:"&#8360;.&nbsp;"}}</td> 
                            <th>Total Refund</th>
                            <td>{{totalRefund | currency:"&#8360;.&nbsp;"}}</td>
                            <th>Total Fees Collected</th>                                      
                            <td>{{totalCollected | currency:"&#8360;.&nbsp;"}}</td> 
                        </tr>
                     </tbody>
            </table>
        </div>
    </div>

    <div class="row" style="margin-top: 10px;">
        <div class="col-md-12 search-table-outter">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th rowspan="2">S.No</th>
                    <th rowspan="2">MR Number</th>
                    <th rowspan="2" style="width:100px">Name</th>
                    <th rowspan="2">Product</th>       
                    <th rowspan="2">Used Through</th>
                    <th rowspan="2">Paid Through</th>
                    <th colspan="5" class="text-center">Fees</th>
                    <th rowspan="2">Reg Date&Time</th>
                    <th rowspan="2">Bill Raised</th>
                    <th rowspan="2">Bill Date&Time</th>                       
            <!-- <th rowspan="2">Bill Cancelled</th>
                    <th rowspan="2">Bill Cancel Date&Time</th>
                    <th rowspan="2">GST</th> -->
                    <th rowspan="2">Remarks</th>
                </tr>
                <tr>
                    <th>Charged</th>
                    <th>Waived</th>
                    <th>Paid</th>
                    <th>Pending</th>
                    <th>Refund</th>
                </tr>
            </thead>

            <tbody class="text-center">
                <tr *ngFor="let collectionRsult of getCollectionRsultList;let i=index;">
                    <td>{{i+1}}</td>
                        <td>{{collectionRsult.mr_number}}</td>
                        <td>{{collectionRsult.patient_name}}</td>
                        <td class="breakWord" style="width:100px">{{collectionRsult.category}}</td>
                        <td>{{collectionRsult.payment_mode}}</td>
                        <td>{{collectionRsult.paidThru}}</td>
                        <td>{{collectionRsult.total_charge | currency:"&#8360;.&nbsp;"}}</td>
                        <td>{{collectionRsult.waived | currency:"&#8360;.&nbsp;"}}</td>
                        <td>{{collectionRsult.fee_paid | currency:"&#8360;.&nbsp;"}}</td>
                        <td>{{collectionRsult.fee_pending | currency:"&#8360;.&nbsp;"}}</td>
                        <td>{{collectionRsult.refund_fee | currency:"&#8360;.&nbsp;"}}</td>
                        <td>{{collectionRsult.registration_date | date:'dd-MMM-yyyy h:mma'}}</td>
                        <td class="breakWord">{{collectionRsult.bill_no}}</td>
                        <td class="breakWord">{{collectionRsult.bill_date | date:'dd-MMM-yyyy h:mma'}}</td>
                        <td class="breakWord">{{collectionRsult.description}}</td>
                </tr>
                <tr>
                    <td colspan="5"></td>
                    <th>Total</th>
                    <td>{{feesCharged| currency:"&#8360;.&nbsp;"}}</td>
                    <td>-</td>
                    <td>{{feesPaid | currency:"&#8360;.&nbsp;"}}</td>
                    <td>{{feesPending | currency:"&#8360;.&nbsp;"}}</td>
                    <td>{{feesRefund | currency:"&#8360;.&nbsp;"}}</td>
                    <td colspan="3"></td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</div>

