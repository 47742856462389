<div class="row">
    <div class="col-md-12">
        <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
    </div>
    <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
        <table class="table table-bordered">
            <thead style="background-color: #adccff;">
                <tr>
                    <th class="text-center" style="width: 85px;">S.No</th>
                    <th class="text-center" style="width: 85px;">Abortion Date</th>
                    <th class="text-center" style="width: 85px;">Abortion Type</th>
                    <th class="text-center" style="width: 85px;">Term</th>
                    <th class="text-center" style="width: 370px;">Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr style="border: rgb(0, 0, 0);" *ngFor="let item of viewabortionDetails; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{item.abortion_date}}</td>
                    <td>{{item.abortion_type}}</td>
                    <td>{{item.mode}}</td>
                    <td class="overflownotes" style="text-align: center;">{{item.notes}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- <style>
    .overflownotes {
    height: 135px;
    display: inline-block;
    width: 452px;
    overflow: auto;
}
</style> -->