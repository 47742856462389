<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-12">
            <h2><img src="./assets/images/analytics/analytics.png" class="imgcustom"> <span class="headerClr"> VHN Performance Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-top:20px">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-6">
            <form [formGroup]="filterregisration">
                <div class="form-row">
                    <div class="form-group col-3">
                        <label>District</label>
                        <select class="custom-select" (change)="ondistrictChange($event)" formControlName="distName">
                        <option value="">select</option>
                        <option *ngFor="let getdistrictList of getdistrict; let i = index" [ngValue]="getdistrictList.id">{{getdistrictList.districtname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>Block</label>
                        <select class="custom-select" (change)="onblockChange($event)" formControlName="distName">
                        <option value="">select</option>
                        <option *ngFor="let getblockList of getblock; let i = index" [ngValue]="getblockList.id">{{getblockList.blockname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>VHN</label>
                        <select class="custom-select" (change)="onvhnChange($event)" formControlName="distName">
                        <option [ngValue]="0">All</option>
                        <option *ngFor="let staffprofileList of staffprofile; let i = index" [ngValue]="staffprofileList.staffid">{{staffprofileList.staffname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>Village</label>
                        <select class="custom-select" (change)="onvilageChange($event)" formControlName="distName">
                        <option [ngValue]="0">All</option>
                        <option *ngFor="let staffvillageList of staffvillage; let i = index" [ngValue]="staffvillageList.village_id">{{staffvillageList.villagename}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row" style="margin-top:20px">
                    <div class="form-group col-8">
                        <div style="display:flex">
                            <span style="margin-top: 3px;">Date :</span>
                            <input style="margin-left: 10px;" type="text"
                            ngxDaterangepickerMd
                            [locale]="{format: 'DD-MM-YYYY'}"
                            [(ngModel)]="selected" [ngModelOptions]="{standalone: true}"
                            [showCustomRangeLabel]="true"
                            [alwaysShowCalendars]="true"
                            [ranges]="ranges"
                            [linkedCalendars]="true"
                            [isInvalidDate] = "isInvalidDate"
                            [showClearButton]="true"
                            (rangeClicked)="rangeClicked($event)"
                            (datesUpdated)="datesUpdated($event)"
                            placeholder="Select Date"/>

                            <button class="buttonOutside" style="width:100px;margin-left: 20px;" (click)="getReport()"><div class="buttonInside">Get Report</div></button>   
                        </div>
                        
                    </div>
                    <div class="col-md-4">
                        <div style="display:flex;float: right;margin-right:10px;">
                            <div style="margin-top: 2px;"><h4>Download : </h4></div>
                            <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                            <!-- <div style="margin-left:10px;" class="addDr" (click)="downloadCSV()"><div class="addDrTxt">CSV</div></div> -->
                            <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </div>
    <hr>
    <div style="text-align:center;color:red" *ngIf="visitReoprt == undefined">Data is not available......</div>
    <div id="printtable" *ngIf="visitReoprt != undefined">
        <div class="row">
            <div class="col-md-12">
                <div style="text-align: center;font-weight: bold;"><label><h4>VHN Performance Report</h4></label></div>
            </div>
            <div class="col-md-8">
                <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label>
            </div>
            <div class="col-md-4">
                <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
            </div>   
        </div>
    <div class="row">
        <div class="col-md-12">
            <h5>Consolidated:</h5>
            <table class="table table-bordered">
                <thead>
                    <th style="font-weight: bolder;">S.No</th>
                    <th style="font-weight: bolder;">Village Name</th>  
                    <th style="font-weight: bolder"># of Scheduled Visits</th>
                    <th style="font-weight: bolder;"># of Visits Made</th>
                    <th style="font-weight: bolder"># of Visits Missed</th>
                    <th style="font-weight: bolder">% of Visits Made</th> 
                    <th style="font-weight: bolder;">% of Visits Missed</th> 
                </thead>
                <tbody>
                    <tr *ngFor="let visitReoprtList of visitReoprt;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{visitReoprtList.villagename}}</td>
                        <td>{{visitReoprtList.scheduled}}</td>
                        <td>{{visitReoprtList.visited}}</td>
                        <td>{{visitReoprtList.notvisited}}</td>
                        <td>{{visitReoprtList.adherence}}%</td>
                        <td>{{visitReoprtList.nonadherence}}%</td>
                    </tr>
                    <tr>
                        <td colspan="2"> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">Grand Total</b></td>
                        <td> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">{{grandTotalscheduled}}</b></td>
                        <td> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">{{grandTotalontime}}</b></td>
                        <td> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">{{grandTotalnotontime}}</b></td>
                        <td> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">{{grandTotalcompliance}}%</b></td>
                        <td> <b style="color: unset !important; font-size: 16px; font-weight: bolder;">{{grandTotalnoncompliance}}%</b></td>                                    
                    </tr>
                </tbody>  
            </table>
        </div>
    </div>
    </div>
  </div>
</div>

