import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from '../app.config';
import { AppService } from '../app.service';
import { StoredService } from '../wrapper/services/stored';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { Toast, ToastrService } from 'ngx-toastr';
import { Observable,Subscription, interval  } from 'rxjs';


@Component({
  selector: 'app-twofactAuth',
  templateUrl: './twofactAuth.component.html',
  styleUrls: ['./twofactAuth.component.scss'],
  providers: [PubNubAngular]
})
export class TwofactauthComponent implements OnInit {
  route;
  dmail;
  doc_id;
  token;
  urlPoints;
  twofacturl;
  pubnub: PubNubAngular;
  uuid:any;
  channelName:any;
  interval:any;
  private updaterequest: Subscription;
  

  constructor(private router:Router,private appService: AppService,private toastr: ToastrService,private storedService:StoredService,pubnub: PubNubAngular,private dom: DomSanitizer) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;

    this.interval = setInterval(() => {
      this.getStatus();
    }, 60 * 1000);
  }

  ngOnInit() {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    this.uuid = this.dmail;
    this.channelName = 'doctor_login_auth_' + this.dmail + '_' + this.doc_id
    this.twofactAuth();
    this.responseStatus();

  }


  twofactAuth(){
    
    var params = {
      "account_email" : this.dmail,
      "doctor_id" : this.doc_id
    }
    this.appService.postData(this.urlPoints.twofactAuth, params, this.token).subscribe((response: any) => {
      if(response){
        if(response.success == 1){
          let geturl = environment.HOST+'/docAuth/?'+'ac='+response.auth_code;
          this.twofacturl = this.dom.bypassSecurityTrustResourceUrl(geturl);
        }else{
          console.log("Not Load two fact")
        }
      }else{console.log("API Not Responded")}
    });
  }

  responseStatus(){
    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: this.uuid
  });
    this.pubnub.subscribe({
      channels: [this.channelName],
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(this.channelName, (msg) => {
      console.log(msg);
      if(msg.message == "true"){
        localStorage.setItem('twofact_auth','true');
        this.router.navigate(['/epicare/appointment']);
        if (this.interval) {
          clearInterval(this.interval);
       }
      }else if(msg.message == "false"){
        this.toastr.error("Access Denied!");
        this.router.navigate(['']);
      }
    });

  }

  getStatus(){
    var params = {
      "account_email" : this.dmail,
      "doctor_id" : this.doc_id
    }
    this.appService.getData(this.urlPoints.gettwofactAuthstatus, params, this.token).subscribe((response: any) => {
      if (response.doctors.success == 1) {
        if (response.doctors.details.status == 2) {  
          localStorage.setItem('twofact_auth','true');
          this.router.navigate(['/epicare/appointment']);
        }else if (response.doctors.details.status == 1){
          this.toastr.error("Access Denied!");
          this.router.navigate(['']);
        }
      }
    });
  }
  
  ngOnDestroy() {
    console.log('ngOnDestroy: cleaning up...');
    clearInterval(this.interval);
  }
  

}


