<div class="container">
    <div>
        <i style="color:#0063FF;float:right" class="fa fa-close" (click)="close()"></i>
    </div>
    <div>
        <h1>{{data.name}}</h1>
        <div>Description:</div>
        <textarea rows="4" style="border-radius:5px;width:100%;height:auto;" cols="50" [(ngModel)]="textAreaValue"></textarea>
    </div>
    <div style="position:relative;justify-content:center;display:flex">
        <button class="btn btn-primary" (click)="save()">Save</button>
    </div>

</div>
