<body>
    <div class="row">
          <!--left View-->
          <div class="bodycontentLefView">
              <div class="patientListContainar">
                my patient list
              </div>
            
          </div>

        <!--Right View-->
          <div class="bodycontentRightView">
            <div class="grid-table">
              <div class="row controw">
                <div class="col-md-4">
                  <p>All patients <span class="badge">{{patient_Count}}</span>
                 <!-- <span matBadge="" matBadgeOverlap="false" matBadgeSize="medium"></span> -->
                  </p>
                </div>
                
                <div class="col-md-8 quesearch">
                  <div style="margin-top: -5px;font-size: 14px;text-align: right;">
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      [color]="'primary'"
                      [(ngModel)]="searchOption">
                      <mat-radio-button class="example-radio-button" [value]="0" (change)="searchChange('0')">All</mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="1" (change)="searchChange('1')">Name</mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="2" (change)="searchChange('2')">MR Number</mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="3" (change)="searchChange('3')">Phone</mat-radio-button>
                      <mat-radio-button class="example-radio-button" [value]="4" (change)="searchChange('4')">DOB</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <input *ngIf="searchOption =='0'" class="form-control" autofocus="false" placeholder="Search" 
                  name="srch-term" 
                  id="srch-term"
                  [(ngModel)]="filterName" 
                  type="text"
                  (keyup)="searchPatient($event.target.value)" [ngClass]="{'disabled': searchOption =='0'}" disabled>
                  
                  <input *ngIf="searchOption =='1'" class="form-control" autofocus="false"
                  placeholder="Search Name" 
                  name="srch-term" 
                  id="srch-term"
                  [(ngModel)]="filterName" 
                  type="text"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);"
                  (keyup)="searchPatient($event.target.value)" [ngClass]="{'disabled': searchOption =='0'}">
                  
                  <input *ngIf="searchOption =='2'" class="form-control" autofocus="false"
                  placeholder="Search MR Number" 
                  name="srch-term" 
                  id="srch-term"
                  [(ngModel)]="filterName" 
                  type="number"
                  (keyup)="searchPatient($event.target.value)">
                
                  <input *ngIf="searchOption =='3'" class="form-control" autofocus="false"
                  placeholder="Search Phone Number" 
                  name="srch-term" 
                  id="srch-term"
                  [(ngModel)]="filterName" 
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="10"
                  (keyup)="searchPatient($event.target.value)">

                  <div *ngIf="searchOption =='4'" style="display: flex;margin-left: auto;float:right;width: 50%;">
                    <input class="form-control" autofocus="false"
                    placeholder="Choose Date Of Birth" 
                    [(ngModel)]="todayDate1" 
                    readonly
                    name="srch-term" 
                    id="srch-term"
                    style="width: 100%;border-right: none !important;border-top-right-radius: unset;border-bottom-right-radius: unset;">
                    <img style="width: 1.5rem;cursor:pointer;margin-right: 1rem;background: #e9f2ff;border: 3px solid #cbdbf3;border-left: none;padding-right: 2px;border-top-right-radius: 4px;border-bottom-right-radius: 4px;" src="assets/leftmenu/today.svg"
                    (click)="picker.open()" title="calender">
                    <mat-form-field style="visibility: hidden;width: 0px !important;height: 0px;">
                      <input matInput [matDatepicker]="picker" [(ngModel)]="todayDate" (dateChange)="onDateChange($event);searchPatient($event.target.value)" placeholder="Choose Date Of Birth" [max]="maxDate">
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker [calendarHeaderComponent]="exampleHeader" [startAt]="maxDate"></mat-datepicker>
                    </mat-form-field>


                  </div>

                </div>
              </div>

              <table mat-table [dataSource]="dataSource" style="width:100%;height: 10vh;">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> Name </th>
                  <td mat-cell *matCellDef="let element" style="width:20%;"> 
                    <img 
                    *ngIf="(element.image == 'No profile picture' || element.image == null || element.image == 'null' || element.image == '' || element.image == 'None')&&(element.gender == 'male')" 
                    style="display: inline-block;" src="assets/avatar-male.png" class="tableavatar"> 

                    <img 
                    *ngIf="(element.image == 'No profile picture' || element.image == null || element.image == 'null' || element.image == '' || element.image == 'None')&&(element.gender == 'female')" 
                    style="display: inline-block;" src="assets/avatar-female.png" class="tableavatar"> 
                    
                    <img *ngIf="element.image != 'No profile picture' && element.image != null && element.image != 'null' && element.image != '' && element.image != 'None'" style="display: inline-block;" src="{{imageUploadUrl}}{{element.image}}" class="tableavatar"> 
                    
                    <span style="font-weight: 600;"> &nbsp;{{element.name | titlecase}} </span>
                   </td> 
                </ng-container>
                <ng-container matColumnDef="mrno">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> MR Number </th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.mrno}} </td>
                </ng-container>
                <ng-container matColumnDef="gender">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> Gender </th>
                  <td mat-cell *matCellDef="let element" style="width:10%"> {{element.gender | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> Mobile</th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.mobile}} </td>
                </ng-container>
                <ng-container matColumnDef="dob">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> DOB</th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.dob}} </td>
                </ng-container>
                <ng-container matColumnDef="age">
                  <th mat-header-cell *matHeaderCellDef class="tablehead"> Age</th>
                  <td mat-cell *matCellDef="let element" style="width:20%"> {{element.age}} </td>
                </ng-container>
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer;" (click)="gotoapptqueue(row)"></tr>
              </table>
              <div class="emptypatientlist" *ngIf="listcount == 0">No Records Found</div>
              <div style="float: right;margin-top: 10px;margin-right: 20px;" *ngIf="pagination">
                <app-paginate style="margin-top: 1rem;" [items]="pagelist" [pages]="count" (changePage)="onChangePage($event)"></app-paginate>
              </div>
            </div>
           
          </div>
    </div>
  <br>
</body>  
  
  