
import { componentFactoryName } from '@angular/compiler';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { data } from 'jquery';
import { MatSelect } from '@angular/material/select';

export interface EngagementElement {
  messagining: any;
  target: any;
  frequency: any;
  Scheduled: any;
  next: any;
}

export interface engagementEdit {
  data: any;
}

// export interface EngagementMessagingElement {
//   reminder: string;
//   edit: any;
//   delete: any;
// }

let ENGAGEMENT_DATA: EngagementElement[] = [];

// const ENGAGEMENT_MESSAGING: EngagementMessagingElement[] = [

// ]

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.scss']
})
export class EngagementComponent implements OnInit {
  searchOption: any=1;
  pagination = true;
  urlPoints;
  dmail: any;
  token: any;
  engagementview = 0;
  engagementview_1 = 0;
  engagementview_2 = 0;
  engagementSettings;
  pageOfItems: any;

  constructor(private _bottomSheet: MatBottomSheet, private appService: AppService, private fb: FormBuilder, private _changeDetectorRef: ChangeDetectorRef) { 
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.token = getauthdetails.token;
    this.engagementSettings = this.fb.group({
      allActiveInactive: new FormControl(1,Validators.required)
    });
    console.log(this.engagementSettings);
  }

  dataSource = new MatTableDataSource<EngagementElement>(ENGAGEMENT_DATA);;
  displayedColumns: string[] = ['messagining', 'target', 'frequency', 'Scheduled', 'next'];


  searchChange(searchvalue){
    this.searchOption = searchvalue;
    if (this.searchOption == '0'){
      this.pageOfItems = 1;
      // this.pagination = true
      let data = {
        "account_email": this.dmail,
        "engagement_status": 'all'
      }
      this.appService.postData(this.urlPoints.viewengagement, data, this.token).subscribe((Response: any)=>{
        ENGAGEMENT_DATA = [];
        this.engagementview = Response.length;
        console.log(this.engagementview);
        console.log(Response);
        for (var i=0;i<Response.length;i++) {

          ENGAGEMENT_DATA.push({
            messagining: Response[i].engagement_name,
            target: Response[i].target_users,
            frequency: Response[i].frequency,
            Scheduled: Response[i].engagement_scheduled_dt,
            next: Response[i].next_run_date
          });
          console.log(ENGAGEMENT_DATA);
        }
        this.dataSource = new MatTableDataSource(ENGAGEMENT_DATA);
      });
    }else if (this.searchOption == '1'){
      this.pagination = true
      let data = {
        "account_email": this.dmail,
        "engagement_status": 'true'
      }
      this.appService.postData(this.urlPoints.viewengagement, data, this.token).subscribe((Response: any)=>{
        ENGAGEMENT_DATA = [];
        this.engagementview_1 = Response.length
        console.log(Response);
        console.log(this.engagementview_1);
        for (var i=0;i<Response.length;i++) {

          ENGAGEMENT_DATA.push({
            messagining: Response[i].engagement_name,
            target: Response[i].target_users,
            frequency: Response[i].frequency,
            Scheduled: Response[i].engagement_scheduled_dt,
            next: Response[i].next_run_date
          });          
        }
        this.dataSource = new MatTableDataSource(ENGAGEMENT_DATA)
        setTimeout(() => {
          this._changeDetectorRef.markForCheck();
        }, 500);
      })
    }else if (this.searchOption == '2'){
      // this.pagination = true
      let data = {
        "account_email": this.dmail,
        "engagement_status": "false"
      }
      this.appService.postData(this.urlPoints.viewengagement, data, this.token).subscribe((Response: any) => {
        ENGAGEMENT_DATA = [];
        this.engagementview_2 = Response.length
        console.log(Response);
        console.log(this.engagementview_2);
        for(var i=0;i<Response.length;i++){

          ENGAGEMENT_DATA.push({
            messagining: Response[i].engagement_name,
            target: Response[i].target_users,
            frequency: Response[i].frequency,
            Scheduled: Response[i].engagement_scheduled_dt,
            next: Response[i].next_run_date
          });
        }
        this.dataSource = new MatTableDataSource(ENGAGEMENT_DATA)
      })
    } 
  }  

  openBottomSheet(): void {
    // const Dialogthis._bottomSheet.open(DialogOverviewExampleDialog_engagementmessageing);
      const dialogRef = this._bottomSheet.open(DialogOverviewExampleDialog_engagementmessageing, {
        data: { },
      });
  }

}

// interface Food {
//   value: string;
//   viewValue: string;
// }


@Component({
  selector: 'engagementmessageing1',
  templateUrl: 'engagement-messaging.html',
  styleUrls: ['engagement-messaging.scss']
})

export class DialogOverviewExampleDialog_engagementmessageing {

  searchOption:any = 1;
  filterName = '';
  pagination = false;
  engagementMessaging;
  showMessage: any;
  targetUser: any;
  gender: any;
  andOrNot: any;
  reminders: any;
  information: any;
  educational: any;
  remainderArray = [];
  frequencyChangeVal;
  triggerChangeVal;
  display = true;
  changeComplaintVal = false;
  changeVal = false;
  changeComplaintValue = false;
  urlPoints;
  dmail;
  token;
  // editDetails = true;



  // foods: Food[] = [
  //   {value: 'steak-0', viewValue: 'Steak'},
  //   {value: 'pizza-1', viewValue: 'Pizza'},
  //   {value: 'tacos-2', viewValue: 'Tacos'},
  // ];

  // important
  // if (this.showMessage == 0) {
  //   var showMessageEngagement = 'Active'
  // }else {
  //   var showMessageEngagement = 'Inactive'
  // }
  // if (this.targetUser == 0) {
  //   var targetUserEngagement = 'Registered Patients'
  // }elseif (this.targetUser == 1){
  //   var targetUserEngagement = 'Providers'
  // }elseif (this.targetUser == 2) {
  //   var targetUserEngagement = 'Vendor'
  // }elseif (this.targetUser == 3) {
  //   var targetUserEngagement = 'Providers'
  // }elseif (this.targetUser == 4) {
  //   var targetUserEngagement = 'Doctor'
  // }else {
  //   var targetUserEngagement = 'Helyxon' 
  // }
  // if (this.searchOption == 0){
  //   var searchOptionEngagement = 'Trigger'
  // }elseif (this.searchOption == 1) {
  //   var searchOptionEngagement = 'Age'
  // }elseif (this.searchOption == 2) {
  //   var searchOptionEngagement = 'Calender'
  // }else {
  //   var searchOptionEngagement = 'Repeat Messaging'
  // }
  // if (this.gender == 0) {
  //   var genderEngagement = 'Male'
  // }else {
  //   var genderEngagement = 'Female'
  // }
  // if (this.andOrNot == 0) {
  //   var andOrNotEngagement = 'And'
  // }elseif (this.andOrNot == 1) {
  //   var andOrNotEngagement = 'Or'
  // }else {
  //   var andOrNotEngagement = 'Not'
  // }
  // if (this.reminders == 0) {
  //   var reminderEngagement = 'Follow Up'
  // }elseif (this.reminders == 1) {
  //   var reminderEngagement = 'Vaccination'
  // }elseif (this.reminders == 2) {
  //   var reminderEngagement = 'Investigstion'
  // }elseif (this.reminders == 3) {
  //   var reminderEngagement = 'Home Visit'
  // }else {
  //   var reminderEngagement = 'Pending Payment'
  // }
  // if (this.information == 0) {
  //   var informationEngagement = 'Appointment Delay'
  // }elseif (this.information  == 1) {
  //   var informationEngagement = 'Expert Doctor Visit'
  // }elseif (this.information == 2) {
  //   var informationEngagement = 'Holiday'
  // }else {
  //   var informationEngagement = 'Special Programs'
  // }
  // if (this.educational == 0) {
  //   var educationalEngagement = 'Hygine'
  // }else {
  //   var educationalEngagement = 'Nutrition'
  // }
  tag = new FormControl();



  gettagData;
  doc_id;
  showDropdown: boolean= false;



  constructor(private _bottomSheetRef: MatBottomSheetRef<DialogOverviewExampleDialog_engagementmessageing>, public dialog: MatDialog, private _changeDetectorRef: ChangeDetectorRef, private fb: FormBuilder,private appService: AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {
  
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    console.log("getauthdetails::",getauthdetails);
    this.dmail= getauthdetails.account_id;
    this.doc_id= getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    this.engagementMessaging = this.fb.group({
      'showMessage': new FormControl(0,Validators.required),
      'name': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'targetUsers': new FormControl('',Validators.required),
      'scheduling': new FormControl(0,Validators.required),
      'triggerType': new FormControl('',Validators.required),
      'year': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'month': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'day': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'selectDay': new FormControl('',Validators.required),
      'selectStartDate': new FormControl('',Validators.required),
      'selectEndDate': new FormControl('',Validators.required),
      'selectFrequency': new FormControl('',Validators.required),
      'frequencyMonthWeek': new FormControl('',Validators.required),
      'FrequencyWeek': new FormControl('',Validators.required),
      'gender': new FormControl('',Validators.required),
      'andOrNot': new FormControl(0,Validators.required),
      'ageFromYears': new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(3)]),
      'ageFromMonths': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'ageFromDays': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'andOrNot1': new FormControl(0,Validators.required), 
      'ageToYears': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'ageToMonths': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'ageToDays': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(3)]),
      'andOrNot2': new FormControl(0,Validators.required),
      'location': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'andOrNot3': new FormControl(1,Validators.required),
      'diagnosis': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'andOrNot4': new FormControl(1,Validators.required),
      'careGroup': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'andOrNot5': new FormControl(1,Validators.required),
      'prescription': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'andOrNot6': new FormControl(1,Validators.required),
      'reminders': new FormControl(0,Validators.required),
      'information': new FormControl('',Validators.required),
      'educational': new FormControl('',Validators.required),
    });
    this.getalltags(1)
    // this.remainderArray.push(this.result)
  }

  closeSelect(selectElement: MatSelect) {
    selectElement.close();
}
  getalltags(pagenum): void {
    let params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      page_number: pagenum
    }
    this.appService.getData(this.urlPoints.get_carenettags, params, this.token).subscribe((response: any) => {
      this.gettagData = response.message;
    });
  }
  // displayedColumns: string[] = ['reminder', 'edit', 'delete'];
  // dataSource = ENGAGEMENT_MESSAGING;

  onSubmit() {
    console.log(this.fb);
    // console.log(this.engagementMessaging.name.errors);
    // console.log(this.engagementMessaging.name.valid);
    this._bottomSheetRef.dismiss();
    console.log(this.engagementMessaging);
    console.log(this.engagementMessaging.valid);
    // console.warn(this.engagementMessaging.value);
    
  }

  frequencyChange() {
    let formvalue = this.engagementMessaging.value;
    this.frequencyChangeVal = formvalue.selectFrequency;
  }

  triggerChange() {
    let formvalue = this.engagementMessaging.value;
    this.triggerChangeVal = formvalue.triggerType;
  }

  changeComplaint() {
    this.changeComplaintVal = true;
  }

  changeComplaint1() {
    this.changeVal = true;
  }

  changeComplaint2() {
    this.changeComplaintValue =true;
    // this.changeComplaintValue =false;
  }


  onNoClick(): void{
    this._bottomSheetRef.dismiss();
  }


  searchChange(searchvalue){
    this.searchOption = searchvalue;
    // this.filterName = '';
    this.pagination = true;
    if (this.searchOption == '0') {
      this.pagination = true;
    }
    else if (this.searchOption == '1') {
      this.pagination = true;
    }
    else if (this.searchOption == '2') {
      this.pagination = true;
    }
    else if (this.searchOption == '3') {
      this.pagination = true;
    }
  }


  reminderEdit(i) {
    // this.editDetails = true;
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_engagementPopup, {
      data: {name: this.remainderArray[i]}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result1',result);
      console.log('resulttt',result.value.remainderName);
      this.remainderArray[i].remainderName=result.value.remainderName;
      this.remainderArray[i].remainderDays=result.value.remainderDays;
      this.remainderArray[i].beforeAfter=result.value.beforeAfter;
      this.remainderArray[i].content=result.value.content;
      setTimeout(() => {
        this._changeDetectorRef.markForCheck();
      }, 500);
    })
  }
  
  reminderDelete(i) {
    this.remainderArray.splice(i,1)
  }

  openDialog(): void{
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_engagementPopup, {
      height: '930px',
      width: '850px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result :',result.value);
      if (result.value.remainderName == '') {
        alert('Reminders is missing')
      }else {

        this.remainderArray.push(result.value)
        setTimeout(() => {
          this._changeDetectorRef.markForCheck();
        }, 500);
        console.log(this.remainderArray);
      }
    });

  }

  
}



@Component({
  selector: 'engagementpopup',
  templateUrl: 'engagement-popup.html',
  styleUrls: ['engagement-popup.scss']
})
 
export class DialogOverviewExampleDialog_engagementPopup {

  date: any;
  engagementPopup;
  selectedArrayAll: any = [];
  engagementPopup1;
  all = true;
  sms = true;
  notification = true;
  email = true; 
  ivrs = true;
  call = true;
  location = true;
  camcorder = true;
  ads = true;



  constructor (public dialogRef: MatDialogRef<DialogOverviewExampleDialog_engagementPopup>, @Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder) {}

  ngOnInit () : void{

    this.engagementPopup = this.fb.group({
      'beforeAfter': new FormControl('',Validators.required),
      'remainderDays': new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(4)]),
      'remainderName': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(20)]),
      'content': new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]*'),Validators.minLength(3), Validators.maxLength(50)]),
      'text': new FormControl('',Validators.required),
      'image': new FormControl('',Validators.required),
      'audio': new FormControl('',Validators.required),
      'video': new FormControl('',Validators.required),
      'all': new FormControl('',Validators.required),
      'sms': new FormControl(false,Validators.required),
      'notification': new FormControl(false,Validators.required),
      'email': new FormControl(false,Validators.required),
      'ivrs': new FormControl(false,Validators.required),
      'call': new FormControl(false,Validators.required),
      'location': new FormControl(false,Validators.required),
      'camcorder': new FormControl(false,Validators.required),
      'ads': new FormControl(false,Validators.required)
    });




    // this.engagementPopup1 = this.fb.group({,
    // });
    // this.selectedArrayAll.push(this.engagementPopup)

    // console.log(this.selectedArrayAll);    
    // console.log(this.engagementPopup1);
    
    console.log(this.data.name.remainderName);
    console.log(this.data.name);
    console.log();
    
    

    if (this.data.name) {
      this.engagementPopup.controls['beforeAfter'].setValue(this.data.name.beforeAfter);
      this.engagementPopup.controls['remainderDays'].setValue(this.data.name.remainderDays);
      this.engagementPopup.controls['remainderName'].setValue(this.data.name.remainderName);
      this.engagementPopup.controls['content'].setValue(this.data.name.content);
      this.engagementPopup.controls['text'].setValue(this.data.name.text);
      this.engagementPopup.controls['image'].setValue(this.data.name.image);
      this.engagementPopup.controls['audio'].setValue(this.data.name.audio);
      this.engagementPopup.controls['video'].setValue(this.data.name.video);
      this.engagementPopup.controls['all'].setValue(this.data.name.all);
      this.engagementPopup.controls['sms'].setValue(this.data.name.sms);
      this.engagementPopup.controls['notification'].setValue(this.data.name.notification);
      this.engagementPopup.controls['email'].setValue(this.data.name.email);
      this.engagementPopup.controls['ivrs'].setValue(this.data.name.ivrs);
      this.engagementPopup.controls['call'].setValue(this.data.name.call);
      this.engagementPopup.controls['location'].setValue(this.data.name.location);
      this.engagementPopup.controls['camcorder'].setValue(this.data.name.camcorder);
      this.engagementPopup.controls['ads'].setValue(this.data.name.ads);

    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveData() {
    this.dialogRef.close(this.engagementPopup);
  }

  selectAll() {

    console.log(this.engagementPopup.value.all);
    if(this.engagementPopup.value.all == true){
      this.engagementPopup.controls['sms'].setValue(true)
      this.engagementPopup.controls['notification'].setValue(true);
      this.engagementPopup.controls['email'].setValue(true);
      this.engagementPopup.controls['ivrs'].setValue(true);
      this.engagementPopup.controls['call'].setValue(true);
      this.engagementPopup.controls['location'].setValue(true);
      this.engagementPopup.controls['camcorder'].setValue(true);
      this.engagementPopup.controls['ads'].setValue(true);
    } else {
      this.engagementPopup.controls['sms'].setValue(false);
      this.engagementPopup.controls['notification'].setValue(false);
      this.engagementPopup.controls['email'].setValue(false);
      this.engagementPopup.controls['ivrs'].setValue(false);
      this.engagementPopup.controls['call'].setValue(false);
      this.engagementPopup.controls['location'].setValue(false);
      this.engagementPopup.controls['camcorder'].setValue(false);
      this.engagementPopup.controls['ads'].setValue(false);
    }
    // console.log(this.engagementPopup.get('sms'));
    // this.selectedArrayAll.push({sms: this.engagementPopup.get('sms'),notification: this.engagementPopup.get('notification'),
    // email: this.engagementPopup.get('email'),ivrs: this.engagementPopup.get('ivrs'),call: this.engagementPopup.get('call'),
    // location: this.engagementPopup.get('location'),camcorder: this.engagementPopup.get('camcorder'),ads: this.engagementPopup.get('ads')});
    // console.log(this.selectedArrayAll);
    // if (this.selectedArrayAll == true){

    // }
  }
}

