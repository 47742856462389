import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { PubNubAngular } from 'pubnub-angular2';
pubnub: PubNubAngular;
import { Toast, ToastrService } from 'ngx-toastr';
import { QrscanerComponent } from '../qrscaner/qrscaner.component';
import { GynecrepotComponent } from '../gynecrepot/gynecrepot.component';

export interface DialogData_printList {
  chartData: any,
  id: any,
  printData : any,
  dmail:any
  token: any
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  date = moment(new Date());
  urlPoints;
  month;
  dmail;
  token;
  current_patient_mr_number;
  current_patient_name;
  current_patient_token;
  estimated_time;
  next_patient_mr_number;
  next_patient_name;
  next_patient_token;
  patients_consulted;
  patients_waiting;
  list;
  doc_id;
  allCount =0;
  confirmedCount =0;
  arrivedCount =0;
  closedCount =0;
  queueList: any;
  appindex = 2;
  full = true;
  startstatus = false;
  update=false
  emergency_indication = false;
  sub;
  patientRecordId;
  docData;
  dr_Name;
  checkVitallAccessStatus = 0;

  fileSrc = 'assets/file.svg';
  fileSrcRed = 'assets/file_red.svg';
  printData;
  unseenCount=0;
  pubnub;

  constructor(private appService: AppService,private toastr: ToastrService,private route: ActivatedRoute,  pubnub: PubNubAngular, private router: Router,private elRef: ElementRef ,  public dialog: MatDialog) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;

   }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    this.pubnub1();

    
    $("#menu-toggle").click(function (e) {
      $("#sidebar-wrapper1").addClass("active");
      e.preventDefault();
    });

    $("#menu-close").click(function (e) {
      $("#sidebar-wrapper1").removeClass("active");
      e.preventDefault();
    });

    this.appointmentCount();
    this.apptabs(this.appindex);
    this.getdrProfile();
    this.checkVitallAccess();
    this.getPrintCount()
  }

  closeToggle()
  {
  //  alert()

    $("#sidebar-wrapper1").removeClass("active");
    //  e.preventDefault();
  }

  // vitalScore(){ pubnub- vitalcritical score
  //   alert(3)
  // }

  pubnub1() {
    // var account_id = this.dmail
    // var channel = account_id.replace('@', '_').replace('.org', '');
    // 
    // var doctor_id = this.doc_id
//prescription_status_rx4_rxcarenet.org_3

  var account_id = this.dmail

  var channel = "prescription_status"+'_'+account_id.replace('@', '_') + '_' + this.doc_id
  
//  alert(channel)

  var uuid = account_id

    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
    });

    this.pubnub.addListener({
      message: msg => {
        console.log(msg.message)

        this.toastr.success(msg.message.message, '', {
          timeOut: 3000,
        });
      }
    })

    this.pubnub.getMessage([channel], (msg) => {

    });

    this.pubnub.getStatus([channel], (msg) => {
    });

    this.pubnub.getPresence([channel], (msg) => {
    });
  }

  getdrProfile(){
    var params = {
      account_email: this.dmail,
      doctor_id: this.doc_id
    }
  
    this.appService.getData(this.urlPoints.getDoctorProfile, params, this.token).subscribe((response: any) => {
      this.docData = response.doctors.profile;
      this.dr_Name = this.docData.name;
      sessionStorage.setItem("hospitalname", response.doctors.profile.hospital_name);
      localStorage.setItem('dr_name', this.dr_Name);
    });

  }
  Gynec(){
    this.dialog.open(GynecrepotComponent,{
      width: '70%',
    })
  }
  scanOpt(){
let allQueueList
    var params = {
      account_email: this.dmail,
      doctor_id: this.doc_id,
      status_type: 0,
      in_date: moment(this.date).format("YYYY-MM-DD")
    }
    
    this.appService.getData(this.urlPoints.getqueueList, params, this.token).subscribe((response: any) => {

      this.appService.queueLists = response;
      const dialogRef = this.dialog.open(QrscanerComponent, {
        width: '30%',
        data:{queueLists:response}
        // height: '90vw',
      });
    })
  }
  started(e){
    this.startstatus = e;
  //  alert(e)
  }

  receivemsg(ev){
    if(ev){
      this.appointmentCount();
    }
  }

  emerInd(ev){
    if(ev){
      this.emergency_indication = ev;
    }
  }

  toggle_full() {
    this.full = !this.full;
    if(!this.full){
      document.getElementById('navbar').style.display  = "none";
    }else{
      document.getElementById('navbar').style.display  = "block";
    }
  }

  appointmentCount() {
   
   var payload = {
      account_email: this.dmail,
      doctor_id: this.doc_id,
      in_date: moment(this.date).format("YYYY-MM-DD")
    }
    this.appService.getData(this.urlPoints.getappointmentCount, payload, this.token).subscribe((response: any) => {
      this.allCount = response.count[0];
      this.confirmedCount = response.count[1];
      this.arrivedCount = response.count[2];
      this.closedCount = response.count[3];
    })
  }

  addDay() {
    this.date = moment(this.date).add(1, 'days');
    this.appointmentCount();
    this.apptabs(this.appindex);
  }

  minusDay(){
    this.date = moment(this.date).subtract(1, 'days');
    this.appointmentCount();
    this.apptabs(this.appindex);
  }

  apptabs(tabindex){
    this.update=!this.update

    this.appindex = tabindex;
    for (var i = 0; i < 4; i++) {
    const el =  document.getElementsByClassName('tabselect')[i] as HTMLElement;
          el.style.borderBottom = "0px solid #000000";
          el.style.color = "#000000";
          el.style.fontWeight = "normal";
    }
    document.getElementById('id_'+tabindex).style.borderBottom  = "2px solid #0063ff";
    document.getElementById('id_'+tabindex).style.color  = "#0063ff";
    document.getElementById('id_'+tabindex).style.fontWeight  = "bold";

     var params = {
          account_email: this.dmail,
          doctor_id: this.doc_id,
          status_type: tabindex,
          in_date: moment(this.date).format("YYYY-MM-DD")
        }
        
        this.appService.getData(this.urlPoints.getqueueList, params, this.token).subscribe((response: any) => {

          console.log(response)

          this.queueList = response;
          if(this.queueList.length==0){
              this.allCount = 0;
          }
          for (var i = 0; i < this.queueList.length; i++) {
            if(this.queueList[i].type == "emergency_voice_call" && this.queueList[i].status == "confirmed"){
                this.emergency_indication = true;
              }
          }
      })
    
    }

    checkVitallAccess(){

      var params ={
        "account_email" : this.dmail,
        "doctor_id" : this.doc_id
        //"doctor_id" : 1
      }
      this.appService.postData(this.urlPoints.getvitallaccesscode, params, this.token).subscribe((response: any) => {
        this.checkVitallAccessStatus = response.success;
        sessionStorage.setItem("accessCode",response.codes[0].code)
        console.log(this.checkVitallAccessStatus);
      });
    }
    
    goToDashboard(){
      this.router.navigate(['epicare/drDashboard'])
    }

    goToVitall(){
      this.router.navigate(['epicare/vitall'])
    }

    getPrintCount()
    {
      var params = {
        account_email: this.dmail,
        doctor_id: this.doc_id,
      }

      this.appService.getData(this.urlPoints.getPrintStatus, params, this.token).subscribe((response: any) => {
        console.log(response.data)

        this.printData = response.data

        console.log(this.printData)

        var unseenArray;

        unseenArray = this.printData.filter((res => res.seen == 0));

        this.unseenCount = unseenArray.length

        console.log(this.unseenCount)

        if(this.unseenCount>0)
        {
          this.fileSrc = 'assets/file_red.svg'
        }

        else
        {
          this.fileSrc = 'assets/file.svg'

        }

        // const dialogRef = this.dialog.open(DialogOverviewExampleDialog_printList, {
        //   width: '600px',
        //   data: { printData: this.printData }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   this.getPrintCount()
        // });
      });

    }

    getPrintCount1()
    {
      var params = {
        account_email: this.dmail,
        doctor_id: this.doc_id,
      }

      this.appService.getData(this.urlPoints.getPrintStatus, params, this.token).subscribe((response: any) => {
        console.log(response.data)

        this.printData = response.data

        console.log(this.printData)

        const dialogRef = this.dialog.open(DialogOverviewExampleDialog_printList, {
          width: '600px',
          data: { printData: this.printData, dmail: this.dmail,token:this.token }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getPrintCount()
        });
      });

    }
}

@Component({
  selector: 'printList',
  templateUrl: 'printList.html',
  styleUrls: ['printList.scss']

})

export class DialogOverviewExampleDialog_printList {
  chartData;
  printList;
  urlPoints;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_printList>, private appService: AppService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_printList, public router: Router, private fb: FormBuilder) {
      this.urlPoints = AppConfig.settings.EndPoints;

  }
  ngOnInit(): void {
    console.log(this.data.dmail)
    console.log(this.data.token)

    console.log(this.data.printData)
  }

  view(data)
  {
    console.log(data)

    var params={
      account_email:this.data.dmail,
      id:data.id
    }

    console.log(params)

    this.appService.postData(this.urlPoints.updatePrintStatus, params, this.data.token).subscribe((response: any) => {
    console.log(response)
      window.open(environment.HOST + '/PDF/' + data.filepath)
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

 
}