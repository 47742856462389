
<div class="row chatContaineer">
            <!-- patient List containeer -->
            <div class="col-lg-5 col-md-5 col-xs-12 col-sm-5">
                <div class="panel panel-default">
                  <div class="fixed-panel">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-8 col-sm-6 xsRightwidthheader">
                                <div style="color:#000">
                                <h3 *ngIf="unread" class="panel-title">Patient List <span class="badge">{{unread}}</span></h3>
                                <h3 *ngIf="unrespond" class="panel-title">Patient List <span class="badge">{{unrespond}}</span></h3>
                                </div>
                            </div>
                         </div>
                        <div class="row" style="color:#000;font-weight: 600;">
                            <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 xltabs1" style="text-align:center;line-height: 40px;cursor: pointer;">
                                <div class="tabselect" id="id_all" [(ngModel)]="messageType" (click)="getPatientList('all')">All</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 xltabs2" style="text-align:center;line-height: 40px;cursor: pointer;">
                                <div class="tabselect" id="id_unread" [(ngModel)]="messageType" (click)="getPatientList('unread')">Unread</div>
                            </div>   
                            <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 xltabs3" style="text-align:center;line-height: 40px;cursor: pointer;">
                               <div class="tabselect" id="id_unresponded" [(ngModel)]="messageType" (click)="getPatientList('unresponded')">Not responded</div> 
                            </div>
                        </div>
                    </div>
                        <!-- <hr style="margin-top:-2px"> -->
                        <div class="row backclr">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 martop">
                                <input class="form-control" 
                                placeholder="Search" 
                                name="srch-term" 
                                id="srch-term" 
                                type="text"
                                [(ngModel)]="searchContent.searchTxt"
                                (keyup)="searchPatient($event)">
                                <div class="input-group-btn">
                                <button class="btn btn-default" type="submit" ><i class="glyphicon glyphicon-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body posbody">
                        <div *ngIf="loaded2==true"><div style="text-align: center;"><span class="fa fa-spinner fa-2x fa-spin"></span></div></div>
                        <div class="list-group">
                            <div *ngIf="chatList.length==0">No Patient List</div>
                            <div class="row list-group-item link" style="display:flex" *ngFor="let patient of chatList" (click)="goToChathistory(patient,patient.email,patient.image)">
                                <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8 xsLeftDateWidth">
                                        <img *ngIf="patient.image == null || patient.image == '' || patient.image == 'None' || patient.image.split(':')[0] == 'https'" style="display: inline-block;margin-top: 5px;" src="assets/default-avatar-.jpg" class="profileavatar"> 
                                        <img *ngIf="patient.image != null && patient.image != '' && patient.image != 'None' && patient.image.split(':')[0] != 'https'" style="display: inline-block;margin-top: 5px;" src="{{imageUploadUrl}}{{patient.image}}" class="profileavatar"> 
                                        <span style="margin-left: 50px;margin-top: -40px;font-weight: 600;display: flex;" class="themeClr xlimgfontsize"> {{patient.full_name}}</span>
                                        <!-- <p class="pull-right"><span class="badge" ng-if="patient.unread">Unread</span></p> -->
                                        <p class="list-group-item-text imgmr themesubClr xlimgfontsize">
                                            {{patient.gender | titlecase}} / {{patient.mr_number}}
                                        </p>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 xsRightDatewidth">
                                        <div style="float:right;margin-top: 15px">
                                            <span class="themeClr xldatefontsize">{{patient.last_messaged_on_Local}}</span>
                                        </div>
                                    </div>

                                
                                <!-- <h4 class="list-group-item-heading">
                                    <img src="../../assets/patient.png" alt="Avatar" class="profileavatar"> 
                                    <span style="margin-left: 5px;"> {{patient.first_name}} {{patient.last_name}} {{patient.last_messaged_on_Local}}</span>
                                    <span class="pull-right"></span>
                                    <p class="list-group-item-text">
                                        {{patient.dob}} / {{patient.gender}} / {{patient.mr_number}} <span>/ {{patient.uhid}}</span>
                                    </p>
                                </h4> -->
                                <!-- <h4 *ngIf="onsearch" class="list-group-item-heading">{{patient.full_name}} <p class="pull-right"><span class="badge">Unread</span></p></h4> -->
                                
                            </div>
                            <!-- <span class="list-group-item link">
                                <h4 class="list-group-item-heading">{{data.name_given data.name_family}}</h4>
                                <p class="list-group-item-text">
                                    {{calculateAge(data.bday) / data.gender / data.mr_number <span *ngIf="data.uhid">/ data.uhid</span><span class="pull-right">patient.last_messaged_on_Local</span>
                                </p>
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-7 col-xs-12 col-sm-7">
                <app-chatmsges [senderDetails]="senderDetails" [sendermail]="sendermail" [senderImage]="senderImage"></app-chatmsges>
            </div>
</div>  
      
      
