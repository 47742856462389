<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Baby's History</p>
        </div>
    </div>

    <!-- <div style="padding: 15px;margin-top:30px;float: right;">
      <button *ngIf="!editStatus&&getMotherData==''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button>
        <button *ngIf="!editStatus&&getMotherData!=''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="cancelStatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="validatemotherAntel();"
            style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>

    </div> -->
   <div class="row" style="margin-left: 10px;margin-top: 60px;width:98%">
      
      <div class="col-lg-6 col-md-6 col-lg-6 leftBorder">
         At Birth
         <button *ngIf="!editStatus&&getMotherData==''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button>
         <button *ngIf="!editStatus&&getMotherData!=''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
         <button  *ngIf="editStatus" class="btn btn-primary customButton" (click)="validateBabyAtbirth();"
         style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
         <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="cancelStatus();" style="color:#ffff;float:right;margin-right: 10px;margin-top: -20px;"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
         <hr class="hrStyle">
        <form [formGroup]="babyHistory" style="width:98%;">
         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> APGAR Score (0-10) </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <input maxlength="80" *ngIf="editStatus" class="form-control" formControlName="apgarscore" type="text" min="0" max="10" maxlength="2"
                  [(ngModel)]='apgar' (keypress)="numberOnly($event)" (keyup)="preventInput($event)">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.score}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>What was the Type of Delivery?</span> <span style="color: red;"> *</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                     formControlName="delivery" required *ngIf="editStatus">
                     <option value="">Select</option>
                     <option value="cesarean" >Cesarean</option>
                     <option value="vaccum" >Vaccum</option>
                     <option value="normal" >Normal</option>
                     <option value="other" >Other</option>
                  </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.delivery | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>Did Baby Cry at Birth?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 12px;"> 
                  <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="babycry" [ngModelOptions]="{standalone: true}"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.cry}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>When did Baby pass Urine?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                     formControlName="passUrine" *ngIf="editStatus">
                     <option value="">Select</option>
                     <option value="before 24 hrs" >Before 24 Hours</option>
                     <option value="between 24 to 48 hrs" >Between 24 to 48 Hours</option>
                     <option value="after 48 hrs" >After 48 Hours</option>
                  </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.urine | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>When did Baby pass Motion?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                     formControlName="passMotion" *ngIf="editStatus">
                     <option value="">Select</option>
                     <option value="before 24 hrs" >Before 24 Hours</option>
                     <option value="between 24 to 48 hrs" >Between 24 to 48 Hours</option>
                     <option value="after 48 hrs" >After 48 Hours</option>
                  </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.motion | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>Was there a time delay in giving the baby to Mother, post delivery?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 12px;"> 
                  <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="timeDelay" [ngModelOptions]="{standalone: true}"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.time_delay}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>When was the Breast Feeding started?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                     formControlName="breastFeeding" required *ngIf="editStatus">
                     <option value="">Select</option>
                     <option value="immediate" >Immediate</option>
                     <option value="3 to 5 hrs" >3 to 5 Hours</option>
                     <option value="after 5 hrs" >After 5 Hrs</option>
                  </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.breast_feeding_start | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>Was baby admitted in NICU?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 12px;"> 
                  <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="nicu" [ngModelOptions]="{standalone: true}"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.is_nicu_admission}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row" *ngIf="nicu==0">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> Reason for admission 
                  <!-- <span style="color: red;"> *</span> -->
               </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <input maxlength="80" *ngIf="editStatus" class="form-control" formControlName="reasonForAdmission">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.nicu_admit_reason}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> 
                  <span>When was the Baby Discharged from Hospital?</span> </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;" *ngIf="editStatus"
                  formControlName="babyDischarged" required (change)="dischargeChange()">
                  <option value="">Select</option>
                  <option value="24 to 48 hrs" >24 to 48 Hrs</option>
                  <option value="3 to 5 days" >3 to 5 Days</option>
                  <option value="after 5 days" >After 5 days (Delay)</option>
               </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.discharge_duration | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row" *ngIf="dischargechangeval=='after 5 days'">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> Reason for delay in Discharge 
                  <!-- <span style="color: red;"> *</span> -->
               </p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <input maxlength="80" *ngIf="editStatus" class="form-control" formControlName="reasonForDelay">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.discharge_delay_reason}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>
         
         <div class="row">
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 20px;"> 
                  <span>Baby's Weight </span> </p>
            </div>
            <div class="col-lg-3 col-md-3 col-lg-3">
               <p style="font-size: 12px;"> At Birth</p>
               <div style="display:flex;margin-top: -8px;"> 
                  <input maxlength="80" *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babyweightBirth" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.birth_weight}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> kg </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
            <div class="col-lg-4 col-md-4 col-lg-4">
               <p style="font-size: 12px;"> At Discharge
               </p>
               <div style="display:flex;margin-top: -8px;"> 
                  <input maxlength="80" *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babyWeight" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.discharge_weight}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> kg </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
         </div>

         <div class="row" style="margin-top: 20px;">
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 20px;"> 
                  <span>Baby's Height </span> </p>
            </div>
            <div class="col-lg-3 col-md-3 col-lg-3">
               <p style="font-size: 12px;"> At Birth</p>
               <div style="display:flex;margin-top: -8px;"> 
                 <input maxlength="80" *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babyheightBirth" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.birth_height}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> Cm </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
            <div class="col-lg-4 col-md-4 col-lg-4">
               <p style="font-size: 12px;"> At Discharge
               </p>
               <div style="display:flex;margin-top: -8px;"> 
                  <input maxlength="80" *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babylenght" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.discharge_height}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> Cm </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
         </div>

         <div class="row" style="margin-top: 20px;">
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p style="margin-top: 20px;"> 
                  <span>Baby's Head Circumference </span> </p>
            </div>
            <div class="col-lg-3 col-md-3 col-lg-3">
               <p style="font-size: 12px;"> At Birth</p>
               <div style="display:flex;margin-top: -8px;"> 
                  <input *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babyHCBirth" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.birth_head_circumference}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> Cm </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
            <div class="col-lg-4 col-md-4 col-lg-4">
               <p style="font-size: 12px;"> At Discharge
               </p>
               <div style="display:flex;margin-top: -8px;"> 
                  <input *ngIf="editStatus" style="width:40%;height: 30px;" class="form-control" formControlName="babyHC" maxlength="6" (keypress)="numberOnly($event)" /> 
                  <span *ngIf="!editStatus" style="margin-top: 5px;" class="ansText">{{getMotherData.discharge_head_circumference}}</span>
                  <span style="margin-top: 5px;margin-left: 10px;" class="ansText"> Cm </span> 
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
            </div>
         </div>

         <div class="row" style="margin-top: 25px;">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 10px;"> 
                  <span>Feeding</span> <span style="color: red;"> *</span></p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                  formControlName="feeding" required (change)="feedingChange()" *ngIf="editStatus">
                  <option value="">Select</option>
                  <option value="mother" >Mother</option>
                  <option value="supplement" >Supplement</option>
               </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.feeding | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row" style="margin-top: 25px;" *ngIf="(feedingchangeval=='supplement')||(getMotherData.feeding=='supplement')">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 10px;"> 
                  <span>If not breast feeding, reason for not Breast Feeding?</span></p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
               <select class="form-control" name="Type" style="font-size: 14px;"
                  formControlName="nofeeding" required (change)="notfeedingChange()" *ngIf="editStatus">
                  <option value="">Select</option>
                  <option value="medical" >Medical</option>
                  <option value="social" >Social</option>
               </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.supplement_reason | titlecase}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>

         <div class="row" *ngIf="(nofeedingchangeval=='medical')||(getMotherData.supplement_reason=='medical')">
            <div class="col-lg-7 col-md-7 col-lg-7">
               <p style="margin-top: 12px;"> If Medical, what is the Reason ?</p>
            </div>
            <div class="col-lg-5 col-md-5 col-lg-5">
               <p> 
                  <input maxlength="80" *ngIf="editStatus" class="form-control" formControlName="reasonForMedicalfeed" (keypress)="omit_special_char($event)">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.medical_reason}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
            </div>
         </div>
        </form>
      </div>
      
      <div class="col-lg-6 col-md-6 col-lg-6">
         New Born
         <button *ngIf="!newbornEditStatus&&getNewBornData==''" class="btn btn-primary customButton" (click)="updateNewbornStatus();" style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button>
         <button *ngIf="!newbornEditStatus&&getNewBornData!=''" class="btn btn-primary customButton" (click)="updateNewbornStatus();" style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
         <button  *ngIf="newbornEditStatus" class="btn btn-primary customButton" (click)="validatenewbornBaby();"
         style="color:#ffff;float:right;margin-top: -20px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
         <button *ngIf="newbornEditStatus" class="btn btn-primary customButton" (click)="cancelNewbornStatus();" style="color:#ffff;float:right;margin-right: 10px;margin-top: -20px;"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
         
         <hr class="hrStyle">
         <form [formGroup]="newbornbabyHistory" style="width:98%;">
            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did Baby have Jaundice ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="babyJaundice" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.jaundice}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="babyJaundice==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>What was the Maximum level ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input *ngIf="newbornEditStatus" formControlName="maxLevel" name="rateInput" maxlength="3" (keypress)="numberOnly($event)" /> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.jaundice_level}}</span> μmol/l
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did baby have problem with Sugar ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="sugar" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.sugar}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="sugar==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>What was the lowest blood sugar ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input *ngIf="newbornEditStatus" formControlName="lowestsugar" name="rateInput" maxlength="3" (keypress)="numberOnly($event)" />
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.sugar_level}}</span> mmol/L
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did Baby have any infection ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="infection" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.infection}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="infection==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Please Describe</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" *ngIf="newbornEditStatus" formControlName="infectionDes" name="rateInput"  (keypress)="omit_special_char($event)"/> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.infection_text}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did Baby have any Breath Difficulty ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="breathDifficulty" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.breadth_difficulty}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="breathDifficulty==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Please Describe</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" *ngIf="newbornEditStatus" formControlName="prethDesc" name="rateInput"  (keypress)="omit_special_char($event)"/> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.breadth_difficulty_text}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did Baby have Fits ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="babyfits" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.fits}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="babyfits==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Please Describe</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" *ngIf="newbornEditStatus" formControlName="fitsDesc" name="rateInput"  (keypress)="omit_special_char($event)"/> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.fits_text}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Did Baby have any problem with Heart ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="heartProb" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.heart_problems}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="heartProb==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Please Describe</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" *ngIf="newbornEditStatus" formControlName="heartprobDesc" name="rateInput"  (keypress)="omit_special_char($event)" /> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.heart_problems_text}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Any other problem ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="anyotherprob" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.other_problem}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="anyotherprob==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Please Describe</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" *ngIf="newbornEditStatus" formControlName="otherprobDesc" name="rateInput" (keypress)="omit_special_char($event)" /> 
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.other_problem_text}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Physical Finding on Baby at Birth Normal</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="birthNormal" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">Don't know</mat-radio-button>
                        </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.physical_findings}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="birthNormal==1">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>If no, Was testicles descended at birth ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <input maxlength="80" formControlName="testiclesDescend" name="rateInput" (keypress)="omit_special_char($event)" /> 
                     <span *ngIf="!editStatus" class="ansText">{{getMotherData.testicles_descended}}</span>
                     <span *ngIf="!editStatus&&getMotherData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Hearing</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="hearing" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Normal</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >Abnormal</mat-radio-button>
                     </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.hearing}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Eye (Red Reflex / ROP):</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="eye" maxlength="15" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.eye}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Thyroid Screening</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="tyroid" maxlength="160" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.thyroid_screening}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Other Endocrine Screening / Other metabolic Screening</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="metabolic" maxlength="160" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.other_screening}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Hip Examination</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="hipExam" maxlength="160" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.hip_examination}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Femorals</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="femorals" maxlength="160" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.femorals}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Pulse Oxymetric Screening</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p> 
                     <input maxlength="80" *ngIf="newbornEditStatus" class="form-control" formControlName="pulseOXY" maxlength="160" (keypress)="omit_special_char($event)">
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.pulse_screening}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>Has baby been admitted for any
                        reason in the newborn period ?</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <mat-radio-group *ngIf="newbornEditStatus"
                           aria-labelledby="example-radio-group-label"
                           class="example-radio-group"
                           [color]="'primary'"
                           [(ngModel)]="admittedReason" [ngModelOptions]="{standalone: true}"
                           >
                           <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                           <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                     </mat-radio-group>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.admitted}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>

            <div class="row" *ngIf="admittedReason==0">
               <div class="col-lg-7 col-md-7 col-lg-7">
                  <p style="margin-top: 12px;"> 
                     <span>If Yes,</span> </p>
               </div>
               <div class="col-lg-5 col-md-5 col-lg-5">
                  <p style="margin-top: 12px;"> 
                     <select class="form-control" name="Type" style="font-size: 14px;"
                        formControlName="yesadmitted" *ngIf="newbornEditStatus">
                        <option value="">Select</option>
                        <option value="Nursing" >Nursing</option>
                        <option value="Special Care" >Special Care</option>
                        <option value="NICU" >NICU</option>
                     </select>
                     <span *ngIf="!newbornEditStatus" class="ansText">{{getNewBornData.admitted_reason}}</span>
                     <span *ngIf="!newbornEditStatus&&getNewBornData==''">-</span>
                  </p>
               </div>
            </div>
         </form>
      </div>
   </div>
    
</div>


