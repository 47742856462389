<div style="display: flex;justify-content: space-between;">
    <p  class="header">Medicine Info</p>

    <img style="width:1.5rem" (click)="onNoClick()" src="assets/cancel.svg">

</div>

<table>
    <tr>
        <td>
            <div class="box" >
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon1.svg">
                </div>

                <p class="text-center icontext">Injection</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon2.svg">
                </div>

                <p class="text-center icontext">Lotion</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon3.svg">
                </div>

                <p class="text-center icontext">Ointment</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon4.svg">
                </div>

                <p class="text-center icontext">Soap</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon5.svg">
                </div>

                <p class="text-center icontext">Tablet</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon6.svg">
                </div>

                <p class="text-center icontext">Suspension</p>

            </div>  
        </td>
    </tr>

    <tr>
        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon7.svg">
                </div>

                <p class="text-center icontext">Cream</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon8.svg">
                </div>

                <p class="text-center icontext">Capsules</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon9.svg">
                </div>

                <p class="text-center icontext">Drops</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon10.svg">
                </div>

                <p class="text-center icontext">Oil</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon11.svg">
                </div>

                <p class="text-center icontext">Gel</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon12.svg">
                </div>

                <p class="text-center icontext">Powder</p>

            </div>
        </td>
    </tr>

    <tr>
        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon13.svg">
                </div>

                <p class="text-center icontext">Syrup</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon14.svg">
                </div>

                <p class="text-center icontext">Sachet</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon15.svg">
                </div>

                <p class="text-center icontext">Vaccine</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon16.svg">
                </div>

                <p class="text-center icontext">Inhaler</p>

            </div>
        </td>

        <td>
            <div class="box">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/icon17.svg">
                </div>

                <p class="text-center icontext">Others</p>
            </div>
        </td>


       
    </tr>
</table>