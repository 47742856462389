<!-- <div class="d-flex" id="wrapper">
    Left Sidebar
    
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
          <h2 style="margin-top:10px">Settings</h2>
          
        </div>
    </nav>
        /#footer
    </div>
  </div> -->

  <div class="d-flex" id="wrapper">
    <!-- Top bar -->
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-sm navbar-light border-bottom" id="navbar">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">

          <div class="row topbanner">
              <div class="headerTxt">
                  Settings
              </div>
           </div>
       </div>
      </nav>
   </div>
</div>

<div class="d-flex viewLayout" style="border:0px solid">
    
    <div class="border-right secondryleftSubmenu">
        <!-- <div class="sidebar-heading">search</div> -->
        <div class="list-group list-group-flush">
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="aptConfiq"><img class="menuiconAlign1" style="width:20px" src="assets/leftmenu/appointment.svg">&nbsp; Appointment</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="advice"><img class="menuiconAlign1" src="assets/leftmenu/advice.svg"><span style="margin-top: 5px;">&nbsp; Advice</span></a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="bannersettings"><img class="menuiconAlign1" src="assets/leftmenu/banner.png" title="Banner Settings"><span style="margin-top: 5px;">&nbsp; Banner</span></a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="chat"><img class="menuiconAlign1" src="assets/leftmenu/chat_master.svg">&nbsp; Chat</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="consulttab"><img class="menuiconAlign1" src="assets/leftmenu/consultation_master.svg">&nbsp; Consultation</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="compliant"><img class="menuiconAlign1" src="assets/leftmenu/complaints.svg">&nbsp; Complaint</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="common"><img class="menuiconAlign1" src="assets/leftmenu/Common_settings.svg">&nbsp; Common</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="drugs"><img class="menuiconAlign1" src="assets/leftmenu/drugs.svg">&nbsp; Drugs</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="drincharge"><img class="menuiconAlign1" src="assets/leftmenu/doctor_inchare_settinga.svg">&nbsp; Dr In-Charge</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="drinout"><img class="menuiconAlign1" src="assets/leftmenu/Dr_in_and_out_settingss.svg">&nbsp; Dr In-Out</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="engagement"><img class="menuiconAlign1" src="assets/leftmenu/engagement-inactive.svg"> Engagement</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="frontdesk"><img class="menuiconAlign1" src="assets/leftmenu/frontdesk_person.svg">&nbsp; FrontDesk</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="investication"><img class="menuiconAlign1" src="assets/leftmenu/investigation.svg">&nbsp; Investigation</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="location"><img class="menuiconAlign1" src="assets/leftmenu/location_master.svg">&nbsp; Location</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="milestoneConfiq"><img class="menuiconAlign1" src="assets/leftmenu/milestone.svg">&nbsp; Milestone</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="payment"><img class="menuiconAlign1" src="assets/leftmenu/payments.svg">&nbsp; Payment</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="payout"><img class="menuiconAlign1" src="assets/leftmenu/payout_master.svg">&nbsp; Payout</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="subscription"><img class="menuiconAlign1" src="assets/leftmenu/subscription_master.svg">&nbsp; Subscription</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="vac-master"><img class="menuiconAlign1" style="width:22px" src="assets/leftmenu/vaccination_master.svg">&nbsp; Vaccination</a>
            <a class="list-group-item list-group-item1 list-group-item-action" routerLink="others"><img class="menuiconAlign1" src="assets/leftmenu/others.svg">&nbsp; Others</a>
            
            <hr>
        </div>
    </div>
    <div class="container-fluid">
        <router-outlet></router-outlet>  
    </div>
</div>