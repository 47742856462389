<div style="display: flex;flex-direction: row;justify-content: space-between;">
    <p style="font-size: 1rem;font-weight: 600;color: #0063FF;">Deffer Vaccines</p>
    <div>

        <!-- <img style="height: 1rem; width: 1rem;cursor: pointer;" src="assets/add.png"> -->

        <button class="btn btn-primary" style="padding-top: 0;padding-bottom: 0;margin-right: 0.5rem;" (click)="addNew()">Add New</button>

        <button class="btn btn-primary" style="padding-top: 0;padding-bottom: 0;" (click)="save()">Save</button>

        <img (click)="close()" style="width: 1rem;cursor: pointer;margin-left: 00.5rem;" src="assets/cancel.svg">

    </div>
</div>

<!-- <div style="display: flex;">
    <p>Date : </p>
    <img style="height: 1rem;width: 1rem;margin-right: 0.5rem;cursor: pointer;margin-left: 00.5rem;" *ngIf="data.next_vaccine_date"
        (click)="picker.open()" src="assets/metro-calendar.svg">
    {{data.next_vaccine_date | date:'dd-MMM-yyyy' || "N/A"}}

    <mat-form-field style="width:0px !important;visibility:hidden;">
        <input matInput [matDatepicker]="picker" [(ngModel)]="data.next_vaccine_date"  [matDatepickerFilter]="weekendsDatesFilter" [min]="data.minDate">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
</div> -->

<div style="max-height: 20rem;overflow: auto;">
    <div *ngFor="let v of vaccines;let i = index" [class.disabled]="v.status == 'adminsitrated-1' || v.status == 'Deferred' || v.status == 'deffered'"
        style="border: 1px solid #D9D9D9;padding: 0.5rem;margin-bottom: 00.5rem;display: flex;">

        <mat-checkbox [checked]="v.checked" (change)="optionToggle(i)"  class="example-margin">
            <p style="margin: 0;">{{v.age_in_days}} - {{v.drug_name}} - {{v.due_date | date:'dd-MMM-yyyy'}} <span
                    *ngIf="v.status!=''">- {{v.status}}</span></p>
        </mat-checkbox>

    </div>
</div>