<style>
    .wrapword {
       word-break: break-word;
       white-space: pre-wrap;
       -moz-white-space: pre-wrap;      
    }
    </style>

<form name="addcprec">
    <div class="row">
        <div class="col-md-12">
            <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
        </div>
        <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
            <table class="table table-bordered">
                <thead style="background-color: #adccff;">
                    <tr>
                        <th class="text-center" style="width: 50px;">S.No</th>
                        <th class="text-center" style="width: 120px;">Date</th>
                        <th class="text-center" style="width: 120px;">BP</th>
                        <th class="text-center" style="width: 120px;">P/A</th>
                        <th style="width: 500px;" class="text-center">Remarks</th>
                        
                    </tr>
                </thead>
                <tbody ng-if="showpastcprdetails == true">
                    <tr class="text-center" style="border: tomato;" *ngFor="let cprdetails of getarchivedcpRecords; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{cprdetails.date}}</td>
                        <td>{{cprdetails.bp}}</td>
                        <td>{{cprdetails.pa}}</td>
                        <td class="wrapword">{{cprdetails.remarks}}</td>
                        
                    </tr> 
                </tbody>
            </table>
            <!-- <div class=" text-center"  style="color: red;"  ng-if="showpastcprdetails == false">No data found</div> -->
        </div>
    </div>
</form>
