import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class ChildToParentService {
  
    onFloatChanged$ = new Subject<any>();
  
    constructor() { }
  
  }