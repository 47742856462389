<div>
    <!---1 for Video call notify-->
    <div *ngIf="data.open_callType == 1">
        <div class="row">
            <p class="col-lg-11 col-md-11  header">Send Notification</p>
            <img class="col-lg-1 col-md-1 img" (click)="close()" src="assets/cancel.svg">
        </div>

        <div class="row row1">
            <p class="col-lg-12 col-md-12">{{data.msg}}
            </p>
        </div>

        <div class="row row1" style="display: flex;justify-content: space-around;">
            <!-- <div class="col-lg-8 col-md-8" style="text-align: center;"></div>
            <div class="col-lg-4 col-md-4" style="text-align: center;">
            </div> -->

            <button class="btn btn-success" *ngIf="data.notify_details.allow_status" (click)="sendNotifyVoiceCall()">Call Patient</button>

            <button class="btn btn-success" (click)="sendNotifyCall()">Initiate call</button>

        </div>
    </div>

<!---0 for Voice call notify-->
    <div *ngIf="data.open_callType == 0">
        <div class="row">
            <p class="col-lg-11 col-md-11  header">Patient has booked the voice call appointment</p>
            <img class="col-lg-1 col-md-1 img" (click)="close()" src="assets/cancel.svg">
        </div>

        <div class="row row1">
            <p class="col-lg-12 col-md-12">{{data.msg}}
            </p>
        </div>

        <div class="row row1">
            <div class="col-lg-4 col-md-4" style="text-align: center;"></div>
            <div class="col-lg-3 col-md-3" style="text-align: center;">
                <button class="btn btn-danger col-lg-8 col-md-8" (click)="close()" style="float: right;margin-right: -30px;">Cancel</button>
            </div>
            <div class="col-lg-5 col-md-5" style="text-align: center;">
                <button class="btn btn-success col-lg-8 col-md-8" (click)="sendNotifyVoiceCall()">Initiate call</button>
            </div>
            
        </div>
    </div>

</div>