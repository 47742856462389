<div class="contaniner">

    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true"
                        style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 containerTop">
            <p class="headText">Payment</p>
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1 containerTop" style="float:right;visibility: hidden;">
            <div>Edit</div>
        </div>

    </div>

    <div class="row" style="width:99%;margin-top: 30px;margin-left: 6px;">
        <div class="col-sm-11 col-md-11 col-lg-11">
            <div style="float: right;">Patient Name : {{data.patient.demographics.name_given}}</div><br>
            <div style="float: right;"><span style="margin-right: 8px;">MR No : </span>{{data.patient.demographics.mr_number}}</div>
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1" style="float:right;" *ngIf="!defPage">
            <img style="cursor:pointer;width: 40px;" title="External Details" src="assets/external-link.png" (click)="getExternalPayhistory()">
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1" style="float:right;" *ngIf="defPage"></div>
    </div>

    <div *ngIf="!defPage">
        <div *ngIf="payment == ''" style="width:99%;margin-top:10%;text-align: center;"><div>No Payments Found</div></div>

        <div class="row" style="width:99%;margin-top: 10px;margin-left: 6px;">
            <div class="col-sm-12 col-md-12 col-lg-12" *ngFor="let p of payment;let i=index" style="margin-top: 25px;">
                <div style="font-weight: 800;"><span>{{i+1}})</span> <span style="margin-left: 10px;">{{p.bill_date | date:'dd-MMM-yyyy h:mm a'}}</span></div>
                <div style="float: right;margin-top: -30px;">
                    <img  matTooltip="Print" src="assets/print icon.svg" style="width:25px;cursor: pointer;margin-right: 10px;" (click)="printPayment(p.bill_no)" />
                    <img  matTooltip="Send SMS" src="assets/sms icon.svg" style="width:25px;cursor: pointer;" (click)="sms(p.bill_no)" />
                </div>
                <hr class="hrStyle">
                <div class="Rtable Rtable--4cols" style="display: flex;flex-wrap: wrap;margin: 0 0 0 0;padding: 0;">  
                    <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Bill NO :&nbsp;&nbsp;</span><span>{{p.bill_no}}</span></span></div>
                    <div class="Rtable-cell" style=" width: 25%;"><span class="fontWeight">Category :&nbsp;&nbsp;</span><span>{{p.category | titlecase}}</span></div>
                    <div class="Rtable-cell" style=" width: 25%;"><span class="fontWeight">Invoice Value :&nbsp;&nbsp;</span><span>{{p.doctor_charges | currency:"&#8377;&nbsp;"}}</span></div>
                    <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">Billed To Patient :&nbsp;&nbsp;</span><span>{{p.bill_amount | currency:"&#8377;&nbsp;"}}</span></div>
                </div>
                <div class="Rtable Rtable--4cols" style="display: flex;flex-wrap: wrap;margin: 0 0 0 0;padding: 0;margin-top: 15px;">  
                    <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">Patient Payment :&nbsp;&nbsp;</span><span>{{p.fee_paid | currency:"&#8377;&nbsp;"}}</span></div>
                    <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Pending payment :&nbsp;&nbsp;</span><span>{{p.fee_pending | currency:"&#8377;&nbsp;"}}</span></span></div>
                    <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Description :&nbsp;&nbsp;</span><span>{{p.description}}</span></span></div>
                    <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Status :&nbsp;&nbsp;</span><span [class.paymentDue]="p.status=='pending'"class="">{{p.status | titlecase}}</span></span></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="defPage" >
        <div class="row" style="width:99%;margin-top: 10px;">
           <div class="col-sm-12 col-md-12 col-lg-12">
                <table style="width: 100%;" *ngIf="conHistorydata.length!=0">
                    <tr style="border: 2px solid;">
                        <th>S.No</th>
                
                        <th>Date</th>
                
                        <th>Patient Number</th>
                
                        <th>Patient Name</th>
                
                        <th>Invoice Number</th>
                
                        <th>Receipt Number</th>
            
                        <th>Vendor Name</th>

                        <th>Vendor Fees(Percent)</th>

                        <th>Treatment Name</th>

                        <th>Amount Paid</th>

                        <th>Payment Mode</th>
                    </tr>
            
                    <tr style="border: 0.5px solid;" *ngFor="let c of conHistorydata;let i = index">
                        <td>{{i+1}}</td>
                        <td>{{c.Date | date:'dd MMM yyyy'}}</td>
                        <td>{{c.Patient_Number}}</td>
                        <td>{{c.Patient_Name}}</td>
                        <td>{{c.Invoice_Number}}</td>
                        <td>{{c.Receipt_Number}}</td>
                        <td>{{c.Vendor_Name}}</td>
                        <td>{{c.Vendor_Fees_Percent}}</td>
                        <td>{{c.Treatment_name}}</td>
                        <td>{{c.Amount_Paid}}</td>
                        <td>{{c.Payment_Mode}}</td>
                    </tr>
                </table>
                <div *ngIf="conHistorydata.length == 0" style="width:100%;margin-top:10%;text-align: center;"><div>No Payment Found</div></div>
           </div> 
        </div>
      </div>
    
    <!-- <div class="row" style="margin-top: 60px;">
        <table class="table">
            <thead>
                <tr style="background-color: #E5F0FF;">
                    <th class="th" scope="col">DATE & TIME</th>
                    <th class="th" scope="col">CATEGORY</th>
                    <th class="th" scope="col">BILL NO</th>
                    <th class="th" scope="col">PATIENT</th>
                    <th class="th" scope="col">MR NUMBER</th>
                    <th class="th" scope="col">DESCRIPTION</th>
                    <th class="th" scope="col">INVOICE VALUE</th>
                    <th class="th" scope="col">BILLED TO PATIENT</th>
                    <th class="th" scope="col">PATIENT PAYMENT</th>
                    <th class="th" scope="col">PENDING PAYMENT</th>
                    <th class="th" scope="col">PRINT</th>
                    <th class="th" scope="col">SEND SMS</th>
                </tr>
            </thead>
            <tbody *ngIf="payment">

                <tr  *ngFor="let p of payment">
                    <td class="td blue">{{p.bill_date | date:'dd-MMM-yyyy h:mm a'}}</td>
                    <td class="td">{{p.category}}</td>
                    <td class="td">{{p.bill_no}}</td>
                    <td class="td">{{p.name}}</td>
                    <td class="td">{{p.mr_number}}</td>
                    <td class="td">{{p.description}}</td>
                    <td class="td">{{p.doctor_charges | currency:"&#8377;"}}</td>
                    <td class="td">{{p.bill_amount | currency:"&#8377;"}}</td>
                    <td class="td">{{p.fee_paid | currency:"&#8377;"}}</td>
                    <td class="td">{{p.fee_pending | currency:"&#8377;"}}</td>
                    <td class="td">
                        <button (click)="print(p.bill_no)"
                            class="btn btn-primary">Print</button>
                    </td>
                    <td class="td">
                        <button (click)="sms(p.bill_no)"
                            class="btn btn-primary">Send
                            SMS</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->
</div>