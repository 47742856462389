<div style="display: flex;">
    <img style="cursor: pointer;margin-left: auto;" (click)="onNoClick()" src="assets/close-copy.svg">
</div>

<div *ngIf="data.id==1">
    <div class="malebox">
        <app-weight-for-height-length-male [chartData]="chartData">
        </app-weight-for-height-length-male>
    </div>
</div>

<div *ngIf="data.id==2">
    <div class="malebox">
        <app-wlhc-male [chartData]="chartData">
        </app-wlhc-male>
    </div>
</div>

<div *ngIf="data.id==3">
    <div class="malebox">
        <app-combined-chart-male [chartData]="chartData">
        </app-combined-chart-male>
    </div>
</div>

<div *ngIf="data.id==4">
    <div class="malebox">
        <app-iap-bmi-chart-male [chartData]="chartData">
        </app-iap-bmi-chart-male>
    </div>
</div>

<div *ngIf="data.id==5">
    <div class="femalebox">
        <app-weight-for-height-length-female [chartData]="chartData">
        </app-weight-for-height-length-female>
    </div>
</div>

<div *ngIf="data.id==6">
    <div class="femalebox">
        <app-wlhc-female [chartData]="chartData">
        </app-wlhc-female>
    </div>
</div>

<div *ngIf="data.id==7">
    <div class="femalebox">
        <app-combined-chart-female [chartData]="chartData">
        </app-combined-chart-female>
    </div>
</div>

<div *ngIf="data.id==8">
    <div class="femalebox">
        <app-iap-bmi-chart-female [chartData]="chartData">
        </app-iap-bmi-chart-female>
    </div>
</div>