<body>
    <div class="outer-container">
        <div class="inner-container">
          <div class="centered-content">
            <p class="login">Log in</p>
                <form [formGroup]="loginForm" (submit)="login()">
                    <div class="input-group username loginform">
                        <input type="text" class="form-control" placeholder="User Name" formControlName="username">
                    </div>
                    <p class="error" *ngIf="(loginForm.controls.username.touched||loginForm.controls.username.dirty||submitted)&&loginForm.controls.username.errors?.required">Username required</p>
                    <div class="input-group password loginform">
                        <input type="password" id="password" class="form-control" placeholder="Password" formControlName="password">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <mat-icon style="cursor:pointer" (click)="toggle()">{{p_icon}}</mat-icon>
                            </span>
                        </div>
                    </div>
                    <p class="error" *ngIf="(loginForm.controls.password.touched||loginForm.controls.password.dirty||submitted)&&loginForm.controls.password.errors?.required">Password required</p>
                    <p class="error" *ngIf="fail">Invalid username/password</p>
                    <div style="padding:10px">
                        <button class="btn btn-primary login_b" type="submit">Login</button>
                    </div>
                </form>            
          </div>
        </div>
     </div>
    
</body>