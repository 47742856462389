    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5">
                <div><span style="cursor:pointer" (click)="onNoClick()"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
            </div>
            <div class="col-lg-2">
                <!-- <h4>Remainder</h4> -->
                <div style="font-size: 15px;font-weight: bold; text-align: center;">Remainder</div>
            </div>
            <div class="col-lg-5"></div>
        </div>
    </div>
    <hr>
    <div class="container-fluid">
        <form [formGroup]="engagementPopup" (ngSubmit)="saveData()">
            <div class="row">
                <div class="col-lg-1">
                    <div style="font-size: 15px;font-weight: bold; text-align: center;">Rule</div>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-5">
                    <div style="font-size: 15px;font-weight: bold; text-align: center;">Days</div>
                </div>
                <!-- <div class="col-lg-5"></div> -->
                <div class="col-lg-4"></div>
            </div>
            <div class="row">
                <div class="col-lg-5" style="margin-top: 15px;">
                    <mat-radio-group
                    [color]="'primary'"
                    formControlName="beforeAfter"
                    >
                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 10px;color: #617595;">Before</mat-radio-button>
                        <mat-radio-button class="example-radio-button" [value]="1" style="color: #617595;margin-right: 10px">On that day</mat-radio-button>
                        <mat-radio-button class="example-radio-button" [value]="2" style="color: #617595;">After</mat-radio-button>

                    </mat-radio-group>
                </div>
                <div class="col-lg-5" style="margin-top: 10px;">
                    <input type="text" class="form-control" placeholder="Eg: 10" formControlName="remainderDays" style="width: 30%;">
                </div>
                <div class="col-lg-2"></div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-3">
                    <div style="font-size: 15px;font-weight: bold; text-align: center;margin-left: -60px;">Messaging Type</div>
                </div>
                <div class="col-lg-9"></div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="text"><img src="assets/engagement/text file.svg" style="width: 26px;height: 20px;margin-left: 5px;"><span style="color: #617595;font-size: 15px;">Plain Text</span></mat-checkbox>
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="image"><img src="assets/engagement/image.svg" style="width: 19px;height: 19px;margin-left: 5px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Image</span></mat-checkbox> 
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="audio"><img src="assets/engagement/noun_Audio_565852.svg" style="width: 14px;height: 24px;margin-left: 5px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Audio</span></mat-checkbox> 
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="video"><img src="assets/engagement/video.svg" style="width: 22px;height: 14px;margin-left: 5px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Video</span></mat-checkbox>
                </div>
                <div class="col-lg-4"></div>
            </div>
            <div class="row" style="margin-top: 15px;">
                <div class="col-lg-3">
                    <div style="font-size: 15px;font-weight: bold; text-align: center;margin-left: -50px;">Messaging Media</div>
                </div>
                <div class="col-lg-9"></div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="all" (change)="selectAll()"><img src="assets/engagement/all.svg" style="width: 18px;height: 15px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">All</span></mat-checkbox> 
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="sms"><img src="assets/engagement/sms.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;" >Sms</span></mat-checkbox> 
                </div>
                <div class="col-lg-3">
                    <mat-checkbox class="example-margin" formControlName="notification"><img src="assets/engagement/Notifcation.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Push Message</span></mat-checkbox> 
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="email"><img src="assets/engagement/mail.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Email</span></mat-checkbox> 
                </div>
                <div class="col-lg-2">
                    <mat-checkbox class="example-margin" formControlName="ivrs"><img src="assets/engagement/ivrs.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">IVRS</span></mat-checkbox> 
                </div>
                <div class="col-lg-1"></div>
            </div>
            <div class="row" style="margin-top: 5px;">
                <div class="col-lg-3">
                    <mat-checkbox class="example-margin" formControlName="call"><img src="assets/engagement/phone-call.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Call</span></mat-checkbox> 
                </div>
                <div class="col-lg-3">
                    <mat-checkbox class="example-margin" formControlName="location"><img src="assets/engagement/location.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Visit</span></mat-checkbox> 
                </div>
                <div class="col-lg-3">
                    <mat-checkbox class="example-margin" formControlName="camcorder"><img src="assets/engagement/camcorder.svg" style="width: 20px;height: 20px;"><span style="color: #617595;font-size: 15px;margin-left:3px;">Home Video</span></mat-checkbox> 
                </div>
                <div class="col-lg-3">
                    <mat-checkbox class="example-margin" formControlName="ads">
                        <img src="assets/engagement/ads.svg" style="width: 20px;height: 20px;">  
                        <span style="color: #617595;margin-left:3px;font-size: 15px;">Ads</span>
                    </mat-checkbox> 
                </div>
            </div>
            <div class="row" style="margin-top: 15px;">
            <div class="col-lg-3" style="margin-left: -15px;">
                <div style="font-size: 15px;font-weight: bold; text-align: center;margin-left: -20px;">Remainder Name</div>
            </div> 
            <div class="col-lg-9"></div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-9">
                    <input type="text" class="form-control" formControlName="remainderName" width="75%" placeholder="Enter" 
                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);">
                </div>
                <div class="col-lg-3"></div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-lg-2" style="margin-left: -15px;">
                    <div style="font-size: 15px;font-weight: bold; text-align: center;margin-left: -20px;">Content</div>
                </div>
                <div class="col-lg-10"></div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-9">
                    <!-- <input type="text" class="form-control" formControlName="content" style="height:250%;" placeholder="Enter the Content"> -->
                    <textarea  rows="5"  cols="80" class="form-control" placeholder="Enter the Content" formControlName="content"></textarea>
                </div>
                <div class="col-lg-3"></div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-lg-4"></div>
                <div class="col-lg-2">
                    <button class="btn btn-outline-primary" (click)="onNoClick()">Cancel</button>
                </div>
                <div class="col-lg-3">
                    <button class="btn btn-primary" type="submit">Save</button>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </form>
    </div>

