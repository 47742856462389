<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div [ngStyle]="mode_?{'display': 'none'} : {'display': 'block'}" class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider2">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;" src="assets/circle9.svg" 
            
            [class.ml_]="slider_value==50" [class.mg_]="slider_value==90"
            [class.mmg_]="slider_value==130" [class.gm_]="slider_value==170"
            [class.mol_]="slider_value==210"  [class.mmol_]="slider_value==250"
            [class.syrup_]="slider_value==290" [class.drops_]="slider_value==330"
            [class.pockets_]="slider_value==10">
        </div>
    
        <div class='container container_'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(90)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==90?'assets/unit5.svg':'assets/unit6.svg'">
                </div>

                <span  [class.bold]="slider_value==90">microgram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;"  (click)="changeSliderValue(130)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==130?'assets/unit3.svg':'assets/unit4.svg'">

                </div>


                <span [class.bold]="slider_value==130">milligram</span>
            </span>

            

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(170)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==170?'assets/unit7.svg':'assets/unit8.svg'">

                </div>
            

                <span  [class.bold]="slider_value==170">gram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(210)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==210?'assets/unit9.svg':'assets/unit10.svg'">

                </div>


                <span  [class.bold]="slider_value==210">mole</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(250)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==250?'assets/unit11.svg':'assets/unit12.svg'">

                </div>


                <span [class.bold]="slider_value==250">millimole</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(290)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==290?'assets/unit13.svg':'assets/unit14.svg'">

                </div>


                <span [class.bold]="slider_value==290">Syrup</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(330)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==330?'assets/unit15.svg':'assets/unit16.svg'">

                </div>


                <span [class.bold]="slider_value==330">Drops</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(50)">
                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==50?'assets/unit1.svg':'assets/unit2.svg'">

                </div>
                
                <span [class.bold]="slider_value==50">milliliter</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(10)">
                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==10?'assets/unit1.svg':'assets/unit2.svg'">

                </div>
                
                <span [class.bold]="slider_value==10">Sachets/ <br> Packets</span>
            </span>
        </div>
    </div>
<!-- </div> -->
<!-- only for srinivas -->
<!-- <div style="height: 27.5rem;width: 30rem;overflow: hidden;"> -->
    <div [ngStyle]="mode_?{'display': 'block'} : {'display': 'none'}" class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider2">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;" src="assets/circle_9.png" 
            
            [class.ml]="slider_value==36" [class.mg]="slider_value==108"
            [class.mmg]="slider_value==144" [class.gm]="slider_value==180"
            [class.mol]="slider_value==216"  [class.mmol]="slider_value==252"
            [class.syrup]="slider_value==288" [class.drops]="slider_value==324"
            [class.pockets]="slider_value==0" [class.empty]="slider_value==72">
        </div>
    
        <div class='container container_srini'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(108)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==108?'assets/unit5.svg':'assets/unit6.svg'">
                </div>

                <span  [class.bold]="slider_value==108">microgram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;"  (click)="changeSliderValue(144)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==144?'assets/unit3.svg':'assets/unit4.svg'">

                </div>


                <span [class.bold]="slider_value==144">milligram</span>
            </span>

            

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(180)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==180?'assets/unit7.svg':'assets/unit8.svg'">

                </div>
            

                <span  [class.bold]="slider_value==180">gram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(216)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==216?'assets/unit9.svg':'assets/unit10.svg'">

                </div>


                <span  [class.bold]="slider_value==216">mole</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(252)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==252?'assets/unit11.svg':'assets/unit12.svg'">

                </div>


                <span [class.bold]="slider_value==252">millimole</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(288)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==288?'assets/unit13.svg':'assets/unit14.svg'">

                </div>


                <span [class.bold]="slider_value==288">Syrup</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(324)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==324?'assets/unit15.svg':'assets/unit16.svg'">

                </div>


                <span [class.bold]="slider_value==324">Drops</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(72)">

                <div style="display: flex;justify-content: center;">
                    <!-- <img [src]="slider_value==98?'assets/unit15.svg':'assets/unit16.svg'"> -->

                </div>


                <span [class.bold]="slider_value==72" style="visibility:hidden">empty</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(36)">
                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==36?'assets/unit1.svg':'assets/unit2.svg'">

                </div>
                
                <span [class.bold]="slider_value==36">milliliter</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(0)">
                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==0?'assets/unit1.svg':'assets/unit2.svg'">

                </div>
                
                <span [class.bold]="slider_value==0">Sachets/ <br> Packets</span>
            </span>
        </div>
    </div>
</div>


<div style="display: flex;justify-content: space-around; justify-content: space-around;">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>