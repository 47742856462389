import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import * as html2pdf from 'html2pdf.js';
import { AppService } from '../app.service';
import { AppConfig } from 'src/app/app.config';
@Component({
  selector: 'app-ordersreport',
  templateUrl: './ordersreport.component.html',
  styleUrls: ['./ordersreport.component.scss']
})
export class OrdersreportComponent implements OnInit {
  constructor(private appService:AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
   }
   urlPoints;
  selected:any;
  startdateInFormat = '-';
  getauthdetails;
  enddateInFormat = '-';
  sendstartDate;
  sendendDate;
  doctor_id;
  demail;
  ordersHistory;
  Total_value
  token;
  platform='All'
  maxDate = moment();
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  ngOnInit(): void {
    this.getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
this.token = this.getauthdetails.token
this.doctor_id = this.getauthdetails.doctor_id
this.demail=this.getauthdetails.account_id;
console.log(this.ordersHistory);
const defaultStartDate = moment();
const defaultEndDate = moment();
this.selected = {
  startDate: defaultStartDate,
  endDate: defaultEndDate
};
this.startdateInFormat = defaultStartDate.format('YYYY-MM-DD');
this.enddateInFormat = defaultEndDate.format('YYYY-MM-DD');
this.getOrderHistoryAPI();
  }
  isInvalidDate = (m: moment.Moment) => {
    console.log("m::",m)
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }
  datesUpdated(range) {

    const startDate = moment(this.selected.startDate.toDate());
    const endDate = moment(this.selected.endDate.toDate());
    const diffInDays = endDate.diff(startDate, 'days');

    console.log("diffInDays::",diffInDays);
    this.sendstartDate = startDate.format('YYYY-MM-DD');
    this.sendendDate = endDate.format('YYYY-MM-DD');
    console.log('sendstartDate:::',this.sendstartDate,this.sendendDate)
    if(diffInDays < 365*2){
      this.getOrderHistoryAPI();
    }else{
      alert(`Date range cannot exceed ${365*2} days`);
      this.selected = ''
    }
    
  }
  platformChange(e){
    if(this.sendstartDate &&this.sendendDate){
this.getOrderHistoryAPI();
    }
    else{
      alert('Please select the Date range!');
    }
  }
  downloadXLS(){
    var blob = new Blob([document.getElementById('mytable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "OrderHistoryReport.xls");
  }
  downloadPDF(){
    var element = document.getElementById('mytable');
 var opt = {
   margin: 0.1,
   filename: 'OrderHistoryReport.pdf',
   image: { type: 'jpeg', quality: 0.98 },
   html2canvas: { scale: 1 },
   jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
html2pdf().from(element).set(opt).save();
 }

 getOrderHistoryAPI(){
  console.log('this.platform',this.platform)
  const param={
'from_date':this.sendstartDate,
'to_date':this.sendendDate,
'doctor_id':this.doctor_id,
'account_email':this.demail,
'order_platform':this.platform === "All" ? 0 : this.platform === "Epicare" ? 1 : this.platform === "obs" ? 2 : 3
  }
  this.appService.getData(this.urlPoints.getEshopOrdersReport, param, this.token).subscribe((res: any)=>{
    console.log('res:::',res);
    this.ordersHistory = res.message;
    this.Total_value = res.total_value;
  })
 }

}
