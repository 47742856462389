<div class="d-flex" id="wrapper">
    <!-- Left Sidebar -->
    
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
          <h2 style="margin-top:10px">Reports</h2>
          
        </div>
    </nav>
        <!-- /#footer -->
    </div>
  </div>

  <div class="bodycontainer">
    <div class="row">
        <!--left View-->
        <div class="bodycontentLefView">
            <div class="reportContainar">
                <div class="border-right secondryleftSubmenu">
                    <div class="list-group list-group-flush">
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_0" (click)="sublefttabs(0)" routerLink="dailycollection"><img  src="assets/images/daily_collection/daily_collection.png"> <span style="margin-left: 5px;margin-top: 5px;">Daily collection</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_16" (click)="sublefttabs(16)" routerLink="cashcollection"> <img src="assets/images/IVRSCall.svg"> <span style="margin-left: 5px;margin-top: 6px;">Cash Collection</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_18" (click)="sublefttabs(18)" routerLink="orderhistory"> <img src="assets/images/History_3.svg"> <span style="margin-left: 5px;margin-top: 6px;">Order History</span></a>

                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_1" (click)="sublefttabs(1)" routerLink="dailyRangecollection"><img src="assets/images/daily_range_collection.svg"> <span style="margin-left: 5px;margin-top: 2px;">Daily Range collection</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_2" (click)="sublefttabs(2)" routerLink="registration"><img src="assets/images/file/file.png"> <span style="margin-left: 5px;margin-top: 5px;">Registration</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_3" (click)="sublefttabs(3)" routerLink="walletTransaction"><img src="assets/images/XMLID_561_/XMLID_561_.png"> <span style="margin-left: 5px;">Wallet Transaction</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_4" (click)="sublefttabs(4)" routerLink="videocall"><img src="assets/images/video-web/video-web.png"> <span style="margin-left: 5px;margin-top: 8px;">Videocall</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_5" (click)="sublefttabs(5)" routerLink="followup"><img src="assets/images/newspaper/newspaper.png"> <span style="margin-left: 5px;margin-top: 5px;">Followup</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_6" (click)="sublefttabs(6)" routerLink="covid"><img src="assets/images/covid-19/covid-19.png"> <span style="margin-left: 5px;margin-top: 5px;">Covid</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_7" (click)="sublefttabs(7)" routerLink="vaccination"><img src="assets/images/vaccination/vaccination.png"> <span style="margin-left: 5px;margin-top: 5px;">Vaccination</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_8" (click)="sublefttabs(8)" routerLink="homeVisit"> <img src="assets/images/home/home.png"> <span style="margin-left: 5px;">Home Visit Compliance</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_17" (click)="sublefttabs(17)" routerLink="homevisitfollowup"> <img src="assets/images/home followup visit.svg"> <span style="margin-left: 5px;">Home Visit Follow Up</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_9" (click)="sublefttabs(9)" routerLink="vhnperformance"> <img src="assets/images/analytics/analytics.png"> <span style="margin-left: 5px;">VHN Performance</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_10" (click)="sublefttabs(10)" routerLink="babyHealth"> <img src="assets/images/medical-history/medical-history.png"> <span style="margin-left: 5px;">Baby Health Status</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_11" (click)="sublefttabs(11)" routerLink="babyGrowth"> <img src="assets/images/medical-report/medical-report.png"> <span style="margin-left: 5px;">Baby Growth Status</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_12" (click)="sublefttabs(12)" routerLink="feedback"> <img src="assets/images/user_feedback/user_feedback.png"> <span style="margin-left: 5px;margin-top: 6px;">User Feedback</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_13" (click)="sublefttabs(13)" routerLink="mobileAccess"> <img src="assets/images/Group 2/Group 2.png"> <span style="margin-left: 5px;margin-top: 6px;">Mobile Access</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_14" (click)="sublefttabs(14)" routerLink="mhusers"> <img src="assets/images/MyHealth.svg"> <span style="margin-left: 5px;margin-top: 6px;">MH Useres</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_15" (click)="sublefttabs(15)" routerLink="ivrs"> <img src="assets/images/IVRSCall.svg"> <span style="margin-left: 5px;margin-top: 6px;">IVRS</span></a>
                        <a class="list-group-item list-group-item-action submenulink leftsubtabselect" id="id_16" (click)="sublefttabs(16)" routerLink="overallpatient"> <img src="assets/images/History_3.svg"> <span style="margin-left: 5px;margin-top: 6px;">Overall Patient</span></a>

                    </div>
                </div>
            </div>
          
        </div>
        <!--right View-->
        <div class="bodycontentRightView">
            <router-outlet></router-outlet>
        </div>
    </div>
    <br>
  </div>


