<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider3">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;border-radius: 50%;margin-left: 2.5rem;"
            [class.before]="slider_value==315" [class.not]="slider_value==135" [class.with]="slider_value==225"
            [class.after]="slider_value==45"
            src="assets/four1.svg">
        </div>
    
        <div class='container'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(315)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==315?'assets/when1.svg':'assets/when2.svg'">
                </div>
                <span [class.bold]="slider_value==315">Before Food</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(45)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==45?'assets/when3.svg':'assets/when4.svg'">
                </div>
    
                <span [class.bold]="slider_value==45">After Food</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(135)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==135?'assets/when5.svg':'assets/when6.svg'">
                </div>
    
                <span  [class.bold]="slider_value==135">Not Related to Food</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(225)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==225?'assets/when7.svg':'assets/when8.svg'">
                </div>
    
                <span  [class.bold]="slider_value==225">With Food</span>
            </span>
            
        </div>
    </div>
</div>

<div style="display: flex;justify-content: space-around; justify-content: space-around;position: relative">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>