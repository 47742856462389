import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent
} from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
declare let alasql;


@Component({
  selector: 'app-vhnperformance',
  templateUrl: './vhnPerformance.component.html',
  styleUrls: ['./vhnPerformance.component.scss']
})
export class VhnperformanceComponent implements OnInit {
  
  @Input() headerTitle: string;
  paymentCategoryForm: FormGroup;

  dmail;
  doc_id ;
  token;
  drId: number;
  params = {};
  getWalletList:any;
  getdistrict:any;
  getblock:any;
  staffprofile:any;
  staffvillage:any;
  villageId:number;

  distId: number;
  blockId: number;
  vhnId:number;
  private sub: any;
  visitReoprt:any;
  grandTotalscheduled:any;
  grandTotalontime:any;
  grandTotalnotontime:any;
  grandTotalcompliance:any;
  grandTotalnoncompliance:any;
  filterregisration: FormGroup;

  todayDate:any;
startdateInFormat = '-';
enddateInFormat = '-';
sendstartDate:any;
sendendDate:any;
selected: any;
alwaysShowCalendars: boolean;
ranges: any = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}
invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  
  constructor(private route: ActivatedRoute,private formBuild: FormBuilder, private toastr: ToastrService, private appService: AppService,private http: HttpClient, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    this.filterregisration = this.formBuild.group({
      distName: ['', Validators.required],
      
    });
    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    let sendDate = momDate.format('YYYY-MM-DD')
    
    this.getDistrict();
    
  }

  getDistrict(){
    this.params = {
      "account_email": this.dmail,
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.getdistrict, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.getdistrict = response;
      });
  }

  ondistrictChange(e){
    this.distId = e.target.value.split(": ")[1];
    this.getBlock();
   }

   onblockChange(e){
    this.blockId = e.target.value.split(": ")[1];
    this.getvhn();
   }

  getBlock(){
    this.params = {
      "account_email": this.dmail,
      "id": this.distId
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.getblock, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.getblock = response;
      });
  }

  getvhn(){
    this.params = {
      "account_email": this.dmail,
      "id": this.blockId
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.getstaffprofile, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.staffprofile = response;
      });
  }

  onvhnChange(e){
    this.vhnId = e.target.value.split(": ")[1];
    this.getvillage();
   }

   getvillage(){
    this.params = {
      "account_email": this.dmail,
      "id": this.vhnId
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.getstaffvillages, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.staffvillage = response;
      });
  }

  onvilageChange(e){
    this.villageId = e.target.value.split(": ")[1];
  }

  getReport(){

    this.params = {
      account_email: this.dmail,
      block_id: this.blockId,
    district_id: this.distId,
    start_dt:  this.sendstartDate,
    staff_id: this.vhnId,
    end_dt: this.sendendDate,
  village_id: this.villageId
    }

  this.appService
      .postData(AppConfig.settings.EndPoints.vhnReport, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.visitReoprt = response.visitdetails;
        this.grandTotalscheduled = response.grandtotal.scheduled;
        this.grandTotalontime = response.grandtotal.visited;
        this.grandTotalnotontime= response.grandtotal.notvisited;
        this.grandTotalcompliance = response.grandtotal.adherence;
        this.grandTotalnoncompliance = response.grandtotal.nonadherence;
        
      });
  }
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
  }

  datesUpdated(range) {
    const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
   
   this.sendstartDate = startDate.format('YYYY-MM-DD');
   this.sendendDate = endDate.format('YYYY-MM-DD');
  }

  downloadPDF(){
     var element = document.getElementById('printtable');
  var opt = {
    margin: 0.1,
    filename: 'VHNPerformanceReport.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
 // New Promise-based usage:
 html2pdf().from(element).set(opt).save();
  }

  downloadCSV(){
    let filename = 'VHNPerformanceReport'
    alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#printtable',{headers:true,separator:','})");
  };

  downloadXLS(){
    var blob = new Blob([document.getElementById('printtable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "VHNPerformanceReport.xls");
  }

}
