import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';
import * as html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



@Component({
  selector: 'app-patient-reports',
  templateUrl: './patient-reports.component.html',
  styleUrls: ['./patient-reports.component.scss']
})
export class PatientReportsComponent implements OnInit {

  urlPoints: any;
  code = sessionStorage.getItem("code");
  token;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  todayDate:any;
  sendstartDate: any;
  sendendDate: any;
  selected: any;
  startdateInFormat = '-';
  enddateInFormat = '-';
  clr = 0;
  getreportData: any = [];
  showType: any = 1;
  dmail: any;
  doc_id: any;
  Father: any;
  mother: any;
  Name: any;
  mrNO: any;
  dob: any;
  gender: any;
  phNo: any;
  height: any;
  weight: any;
  mmol: any;
  mgol: any;
  tem: any;
  rDate: any;
  heart: any;
  sys: any;
  dia: any;
  oxy: any;
  selectedBack: any = false;
  tableContent: string = '';
  getreportDatacopy: any;
  getreportDatamain: any = [];

  constructor(private appService: AppService, private router: Router , private toastr: ToastrService, public dialog: MatDialog) { 
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit(): void {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    let sendDate = momDate.format('DD-MM-YYYY')
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
    //console.log('[rangeClicked] range is : ', range);
  }

  getPatientreport(type){
    var param = {
      account_email: this.dmail,
      from_date: this.sendstartDate,
      to_date: this.sendendDate
    }
    this.appService.getData(this.urlPoints.getpatientreport, param, this.token).subscribe((response: any) => {
      if(response.success==1){
        this.getreportData =  response.message;

        this.getreportDatamain = this.getreportData

        this.getreportDatacopy = this.getreportData
        

        this.showType = type
      }

    });
  }

  datesUpdated(range) {
   //console.log('[datesUpdated] range is : ', range);
   
    const startDate = moment(this.selected.startDate.toDate());
    const endDate = moment(this.selected.endDate.toDate());
    this.startdateInFormat = startDate.format('DD/MM/YYYY');
    this.enddateInFormat = endDate.format('DD/MM/YYYY');
    console.log(this.startdateInFormat,'from');
    console.log(this.enddateInFormat,'to');
    this.sendstartDate = startDate.format('YYYY-MM-DD');
    this.sendendDate = endDate.format('YYYY-MM-DD');
    // this.getReport(this.sendstartDate,this.sendendDate);
    this.getPatientreport(1)
  }

  downloadPDF(file){
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogCheckboxoptions, {
      width: '700px',
      data: {type: file, dataResult: this.getreportData},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.selectedBack = true
        console.log(result,'selected');       
        this.Name = result.name;
        this.mrNO = result.mr;
        this.dob = result.dob;
        this.gender = result.gender;
        this.phNo = result.phno;
        this.height = result.height;
        this.weight = result.weight;
        this.mmol = result.mmol;
        this.mgol = result.mgol;
        this.tem = result.tem;
        this.rDate = result.rdate;
        this.heart = result.heart;
        this.sys = result.sys;
        this.dia = result.dia;
        this.oxy = result.oxy;
        this.Father = result.father;
        this.mother = result.mother

        if(result.fileType=='PDF'){
          if(this.Father==false&&this.oxy==false&&this.dia==false&&this.sys==false&&this.heart==false&&this.rDate==false
            &&this.tem==false&&this.mgol==false&&this.mmol==false){
            this.pdfType1()
          }else{
            this.pdfType2()
          }
        }else{
          this.downloadXLS()
        }

      }
    })

  }

  pdfType1(){
    this.clr = 1;
    var element = document.getElementById('mytable');
    var opt = {
      margin: 0,
      filename: 'OverallPatientReport.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
    };
    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
  }

  pdfType2(){
    this.clr = 1;
    var element = document.getElementById('mytable');
    var opt = {
      margin: 0,
      filename: 'OverallPatientReport.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a1', orientation: 'landscape' }
    };
    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
  }
  
  downloadXLS(){

    const dialogRef = this.dialog.open(DialogOverviewExampleDialogCheckboxoptions, {
      width: '700px',
      data: {type: 'XLS', dataResult: ''},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.selectedBack = true
        console.log(result,'selected');     
        this.getreportDatacopy.forEach(patient => {
          patient.registred_date = this.formatDate(patient.registred_date);
        });
  
        this.getreportDatacopy.forEach(patient =>{
          patient.dob = this.formatDate1(patient.dob)
        })
        this.deleteKeysFromArrayObjects(this.getreportDatacopy,['mother_name','kce_id','measured_date','arm_circumference','latest_vital_record','latest_height_date','latest_weight_date','head_circumference','latest_head_circumference_date','latest_bp_systolic_date','latest_bp_diastolic_date','latest_temperature_date','latest_oxygen_saturation_date','latest_heart_rate_date','latest_respiratory_rate_date','respiratory_rate','chest_circumference','latest_chest_circumference_date','latest_arm_circumference_date','glucometer'])

        // console.log('getreportDatacopy',this.getreportDatacopy);
        // var send = ['name','mr_number','dob','gender','phone_number','height','weight','temperature','registred_date','heart_rate','bp_systolic','bp_diastolic','oxygen_saturation','father_name']  
        var send1 = []
        this.Name = true;
        this.mrNO = true;
        this.dob = true;
        this.gender = true;
        this.phNo = true;
        this.height = true;
        this.weight = true;
        this.tem = result.tem;
        if(this.tem==false){
          send1.push('temperature')
        }
        this.rDate = result.rdate;
        if(this.rDate==false){
          send1.push('registred_date')
        }
        this.heart = result.heart;
        if(this.heart==false){
          send1.push('heart_rate')
        }
        this.sys = result.sys;
        if(this.sys==false){
          send1.push('bp_systolic')
        }
        this.dia = result.dia;
        if(this.dia==false){
          send1.push('bp_diastolic')
        }
        this.oxy = result.oxy;
        if(this.oxy==false){
          send1.push('oxygen_saturation')
        }
        this.Father = result.father;
        if(this.Father==false){
          send1.push('father_name')
        }
        this.mother = result.mother
        
        // console.log(send,'send');

        console.log(send1,'send1');

        console.log('getreportDatacopy',this.getreportDatacopy);

        this.deleteKeysFromArrayObjects(this.getreportDatacopy,send1)
  
        this.appService.jsonToExcel(this.getreportDatacopy, 'OverallPatientReport');

        this.getPatientreport(1)

      }
    })

  }

  xlsType(){
    this.clr = 1
    this.tableContent = document.getElementById('mytable1')?.innerHTML || '';
    if (this.tableContent) {
      const blob = new Blob([this.tableContent], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      });
      saveAs(blob, 'OverallPatientReport.xls');
    } else {
      console.error('Table content not available.');
    }
    
  }

  deleteKeysFromArrayObjects(array, keysToDelete) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      keysToDelete.forEach(key => {
        if (obj.hasOwnProperty(key)) {
          delete obj[key];
        }
      });
    }
  }


  formatDate(inputDate: string): string {
    const dateParts = inputDate.split(' ')[0].split('-');
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }

  formatDate1(inputDate: any){
    const dateParts = inputDate.split('-');
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }

}


@Component({
  selector: 'download-checkboxoptions',
  templateUrl: 'checkBoxoption.html',
  styleUrls: ['checkBoxoption.scss']
})

export class DialogOverviewExampleDialogCheckboxoptions{

  getreportData: any = [];
  showType = 1;
  Father = false;
  mother = false;
  clr = 0;
  Name = true;
  mrNO = true;
  dob = true;
  gender = true;
  phNo = true;
  height = true;
  weight = true;
  mmol = false;
  mgol = false;
  tem = false;
  rDate = false;
  heart = false;
  sys = false;
  dia = false;
  oxy = false

  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialogCheckboxoptions>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    console.log(this.data);
    // this.getreportData = this.data.dataResult
  }

  selectOption(data){
    console.log(data);
  }

  close(){
    this.dialogRef.close()
  }

  print(){
    // if(this.data.type=='pdf'){

    //   this.clr = 1;
    //   var element = document.getElementById('mytable');
    //   console.log(element);
    //   var opt = {
    //     margin: 0,
    //     filename: 'OverallPatientReport.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'in', format: 'a1', orientation: 'landscape' }
    //   };
    // // New Promise-based usage:
    // html2pdf().from(element).set(opt).save();
    // }
    this.dialogRef.close(
      {father: this.Father,
        mother: this.mother,
        mr: this.mrNO,
        name: this.Name,
        gender: this.gender,
        phno: this.phNo,
        dob: this.dob,
        height: this.height,
        weight: this.weight,
        sys: this.sys,
        dia: this.dia,
        tem: this.tem,
        rdate: this.rDate,
        heart: this.heart,
        mmol: this.mmol,
        mgol: this.mgol,
        oxy: this.oxy,
        fileType: this.data.type
      }
      )
  }
}