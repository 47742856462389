<div>
    <p>{{data.name}}<b>{{data.product}}</b>?</p>
    </div>
    
 
    <!-- <div class="col-lg-2 col-md-2" style="display: flex; justify-content: center">
    <button class="btn btn-secondary" (click)="noSave(1)" style="width: fit-content">
      No
    </button>
    </div>

    <div class="col-lg-2 col-md-2" style="display: flex; justify-content: center">
        <button class="save btn" (click)="save(1)" style="width: fit-content">
          Okay
        </button>
        </div> -->


        <div style="display: flex;justify-content: center;">
            <button class="btn btn-secondary" (click)="noSave(1)" style="width: fit-content;">No</button>
            <button class="save btn" (click)="save(2)" style="width: fit-content;
            margin-left: 33px;
        ">Yes</button>
        </div>