import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent
} from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
declare let alasql;


@Component({
  selector: 'app-mobileaccess',
  templateUrl: './mobileaccess.component.html',
  styleUrls: ['./mobileaccess.component.scss']
})
export class MobileaccessComponent implements OnInit {
  
  @Input() headerTitle: string;
  paymentCategoryForm: FormGroup;
  
  params = {};
  report:any;
  todayDate;
  dmail;
  doc_id ;
  token;
  drId: number;
  
  constructor(private route: ActivatedRoute,private formBuild: FormBuilder, private toastr: ToastrService, private appService: AppService,private http: HttpClient, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');

    this.getReport();
    
  }

  

  getReport(){

    this.params = this.dmail;

  this.appService
      .getRestobjaccess(AppConfig.settings.EndPoints.accessreport,this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.report = response.result;
        
        
      });
  }

  
  downloadPDF(){
    var element = document.getElementById('printtable');
 var opt = {
   margin: 0.1,
   filename: 'MobileAccessReport.pdf',
   image: { type: 'jpeg', quality: 0.98 },
   html2canvas: { scale: 1 },
   jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
// New Promise-based usage:
html2pdf().from(element).set(opt).save();
 }

 downloadCSV(){
   let filename = 'MobileAccessReport'
   alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#printtable',{headers:true,separator:','})");
 };

 downloadXLS(){
   var blob = new Blob([document.getElementById('printtable').innerHTML], {
     type: "application/vnd.ms-excel;charset=utf-8"
   })
   saveAs(blob, "MobileAccessReport.xls");
 }

  
  
  back() {
    this.router.navigate(['/reports'], { queryParams: {account_email: localStorage.getItem('ACCOUNTID'),status: localStorage.getItem('bannerStatus'),doctor_id: localStorage.getItem('dr_id'),auth_token:localStorage.getItem('TOKEN')}});
  }
  
}
