<div>
    <p style="font-weight:800">Select a Template</p>
    <div style="justify-content: right;display: flex;margin-top: -32px;">
        <button [class.disable_click]="!submitted" style="margin-left: 5px;" class="ok btn" (click)="ok()">Select</button>
    </div>
</div> 

<div class="row">
    <div class="col-md-5">
        <div style="font-weight:800;color:#0063FF">Print Prescription</div>
        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">Frequently Used</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div *ngFor="let freqtemplist of freqprintData;let i=index">
                <div class="item" (click)="selectTemp(freqtemplist.template_id,freqtemplist.category,freqtemplist.template_name,freqtemplist.sub_category)" [class.selected]="selected==freqtemplist.template_id">{{freqtemplist.template_name}}</div>
            </div>
        </div>
        

        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">All</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div *ngFor="let templist of templataeData;let i=index">
                <div class="item" (click)="selectTemp(templist.template_id,templist.category,templist.template_name,templist.sub_category)" [class.selected]="selected==templist.template_id">{{templist.template_name}}</div>
            </div>
        </div>
        
        <div style="font-weight:800;color:#0063FF;margin-top:20px">Certificate and Others</div>
        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">Frequently Used</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div *ngFor="let freqcatelist of freqceritificateData;let i=index">
                <div class="item" (click)="selectTemp(freqcatelist.template_id,freqcatelist.category,freqcatelist.template_name,freqcatelist.sub_category)" [class.selected]="selected==freqcatelist.template_id">{{freqcatelist.template_name}}</div>
            </div>
        </div>
        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">All</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div *ngFor="let catelist of cateTempdata;let i=index">
                <div class="item" (click)="selectTemp(catelist.template_id,catelist.category,catelist.template_name,catelist.sub_category)" [class.selected]="selected==catelist.template_id">{{catelist.template_name}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-1 leftBorder">

    </div>
    <div class="col-md-6">
        <div style="font-weight:800;color:#0063FF">Consultation</div>

        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">Frequently Used</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div *ngFor="let freqconsultlist of freqconsultData;let i=index">
                <div [class.disable_click]="!consultTemp_status" class="item" (click)="selectconsultTemp(freqconsultlist.id,freqconsultlist.template_name)" [class.selected]="consultselected==freqconsultlist.id">{{freqconsultlist.template_name}}</div>
            </div>
        </div>
        

        <div style="color:#7D7D7D;font-size: 14px;margin-top:20px">All</div>
        <div style="display: flex;flex-flow: row wrap;margin-top:-8px">
            <div [class.disable_click]="!consultTemp_status" class="item" (click)="selectconsultTemp(0,'')" [class.selected]="consultselected==0">New Consultation</div>
            <div *ngFor="let consultlist of consltTempData;let i=index">
                <div [class.disable_click]="!consultTemp_status" class="item" (click)="selectconsultTemp(consultlist.id,consultlist.template_name)" [class.selected]="consultselected==consultlist.id">{{consultlist.template_name}}</div>
            </div>
        </div>
    </div>
</div>

