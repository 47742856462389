import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { DomSanitizer } from '@angular/platform-browser';
import { PubNubAngular } from 'pubnub-angular2';

@Component({
  selector: 'app-vitall',
  templateUrl: './vitall.component.html',
  styleUrls: ['./vitall.component.scss'],
  providers: [PubNubAngular]
})
export class VitallComponent implements OnInit {
  routeURL:any;

  dmail;
  token;
  doc_id;
  dr_name;
  dr_degree;
  hos_name;

  getaccount_email: any;
  getdrID:any;
  getauthToken: any;
  setaccount_email: any;
  setdrID:any;
  setauthToken: any;
  urlPoints: any;
  authToken: any;
  vitallurl;
  getVitallAccess_status = 0;
  getVitallAccess_code;
  getVitallMultiAccess;
  multiaccesscall_status=false;
  passChooseStatus = false;
  loader = false;
  

  constructor(private router:Router,private route: ActivatedRoute, pubnub: PubNubAngular,private dom: DomSanitizer, private appService: AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
    
    router.events.subscribe( (event) => ( event instanceof NavigationEnd ) && this.handleRouteChange() )
  }

  handleRouteChange = () => {
     
  };

  
  ngOnInit() {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dr_name = getauthdetails.doctor_name;
    this.dr_degree = getauthdetails.doctor_degree;
    this.hos_name = getauthdetails.hospital_name;
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    this.loader = true;
    this.checkVitallAccess();
  }

  checkVitallAccess(){

    var params ={
      "account_email" : this.dmail,
      "doctor_id" : this.doc_id
      //"doctor_id" : 1
    }
    this.appService.postData(this.urlPoints.getvitallaccesscode, params, this.token).subscribe((response: any) => {
      this.getVitallAccess_status = response.success;
      if(this.getVitallAccess_status ==1){
        this.getVitallAccess_code = response.codes[0].code;

        let geturl = 'https://ivital.co/route/#/home?pass='+this.getVitallAccess_code+'&dr_Id='+this.doc_id+'&acc_email='+this.dmail+'&auth_token='+this.token;
        this.vitallurl = this.dom.bypassSecurityTrustResourceUrl(geturl);
        this.loader = false;
      }
      if(this.getVitallAccess_status ==3) {
        this.getVitallMultiAccess = response.codes;
        this.loader = false;
      }
    });
  }
  checkpass(getPass){
    this.passChooseStatus =true;
   this.getVitallAccess_code = getPass;
  }

  procced(){
    this.loader = true;
    this.multiaccesscall_status = true;
    let geturl = 'https://ivital.co/route/#/home?pass='+this.getVitallAccess_code+'&dr_Id='+this.doc_id+'&acc_email='+this.dmail+'&auth_token'+this.token;
    this.vitallurl = this.dom.bypassSecurityTrustResourceUrl(geturl);
    this.loader = false;
  }

  goToAppointment(){
    this.router.navigate(['epicare/appointment'])
  }


}
