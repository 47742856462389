
  <div class="d-flex" id="wrapper">
      <!-- Top bar -->
      <div id="page-content-wrapper">
        <nav class="navbar navbar-expand-sm navbar-light border-bottom" id="navbar">
         
          <div class="collapse navbar-collapse" id="navbarSupportedContent">

            <div class="row topbanner">
                <div class="headerTxt">
                    Patients
                </div>
             </div>
         </div>
        </nav>
     </div>
  </div>

 <!-- body -->
    <div class="row">
      <div class="bodycontent">
        <app-patientslist></app-patientslist>
      </div>
    </div>
    
    
  
  