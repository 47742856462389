<div class="row">
    <div class="col-md-12">
        <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
    </div>
    <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
        <table class="table table-bordered">
            <thead style="background-color: #adccff">
                <tr>
                    <th class="text-center" style="width: 40px;"><input type="checkbox" [(ngModel)]="masterSelected1" name="list_name1" value="m2" (change)="checkUncheckAll1()"></th>
                    <th class="text-center" style="width: 85px;">S.NO</th>
                    <th class="text-center" style="width: 85px;">Date of Change</th>
                    <th class="text-center" style="width: 85px;">Risk changed from</th>
                    <th class="text-center" style="width: 85px;">Risk changed to</th>
                </tr>
            </thead>
            <tbody  *ngIf="emptyriskdetail == false">
                <tr style="border: rgb(0, 0, 0);" *ngFor="let data of allcurrentpregDetails; let i=index;">
                    <td><input type="checkbox" [(ngModel)]="data.isSelected" name="list_name1" (change)="isAllSelected1()"></td>
                    <td>{{i+1}}</td>
                    <td>{{data.date}}</td>
                    <td>{{data.risk_from}}</td>
                    <td>{{data.risk_to}}</td>
                </tr>
            </tbody>
        </table>
        <div class="row" *ngIf="emptyriskdetail == false">
            <div class="col-lg-12">
                <span class="obsgynecaddview" style="float: right;font-weight: bold;" (click)="archivecpRisk()"><img style="cursor: pointer;height: 10px; width: 17px;"
                    src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp; &nbsp; &nbsp;</span>
            </div>
        </div>
        <div class="row" *ngIf="emptyriskdetail == true">
 <div class="col-lg-12">
    <div class=" text-center"  style="color: red;">No data found</div>
 </div>
        </div>
    </div>
</div>

<style>
    .overflownotes {
    height: 135px;
    display: inline-block;
    width: 370px;
    overflow: auto;
}
</style>