<div class="bodycontainer">
    <div class="row" style="margin-top: 2%;">
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 marTop">
           <div style="margin:10px;"><span style="padding:5px;cursor:pointer" (click)="back()"><mat-icon>arrow_back</mat-icon> </span> <span  style="margin-left:25px;font-size: 22px;font-weight: bold;color: #00A0E3">Activity Reports</span></div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6" style="margin-top:10px;">
            <!-- <mat-form-field appearance="fill" >
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="minDate" [max]="maxDate">
                <input matStartDate formControlName="start" placeholder="Start date"  #dateRangeStart>
                <input matEndDate formControlName="end" placeholder="End date" #dateRangeEnd
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                
            </mat-form-field> -->
            <div style="display:flex">
                <span style="margin-top: 3px;">Date :</span>
                <input style="margin-left: 10px;" type="text"
                ngxDaterangepickerMd
                [locale]="{format: 'DD-MM-YYYY'}"
                [(ngModel)]="selected"
                [maxDate]='maxDate'
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [isInvalidDate] = "isInvalidDate"
                [showClearButton]="true"
                (rangeClicked)="rangeClicked($event)"
                (datesUpdated)="datesUpdated($event)"
                placeholder="Select Date"/>
            </div>   
        </div>
    </div>
    <div class="row" style="margin-top: 5%;">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 marTop">
            <!-- <div id="barChart" style="min-width: 310px; max-width: 800px; height: 400px; margin: 0 auto"></div> -->
            <highcharts-chart
                [Highcharts] = "highcharts" 
                [options] = "chartOptions" 
                style = "width: 100%; height: 400px; display: block;">
            </highcharts-chart>
        </div>
    </div>
</div>


