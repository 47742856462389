import { Component, OnInit, ViewChild,   ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import {MatCalendar, MatDatepickerModule} from '@angular/material/datepicker';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

export interface PatientlistElement {
  image: any;
  name: string;
  mobile: number;
  mrno: string;
  recordNum: any;
  gender: string;
  dob: any;
  age:any;
  
}
let ELEMENT_DATA: PatientlistElement[] = [];

@Component({
  selector: 'app-patientslist',
  templateUrl: './patientslist.component.html',
  styleUrls: ['./patientslist.component.scss']
})
export class PatientslistComponent implements OnInit {

  dataSource = new MatTableDataSource<PatientlistElement>(ELEMENT_DATA);
  displayedColumns: string[] = ['name','mrno','dob','age','gender','mobile'];
  urlPoints:any;
  month:any;
  searchparam;
  searchOption:any = 0;
  patient_Count = 0;
  imageUploadUrl;
  imgURL:any;
  filterName = '';
 dmail;doc_id;token;dr_recordID;
 pageSizeOptions: any = [5, 10, 25];
 pageNumber: any =0;
 pagelist = [];
 listcount = 0;
 count;
 pageOfItems:any;
 pagination = true;
 exampleHeader = ExampleHeader;
 todayDate;
 todayDate1;


 @ViewChild(MatPaginator) paginator: MatPaginator;
  maxDate;

  constructor(private appService: AppService,private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
   }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.dr_recordID = getauthdetails.docRecord_id;
    this.token = getauthdetails.token;
    this.pageOfItems = 1;
    this.patientCount();
    var currentDate = new Date()
    this.maxDate = currentDate;
  }


patientCount(){

  let countparams = this.dmail;

  this.appService.getRestobj(this.urlPoints.patientlistCount, countparams,0, this.token).subscribe((response: any) => {
    this.patient_Count = response.patient_count;
    this.count = Math.ceil(response.patient_count / 20);
  });
}

searchChange(searchvalue){
  this.searchOption = searchvalue;
  this.filterName = '';
  this.pagination = false;
  if(this.searchOption == '0'){
    this.patientCount();
    this.pagination = true;
  }
}

  searchPatient(filterValue: string) {
    this.pageOfItems = 1;
    if(this.searchOption!='4'){
    filterValue = filterValue.trim();
    } // Remove whitespace
    //filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    console.log('filter',filterValue);

    this.searchparam = {
      account_email: this.dmail,
    }

    if(this.searchOption == '1'){
      if(filterValue != "")
      this.searchparam["full_name"] = filterValue;
    }
    else if(this.searchOption == '2'){
      if(filterValue != "")
     this.searchparam["mr_number"] = 'MR'+filterValue;

    }else if(this.searchOption == '3'){
      if(filterValue != "")
      this.searchparam["contact_no"] = filterValue;
    }else if(this.searchOption == '4'){
      if(filterValue != '')
      this.searchparam["bday"] = moment(filterValue).format('YYYY-MM-DD')
    }

    console.log('param',this.searchparam);

    this.appService.postData(this.urlPoints.search, this.searchparam, this.token).subscribe((response: any) => {
      ELEMENT_DATA = [];
      this.listcount = response.length;
      for(var i=0;i<response.length;i++) {

        var b = moment(response[i].bday).format("YYYY-MM-DD")
        var a = moment();
        var age = moment.duration(a.diff(b));
        var years = age.years();
        var months = age.months();
        
        ELEMENT_DATA.push({
          image: response[i].image,
          name: response[i].name_given,
           mobile: response[i].father_tel_number, 
           mrno: response[i].mr_number,
           recordNum: response[i].record_id,
           gender: response[i].gender,
           dob: moment(response[i].bday).format("DD-MMM-YYYY"),
           age: years + " years " + months + " months"
        });
      }
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    ELEMENT_DATA = [];
    this.pageOfItems = pageOfItems;
    let params = this.dr_recordID;
   //let pagecount = (this.pageOfItems - 1) * 20;
  
    this.appService.getRestobj(this.urlPoints.latestpatientlist, params,((this.pageOfItems - 1) * 20), this.token).subscribe((response: any) => {
      if (response) {
        this.listcount = response.length;
        for(var i=0;i<response.length;i++) {

        var b = moment(response[i].bday).format("YYYY-MM-DD")
        var a = moment();
        var age = moment.duration(a.diff(b));
        var years = age.years();
        var months = age.months();

        ELEMENT_DATA.push({
            image: response[i].image,
            name: response[i].name_given,
             mobile: response[i].father_tel_number, 
             mrno: response[i].mr_number,
             recordNum: response[i].record_id,
             gender: response[i].gender,
             dob: moment(response[i].bday).format("DD-MMM-YYYY"),
             age: years + " years " + months + " months"
          });
        }
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
       
      }
    }
    )
  }

gotoapptqueue(selectedpatient){
  this.router.navigate(['epicare/appointment', selectedpatient.recordNum]);
}
hasNumber(myString) {
  return /\d/.test(myString);
}
isNumeric(num) {
  return !isNaN(num)
}
isNumberKey(evt){
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
  return true;
}    

onDateChange(e: HTMLInputElement){
  this.todayDate = moment(e.value).format('YYYY-MM-DD');
  console.log(this.todayDate,'todaydate')
  this.todayDate1 = moment(e.value).format('DD-MMM-yyyy');
}
}

@Component({
  selector: 'example-header',
  styleUrls: ['./calenderHeader.scss'],
  templateUrl: './calenderHeader.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  picon = 'keyboard_double_arrow_left'
  picon1 = 'keyboard_arrow_left'
  picon2 = 'keyboard_arrow_right'
  picon3 = 'keyboard_double_arrow_right'

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}