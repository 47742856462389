import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-iap-bmi-chart-female-small',
  templateUrl: './iap-bmi-chart-female-small.component.html',
  styleUrls: ['./iap-bmi-chart-female-small.component.scss']
})
export class IapBmiChartFemaleSmallComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }
  @Input() chartData;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
 
    this.drawChart(this.chartData)
  }

  drawChart(data) {
    var vitalData: any = data

    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 250 - margin.top - margin.bottom;
    var width = 370 - margin.left - margin.right;

    var bmiData = [];

    if (vitalData.bmi) {
      for (var i = 0; i < vitalData.bmi.length; i++) {
        if (vitalData.bmi[i].x > 59 && vitalData.bmi[i].x < 217) {
          bmiData.push(vitalData.bmi[i]);
        }
      }
    }

    var set1 = [{ x: 60, y: 11.9 }, { x: 66, y: 11.9 }, { x: 72, y: 12 }, { x: 78, y: 12.1 }, { x: 84, y: 12.1 }, { x: 90, y: 12.2 }, { x: 96, y: 12.3 }, { x: 102, y: 12.3 },
    { x: 108, y: 12.4 }, { x: 114, y: 12.5 }, { x: 120, y: 12.7 }, { x: 126, y: 12.8 }, { x: 132, y: 13 }, { x: 138, y: 13.2 },
    { x: 144, y: 13.4 }, { x: 150, y: 13.7 }, { x: 156, y: 13.9 }, { x: 162, y: 14.1 }, { x: 168, y: 14.3 }, { x: 174, y: 14.5 },
    { x: 180, y: 14.7 }, { x: 186, y: 14.9 }, { x: 192, y: 15 }, { x: 198, y: 15.2 }, { x: 204, y: 15.4 }, { x: 210, y: 15.5 }, { x: 216, y: 15.7 }];

    var set2 = [{ x: 60, y: 12.1 }, { x: 66, y: 12.2 }, { x: 72, y: 12.2 }, { x: 78, y: 12.3 }, { x: 84, y: 12.4 }, { x: 90, y: 12.5 }, { x: 96, y: 12.6 }, { x: 102, y: 12.7 },
    { x: 108, y: 12.8 }, { x: 114, y: 12.9 }, { x: 120, y: 13.1 }, { x: 126, y: 13.2 }, { x: 132, y: 13.4 }, { x: 138, y: 13.7 },
    { x: 144, y: 13.9 }, { x: 150, y: 14.2 }, { x: 156, y: 14.4 }, { x: 162, y: 14.6 }, { x: 168, y: 14.9 }, { x: 174, y: 15.1 },
    { x: 180, y: 15.2 }, { x: 186, y: 15.4 }, { x: 192, y: 15.6 }, { x: 198, y: 15.8 }, { x: 204, y: 16 }, { x: 210, y: 16.1 }, { x: 216, y: 16.3 }];

    var set3 = [{ x: 60, y: 12.5 }, { x: 66, y: 12.6 }, { x: 72, y: 12.7 }, { x: 78, y: 12.8 }, { x: 84, y: 12.8 }, { x: 90, y: 12.9 }, { x: 96, y: 13.1 }, { x: 102, y: 13.2 },
    { x: 108, y: 13.3 }, { x: 114, y: 13.5 }, { x: 120, y: 13.7 }, { x: 126, y: 13.9 }, { x: 132, y: 14.1 }, { x: 138, y: 14.4 },
    { x: 144, y: 14.7 }, { x: 150, y: 15 }, { x: 156, y: 15.2 }, { x: 162, y: 15.5 }, { x: 168, y: 15.7 }, { x: 174, y: 16 },
    { x: 180, y: 16.1 }, { x: 186, y: 16.3 }, { x: 192, y: 16.5 }, { x: 198, y: 16.7 }, { x: 204, y: 16.9 }, { x: 210, y: 17.1 }, { x: 216, y: 17.3 }];

    var set4 = [{ x: 60, y: 13.3 }, { x: 66, y: 13.4 }, { x: 72, y: 13.5 }, { x: 78, y: 13.6 }, { x: 84, y: 13.7 }, { x: 90, y: 13.9 }, { x: 96, y: 14 }, { x: 102, y: 14.2 },
    { x: 108, y: 14.4 }, { x: 114, y: 14.6 }, { x: 120, y: 14.9 }, { x: 126, y: 15.2 }, { x: 132, y: 15.5 }, { x: 138, y: 15.8 },
    { x: 144, y: 16.1 }, { x: 150, y: 16.5 }, { x: 156, y: 16.8 }, { x: 162, y: 17.1 }, { x: 168, y: 17.3 }, { x: 174, y: 17.6 },
    { x: 180, y: 17.8 }, { x: 186, y: 18 }, { x: 192, y: 18.2 }, { x: 198, y: 18.4 }, { x: 204, y: 18.6 }, { x: 210, y: 18.7 }, { x: 216, y: 18.9 }];

    var set5 = [{ x: 60, y: 14.3 }, { x: 66, y: 14.4 }, { x: 72, y: 14.5 }, { x: 78, y: 14.7 }, { x: 84, y: 14.9 }, { x: 90, y: 15.1 }, { x: 96, y: 15.3 }, { x: 102, y: 15.6 },
    { x: 108, y: 15.8 }, { x: 114, y: 16.1 }, { x: 120, y: 16.5 }, { x: 126, y: 16.8 }, { x: 132, y: 17.2 }, { x: 138, y: 17.6 },
    { x: 144, y: 18 }, { x: 150, y: 18.4 }, { x: 156, y: 18.8 }, { x: 162, y: 19.1 }, { x: 168, y: 19.4 }, { x: 174, y: 19.7 },
    { x: 180, y: 19.9 }, { x: 186, y: 20.1 }, { x: 192, y: 20.3 }, { x: 198, y: 20.4 }, { x: 204, y: 20.6 }, { x: 210, y: 20.8 }, { x: 216, y: 21 }];

    var set6 = [{ x: 60, y: 15.5 }, { x: 66, y: 15.7 }, { x: 72, y: 15.9 }, { x: 78, y: 16.1 }, { x: 84, y: 16.4 }, { x: 90, y: 16.6 }, { x: 96, y: 16.9 }, { x: 102, y: 17.2 },
    { x: 108, y: 17.6 }, { x: 114, y: 18 }, { x: 120, y: 18.4 }, { x: 126, y: 18.8 }, { x: 132, y: 19.3 }, { x: 138, y: 19.8 },
    { x: 144, y: 20.2 }, { x: 150, y: 20.7 }, { x: 156, y: 21.1 }, { x: 162, y: 21.5 }, { x: 168, y: 21.8 }, { x: 174, y: 22 },
    { x: 180, y: 22.3 }, { x: 186, y: 22.4 }, { x: 192, y: 22.6 }, { x: 198, y: 22.8 }, { x: 204, y: 22.9 }, { x: 210, y: 23.1 }, { x: 216, y: 23.2 }];

    var set7 = [{ x: 60, y: 18 }, { x: 66, y: 18.3 }, { x: 72, y: 18.6 }, { x: 78, y: 18.9 }, { x: 84, y: 19.3 }, { x: 90, y: 19.7 }, { x: 96, y: 20.1 }, { x: 102, y: 20.5 },
    { x: 108, y: 21 }, { x: 114, y: 21.4 }, { x: 120, y: 21.9 }, { x: 126, y: 22.5 }, { x: 132, y: 23 }, { x: 138, y: 23.6 },
    { x: 144, y: 24.1 }, { x: 150, y: 24.7 }, { x: 156, y: 25.2 }, { x: 162, y: 25.6 }, { x: 168, y: 25.9 }, { x: 174, y: 26.2 },
    { x: 180, y: 26.3 }, { x: 186, y: 26.4 }, { x: 192, y: 26.5 }, { x: 198, y: 26.6 }, { x: 204, y: 26.7 }, { x: 210, y: 26.7 }, { x: 216, y: 26.8 }];

    var rawSvg = this.elementRef.nativeElement.querySelector('svg');

    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    var wrap = d3.select(rawSvg).selectAll('d.d3line').data([bmiData]);
    var gEnter = wrap.enter().append('g').attr('class', 'd3line').append('g');

    gEnter.append('g').attr('class', 'lines');
    gEnter.append('g').attr('class', 'point-clips');
    gEnter.append('g').attr('class', 'point-paths');

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveBundle.beta(.8));


    var datalineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xScale0 = d3.scaleLinear().domain([216, 60]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([216, 60]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([10, 35]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([10, 35]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return d / 12 % 1 ? '' : (d / 12) as any;
    }).tickSizeInner(-height).tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-height).tickSizeOuter(0);
    var yAxisLeft = d3.axisLeft(yScale1).ticks(26).tickValues(d3.range(10, 35, 2)).tickFormat(function (d: any) {
      return d % 2 == 0 ? d : '';
    }).tickSizeInner(-width).tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(26).tickValues(d3.range(10, 35, 2)).tickFormat(function (d: any) {
      return '';
    }).tickSizeInner(-width).tickSizeOuter(0);

    var age = function (data) {
      var mon = data / 12;
      var result = mon.toFixed(1).split('.');
      var mod = data % 12;
      var res = mod.toString().split('.');
      return result[0].toString() + " years ";
    };

    svg.selectAll("circle")
      .data(bmiData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'green').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString()});

      // .on("mouseover", function (event, d) {
      //   div.transition()
      //     .duration(200)
      //     .style("opacity", .9);
      //   div.html(age(d.x) + "<br/>" + (d.y).toString())
      //     .style("left", (event.pageX) + "px")
      //     .style("top", (event.pageY - 28) + "px");
      // })
      // .on("mouseout", function (d) {
      //   div.transition()
      //     .duration(500)
      //     .style("opacity", 0);
      // });

    // x-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 30)
      .style("text-anchor", "middle")
      .text("Age in Years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -7)
      .style("text-anchor", "middle")
      .text("IAP Girls BMI Chart 5 - 18 years");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 735)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("3");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 710)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("5");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 680)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("10");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 615)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("25");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 540)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("50");

    // y-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);

    // path lines
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set1),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set1)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "gray")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set2),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set2)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "black")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set3),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set3)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "black")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set4),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set4)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "black")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set5),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set5)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "black")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set6),
    //     "stroke": "orange",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set6)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "orange")
    .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set7),
    //     "stroke": "red",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(set7)
    .attr("class", "path")
    .attr("d", lineFunc as any)
    .style("fill", "none")
    .style("stroke", "red")
    .style("stroke-width", "2");


    // user data
    // svg.append("svg:path")
    //   .attr({
    //     d: datalineFunc(bmiData),
    //     "stroke": "green",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   });

    svg.append("path")
    .datum(bmiData)
    .attr("class", "path")
    .attr("d", datalineFunc as any)
    .style("fill", "none")
    .style("stroke", "green")
    .style("stroke-width", "2");


  }
}
