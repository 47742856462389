<div>
    <p style="font-size: 1.2rem;">Choose display type</p>

    <div>
        <input style="cursor:pointer" class="radio" type="radio" id="drug"
            name="drug" (change)="radioChange(1)" [checked]="type==1" value="1">
        <label for="drug">Drug Name</label><br>
    </div>

    <div>
        <input style="cursor:pointer" class="radio" type="radio" id="generic"
            name="generic" (change)="radioChange(2)"  [checked]="type==2" value="2">
        <label for="generic">Generic Name</label><br>
    </div>

    <div>
        <input style="cursor:pointer" class="radio" type="radio" id="both"
            name="both" (change)="radioChange(3)" value="3"  [checked]="type==3" >
        <label for="both">Generic Name & Drug Name</label><br>
    </div>
</div>


<div>
    <mat-checkbox [(ngModel)]="durationAlone"><span style="font-size: 1rem;" class="text">Include number of days in prescription</span> </mat-checkbox>
</div>

<!-- <div>
    <mat-checkbox [(ngModel)]="fullFreq"><span style="font-size: 1rem;" class="text">Display time fully</span> </mat-checkbox>
</div> -->


<div>
    <mat-checkbox [(ngModel)]="showUnits"><span style="font-size: 1rem;" class="text">Add Unit of Measure to dosage</span> </mat-checkbox>
</div>

<div>
    <p>Note: Changes will only reflect in prescription</p>
</div>

<div style="display: flex;justify-content: center;">
    <button class="btn btn-primary" (click)="close()">OK</button>
    <button class="btn btn-primary" (click)="onNoClick()" style="margin-left: 0.5rem;">Close</button>

</div>
