

<div class="d-flex" id="wrapper">
  <!-- Left Sidebar -->
  
  <div id="page-content-wrapper">
    <nav class="navbar navbar-expand-lg navbar-light border-bottom">
     
      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <h2 style="margin-top:10px">Profile</h2>
        
      </div>
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li class="nav-item active" style="margin-right: 1rem;">
          <button class="btn btn-primary" id="menu-toggle" (click)="logout()">Logout</button>
        </li>
      </ul>
    </nav>
      <!-- /#footer -->
  </div>
</div>


<div class="bodycontainer">
  
  <div class="row">

    <!-- <div class="col-md-4">
      
    </div> -->

    <div class="col-md-8">
      <p style="font-size: 24px;"><b>{{first_Name}}</b> <span style="font-size: 12px;margin-left: 3px;">{{dr_degree}}</span></p>
      <p>Last Login {{last_login}}</p>
    </div>
    
    <div class="col-md-4">
<!-- hide doctor wallet -->
      <!-- <img style="cursor:pointer" *ngIf="!isShowfloat" src="assets/leftmenu/noun_doctor.svg" (click)="flottoggle()">
      <img style="cursor:pointer" *ngIf="isShowfloat" src="assets/leftmenu/noun-doctor-green.svg" (click)="flottoggle()"> -->

      <button class="btn btn-primary" *ngIf="drPofileFormShow && updatebutShowstatus" id="menu-toggle1" (click)="drpofileUpdate();" style="margin-right: 5px;float: right;">Update</button>
      <button class="btn btn-primary" *ngIf="drPofileFormShow && updatebutShowstatus && !changePasswordScreen" id="menu-toggle" (click)="cancelEdit()" style="margin-right: 5px;float: right;">Cancel</button> 
      <button class="btn btn-primary" *ngIf="!drPofileFormShow && !changePasswordScreen" id="menu-toggle1" (click)="drpofileEdit();" style="margin-left: 5px;float: right;">Edit</button>
      <div id="menu-toggle" *ngIf="!changePasswordScreen && !drprofiletxt" (click)="changepasswordtoggle()" style="margin-top:8px;margin-right: 15px;float: right;color:#007bff;cursor:pointer"><i class="fa fa-key"></i> Change Password</div>
      <div id="menu-toggle" *ngIf="changePasswordScreen && drprofiletxt" (click)="changerprofiletoggle()" style="margin-top:8px;margin-right: 15px;float: right;color:#007bff;cursor:pointer"><i class="fa fa-user-md"></i> Doctor Profile</div>  
    </div>
  </div>

  <div *ngIf="changePasswordScreen && !drPofileFormShow" style="margin-top: 20px;">
    <div style="font-weight: 600;font-size: 16px;">Change password</div>
    <form [formGroup]="changepasswordForm" style="margin-top: 20px;">
      
      <div class="form-row">
        <div class="col-md-3"></div>
        <div class="form-group col-md-6">
          <label>Existing Password <span style="color:red">*</span></label>
          <div style="display:flex">
            <input type="password" id="expassword" placeholder="Existing Password" formControlName="existingpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted1 && k.existingpassword.errors }" />
            <div class="input-group-append" style="margin-left: 5px;">
              <span class="input-group-text">
                  <mat-icon style="cursor:pointer" (click)="toggle('expassword')">{{p_icon}}</mat-icon>
              </span>
            </div>
          </div>
          <div *ngIf="k.existingpassword.errors" class="invalid-feedback">
              <div *ngIf="k.existingpassword.errors.required">Existing Password is required</div>
          </div>
          
       </div>
       <div class="col-md-3"></div>
      </div>

      <div class="form-row">
        <div class="col-md-3"></div>
        <div class="form-group col-md-6">
          <label>New Password <span style="color:red">*</span></label>
          <div style="display:flex">
            <input type="password" id="newpassword" placeholder="New Password" formControlName="newpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted1 && k.newpassword.errors }" />
            <div class="input-group-append" style="margin-left: 5px;">
              <span class="input-group-text">
                  <mat-icon style="cursor:pointer" (click)="toggle('newpassword')">{{p_icon}}</mat-icon>
              </span>
            </div>
          </div>

          <div style="display: flex;color:red;margin-top: 5px;">
            <div *ngIf="k.newpassword.hasError('hasNumber')">Number required,</div>
            <div *ngIf="k.newpassword.hasError('haslowercase')" style="margin-left: 5px;">Lowercase required,</div>
           <div *ngIf="k.newpassword.hasError('hasuppercase')" style="margin-left: 5px;">Uppercase required,</div>
            <div *ngIf="k.newpassword.hasError('hasspecial')" style="margin-left: 5px;">Special char required,</div>
            <div *ngIf="k.newpassword.hasError('hasminchar')" style="margin-left: 5px;"> min 6 char required</div>
          </div>
          
          <div *ngIf="submitted1 && k.newpassword.errors" class="invalid-feedback">
              <div *ngIf="k.newpassword.errors.required">New Password is required</div>
          </div>
       </div>
       <div class="col-md-3"></div>
      </div>

      <div class="form-row">
        <div class="col-md-3"></div>
        <div class="form-group col-md-6">
          <label>Confirm Password <span style="color:red">*</span></label>
          <div style="display:flex">
          <input type="password" id="confirmpassword" placeholder="Confirm Password" formControlName="confirmpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted1 && k.confirmpassword.errors }" />
          <div class="input-group-append" style="margin-left: 5px;">
            <span class="input-group-text">
                <mat-icon style="cursor:pointer" (click)="toggle('confirmpassword')">{{p_icon}}</mat-icon>
            </span>
          </div>
        </div>
          <div *ngIf="submitted1 && k.confirmpassword.errors" class="invalid-feedback">
              <div *ngIf="k.confirmpassword.errors.required">Confirm Password is required</div>
          </div>
       </div>
       <div class="col-md-3"></div>
      </div>
      <div *ngIf="submitted1 && mismatchpass" style="text-align: center;margin-left: 200px;">
        <div class="col-md-6" style="color:red">New and Confirm Password doesn't match</div>
      </div>
    </form>
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <button class="btn btn-primary" id="menu-toggle1" (click)="changePassword();" style="margin-right: 105px;float: right;">Change</button>
      <button class="btn btn-primary" id="menu-toggle" (click)="cancelpassword()" style="margin-right: 5px;float: right;">Cancel</button> 
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="doctorProfileFormfield" *ngIf="!drPofileFormShow && !changePasswordScreen" style="margin-top: 25px;">
    <div class="row">
        <div class="col-md-5">
          <div class="imgcontainear" style="height:250px">
            <div style="width:100%;height: 240px; background-size: cover; border-radius: 2px;box-shadow: 0px 2px 10px 5px grey;" [ngStyle]="{'background-image': 'url('+ uploadCoverDrImage + ')'}"> 
                 <div style="top:80px;left:10px;position: relative;">
                      <!-- <img style="border:1px solid black;width:150px;height: 150px;border-radius: 100%;" src="./assets/images/avatar-default.png" /> -->
                      <img *ngIf="croppedImage != ''" style="box-shadow: 0px 0px 12px 2px grey;width:150px;height: 150px;border-radius: 100%;" [src]="croppedImage" />
                  </div>
              </div>
         </div>
          <div class="form-row" style="margin-top: 40px;">
            <div class="form-group col-md-12">
              <label>Name</label>
              <div>{{first_Name}}</div>
           </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Registration Number </label>
              <div>{{reg_No}}</div>
           </div>
          </div>

          <div class="form-row" style="margin-top: 60px;">
            <div class="form-group col-md-12">
              <label>Phone  Number</label>
              <div>{{phone_Num}}</div>
           </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Alternate Number</label>
              <div *ngIf="Alter_No!=''">{{Alter_No}}</div>
              <div *ngIf="Alter_No==''">-</div>
            </div>
          </div>
          <div class="form-row" style="margin-top: 60px;">
            <div class="form-group col-md-12">
              <label>Address</label>
              <div>{{_address}}</div>
           </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>City</label>
              <div *ngIf="_city!=''">{{_city}}</div>
              <div *ngIf="_city==''">-</div>
           </div>
          </div>
        </div>
      <!----->
        <div class="col-md-4">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Hospital Name</label>
                <div>{{hos_name}}</div>
            </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Biography</label>
                <div *ngIf="_biography!=''">{{_biography}}</div>
                <div *ngIf="_biography==''">-</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Summary</label>
                <div *ngIf="_summary!=''">{{_summary}}</div>
                <div *ngIf="_summary==''">-</div>
              </div>
            </div>
            <div class="form-row" style="margin-top: 62px;">
              <div class="form-group col-md-12">
                <label>Speciality </label>
                <div>{{_speciality}}</div>
             </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Degree </label>
                <div>{{_degree}}</div>
             </div>
            </div>
            <div class="form-row" style="margin-top: 68px;">
              <div class="form-group col-md-12">
                <label>Email </label>
                <div>{{_email}}</div>
             </div>
            </div>
            <div class="form-row" style="margin-top: 135px;">
              <div class="form-group col-md-12">
                <label>state</label>
                <div *ngIf="_state!=''">{{_state}}</div>
                <div *ngIf="_state==''">-</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Postal Code </label>
                <div>{{_code}}</div>
             </div>
            </div>
        </div>
        <!----->
        <div class="col-md-3">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Vision</label>
              <div *ngIf="_vision!=''">{{_vision}}</div>
              <div *ngIf="_vision==''">-</div>
             </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Mission</label>
              <div *ngIf="_mission!=''">{{_mission}}</div>
              <div *ngIf="_mission==''">-</div>
            </div>
          </div>
          
        </div>
    </div>
  </div>

  <form [formGroup]="doctorProfileForm" *ngIf="drPofileFormShow && !changePasswordScreen" style="margin-top: 25px;">
      <div class="row">
          <div class="col-md-5">
            <div class="imgcontainear" style="height:250px">
              <div class="imgcontainear" style="height:250px">
                <div style="width:100%;height: 240px; background-size: cover;border-radius: 2px;border-radius: 2px;box-shadow: 0px 2px 10px 5px grey;" [ngStyle]="{'background-image': 'url('+ uploadCoverDrImage + ')'}"> 
                      <div style="top:10px;position: relative;float: right; right: 10px;">
                          <input type="file" name="file" id="file" class="inputfile" accept="image/*" (change)="uploadCoverImage($event);"/>
                          <label for="file"><mat-icon>add_a_photo</mat-icon></label>
                      </div>
                      <div style="top:80px;position: relative;left:10px">
                          <img *ngIf="croppedImage != ''" style="box-shadow: 0px 0px 12px 2px grey;width:150px;height: 150px;border-radius: 100%;" [src]="croppedImage" />
                          <input type="file" name="cropimage" id="fileone" class="inputfile" accept="image/*" (change)="fileChangeEvent($event);" />
                          <label style="top:60px;left:-30px;position: relative;" for="fileone"><mat-icon>add_a_photo</mat-icon></label>
                      </div>
                  </div>
             </div>
            </div>
            <div class="form-row" style="margin-top: 45px;">
              <div class="form-group col-md-12">
                <label>Name <span style="color:red">*</span></label>
                <input type="text" onkeypress="return(event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);" 
                placeholder="First Name.Middle Name.Last Name" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">Name is required</div>
                </div>
             </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Registration Number <span style="color:red">*</span></label>
                <input type="number" 
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="8"
                placeholder="Registration No" formControlName="regNo" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.regNo.errors }" />
                <div *ngIf="submitted && f.regNo.errors" class="invalid-feedback">
                    <div *ngIf="f.regNo.errors.required">Reg No is required</div>
                </div>
             </div>
            </div>

            <div class="form-row" style="margin-top: 60px;">
              <div class="form-group col-md-12">
                <label>Phone  Number <span style="color:red">*</span></label>
                <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10" placeholder="Contact Number" formControlName="phoneNum" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNum.errors }" />
                <div *ngIf="submitted && f.phoneNum.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNum.errors.required">First Name is required</div>
                </div>
             </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Alternate Number</label>
                <input type="text" placeholder="Alternate Number" formControlName="AlterNo" class="form-control" />
              </div>
            </div>
            <div class="form-row" style="margin-top: 60px;">
              <div class="form-group col-md-12">
                <label>Address <span style="color:red">*</span></label>
                <input type="text" placeholder="Street and Area" formControlName="address" class="form-control" 
                (keypress)="omit_special_char($event)" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required"> Address is required</div>
                </div>
             </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>City</label>
                <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);"
                placeholder="City" formControlName="city" class="form-control"  />
              </div>
            </div>
          </div>
        <!----->
          <div class="col-md-4">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Hospital Name <span style="color:red">*</span></label>
                  <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);" 
                   placeholder="Hospital Name" formControlName="hospitalName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.hospitalName.errors }" />
                  <div *ngIf="submitted && f.hospitalName.errors" class="invalid-feedback">
                      <div *ngIf="f.hospitalName.errors.required">Hospital Name is required</div>
                  </div>
              </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Biography</label>
                  <input type="text" placeholder="Biography" formControlName="biography" class="form-control"  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Summary</label>
                  <input type="text" placeholder="Summary" formControlName="summary" class="form-control" />
                </div>
              </div>
              <div class="form-row" style="margin-top: 18px;">
                <div class="form-group col-md-12">
                  <label>Speciality <span style="color:red">*</span></label>
                  <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);" 
                   placeholder="Speciality" formControlName="speciality" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.speciality.errors }" />
                  <div *ngIf="submitted  && f.speciality.errors" class="invalid-feedback">
                      <div *ngIf="f.speciality.errors.required">Speciality is required</div>
                  </div>
               </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Degree <span style="color:red">*</span></label>
                  <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);" 
                   placeholder="Degree" formControlName="degree" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.degree.errors }" />
                  <div *ngIf="submitted && f.degree.errors" class="invalid-feedback">
                      <div *ngIf="f.degree.errors.required">Degree is required</div>
                  </div>
               </div>
              </div>
              <div class="form-row" style="margin-top: 60px;">
                <div class="form-group col-md-12">
                  <label>Email <span style="color:red">*</span></label>
                  <input type="text" placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
               </div>
              </div>
              <div class="form-row" style="margin-top: 152px;">
                <div class="form-group col-md-12">
                  <label>state</label>
                  <input type="text" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);"
                  placeholder="State" formControlName="state" class="form-control" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Postal Code <span style="color:red">*</span></label>
                  <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="10" placeholder="Code" formControlName="code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />
                  <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                      <div *ngIf="f.code.errors.required">Postal code is required</div>
                  </div>
               </div>
              </div>
          </div>
          <!----->
          <div class="col-md-3">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Vision</label>
                <input type="text" placeholder="Vision" formControlName="vision" class="form-control"  />
               </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Mission</label>
                <input type="text" placeholder="Mission" formControlName="mission" class="form-control"  />
              </div>
            </div>
            
          </div>
      </div>
  </form>
  
</div>
<br>