import { Component, OnInit } from '@angular/core';
import {JsonPipe} from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Toast, ToastrService } from 'ngx-toastr';
import {FormGroup, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppService } from '../app.service';
import { AppConfig } from 'src/app/app.config';
@Component({
  selector: 'app-gynecrepot',
  templateUrl: './gynecrepot.component.html',
  styleUrls: ['./gynecrepot.component.scss']
})
export class GynecrepotComponent implements OnInit {
  endDate
  startDate;
  urlPoints
  token;
  ivrSend = false;
  dMail;
  report=[];
  constructor(public dialogRef:MatDialogRef<GynecrepotComponent>,private datePipe: DatePipe,private appService: AppService,private toastr: ToastrService) { }

  private formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy/MM/dd');
  }
  ngOnInit(): void {
    this.urlPoints = AppConfig.settings.EndPoints;
    const authDetails = JSON.parse(sessionStorage.getItem('authDetails'));
    this.token = authDetails.token;
    this.dMail = authDetails.account_id
    console.log("this.token",this.token)
    console.log("this.token",authDetails)
  }
  startDateChange(event: MatDatepickerInputEvent<Date>){
    const startDate = event.value;
    console.log("this.startDate::",this.formatDate(this.startDate))
    this.startDate = this.formatDate(startDate);
    this.getGynecReport();
  }
  endDateChange(event: MatDatepickerInputEvent<Date>){
    const endDate = event.value;
    console.log("this.endDate::",this.formatDate(this.endDate));
    this.endDate = this.formatDate(endDate)
    this.getGynecReport();
  }

  getGynecReport(){
   
    this.report = [];
    if(this.startDate && this.endDate && this.startDate < this.endDate){
    var param={
      account_email:this.dMail,
      from_date:this.startDate,
      to_date:this.endDate
    }
    this.appService.getData(this.urlPoints.getgynecengagereport, param, this.token).subscribe((response: any) => {
      console.log("getgynecengagereport",response)
this.report = response.message;
    });
  }else{
    // this.toastr.error('End date cannot be less than start date')
    this.toastr.error('Invalid date range!')
  }
  }

  getIdx(e){
console.log("e::: r",this.report[e]);
const getIndexData = this.report[e]
var params ={
  account_email:this.dMail,
  "content": getIndexData.content,
  "due_date": getIndexData.due_date,
  "engagement_type": getIndexData.engagement_type,
  "id": getIndexData.id,
  "is_activate": getIndexData.true,
  "lmp_date": getIndexData.lmp_date,
  "mr_number": getIndexData.mr_number,
  "name": getIndexData.name,
  "ph_number": getIndexData.ph_number,
  "plan_day":getIndexData.plan_day
}
this.appService.postData(this.urlPoints.sendgynecivrcall, params, this.token).subscribe((response: any) => {
console.log("response::",response)
if(response.success == 1){
  this.ivrSend = true;
  // this.toastr.success("IVR call has been sent successfully");
  this.toastr.success(response.message);
}else{
  this.toastr.error(response.message);
  // this.toastr.error("Oops! The IVR call failed to send");
}

  })
}
sendIdx(e){
  this.toastr.success("IVR call already initiated");
}
closeTab(){
  this.dialogRef.close();
}
}
