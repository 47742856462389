import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import {FormGroup, FormControl} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bookappointment',
  templateUrl: './bookappointment.component.html',
  styleUrls: ['./bookappointment.component.scss']
})
export class BookappointmentComponent implements OnInit {

  date;
  urlPoints;
  dmail;
  token;
  doc_id;
  searchTxt;
  loader = false;
  list = [];
  apptType:any = 0;
  followup_date;
  searchpatient = true;
  selectedpatientDetails = false;
  bookingSlots = false;
  bookingConfirmation = false;

  patientName;
  patientEmail;
  patImage;
  mrno;
  mobNum;
  gender;

  minDate;
  maxDate;
  TodayDate;
  

  slotArray = [];
  slots;
  selectedTokens = [];
  slots_arr=[];
  time;
  deduct = false;
  disabled=false;
  subStatus;
  changedate;
  followStatus = false;
  followupstatus = false;
  follow_date_display;

  type;
  state = 1;
  apptTokens;
  doctor_charge;
  imageUploadUrl;
  @Output() someEvent_close = new EventEmitter<Boolean>();
  TodayDateUI: string;

 
  
  constructor(private appService: AppService,private route: ActivatedRoute,private toastr: ToastrService, private router: Router,private elRef: ElementRef) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
   }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    var maxCurrentDate = new Date();
    this.maxDate = maxCurrentDate;

    this.TodayDate = moment(this.maxDate).format('ddd Do MMM, YYYY');
    this.TodayDateUI = moment(this.maxDate).format('DD-MMM-YYYY')
    var minCurrentDate = new Date();
    var maxNewDate = new Date();
    this.minDate = minCurrentDate;
    
    this.type = this.apptType;
  
  }

  search() {
    this.list = [];
    this.loader = true;
    var type;
    var mr;
    var regex = /\d/;
    mr = (this.searchTxt.toLowerCase()).startsWith('mr');
    var params;

    if (mr && this.hasNumber(this.searchTxt)) {
      type = 'MR';

      params = {
        mr_number: this.searchTxt.toUpperCase(),
        account_email: this.dmail
      }

    }

    else if (this.isNumeric(this.searchTxt) && this.searchTxt.length == 10) {
      type = 'phone'

      params = {
        contact_no: this.searchTxt,
        account_email: this.dmail
      }
    }

    else if (!this.hasNumber(this.searchTxt)) {
      type = 'name';

      params = {
        full_name: this.searchTxt,
        account_email: this.dmail
      }

    }

    this.appService.postData(this.urlPoints.search, params, this.token).subscribe((response: any) => {
      this.list = response;
      this.loader = false;
    },
      error => {
        this.list = [];
      })
  }

  isNumeric(num) {
    return !isNaN(num)
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }

  selectedPatient(patientDetails){
    
    this.searchpatient = false;
    this.selectedpatientDetails = true;
    this.bookingSlots = false;
    this.bookingConfirmation = false;

    this.patientName = patientDetails.name_given;
    this.mrno = patientDetails.mr_number;
    this.mobNum = patientDetails.father_tel_number;
    this.gender = patientDetails.gender;
    this.patientEmail = patientDetails.email;
    this.patImage = patientDetails.image;
    this.followup_date = patientDetails.follow_up_date;
    this.follow_date_display = moment(patientDetails.follow_up_date).format("DD/MM/YYYY");

}

gotoapptbook(){
  
  switch(this.apptType){
    case 0:
    this.type = "consultation";
    break;
  case 1:
    this.type = "vaccination";
    break;
  case 2:
    this.type = "voice_call";
    break;
    case 3:
    this.type = "video_call";
    break;
  default:
    this.type = "consultation";
  }
  
  this.TodayDate = moment(this.maxDate).format('ddd Do MMM, YYYY');
                  
  this.searchpatient = false;
  this.selectedpatientDetails = false;
  this.bookingSlots = true;
  this.bookingConfirmation = false;

  var apptparam = {
    account_email: this.dmail,
    appt_date: moment(this.maxDate).format("YYYY-MM-DD"),
    doctor_id: this.doc_id,
    appt_type: this.type
  }

  this.appService.getData(this.urlPoints.checkappointmenttoken, apptparam, this.token).subscribe((response: any) => {
    this.slots = response.schedule;
    
    for (var i = 0; i < this.slots.length; i++) {
      this.slots[i].selected = false
      if (moment(this.slots[i].start).add(5.5, 'hours').valueOf() < moment(new Date()).valueOf()) {
        this.slots[i].past = true
      }
      else {
        this.slots[i].past = false
      }
    }
    this.selectedTokens = []

      this.slotArray = this.listToMatrix(this.slots, 10)
  });

  this.appService.getData(this.urlPoints.checktokenavailability, apptparam, this.token).subscribe((response: any) => {

  });

}

listToMatrix(list, elementsPerSubArray) {
  var matrix = [], i, k;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
}

backapptselection() {
  this.searchpatient = true;
  this.selectedpatientDetails = false;
  this.bookingSlots = false;
  this.bookingConfirmation = false;
}
backslot(){
  this.searchpatient = false;
  this.selectedpatientDetails = true;
  this.bookingSlots = false;
  this.bookingConfirmation = false;
}

select(slot) {
  
if (this.selectedTokens.length == 0) {
    
    var index = this.slots.findIndex(s => s == slot);
    this.slots[index].selected = true;
    this.listToMatrix(this.slots, 10);
    this.selectedTokens.push(slot.token)
    var obj={
      slot_id:slot.id,
      appt_type:this.type
    }

    this.slots_arr.push(obj)
  }
  else if (this.selectedTokens.length == 1) {
    var index = this.slots.findIndex(s => s.token == this.selectedTokens[0]);

    var index1 = this.slots.findIndex(s => s == slot);
    if (this.slots[index1].selected) {
      this.slots[index1].selected = false;
      this.listToMatrix(this.slots, 10);
      this.selectedTokens.splice(0, 1)
    }
    else {
      if ((index - index1 == 1) || (index1 - index) == 1) {
        this.slots[index1].selected = true;
        this.selectedTokens.push(slot.token)

        var obj={
          slot_id:slot.id,
          appt_type:this.type
        }

        this.slots_arr.push(obj)
      }
      else {
        this.toastr.error("Please select consecutive tokens")
      }
    }
  }
  else if (this.selectedTokens.length == 2) {
    var index = this.slots.findIndex(s => s == slot);
    if (this.slots[index].selected) {
      this.slots[index].selected = false;

      var index1: any = this.selectedTokens.findIndex(s => s == slot.token);

      this.selectedTokens.splice(index1, 1)
    }
    else {

      this.toastr.error("Maximum limit reached")

    }
  }

  this.time = slot.start

}

next(){
  if (this.selectedTokens.length == 0) {
    this.toastr.error("Please select a token")
  }else {
    // this.searchpatient = false;
    // this.selectedpatientDetails = false;
    // this.bookingSlots = true;
    this.confirmBooking()
  //  this.bookingConfirmation = true;
  }
}

backconfirm(){
   this.searchpatient = false;
    this.selectedpatientDetails = false;
    this.bookingSlots = true;
    this.bookingConfirmation = false;
}

confirmBooking() {

  this.someEvent_close.next();
  
  if (moment(this.maxDate).format("YYYY-MM-DD") != moment(new Date()).format("YYYY-MM-DD")) {
    this.deduct = true;
  }
    var params2 = {
        token: this.selectedTokens,
        appt_datetime: this.time + 'Z',
        patient_account: this.patientEmail,
        appt_type: this.type,
        location: null,
        account_email: this.dmail,
        doctor_id: this.doc_id
      }

      this.disabled=true

      this.appService.postData(this.urlPoints.bookAppt, params2, this.token).subscribe((response: any) => {

        let apptidforRaiseInvoice = response[0].id;        

        

        this.toastr.success("Appointment Booked Successfully!");

        this.disabled = false
        
        var updateParams = {
          account_email: this.dmail,
          patient_email: this.patientEmail,
          doctor_id: this.doc_id,
          a_id: response[0].id,
          slot: this.time + 'Z',
          token: this.selectedTokens,
          mode: "EPICARE"
        }

        if (this.type == 'video_call')
          this.appService.postData(this.urlPoints.updateWebToken, updateParams, this.token).subscribe((response: any) => {
        });

          if(this.deduct) 
          {

            let drchargeparam = {
              account_email: this.dmail,
              doctor_id: this.doc_id,
            }
            
            this.appService.getData(this.urlPoints.getpaymentmaster,drchargeparam, this.token).subscribe((response: any) => {
              response.map(res => {
                  if (res.module == "appointment"){
                    this.doctor_charge = res.doctor_charge;
                  }
                });
             
                if(this.selectedTokens.length == 2) {
                  this.doctor_charge = this.doctor_charge * 2;
                } else if(this.selectedTokens.length == 1) {
                  this.doctor_charge = this.doctor_charge;
                }

            var raiseParams={
              account_email:this.dmail,
              patient_email:this.patientEmail,
              category:"appointment",
              description:"",
              doctor_charges: this.doctor_charge,
              user_type: "doctor",
              type:3,
              doctor_id: this.doc_id,
              appointment_id: apptidforRaiseInvoice,
              slots:this.slots_arr
            }

            this.appService.postData(this.urlPoints.raiseInvoice, raiseParams, this.token).subscribe((response: any) => {
              if(response){
                this.toastr.success("Payment raised Successfully! Patient has to pay "+response.amount+" for appointment booking")
              }
            });

          });
        }

        this.selectedTokens = [];
        for (var i = 0; i < this.slots.length; i++) {
          this.slots[i].selected = false
        }
      //  this.bookingcancel()
      //  this.listToMatrix(this.slots, 10);
      //  this.backslot();

      console.log('here')

      this.bookingcancel()

      },

        error => {

          if (error.status == 500)
            this.toastr.error("Slot already booked")
        })
}

followupdateChange(){
this.followStatus = true;
this.followupstatus = true;
}

nofollowup(){
  this.followStatus = false;
  this.followupstatus = false;
  var maxCurrentDate = new Date();
  this.maxDate = maxCurrentDate;
  this.TodayDate = moment(this.maxDate).format('ddd Do MMM, YYYY');
}

yesfollowup(){
  this.maxDate = this.followup_date;
  this.TodayDate = moment(this.maxDate).format('ddd Do MMM, YYYY');
  this.followStatus = false;
  this.followupstatus = true;
}

onDateChange(e: HTMLInputElement){
  this.followStatus = false;
  this.followupstatus = false;
  this.TodayDate = moment(e.value).format('ddd Do MMM, YYYY');
}

bookingcancel() {
  console.log('cancelled')
  this.backapptselection();
  this.searchTxt = '';
  this.list = [];
  this.selectedTokens = [];
  this.followStatus = false;
  this.followupstatus = false;
}

}

