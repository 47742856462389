import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrintService} from '../wrapper/services/print.service';

@Component({
  selector: 'app-cashreceipt',
  templateUrl: './cashreceipt.component.html',
  styleUrls: ['./cashreceipt.component.scss']
})
export class CashreceiptComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  
recepit: any;
date;
mrNum;
recepiNum;
invoiceNum;
amount;
patientName;
parentName;
aged;
drName;
drdegree;
drRegnum;
drfull;

  constructor(route: ActivatedRoute,
              private printService: PrintService) {}

  ngOnInit() {

  

    this.recepit = this.printService.getreceiptData();
    this.date = this.recepit.date;
    this.mrNum = this.recepit.mr_no;
    this.recepiNum = this.recepit.receipt_no;
    this.invoiceNum = this.recepit.invoice_no;
    this.amount = this.recepit.amount;
    this.patientName = this.recepit.patient_name;
    this.parentName = this.recepit.parent_name;
    this.aged = this.recepit.age;
    this.drName = this.recepit.doctor_name;
    this.drdegree = this.recepit.degree;
    this.drfull = this.drName + this.drdegree;
    this.drRegnum = this.recepit.reg_no;

   this.printService.onDataReady('abc','123')
     
    // Promise.all(this.recepit)
    // .then(() => this.printService.onDataReady('xyz','123'));
      
  }


}
