<!-- <h2>hjgbfhbshfchsv</h2> -->
<!-- header -->
<div class="container-fluid">
    <!-- <div class="row">
        <div class="col-lg-1" style="margin-top: 17px; margin-left: -15px;">
            <img class="menuiconAlign" src="assets/Icon material-arrow_back.svg">            
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-lg-3"></div>
        <div class="col-lg-4" style="font-size: 15px;font-weight: bold;margin-top: 17px;; text-align: center;">Engagement Messaging</div>
        <div class="col-lg-3"></div>
        <div class="col-lg-1"><button class="btn btn-primary" style="margin-top: 10px;" (click)="openDialog()">Reminder</button></div>
    class="btn btn-primary"<class="btn btn-primary"/div> -->

    <div class="row titleContainer">
        <div class="col-sm-2 col-md-2 col-lg-2 containerTop" style="margin-top: 15px;">
            <div><span style="cursor:pointer;" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
            <!-- <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div> -->
        </div>
        <div class="col-sm-8 col-md-8 col-lg-8 containerTop">
            <p class="headText" style="font-size: 15px;font-weight: bold;margin-top: 17px;; text-align: center;">Engagement Messaging    </p>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 containerTop">
            <div><button class="btn btn-primary" style="margin-top: 10px;" (click)="openDialog()">Reminder</button></div>        
        </div>
    </div>
<!-- </div>
body
<div class="container-fluid"> -->
    <div class="selection">
        <form [formGroup]="engagementMessaging" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-5" style="padding: 10px;" >
                    <div class="subcontainer1">
                        <div class="row" style="margin-top: 5px;margin-left: -15px;">
                            <div style="color: #656565;font-size: medium;"> Selection Criteria</div> 
                        </div>
                        <div class="row" style="margin-top: 20px;margin-left: -30px;">
                            <div class="col-lg-4">
                            <div style="color: #000000;font-size: 15px;font-weight: bold;"> Show Message</div>  
                            </div>
                            <!-- <div ></div> -->
                            <div class="col-lg-8">
                                <mat-radio-group
                                [color]="'primary'"
                                style="color: #617595;"
                                formControlName="showMessage">
                                    <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 10px;"> <span></span> Active</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" [value]="1" >Inactive</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 20px;margin-left: -30px;">
                            <div class="col-lg-4" >
                            <div style="color: #000000;font-size: 15px;font-weight: bold;"><label for="name">Name</label></div> 
                            </div>
                            <div class="col-lg-8">
                                <input style="width: 70%;" class="form-control" placeholder="eg. Harvy Specter" formControlName="name"  minlength="3" maxlength="20" pattern="[a-zA-Z ]*"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);"> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red-input" *ngIf="(engagementMessaging.controls.name.errors?.required) && (engagementMessaging.controls.name.touched || engagementMessaging.controls.name.dirty) && (engagementMessaging.controls.name.invalid)" style="margin-left: 15px;">Name is required</p>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 20px;margin-left: -30px;">
                            <div class="col-lg-4">
                                <div style="color: #000000;font-size: 15px;font-weight: bold;"> Target Users</div>  
                            </div>
                            <div class="col-lg-8">
                                <mat-radio-group
                                [color]="'primary'"
                                style="color: #617595;"
                                formControlName="targetUsers">
                                    <mat-radio-button class="example-radio-button" [value]="0" >Registered Patients</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" [value]="1" style="margin-left: 5px;">Providers (Field)</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" [value]="2" >Vendor</mat-radio-button>
                                <!-- </mat-radio-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <mat-radio-group> -->
                                    <mat-radio-button class="example-radio-button" [value]="3" style="margin-left: 89px;">Providers (Offline)</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" [value]="4" >Doctor</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" [value]="5" style="margin-left: 93px;">Helyxon</mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red-input" *ngIf="(engagementMessaging.controls.targetUsers.errors?.required) && (engagementMessaging.controls.targetUsers.touched || engagementMessaging.controls.targetUsers.dirty) && (engagementMessaging.controls.targetUsers.invalid) " style="margin-left: 15px;">TargetUsers is required</p>
                            </div>
                        </div> -->
                    </div>
                    <br> <br> <br>
                    <hr> 
                    <br> <br>
                    <div class="subcontainer2" style="margin-top: 30px;">
                        <div class="row" style="margin-top: 5px;margin-left: -15px;">
                            <div style="color: #656565;font-size: medium;"> Scheduling Options</div> 
                        </div>

                        <div class="row" style="margin-top: 15px;margin-left: -15px;">
                            <mat-radio-group
                            [color]="'primary'"
                            style="color: #617595;"
                            formControlName="scheduling">
                                <mat-radio-button class="example-radio-button" [value]="0" (change)="searchChange('0')" style="margin-right: 10px;">Trigger</mat-radio-button>
                                <mat-radio-button class="example-radio-button" [value]="1" (change)="searchChange('1')" style="margin-right: 10px;">Age</mat-radio-button>
                                <mat-radio-button class="example-radio-button" [value]="2" (change)="searchChange('2')" style="margin-right: 10px;">Calender</mat-radio-button>
                                <mat-radio-button class="example-radio-button" [value]="3" (change)="searchChange('3')" >Repeat Messaging</mat-radio-button>
                            </mat-radio-group>                            
                        </div>

                        <div class="row" *ngIf = "searchOption == '0'" style="margin-top: 20px;margin-left: -25px;">
                            <div class="col-lg-3">
                                <div style="color: #000000;font-size: 15px;font-weight: bold;">Trigger</div>
                            </div>
                            <div class="col-lg-9">
                                <!-- <mat-form-field appearance="fill">
                                    <mat-label> Select </mat-label>
                                    <mat-select>
                                      <mat-option *ngFor="let food of foods" [value]="food.value">
                                        {{food.viewValue}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field> -->
                                <select class="form-control" name="Type" style="font-size: 14px;"
                                formControlName="triggerType" aria-placeholder="Select" (change)="triggerChange()">
                                    <!-- <option value="" disabled selected hidden>Select</option> -->
                                    <option value="complaint">complaint</option>
                                    <option value="follow">Follow Up</option>
                                    <option value="device">Device Alarm</option>
                                </select>
                            </div>
                            <!-- <div class="col-lg-6" *ngIf = "triggerChangeVal == 'complaint'">
                                <mat-card>
                                    <button class="btn btn-primary">Head ache</button>
                                    <button class="btn btn-primary">Cold</button>
                                    <button class="btn btn-primary">Fever</button>
                                </mat-card>
                            </div> -->
                        </div>
                        <div class="row" *ngIf = "searchOption == '0'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.triggerType.errors?.required) && (engagementMessaging.controls.triggerType.touched || engagementMessaging.controls.triggerType.dirty) && (engagementMessaging.controls.triggerType.invalid)" style="margin-left: 15px;">Triggertype is required</p>
                            </div>
                        </div>

                        <div class="row" *ngIf = "triggerChangeVal == 'complaint'" style="margin-top: 10px;">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-9">                            
                                <mat-card>
                                    <button type="button" [ngClass]="{'green':changeComplaintVal == true,'blue':changeComplaintVal == false}" (click)="changeComplaint()">Head ache</button>
                                    <button type="button" [ngClass]="{'green':changeVal == true,'blue':changeVal == false}" (click)="changeComplaint1()" style="margin-left: 5px;">Cold</button>
                                    <button type="button" [ngClass]="{'green':changeComplaintValue == true,'blue':changeComplaintValue == false}" (click)="changeComplaint2()" style="margin-left: 5px;">Fever</button>
                                </mat-card>
                            </div>
                            <div class="col-lg-1"></div>
                        </div>

                        <div class="row" *ngIf = "searchOption == '1'" style="margin-top: 20px;margin-left: -30px;">
                            <div class="col-lg-4">
                                <div style="color: #000000;font-size: 15px;font-weight: bold;">Enter Your Age</div>
                            </div>
                            <div class="col-lg-8">
                                <div style="display: flex;">
                                    <div style="display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="year"><span style="margin-left: 5px;margin-top: 7px;">Years</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="month"><span style="margin-left: 5px;margin-top: 7px;">Months</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="day"><span style="margin-left: 5px;margin-top: 7px;">Days</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row" *ngIf = "searchOption == '1'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red-input" *ngIf="engagementMessaging.controls.month.errors?.required" style="margin-left: 15px;">Age is required</p>
                            </div>
                        </div> -->

                        <div class="row" *ngIf = "searchOption == '2'" style="margin-top: 20px;">
                            <div class="col-lg-3">
                                <div style="color: #000000;font-size: 15px;font-weight: bold;">Select a Date</div>
                            </div>
                            <div class="col-lg-9">
                                <select class="form-control" name="Type" style="font-size: 14px;"
                                formControlName="selectDay" aria-placeholder="Select">
                                    <!-- <option value="" disabled selected hidden>Select</option> -->
                                    <option value="diwali">Diwali</option>
                                    <option value="newYear">New Year</option>
                                    <option value="pongal">Pongal</option>
                                    <option value="ramzan">Ramzan</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" *ngIf = "searchOption == '2'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.selectDay.errors?.required) && (engagementMessaging.controls.selectDay.touched || engagementMessaging.controls.selectDay.dirty) && (engagementMessaging.controls.selectDay.invalid)" style="margin-left: 15px;">AgeFrom is required</p>
                            </div>
                        </div>

                        <div class="row" *ngIf = "searchOption == '3'" style="margin-top: 20px;">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div style="color: #000000;font-size: 15px;font-weight: bold;">Start Messaging On</div>
                                    </div>
                                    <div class="col-lg-5">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="picker">
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>                                  
                                    </div>
                                    <div class="col-lg-4">
                                        <select class="form-control" name="Type" style="font-size: 14px;"
                                        formControlName="selectStartDate" aria-placeholder="Select">
                                            <option value="">select</option>
                                            <option value="12 am">12 am</option>
                                            <option value="1 am">1 am</option>
                                            <option value="2 am">2 am</option>
                                            <option value="3 am">3 am</option>
                                            <option value="4 am">4 am</option>
                                            <option value="5 am">5 am</option>
                                            <option value="6 am">6 am</option>
                                            <option value="7 am">7 am</option>
                                            <option value="8 am">8 am</option>
                                            <option value="9 am">9 am</option>
                                            <option value="10 am">10 am</option>
                                            <option value="11 am">11 am</option>
                                            <option value="12 pm">12 pm</option>
                                            <option value="1 pm">1 pm</option>
                                            <option value="2 pm">2 pm</option>
                                            <option value="3 pm">3 pm</option>
                                            <option value="4 pm">4 pm</option>
                                            <option value="5 pm">5 pm</option>
                                            <option value="6 pm">6 pm</option>
                                            <option value="7 pm">7 pm</option>
                                            <option value="8 pm">8 pm</option>
                                            <option value="9 pm">9 pm</option>
                                            <option value="10 pm">10 pm</option>
                                            <option value="11 pm">11 pm</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-lg-3">
                                        <div style="color: #000000;font-size: 15px;font-weight: bold;">End Messaging On</div>
                                    </div>
                                    <div class="col-lg-5">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="picker1">
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>                                  
                                    </div> 
                                    <div class="col-lg-4">
                                        <select class="form-control" name="Type" style="font-size: 14px;"
                                        formControlName="selectEndDate" aria-placeholder="Select">
                                            <option value="">select</option>
                                            <option value="12 am">12 am</option>
                                            <option value="1 am">1 am</option>
                                            <option value="2 am">2 am</option>
                                            <option value="3 am">3 am</option>
                                            <option value="4 am">4 am</option>
                                            <option value="5 am">5 am</option>
                                            <option value="6 am">6 am</option>
                                            <option value="7 am">7 am</option>
                                            <option value="8 am">8 am</option>
                                            <option value="9 am">9 am</option>
                                            <option value="10 am">10 am</option>
                                            <option value="11 am">11 am</option>
                                            <option value="12 pm">12 pm</option>
                                            <option value="1 pm">1 pm</option>
                                            <option value="2 pm">2 pm</option>
                                            <option value="3 pm">3 pm</option>
                                            <option value="4 pm">4 pm</option>
                                            <option value="5 pm">5 pm</option>
                                            <option value="6 pm">6 pm</option>
                                            <option value="7 pm">7 pm</option>
                                            <option value="8 pm">8 pm</option>
                                            <option value="9 pm">9 pm</option>
                                            <option value="10 pm">10 pm</option>
                                            <option value="11 pm">11 pm</option>
                                        </select>
                                    </div>
                                </div>
                           
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-lg-3">
                                        <div style="color: #000000;font-size: 15px;font-weight: bold;">Frequency</div>
                                    </div>
                                    <div class="col-lg-4" >
                                        <!-- style="margin-left: 25px;" -->
                                        <select class="form-control" name="Type" style="font-size: 14px; "
                                        formControlName="selectFrequency" aria-placeholder="Select" (change)="frequencyChange()">
                                            <option value="">Select</option>
                                            <option value="once">Once</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                        <!-- </div>
                                        <div class="col-lg-4"> -->
                                            <!-- <select class="form-control" name="Type" style="font-size: 14px;"
                                            formControlName="selectDate" aria-placeholder="Select">
                                                <option value="monday">Monday</option>
                                                <option value="tuesday">Tuesday</option>
                                                <option value="wednesday">Wednesday</option>
                                                <option value="thursday">Thursday</option>
                                                <option value="friday">Friday</option>
                                                <option value="saturday">Saturday</option>
                                                <option value="sunday">Sunday</option>
                                            </select> -->
                                    </div>
                                    <div class="col-lg-5" *ngIf="frequencyChangeVal=='monthly'">
                                        <select class="form-control" formControlName="frequencyMonthWeek" style="font-size: 14px;">
                                            <option value="">Select</option>
                                            <option value="first">First</option>
                                            <option value="second">Second</option>
                                            <option value="third">Third</option>
                                            <option value="fourth">Fourth</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5" *ngIf="frequencyChangeVal !== 'monthly'">
                                        <select class="form-control" formControlName="FrequencyWeek" style="font-size: 14px; ">
                                            <option value="">select</option>
                                            <option value="monday">Monday</option>
                                            <option value="tuesday">Tuesday</option>
                                            <option value="wednesday">Wednesday</option>
                                            <option value="thursday">Thursday</option>
                                            <option value="friday">Friday</option>
                                            <option value="saturday">Saturday</option>
                                            <option value="sunday">Sunday</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row" *ngIf = "searchOption == '3'">
                                    <div class="col-lg-3"></div>
                                    <div class="col-lg-9">
                                        <p class="red" *ngIf="(engagementMessaging.controls.selectFrequency.errors?.required) && (engagementMessaging.controls.selectFrequency.touched || engagementMessaging.controls.selectFrequency.dirty) && (engagementMessaging.controls.selectFrequency.invalid)" style="margin-left: 15px;">Frequency is required</p>
                                    </div>
                                </div>
                                

                                <div class="row" style="margin-top: 10px;" *ngIf="frequencyChangeVal == 'monthly'">
                                    <div class="col-lg-3"></div>
                                    <div class="col-lg-5">
                                        <select class="form-control" formControlName="FrequencyWeek" style="font-size: 14px; ">
                                            <option value="">select</option>
                                            <option value="monday">Monday</option>
                                            <option value="tuesday">Tuesday</option>
                                            <option value="wednesday">Wednesday</option>
                                            <option value="thursday">Thursday</option>
                                            <option value="friday">Friday</option>
                                            <option value="saturday">Saturday</option>
                                            <option value="sunday">Sunday</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br> <br> <br>
                </div> 

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="vl"></div>
                </div>

                <!-- <br> <br> <br> <br> -->
                <!-- <hr> -->

                <div class="col-lg-6 col-md-6 col-sm-6" style="padding: 10px;">
                    <div class="subcontainer3">
                        <div class="row">
                            <!-- <div class="col-12"> -->
                                <div style="color: #656565;font-size: medium;margin-left: 15px;"> Selection Criteria</div>
                            <!-- </div>  -->
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;"> Gender</div>
                            </div>
                            <div class="col-lg-6">
                                <mat-radio-group
                                [color]="'primary'"
                                style="color: #617595;"
                                class="gender"
                                formControlName="gender">
                                    <mat-radio-button class="example-radio-button" value="male" style="margin-right: 10px;">Male</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" value="female" >Female</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;"> Age From</div>
                            </div>
                            <div class="col-lg-6">
                                <div style="display: flex;">
                                    <div style="display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="ageFromYears"><span style="margin-left: 2px;margin-top: 7px;">Years</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="ageFromMonths"><span style="margin-left: 2px;margin-top: 7px;">Months</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="ageFromDays"><span style="margin-left: 2px;margin-top: 7px;">Days</span></div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot1">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>
                        </div>
                        

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;"> Age To</div>
                            </div>
                            <div class="col-lg-6">
                                <div style="display: flex;">
                                    <div style="display: flex;"><input type="number" style="width:45px;" class="form-control" formControlName="ageToYears"><span style="margin-left: 2px;margin-top: 7px;">Years</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" class="form-control" style="width:45px;" formControlName="ageToMonths"><span style="margin-left: 2px;margin-top: 7px;">Months</span></div>
                                    <div style="margin-left: 10px;display: flex;"><input type="number" class="form-control" style="width:45px;" formControlName="ageToDays"><span style="margin-left: 2px;margin-top: 7px;">Days</span></div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot2">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>                      
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;">Location</div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" formControlName="location" class="form-control" placeholder="Enter Your Location" maxlength="20"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);">
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot3">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>         
                        </div>
                        <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.location.errors?.required) && (engagementMessaging.controls.location.touched || engagementMessaging.controls.location.dirty) && (engagementMessaging.controls.location.invalid)" style="margin-left: 15px;">Location is required</p>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;">Diagnosis</div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" formControlName="diagnosis" maxlength="15"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);">
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot4">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>                           
                        </div>
                        <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.diagnosis.errors?.required) && (engagementMessaging.controls.diagnosis.touched || engagementMessaging.controls.diagnosis.dirty) && (engagementMessaging.controls.diagnosis.invalid)" style="margin-left: 15px;">Diagnosis is required</p>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;">Care-Net Group</div>
                            </div>
                            <div class="col-lg-6">
                                <!-- <input type="text" class="form-control" formControlName="careGroup" maxlength="15"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);"> -->  
                                <mat-form-field>
                                    <mat-select #selectElement placeholder="Carenet-Group" [formControl]="tag" multiple 
                                                (valueChange)="closeSelect(selectElement)">
                                        <mat-option *ngFor="let tag of gettagData" [value]="tag.tag">{{tag.tag}}</mat-option>
                                    </mat-select>
                                </mat-form-field>                                                                
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot5">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.careGroup.errors?.required) && (engagementMessaging.controls.careGroup.touched || engagementMessaging.controls.careGroup.dirty) && (engagementMessaging.controls.careGroup.invalid)" style="margin-left: 15px;">Care-Group is required</p>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-2">
                                <div style="color:#000000;font-size: 15px;font-weight: bold;">Prescription</div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" formControlName="prescription" maxlength="20"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);">
                            </div>
                            <div class="col-lg-4">
                                <span style="margin-left: -10px;">
                                    <span style="font-size: 20px; margin-right: 5px;">[</span>
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #000000;"
                                    formControlName="andOrNot6">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 5px;">And</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 5px;">Or</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" >Not</mat-radio-button>
                                    </mat-radio-group> <span style="font-size: 20px;margin-left: 5px;">]</span>
                                </span>
                            </div>

                        </div>
                        <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.prescription.errors?.required) && (engagementMessaging.controls.prescription.touched || engagementMessaging.controls.prescription.dirty) && (engagementMessaging.controls.prescription.invalid)" style="margin-left: 15px;">Prescription is required</p>
                            </div>
                        </div>

                        <!-- <div class="row" style="margin-top: 20px;"> -->
                        <div class="row" style="margin-top: 30px;">
                                <!-- <div class="col-12"> -->
                                    <div style="color: #656565;font-size: medium;margin-left: 15px;"> Messaging Category</div>
                                <!-- </div>  -->
                        </div>

                        <div class="row" style="margin-top: 20px;">
                                <div class="col-lg-3">
                                    <div style="color:#000000;font-size: 15px;font-weight: bold;">Remainders</div>
                                </div>
                                <div class="col-lg-9">
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #617595;"
                                    formControlName="reminders">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 10px;">Follow Up</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 10px;">Vaccination</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" style="margin-right: 10px;">Investigstion</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="3" style="margin-right: 10px;">Home Visit</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="4" >Pending Payment</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                        </div>
                        <!-- <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.reminders.errors?.required) && (engagementMessaging.controls.triggerType.touched || engagementMessaging.controls.triggerType.dirty) && (engagementMessaging.controls.triggerType.invalid)" style="margin-left: 15px;">Reminders is required</p>
                            </div>
                        </div> -->
                        

                        <div class="row" style="margin-top: 20px;">
                                <div class="col-lg-3">
                                    <div style="color:#000000;font-size: 15px;font-weight: bold;">Informations</div>
                                </div>
                                <div class="col-lg-9">
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #617595;"
                                    formControlName="information">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 10px;">Appointment Delay</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 10px;">Expert Doctor Visit</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="2" style="margin-right: 10px;">Holiday</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="3" >Special Programs</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                        </div>

                        <div class="row" style="margin-top: 20px;">
                                <div class="col-lg-3">
                                    <div style="color:#000000;font-size: 15px;font-weight: bold;">Educational</div>
                                </div>
                                <div class="col-lg-9">
                                    <mat-radio-group
                                    [color]="'primary'"
                                    style="color: #617595;"
                                    class="educational"
                                    formControlName="educational">
                                        <mat-radio-button class="example-radio-button" [value]="0" style="margin-right: 10px;">Hygine</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" [value]="1" >Nutrition</mat-radio-button>
                                    </mat-radio-group>

                                </div>
                        </div>
                        <!-- <div class="row" *ngIf = "searchOption == '3'">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <p class="red" *ngIf="(engagementMessaging.controls.educational.errors?.required) && (engagementMessaging.controls.educational.touched || engagementMessaging.controls.educational.dirty) && (engagementMessaging.controls.educational.invalid)" style="margin-left: 15px;">Educational is required</p>
                            </div>
                        </div> -->

                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-8">
                                <!-- {{remainderArray.length}} -->
                                <table style="width: 100%;">
                                    <tr style="border: 1px solid;color: #000000;">
                                        <th> <span style="margin-left: 10px;"> Remainders</span></th>
                                        <th> <span style="margin-left: 23px;"> Edit/Delete</span></th>
                                    </tr>
                                    <tr style="border: 1px solid;" *ngFor="let reminder of remainderArray;let i = index">
                                        <td > <span style="color: #007bff;margin-left: 10px;"> {{reminder.remainderName}}</span></td>
                                        <td>
                                            <span style="margin-left: 10px;">
                                                <button type="button" class="btn btn-link" (click)="reminderEdit(i)">Edit</button>
                                                <button type="button" class="btn btn-danger-link" (click)="reminderDelete(i)">Delete</button>
                                            </span>
                                        </td>
                                    </tr>
                                </table> 
                                <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                                    - Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition"
                                  
                                    Reminder Column
                                    <ng-container matColumnDef="position">
                                      <th mat-header-cell *matHeaderCellDef> Reminder </th>
                                      <td mat-cell *matCellDef="let element">  </td>
                                    </ng-container>
                                  
                                    Edit/Delete Column
                                    <ng-container matColumnDef="name">
                                      <th mat-header-cell *matHeaderCellDef> Edit/Delete </th>
                                      <td mat-cell *matCellDef="let element">  </td>
                                    </ng-container>
                                  
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                  </table>      -->
                            </div>
                            <div class="col-lg-4"></div>

                        </div>





                        <!-- </div> -->

                        
                    </div>
                </div>
                <!-- <div class="row">
                    <div>
                        <h5>Selection Criteria</h5>
                    </div>
                </div>
                <div class="row">
                    <div style="display: flex;">
                        <div><h5>Show Message</h5></div>
                        <div>                    
                            <mat-radio-group>
                            <mat-radio-button class="example-radio-button" [value]="0" >Active</mat-radio-button>
                            <mat-radio-button class="example-radio-button" [value]="1" >Inactive</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="row">

                </div>
                <div class="row"></div> -->
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-lg-5"></div>
                <div class="col-lg-1">
                    <button class="btn btn-outline-primary" (click)="onNoClick()">Cancel</button>
                </div>
                <div class="col-lg-2">
                    <button class="btn btn-primary" type="submit" >Save</button>
                </div>
                <!-- [disabled]="!engagementMessaging.valid" -->
                <div class="col-lg-4"></div>
            </div>
        </form>
    </div>
</div>