

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Family History</p>
        </div>

       
    </div>
    <br><br><br>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
         <mat-panel-title>Parents</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mt-1">      
        <div style="float: right;">
          <!-- <button *ngIf="!familyeditStatus"  class="btn btn-primary customButton" (click)="fatherupdate();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button> -->
          <button *ngIf="!familyeditStatus&&parentshistory==undefined" class="btn btn-primary customButton" (click)="fatherupdate();" style="color:#ffff;"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Add</button>
          <button *ngIf="!familyeditStatus&&parentshistory!=undefined" class="btn btn-primary customButton" (click)="fatherupdate();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
          <button *ngIf="familyeditStatus"  class="btn btn-primary customButton" (click)="cancelfatherstatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
          <button *ngIf="familyeditStatus"  class="btn btn-primary customButton" (click)="savefatherstatus();"
              style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
      </div>
      <br><br><br>
        <div class="row" style="margin-top: 20px;">
           <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                  <span>Relation : &nbsp;&nbsp;</span>
                  <span [(ngModel)]="relationfather" style="font-weight: 600;">{{(parentshistory?.relation==="mother"? "Mother":parentshistory?.relation=="Mother"?"Mother":"Father")}}</span>
                
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p> Major Known Health issues: </p>
                  <input maxlength="80" class="form-control" [(ngModel)]="fatherhealthissues"  *ngIf="familyeditStatus" type="text" size="30" class="family-hist">
                  <label *ngIf="!familyeditStatus" class="ansText">{{parentshistory?.health_issues}}</label> 
                </div>
              </div>
        </div>

        <div class="row" style="margin-top: 20px;">
                <div class="col-lg-6 col-md-6 col-lg-6">
                  <div>
                     <p> Any daily Medication ? </p>
                     <mat-radio-group *ngIf="familyeditStatus"
                       aria-labelledby="example-radio-group-label"
                       class="example-radio-group"
                       [color]="'primary'" [(ngModel)]="anyfatherdailymedication">
                       <mat-radio-button class="example-radio-button" (click)="dailymedication('true')" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                       <mat-radio-button class="example-radio-button" (click)="dailymedication('false')" [value]="1" >No</mat-radio-button>
                    </mat-radio-group>
                    <span *ngIf="!familyeditStatus" class="ansText">{{parentshistory?.medication=='True' ? 'Yes' : parentshistory?.medication=='False' ? 'No' : ''}}</span>
                     <br>
                     <div *ngIf="enablemedication == true ">
                      <p> Please Describe </p>
                      <input maxlength="80" [(ngModel)]="fathermedicationtext"/>
                     </div>
                  </div>
                </div>
  
                <div class="col-lg-6 col-md-6 col-lg-6">
                  <div>
                     <p> Was there any Hospitalization ? </p>
                     <mat-radio-group *ngIf="familyeditStatus"
                     aria-labelledby="example-radio-group-label"
                     class="example-radio-group"
                     [color]="'primary'"
                     [(ngModel)]="isfatherhospitalized" [ngModelOptions]="{standalone: true}"
                     >
                     <mat-radio-button class="example-radio-button" (click)="fatherhospitalization('true')" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                     <mat-radio-button class="example-radio-button" (click)="fatherhospitalization('false')" [value]="1" >No</mat-radio-button>
                  </mat-radio-group>
                  <span *ngIf="!familyeditStatus" class="ansText">{{parentshistory?.hospitalization=='True' ? 'Yes' : parentshistory?.hospitalization=='False' ? 'No' : ''}}</span>
                  <br>
                     <div *ngIf="father_hospitalization == true">
                      <p> Please Describe </p>
                      <input maxlength="80" [(ngModel)]="fatherhospitalizedtext"/>
                     </div>
                    
                  </div>
                </div>
        </div>
             
        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>  Food Type : </p>
                 <mat-radio-group *ngIf="familyeditStatus"
                 aria-labelledby="example-radio-group-label"
                 class="example-radio-group"
                 [color]="'primary'"
                 [(ngModel)]="fatherfoodtype" [ngModelOptions]="{standalone: true}"
                 >
                 <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Vegetarian</mat-radio-button>
                 <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 10px;">Non-Veg </mat-radio-button>
                 <mat-radio-button class="example-radio-button" [value]="2" >Egg + Vegetarian</mat-radio-button>
              </mat-radio-group>
              <span *ngIf="!familyeditStatus" class="ansText">{{parentshistory?.food_type}}</span>
                
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p> Any Diseases running in a family
                  (Hereditary Diseases) </p>

                  <input maxlength="80" [(ngModel)]="fatherHereditary" class="form-control" *ngIf="familyeditStatus" type="text" size="30" class="family-hist">
                  <label *ngIf="!familyeditStatus" class="ansText">{{parentshistory?.hereditary_disease}}</label> 
                
              </div>
            </div>
        </div>
      <br>
      <hr class="hrStyle">

       <div style="float: right;">
            <button *ngIf="!mothereditStatus&&motherdata==undefined" class="btn btn-primary customButton" (click)="motherupdate();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Add</button>
            <button *ngIf="!mothereditStatus&&motherdata!=undefined" class="btn btn-primary customButton" (click)="motherupdate();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
            <button *ngIf="mothereditStatus" class="btn btn-primary customButton" (click)="cancelmotherstatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
            <button *ngIf="mothereditStatus" class="btn btn-primary customButton" (click)="savemotherstatus();"
                style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>
    
        </div>
        <br><br><br>
        <div class="row" style="margin-top: 20px;">
           <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                  <span>Relation : &nbsp;&nbsp;</span>
                  <span [(ngModel)]="relationmother" style="font-weight: 600;">{{(parentshistory?.relation!=="mother"? "Mother":parentshistory?.relation=="Mother"?"Mother":"Father")}}</span>
                
                </div>
           </div>
          <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p> Major Known Health issues: </p>
                   <input maxlength="80" class="form-control" [(ngModel)]="motherhealthissues"  *ngIf="mothereditStatus" type="text" size="30" class="family-hist">
                   <label *ngIf="!mothereditStatus" class="ansText">{{motherdata?.health_issues}}</label> 
                </div>
          </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p> Any daily Medication ? </p>
                   <mat-radio-group *ngIf="mothereditStatus"
                   aria-labelledby="example-radio-group-label"
                   class="example-radio-group"
                   [color]="'primary'"
                   [(ngModel)]="anymotherdailymedication" [ngModelOptions]="{standalone: true}">
                   <mat-radio-button (click)="callmothermedication('true')" class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                   <mat-radio-button (click)="callmothermedication('false')" class="example-radio-button" [value]="1" >No</mat-radio-button>
                </mat-radio-group>
                <span *ngIf="!mothereditStatus" class="ansText">{{motherdata?.medication=='True' ? 'Yes' : motherdata?.medication=='False' ? 'No' : ''}}</span>
                 <br>
                 <p *ngIf="!mothereditStatus&&enablemothermedication == true">{{motherdata?.medication_text}}</p>
                 <div *ngIf="mothereditStatus&&enablemothermedication == true">
                  <p> Please Describe </p>
                  <input maxlength="80" [(ngModel)]="mothermedicationtext" />
                 </div>
                   
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p> Was there any Hospitalization ? </p>

                   <mat-radio-group *ngIf="mothereditStatus"
                   aria-labelledby="example-radio-group-label"
                   class="example-radio-group"
                   [color]="'primary'"
                   [(ngModel)]="ismotherhospitalized" [ngModelOptions]="{standalone: true}"
                   >
                   <mat-radio-button  class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                   <mat-radio-button  class="example-radio-button" [value]="1" >No</mat-radio-button>
                </mat-radio-group>
                <span *ngIf="!mothereditStatus" class="ansText">{{motherdata?.hospitalization=='True' ? 'Yes' : motherdata?.hospitalization=='False' ? 'No' : ''}}</span>
                <br>
                  <p  *ngIf="!mothereditStatus && ismotherhospitalized == 0">{{motherdata?.hospitalization_text}}</p>
                   <div *ngIf="mothereditStatus && ismotherhospitalized == 0">
                    <p> Please Describe </p>
                    <input maxlength="80" [(ngModel)]="motherhospitalizedtext"/>

                   </div>
                  
                </div>
            </div>
        </div>  

        <div class="row" style="margin-top: 20px;"> 
            <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p>  Food Type : </p>

                   <mat-radio-group *ngIf="mothereditStatus"
                   aria-labelledby="example-radio-group-label"
                   class="example-radio-group"
                   [color]="'primary'"
                   [(ngModel)]="motherfoodtype" [ngModelOptions]="{standalone: true}">
                   <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Vegetarian</mat-radio-button>
                   <mat-radio-button class="example-radio-button" [value]="1" style="margin-right: 10px;" >Non-Veg </mat-radio-button>
                   <mat-radio-button class="example-radio-button" [value]="2" >Egg + Vegetarian</mat-radio-button>
                  </mat-radio-group>
                  <span *ngIf="!mothereditStatus" class="ansText">{{motherdata?.food_type}}</span>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
                <div>
                   <p> Any Diseases running in a family
                    (Hereditary Diseases) </p>
                  
                    <input maxlength="80" class="form-control" *ngIf="mothereditStatus" type="text" size="30" class="family-hist">
                    <label *ngIf="!mothereditStatus"  [(ngModel)]="motherhereditarydisease" >{{motherdata?.hereditary_disease}}</label> 
                </div>
            </div>
        </div>
      </div>
    </mat-expansion-panel>
     
    <mat-expansion-panel style="margin-top: 5px;">
       <mat-expansion-panel-header>
          <mat-panel-title>Siblings</mat-panel-title>
       </mat-expansion-panel-header>
    
       <div class="row" style="float:right">
        <div>
          <!-- <button *ngIf="!siblingeditStatus&&siblingshistory==undefined" class="btn btn-primary customButton" (click)="siblingeditStatusupdate();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button> -->
          <!-- <button *ngIf="!siblingeditStatus&&siblingshistory!=undefined" class="btn btn-primary customButton" (click)="siblingeditStatusupdate();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button> -->
          <button *ngIf="siblingeditStatus" class="btn btn-primary customButton" (click)="cancelsiblingeditStatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
          <button *ngIf="siblingeditStatus" class="btn btn-primary customButton" (click)="savesiblingeditStatus();"
              style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button> 
          <button *ngIf="!siblingeditStatus" class="btn btn-primary customButton" (click)="addSibilings();"
              style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Add</button> 
          <button *ngIf="!siblingeditStatus" class="btn btn-primary customButton" (click)="deleteSibilings();"
              style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Delete</button> 
        
            </div>
       </div>
       <br><br>
      <!-- <div class="row" style="margin-top: 25px;"> 
          <div class="col-6">
            <div>
              <span>Name of the Sibling : &nbsp;&nbsp;</span>
            </div>
            <div *ngIf="!siblingeditStatus">{{siblingshistory?.first_name}}&nbsp;{{siblingshistory?.last_name}}</div>
            <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
              <input maxlength="80" [(ngModel)]="siblingfirstname"  type="text" placeholder=" First Name"/>&nbsp;&nbsp;
              <input maxlength="80" [(ngModel)]="siblingsecondname" type="text" placeholder=" Second Name"/>
            </div>
          </div>

          <div class="col-6">
            <div>
              <span>D.O.B : &nbsp;&nbsp;</span>
            </div>
            <div *ngIf="!siblingeditStatus">{{siblingshistory?.dob}}</div>
            <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
              <input maxlength="80" style="width:30%" id="dob" [(ngModel)]="siblingdob" class="form-control" type="date" [min]="patientmindate" [max]="patientmaxdate" 
              placeholder="DD . MMM . YYYY"  (keydown)="disableDate()">
             </div>
          </div>
      </div>
      <div class="row" style="margin-top: 25px;">
 
         <div class="col-6">
           <div>
             <span>Gender: &nbsp;&nbsp;</span>
             
             
             <div *ngIf="!siblingeditStatus">{{siblingshistory?.gender}}</div>
           </div>
           <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
            <mat-radio-group 
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [color]="'primary'" [(ngModel)]="siblinggender">
            <mat-radio-button  class="example-radio-button" [value]="0"  style="margin-right: 10px;">Male</mat-radio-button>
            <mat-radio-button  class="example-radio-button" [value]="1" >Female</mat-radio-button>
           </mat-radio-group>
           </div>
         </div>
 

         <div class="col-6">
           <div>
             <span>Major Known Health issues: : &nbsp;&nbsp;</span>
           </div>
           <div *ngIf="!siblingeditStatus">{{siblingshistory?.health_issues}}</div>
           <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
            <input maxlength="80" [(ngModel)]="siblingmajorhealthissues" type="text" placeholder=" Issues"/>
          </div>
         </div>
      </div>
      <div class="row" style="margin-top: 25px;">
         <div class="col-6">
           <div>
             <span>Any daily Medication ? : &nbsp;&nbsp;</span>
             <div *ngIf="!siblingeditStatus">{{siblingshistory?.medication}}</div>
          </div>
           <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
            <mat-radio-group 
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [color]="'primary'" [(ngModel)]="siblingdailymedication">
            <mat-radio-button (click)="anydaily_medication('Yes')" class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
            <mat-radio-button (click)="anydaily_medication('No')" class="example-radio-button" [value]="1" >No</mat-radio-button>
         </mat-radio-group>
           </div>
           <div *ngIf="!siblingeditStatus">{{siblingshistory?.medication_text}}</div>
           <div *ngIf="any_daily_medication" style="margin-top: 10px;">
            <span>Please Describe : &nbsp;&nbsp;</span>
            
            <div  >
              <input maxlength="80" type="text"  [(ngModel)]="siblingdaily_medication" placeholder="Medication description" />
            </div>
          </div>
         </div>

         <div class="col-6">
           <div>
             <span>Was there any Hospitalization ? : &nbsp;&nbsp;</span>
             <div *ngIf="!siblingeditStatus">{{siblingshistory?.hospitalization}}</div>
             <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
              <mat-radio-group 
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [color]="'primary'" [(ngModel)]="siblinghospitalization">
              <mat-radio-button (click)="sibling_hospitalization('Yes')" class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
              <mat-radio-button (click)="sibling_hospitalization('No')" class="example-radio-button" [value]="1" >No</mat-radio-button>
           </mat-radio-group>
             </div>
           </div>
           <div *ngIf="!siblingeditStatus">{{siblingshistory?.hospitalization_text}}</div>
           <div *ngIf="sibling__hospitalization" style="margin-top: 10px;">
            <span>Please Describe :&nbsp;&nbsp;</span>
            
            <div >
              <input maxlength="80" type="text" [(ngModel)]="siblinghospitaltext" placeholder="Medication description" />
            </div>
            
          </div>
         </div>
      </div>
      <div class="row" style="margin-top: 25px;">
         <div class="col-6">
           <div>
             <span>Other details if any.. : &nbsp;&nbsp;</span>
           </div>
           <div *ngIf="!siblingeditStatus">
            {{siblingshistory?.other_details}}
           </div>
           <div *ngIf="siblingeditStatus" style="margin-top: 10px;">
            <input maxlength="80" type="text" [(ngModel)]="siblingotherissues" placeholder="Other Details" />
          </div>
         </div>
 
      </div> -->
      <div *ngIf="!siblingeditStatus" style="margin-top:40px">
        <div *ngFor="let siblings of siblingshistory">
          <br>
          <button *ngIf="!siblingeditStatus&&siblingshistory!=undefined" class="btn btn-primary customButton" (click)="siblingeditStatusupdate(siblings);" style="color:#ffff;float: right;margin-top: -40px;"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> 
            <span *ngIf="siblings.id==undefined" style="color:#ffff;float: right">Add</span>
            <span *ngIf="siblings.id!=undefined" style="color:#ffff;float: right">Update</span>
          </button>
          <hr class="hrStyle">
          <div class="row" style="margin-top: 25px;"> 
              <div class="col-6">
                <div>
                  <span>Name of the Sibling : &nbsp;&nbsp;</span>
                </div>
                <div class="ansText">{{siblings?.first_name}}&nbsp;{{siblings?.last_name}}</div>
              </div>

              <div class="col-6">
                <div>
                  <span>D.O.B : &nbsp;&nbsp;</span>
                </div>
                <div class="ansText">{{siblings?.dob}}</div>
              </div>
          </div>
          <div class="row" style="margin-top: 25px;">
    
            <div class="col-6">
              <div>
                <span>Gender: &nbsp;&nbsp;</span>
                <div class="ansText">{{siblings?.gender}}</div>
              </div>
            </div>
            
            <div class="col-6">
              <div>
                <span>Major Known Health issues: : &nbsp;&nbsp;</span>
              </div>
              <div class="ansText">{{siblings?.health_issues}}</div>
            </div>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-6">
              <div>
                <span>Any daily Medication ? : &nbsp;&nbsp;</span>
                <div class="ansText">{{siblings?.medication == "True" ? 'Yes' : siblings?.medication == "False" ? 'No':''}}</div>
              </div>
              <div class="ansText" *ngIf="!siblingeditStatus&&siblings?.medication == 'True'">{{siblings?.medication_text}}</div>
              </div>

            <div class="col-6">
              <div>
                <span>Was there any Hospitalization ? : &nbsp;&nbsp;</span>
                <div class="ansText">{{siblings?.hospitalization == "True" ? 'Yes' : siblings?.hospitalization == "False" ? 'No':''}}</div>
              </div>
              <div class="ansText" *ngIf="!siblingeditStatus&&siblings?.hospitalization == 'True'">{{siblings?.hospitalization_text}}</div>
              </div>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-6">
              <div>
                <span>Other details if any.. : &nbsp;&nbsp;</span>
              </div>
              <div class="ansText">
                {{siblings?.other_details}}
              </div>
            </div>
          </div>
        </div>
    </div>

      <div *ngIf="siblingeditStatus">
        <div class="row" style="margin-top: 25px;"> 
          <div class="col-6">
            <div>
              <span>Name of the Sibling : &nbsp;&nbsp;</span>
            </div>
            <div style="margin-top: 10px;">
              <input maxlength="80" [(ngModel)]="siblingfirstname"  type="text" placeholder=" First Name"/>&nbsp;&nbsp;
              <input maxlength="80" [(ngModel)]="siblingsecondname" type="text" placeholder=" Second Name"/>
            </div>
          </div>

          <div class="col-6">
            <div>
              <span>D.O.B : &nbsp;&nbsp;</span>
            </div>
            <div style="margin-top: 10px;">
              <input maxlength="80" style="width:30%" id="dob" [(ngModel)]="siblingdob" class="form-control" type="date" [min]="patientmindate" [max]="patientmaxdate" 
              placeholder="DD . MMM . YYYY"  (keydown)="disableDate()">
             </div>
          </div>
      </div>
      <div class="row" style="margin-top: 25px;">
 
         <div class="col-6">
           <div>
             <span>Gender: &nbsp;&nbsp;</span>
             
           </div>
           <div style="margin-top: 10px;">
            <mat-radio-group 
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [color]="'primary'" [(ngModel)]="siblinggender">
            <mat-radio-button  class="example-radio-button" [value]="0"  style="margin-right: 10px;">Male</mat-radio-button>
            <mat-radio-button  class="example-radio-button" [value]="1" >Female</mat-radio-button>
           </mat-radio-group>
           </div>
         </div>
 

         <div class="col-6">
           <div>
             <span>Major Known Health issues: : &nbsp;&nbsp;</span>
           </div>
           <div style="margin-top: 10px;">
            <input maxlength="80" [(ngModel)]="siblingmajorhealthissues" type="text" placeholder=" Issues"/>
          </div>
         </div>
      </div>
      <div class="row" style="margin-top: 25px;">
         <div class="col-6">
           <div>
             <span>Any daily Medication ? : &nbsp;&nbsp;</span>
            
          </div>
           <div style="margin-top: 10px;">
            <mat-radio-group 
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [color]="'primary'" [(ngModel)]="siblingdailymedication">
            <mat-radio-button (click)="anydaily_medication('Yes')" class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
            <mat-radio-button (click)="anydaily_medication('No')" class="example-radio-button" [value]="1" >No</mat-radio-button>
         </mat-radio-group>
           </div>
          <div *ngIf="siblingeditStatus&&any_daily_medication" style="margin-top: 10px;">
            <span>Please Describe : &nbsp;&nbsp;</span>
            
            <div>
              <input maxlength="80" type="text"  [(ngModel)]="siblingdaily_medication" placeholder="Medication description" />
            </div>
          </div>
         </div>

         <div class="col-6">
           <div>
             <span>Was there any Hospitalization ? : &nbsp;&nbsp;</span>
             
             <div style="margin-top: 10px;">
              <mat-radio-group 
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [color]="'primary'" [(ngModel)]="siblinghospitalization">
              <mat-radio-button (click)="sibling_hospitalization('Yes')" class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
              <mat-radio-button (click)="sibling_hospitalization('No')" class="example-radio-button" [value]="1" >No</mat-radio-button>
           </mat-radio-group>
             </div>
           </div>
           
           <div *ngIf="siblingeditStatus&&sibling__hospitalization" style="margin-top: 10px;">
            <span>Please Describe :&nbsp;&nbsp;</span>
            
            <div >
              <input maxlength="80" type="text" [(ngModel)]="siblinghospitaltext" placeholder="Hospitalization Description" />
            </div>
            
          </div>
         </div>
      </div>
      <div class="row" style="margin-top: 25px;">
         <div class="col-6">
           <div>
             <span>Other details if any.. : &nbsp;&nbsp;</span>
           </div>
           <div style="margin-top: 10px;">
            <input maxlength="80" type="text" [(ngModel)]="siblingotherissues" placeholder="Other Details" />
          </div>
         </div>
 
      </div>
      </div>
      
    </mat-expansion-panel>
</div>





