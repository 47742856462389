import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { param } from 'jquery';



@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss']
})
export class VersionListComponent implements OnInit {
  urlPoints;
  token;
  dmail;
  releaseNotes;
  releaseNotesArray: any = [];
  imageUploadUrl;
  vhnimageUploadUrl;


  constructor(private appService: AppService) { 
    this.urlPoints = AppConfig.settings.EndPoints;

  }

  ngOnInit(): void {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.token = getauthdetails.token;
    this.dmail =  getauthdetails.account_id;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';


    this.versionListNotes();
  }

  versionListNotes() {

    let data = {
      account_email: this.dmail,
      package_name: 'com.helyxon.epicare',
      status: '1'
    }
    this.appService.getData(this.urlPoints.version,data,this.token).subscribe((Response: any) =>{
      console.log(Response);
      this.releaseNotes = Response.message.length
      console.log(this.releaseNotes);
      for (var i=0;i<Response.message.length;i++){

        this.releaseNotesArray.push({
          app_name: Response.message[i].app_name,
          package_name: Response.message[i].package_name,
          release_date: Response.message[i].release_date,
          version: Response.message[i].version,
          video: this.vhnimageUploadUrl + Response.message[i].video,
          image: this.imageUploadUrl + Response.message[i].image,
          release_notes: Response.message[i].release_notes
        })
      }
    });
  }
}
