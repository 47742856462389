<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Social History</p>
        </div>
    </div>

    <div style="padding: 15px;margin-top:30px;float: right;">
      <button *ngIf="!editStatus&&getMotherData==''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Add</button>
        <button *ngIf="!editStatus&&getMotherData!=''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="cancelStatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="validatemotherAntel();"
            style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>

    </div>
    <div style="margin-left: 30px;margin-top: 100px;">
        <hr class="hrStyle">
    <form [formGroup]="socialhistory" style="width:100%;">
      <div class="row" style="margin-top: 20px;">
         <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p> What type of House ? </p>
                 <div style="margin-top: 20px;">
                  <mat-radio-group *ngIf="editStatus"
                     aria-labelledby="example-radio-group-label"
                     class="example-radio-group"
                     [color]="'primary'"
                     [(ngModel)]="typeofHouse" [ngModelOptions]="{standalone: true}"
                     >
                     <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Kutcha House</mat-radio-button>
                     <mat-radio-button class="example-radio-button" [value]="1" >Pucca House</mat-radio-button>
                  </mat-radio-group>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.house_type}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p> How many people in the house ? </p>
                 <p style="margin-top: 20px;"> <input style="width: 50%;" *ngIf="editStatus" class="form-control dateInput"
                    formControlName="peopleinHouse" maxlength="2" (keypress)="numberOnly($event)">
                    <span *ngIf="!editStatus" class="ansText">{{getMotherData.number_of_people}}</span>
                    <span *ngIf="!editStatus&&getMotherData==''">-</span></p>
              </div>
            </div>
       </div>
    
        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div style="width:99%;">
                 <p>How many rooms in the house ? </p>
                 <p style="margin-top: 20px;"> <input style="width: 50%;" *ngIf="editStatus" class="form-control dateInput"
                  formControlName="roomsinHouse" maxlength="2" (keypress)="numberOnly($event)">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.number_of_rooms}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span></p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Any smokers in the house? </p>
                 <div style="width:99%;">
                    <div style="margin-top: 28px;">
                       <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="smokerinHouse" [ngModelOptions]="{standalone: true}"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="2" style="margin-left: 10px;">May Be</mat-radio-button>
                       </mat-radio-group>
                       <span *ngIf="!editStatus" class="ansText">{{getMotherData.smokers}}</span>
                       <span *ngIf="!editStatus&&getMotherData==''">-</span>
                    </div>
                 </div>
              </div>
            </div>
        </div>
        <div class="row" style="margin-top: 30px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Type of Stove use for Cooking </p>
                 <p style="width: 50%;"> 
                  <select class="form-control" name="Type" style="font-size: 14px;"
                  formControlName="typeofStove" required *ngIf="editStatus">
                  <option value="">Select</option>
                  <option value="gas" >Gas</option>
                  <option value="kerosine" >Kerosine</option>
                  <option value="firewood" >Firewood</option>
                  <option value="others" >Others</option>
               </select>
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.stove_type}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Distance to Doctor's Clinic </p>
                 <p style="display: flex;"> <input style="width: 20%;" *ngIf="editStatus" class="form-control dateInput"
                  formControlName="distance" maxlength="3" (keypress)="numberOnly($event)">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.distance}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
                  
                  <select class="form-control" name="Type" style="font-size: 14px;width:20%;margin-left: 10px;margin-top: 10px;"
                  formControlName="distanceType" required *ngIf="editStatus">
                  <option value="">Select</option>
                  <option value="km" >km</option>
                  <option value="mile" >mile</option>
                   </select>
                   <span *ngIf="!editStatus" style="margin-left: 5px;"> {{getMotherData.distance_units}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
              </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div style="width:99%;">
              <p>Type of Transportation </p>
              <p> 
               <select class="form-control" name="Type" style="font-size: 14px;width:50%"
               formControlName="typeofTrans" required *ngIf="editStatus">
               <option value="">Select</option>
               <option value="public transport" >Public Transport </option>
               <option value="own vehicle" >Own Vehicle</option>
               </select>
               <span *ngIf="!editStatus" class="ansText">{{getMotherData.transportation}}</span>
               <span *ngIf="!editStatus&&getMotherData==''">-</span>
              </p>
           </div>
         </div>
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div>
              <p>Any problem preventing Child coming to Hospital? </p>
              <p> <input style="width: 50%;" *ngIf="editStatus" class="form-control dateInput"
               formControlName="probPreventing" maxlength="160" (keypress)="omit_special_char($event)">
               <span *ngIf="!editStatus" class="ansText">{{getMotherData.problem_coming_to_hospital}}</span>
               <span *ngIf="!editStatus&&getMotherData==''">-</span></p>
           </div>
         </div>
        </div>
        <div class="row" style="margin-top: 20px;">
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div style="width:99%;margin-top: 10px;">
              <p>Is the House on the Main Road ? </p>
              <div style="width:99%;margin-top: 20px;">
                 <div>
                    <mat-radio-group *ngIf="editStatus"
                       aria-labelledby="example-radio-group-label"
                       class="example-radio-group"
                       [color]="'primary'"
                       [(ngModel)]="isMainroad" [ngModelOptions]="{standalone: true}"
                       >
                       <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                       <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                    </mat-radio-group>
                    <span *ngIf="!editStatus" class="ansText">{{getMotherData.house_on_main_road}}</span>
                    <span *ngIf="!editStatus&&getMotherData==''">-</span>
                 </div>
              </div>
           </div>
         </div>
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div>
              <p>Does the House have Proper Drinking Water ?</p>
              <div style="width:99%;">
                 <div style="margin-top: 20px;">
                    <mat-radio-group *ngIf="editStatus"
                       aria-labelledby="example-radio-group-label"
                       class="example-radio-group"
                       [color]="'primary'"
                       [(ngModel)]="proberDrinking" [ngModelOptions]="{standalone: true}"
                       >
                       <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                       <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                    </mat-radio-group>
                    <span *ngIf="!editStatus" class="ansText">{{getMotherData.drinking_water_available}}</span>
                    <span *ngIf="!editStatus&&getMotherData==''">-</span>
                 </div>
              </div>
           </div>
         </div>
        </div>
        <div class="row" style="margin-top: 20px;">
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div style="width:99%;">
              <p>Does the House have a toilet ? </p>
              <div style="width:99%;">
                 <div>
                    <mat-radio-group *ngIf="editStatus"
                       aria-labelledby="example-radio-group-label"
                       class="example-radio-group"
                       [color]="'primary'"
                       [(ngModel)]="haveToilet" [ngModelOptions]="{standalone: true}"
                       >
                       <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">Yes</mat-radio-button>
                       <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                    </mat-radio-group>
                    <span *ngIf="!editStatus" class="ansText">{{getMotherData.toilet_available}}</span>
                    <span *ngIf="!editStatus&&getMotherData==''">-</span>
                 </div>
              </div>
           </div>
         </div>
         <div class="col-lg-6 col-md-6 col-lg-6">
           <div>
              <p>What kind of air and water pollution is in your area ?</p>
              <p> 
               <select class="form-control" name="Type" style="font-size: 14px;width:50%"
               formControlName="waterPollution" required *ngIf="editStatus">
               <option value="">Select</option>
               <option value="good" >Good</option>
               <option value="moderate" >Moderate</option>
               <option value="unhealthy" >Unhealthy</option>
               <option value="very unhealthy" >Very Unhealthy</option>
               <option value="hazardous" >Hazardous</option>
               </select>
               <span *ngIf="!editStatus" class="ansText">{{getMotherData.pollution}}</span>
               <span *ngIf="!editStatus&&getMotherData==''">-</span>
              </p>
           </div>
         </div>
        </div>
    </form>
     </div>
</div>


