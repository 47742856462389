<div>Crop image</div>
<div class="row" style="margin-top:15px">
    <div class="col-7">
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="4 / 3"
        [resizeToWidth]="256"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
</div>
<div class="col-5" style="margin-top:30px;text-align: center;">
    <label><b>Preview</b></label>
    <div><img [src]="croppedImage" style="width:150px;height: 150px;border:2px solid;border-radius: 100%;" [style.border]="croppedImage ? '1px solid black' : 'none'" /></div>
</div>

<div class="col-12">
    <button  class="cancel btn" (click)="saveDrProfile()" style="width: fit-content;float: right;">Set</button>
    <button  class="cancel btn" (click)="onCloseDrprof()" style="width: fit-content;float: right;">Cancel</button>
</div>

</div>

