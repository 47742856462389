<style>
    .wrapword {
       word-break: break-word;
       white-space: pre-wrap;
       -moz-white-space: pre-wrap;      
    }
    </style>
    
    <form name="folliclestudy">
        <div class="row">
            <div class="col-md-12">
                <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
            </div>
            <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
                <table class="table table-bordered">
                    <thead style="background-color: #adccff;">
                        <tr>
                            <th class="text-center" style="width: 50px;">S.No</th>
                            <th class="text-center" style="width: 170px;">Date</th>
                            <th class="text-center" style="width: 100px;">Day</th>
                            <th class="text-center" style="width: 150px;">RT Ovary</th>
                            <th class="text-center" style="width: 150px;">LT Ovary</th>
                            <th class="text-center" style="width: 150px;">Endometrium</th>
                            <th style="width: 450px;" class="text-center">Remarks</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr class="text-center" style="border: tomato;" *ngFor="let follicledetails of getarchivedfollicle; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{follicledetails.date}}</td>
                            <td>{{follicledetails.day}}</td>
                            <td  class="wrapword">{{follicledetails.rt_ovary}}</td>
                            <td  class="wrapword">{{follicledetails.lt_ovary}}</td>
                            <td class="wrapword">{{follicledetails.endometrium}}</td>
                            <td class="wrapword">{{follicledetails.comments}}</td>
                        </tr>
                        
                    </tbody>
                </table>
                <!-- <div class=" text-center"  style="color: red;"  ng-if="showarchivedfollicledetails == false">No data found</div> -->
            </div>
        </div>
    </form>
    
    