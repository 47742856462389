import { Component, OnInit, Input, ViewChild,Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { StarRatingComponent } from 'ng-starrating';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent
} from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
declare let alasql;


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackReportComponent implements OnInit {
  
  @Input() headerTitle: string;
  paymentCategoryForm: FormGroup;
  
  params = {};
  getfollowupList:any;
  getvideocallTransList:any;

  dmail;
  doc_id ;
  token;
  drId: number;
  private sub: any;

  todayDate:any;
startdateInFormat = '-';
enddateInFormat = '-';
sendstartDate:any;
sendendDate:any;
selected: any;
alwaysShowCalendars: boolean;
ranges: any = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}
invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  
  
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private appService: AppService,private http: HttpClient, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    
    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    this.sendstartDate = momDate.format('YYYY-MM-DD');
    this.sendendDate = momDate.format('YYYY-MM-DD');
   
    this. getWalletReport();
  }

  getWalletReport(){ 

   this.getfollowupList = '';
   this.getvideocallTransList = '';
  

    this.params = {
      "account_email": this.dmail,
      "from_date": this.sendstartDate,
      "to_date": this.sendendDate
    }

  this.appService
      .getData(AppConfig.settings.EndPoints.feedbackReport, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.getfollowupList = response.data;
      });
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
    
  }

  datesUpdated(range) {
    const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
   
   this.sendstartDate = startDate.format('YYYY-MM-DD');
   this.sendendDate = endDate.format('YYYY-MM-DD');
  }

  downloadPDF(){
     var element = document.getElementById('printtable');
  var opt = {
    margin: 0.1,
    filename: 'FeedbackReport.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
 // New Promise-based usage:
 html2pdf().from(element).set(opt).save();
  }

  downloadCSV(){
    let filename = 'FeedbackReport'
    alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#printtable',{headers:true,separator:','})");
  };

  downloadXLS(){
    var blob = new Blob([document.getElementById('printtable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "FeedbackReport.xls");
  }

  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  
}
