<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider2">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;" src="assets/six1.svg" 
            [class.ml]="slider_value==30" [class.mmg]="slider_value==90"
            [class.mg]="slider_value==150" [class.gm]="slider_value==210"
            [class.mol]="slider_value==270"  [class.mmol]="slider_value==330"
            >
        </div>
    
        <div class='container'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(30)">
                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==30?'assets/unit1.svg':'assets/unit2.svg'">

                </div>
                
                <span [class.bold]="slider_value==30">milliliter</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(90)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==90?'assets/unit5.svg':'assets/unit6.svg'">

                </div>


                <span  [class.bold]="slider_value==90">microgram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;"  (click)="changeSliderValue(150)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==150?'assets/unit3.svg':'assets/unit4.svg'">

                </div>


                <span [class.bold]="slider_value==150">milligram</span>
            </span>

            

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(210)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==210?'assets/unit7.svg':'assets/unit8.svg'">

                </div>
            

                <span  [class.bold]="slider_value==210">gram</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(270)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==270?'assets/unit9.svg':'assets/unit10.svg'">

                </div>


                <span  [class.bold]="slider_value==270">mole</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(330)">

                <div style="display: flex;justify-content: center;">
                    <img [src]="slider_value==330?'assets/unit11.svg':'assets/unit12.svg'">

                </div>


                <span [class.bold]="slider_value==330">millimole</span>
            </span>
        </div>
    </div>
</div>


<div style="display: flex;justify-content: space-around; justify-content: space-around;">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>