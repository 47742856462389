<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div [ngStyle]="mode?{'display': 'none'} : {'display': 'block'}" class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider3">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;border-radius: 50%;margin-left: 2.5rem;"
            [class.before_]="slider_value==315" [class.not_]="slider_value==135" [class.with_]="slider_value==225"
            [class.after_]="slider_value==45"
            src="assets/four1.svg">
        </div>
    
        <div class='container container_'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(315)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==315?'assets/when1.svg':'assets/when2.svg'">
                </div>
                <span [class.bold]="slider_value==315">Before Food</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(45)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==45?'assets/when3.svg':'assets/when4.svg'">
                </div>
    
                <span [class.bold]="slider_value==45">After Food</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(135)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==135?'assets/when5.svg':'assets/when6.svg'">
                </div>
    
                <span  [class.bold]="slider_value==135">Not Related to Food</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(225)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==225?'assets/when7.svg':'assets/when8.svg'">
                </div>
    
                <span  [class.bold]="slider_value==225">With Food</span>
            </span>
            
        </div>
    </div>
<!-- </div> -->
<!-- for srinivas only -->
<!-- <div style="height: 27.5rem;width: 30rem;overflow: hidden;"> -->
    <div [ngStyle]="mode?{'display': 'block'} : {'display': 'none'}" class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider3">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;border-radius: 50%;margin-left: 2.5rem;"
            [class.before]="slider_value==0" [class.not]="slider_value==72" [class.with]="slider_value==144"
            [class.after]="slider_value==216"[class.empty]="slider_value==288"
            src="assets/four_1.png">
        </div>
    
        <div class='container container_srini'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(0)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==0?'assets/when1.svg':'assets/when2.svg'">
                </div>
                <span [class.bold]="slider_value==0">Before Food</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(72)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==72?'assets/when3.svg':'assets/when4.svg'">
                </div>
    
                <span [class.bold]="slider_value==72">After Food</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(144)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==144?'assets/when5.svg':'assets/when6.svg'">
                </div>
    
                <span  [class.bold]="slider_value==144">Not Related to Food</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(288)">
    
                <span  [class.bold]="slider_value==288" style="visibility:hidden">empty</span>
            </span>

            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(216)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==216?'assets/when7.svg':'assets/when8.svg'">
                </div>
    
                <span  [class.bold]="slider_value==216">With Food</span>
            </span>
            
        </div>
    </div>
</div>

<div style="display: flex;justify-content: space-around; justify-content: space-around;position: relative">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>