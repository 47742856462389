import { Component, OnInit,Input } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from 'src/app/app.config';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qrscaner',
  templateUrl: './qrscaner.component.html',
  styleUrls: ['./qrscaner.component.scss']
})
export class QrscanerComponent implements OnInit {
  @Input() queueLists: any[];
  showDetails: any;
  fromQR:boolean=false;
  closeScan:boolean=false;
  availableDevices: MediaDeviceInfo[] | any;
  currentDevice: MediaDeviceInfo[] | any;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
  ];

  hasDevices: boolean | any;
  urlPoints;
  hasPermission: boolean | any;
  dr_recordID;
  token;
  loaded2 = false;
  qrResultString: string | any;
  constructor(public dialogRef:MatDialogRef<QrscanerComponent>,private toastr: ToastrService,private router: Router,private appService: AppService) { 
    this.urlPoints = AppConfig.settings.EndPoints;
  }
  ngOnInit(): void {  
    console.log("queueList:::",this.appService.queueLists);
  }

  clearResult(): void {
    this.qrResultString = null;

  }

  isProgress: boolean = false;
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.isProgress = true;
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }
  
  onCodeResult(resultString: string) {
    this.qrResultString = this.extractMrNumber(resultString);
      this.isProgress = true;
      let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
      this.dr_recordID = getauthdetails.docRecord_id;
      this.token = getauthdetails.token;

      let successMessageShown = false;

      this.appService.queueLists.forEach(e => {
          // if(e.status == "confirmed" && (e.type == "consultation" ||e.type == "appointment" || e.type =='vaccination')){
          if(e.status == "confirmed" && (e.type == "consultation" ||e.type == "appointment")){
            if (e.mr_number === this.qrResultString) {
          this.appService.fromQR=true;
          this.appService.fromQRData=e;
          this.router.navigate(['epicare/appointment', e.record_id]);
          successMessageShown = true;
          this.close();
        }
     }
      });
      
      if (!successMessageShown) {
        this.toastr.error('There are no appointments for this patient today!');
        this.close();
      }
    // }
    if(resultString){
      this.closeScan = false;
    }
  }
  extractMrNumber(resultString: string): string {
    try {
      const jsonData = JSON.parse(resultString);
      if (jsonData.mr_number) {
        return jsonData.mr_number;
      }
    } catch (error) {
      return resultString;
    }
      return 'Not found';
  }
  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  scanBtn() {
    this.closeScan = !this.closeScan;
    this.showDetails = 'showScan';
    this.isProgress = false;
  }

  close() {
    this.dialogRef.close();
  }

}
