<!-- <body>
    <div style="width: 100%; background-color: white; bottom: 0;">
        <div class="alert alert-success" role="alert" id="success_alert" style="text-align: center;">
        </div>
        <div class="alert alert-danger" role="alert" id="error_alert" style="text-align: center;">
        </div>
    </div>

    <div id="sett_box" style="margin-bottom:30px; padding:25px;overflow-y: scroll; min-height:60px" class="panel-collapse collapse show">
        <label style="font-weight: bold;font-size:15px;">Enter Device Id:</label>
        <input id="dev_id" type="text" name="name" class="normalborder" style="background: white;color: black;margin-right:10px;" required />
        <label style="font-weight: bold;font-size:15px;">Enter AuthKey:</label>
        <input id="auth" type="text" name="name" class="normalborder" style="background: white;color: black; margin-right:10px;" required />
        <label style="font-weight: bold;font-size:15px;">Enter WSS1 URL:</label>
        <input id="wss1_url" type="text" name="name" class="normalborder" style="background: white;color: black;margin-right:10px;" required /><br>

        <div id="btn_div" style="margin-top: 30px;">
            <div class="row">
                <div class="col">
                </div>
                <div class="col-5">
                    <input id="start" class="btn btn-outline-primary" type="button" name="submit" style="margin:0px 5px 5px 50px;" value="Start" (click)="onStartClick()">
                </div>
                <div class="col">
                </div>
            </div>
        </div>
    </div>

    <div id="stop_box" class="row">
        <div class="col">
            <div id="timer" style="display: inline; float: left; margin-top: 10px;">
                <label style="font-weight: bold;font-size:15px; margin-right: 5px;">Time remaining:</label>
                <div id="two_countdown" style="margin-right: 60px; display: inline; padding: 5px; font-size: 15px; font-weight: bold;">
                    2:00
                </div>
            </div>
        </div>
        <div class="col-5">
            <input id="stop" class="btn btn-outline-danger" type="button" name="submit" style="margin:0px 5px 5px 10px;" value="Stop" (click)="onStopClick()">
        </div>
        <div class="col">
        </div>
    </div>

</body> -->

<div>
    
    <img (click)="close()" style="float: right; margin-bottom: 1rem;" src="assets/cancel.svg">
</div>

<iframe src="https://api.epicare.net/voice/?device_id=demo-api-000027-intg-gp-helyxon&auth_key=23d9b76f9393055b7f8491d73aad5faec75cd1352bd4e2b3cf9d95a32030380c&wss1_url=wss://test.alpha.phit.in/asr"  allow="camera; microphone"></iframe>