import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-drinout',
  templateUrl: './drinout.component.html',
  styleUrls: ['./drinout.component.scss']
})
export class drinoutsettingsComponent implements OnInit {

  currentProjectUrl;
  dmail;
  doc_id;
  token;
  
  constructor(private route: ActivatedRoute,private dom: DomSanitizer) {}
  
  ngOnInit() {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    this.currentProjectUrl = environment.HOST+'/ec1.0_settings/#/inandout?dr_id='+this.doc_id+'&dr_email='+this.dmail+'&auth_token='+this.token;
    this.currentProjectUrl = this.dom.bypassSecurityTrustResourceUrl(this.currentProjectUrl);
  }


}
