<p class="header text-center">{{data.header}}</p>

<div class="box" style="display: flex;">
    <p>Food</p>
    <!-- <input [(ngModel)]="food"> -->
    <table>
        <tr *ngFor="let f of foodArray; let i = index">
            <td><input placeholder="Type" maxlength="100"  (input)=deduct()  [(ngModel)]="f.name"></td>
            <td><input placeholder="Reaction"  maxlength="100"  (input)=deduct()  [(ngModel)]="f.reaction"></td>
            
            <td *ngIf="i!=foodArray.length-1" (click)="minusFood(i)"><img style="height: 1.5rem;cursor: pointer;" src="assets/minus.svg"></td>

            <td *ngIf="i==foodArray.length-1" (click)="addFood(i)"><img style="height: 1.5rem;cursor: pointer;" src="assets/plus.svg"></td>
        </tr>
    </table>
</div>

<div class="box" style="display: flex;">
    <p>Drug</p>
    <!-- <input [(ngModel)]="drug"> -->
    <table>
        <tr *ngFor="let d of drugArray; let i = index">
            <td><input placeholder="Type" (input)=deduct()  maxlength="100" [(ngModel)]="d.name"></td>
            <td><input placeholder="Reaction" (input)=deduct()   maxlength="100" [(ngModel)]="d.reaction"></td>
            
            <td *ngIf="i!=drugArray.length-1" (click)="minusDrug(i)"><img style="height: 1.5rem;cursor: pointer;" src="assets/minus.svg"></td>

            <td *ngIf="i==drugArray.length-1" (click)="addDrug(i)"><img style="height: 1.5rem;cursor: pointer;" src="assets/plus.svg"></td>
        </tr>
    </table>
</div>

<div class="box" style="display: flex;">
    <p>Environment</p>
    <!-- <input [(ngModel)]="environment"> -->
    <table>
        <tr *ngFor="let d of environmentArray; let i = index">
            <td><input placeholder="Type" (input)=deduct()   maxlength="100" [(ngModel)]="d.name"></td>
            <td><input placeholder="Reaction"  (input)=deduct()  maxlength="100" [(ngModel)]="d.reaction"></td>

            <td *ngIf="i!=environmentArray.length-1" (click)="minusEnvironment(i)"><img
                    style="height: 1.5rem;cursor: pointer;" src="assets/minus.svg"></td>

            <td *ngIf="i==environmentArray.length-1" (click)="addEnvironment(i)"><img
                    style="height: 1.5rem;cursor: pointer;" src="assets/plus.svg"></td>
        </tr>
    </table>
</div>

<div class="box" style="display: flex;">
    <p>Others</p>
    <!-- <input [(ngModel)]="others"> -->
    <table>
        <tr *ngFor="let d of othersArray; let i = index">
            <td><input placeholder="Type"  (input)=deduct()  maxlength="100" [(ngModel)]="d.name"></td>
            <td><input placeholder="Reaction"  (input)=deduct()  maxlength="100" [(ngModel)]="d.reaction"></td>

            <td *ngIf="i!=othersArray.length-1" (click)="minusOthers(i)"><img style="height: 1.5rem;cursor: pointer;"
                    src="assets/minus.svg"></td>

            <td *ngIf="i==othersArray.length-1" (click)="addOthers(i)"><img style="height: 1.5rem;cursor: pointer;"
                    src="assets/plus.svg"></td>
        </tr>
    </table>
</div>

<div style="display: flex;justify-content: space-around;">
    <button [disabled]="disabled" (click)="ok()" class="btn ok">Save</button>
    <button (click)="close()" class="btn cancel">Cancel</button>

</div>