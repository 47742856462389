<div class="Rtable Rtable--4cols">
    <div class="Rtable-cell"> <span>MR No :&nbsp;&nbsp;</span><span class="fontWeight">{{mrNo}}</span></div>
    <div class="Rtable-cell"> <span>&nbsp;&nbsp;</span></div>
    <div class="Rtable-cell"> <span>&nbsp;&nbsp;</span></div>
    <div class="Rtable-cell" style="text-align: right;"> <span style="margin-right: 10px;"><span>DOB :&nbsp;&nbsp;</span><span class="fontWeight">{{Dob | date: 'dd-MM-yyyy'}}</span></span></div>
</div>

    <div class="row" style="margin-top: 20px !important;" *ngIf="vital != 0 && (accountEmail == 'rx120049@rxcarenet.org' && doctorID == '2') || (accountEmail == 'rx4@rxcarenet.org' && doctorID == '7')">
        <div class="col-md-12">
                <div class="col-xs-12" style="padding: 0px;margin: 0px !important;border: 1px solid black;">
                    <div>
                        <table class="table" *ngIf="accountEmail != 'rx15@rxcarenet.org' && accountEmail != 'rx122340@rxcarenet.org'" style="margin-bottom: 1px !important;padding: 2px !important;">
                        <tbody>
                            <tr>
                                <td>Weight (kg)</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{weigth}}</td>
                                <td>BP</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{Bp}}</td>
                                <td>MAC</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{Mac}}</td>
                                <td>Heart Rate (bpm)</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{heartRate}}</td>
                                <td>Spo2 (%)</td>
                                <td style="font-weight:bold;">{{spo2}}</td>
                            </tr>
                            <tr>    
                                <td>Height (cm)</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{heigt}}</td>
                                <td>BMI:(k/m2)</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{BMI}}</td>
                                <td>HC</td>
                                <td style="font-weight:bold;border-right: 1px solid black;">{{Hc}}</td>
                                <td>Temperature ('f)</td>
                                <td style="font-weight:bold;border-right: 1px solid black;"> {{temprature}}</td>
                                <td>Respiratory Rate (RPM)</td>
                                <td style="font-weight:bold;">{{resRate}}</td> 
                            </tr>
                        </tbody>
                        </table>
                        <table class="table" *ngIf="accountEmail == 'rx15@rxcarenet.org' || accountEmail == 'rx122340@rxcarenet.org'" style="margin-bottom: 1px !important;padding: 2px !important;">
                            <tbody>
                                <tr>
                                    <td>Weight (kg)</td>
                                    <td style="font-weight:bold;border-right: 1px solid black;">{{weigth}}</td>
                                    <td>Height (cm)</td>
                                    <td style="font-weight:bold;border-right: 1px solid black;">{{heigt}}</td>
                                    <td>BMI:(k/m2)</td>
                                    <td style="font-weight:bold;border-right: 1px solid black;">{{BMI}}</td>
                                    <td>HC</td>
                                    <td style="font-weight:bold;border-right: 1px solid black;">{{Hc}}</td>
                                </tr>
                               </tbody>
                            </table>
                    </div>
                </div>
        </div>
    </div>
    <br>
    <div style="margin-left: 0px !important; margin-right: 0px !important;padding-top: 3px;border-top:0.5px solid;"></div>
    <div class="Rtable Rtable--3cols" style="margin-top:5px;">  
        <div class="Rtable-cell"> <span class="fontWeight">Chief Complaints :</span></div>
        <div class="Rtable-cell"> <span class="fontWeight">&nbsp;&nbsp;</span></div>
        <!-- <div class="Rtable-cell"> <span class="fontWeight" *ngIf="todayVaccine !=''">Today vaccines :</span> </div> -->
        <div class="Rtable-cell"> <span class="fontWeight">Advice :</span></div>
    </div>
    <div class="Rtable Rtable--3cols">  
        <div class="Rtable-cell">
            <div *ngIf="complaint != null">
                <table *ngFor="let compissues of complaintissues">
                    <thead style="font-weight: normal !important;"><td>{{compissues.complaint_category | titlecase}}</td></thead> 
                    <tbody *ngFor="let val in compissues.details">
                        <tr *ngFor="let val1 in val.answers.split('^~^')">
                            <td style="margin-top:-15px;">{{val1}}</td>
                        </tr>       
                    </tbody>                            
                </table>
            </div>
        </div>
        <div class="Rtable-cell">
            <table>
                <tbody>
                    <tr *ngFor="let todayvacc of todayVaccine">
                        <td style="margin-top:-15px;">{{todayvacc}}</td>
                    </tr>       
                </tbody>                            
            </table>
        </div>
        <div class="Rtable-cell"> 
            <div *ngIf="advice!=''&&advice!=undefined">{{advice}}</div>
        </div>
    </div>
    <div class="row" style="margin-top: 0px;">
        <div class="col-md-12" *ngIf="prescrDetails != null">
            <br>
            <label id="prescription" style="font-weight: bold;">Drug Prescription : </label>
                <table class="table" id="table_width">
                    <thead>
                        <tr>
                            <th style="border: 1px solid;">S.No.</th>
                            <th style="border: 1px solid;">Formulation. Drug Generic (Strength)</th>
                            <th style="border: 1px solid;">Route</th>
                            <th style="border: 1px solid;">Dosage</th>
                            <th style="border: 1px solid;">Frequency</th>
                            <th style="border: 1px solid;">Duration</th>
                            <th style="border: 1px solid;">Total</th>
                            <th style="border: 1px solid;">BF/AF</th>
                            <th style="border: 1px solid;">Special Instructions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let presdetails of prescrDetails; let i = index">
                            <td style="border: 1px solid; text-align: center;">{{i+1}} </td> 
                            <td style="border: 1px solid; text-align: center;">{{presdetails.formulation | titlecase}}. {{presdetails.brand | uppercase}} <span ng-if="presdetails.generic!=''"> - {{presdetails.generic | titlecase}}</span> ({{presdetails.strength}})</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.route}}</td>
                            <td style="border: 1px solid; text-align: center;"><span>{{presdetails.dose}}</span> {{presdetails.dose_option}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.frequency}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.duration}} {{presdetails.duration_type}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.quantity}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.when}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.special_instruction}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    <br>
    <div *ngIf="vaccionmsg!='No Vaccination due.'" class="col-xs-12" style="margin-left: 0px !important; margin-right: 0px !important;padding-top: 5px;border-top:0.5px solid;">
        <div class="center">
            <!-- <p style="margin-top: 5px !important;">Your <span style="font-weight:bold">next vaccine</span> is due on:<span style="font-weight:bold">{{vaccionDate}}</span> for <span style="font-weight:bold">{{vaccionmsg}}</span><br> -->
                <p style="margin-top: 5px !important;">Your <span style="font-weight:bold">next vaccine</span> is due on:<span>_____________________</span> for <span>_________________________________________</span><br>
                <span style="margin-left: 30px;margin-top: 20px;">To avoid last minute rush, please book your appointment in advance.</span></p>
        </div>
    </div>
    <div *ngIf="vaccionmsg == 'No Vaccination due.'" class="col-xs-12" style="margin-left: 0px !important; margin-right: 0px !important;padding-top: 3px;border-top:0.5px solid;">
        <div class="center">
            <p>{{vaccionmsg}}<br>
        </div>
    </div>
     <br> 
     <div class="row" style="margin-top: 5px;">
        <div class="col-md-12"> 
            <hr>
            <div class="row">
                <div class="col-md-12" style="margin-left: 12px !important; margin-right: 0px !important;">
                    <div class="row">
                        <div class="col-xs-4"></div>
                        <div class="col-xs-4"></div>
                        <div class="col-xs-4 pull-right" *ngIf="followupDate != null || followuptext != null">
                            <label><span style="font-weight: bold;">Follow Up :</span> &nbsp;&nbsp;<span>{{followupDate | date:'dd-MM-yy'}}</span>&nbsp;&nbsp;<span ng-if="followupDate&&followuptext!=''">{{followuptext | date:'dd-MM-yy'}}</span></label>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>

    <div class="subpage">
        <div class="row">
            <div class="col-md-12" style="margin-left: 12px !important; margin-right: 0px !important;">
                <div class="row">
                    <div *ngIf="findings!='' && findings!=undefined">
                        <label id="prescription" style="font-weight:bold">Findings</label><br>
                        <span>{{findings}}</span>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div *ngIf="diagnosis&&diagnosis!=''&&diagnosis!=undefined">
                        <label id="prescription" style="font-weight:bold">Provisional Diagnosis :</label><br>
                         <span>{{diagnosis}}</span>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div *ngIf="testadvised!=undefined && testadvised!=null && testadvised!=0">
                        
                        <div>
                            <label id="prescription" style="font-weight:bold">Test Advised :</label><br>
                            <span *ngFor="let test of testadvised; let i = index">{{i+1}}. {{test}}<br></span>
                            <span *ngIf="testadvised=='' || testadvised==null || testadvised==0">No Tests</span>
                        </div>
                </div>
            </div>
        </div>
    </div>

    
        
    