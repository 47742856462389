<div class="row">
    <div class="col-md-12">
        <div style="float:right;cursor: pointer;" (click)="onNoClick()"><i class="fa fa-close " ></i></div>
    </div>

    <div class="col-md-12" style="height: auto; text-align: center;margin-top: 10px;">
        <table class="table table-bordered">
            <thead style="background-color: #adccff">
                <tr>
                    <th class="text-center" style="width: 85px;">S.NO</th>
                    <th class="text-center" style="width: 85px;">Date</th>
                    <th class="text-center" style="width: 85px;">Term</th>
                    <th class="text-center" style="width: 85px;">Type</th>
                    <th class="text-center" style="width: 85px;">Baby Status</th>
                    <th class="text-center" style="width: 370px;">Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr style="border: rgb(0, 0, 0);" *ngFor="let data of viewparaDetails; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{data.delivery_date}}</td>
                    <td>{{data.pregnancy_term}}</td>
                    <td>{{data.delivery_type}}</td>
                    <td>{{data.baby_status}}</td>
                    <td style="text-align: center;">{{data.notes}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<style>
    .overflownotes {
    height: 135px;
    display: inline-block;
    width: 370px;
    overflow: auto;
}
</style>