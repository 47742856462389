<!-- <p>homevisit-followup works!</p> -->

<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row" style="margin-left: 5px;">
        <div class="col-md-6">
            <h2><img src="assets/images/home followup visit.svg" class="imgcustom"> <span class="headerClr"> Home Visit FollowUp</span></h2>
        </div>
        
    </div>

    <div class="row" style="margin-left: 5px;">
        <div class="col-md-8 col-lg-8 col-sm-8">  
        <div style="display:flex">
            <span style="margin-top: 3px;">Date :</span>
            <input style="margin-left: 10px;" type="text"
            ngxDaterangepickerMd
            [locale]="{format: 'DD-MM-YYYY'}"
            [(ngModel)]="selected" [ngModelOptions]="{standalone: true}"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="true"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            placeholder="Select Date please..."/>

            <button class="buttonOutside" style="width:100px;margin-left: 20px;" (click)="getReport()"><div class="buttonInside">Get Report</div></button>   
        </div>
        </div>
    </div>



    <div class="row" style="margin-top: 10px;">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <table >
                <thead style="text-align: center;">
                    <th style="font-weight: bolder;">S.no</th>
                    <th style="font-weight: bolder;">Agent Mobile</th>
                    <th style="font-weight: bolder;">Agent Name</th>
                    <th style="font-weight: bolder;">Agent Type</th>
                    <th style="font-weight: bolder;">Description</th>
                    <th style="font-weight: bolder;">Gender</th>
                    <th style="font-weight: bolder;">Lattitude</th>
                    <th style="font-weight: bolder;">Longitude</th>
                    <th style="font-weight: bolder;">Mr no</th>
                    <th style="font-weight: bolder;">Patient Mobile</th>
                    <th style="font-weight: bolder;">Patient Name</th>
                    <th style="font-weight: bolder;">Remarks</th>
                    <th style="font-weight: bolder;">Scheduled Date</th>
                    <th style="font-weight: bolder;">Visit No</th>
                </thead>
                <tbody>
                    <tr *ngFor="let record of resportArray;let i = index">
                        <td>{{i+1}}</td>
                        <td>{{record.agent_mobile}}</td>
                        <td>{{record.agent_name}}</td>
                        <td>{{record.agent_type}}</td>
                        <td>{{record.description}}</td>
                        <td>{{record.gender}}</td>
                        <td>{{record.lattitude}}</td>
                        <td>{{record.longitude}}</td>
                        <td>{{record.mrn}}</td>
                        <td>{{record.patient_mobile}}</td>
                        <td>{{record.patient_name}}</td>
                        <td >{{record.remarks}} <span *ngIf="record.remarks == null">-</span></td>
                        <td>{{record.scheduled_dt}}</td>
                        <td>{{record.visit_no}}</td>
                    </tr>
                </tbody>
            </table>
<hr>
            <div style="text-align:center;color:red" *ngIf="resportArray.length == 0">Data is not available....</div>

        </div>
    </div>

  </div>
</div>