<div style="display: flex;flex-direction: row;justify-content: space-between;font-size: 1.25rem;margin-bottom: 1rem;">
    <img src="assets/sticky notes.svg" >
    <p>Doctor Notes</p>
    <img (click)="close()" src="assets/cancel.svg">
</div>

<div *ngIf="block==1" style="height: 15rem;display: block;overflow-y: auto;">
    <div style="width: 100%;border: 3px solid #E9F2FF;border-radius: 0.5rem;margin-bottom: 0.5rem;padding: 0.5rem;" *ngFor="let d of noteList">
      <div style="display: flex;justify-content: space-between">
        <p class="notes"> {{d.patient_notes}} </p> <img src="assets/delete.svg" style="align-self: end;" (click)="remove(d.id,d.patient_notes)">
      </div>

      <p><b>Date  & Time: </b>{{d.created_at | date : 'dd-MMM-yyyy':'GMT+11'}} {{d.created_at | date : 'shortTime' :'GMT+11'}}</p>
    </div>

    <p style="font-size: 1rem;" *ngIf="noteList.length==0">No notes have been added for the patient</p>
</div>

<div *ngIf="block==2" style="height: 15rem;display: block;">

    <div style="display: flex;justify-content: space-between;">
        <p style="visibility: hidden;color: red;" [class.visible]="submitted&&newNote==''">Please enter a note</p>
        <button class="btn" [disabled]="disabled" (click)="save()">Save</button>
    </div>

    <textarea [(ngModel)]="newNote" style="height: 80%;margin-top: 2.5%;width: 100%;" maxlength="300"></textarea>
</div>

<div style="width: 100%;display: flex;margin-top: 1rem;">
    <div [class.inactive]="block==2"  [class.active]="block==1" (click)="setBlock(1)" style="width: 30%;font-size: 1rem;padding: 0.5rem;">
        <p class="text-center" >Notes</p>
    </div>

    <div [class.inactive]="block==1"  [class.active]="block==2" (click)="setBlock(2)" style="width: 30%;font-size: 1rem;padding: 0.5rem;">
        <p class="text-center">+ Create Note</p>
    </div>
</div>