<!------rx15 mangalabharthi prescription layout------>

<div [class.isPrinting]="printService.isPrinting" *ngIf="accountID == 'rx15@rxcarenet.org' && checkpath == 'prescription' || accountID == 'rx122340@rxcarenet.org' && checkpath == 'prescription'">
<table class="content">
  <thead><tr><td>
    <div class="header-space"></div>
  </td></tr></thead>
  <tbody>
    <tr>
      <td style="width:190px;"></td>
      <td>
        <div class="content">
        <router-outlet></router-outlet>
      </div>
    </td>
    </tr>
  </tbody>
  <tfoot><tr><td>
    <div class="footer-nextVisit-space"></div>
    <div class="footer-doctorsign"></div>
    <div class="footer-space"></div>
  </td></tr></tfoot>
</table>

<div class="header"></div>
<div class="footer-doctsign">
  <div class="pull-right" style="margin-top: 60px;">
    <div style="margin-left: 0px;">{{doctorName}}</div>
    <div>Reg.No.{{doctorID}}</div>
  </div>
</div>
<div class="footer-nextVisit-place">
  <div class="pull-right">
    <div style="margin-right: 40px;font-size:20px;font-weight: bold;">{{nextvisitDate}}</div>
  </div>
</div>
<div class="footer"></div>
</div>
<!---------End----------->

<!------mangalabharthi Cash Receipt layout------>
<div *ngIf="accountID == 'rx15@rxcarenet.org' && checkpath == 'cashReceipt' || accountID == 'rx122340@rxcarenet.org' && checkpath == 'cashReceipt'">
    <table class="content">
      <thead><tr><td>
        <div class="header-space"></div>
      </td></tr></thead>
      <tbody>
        <tr>
          <td style="width:190px;"></td>
          <td>
            <div class="content">
            <router-outlet></router-outlet>
          </div>
        </td>
        </tr>
      </tbody>
      <tfoot><tr><td>
        <div class="footer-space"></div>
      </td></tr></tfoot>
    </table>
    <div class="header"></div>
    <div class="footer"></div>
</div>

<!---------End----------->

<!------rx5016 eswarRaja prescription layout------>
<div *ngIf="accountID == 'rx5016@rxcarenet.org' || accountID == 'rx81621@rxcarenet.org'">
  
  <table class="rx5016_content">
    <thead>
      <tr><td>
        <div class="rx5016_header-space"></div>
      </td></tr>
    </thead>
    
    <tbody>
      <tr>
        <td style="width:0px;"></td>
        <td>
          <div class="rx5016_content">
          <router-outlet></router-outlet>
        </div>
      </td>
      </tr>
    </tbody>
    <tfoot><tr><td>
      <div class="rx5016_footer-doctorsign"></div>
      <div class="rx5016_footer-space"></div>
    </td></tr></tfoot>
  </table>
  
  <div class="rx5016_header"></div>
  
  <div class="rx5016_footer-doctsign">
    <div class="pull-right">
      <div style="margin-left: 0px;">{{doctorName}}</div>
      <div>Reg.No.{{doctorID}}</div>       
    </div>
  </div>
  <div class="rx5016_footer"></div>
</div>
  <!---------End----------->

  <!------rx4 obst&Gynec prescription layout------>

<div *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 3) || (accountID == 'rx136720@rxcarenet.org' && currentdoctID == 1)">
  <table class="obstGync_content">
    <thead>
      <tr><td>
        <div class="obstGync_header-space"></div>
      </td></tr>
    </thead>
    
    <tbody>
      <tr>
        <td style="width:0px;"></td>
        <td>
          <div class="obstGync_content">
          <router-outlet></router-outlet>
        </div>
      </td>
      </tr>
    </tbody>
    <tfoot><tr><td>
      <div class="obstGync_footer-doctorsign"></div>
      <div class="obstGync_footer-space"></div>
    </td></tr></tfoot>
  </table>
  
  <div class="obstGync_header"></div>
  
  <div class="obstGync_footer-doctsign">
    <div class="pull-right">
      <div style="margin-left: 0px;">{{doctorName}}</div>
      <div>Reg.No.{{doctorID}}</div>       
    </div>
  </div>
  <div class="obstGync_footer"></div>
</div>
  <!---------End----------->

   <!------Dr Vedha prescription layout------>

<div *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 1) || (accountID == 'rx4@rxcarenet.org' && currentdoctID == 7) 
|| (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 1) || (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 2)">
  <table class="DrVedha_content">
    <thead>
      <tr><td>
        <div class="DrVedha_header-space"></div>
      </td></tr>
    </thead>
    
    <tbody>
      <tr>
        <td style="width:380px;" *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 7) || (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 2)"></td>
        <td style="width:0px;" *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 1) || (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 1)"></td>
        <td>
          <div class="DrVedha_content">
          <router-outlet></router-outlet>
        </div>
      </td>
      </tr>
    </tbody>
    <tfoot><tr><td>
      <div class="DrVedha_footer-doctorsign"></div>
      <div class="DrVedha_footer-space"></div>
    </td></tr></tfoot>
  </table>
  
  <div class="DrVedha_header" style="border: 0px solid;" *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 7) || (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 2)">
    <table style="width:100%;margin-top: 29px;">
        <tr>
          <td style="width:430px;"></td>
          <td>
            <div class="Rtable Rtable--2cols">  
                <div class="Rtable-cell"><span>&nbsp;&nbsp;</span><span class="fontWeight"> </span></div>
                <div class="Rtable-cell" style="text-align: right;"> <span style="margin-right:30px;"><span></span><span class="fontWeight">{{todayDate}}</span></span></div>
            </div>
            <div class="Rtable Rtable--3cols" style="margin-top: 10px;">  
              <div class="Rtable-cell"><span style="margin-left: 30px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getpresprintname}}</span></span></div>
              <div class="Rtable-cell" style="text-align: right;"> <span style="margin-right: 0px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getpresprintage}} </span></span></div>
              <div class="Rtable-cell" style="text-align: right;"> <span style="margin-right: 5px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getpresprintgender | titlecase}}</span></span></div>
          </div>
        </td>
        </tr>
    </table>
  </div>

  <div class="DrVedha_header" style="border: 0px solid;" *ngIf="(accountID == 'rx4@rxcarenet.org' && currentdoctID == 1) || (accountID == 'rx120049@rxcarenet.org' && currentdoctID == 1)">
    <table style="width:100%;margin-top: 29px;">
        <tr>
          <td style="width:200px;"></td>
          <td>
            <div class="Rtable Rtable--3cols">  
                <div class="Rtable-cell" style="width: 50%;"><span style="margin-left: 20px;"><span>&nbsp;&nbsp;</span><span class="fontWeight"> {{getpresprintname}} </span></span></div>
                <div class="Rtable-cell" style="width: 25%;"><span style="margin-left: 13px;"><span>&nbsp;&nbsp;</span><span class="fontWeight"> {{getpresprintage}}</span></span></div>
                <div class="Rtable-cell" style="width: 25%;"> <span style="margin-left: 10px"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{todayDate}}</span></span></div>
            </div>
            <div class="Rtable Rtable--4cols" style="margin-top: 20px;">  
              <div class="Rtable-cell"><span style="margin-left: 13px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getpresprintgender | titlecase}} </span></span></div>
              <div class="Rtable-cell"> <span style="margin-left: 13px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getvitalweight}} kg</span></span></div>
              <div class="Rtable-cell"> <span style="margin-left: 13px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{getvitalheight}} cm</span></span></div>
              <div class="Rtable-cell"> <span style="margin-left: 30px;"><span>&nbsp;&nbsp;</span><span class="fontWeight">{{editvitaltemp}} °f</span></span></div>
          </div>
        </td>
        </tr>
    </table>
  </div>
  
  <div class="DrVedha_footer-doctsign">
    <div class="pull-right">
      <div style="margin-left: 0px;">{{doctorName}}</div>
      <div>Reg.No.{{doctorID}}</div>       
    </div>
  </div>
  <div class="DrVedha_footer"></div>
</div>
  <!---------End----------->


