
<div class="row">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <span>Pills</span>
                <table>
                    <thead class="tablehead" style="background-color: #E5F0FF;">
                        <tr style="height: 30px;">
                            <th style="border: 2px solid #c8e2ff;width: 6%;">S.no</th>
                            <th style="border: 2px solid #c8e2ff;width: 6%;">Name</th>
                            <th style="border: 2px solid #c8e2ff;width: 6%;">Strength (mg)</th>
                        </tr>
                    </thead>
                    <tbody class="tablebody" style="font-size: 15px; text-align: center;" *ngFor="let data of glucometer_pills" >
                        <tr style="height: 40px;">
                            <td style="border: 2px solid #e3edf8">{{data.position}}</td>
                            <td style="border: 2px solid #e3edf8">{{data.namepills}}</td>
                            <td style="border: 2px solid #e3edf8">{{data.strengthpills}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <span>Injection</span>
                <table>
                    <thead class="tablehead" style="background-color: #E5F0FF;">
                        <tr style="height: 30px;">
                            <th style="border: 2px solid #c8e2ff;width: 6%;">S.no</th>
                            <th style="border: 2px solid #c8e2ff;width: 6%;">Name</th>
                            <th style="border: 2px solid #c8e2ff;width: 6%;">Strength (ml)</th>
                        </tr>
                    </thead>
                    <tbody class="tablebody" style="font-size: 15px; text-align: center;" *ngFor="let element of glucometer_injection" >
                        <tr style="height: 40px;">
                            <td style="border: 2px solid #e3edf8">{{element.position}}</td>
                            <td style="border: 2px solid #e3edf8">{{element.nameInjection}}</td>
                            <td style="border: 2px solid #e3edf8">{{element.strengthInjection}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="margin-top: 4px;display: flex;justify-content: end;">
            <button class="btn btn-primary" (click) = "closepopuppicture()">Cancel</button>
        </div>
    </div>
</div>