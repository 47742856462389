<div class="container">

    <form [formGroup]="paraDetails" (submit)="save_paradetails()">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Pregnancy term</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <select class="form-control" style="width: 190px;font-size: 14px;" id="term"
                            name="Pregnancy term" formControlName="parapregterms" required>
                            <option value="" disabled>Select</option>
                            <option value="Term">Term</option>
                            <option value="Pre-Term">Pre-Term</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Delivery type</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <select class="form-control" style="width: 190px;font-size: 14px;" id="type"
                            formControlName="paradeliverytypes" name="Delivery type" required>
                            <option value="" disabled>Select</option>
                            <option value="Vaginal birth">Vaginal birth</option>
                            <option value="LSCS">LSCS</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Baby status</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <select class="form-control" style="width: 190px;font-size: 14px;" id="status"
                            formControlName="parababystatuses" name="Baby status" required>
                            <option value="" disabled>Select</option>
                            <option value="Live Birth">Live Birth</option>
                            <option value="Still birth">Still birth</option>
                            <option value="NND">NND (Neonatal death)</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Date of delivery</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <div class="input-group calendaralign" style="width: 190px;font-size: 14px;">
                            <input class="form-control" type="date" onkeypress="return event.charCode == 8" [max]="obsparamaxDate" formControlName="paradods"
                                style="font-size: 14px;" required />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <textarea class="form-control" maxlength="2000" name="" cols="30" rows="6" placeholder="Notes here"
                    formControlName="parageneralnotes"></textarea>
            </div>
        </div>
        <br><br>
        <div class="container" style="border: none;">
            <div class="row" style="text-align: center;">
                <div class="col-lg-12">
                    <button type="button" value="Cancel" (click)="openpopupforexit()"
                        class="btn btn-default">Cancel</button>&nbsp;
                    &nbsp; &nbsp; &nbsp;
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </form>
</div>