<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Mothers Antenatal Record</p>
        </div>
    </div>

    <div style="padding: 15px;margin-top:30px;float: right;">
      <button *ngIf="!editStatus&&getMotherData==''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-plus-circle" aria-hidden="true" style="color:#fff"></i> Create</button>
        <button *ngIf="!editStatus&&getMotherData!=''" class="btn btn-primary customButton" (click)="updateStatus();" style="color:#ffff"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color:#fff"></i> Update</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="cancelStatus();" style="color:#ffff"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
        <button *ngIf="editStatus" class="btn btn-primary customButton" (click)="validatemotherAntel();"
            style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>

    </div>
    <div style="margin-left: 30px;margin-top: 100px;">
        <hr class="hrStyle">
    <form [formGroup]="motherAntenatal" style="width:100%;">
      <div class="row" style="margin-top: 20px;">
         <div class="col-lg-6 col-md-6 col-lg-6">
              <div style="margin-top: 10px;">
                 <p> Where was the checkups done ? </p>
                 <p style="margin-top: 20px;"> 
                  <input *ngIf="editStatus" class="form-control dateInput" formControlName="checkups" maxlength="60" (keypress)="omit_special_char($event)">
                  <span *ngIf="!editStatus" class="ansText">{{getMotherData.check_up}}</span>
                  <span *ngIf="!editStatus&&getMotherData==''">-</span>
               </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div style="margin-top: 10px;">
                 <p> Where was the baby delivered ? </p>
                 <p style="margin-top: 20px;"> <input *ngIf="editStatus" class="form-control dateInput"
                    formControlName="babydelviery" maxlength="60" (keypress)="omit_special_char($event)">
                    <span *ngIf="!editStatus" class="ansText">{{getMotherData.delivery}}</span>
                    <span *ngIf="!editStatus&&getMotherData==''">-</span></p>
              </div>
            </div>
       </div>
    </form>
        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div style="width:99%;margin-top: 10px;">
                 <p>Did mother have diabetes ? </p>
                 <div style="width:99%;margin-top: 20px;">
                    <div>
                       <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="diabetes"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                       <span *ngIf="!editStatus" class="ansText">{{getMotherData.diabetes}}</span>
                       <span *ngIf="!editStatus&&getMotherData==''">-</span>
                    </div>
                 </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Did mother have HypoThyroidism? </p>
                 <div style="width:99%;margin-top: 20px;">
                    <div>
                       <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="hypoThyroidism"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                       <span *ngIf="!editStatus" class="ansText">{{getMotherData.hypo_thyroidism}}</span>
                       <span *ngIf="!editStatus&&getMotherData==''">-</span>
                    </div>
                 </div>
              </div>
            </div>
        </div>
        <div class="row" style="margin-top: 30px;">
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Did mother have FITS? </p>
                 <div style="width:99%;margin-top: 20px;">
                    <div>
                       <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="fits"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                       <span *ngIf="!editStatus" class="ansText">{{getMotherData.fits}}</span>
                       <span *ngIf="!editStatus&&getMotherData==''">-</span>
                    </div>
                 </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-lg-6">
              <div>
                 <p>Did mother have Urinary Infection? </p>
                 <div style="width:99%;margin-top: 20px;">
                    <div>
                       <mat-radio-group *ngIf="editStatus"
                          aria-labelledby="example-radio-group-label"
                          class="example-radio-group"
                          [color]="'primary'"
                          [(ngModel)]="urinaryInfection"
                          >
                          <mat-radio-button class="example-radio-button" [value]="0"  style="margin-right: 10px;">yes</mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="1" >No</mat-radio-button>
                       </mat-radio-group>
                       <span *ngIf="!editStatus" class="ansText">{{getMotherData.urinary_infection}}</span>
                       <span *ngIf="!editStatus&&getMotherData==''">-</span>
                    </div>
                 </div>
              </div>
            </div>
        </div>
     </div>
</div>


