<!-- <div style="display: flex;flex-direction: row;justify-content: space-between;font-size: 1.25rem;margin-bottom: 1rem;">
    <img style="visibility: hidden;" src="assets/sticky notes.svg" >
    <p style="visibility: hidden;">Lab Notes</p>
    <img (click)="close()" src="assets/cancel.svg">
</div> -->
<div style="width: 100%;">
    <img  style="float: right;margin-bottom: 0.5rem;cursor: pointer;" (click)="close()" src="assets/cancel.svg">

</div>


<table style="width: 100%;">
    <tr  style="background-color: #E5F0FF;text-align: center;"  class="tableheader">
        <!-- <th>S. No</th> -->
        <th>Date</th>
        <th>Complaint</th>
        <th>Diagnosis</th>
        <th>Medicine Given</th>
    </tr>

    <tr style="border-bottom: 2px dotted #e3edf8;text-align: center;">
        <td>{{data.data.date}}</td>
        <td>{{data.data.complaintString}}</td>
        <td>{{diagnosisString}}</td>
        <td>
            <p *ngFor="let d of data.data.drugs;let j=index">{{j+1}}. {{d.formulation}} - {{d.generic_name}} ({{d.brand_name}})</p>
        </td>

    </tr>
</table>