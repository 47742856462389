import { Component, Inject, Input, OnInit, SimpleChanges, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Output, EventEmitter } from '@angular/core';
import { VoiceRecognitionService } from '../wrapper/service/voice-recognition.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PubNubAngular } from 'pubnub-angular2';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Toast, ToastrService } from 'ngx-toastr';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker'
import { DatePipe } from '@angular/common';
//import * as $ from 'jquery';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "DD-MMM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY"
  }
}

export interface DialogData_Access {
  obj: any
}

export interface DialogData_Confirm {
  name: any
}
export interface DialogData_Carenet {
  name: any;
  patient_email:any;
}
export interface DialogData_Addnewcarenet {
  name: any;
  obj:any;
  patient_email:any;
  status:any;
}
export interface DialogData_growthchart {
  name: any
}

export interface DialogData_vital {
  apptId: number;
  recordId: number;
}

export interface DialogData_Allergy {
  header: any,
  food: any,
  drug: any,
  environment: any,
  others: any,
  exists: any
}

export interface DialogData_chronic {
  chronic: any,
  dmail: any,
  email: any
}

export interface DialogData_chronic2 {
  chronic: any,
  dmail: any,
  email: any
}

export interface DialogData_details {
  profile: any,
  url: any,
  dmail: any,
  token: any,
  email: any,
  bday: any
}

//DialogData_details

//DialogData_Allergy
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() patient;
  @Input() token;
  @Input() imgURL;
  @Input() dmail;
  @Input() text;
  @Input() id;
  @Input() phone;
  @Input() bday;
  @Input() drName;
  @Input() mr;
  @Input() email;
  @Input() name;
  @Input() arrived;
  @Input() drID;
  @Input() started;
  @Input() muted;
  @Input() full;
  @Input() clear;
  @Input() fullscrImgSrc;

  @Input() lmp_update;

  @Input() openAllergies;
  @Input() unseenLength;
  @Input() update;

  @Input() allergyClicked;
  @Input() apptId;
  @Input() changeVitals; //vitalOpened
  @Input() vitalOpened;
  @Input() row;
  @Input() status;
  @Input() appt_type;
  @Input() vitalchangestatus;
  @Input() speciality;

  private subscriptionName: Subscription;

  @Output() someEvent = new EventEmitter<Boolean>();
  @Output() someEvent_key = new EventEmitter<Boolean>();
  @Output() someEvent_enter = new EventEmitter<any>();
  @Output() someEvent_input = new EventEmitter<any>();
  @Output() someEvent_full = new EventEmitter<any>();
  @Output() someEvent_join = new EventEmitter<any>();


  @Output() someEvent_allergy = new EventEmitter<any>();
  @Output() someEvent_lab = new EventEmitter<any>();
  @Output() someEvent_openvacc = new EventEmitter<any>();
  @Output() someEvent_changestatus = new EventEmitter<any>();
  //someEvent_payment
  @Output() someEvent_payment = new EventEmitter<any>();

  @Output() updatevitall = new EventEmitter<any>();

  @Output() openVaccination = new EventEmitter<any>();


  config = AppConfig.settings;
  constants = AppConfig.constants;
  urlPoints: any;
  profile;
  y;
  m;
  arrival;
  last_visit;
  last_login;
  url;
  //muted = true;
  muteButton;
  supported;
  mobile_access;
  pubnub: PubNubAngular;
  osName;
  obspregrisksprofile: any;
  obsteddprofile: any;
  showeddpregrisk: boolean;
  allergyExists = false
  allergyData: any = {};
  readStatus
  newReport;
  allergyExists1 = false
  nextDate;
  lmp;
  chronicData;
  chronicDataExists = [];
  documentId;
  last_vaccine;
  last_vaccine_date

  next_vaccine;
  next_vaccine_date;

  height;
  heightInput;
  weight;
  temperature;
  headC;
  bmi;
  heart_rate;
  respiratory_rate;
  spo2;
  blood_pressure;
  danger = true;
  bsa;
  dob;
  abhaDetails: any = {};
  abha = false;
elite=[];
    constructor(private appService: AppService, pubnub: PubNubAngular, public dialog: MatDialog, public service: VoiceRecognitionService, private toastr: ToastrService, private elementRef: ElementRef) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;

    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      if (this.getOS() != 'iOS' && this.getOS() != "Mac OS") {
        this.service.init();
        this.supported = true
      }
      else {
        this.supported = false
      }

    } else {
      this.supported = false
    }

  }

  ngOnInit(): void {

    this.muteButton = 'assets/mute.svg'
    this.pubnub1();
    // alert(this.speciality)

    //  alert(this.status)

    this.get_currentpregRiskdetailsprofile()
    this.get_obsthistoryprofile()
    this.get_obsthistoryprofile1()
    this.checkAllergy()
    this.checkNotes()
    this.checkChronic()
    this.checkVaccine()
    this.defaultVitalDatas();
    this.getpatientcarenettags();
    //this.getVitalData();
    //  this.rightToggle()
    this.appService.getQueue(this.urlPoints.checkValidity + '?' + this.dmail + '&' + this.id, this.token).subscribe((response: any) => {
      if (response.access == 'mobile access valid') {
        this.mobile_access = true;
      }
      else {
        this.mobile_access = false
      }
    });

    this.getAbha()
  }
  getpatientcarenettags(){
    let params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.drID,
      patient_account: this.email,
      page_number: 1
    }

    this.appService.getData(this.urlPoints.getpatientcarenettags, params, this.token).subscribe((response: any) => {
      if (response.success == 1) {
        let msg = response.message;
        let localArr = [];
        for (var i = 0; i < msg.length; i++) {
          localArr.push(msg[i].tag);
        }
        if(localArr.length > 2){
          this.elite = localArr.slice(0,2);
        }else{
          localArr.push('CARENET');
          this.elite = localArr.slice(0,2);
        }
        console.log("localArr:::",localArr)
        console.log("this.elite:::",this.elite)
      }
    });
  }

  changeStatus() {
    this.someEvent_changestatus.next();
  }
  
  docmentApi(){
    if(this.heightInput == 'NaN'){
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog_growthchart,{
        width:'50%',
        
      })
      dialogRef.afterClosed().subscribe(result => {
console.log("result",result)
this.defaultVitalDatas();
      })

    }else{
      console.log("docmentApi")
    }
   
  }

  rightToggle() {
    $("#sidebar-wrapper2").addClass("active");
  }


  join() {
    this.someEvent_join.next();

  }

  closeToggle() {
    $("#sidebar-wrapper2").removeClass("active");
  }

  openVaccine() {

    this.someEvent_openvacc.next();

  }

  payment() {
    this.someEvent_payment.next();
  }

  checkVaccine() {
    var params = {
      account_email: this.dmail,
      patient_email: this.email,
      dob: this.bday
    }

    var today = moment(new Date()).format('YYYY-MM-DD')

    this.appService.getData(this.urlPoints.checkVaccineDate, params, this.token).subscribe((response: any) => {

      var date;

    })

    var params_vaccineDate = {
      account_email: this.dmail,
      patient_email: this.email,
    }

  }

  get_currentpregRiskdetailsprofile() {
    let data = {
      "account_email": this.dmail,
      "patient_email": this.email
    }
    this.appService.getData(this.urlPoints.getrisk, data, this.token).subscribe((response: any) => {

      this.obspregrisksprofile = response.data[0].risk_to


      this.subscriptionName = this.appService.getCpRiskData().subscribe
        (message => { //message contains the data sent from service
          this.obspregrisksprofile = message.text;
        });
    })

  }
careNet(){
  const dialogRef = this.dialog.open(Carenet,{
    data: { patient_email: this.email },
    panelClass: 'custom-modalbox'
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    // if(result){
      this.getpatientcarenettags();
    // }
  })
}
  openDetails() {

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_details, {
      width: '600px',
      data: { url: this.url, profile: this.profile, dmail: this.dmail, token: this.token, bday: this.bday, email: this.email }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        // alert()
        // this.openVaccination.next();
        this.openVaccine()

      }
    });
  }

  defaultVitalDatas() {

    var params = {
      account_email: this.dmail,
      patient_email: this.email
    }

    this.appService.getData(this.urlPoints.getShortProfile, params, this.token).subscribe((response: any) => {
      console.log(response.data.vitals)

      console.log(response.success)

      if (response.success == 1) {

        console.log(response.data.vitals)

        this.heart_rate = response.data.vitals.heart_rate

        this.respiratory_rate = response.data.vitals.respiratory_rate

        if (response.data.vitals.bp_systolic && response.data.vitals.bp_diastolic) {
          this.blood_pressure = response.data.vitals.bp_systolic.bp_systolic + '/' + response.data.vitals.bp_systolic.bp_diastolic;
        }

        this.weight = response.data.vitals.weight
        console.log(this.weight);
        this.height = response.data.vitals.height * 100
        this.heightInput = response && response.data && response.data.vitals && response.data.vitals.height ? response.data.vitals.height * 100 : "NaN"
        this.temperature = response.data.vitals.temperature
        this.bmi = response.data.vitals.bmi
        this.headC = response.data.vitals.head_circumference


        this.heart_rate = response.data.vitals.heart_rate;
        this.respiratory_rate = response.data.vitals.respiratory_rate;
        this.spo2 = response.data.vitals.oxygen_saturation;

        this.bsa = ((0.007184 * Math.pow(Number(this.weight), 0.425) * (Math.pow((Number(this.height) / 100), 0.725))).toFixed(3))

      }
    });
    // let params = {
    //   account_email: this.dmail,
    //   appointment_id: this.apptId
    // }

    // this.appService.getData(this.urlPoints.appointmentvitalsdata, params, this.token).subscribe((response: any) => {

    //   if(response.vitals[0]) {
    //      this.documentId = response.vitals[0].vitals;

    //      let vitalsDataResponse = response.vitals[0];

    //      this.heart_rate = vitalsDataResponse.heart_rate;
    //       this.respiratory_rate = vitalsDataResponse.respiratory_rate;
    //       this.spo2 = vitalsDataResponse.oxygen_saturation;

    //   if (vitalsDataResponse.bp_systolic && vitalsDataResponse.bp_diastolic)
    //     this.blood_pressure = vitalsDataResponse.bp_systolic + '/' + vitalsDataResponse.bp_diastolic;

    //       this.weight = vitalsDataResponse.weight;
    //       this.height = vitalsDataResponse.height;

    //       this.temperature = vitalsDataResponse.temperature

    //       this.bmi = (vitalsDataResponse.weight / (vitalsDataResponse.height * vitalsDataResponse.height)).toFixed(2)

    //       this.bsa = ((0.007184 * Math.pow(Number(this.weight), 0.425) * (Math.pow((Number(this.height) / 100), 0.725))).toFixed(3))

    //       this.updatevitall.next();
    //   }
    //   else
    //   {
    //     this.weight = null
    //     this.height = null
    //     this.temperature = null
    //     this.bmi = null
    //     this.bsa = null
    //   }
    // });

  }
  openLab() {

    this.someEvent_lab.next()

  }

  checkNotes() {

    var params = {
      account_email: this.dmail,
      patient_email: this.email,
      doctor_id: this.drID

    }

    this.appService.getData(this.urlPoints.newLabNotes, params, this.token).subscribe((response: any) => {
      this.newReport = response.new_reports
    })
  }

  toggle_full() {
    this.someEvent_full.next();
  }

  input(event) {
    this.someEvent_input.next(event.target.value)
  }

  get_obsthistoryprofile1() {
    let data = {
      "account_email": this.dmail,
      "patient_email": this.email
    }
    this.appService.getData(this.urlPoints.getobsthistory, data, this.token).subscribe((response: any) => {

      if (response.data[0]) {
        this.obsteddprofile = response.data[0].expected_delivery
        this.lmp = response.data[0].lmp
      }
    });
  }

  get_obsthistoryprofile() {
    let data = {
      "account_email": this.dmail,
      "patient_email": this.email
    }
    this.appService.getData(this.urlPoints.getobsthistory, data, this.token).subscribe((response: any) => {

      //   this.get_obsthistoryprofile1() 

      this.subscriptionName = this.appService.getObsteddData().subscribe
        (message => {
          this.obsteddprofile = message.text;
        });

    });
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    this.osName = os

    return os;
  }

  ngOnChanges(changes: SimpleChanges) {


    var that = this

    if (this.status == 'End Consultation') {
      setInterval(function () {

        that.danger = !that.danger


      }, 1000);
    }

    if (changes.patient) {

      var params = {
        'account_email': this.dmail,
        'mr_number': changes.patient.currentValue
      }

      var token = this.token;
      this.getPatient(this.urlPoints.getPatient, params, token);
      this.checkAllergy()
      this.get_obsthistoryprofile1()
      this.checkVaccine()
      this.defaultVitalDatas()
    }

    if (changes.apptId) {
      this.defaultVitalDatas();

    }

    if (changes.vitalchangestatus) {
      this.defaultVitalDatas();
    }

    if (changes.update) {
      var params = {
        'account_email': this.dmail,
        'mr_number': this.patient
      }

      var token = this.token;
      this.getPatient(this.urlPoints.getPatient, params, token);

      this.checkVaccine()
    }

    if (changes.changeVitals && this.vitalOpened) {
      this.vitals()
    }

    //lmp_update
    if (changes.lmp_update) {
      // alert()
      this.get_currentpregRiskdetailsprofile()
      this.get_obsthistoryprofile1()
      this.checkAllergy()

    }

    if (changes.openAllergies) {

      if (this.allergyClicked)
        this.allergy()

    }

    if (changes.clear) {
      this.text = ''
    }

    if (this.muted) {
      this.muteButton = 'assets/mute.svg'
    }
    else {
      this.muteButton = 'assets/unmute.svg'
    }

    var params1 = this.dmail + '/'

    //alert()

    this.appService.getQueue(this.urlPoints.checkValidity + '?' + this.dmail + '&' + this.id, this.token).subscribe((response: any) => {
      if (response.access == 'mobile access valid') {
        this.mobile_access = true;
      }
      else {
        this.mobile_access = false
      }
    });

  }



  enter() {
    this.someEvent_enter.next(this.text);
  }

  pubnub1() {
    var account_id = this.dmail
    var channel = account_id.replace('@', '_').replace('.org', '');
    var uuid = account_id
    var doctor_id = this.drID

    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
    });

    this.pubnub.addListener({
      message: msg => {
      }
    })

    this.pubnub.getMessage([channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id], (msg) => {

      if (this.profile) {
        // this.appService.getQueue(this.urlPoints.getArrival + '?' + this.dmail + '/' + this.profile.record_id, this.token).subscribe((response: any) => {
        //   this.arrival = response.arrival_time

        //   if (this.arrival)
        //     this.arrival = moment(this.arrival).add(5.5, 'hours').format('DD-MM-YYYY hh:mm A');

        //   if (response.arrival_time == "None")
        //     this.arrival = ""
        // });
      }
    });

    this.pubnub.getStatus([channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id], (msg) => {
    });

    this.pubnub.getPresence([channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id], (msg) => {
    });
  }

  getPatient(url, params, token) {
    this.appService.postData(url, params, token).subscribe((response: any) => {
      this.profile = response[0];

      if (this.profile.gender == 'male') {
        this.showeddpregrisk = false
      } else if (this.profile.gender == 'female') {
        if ((this.dmail == 'rx4@rxcarenet.org' && this.drID == '3') || (this.dmail == 'rx29079@rxcarenet.org' && this.drID == '9')) {
          this.showeddpregrisk = true
        } else {
          this.showeddpregrisk = false
        }
      }


      sessionStorage.setItem("patientprofile", JSON.stringify(this.profile));
      var params1 = this.dmail + '/' + this.profile.record_id
      var params2 = {
        account_email: this.dmail,
        patient: this.profile.email
      }

      if (this.profile.gender == 'male') {
        this.profile.gender = 'Male'
      }
      else {
        this.profile.gender = 'Female'
      }

      if (this.imgURL != 'No profile picture' && this.imgURL != '' && this.imgURL != null && this.imgURL != 'null') {
        this.url = environment.HOST + '/helyxonapi/cloud/image/upload/' + this.imgURL;
      }

      else {

        if (this.profile.gender == 'Male') {
          this.url = 'assets/avatar-male.png'
        }
        else {

          this.url = 'assets/avatar-female.png'
        }
      }

      var a = this.profile.bday;
      this.dob = moment(this.profile.bday).format("DD-MMM-YYYY");
      console.log(this.dob)
      var b = moment(new Date()).format("YYYY-MM-DD");

      var a_arr = a.split('-')
      var b_arr = b.split('-')

      var x = moment([b_arr[0], Number(b_arr[1]) - 1, b_arr[2]]);
      var y = moment([a_arr[0], Number(a_arr[1]) - 1, a_arr[2]]);

      this.y = moment(x).diff(moment(y), 'year')
      y.add('years', this.y)
      this.m = moment(x).diff(moment(y), 'months')
      sessionStorage.setItem("dob", this.y + "Y" + " " + this.m + "M")

    });
  }

  toggle() {
    this.muted = !this.muted
    if (this.muted) {
      this.muteButton = 'assets/mute.svg'
      this.someEvent.next(false);

    }
    else {
      this.muteButton = 'assets/unmute.svg'
      this.someEvent.next(true);

    }
  }

  toggle_key() {
    //someEvent_key
    this.someEvent_key.next();
  }

  confirm() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_confirm, {
      width: '600px',
      data: { name: this.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        var params = {
          cell_number: this.phone,
          acc_email: this.dmail,
          record_id: this.id,
          dr_name: this.drName,
          mr: this.mr,
          patient_account_email: this.email,
        }

        this.appService.postData(this.urlPoints.sendCred, params, this.token).subscribe((response: any) => {

          this.appService.getQueue(this.urlPoints.checkValidity + '?' + this.dmail + '&' + this.id, this.token).subscribe((response: any) => {
            if (response.access == 'mobile access valid') {
              this.mobile_access = true;
            }
            else {
              this.mobile_access = false
            }
          });


          const dialogRef = this.dialog.open(DialogOverviewExampleDialog_access, {
            width: '600px',
            data: { obj: response }


          });


        });
      }
    })
  }

  resend() {

    var params = {
      cell_number: this.phone,
      acc_email: this.dmail,
      record_id: this.id,
      dr_name: this.drName,
      bday: this.bday + "T00:00:00.000Z",
      mr: this.mr,
      patient_account_email: this.email,
      reset: 0
    }


    this.appService.postData(this.urlPoints.resend, params, this.token).subscribe((response: any) => {

      const dialogRef = this.dialog.open(DialogOverviewExampleDialog_access, {
        width: '600px',
        data: { obj: response }
      });
    });
  }

  checkAllergy() {

    var params = {
      account_email: this.dmail,
      patient_email: this.email
    }

    this.appService.getData(this.urlPoints.getAllergiesReaction, params, this.token).subscribe((response: any) => {

      if (response.data[0].new_allergies) {
        this.readStatus = 0
      }
      else {
        this.readStatus = 1
      }
      this.someEvent_allergy.next()

      this.allergyData.allergy = {}

      if (response.data[0].drug.length > 0 || response.data[0].environment.length > 0 || response.data[0].food.length > 0 || response.data[0].others.length > 0) {
        this.allergyExists = true
        this.allergyExists1 = true
        this.allergyData.drug = response.data[0].drug
        this.allergyData.food = response.data[0].food
        this.allergyData.environment = response.data[0].environment
        this.allergyData.others = response.data[0].others
        this.allergyData.allergy = {}
      }
      else {
        this.allergyData.drug = []
        this.allergyData.food = []
        this.allergyData.environment = []
        this.allergyData.others = []

        this.allergyExists = false
        this.allergyExists1 = false
      }

    });
  }

  checkChronic() {
    var params = {
      account_email: this.dmail,
      patient_email: this.email
    }

    this.appService.getData(this.urlPoints.getChronic, params, this.token).subscribe((response: any) => {
      this.chronicData = response.data

      this.chronicDataExists = []

      for (var i = 0; i < this.chronicData.length; i++) {
        if (this.chronicData[i].list.length > 0) {

          for (var j = 0; j < this.chronicData[i].list.length; j++) {
            if (this.chronicData[i].list[j].status == 1) {
              var obj = {
                name: this.chronicData[i].disease_type,
                name1: this.chronicData[i].list[j].name,
                value: this.chronicData[i].list[j].textbox == 1 ? this.chronicData[i].list[j].textbox_value : null
              }
              this.chronicDataExists.push(obj)
            }
          }


        }
        else {
          if (this.chronicData[i].status == 1) {
            var obj1 = {
              name: this.chronicData[i].disease_type,
              value: this.chronicData[i].textbox == 1 ? this.chronicData[i].textbox_value : null
            }
            this.chronicDataExists.push(obj1)

          }
        }
      }

    });
  }

  read() {


    var data = []

    var obj: any


    if (this.allergyData.drug)
      for (var i = 0; i < this.allergyData.drug.length; i++) {
        obj = {}
        obj.id = this.allergyData.drug[i].id
        obj.name = this.allergyData.drug[i].name
        obj.category = this.allergyData.drug[i].category
        obj.reaction = this.allergyData.drug[i].reaction
        obj.doctor_read_status = 1

        data.push(obj)
      }

    if (this.allergyData.food)
      for (var i = 0; i < this.allergyData.food.length; i++) {
        obj = {}
        obj.id = this.allergyData.food[i].id
        obj.name = this.allergyData.food[i].name
        obj.category = this.allergyData.food[i].category
        obj.reaction = this.allergyData.food[i].reaction
        obj.doctor_read_status = 1

        data.push(obj)
      }

    if (this.allergyData.environment)
      for (var i = 0; i < this.allergyData.environment.length; i++) {
        obj = {}
        obj.id = this.allergyData.environment[i].id
        obj.name = this.allergyData.environment[i].name
        obj.category = this.allergyData.environment[i].category
        obj.reaction = this.allergyData.environment[i].reaction
        obj.doctor_read_status = 1

        data.push(obj)
      }

    if (this.allergyData.others)
      for (var i = 0; i < this.allergyData.others.length; i++) {
        obj = {}
        obj.id = this.allergyData.others[i].id
        obj.name = this.allergyData.others[i].name
        obj.category = this.allergyData.others[i].category
        obj.reaction = this.allergyData.others[i].reaction
        obj.doctor_read_status = 1

        data.push(obj)
      }

    var updateParams = {
      account_email: this.dmail,
      patient_email: this.email,
      data: data
    }

    this.appService.postData(this.urlPoints.createAllergy, updateParams, this.token).subscribe((response: any) => {
      this.checkAllergy()
    });

  }



  vitals() {

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_vital, {
      width: '75%',
      data: { recordId: this.id, apptId: this.apptId, patient_email: this.email }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
      // this.getVitalData();
      var params = {
        account_email: this.dmail,
        patient_email: this.email
      }
  
      this.appService.getData(this.urlPoints.getShortProfile, params, this.token).subscribe((response: any) => {
        console.log(response.data.vitals)
  
        console.log(response.success)
  
        if (response.success == 1) {
  
          console.log(response.data.vitals)
  
          this.heart_rate = response.data.vitals.heart_rate
  
          this.respiratory_rate = response.data.vitals.respiratory_rate
  
          if (response.data.vitals.bp_systolic && response.data.vitals.bp_diastolic) {
            this.blood_pressure = response.data.vitals.bp_systolic.bp_systolic + '/' + response.data.vitals.bp_systolic.bp_diastolic;
          }
  
          this.weight = response.data.vitals.weight
          console.log(this.weight);
          this.height = response.data.vitals.height * 100
          this.temperature = response.data.vitals.temperature
          this.bmi = response.data.vitals.bmi
  
  
          this.heart_rate = response.data.vitals.heart_rate;
          this.respiratory_rate = response.data.vitals.respiratory_rate;
          this.spo2 = response.data.vitals.oxygen_saturation;
  
          this.bsa = ((0.007184 * Math.pow(Number(this.weight), 0.425) * (Math.pow((Number(this.height) / 100), 0.725))).toFixed(3))
  
        }
      });

      this.updatevitall.next();
    });

  }






  async allergy() {

    this.read()

    var food = ''
    var drug = ''
    var environment = ''
    var others = ''

    if (this.allergyExists) {
      food = this.allergyData.food
      drug = this.allergyData.drug
      environment = this.allergyData.environment
      others = this.allergyData.others
    }

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_allergy, {
      width: 'fit-content',
      data: { header: !this.allergyExists ? 'Add Allergy' : 'Edit Allergy', food: food, drug: drug, environment: environment, others: others, exists: this.allergyExists }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {

        var newData = []
        var deleteData = []
        var updateData = []

        var obj;

        for (var i = 0; i < result.newDrugArray.length; i++) {
          obj = {
            name: result.newDrugArray[i].name,
            category: 'drug',
            reaction: result.newDrugArray[i].reaction != '' ? result.newDrugArray[i].reaction : 'N/A',
          }
          newData.push(obj)
        }

        for (var i = 0; i < result.deleteDrugArray.length; i++) {
          obj = {
            name: result.deleteDrugArray[i].name,
            category: 'drug',
            reaction: result.deleteDrugArray[i].reaction,
            id: result.deleteDrugArray[i].id,
            is_active: 0
          }
          deleteData.push(obj)
        }

        for (var i = 0; i < result.drugArray.length; i++) {
          if (result.drugArray[i].name != '') {
            obj = {
              name: result.drugArray[i].name,
              category: 'drug',
              reaction: result.drugArray[i].reaction != '' ? result.drugArray[i].reaction : 'N/A',
              id: result.drugArray[i].id,
            }
            updateData.push(obj)
          }
        }

        for (var i = 0; i < result.newFoodArray.length; i++) {
          obj = {
            name: result.newFoodArray[i].name,
            category: 'food',
            reaction: result.newFoodArray[i].reaction != '' ? result.newFoodArray[i].reaction : 'N/A',

          }
          newData.push(obj)
        }

        for (var i = 0; i < result.deleteFoodArray.length; i++) {
          obj = {
            name: result.deleteFoodArray[i].name,
            category: 'food',
            reaction: result.deleteFoodArray[i].reaction,
            id: result.deleteFoodArray[i].id,
            is_active: 0
          }
          deleteData.push(obj)
        }

        for (var i = 0; i < result.foodArray.length; i++) {
          if (result.foodArray[i].name != '') {
            obj = {
              name: result.foodArray[i].name,
              category: 'food',
              reaction: result.foodArray[i].reaction != '' ? result.foodArray[i].reaction : 'N/A',

              id: result.foodArray[i].id,
            }
            updateData.push(obj)
          }
        }

        for (var i = 0; i < result.newEnvironmentArray.length; i++) {
          obj = {
            name: result.newEnvironmentArray[i].name,
            category: 'environment',
            reaction: result.newEnvironmentArray[i].reaction != '' ? result.newEnvironmentArray[i].reaction : 'N/A',

          }
          newData.push(obj)
        }

        for (var i = 0; i < result.deleteEnvironmentArray.length; i++) {
          obj = {
            name: result.deleteEnvironmentArray[i].name,
            category: 'environment',
            reaction: result.deleteEnvironmentArray[i].reaction,
            id: result.deleteEnvironmentArray[i].id,
            is_active: 0
          }
          deleteData.push(obj)
        }

        for (var i = 0; i < result.environmentArray.length; i++) {
          if (result.environmentArray[i].name != '') {
            obj = {
              name: result.environmentArray[i].name,
              category: 'environment',
              reaction: result.environmentArray[i].reaction != '' ? result.environmentArray[i].reaction : 'N/A',

              id: result.environmentArray[i].id,
            }
            updateData.push(obj)
          }
        }

        for (var i = 0; i < result.newOthersArray.length; i++) {
          obj = {
            name: result.newOthersArray[i].name,
            category: 'others',
            reaction: result.newOthersArray[i].reaction != '' ? result.newOthersArray[i].reaction : 'N/A',

          }
          newData.push(obj)
        }

        for (var i = 0; i < result.deleteOthersArray.length; i++) {
          obj = {
            name: result.deleteOthersArray[i].name,
            category: 'others',
            reaction: result.deleteOthersArray[i].reaction,
            id: result.deleteOthersArray[i].id,
            is_active: 0
          }
          deleteData.push(obj)
        }

        for (var i = 0; i < result.othersArray.length; i++) {
          if (result.othersArray[i].name != '') {
            obj = {
              name: result.othersArray[i].name,
              category: 'others',
              reaction: result.othersArray[i].reaction != '' ? result.othersArray[i].reaction : 'N/A',

              id: result.othersArray[i].id,
            }
            updateData.push(obj)
          }
        }

        if (newData.length > 0) {
          var newParams = {
            account_email: this.dmail,
            patient_email: this.email,
            data: newData
          }

          await this.appService.postData(this.urlPoints.createAllergy, newParams, this.token).subscribe((response: any) => {
            if (updateData.length == 0 && deleteData.length == 0) {
              this.toastr.success("Allergy saved!")

            }
          });
        }

        if (deleteData.length > 0) {
          var deletParams = {
            account_email: this.dmail,
            patient_email: this.email,
            data: deleteData
          }

          await this.appService.postData(this.urlPoints.createAllergy, deletParams, this.token).subscribe((response: any) => {
            if (updateData.length == 0) {
              this.toastr.success("Allergy saved!")

            }
          });
        }

        if (updateData.length > 0) {
          var updateParams = {
            account_email: this.dmail,
            patient_email: this.email,
            data: updateData
          }

          await this.appService.postData(this.urlPoints.createAllergy, updateParams, this.token).subscribe((response: any) => {
            this.toastr.success("Allergy saved!")

          });
        }

        this.checkAllergy()

      }
    })
  }

  openChronic() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_chronic, {
      width: '600px',
      data: { chronic: this.chronicData, email: this.email, dmail: this.dmail }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appService.postData(this.urlPoints.saveChronic, result, this.token).subscribe((response: any) => {
          this.checkChronic()
        });
      }
    });
  }

  openChronicExists() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_chronic2, {
      width: '600px',
      data: { chronic: this.chronicDataExists, email: this.email, dmail: this.dmail }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openChronic()
      }
      this.checkChronic()

    });
  }
  //  

  getAbha(){
    var params = {
      account_email: this.dmail,
      patient_email: this.email
    }

    this.appService.getData(this.urlPoints.getabharecord, params, this.token).subscribe((response: any) => {
      console.log('response::',response);
      if(response.success==1){
        this.abha = true
        this.abhaDetails = response.message[0]
        console.log('abhaDetails:',this.abhaDetails);
      }
    });

  }
}

@Component({
  selector: 'mobile-access',
  templateUrl: 'mobile_access.html',
  styleUrls: ['mobile_access.scss']
})

export class DialogOverviewExampleDialog_access {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_access>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_Access) {

  }
  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'confirm',
  templateUrl: 'confirm.html',
  styleUrls: ['confirm.scss']
})

export class DialogOverviewExampleDialog_confirm {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_confirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_Confirm) {

  }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  enable() {
    this.dialogRef.close('yes')
  }
}


@Component({
  selector: 'Vital',
  templateUrl: 'vital.html',
  styleUrls: ['vital.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class DialogOverviewExampleDialog_vital {


  maxDate = moment(new Date()).format('YYYY-MM-DD');
  getauthdetails;
  urlPoints;
  dmail; doc_id; token;
  gulcometer_mmol;
  splithours;
  hours;
  min;
  update_date;
  gulcovalue;
  documentId;
  success;
  gulco_date: any = moment(new Date()).format('YYYY-MM-DD');
  valid = true;
  spo2Valid = true;
  HRValid = true;
  RRValid = true;
  systolicValid = true;
  diastolicValid = true;
  tempC: any = ''
  view = 1;
  todayEntries = [];
  entryLength = 0
  gulcometer_mgdl: any;

  vitals:any = {
    weight: "",
    height: "",
    temperature: "",
    arm_circumference: "",
    head_circumference: "",
    chest_circumference: "",
    bmi: "",
    bsa: "",
    heart_rate: "",
    respiratory_rate: "",
    bp_systolic: "",
    bp_diastolic: "",
    oxygen_saturation: "",
    gulcometer_mmol: "",
    gulcometer_mgdl: ""
  };
  pills = [];
  injication = [];
  getHeight: any;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_vital>,
    @Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService, public snackBar: MatSnackBar,private toastr: ToastrService) {

    this.urlPoints = AppConfig.settings.EndPoints;
    this.getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = this.getauthdetails.account_id;
    this.doc_id = this.getauthdetails.doctor_id;
    this.token = this.getauthdetails.token;
    this.success = "Updated successfully !"

  }



  ngOnInit(): void {
    this.defaultVitalDatas();

    var now = moment();

    this.hours = now.hour()

    this.min = now.minutes()

  }

  defaultVitalDatas() {

    let params = {
      account_email: this.dmail,
      appointment_id: this.data.apptId
    }

    this.appService.getData(this.urlPoints.appointmentvitalsdata, params, this.token).subscribe((response: any) => {


      this.entryLength = response.vitals.length

      if (response.vitals.length > 0) {



        this.vitals = response.vitals[0];
        this.getHeight = response.vitals[0].height * 100

        this.documentId = response.vitals[0].vitals;

        let date = moment.utc(response.vitals[0].glucometer_measured_date).format('YYYY-MM-DD HH:mm:ss');
        let stillUtc = moment.utc(date).toDate();
        let local = moment(stillUtc).local().format('DD/MM/YYYY HH:mm');

        this.gulco_date = moment.utc(response.vitals[0].glucometer_measured_date);

        this.splithours = local.split(' ')[1];
        this.hours = this.splithours.split(' ')[0].split(':')[0];
        this.min = this.splithours.split(' ')[0].split(':')[1];

        this.gulcometer_mmol = response.vitals[0].glucometer.mmol_value;
        this.gulcovalue = response.vitals[0].glucometer.option;

        this.gulcometer_mgdl = (Number(this.gulcometer_mmol) * 0.0555).toFixed(3);

        this.vitals.bsa = ((0.007184 * Math.pow(Number(this.vitals.weight), 0.425) * (Math.pow((Number(this.vitals.height) / 100), 0.725))).toFixed(3))
        this.tempC = ((Number(this.vitals.temperature) - 32) / 1.8).toFixed(2)


        for (var i = 0; i < response.vitals[0].glucometer.pills.length; i++) {
          this.pills.push({ name: response.vitals[0].glucometer.pills[i].name, strength: response.vitals[0].glucometer.pills[i].strength });
        }
        for (var j = 0; j < response.vitals[0].glucometer.injection.length; j++) {
          this.injication.push({ name: response.vitals[0].glucometer.injection[j].name, strength: response.vitals[0].glucometer.injection[j].strength });
        }

      }
    });
  }

  addpills() {
    let newpill = {}
    this.pills.push(newpill);
  }

  removepills() {
    this.pills.pop();

  }

  changeView(i) {
    this.view = i
  }

  addinj() {
    let newpill = {}
    this.injication.push(newpill);
  }

  removeinj() {
    this.injication.pop();

  }

  getGulcoVal(getgulcoVal) {
    this.gulcovalue = getgulcoVal;
  }

  spo2Change() {

    if (Number(this.vitals.oxygen_saturation) > 100) {
      this.valid = false //spo2Valid
      this.spo2Valid = false //spo2Valid

    }
    else {
      this.valid = true
      this.spo2Valid = true //spo2Valid

    }

  }

  HRChange() {
    if (Number(this.vitals.heart_rate) > 200) {
      this.valid = false //spo2Valid
      this.HRValid = false //spo2Valid

    }
    else {
      this.valid = true
      this.HRValid = true //spo2Valid

    }
  }

  RRChange() {
    if (Number(this.vitals.respiratory_rate) > 60) {
      this.valid = false //spo2Valid
      this.RRValid = false //spo2Valid

    }
    else {
      this.valid = true
      this.RRValid = true //spo2Valid

    }
  }

  systolicChange() {
    if (Number(this.vitals.bp_diastolic) > Number(this.vitals.bp_systolic)) {
      this.valid = false //spo2Valid
      this.systolicValid = false //spo2Valid

    }
    else {
      this.valid = true
      this.systolicValid = true //spo2Valid
      this.diastolicValid = true //spo2Valid

    }
  }

  diastolicChange() {
    if (Number(this.vitals.bp_diastolic) > Number(this.vitals.bp_systolic)) {
      this.valid = false //spo2Valid
      this.diastolicValid = false //spo2Valid

    }
    else {
      this.valid = true
      this.diastolicValid = true //spo2Valid
      this.systolicValid = true //spo2Valid

    }
  }

  weightChange() {
    if ((Number(this.vitals.weight) > 0) && (Number(this.vitals.height) > 0)) {
      this.vitals.bmi = (((Number(this.vitals.weight) / (Number(this.vitals.height) * Number(this.vitals.height))) * 10000).toFixed(2)).toString()
      this.vitals.bsa = ((0.007184 * Math.pow(Number(this.vitals.weight), 0.425) * (Math.pow((Number(this.vitals.height) / 100), 0.725))).toFixed(3))

    }
    else {
      this.vitals.bmi = ''
      this.vitals.bsa = ''
    }
  }

  heightChange() {
     this.vitals.height = this.getHeight
    if ((Number(this.vitals.weight) > 0) && (Number(this.vitals.height) > 0)) {


      this.vitals.bmi = (((Number(this.vitals.weight) / (Number(this.vitals.height) * Number(this.vitals.height))) * 10000).toFixed(2)).toString()

      //		$scope.growth.bsa = (0.007184*Math.pow($scope.growth.weight,0.425) * (Math.pow(($scope.growth.height/100),0.725))).toFixed(3);

      this.vitals.bsa = ((0.007184 * Math.pow(Number(this.vitals.weight), 0.425) * (Math.pow((Number(this.vitals.height) / 100), 0.725))).toFixed(3))

    }

    else {
      this.vitals.bmi = ''
      this.vitals.bsa = ''

    }
  }

  tempChange() {
    if (Number(this.vitals.temperature) > 0) {


      this.tempC = ((Number(this.vitals.temperature) - 32) / 1.8).toFixed(2)
    }
    else {
      this.tempC = ''
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  tommol() {

    this.gulcometer_mgdl = (Number(this.gulcometer_mmol) * 0.0555).toFixed(3);

    ((this.gulcometer_mmol * 0.0555).toFixed(3))
  }

  updateVital() {
// if(this.vitals && this.vitals.weight && this.vitals.height && this.vitals.head_circumference){
if(this.vitals && this.vitals.weight){
    let currentDate = new Date();
    let updateDate = new Date();
    let patientRecordId = this.data.recordId;

    var getjson = JSON.stringify(
      {
        'mmol_value': this.gulcometer_mmol != undefined ? this.gulcometer_mmol.toString() : "",
        'mgol_value': this.gulcometer_mmol != undefined ? this.gulcometer_mmol.toString() : "",
        'option': this.gulcovalue, 'pills': JSON.parse(JSON.stringify(this.pills)),
        'injection': JSON.parse(JSON.stringify(this.injication)),//moment(new Date()).format('YYYY-MM-DD') gulco_date
        //  "text":moment(new Date(this.gulco_date)).format('YYYY-MM-DD')+'T'+this.hours?this.hours:'00'+':'+this.min?this.min:"00"+":00.000Z"
      });


    var getjsnonVal = getjson.replace(/["']/g, "'");
let height = this.vitals.height && this.vitals.height / 100;

    let updateDataParams = {
      record_id: patientRecordId,
      document_id: this.documentId,
      doctor_id:this.doc_id,
      patient_email:this.data.patient_email,
      type: "Vitals",
      data: {
        Models: {
          Model: {
            name: "HelChildVitalSigns",
            Field: [
              { name: "date", text: currentDate },
              { name: "weight", text: this.vitals.weight != (undefined || null) ? this.vitals.weight.toString() : "" },
              { name: "height", text: height != (undefined || null) ? ((height)).toString() : "" },
              { name: "temperature", text: this.vitals.temperature != (undefined || null) ? this.vitals.temperature.toString() : "" },
              { name: "arm_circumference", text: this.vitals.arm_circumference != (undefined || null) ? this.vitals.arm_circumference.toString() : "" },
              { name: "head_circumference", text: this.vitals.head_circumference != (undefined || null) ? this.vitals.head_circumference.toString() : "" },
              { name: "chest_circumference", text: this.vitals.chest_circumference != (undefined || null) ? this.vitals.chest_circumference.toString() : "" },
              { name: "bmi", text: this.vitals.bmi != (undefined || null) ? this.vitals.bmi.toString() : "" },
              { name: "heart_rate", text: this.vitals.heart_rate != (undefined || null) ? this.vitals.heart_rate.toString() : "" },
              { name: "respiratory_rate", text: this.vitals.respiratory_rate != (undefined || null) ? this.vitals.respiratory_rate.toString() : "" },
              { name: "bp_systolic", text: this.vitals.bp_systolic != (undefined || null) ? this.vitals.bp_systolic.toString() : "" },
              { name: "bp_diastolic", text: this.vitals.bp_diastolic != (undefined || null) ? this.vitals.bp_diastolic.toString() : "" },
              { name: "oxygen_saturation", text: this.vitals.oxygen_saturation != (undefined || null) ? this.vitals.oxygen_saturation.toString() : "" },
              { name: "glucometer", text: getjsnonVal },
              { name: "glucometer_measured_date", text: moment(new Date(this.gulco_date)).format('YYYY-MM-DD') + 'T' + (this.hours ? this.hours : '00') + ':' + (this.min ? this.min : "00") + ":00.000Z" }
            ]
          }
        }
      }
    };

    if (this.documentId) {
      this.appService.postData(this.urlPoints.updateVitalData, updateDataParams, this.token).subscribe((response: any) => {
        this.linkupdatedVitalData(response.Document.id)
      });
    }

    else {
      this.appService.postData(this.urlPoints.postVitals, updateDataParams, this.token).subscribe((response: any) => {
        this.linkupdatedVitalData(response.Document.id)
      });
    }

  }else{
    this.toastr.error('To proceed, please fill out all the required fields in the form.')
  }

  }

  linkupdatedVitalData(getdocId) {
    var linkData = {
      account_email: this.dmail,
      link: {
        vitals_id: getdocId,
        appointment_id: this.data.apptId
      }
    }
    this.appService.postData(this.urlPoints.linkappointmentvitals, linkData, this.token).subscribe((response: any) => {
      this.openSnackBar();
      this.defaultVitalDatas();
      this.close();
    });
  }

  disableDate() {
    return false;
  }

  openSnackBar() {
    this.snackBar.open(this.success, '', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
    });
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'allergy',
  templateUrl: 'allergy.html',
  styleUrls: ['allergy.scss']
})

export class DialogOverviewExampleDialog_allergy {

  food = '';
  drug = '';
  environment = '';
  others = '';
  exists;
  foodArray = [
    // {name:'',
    // reaction:''}
  ]

  drugArray = [
    // {name:'',
    // reaction:''}
  ]

  environmentArray = [
    // {name:'',
    // reaction:''}
  ]

  othersArray = [
    // {name:'',
    // reaction:''}
  ]

  newFoodArray = [];
  newDrugArray = []
  newEnvironmentArray = []
  newOthersArray = []

  deleteDrugArray = []
  deleteFoodArray = []
  deleteEnvironmentArray = []
  deleteOthersArray = []
  count = 0;
  allergyDetails;
  disabled = false

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_allergy>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_Allergy) {

  }
  ngOnInit(): void {
    // this.food=this.data.food
    // this.drug=this.data.drug
    // this.environment=this.data.environment
    // this.others=this.data.others
    // this.exists=this.data.exists


    var obj: any;

    var drugArray = []
    for (var i = 0; i < this.data.drug.length; i++) {
      obj = {}
      obj.name = this.data.drug[i].name
      obj.reaction = this.data.drug[i].reaction
      obj.new = false
      obj.id = this.data.drug[i].id
      drugArray.push(obj)
    }

    this.drugArray = Object.assign([], drugArray);
    this.drugArray.push({
      name: '',
      reaction: ''
    })

    var foodArray = []
    for (var i = 0; i < this.data.food.length; i++) {
      obj = {}
      obj.name = this.data.food[i].name
      obj.reaction = this.data.food[i].reaction
      obj.new = false
      obj.id = this.data.food[i].id
      foodArray.push(obj)
    }

    this.foodArray = Object.assign([], foodArray);
    this.foodArray.push({
      name: '',
      reaction: ''
    })

    var environmentArray = []

    for (var i = 0; i < this.data.environment.length; i++) {
      obj = {}
      obj.name = this.data.environment[i].name
      obj.reaction = this.data.environment[i].reaction
      obj.new = false
      obj.id = this.data.environment[i].id
      environmentArray.push(obj)
    }

    this.environmentArray = Object.assign([], environmentArray);
    this.environmentArray.push({
      name: '',
      reaction: ''
    })

    var othersArray = []

    for (var i = 0; i < this.data.others.length; i++) {
      obj = {}
      obj.name = this.data.others[i].name
      obj.reaction = this.data.others[i].reaction
      obj.new = false
      obj.id = this.data.others[i].id
      othersArray.push(obj)
    }

    this.othersArray = Object.assign([], othersArray);
    this.othersArray.push({
      name: '',
      reaction: ''
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ok() {


    if (this.drugArray[this.drugArray.length - 1].new == undefined && this.drugArray[this.drugArray.length - 1].name != '') {
      this.count++
      this.drugArray[this.drugArray.length - 1].new = true;
      this.drugArray[this.drugArray.length - 1].id = 'new' + this.count;
      this.newDrugArray.push(this.drugArray[this.drugArray.length - 1])
    }

    if (this.foodArray[this.foodArray.length - 1].new == undefined && this.foodArray[this.foodArray.length - 1].name != '') {
      this.count++
      this.foodArray[this.foodArray.length - 1].new = true;
      this.foodArray[this.foodArray.length - 1].id = 'new' + this.count;
      this.newFoodArray.push(this.foodArray[this.foodArray.length - 1])
    }

    if (this.environmentArray[this.environmentArray.length - 1].new == undefined && this.environmentArray[this.environmentArray.length - 1].name != '') {
      this.count++
      this.environmentArray[this.environmentArray.length - 1].new = true;
      this.environmentArray[this.environmentArray.length - 1].id = 'new' + this.count;
      this.newEnvironmentArray.push(this.environmentArray[this.environmentArray.length - 1])
    }

    if (this.othersArray[this.othersArray.length - 1].new == undefined && this.othersArray[this.othersArray.length - 1].name != '') {
      this.count++
      this.othersArray[this.othersArray.length - 1].new = true;
      this.othersArray[this.othersArray.length - 1].id = 'new' + this.count;
      this.newOthersArray.push(this.othersArray[this.othersArray.length - 1])
    }

    var result = {
      food: this.food,
      drug: this.drug,
      environment: this.environment,
      others: this.others,
      drugArray: this.drugArray,
      foodArray: this.foodArray,
      environmentArray: this.environmentArray,
      othersArray: this.othersArray,
      deleteDrugArray: this.deleteDrugArray,
      deleteFoodArray: this.deleteFoodArray,
      deleteEnvironmentArray: this.deleteEnvironmentArray,
      deleteOthersArray: this.deleteOthersArray,
      newDrugArray: this.newDrugArray,
      newFoodArray: this.newFoodArray,
      newEnvironmentArray: this.newEnvironmentArray,
      newOthersArray: this.newOthersArray,

    }

    this.dialogRef.close(result)
  }

  deduct() {

    var flag = false

    for (var i = 0; i < this.foodArray.length; i++) {
      if (this.foodArray[i].name == '' && this.foodArray[i].reaction != '') {
        flag = true
      }
    }


    for (var i = 0; i < this.drugArray.length; i++) {
      if (this.drugArray[i].name == '' && this.drugArray[i].reaction != '') {
        flag = true
      }
    }

    for (var i = 0; i < this.environmentArray.length; i++) {
      if (this.environmentArray[i].name == '' && this.environmentArray[i].reaction != '') {
        flag = true
      }
    }


    for (var i = 0; i < this.othersArray.length; i++) {
      if (this.othersArray[i].name == '' && this.othersArray[i].reaction != '') {
        flag = true
      }
    }



    this.disabled = flag
  }

  addFood(i) {

    if (this.foodArray[i].name != '') {
      this.foodArray.push({
        name: '',
        reaction: ''
      })

      this.foodArray[i].new = true

      this.count++
      this.foodArray[i].id = 'new' + this.count

      this.newFoodArray.push(this.foodArray[i])
    }



  }

  minusFood(i) {


    var index;

    if (this.foodArray[i].new) {
      for (var j = 0; j < this.newFoodArray.length; j++) {
        if (this.newFoodArray[j].id == this.foodArray[i].id) {
          index = j;
          break;
        }
      }
      this.newFoodArray.splice(index, 1)
    }

    else {
      this.foodArray[i].deleted = true
      this.deleteFoodArray.push(this.foodArray[i])
    }
    this.foodArray.splice(i, 1)
  }

  addDrug(i) {

    if (this.drugArray[i].name != '') {
      this.drugArray.push({
        name: '',
        reaction: ''
      })

      this.drugArray[i].new = true
      this.count++
      this.drugArray[i].id = 'new' + this.count

      this.newDrugArray.push(this.drugArray[i])
    }



  }

  minusDrug(i) {


    var index;

    if (this.drugArray[i].new) {
      for (var j = 0; j < this.newDrugArray.length; j++) {
        if (this.newDrugArray[j].id == this.drugArray[i].id) {
          index = j;
          break;
        }
      }
      this.newDrugArray.splice(index, 1)
    }
    else {
      this.drugArray[i].deleted = true
      this.deleteDrugArray.push(this.drugArray[i])
    }

    this.drugArray.splice(i, 1)
  }

  addEnvironment(i) {

    if (this.environmentArray[i].name != '') {
      this.environmentArray.push({
        name: '',
        reaction: ''
      })

      this.environmentArray[i].new = true

      this.count++
      this.environmentArray[i].id = 'new' + this.count

      this.newEnvironmentArray.push(this.environmentArray[i])
    }


  }

  minusEnvironment(i) {

    var index;

    if (this.environmentArray[i].new) {
      for (var j = 0; j < this.newEnvironmentArray.length; j++) {
        if (this.newEnvironmentArray[j].id == this.environmentArray[i].id) {
          index = j;
          break;
        }
      }
      this.newEnvironmentArray.splice(index, 1)
    }
    else {
      this.environmentArray[i].deleted = true
      this.deleteEnvironmentArray.push(this.environmentArray[i])
    }

    this.environmentArray.splice(i, 1)
  }

  addOthers(i) {

    if (this.othersArray[i].name != '') {
      this.othersArray.push({
        name: '',
        reaction: ''
      })

      this.othersArray[i].new = true

      this.count++
      this.othersArray[i].id = 'new' + this.count

      this.newOthersArray.push(this.othersArray[i])
    }


  }

  minusOthers(i) {


    var index

    if (this.othersArray[i].new) {
      for (var j = 0; j < this.newOthersArray.length; j++) {
        if (this.newOthersArray[j].id == this.othersArray[i].id) {
          index = j;
          break;
        }
      }
      this.newOthersArray.splice(index, 1)
    }
    else {
      this.othersArray[i].deleted = true
      this.deleteOthersArray.push(this.othersArray[i])
    }

    this.othersArray.splice(i, 1)
  }
}
@Component({
  selector: 'growthchart',
  templateUrl: 'growthchart.html',
  styleUrls: ['growthchart.scss']
})

export class DialogOverviewExampleDialog_growthchart {
  currentDate: Date = new Date();
  formattedDate;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_growthchart>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_growthchart,private appService: AppService,private toastr: ToastrService,private datePipe: DatePipe) {
      this.formattedDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ');

  }
  weight;
  
  arm_circumference;
  height;
  head_circumference;
  bmi;
  chest_circumference;
  bsa;
  urlPoints;
  doc_id;
  getauthdetails;
  patientprofile;
  patientMail;
  dmail;
  token;
  @Input() id;
  ngOnInit(): void {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.patientprofile = JSON.parse(sessionStorage.getItem("patientprofile"));
    this.dmail = this.getauthdetails.account_id;
    this.doc_id = this.getauthdetails.doctor_id;
    this.token = this.getauthdetails.token;
    this.patientMail=this.patientprofile.email
  }
  weightChange(e) {
    if(e.charCode != 46){
    if(e.charCode < 48 || e.charCode > 57){
      e.preventDefault();
    }
    else {
      console.log("e::",e)
    }
  }

  }
  weight_height(e){

        if(this.weight && this.height){
          const heightInMeters = this.height / 100;
          let localBBmi = this.weight / (heightInMeters * heightInMeters);
          this.bmi = localBBmi.toFixed(2)
        }else{
          this.bmi =''
        }
   
  }
  
  postVitalsApi(){
// let validDate = this.weight && this.height && this.head_circumference ? true : false;
 let validDate = this.weight ? true : false;

if(validDate){
  //to meter
  let height = this.height && this.height / 100;
    let currentDate = new Date();
    // let localBmi = this.bmi.toFixed(2);
    var param = {
      record_id:this.patientprofile.record_id,
      doctor_id:this.doc_id,
      patient_email:this.patientMail,
      type: "Vitals",
      data:{
        Models: {
          Model:{
          name:"HelChildVitalSigns",
          Field: [
            {
              name:'date',
              text:currentDate
              // text:this.formattedDate.toString()
            },
            {
              name:'weight',
              text:this.weight ? this.weight.toString() :''
            },
            {
              name:'height',
              text:height ?height.toString() :''
            },
            {
              name:'temperature',
              text:""
            },
            {
              name:'arm_circumference',
              text:this.arm_circumference ? this.arm_circumference.toString() : ''
            },
            {
              name:'head_circumference',
              text:this.head_circumference ? this.head_circumference.toString() : ''
            },
            {
              name:'chest_circumference',
              text:this.chest_circumference ? this.chest_circumference.toString() : ''
            },
            {
              name:'bmi',
              text:this.bmi ? this.bmi.toString() : ''
            },
            {
              name:'heart_rate',
              text:""
            },
            {
              name:'respiratory_rate',
              text:""
            },
            {
              name:'bp_systolic',
              text:""
            },
            {
              name:'bp_diastolic',
              text:""
            },
            {
              name:'oxygen_saturation',
              text:""
            },
            {
              name:'glucometer',
              text:""
            },
            {
              name:'respiratory_rate',
              text:""
            },

          ]
        }
        }
      }
    }
    this.appService.postData(this.urlPoints.postVitals, param, this.token).subscribe((res: any) => {
      console.log("res::",res)
      if(res.Document){
        this.toastr.success('Updated successful')   
        this.close();
      }else{
        this.toastr.error('Failed to Update')
      }
    });
  }else{
    this.toastr.error('To proceed, please fill out all the required fields in the form.')
  }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'doctornotes',
  templateUrl: 'doctornotes.html',
  styleUrls: ['doctornotes.scss']
})

export class DialogOverviewExampleDialog_doctornotes {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_doctornotes>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_Confirm) {

  }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
@Component({
  selector:'addnewcarenet',
  templateUrl:'addnewcarenet.html',
  styleUrls:['addnewcarenet.scss']
})

export class Addnewcarenet{
  urlPoints;
  dmail;
  doc_id;
  getauthdetails;
  token;
  newCare = '';
  checkerror: boolean=false;
  checkstatus: any;
  constructor(public dialogRef: MatDialogRef<Addnewcarenet>, @Inject(MAT_DIALOG_DATA) public data: DialogData_Addnewcarenet,
  private appService: AppService, private toastr: ToastrService,private _changeDetectorRef: ChangeDetectorRef,
  public snackBar: MatSnackBar){
    this.urlPoints = AppConfig.settings.EndPoints;
    this.getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = this.getauthdetails.account_id;
    this.doc_id = this.getauthdetails.doctor_id;
    this.token = this.getauthdetails.token;
  }
  ngOnInit(): void {
    this.newCare = this.data.obj.tag;
    this.checkstatus = this.data.status;
    console.log(this.checkstatus);
  }
  deduct() {
    this.checkerror = false;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
  ok() {

    var arr = [];
    var geturlpoint = '';
    var params_queue = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
    };
    console.log(this.newCare);
    if (this.newCare == undefined || this.newCare == '') {
      this.checkerror = true;
      return;
    }

    if (this.checkstatus == 'update') {
      let arrobj = {
        id: this.data.obj.id,
        tag: this.newCare
      }
      arr.push(arrobj);
      geturlpoint = this.urlPoints.updatecarenettags;
      params_queue["tags"] = arr;
    }
    else if (this.checkstatus == 'add') {
      arr.push(this.newCare);
      geturlpoint = this.urlPoints.createcarenettags;
      params_queue["tag"] = arr;
    } else {
      let arr1obj = {
        id: this.data.obj.id,
      }
      arr.push(arr1obj);
      geturlpoint = this.urlPoints.deletecarenettags;
      params_queue["tag_id"] = arr;
    }
    console.log(arr)
    this.appService.postData(geturlpoint, params_queue, this.token).subscribe((response: any) => {
      console.log(response);
    })
    var result = { status: this.checkstatus }

    this.dialogRef.close(result)
  }
}

@Component({
  selector: 'carenet',
  templateUrl: 'carenet.html',
  styleUrls:['carenet.scss'],
})
export class Carenet{
  urlPoints;
  getauthdetails;
  dmail;
  token;
  doc_id;
  carenetsearch;
  pagelist = [];
  count = 1;
  selectedcarenetId = [];
  deleetarr = [];
  gettagData: any;
  pageOfItems: any;
  success = 'Created !';
  selectedcarenetArr = [];
  getfreqData: any;
  constructor(public dialogRef: MatDialogRef<Carenet>, @Inject(MAT_DIALOG_DATA) public data: DialogData_Carenet,
  private appService: AppService,public dialog: MatDialog, private toastr: ToastrService,private _changeDetectorRef: ChangeDetectorRef,
  public snackBar: MatSnackBar) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = this.getauthdetails.account_id;
    this.doc_id = this.getauthdetails.doctor_id;
    this.token = this.getauthdetails.token;
  }
  ngOnInit(): void {
    this.pageOfItems = 1;
    this.getalltags(this.pageOfItems);
    this.getSelectedtags();
    this.getfreqtags();
    setTimeout(() => {
      this._changeDetectorRef.markForCheck();
    }, 100);
  }
  getalltags(pagenum): void {
    let params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      page_number: pagenum
    }
    this.appService.getData(this.urlPoints.get_carenettags, params, this.token).subscribe((response: any) => {
      this.gettagData = response.message;
      this.count = 3;
      setTimeout(() => {
        this._changeDetectorRef.markForCheck();
      }, 100);
    });
  }
  search1(e) {
    let chars = e.target.value;
    if (chars == '') {
      this.getalltags(1);
    }else{
      let params = {
        doctor_account: this.dmail,
        account_email: this.dmail,
        doctor_id: this.doc_id,
        carenet_tag: chars.toUpperCase().trim(),
        page_number: 1
      }
      this.appService.getData(this.urlPoints.searchcarenettags, params, this.token).subscribe((response: any) => {
        this.gettagData = response.message;
        setTimeout(() => {
          this._changeDetectorRef.markForCheck();
        }, 100);
      })
    }
  }
  openSnackBar() {
    this.snackBar.open(this.success, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    var pagecount = (this.pageOfItems);
    this.getalltags(pagecount);
  }

  getSelectedtags(): void {
    this.selectedcarenetId = [];
    let params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      patient_account: this.data.patient_email,
      page_number: 1
    }
    this.appService.getData(this.urlPoints.getpatientcarenettags, params, this.token).subscribe((response: any) => {
      if (response.success == 1) {
        this.selectedcarenetArr = response.message;
        for (var i = 0; i < this.selectedcarenetArr.length; i++) {
          this.selectedcarenetId.push(this.selectedcarenetArr[i].tag_id);
        }
        console.log(this.selectedcarenetArr);
        console.log(this.selectedcarenetId);
      } else {
        this.selectedcarenetArr = [];
      }
      setTimeout(() => {
        this._changeDetectorRef.markForCheck();
      }, 100);
    })
  }
  getfreqtags(): void {
    let params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      page_number: 1
    }

    this.appService.getData(this.urlPoints.getfrequentlycarenettags, params, this.token).subscribe((response: any) => {
      this.getfreqData = response.message;
      setTimeout(() => {
        this._changeDetectorRef.markForCheck();
      }, 100);
    })

  }
  tagselected(getTagName, getTagId) {
    var localflag = false;
    for (var i = 0; i < this.selectedcarenetId.length; i++) {
      if (this.selectedcarenetId[i] == getTagId) {
        localflag = true;
        this.toastr.error("Already Selected")
      }
    }
    if (!localflag) {
      let selectedobj = {
        tag: getTagName,
        tag_id: getTagId
      }
      this.selectedcarenetArr.push(selectedobj);
      this.selectedcarenetId.push(getTagId);
      console.log(this.selectedcarenetArr);
      console.log(this.selectedcarenetId);
      
    }

  }
  deletetag(getdeleteTagId, index) {
    console.log(getdeleteTagId);
    this.selectedcarenetArr.splice(index, 1);
    this.selectedcarenetId.splice(index, 1);
    this.deleetarr = [];
    let selecteddeleteobj = {
      id: getdeleteTagId
    }
    this.deleetarr.push(selecteddeleteobj);
  }
  deleteSavedTag(){
    var params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      patient_account: this.data.patient_email,
      tag_id: this.deleetarr
    };

    this.appService.postData(this.urlPoints.deletepatientcarenettags, params, this.token).subscribe((response: any) => {
      this.getSelectedtags();
      this.getfreqtags();
    })
  }
  save() {
    console.log(this.selectedcarenetId);
    var params = {
      doctor_account: this.dmail,
      account_email: this.dmail,
      doctor_id: this.doc_id,
      patient_account: this.data.patient_email,
      tags: this.selectedcarenetId
    };

    this.appService.postData(this.urlPoints.savepatientcarenettags, params, this.token).subscribe((response: any) => {
      console.log(response);
    })
  }
  openaddnew(getupdate, getstatus) {
const  dialogRef = this.dialog.open(Addnewcarenet,{
  width: 'fit-content',
  data: { obj: getupdate, status: getstatus }
});
dialogRef.afterClosed().subscribe(result => {
  if (result.status == 'add') {
    this.success = 'Created !'
  } else if (result.status == 'update') {
    this.success = 'Updated !';
    this.getSelectedtags();
  } else {
    this.success = 'Deleted !';
    this.getSelectedtags();
  }
  this.getalltags(1);
  this.getfreqtags();
  this.openSnackBar();
})

setTimeout(() => {
  this._changeDetectorRef.markForCheck();
}, 100);
  }
  saveOK() {
    // this.save();
    this.deleteSavedTag()
    if (this.selectedcarenetId.length == 0) {
      this.dialogRef.close()
      // this.toastr.error('Select anyone')
    } else {
      console.log(this.selectedcarenetId);
      var params = {
        doctor_account: this.dmail,
        account_email: this.dmail,
        doctor_id: this.doc_id,
        patient_account: this.data.patient_email,
        tags: this.selectedcarenetId
      };

      this.appService.postData(this.urlPoints.savepatientcarenettags, params, this.token).subscribe((response: any) => {
        console.log(response);
        this.toastr.success('Saved !')
        this.dialogRef.close()
      })
      
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'details1',
  templateUrl: 'details.html',
  styleUrls: ['details.scss']
})

export class DialogOverviewExampleDialog_details {
  m;
  y;
  arrival;
  urlPoints;
  last_visit;
  last_login;
  last_vaccine;
  last_vaccine_date;
  next_vaccine_date;
  next_vaccine;
  minDate;
  upcomingVaccination;

  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();

    /* Prevent Saturday and Sunday for select. */
    return day !== 0;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_details>,public dialog: MatDialog, private appService: AppService, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_details) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  ngOnInit(): void {

    var a = this.data.profile.bday;
    var b = moment(new Date()).format("YYYY-MM-DD");

    var a_arr = a.split('-')
    var b_arr = b.split('-')

    var x = moment([b_arr[0], Number(b_arr[1]) - 1, b_arr[2]]);
    var y = moment([a_arr[0], Number(a_arr[1]) - 1, a_arr[2]]);

    this.y = moment(x).diff(moment(y), 'year')
    y.add('years', this.y)
    this.m = moment(x).diff(moment(y), 'months')

    this.appService.getQueue(this.urlPoints.getArrival + '?' + this.data.dmail + '/' + this.data.profile.record_id, this.data.token).subscribe((response: any) => {
      this.arrival = response.arrival_time


      if (this.arrival)
        this.arrival = moment(this.arrival).add(5.5, 'hours').format('DD-MMM-YYYY hh:mm A');

      // alert(this.arrival)

      if (response.arrival_time == "None")
        this.arrival = ""
    });

    var params2 = {
      account_email: this.data.dmail,
      patient: this.data.profile.email
    }

    this.appService.getData(this.urlPoints.getLastVisit, params2, this.data.token).subscribe((response: any) => {

      if (response.last_login_at)
        var a = response.last_login_at.split('T')[0];

      if (response.last_visit)
        var b = response.last_visit.split('T')[0];

      if (b)
        this.last_visit = moment(b).format("DD-MMM-YYYY")

      if (a)
        this.last_login = moment(a).format("DD-MMM-YYYY")


    });

    this.getVaccineDetails()
  }

  getVaccineDetails() {

    var params = {
      account_email: this.data.dmail,
      patient_email: this.data.email,
      dob: this.data.bday
    }

    this.appService.getData(this.urlPoints.patientdetailsvaccination, params, this.data.token).subscribe((response: any) => {

      this.last_vaccine = response.details[0].last_vaccine

      this.last_vaccine_date = response.details[0].last_vaccine_date


      this.next_vaccine_date = response.details[0].next_vaccine_date
      


      this.next_vaccine = response.details[0].next_vaccine

      this.upcomingVaccination = response.details[0].upcoming_vaccination

      var minCurrentDate = new Date();
      var maxNewDate = new Date();
      var newDate = moment(response.details[0].last_vaccine_date).add(30, 'days').format('YYYY-MM-DD')
      this.minDate = newDate

    })
  }

  onDateChange(e: HTMLInputElement) {

    var nameArray = this.next_vaccine.split(',')

    var s_no = []

    console.log(this.upcomingVaccination)

    for (var i = 0; i < this.upcomingVaccination.length; i++) {
      s_no.push(this.upcomingVaccination[i].sno)
    }

    var params = {
      account_email: this.data.dmail,
      // vaccine_details:data,
      s_no: s_no,
      master_vaccine_sno: [],
      patient_email: this.data.email,
      schedule_day: moment(new Date()).format('YYYY-MM-DD'),
      due_date: moment(e.value).format('YYYY-MM-DD')
    }

    this.appService.postData(this.urlPoints.vaccinationReschedule, params, this.data.token).subscribe((response: any) => {
      // this.getVaccineDetails()

      this.toastr.success("Vaccination rescheduled!")

    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openVacc()
  {
    console.log('here')
    // this.dialogRef.close('openVacc');
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_deffer1, {
      width: '600px',
      data: { account_email: this.data.dmail,patient_email: this.data.email,token:this.data.token,
        dob: this.data.bday,next_vaccine_date:this.next_vaccine_date,minDate:this.minDate }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getVaccineDetails()
      if(result)
      {

        console.log(result)

        this.upcomingVaccination = result

        console.log(this.upcomingVaccination)

        var name_arr = []

        // for(var i=0;i<this.upcomingVaccination.length;i++)
        // {
        //   name_arr.push(this.upcomingVaccination[i].drug_name)


        // }

        // this.next_vaccine = name_arr.join(',')

        var s_no = []

        console.log(this.upcomingVaccination)
    
        for (var i = 0; i < this.upcomingVaccination.length; i++) {
          s_no.push(this.upcomingVaccination[i].sno)
        }
    
        var params = {
          account_email: this.data.dmail,
          // vaccine_details:data,
          s_no: s_no,
          master_vaccine_sno: [],
          patient_email: this.data.email,
          schedule_day: moment(new Date()).format('YYYY-MM-DD'),
          due_date: this.next_vaccine_date !=''? moment(this.next_vaccine_date).format('YYYY-MM-DD'): moment(new Date()).format('YYYY-MM-DD')
        }
    
        this.appService.postData(this.urlPoints.vaccinationReschedule, params, this.data.token).subscribe((response: any) => {
          this.getVaccineDetails()
    
          // this.toastr.success("Vaccination rescheduled!")
    
        });

      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'chronic',
  templateUrl: 'chronic.html',
  styleUrls: ['chronic.scss']
})

export class DialogOverviewExampleDialog_chronic {
  matMenuTimer1: any;
  disabled;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_chronic>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_chronic) {

  }

  ngOnInit(): void {


    for (var i = 0; i < this.data.chronic.length; i++) {
      if (this.data.chronic[i].list.length > 0) {
        this.data.chronic[i].value = -1;

        this.data.chronic[i].opened = 0;
        this.data.chronic[i].disabled = false;

        for (var j = 0; j < this.data.chronic[i].list.length; j++) {
          if (this.data.chronic[i].list[j].status == 1) {
            this.data.chronic[i].value = j;
            this.data.chronic[i].status = 1
          }
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    var params = {
      "account_email": this.data.dmail,
      "patient_email": this.data.email,
      "data": this.data.chronic
    }
    this.dialogRef.close(params);

  }

  optionToggle(i) {

    if (this.data.chronic[i].status == 1) {
      this.data.chronic[i].status = 0

      if (this.data.chronic[i].list.length > 0) {
        for (var k = 0; k < this.data.chronic[i].list.length; k++) {
          this.data.chronic[i].list[k].status = 0
        }
      }
    }
    else {
      this.data.chronic[i].status = 1
    }

    this.checkDisabled()


  }

  changeRadio(i, j) {

    if (this.data.chronic[i].list[j].status == 1) {
      this.data.chronic[i].list[j].status = 0
    }
    else {

      this.data.chronic[i].value = j

      this.data.chronic[i].list[j].status = 1

      this.data.chronic[i].status = 1

    }

    this.checkDisabled()

  }

  checkDisabled() {
    this.disabled = false

    for (var i = 0; i < this.data.chronic.length; i++) {
      if (this.data.chronic[i].list.length > 0) {

        this.data.chronic[i].disabled = false

        if (this.data.chronic[i].status == 1) {
          var flag = 0

          for (var k = 0; k < this.data.chronic[i].list.length; k++) {
            if (this.data.chronic[i].list[k].status == 1) {
              flag = 1
            }
          }

          if (flag == 0) {
            this.data.chronic[i].disabled = true
            this.disabled = true
          }
        }
      }
    }
  }

  changeOpen(i) {

    this.matMenuTimer1 = setTimeout(() => { this.clickedMe1(); }, 300);

    this.matMenuTimer1 = setTimeout(() => {
      var that = this
      if (((document.getElementById('ccollapseExample' + (i + 1)) as HTMLDivElement).className).includes('show')) {
        that.data.chronic[i].opened = 1
      }
      else {
        that.data.chronic[i].opened = 0
      }
    }, 500);


  }

  changeOpen2(i) {
    clearTimeout(this.matMenuTimer1);
    this.matMenuTimer1 = undefined;

  }

  clickedMe1() {
    if (!this.matMenuTimer1) return;
  }

  checkClass(i) {
  }

}

@Component({
  selector: 'chronic2',
  templateUrl: 'chronic2.html',
  styleUrls: ['chronic2.scss']
})

export class DialogOverviewExampleDialog_chronic2 {
  categories: any = [];

  categoriesData: any = [];

  disabled = false;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_chronic2>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_chronic2) {

  }
  ngOnInit(): void {

    for (var i = 0; i < this.data.chronic.length; i++) {
      if (!this.categories.includes(this.data.chronic[i].name)) {
        this.categories.push(this.data.chronic[i].name)
      }
    }


    for (var i = 0; i < this.categories.length; i++) {

      this.categoriesData.push(
        {
          name: this.categories[i],
          value: []
        }
      )

      for (var j = 0; j < this.data.chronic.length; j++) {
        if (this.data.chronic[j].name == this.categories[i]) {

          if (this.data.chronic[j].value) {

            if (this.data.chronic[j].value != '') {

              if (this.data.chronic[j].name1)
                this.categoriesData[i].value.push(this.data.chronic[j].name1 + ' - ' + this.data.chronic[j].value)
              else
                this.categoriesData[i].value.push(this.data.chronic[j].value)

            }
            else
              this.categoriesData[i].value.push(this.data.chronic[j].name1)

          }
          else
            if (this.data.chronic[j].name1)
              this.categoriesData[i].value.push(this.data.chronic[j].name1)
        }
      }

    }

  }

  close() {
    this.dialogRef.close()
  }

  add() {
    this.dialogRef.close(1)
  }


}

@Component({
  selector: 'deffer_vac',
  templateUrl: 'deffer_vac.html',
  styleUrls: ['deffer_vac.scss']
})

export class DialogOverviewExampleDialog_deffer1 {
  urlPoints;
  vaccines=[];
  checkedCount = 0
  selectedVaccines = []

  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    /* Prevent Saturday and Sunday for select. */
    return day !== 0;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_deffer1>,public dialog: MatDialog,private appService: AppService,private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.urlPoints = AppConfig.settings.EndPoints;

  }
  ngOnInit(): void {
    console.log(this.data)
    
    this.getVaccines()
  }

  getVaccines()
  {
    var params = {
      account_email: this.data.account_email,
      patient_email: this.data.patient_email,
      dob: this.data.dob
    }

    this.appService.getData(this.urlPoints.vaccinationdruglist, params, this.data.token).subscribe((response: any) => {

      console.log(response)

      for(var i=0;i<response.length;i++)
      {
        // console.log(response[i][1])
        for(var j=0;j<response[i][1].length;j++)
        {
          // console.log(response[i][1][j])
          response[i][1][j].checked=false
          this.vaccines.push(response[i][1][j])
          // this.checked_arr.push(false)
        }
      }

    })
  }

  save()
  {

    this.dialogRef.close(this.selectedVaccines)

    var count = 0;

    for(var i=0;i<this.vaccines.length;i++)
    {
      if(this.vaccines[i].checked)
      {


        this.selectedVaccines.push(this.vaccines[i])

        // var params={
        //   account_email:this.data.account_email,
        //   s_no:[],
        //   master_vaccine_sno:this.vaccines[i].sno,
        //   patient_email:this.data.patient_email,
        //   schedule_day:moment(this.data.next_vaccine_date).format('YYYY-MM-DD'),
        //   due_date:moment(this.vaccines[i].due_date).format('YYYY-MM-DD')
        // }

        // console.log(params)
        // this.appService.postData(this.urlPoints.reschdulevaccinationcalender, params, this.data.token).subscribe((response: any) => {
        //   console.log(response)
        //   count++

        //   if(count == this.checkedCount)
        //   {
        //     this.toastr.success("Vaccinations rescheduled!")

        //   }
        // });

      }
    }

    this.dialogRef.close(this.selectedVaccines)

  }

  optionToggle(index)
  {

    this.checkedCount = 0

    this.vaccines[index].checked = !this.vaccines[index].checked

    console.log(this.vaccines)

    for(var i=0;i<this.vaccines.length;i++)
    {
      if(this.vaccines[i].checked)
      {
        this.checkedCount++
      }
    }

    console.log(this.checkedCount)
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNew()
  {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_addVaccine, {
      width: '600px',
      data: {  }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
       console.log(result)

        var params = {
          account_email: this.data.account_email,
          age_group:result.ageCategory,
          name:result.name.trim(),
          description:'',
          disease:'',
          gender:result.gender,
          site: result.site,
          side_effects : '',
          brands : ["Other"],
          remarks:''
        }

        console.log(params)

       this.appService.postData(this.urlPoints.addVaccine, params, this.data.token).subscribe((response: any) => {
         console.log(response)
         if(response.success == 1)
         {
          this.toastr.success("Vaccine added!")

          this.vaccines = []

          this.getVaccines()
         }
         else{
          this.toastr.error("Vaccine name already exists!")
         }
       });

      }
    });
  }
  

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'add_vaccine',
  templateUrl: 'add_vaccine.html',
  styleUrls: ['add_vaccine.scss']
})

export class DialogOverviewExampleDialog_addVaccine {

  ageCategory="Birth";
  name='';
  gender="male";
  site = "Intramuscular (IM)";
  submitted = false

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_addVaccine>,public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any) {

  }
  ngOnInit(): void {
  }

  save()
  {
    console.log(this.ageCategory)
    console.log(this.gender)
    console.log(this.name)
    console.log(this.site)

    this.dialogRef.close(
      {
        ageCategory : this.ageCategory,
        gender : this.gender,
        name : this.name,
        site : this.site
      }
    );


  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}