<body>
    <div id="pause" *ngIf="isLoading" class="d-flex align-items-center justify-content-center">
        <div id="spinner"></div>
    </div>

    <div id="pause" *ngIf="isPrinting" class="d-flex align-items-center justify-content-center">
        <!-- <div id="spinner" ></div> -->
        <img src="assets/printing.gif">
    </div>

    <div cdkDrag id="dragcontainer" *ngIf="isShowfloat">
        <div>
            <!-- <img (click)="closeChat()" style="float: right;padding: 0.5rem;" src="assets/cancel.svg"> -->

            <app-appt-chatmsges [setaccount_email]="dmail" [senderDetails]="senderDetails" [senderData]="senderData"
                [setdrID]="doc_id" [setdrName]="docData.name" [sendermail]="sendermail" (someEvent_close)="closeChat()"
                [id]="chat_appt_id" [senderImage]="senderImage"></app-appt-chatmsges>

        </div>
    </div>


    <div class="row">
        <!--left queue-->
        <div class="appointmnetList" id="appointmnetList">
            <div style="height: 5rem;">
                <div class="row quesearch" id="quesearch"><input class="form-control" autofocus="false"
                        placeholder="Search Patient" [(ngModel)]="searchTxt" id="srch-term" type="text">
                </div>
                <div class="legend" id="legend">
                    <!-- <span>Booked From </span> -->
                    <i class="fa fa-square set-mobileweb" aria-hidden="true"></i> EC&nbsp;
                    <i class="fa fa-square orange" aria-hidden="true"></i> App&nbsp;
                    <i class="fa fa-square set-open" aria-hidden="true"></i> Walkin&nbsp;
                    <i class="fa fa-square black" aria-hidden="true"></i> IVRS&nbsp;
                    <i class="fa fa-square red" aria-hidden="true"></i> Website
                </div>
            </div>
            <div class="quecontainer">
                <div>
                    <div class="nolist" *ngIf="list == ''" style="margin-top:150px"> Don't have appointments as of now
                    </div>
                </div>
                <!---For Tablet-->
                <div class="quelist forTablets" *ngFor="let patlist of list | filter:searchTxt;let i=index"
                    [class.disabled]="started" [class.disable_click]="started" [class.queuehighlight]="patlist.next_queue_patient==1"
                    [ngClass]="{'emergncyStyle':(patlist.type == 'emergency_voice_call' && patlist.status == 'confirmed')}"
                    (click)="arrivalStatus=patlist.arrival_time;setPatientDetails($event,patlist,i)">
                    <div class="row">
                       

                        <div style="position: relative;position: relative;
                        width: 4rem;
                        height: 4rem;
                        margin-bottom: 0.5rem;">
                            <img style="margin-top:4px;width: 100% !important;height: 100% !important;"
                                class="profileavatar" *ngIf="!patlist.imgURL" src="assets/avatar-male.png">

                            <img style="margin-top:4px;width: 100% !important;height: 100% !important;"
                                class="profileavatar" *ngIf="patlist.imgURL" [src]="patlist.imgURL">

                            <img *ngIf="patlist.type == 'video_call'" style="cursor:pointer;width: 1rem;height: 1rem;position: absolute;right: 0;bottom: 0;background-color: #fff;border-radius: 50%;"
                                src="assets/video.svg">

                                <img *ngIf="patlist.type == 'voice_call'" style="cursor:pointer;width: 1rem;height: 1rem;position: absolute;right: 0;bottom: 0;background-color: #fff;border-radius: 50%;"
                                src="assets/Voice.svg">

                        </div>

                        <div class="token_mob">
                            <div class="boxcontainer" style="width:100%"
                                [ngClass]="{'ec':(patlist.appointment_mode === 'web')&&(patlist.type != 'website'),
                            'app': patlist.appointment_mode === 'app','walkin': patlist.appointment_mode === 'walkin',
                            'ivrs': patlist.appointment_mode === 'web'&& patlist.type === 'website','website': patlist.appointment_mode === 'Online Site'}">
                                <div class="box">{{patlist.token}}</div>
                                <div class="tokenTime" style="font-weight: normal;font-size: 12px;">
                                    <div>{{patlist.slot}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-sm-12 patientDetails">
                            <p style="text-align: center;"><b>{{patlist.name_given}}</b> | {{patlist.gender | titlecase}} ,
                                {{patlist.age}}Y{{patlist.month}}M </p>
    
                                <p style="text-align: center;"> {{patlist.mr_number}} | {{patlist.phone}}</p>
                        </div>

                        <div class="col-md-12 col-sm-12 col-lg-6">
                         
                        </div>
                        <div style="text-align: center;" class="col-md-12">
                            <div *ngIf="patlist.type == 'video_call' && (patlist.allow_status != 1 && patlist.participant_connect_status == null || patlist.participant_connect_status == 0) && (patlist.status != 'closed')"
                                style="color:red">Not Initiated</div>
                            <div *ngIf="patlist.type == 'video_call' && (patlist.participant_connect_status == 1) && (patlist.status != 'closed')"
                                style="color:green">Joined and Waiting</div>

                            <div *ngIf="patlist.type == 'video_call' && (patlist.allow_status == 1 && (patlist.status != 'closed') && (patlist.participant_connect_status == 0 || patlist.participant_connect_status == null ))"
                                style="color:orange">Not Joined</div>

                        </div>

                        <div class="col-md-12 col-sm-12 col-lg-6">
                            <!-- <p style="margin-top:2px">{{patlist.type}}</p> -->
                            <p style="margin-top:2px">Consultation {{patlist.status}}</p>
                        </div>


                    </div>

                    <div class="Arrival" style="width: 100%;display: flex;justify-content: space-around;">
                        <div *ngIf="patlist.arrival_time == null"><button
                                class="btn btn-success custombutton glowsucess"
                                (click)="setArrival($event,patlist)"><span style="margin-top: -1px;display:block">Set
                                    Arrival</span></button></div>
                       
                        &nbsp;&nbsp;<div
                            *ngIf="patlist.arrival_time != null && patlist.type == 'video_call' && (patlist.participant_connect_status == null)"
                            style="margin-top: 3px;" (click)="notifyVideoCall($event,patlist)"><span
                                class="fa fa-phone-square fa-2x" style="color:green"></span></div>
                        <div
                            *ngIf="patlist.type == 'video_call' && (patlist.participant_connect_status == 1)  && (patlist.status != 'closed')">
                            <button class="btn btn-success custombutton glowsucess"
                                (click)="joinVideoCall($event,patlist)"><span
                                    style="margin-top: -1px;display:block">Join</span></button>
                        </div>
                    </div>

                    <div style="display: flex;justify-content: center;" *ngIf="patlist.next_queue_patient==1">
                        <p style="background-color: #FFAA00;color: #FFF;">Next patient</p>
                    </div>
                </div>
                <!---------------->

                <!---For lap and othres-->
                <div class="quelist hidemobile selectedQueue"
                    *ngFor="let patlist of list | filter:searchTxt;let i=index" [class.disabled]="started" [class.queuehighlight]="patlist.next_queue_patient==1"
                    [class.disable_click]="started" id="queue_{{i}}"
                    [ngClass]="{'emergncyStyle':(patlist.type == 'emergency_voice_call' && patlist.status == 'confirmed')}"
                    (click)="arrivalStatus=patlist.arrival_time;setPatientDetails($event,patlist,i)">
                    <div style="display: flex;justify-content: space-between;">

                        <div class="boxcontainer" style="visibility: hidden;"
                            [ngClass]="{'ec':(patlist.appointment_mode === 'web')&&(patlist.type != 'website'),
                        'app': patlist.appointment_mode === 'app','walkin': patlist.appointment_mode === 'walkin',
                        'ivrs': patlist.appointment_mode === 'web'&& patlist.type === 'website','website': patlist.appointment_mode === 'Online Site'}">
                            <div class="box">{{patlist.token}}</div>
                        </div>

                    
                        <div style="position: relative;position: relative;
                        width: 4rem;
                        height: 4rem;
                        margin-bottom: 0.5rem;">
                            <img style="margin-top:4px;width: 100% !important;height: 100% !important;"
                                class="profileavatar" *ngIf="!patlist.imgURL" src="assets/avatar-male.png">

                            <img style="margin-top:4px;width: 100% !important;height: 100% !important;"
                                class="profileavatar" *ngIf="patlist.imgURL" [src]="patlist.imgURL">

                            <img *ngIf="patlist.type == 'video_call'" style="cursor:pointer;width: 1rem;height: 1rem;position: absolute;right: 0;bottom: 0;background-color: #fff;border-radius: 50%;"
                                src="assets/video.svg">

                                <img *ngIf="patlist.type == 'voice_call'" style="cursor:pointer;width: 1rem;height: 1rem;position: absolute;right: 0;bottom: 0;background-color: #fff;border-radius: 50%;"
                                src="assets/Voice.svg">

                        </div>




                        <div class="boxcontainer"
                            [ngClass]="{'ec':(patlist.appointment_mode === 'web')&&(patlist.type != 'website'),
                        'app': patlist.appointment_mode === 'app','walkin': patlist.appointment_mode === 'walkin',
                        'ivrs': patlist.appointment_mode === 'web'&& patlist.type === 'website','website': patlist.appointment_mode === 'Online Site'}">
                            <div class="box">
                                <p>{{patlist.token}}</p>
                                <p *ngIf="patlist.slot!='Invalid date'" style="font-weight: 500;
                                font-size: 0.75rem;
                                margin-top: 0.25rem;">{{patlist.slot}}</p>

                            </div>
                        </div>
                    </div>
                    <!-- <div class="tokenTime">
                    </div> -->

                    <div>
                        <p style="text-align: center;"><b>{{patlist.name_given}}</b> | {{patlist.gender | titlecase}} ,
                            {{patlist.age}}Y{{patlist.month}}M </p>

                            <p style="text-align: center;"> {{patlist.mr_number}} | {{patlist.phone}}</p>
                    </div>

                    <div class="Arrival" style="margin-top: 0.5rem !important;float: unset !important;display: flex;justify-content: space-around;width: 100%;">
                        <div *ngIf="patlist.type == 'video_call' && patlist.allow_status != 1 && (patlist.participant_connect_status == null || patlist.participant_connect_status == 0) && (patlist.status != 'closed')"
                            style="margin-right: 60px;margin-top:8px;color:red">Not Initiated</div>
                        <div *ngIf="patlist.type == 'video_call' && (patlist.participant_connect_status == 1) && (patlist.status != 'closed')"
                            style="margin-top:-8px;font-size:10px;color:green">Joined<br /> and Waiting </div>

                        <div *ngIf="patlist.type == 'video_call' && patlist.allow_status == 1 && (patlist.status != 'closed') && (patlist.participant_connect_status == 0 || patlist.participant_connect_status == null)"
                            style="margin-right: 70px;margin-top:8px;color:orange">Not Joined</div>
                        <!-- <div *ngIf="patlist.type == 'video_call' && (patlist.status == 'closed')" 
                        style="margin-right: 110px;margin-top:8px;color:blue">Closed</div> -->

                        <div *ngIf="patlist.arrival_time == null"><button
                                class="btn btn-success custombutton glowsucess"
                                (click)="setArrival($event,patlist)"><span style="margin-top: -1px;display:block;float:right;justify-content: space-around;">Set
                                    Arrival</span></button></div>
                        <!-- &nbsp;&nbsp;<div *ngIf="patlist.arrival_time != null" style="margin-top: 8px;"><span>Arrived
                                {{patlist.arrivaled_time}}</span></div> -->
                        &nbsp;&nbsp;
                        <div *ngIf="patlist.chat==1" (click)="chat(patlist)" style="margin-top: 3px;margin-right: 3px;">
                            <span>
                                <img src="assets/leftmenu/chat.svg" class="menuiconAlign">
                            </span>
                        </div>

                        <div *ngIf="patlist.type == 'video_call' && patlist.status == 'confirmed' && (patlist.participant_connect_status == null || patlist.participant_connect_status == 0)"
                            style="margin-top: 3px;margin-left: auto;" (click)="notifyVideoCall($event,patlist)"><span
                                class="fa fa-phone-square fa-2x" style="color:green"></span></div>

                        <div
                            *ngIf="patlist.type == 'video_call' && (patlist.participant_connect_status == 1)  && (patlist.status != 'closed')">
                            <button class="btn btn-success custombutton glowsucess"
                                (click)="joinVideoCall($event,patlist)"><span
                                    style="margin-top: -1px;display:block">Join</span></button>
                        </div>

                       
                    </div>

                    <div>
                        <p style="text-align: center;margin-top: 0.5rem;">Consultation {{patlist.status}}</p>
                    </div>

                    <div style="display: flex;justify-content: center;" *ngIf="patlist.next_queue_patient==1">
                        <p style="background-color: #FFAA00;color: #FFF;font-size:0.9rem;padding: 5px;">Next patient</p>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <div class="icsscreen" id="icsscreen">
            <div class="row" style="display: flex;flex-wrap: wrap;">
                <div class="accordionTabs" [class.clickDisabled]="!id">
                    <div class="accordion" id="accordionExample">
                        <div style="height: 3rem;padding-left: 1rem;padding-right: 1rem;">
                            <div style="display: flex;" class="accordion no-transition" id="accordionExample">
                                <p class="topmenu" data-toggle="collapse" data-target="#collapseExample1"
                                    id="accordid_0" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="accordiantabs(0)">Profile</p>
                                <p class="topmenu" data-toggle="collapse" data-target="#collapseExample2"
                                    id="accordid_1" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="accordiantabs(1)">History</p>
                                <p class="topmenu clickDisabled" data-toggle="collapse" data-target="#collapseExample3"
                                    id="accordid_2" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="accordiantabs(2)">Engagement</p>
                                <p class="topmenu clickDisabled" data-toggle="collapse" data-target="#collapseExample4"
                                    id="accordid_3" aria-expanded="false" aria-controls="collapseExample"
                                    (click)="accordiantabs(3)">Discharge Summary</p>
                                <p class="topmenu" data-toggle="collapse" data-target="#collapseExample5"
                                    id="accordid_4" aria-expanded="false" aria-controls="collapseExample"
                                    *ngIf="speciality==1" (click)="accordiantabs(4)">Paediatrics</p>

                                <p class="topmenu" data-toggle="collapse" data-target="#collapseExample6"
                                    id="accordid_5" aria-expanded="false" aria-controls="collapseExample"
                                    *ngIf="speciality==2&&patient &&patient.demographics && patient.demographics.gender=='female'" (click)="accordiantabs(5)">Obst & Gynec</p>
                                <p class="topmenu" id="accordid_6" (click)="openLab()" (click)="accordiantabs(6)"
                                    [class.red]="labLength>0">Lab Reports <span *ngIf="labLength>0">({{labLength}})</span></p>
                            </div>
                        </div>
                        <div class="collapse collapseDiv accordionsubtabs" data-parent="#accordionExample"
                            id="collapseExample1">
                            <div style="display: flex;">
                                <p class="topmenu_sub" id="subTab_0" (click)="openbottomwindom(0)">Basic Details</p>
                                <p class="topmenu_sub" id="subTab_1" (click)="openbottomwindom(1)">Family History</p>
                                <p class="topmenu_sub" id="subTab_2" (click)="openbottomwindom(2)">Social History</p>
                                <p class="topmenu_sub" id="subTab_3" (click)="openbottomwindom(3)">Health Profile</p>
                                <p class="topmenu_sub" id="subTab_4" (click)="openbottomwindom(4)">Questionnaire</p>
                                <p class="topmenu_sub" id="subTab_5" (click)="openbottomwindom(5)">Treatment Goals</p>

                            </div>
                        </div>
                        <div class="collapse collapseDiv accordionsubtabs" data-parent="#accordionExample"
                            id="collapseExample2">
                            <div style="display: flex;">
                                <p class="topmenu_sub histab" id="hisTab_0" (click)="openbottomHistory(0)">Consultation
                                </p>
                                <p class="topmenu_sub histab" id="hisTab_1" (click)="openbottomHistory(1)">Medical</p>
                                <p class="topmenu_sub histab" id="hisTab_2" (click)="openbottomHistory(2)">Clinical</p>
                                <p class="topmenu_sub histab" id="hisTab_5" (click)="openbottomHistory(5)">Vital</p>

                                <!-- <p class="topmenu_sub histab" id="hisTab_3" (click)="openbottomHistory(3)">Vital</p> -->
                                <p class="topmenu_sub histab" id="hisTab_4"
                                    (click)="openbottomHistory(4);openPaymentSheet()" [class.red]="pending>0">Payment
                                    <span *ngIf="pending>0" class="paymentDue">Due: &#8377;&nbsp;{{pending}}</span>
                                </p>

                                <p class="topmenu_sub histab" id="hisTab_6" (click)="openbottomHistory(6)">Social</p>
                                <p class="topmenu_sub histab" id="hisTab_7" (click)="openbottomHistory(7)">Family</p>

                            </div>
                        </div>
                        <div class="collapse collapseDiv accordionsubtabs" data-parent="#accordionExample"
                            id="collapseExample3">
                            <div style="display: flex;">
                                <p class="topmenu_sub" id="subTab_5" (click)="devprocessAlert(5)">Covid</p>
                                <p class="topmenu_sub" id="subTab_6" (click)="devprocessAlert(6)">Vaccine</p>
                                <p class="topmenu_sub" id="subTab_7" (click)="devprocessAlert(7)">Followup</p>
                            </div>
                        </div>
                        <div class="collapse collapseDiv accordionsubtabs" data-parent="#accordionExample"
                            id="collapseExample5">
                            <div style="display: flex;">
                                <p class="topmenu_sub pedtab" id="pedtab_0" (click)="openGrowth()">Growth</p>
                                <p class="topmenu_sub pedtab" id="pedtab_1" (click)="openbottomwPaediatrics(1)">
                                    Vaccination</p>
                                <p class="topmenu_sub pedtab" id="pedtab_2" (click)="openbottomwPaediatrics(2)">
                                    Milestones</p>
                                <p class="topmenu_sub pedtab" id="pedtab_3" [class.disable_click]="y>=18" (click)="openbottomwPaediatrics(3)">Mothers
                                    antenatal record</p>
                                <p class="topmenu_sub pedtab" id="pedtab_4" [class.disable_click]="y>=18" (click)="openbottomwPaediatrics(4)">Baby's
                                    History
                                </p>
                            </div>
                        </div>
                        <div class="collapse collapseDiv accordionsubtabs" data-parent="#accordionExample"
                            id="collapseExample6">
                            <div style="display: flex;">
                                <p class="topmenu_sub gyncsubtabs" id="subGynecTab_0" (click)="gynecbootomwindow(0)">
                                    Obstetrics</p>
                                <p class="topmenu_sub gyncsubtabs" id="subGynecTab_1" (click)="gynecbootomwindow(1)">
                                    Gynaecology</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" [class.main]="full" [class.main1]="!full" style="margin-top: 5px;">
                    <div style="border-bottom: 1px solid #CBDBF3;" class="profilepadding">
                        <app-profile *ngIf="patient&&token&&docData" [patient]="patient.demographics.mr_number" [speciality]="speciality"
                            [imgURL]="imgURL" [started]="started" (someEvent)="toggleMute($event)" [row]="1"
                            (someEvent_enter)="enter($event)" (someEvent_input)="changeChars($event)" [appt_type]="current_videocall_patientDetails"
                            (someEvent_allergy)="checkAllergy()" (someEvent_key)="toggleKey()" [token]="token" [id]="id"
                            [bday]="patient.demographics.bday" [phone]="this.patient.record_info.Record.phone"
                            [name]="patient.record_info.Record.label" [drName]="docData.name" [drID]="doc_id"
                            (someEvent_full)="toggle_full()" [mr]="patient.record_info.Record.mr_number"
                            [arrived]="arrived" [muted]="muted" [full]="full" (someEvent_changestatus)="changeStatus()"
                            [email]="this.patient.record_info.Record.account_email" [dmail]="dmail" (someEvent_join)="joinVideoCall($event,current_videocall_patientDetails)"
                            [text]="service.text1" [lmp_update]="lmp_update" [fullscrImgSrc]="fullscrImgSrc" 
                            (someEvent_openvacc)="openbottomwPaediatrics(1)" [update]="updateProfile"
                            (someEvent_payment)="openPayment1()" [status]="status" [apptId]="appointment_id" [vitalchangestatus]="vitalchage">
                        </app-profile>
                    </div>

                    <div class="actionmargin" style="display: flex;height: 2rem;">

                        <app-profile *ngIf="patient&&token&&docData" style="margin-left: 1rem;"
                            [patient]="patient.demographics.mr_number" [imgURL]="imgURL" [started]="started"
                            (someEvent)="toggleMute($event)" (updatevitall)="updatevital()" [row]="3" (someEvent_enter)="enter($event)"
                            [height]="height" [weight]="weight" [bmi]="bmi" [temperature]="temperature"
                            (someEvent_input)="changeChars($event)" (someEvent_allergy)="checkAllergy()"
                            (someEvent_key)="toggleKey()" [token]="token" [id]="id" [bday]="patient.demographics.bday"
                            [phone]="this.patient.record_info.Record.phone" [name]="patient.record_info.Record.label"
                            [drName]="docData.name" [drID]="doc_id" (someEvent_full)="toggle_full()"
                            [mr]="patient.record_info.Record.mr_number" [arrived]="arrived" [muted]="muted"
                            [full]="full" [email]="this.patient.record_info.Record.account_email" [dmail]="dmail"
                            [text]="service.text1" [lmp_update]="lmp_update" [fullscrImgSrc]="fullscrImgSrc"
                            [changeVitals]="changeVitals" [vitalOpened]="vitalOpened" [update]="updateProfile"
                            [openAllergies]="openAllergies" [allergyClicked]="this.allergyClicked" [status]="status"
                            [apptId]="appointment_id"></app-profile>



                        <app-actions *ngIf="patient&&token&&docData" [patient]="patient.demographics.mr_number"
                            [imgURL]="imgURL" [started]="started" (someEvent)="toggleMute($event)"
                            (someEvent_enter)="enter($event)" (someEvent_input)="changeChars($event)"
                            (someEvent_key)="toggleKey()" [token]="token" [id]="id" [full]="full"
                            (someEvent_click)="inputClick()" [bday]="patient.demographics.bday"
                            [phone]="this.patient.record_info.Record.phone" [name]="patient.record_info.Record.label"
                            [drName]="docData.name" [drID]="doc_id" [mr]="patient.record_info.Record.mr_number"
                            [arrived]="arrived" [muted]="muted" [email]="this.patient.record_info.Record.account_email"
                            [dmail]="dmail" [text]="service.text1" [clear]="clear">
                        </app-actions>

                        <div style="margin-left: 0.5rem;" *ngIf="patient&&token&&docData&&eshop_enabled=='true'">

                            <button style="display: flex;justify-content: center;padding: 0;"
                                [class.disabled]="!started" tabindex="1" class=" btn2" data-toggle="tooltip"
                                (click)="!started ? notifier():false" (click)="started?listCart():false"
                                data-placement="top" title="Check Cart">
                                <img class="icon_menu" [class.shrink]="!full" style="padding: 0;margin-left: 0.5rem;"
                                    src="assets/noun-cart.svg">
                            </button>

                            <p style="font-size: 00.75rem;text-align:center">eShop</p>
                        </div>

                        <div style="margin-left: auto;" [class.disabled]="!started" [class.disable_click]="!ICSscreen_load">

                            <div style="display: flex;justify-content: center;">
                                <button
                                    style="display: flex;justify-content: center;padding-left: 6px;padding-right: 6px;"
                                    [class.disabled]="!started" tabindex="1" class=" btn2" data-toggle="tooltip"
                                    (click)="!started ? notifier():false" (click)="started?selectTemplate():false"
                                    data-placement="top" title="Load Template">
                                    <img class="icon_menu" [class.shrink]="!full" style="padding: 0;"
                                        src="assets/noun-file.svg">
                                </button>
                            </div>
                            <p style="font-size: 0.75rem;text-align: center;">Open Template</p>
                            
                        </div>

                    <div data-toggle="tooltip" [class.disabled]="!started" [class.disable_click]="!ICSscreen_load" style="margin-left: 0.5rem;"
                            data-placement="top" title="Save">
                            <div style="display: flex;justify-content: center;">
                                <button style="display: flex;width: 100%;justify-content: space-around" tabindex="1"
                                    (click)="started?save_follow():false" (keydown.enter)="started?save_follow():false"
                                    class="btn2">
                                    <img class="icon_menu" src="assets/save1.svg">
                                </button>
                            </div>

                            <p style="font-size: 0.75rem;text-align: center;">Save Consultation</p>
                        </div>
                        <div data-toggle="tooltip" [class.disabled]="!started" [class.disable_click]="!ICSscreen_load" style="margin-left: 0.5rem;"
                            data-placement="top" title="Template">
                            <div style="display: flex;justify-content: center;">
                                <button style="display: flex;width: 100%;justify-content: space-around" tabindex="1"
                                    (click)="started?template():false" class="btn2">
                                    <img class="icon_menu" src="assets/saveas.svg">
                                </button>
                            </div>

                            <p style="font-size: 0.75rem;">Save as Template</p>

                        </div>
                        <div data-toggle="tooltip" [class.disabled]="!started" data-placement="top"
                            style="margin-left: 0.5rem;" title="Print">
                            <div style="display: flex;justify-content: center;"></div>

                            <button style="display: flex;width: 100%;justify-content: space-around" tabindex="1"
                                (click)="started?printTemp():false" (keydown.enter)="printTemp()"
                                 class="btn2">
                                <img class="icon_menu" src="assets/print1.svg">
                            </button>
                            <!-- [disabled]="printDisabled" -->

                            <p style="font-size: 0.75rem;text-align: center;">Print</p>

                            <div *ngFor="let p of printArr">
                                <div class="printPres-iframeDiv" *ngIf="callprintprespopup==true">
                                    <iframe style="width: 100%;height: 100%;" style="display: none;"
                                        [src]="currentProjectUrl">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div class="row2"
                        [ngClass]="{'consultationgrayout': (arrivalStatus === null && (patientListRecordId != undefined && status !='Walkin')) || (arrivalStatus === null && patientListRecordId === undefined) }"
                        [class.disabled]="(arrivalStatus === null && (patientListRecordId != undefined && status !='Walkin')) || (arrivalStatus === null && patientListRecordId === undefined)"
                        [class.disable_click]="(arrivalStatus === null && (patientListRecordId != undefined && status !='Walkin')) || (arrivalStatus === null && patientListRecordId === undefined)">
                        <div class="row consultation_row" style="width: 100%;">
                            <div [class.disabled]="!started" (click)="!started ? notifier():false"
                                class="consultation col-lg-12 col-md-12">

                                <div class="row">
                                    <div style="padding-left: 0.5rem;margin-top:0.5rem;margin-bottom:0.5rem" class="col-lg-12 col-md-12 col-sm-12">
                                        
                                        <p class="selectedTemp text-center" [class.invisible]="!selectedTemp&&!selected_temp_name">
                                            <span *ngIf="selected_temp_name" style="font-weight: 600;">Selected Print Template : {{selected_temp_name}}</span>&nbsp;
                                            <span 
                                                style="font-weight: 600;"><span *ngIf="selectedTemp">Selected Template :</span> </span> {{selectedTemp}}</p>
                                    </div>
                                </div>

                                <!--Category of templates-->

                                <!--Referal letter-->
                                <div style="height: calc(100vh - 21rem);overflow-y: auto;" *ngIf="custom_template && selected_temp_Id==426858"
                                [class.full_preview]="!full">
                                <div class="preview card" style="display: flex;flex-direction: column;">
                                    <div style="text-align: center;margin-top: 10px;"><b>Referal Letter</b></div>
                                    <div class="Rtable Rtable--1cols" style="margin-top: 10px;">  
                                        <div class="Rtable-cell" style="display: flex;"> <span>Dr</span> <input style="width: 30%;margin-left: 10px;" class="form-control"
                                             type="text"  [(ngModel)]="refer_dr_name" onkeypress="return(event.key === '.' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);" ></div>
                                             <div class="Rtable-cell" style="margin-top:-5px;display: flex;"><span class="fntsize">hospital</span> <input style="width: 30%;margin-left: 10px;" class="form-control"
                                                [(ngModel)]="refer_hos_name"></div>
                                        <div class="Rtable-cell" style="margin-top:-5px;display: flex;"><span class="fntsize">Specialist</span> <input style="width: 30%;margin-left: 10px;" class="form-control"
                                            [(ngModel)]="refer_dr_spec"></div>
                                        <div class="Rtable-cell" style="margin-top:-5px;display: flex;"><span class="fntsize">Address</span><input style="width: 30%;margin-left: 10px;" class="form-control"
                                            [(ngModel)]="refer_dr_address" (keypress)="omit_special_char($event)"></div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Dear Dr<span class="fontWeight"> {{refer_dr_name}},</span></div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Re:  Referring <span class="fontWeight">Mr/Mrs/Ms. {{patient.record_info.Record.label}}, {{patient.demographics.gender}}, Date of Birth {{patient.demographics.bday | date: "dd/MMM/yyyy"}}</span></div>
                                    </div> 
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Thank you for accepting to see  <span class="fontWeight fntsize">Mr/Mrs/Ms. {{patient.record_info.Record.label}}, {{patient.demographics.gender}}, {{getdob}} </span> old, regarding 
                                            <span class="fontWeight fntsize"><input style="width: 30%;margin-left: 2px;display:inline" class="form-control"
                                                [(ngModel)]="pat_diagonsis" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"> </span> possibly requiring <span class="fontWeight"><input style="width: 30%;margin-left: 2px;display:inline" class="form-control"
                                                    [(ngModel)]="pat_examination" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"> </span>
                                        </div>
                                    </div> 
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">
                                            I have advised <span class="fontWeight">Mr/Mrs/Ms. {{patient.record_info.Record.label}}</span> the following medicines: 
                                        </div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell" style="font-weight:800"><div style="cursor:pointer" (click)="openPrescription()">Medicines</div>
                                            <!-- Tab. Paracetamol 500mg     1-1-1-1    daily<br/>
                                            Cap. Naproxen 500 mg BID  1-0-0-1    daily -->
                                            <tr *ngFor="let pres_s of pres_selected;let indexOfelement=index;let i=index;"
                                                    [class.selected_item]="highlight_pres&&pres_selected.length==(i+1)&&show_block=='pres'"
                                                    >
                                                    
                                                    <td>
                                                        <p *ngIf="drugShowType==1">{{pres_s.brand}}</p>

                                                        <p *ngIf="drugShowType==2">{{pres_s.generic}}</p>

                                                        <p *ngIf="drugShowType==3">{{pres_s.generic}} - {{pres_s.brand}}
                                                        </p>

                                                    </td>

                                                    <td *ngIf="!durationAlone">
                                                        <p>{{pres_s.dose}} {{pres_s.dose_option}}
                                                            <span *ngIf="pres_s.dose_option"> , </span>
                                                            {{pres_s.shortFreq}} ,
                                                            {{pres_s.duration}} {{pres_s.duration_type}}
                                                        </p>
                                                    </td>

                                                    <td *ngIf="durationAlone" style="display:block;margin-left:10px">
                                                        <p>{{pres_s.dose}} {{pres_s.dose_option}}
                                                            <span *ngIf="pres_s.dose_option"> & </span>
                                                            {{pres_s.shortFreq}}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="durationAlone">
                                                        <p>{{pres_s.duration}} {{pres_s.duration_type}}
                                                        </p>
                                                    </td>

                                                   
                                            </tr>
                                        </div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">
                                            Kindly do the needful. I will be happy to receive feedback and follow-up plan. 
                                        </div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">
                                            Kind Regards
                                        </div>
                                    </div>
                                    <div class="Rtable Rtable--4cols">
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell" style="text-align: left;"> 
                                            <span style="margin-right: 10px;">
                                            <span class="fontWeight">{{getdrName}}  </span> <br/>
                                            <span>{{getdrnum}}  </span><br/>
                                            <span>{{getdrdegree}}  </span><br/>
                                            <span>{{getdrspec}}  </span><br/>
                                            </span>
                                        </div>
                                    </div>
                                
                                </div>
                                </div>

                                <!--Fitness Ceriticate-->
                                <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="custom_template && selected_temp_Id==426907"
                                [class.full_preview]="!full">
                                <div class="preview card" style="display: flex;flex-direction: column;">
                                    <div style="text-align: center;margin-top: 10px;"><b>Medical Fitness Certificate</b></div>
                                    <div class="Rtable Rtable--1cols" style="margin-top: 10px;">  
                                        <div class="Rtable-cell"><span class="fntsize">To Whom so ever it may concern</span></div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell" style="text-align:center"><div style="width:90px;height: 90px;margin: 0 auto;">
                                            <img class="dp"
                                            style="width:100%;height: 100%;"
                                            [src]="fit_imgURL"></div></div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">I have carefully examined <span class="fontWeight fntsize"> Mr/Mrs/Ms. {{patient.record_info.Record.label}}</span> Son of
                                            <span class="fontWeight fntsize" *ngIf="patient.demographics.father_name_family ==''">-</span>
                                            <span class="fontWeight fntsize" *ngIf="patient.demographics.father_name_family !=''">{{patient.demographics.father_name_family}}</span> Based on the examination, I certify that he is in good mental and physical health and is free from any physical defects which may interfere with his studies including the active outdoor duties required of a student</div>
                                    </div> 
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Marks of Identification <input style="width: 30%;margin-left: 2px;display:inline" class="form-control"
                                             type="text" name="identi" [(ngModel)]="fit_identification" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);">
                                        </div>
                                    </div>
                                    
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Place : <input style="width: 30%;margin-left: 2px;display:inline" class="form-control"
                                             type="text" name="identi" [(ngModel)]="fit_place" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);">
                                        </div><br/>
                                        <div class="Rtable Rtable--1cols">  
                                            <div class="Rtable-cell">Date : {{fittodayDate}}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="Rtable Rtable--4cols">
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell" style="text-align: left;"> 
                                            <span style="margin-right: 10px;">
                                            <span class="fontWeight">{{getdrName}}  </span> <br/>
                                            <span>{{getdrnum}}  </span><br/>
                                            <span>{{getdrdegree}}  </span><br/>
                                            <span>{{getdrspec}}  </span><br/>
                                            </span>
                                        </div>
                                    </div>
                                 </div>
                                </div>
                                 <!--MEdical leave student or child Ceriticate-->
                                 <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="custom_template && selected_temp_Id==426946"
                                 [class.full_preview]="!full">
                                    <div class="preview card" style="display: flex;flex-direction: column;">
                                     <div style="text-align: center;margin-top: 10px;"><b>MEDICAL CERTIFICATE FOR LEAVE <br/>
                                            OR EXTENTION OF LEAVE OR COMMUTATION OF LEAVE</b></div>
                                     <div class="Rtable Rtable--1cols" style="margin-top:10px">  
                                         <div class="Rtable-cell" style="line-height:2.7rem">I, <span class="fontWeight fntsize">{{getdrName}}</span> {{getdrnum}}, after careful personal examination of the case, hereby certify that <span class="fontWeight fntsize"> Mr/Ms. {{patient.record_info.Record.label}}, {{patient.demographics.gender}}, </span>Date of Birth <span class="fontWeight fntsize">{{patient.demographics.bday | date: "dd/MMM/yyyy"}}</span>, 
                                            is suffering from <span class="fontWeight fntsize"><input style="width: 30%;margin-left: 2px;display:inline" class="form-control" [(ngModel)]="medLeave_dises" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"
                                                ></span> and I consider that a period of absence from duty of <span class="fontWeight fntsize"><input style="width: 10%;margin-left: 2px;display:inline" class="form-control"
                                                     type="text" name="returnduty" [(ngModel)]="medLeave_days" (keypress)="avoid_special_char_only($event)"></span> days with effect from <span class="fontWeight fntsize" style="display:flex">                                            
                                                        <span class="fontWeight fntsize" style="margin-top:5px;width:15%">
                                                        <mat-form-field class="field" appearance="outline">
                                                            <!-- <mat-label>Choose a date</mat-label> -->
                                                            <input class="formBorder" matInput [min]="fitmaxDate"  (dateChange)="leavestartdatechanged($event)" [ngModel]="fitstartDate"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                    </span><span style="margin-top: 6px;"> &nbsp; is absolutely necessary for the restoration of his health.</span></span>
                                        </div>
                                     </div>
                                     <div class="Rtable Rtable--1cols">  
                                         <div class="Rtable-cell" style="display:flex"><span style="margin-top: 10px;">He will be fit to join school back on </span>
                                            <span class="fontWeight fntsize" style="margin-left:10px">
                                                <mat-form-field class="field" appearance="outline">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input class="formBorder" matInput [min]="fitminbackDate" (dateChange)="leavbackdatechanged($event)" [ngModel]="fitBack_date"
                                                        [matDatepicker]="picker2">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                                    
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>
                                                </mat-form-field>
                                            </span>
                                        </div>
                                     </div> 
                                    
                                     <div class="Rtable Rtable--4cols">
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell" style="text-align: left;"> 
                                            <span style="margin-right: 10px;">
                                            <span class="fontWeight">{{getdrName}}  </span> <br/>
                                            <span>{{getdrnum}}  </span><br/>
                                            <span>{{getdrdegree}}  </span><br/>
                                            <span>{{getdrspec}}  </span><br/>
                                            </span>
                                        </div>
                                    </div>
 
                                    </div>
                                 </div>
                                 <!--MEdical leave for adult with sign Ceriticate-->
                                 <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="custom_template && selected_temp_Id==427017"
                                 [class.full_preview]="!full">
                                    <div class="preview card" style="display: flex;flex-direction: column;">
                                    <div style="text-align: center;margin-top: 10px;"><b>MEDICAL CERTIFICATE FOR LEAVE <br/>
                                           OR EXTENTION OF LEAVE OR COMMUTATION OF LEAVE</b></div>
                                    <div class="Rtable Rtable--1cols" style="margin-top: 10px;">  
                                        <div class="Rtable-cell" style="line-height: 2.7rem;">I, <span class="fontWeight fntsize">{{getdrName}}</span> {{getdrnum}}, after careful personal examination of the case, hereby certify that <span class="fontWeight fntsize">Mr/Mrs/Ms. {{patient.record_info.Record.label}}, {{patient.demographics.gender}}, </span>Date of Birth <span class="fontWeight fntsize">{{patient.demographics.bday | date: "dd/MMM/yyyy"}}</span>, 
                                            whose signature is given below, is suffering from <span class="fontWeight fntsize"><input style="width: 30%;margin-left: 2px;display:inline" class="form-control"
                                                 type="text" name="returnduty" [(ngModel)]="adult_medLeave_disies" onkeypress="return(event.key === '.' || event.key === ',' || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode == 8 || event.charCode == 32);"></span> and I consider that a period of absence from duty of <span class="fontWeight fntsize"><input style="width: 10%;margin-left: 2px;display:inline" class="form-control"
                                                 type="text" name="returnduty" [(ngModel)]="adult_medLeave_days" (keypress)="avoid_special_char_only($event)"></span> <span style="display:flex;margin-top:10px">days with effect from 
                                                <span class="fontWeight fntsize" style="margin-left:10px;width:15%;">
                                                    <mat-form-field class="field" appearance="outline">
                                                        <!-- <mat-label>Choose a date</mat-label> -->
                                                        <input class="formBorder" matInput [min]="fitmaxDate" (dateChange)="leaveeffectdatechanged($event)" [ngModel]="leave_start_date | date:'yyyy-MM-dd'"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </span> &nbsp; is absolutely necessary for the restoration of <span class="fontWeight fntsize"> &nbsp;his</span> &nbsp; health.</span>
                                       </div>
                                    </div>
                                    <div class="Rtable Rtable--1cols">  
                                        <div class="Rtable-cell">Signature of  <span class="fontWeight fntsize">Mr/Mrs/Ms. {{patient.record_info.Record.label}}</span></div>
                                    </div> 
                                    
                                    <div class="Rtable Rtable--4cols" style="margin-top:20px">
                                        <div class="Rtable-cell" style="text-align: left;"> 
                                            <span style="margin-right: 10px;">
                                            <span class="fontWeight">{{getdrName}}  </span> <br/>
                                            <span>{{getdrnum}}  </span><br/>
                                            <span>{{getdrdegree}}  </span><br/>
                                            <span>{{getdrspec}}  </span><br/>
                                            </span>
                                        </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                        <div class="Rtable-cell"> </div>
                                    </div>
                                </div>
                                 </div>
                                 <!--Return to duty Ceriticate-->
                                 <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="custom_template && selected_temp_Id==426949"
                                 [class.full_preview]="!full">
                                 <div class="preview card" style="display: flex;flex-direction: column;">
                                     <div style="text-align: center;margin-top: 10px;"><b>MEDICAL CERTIFICATE OF FITNESS TO RETURN TO DUTY</b></div>
                                     <div class="Rtable Rtable--1cols" style="margin-top:10px">  
                                         <div class="Rtable-cell">I, <span class="fontWeight fntsize">{{getdrName}}</span> {{getdrnum}}, do hereby certify that I have carefully examined <span class="fontWeight fntsize"> Mr/Mrs/Ms. {{patient.record_info.Record.label}}, {{patient.demographics.gender}}, </span>Date of Birth <span class="fontWeight fntsize">{{patient.demographics.bday | date: "dd/MMM/yyyy"}}</span>, 
                                            whose signature is given below, and find that <span class="fontWeight fntsize">he/she</span> has recovered from his illness and is now fit to resume duties in Govt. Service. I also certify that before arriving at this decision I have examined the original medical certificate (s) and statement (s) of the case (or certified copies thereof) on which leave was granted or extended and have taken these into consideration in arriving at my decision.
                                        </div>
                                     </div>
                                     <div class="Rtable Rtable--1cols">  
                                         <div class="Rtable-cell">Signature of<span class="fontWeight fntsize"> Mr/Mrs/Ms. {{patient.record_info.Record.label}}</span></div>
                                     </div> 
                                    
                                     <div class="Rtable Rtable--4cols" style="margin-top:20px">
                                        <div class="Rtable-cell" style="text-align: left;"> 
                                            <span style="margin-right: 10px;">
                                            <span class="fontWeight">{{getdrName}}  </span> <br/>
                                            <span>{{getdrnum}}  </span><br/>
                                            <span>{{getdrdegree}}  </span><br/>
                                            <span>{{getdrspec}}  </span><br/>
                                            </span>
                                        </div>
                                         <div class="Rtable-cell"> </div>
                                         <div class="Rtable-cell"> </div>
                                         <div class="Rtable-cell"> </div>
                                     </div>
 
                                 </div>
                                 </div>
                                <!--ICS normal template-->
                                <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="preview_type==1 && ICSscreen_load"
                                    [class.full_preview]="!full">
                                    <div class="preview card" style="display: flex;flex-direction: column;">

                                        <div style="display: flex;flex-direction: row;">
                                            <div [class.disable_click]="!started"
                                                (click)="show_block != 'cc' ? set('cc'):false"
                                                style="width: 45%; cursor: pointer;">
                                                <p style="font-weight: 900;font-size: 0.9rem;" (click)="openChiefComplaints()" 
                                                    [class.print_highlight]="show_block=='cc'">Chief
                                                    Complaints:</p>
                                                <div style="display: flex;flex-direction: column;">
                                                    <p [class.selected_item]="highlight_cc&&cc_selected.length==(i+1)&&show_block=='cc'"
                                                        *ngFor="let cc_s of cc_selected;let i = index" (click)='click1(i)' (dblclick)='doubleClick1(i)'
                                                        ><span *ngIf="cc_s.is_question">?</span>{{cc_s.name}}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style="width: 45%;cursor: pointer;" [class.disable_click]="!started" 
                                                (click)="show_block != 'ex' ? set('ex'):false">
                                                <p style="font-weight: 900;font-size: 0.9rem;"  (click)="openExamination()" 
                                                    [class.print_highlight]="show_block=='ex'">Signs:
                                                </p>
                                                <div style="display: flex;flex-direction: column;">
                                                    <p [class.selected_item]="highlight_ex&&ex_selected.length==(i+1)&&show_block=='ex'"
                                                        *ngFor="let ex_s of ex_selected;let i = index"  (click)='click2(i)' (dblclick)='doubleClick2(i)'
                                                       >{{ex_s.name}}
                                                    </p>
                                                </div>
                                            </div>

                                            <!-- <div style="display: flex;justify-content:space-evenly;width: 10%;border: 2px solid;margin-top: 2px;height:1.5rem !important;border-radius: 5px;">
                                                <div style="cursor: pointer;width:50%;" [class.disable_click]="!started" >
                                                    <button style="display: flex;width: 100%;justify-content: space-around;font-size:smaller;font-weight:600;border:none;" tabindex="1"
                                                     (click)="previousIcs();fillButton(2)" title="Past Consultation" [ngClass]="{ 'filled': isButton2Filled, 'btn3': isButton1Filled }">Past
                                                        <img class="icon_menu" src="assets/Past_Consultation.png" (click)="previousIcs()">
                                                    </button>
                                                    <p style="text-align:center;cursor:pointer;font-weight: 900;font-size: 0.9rem;" (click)="previousIcs();fillButton(2)">Past</p>
                                                </div>
                                                <div style="border-right:2px solid;"></div>
                                                <div style="cursor: pointer;width:50%;" [class.disable_click]="!started" >
                                                    <button style="display: flex;width: 100%;justify-content: space-around;font-size:smaller;font-weight:600;border:none;" tabindex="1"
                                                     (click)="clearIcs();fillButton(1)" title="New Consultation" [ngClass]="{ 'filled': isButton1Filled, 'btn3': isButton2Filled}">New
                                                       <img class="icon_menu" src="assets/New_Consultation.png" (click)="clearIcs()">
                                                   </button>
                                                   <p  style="text-align:center;cursor:pointer;font-weight: 900;font-size: 0.9rem;" (click)="clearIcs();fillButton(1)">New</p>
                                                </div>
                                            </div> -->
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;"
                                            (click)="show_block != 'diag' ? set('diag'):false">
                                            <p>
                                                <span style="font-size: 0.9rem;font-weight: 900"  (click)="openDiagnosis()" 
                                                    [class.print_highlight]="show_block=='diag'">Diagnosis: </span>
                                                <span
                                                    [class.selected_item]="highlight_diag&&diag_selected.length==(i+1)&&show_block=='diag'"
                                                    style="font-size: 0.8rem;font-size: normal;"
                                                    *ngFor="let diag_s of diag_selected; let i=index;"
                                                    (click)='click3(i)'
                                                    (dblclick)='doubleClick3(i)'>{{diag_s.name}}<span
                                                        *ngIf="diag_selected.length!=(i+1)">, </span></span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;"
                                            (click)="show_block != 'inv' ? set('inv'):false">
                                            <p>
                                                <span [class.print_highlight]="show_block=='inv'"  (click)="openInvestigation()" 
                                                    style="font-size: 0.9rem;font-weight: 900">Investigations: </span>
                                                <span
                                                    [class.selected_item]="highlight_inv&&inv_selected.length==(i+1)&&show_block=='inv'" (click)='click4(i)'
                                                    (dblclick)='doubleClick4(i)'
                                                    *ngFor="let inv_s of inv_selected; let i=index;">{{inv_s.name}}<span
                                                        *ngIf="inv_selected.length!=(i+1)">, </span></span>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;"
                                            (click)="show_block != 'pres' ? set('pres'):false">
                                            <p style="font-weight: 900;font-size: 0.9rem;"  (click)="openPrescription()" 
                                                [class.print_highlight]="show_block=='pres'">Drug
                                                Prescription
                                                :</p>

                                            <table class="pres">
                                                <tr style="font-weight: 600;font-size: 0.9rem;" (click)="openPrescription()">
                                                    <td>
                                                        <p>S. No</p>
                                                    </td>
                                                    <td>
                                                        <p>Drug and Strength</p>
                                                    </td>
                                                    <td *ngIf="!durationAlone">
                                                        <p>Dosage, Frequency & Duration</p>
                                                    </td>
                                                    <td *ngIf="durationAlone">
                                                        <p>Dosage & Frequency</p>
                                                    </td>
                                                    <td *ngIf="durationAlone">
                                                        <p>Duration</p>
                                                    </td>
                                                    <!-- <td *ngIf="(displayTotal&&dmail!='rx122340@rxcarenet.org')&&(displayTotal&&dmail!='rx4@rxcarenet.org')&&(displayTotal&&dmail!='rx9978@rxcarenet.org')&&(displayTotal&&dmail!='rx483098@rxcarenet.org')">
                                                        <p>Total</p>
                                                    </td> -->
                                                    <!-- only in local for Sky -->
                                                    <td *ngIf="dmail =='rx494693@rxcarenet.org'|| dmail =='rx4@rxcarenet.org'">
                                                        <p title="Route of Administration">ROA</p>
                                                    </td>
                                                    <td>
                                                        <p>Time</p>
                                                    </td>
                                                </tr>

                                                <tr *ngFor="let pres_s of pres_selected;let indexOfelement=index;let i=index;"
                                                    [class.selected_item]="highlight_pres&&pres_selected.length==(i+1)&&show_block=='pres'"
                                                    (click)='click5(i)' (dblclick)='doubleClick5(i)'
                                                    >
                                                    <td>
                                                        <p>{{indexOfelement+1}}</p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="drugShowType==1">{{pres_s.brand}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}</p>

                                                        <p *ngIf="drugShowType==2">{{pres_s.generic}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}</p>

                                                        <p *ngIf="drugShowType==3">{{pres_s.generic}} - {{pres_s.brand}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="!durationAlone">
                                                        <p>
                                                            {{pres_s.shortFreq}} ,
                                                            {{pres_s.duration}} {{pres_s.duration_type}}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="durationAlone">
                                                        <p >
                                                            {{pres_s.shortFreq}}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="durationAlone">
                                                        <p>{{pres_s.duration}} {{pres_s.duration_type}}
                                                        </p>
                                                    </td>

                                                    <!-- <td *ngIf="(displayTotal&&dmail!='rx122340@rxcarenet.org')&&(displayTotal&&dmail!='rx4@rxcarenet.org')&&(displayTotal&&dmail!='rx9978@rxcarenet.org')&&(displayTotal&&dmail!='rx483098@rxcarenet.org')">
                                                        <p>{{pres_s.quantity}}</p>
                                                    </td> -->

                                                    <td *ngIf="!fullFreq">
                                                        <p *ngIf="pres_s.when=='After Food'">AF</p>
                                                        <p *ngIf="pres_s.when=='Before Food'">BF</p>
                                                        <p *ngIf="pres_s.when=='With Food'">WF</p>
                                                        <p *ngIf="pres_s.when=='SOS'">SOS</p>
                                                        <p *ngIf="pres_s.when=='Other'">Other</p>
                                                        <p *ngIf="pres_s.when=='Not Related to Food'">NRF</p>
                                                        <p *ngIf="pres_s.when.startsWith('Time')">{{pres_s.when}}</p>

                                                        <p *ngIf="pres_s.when=='AF'">AF</p>
                                                        <p *ngIf="pres_s.when=='BF'">BF</p>
                                                        <p *ngIf="pres_s.when=='WF'">WF</p>
                                                        <p *ngIf="pres_s.when=='NRF'">NRF</p>

                                                    </td>
                                                    <td *ngIf="dmail =='rx494693@rxcarenet.org'|| dmail =='rx4@rxcarenet.org'">{{pres_s.route}}</td>
                                                    <td *ngIf="fullFreq">
                                                        <p *ngIf="pres_s.when=='After Food'">After Food</p>
                                                        <p *ngIf="pres_s.when=='Before Food'">Before Food</p>
                                                        <p *ngIf="pres_s.when=='With Food'">With Food</p>
                                                        <p *ngIf="pres_s.when=='SOS'">SOS</p>
                                                        <p *ngIf="pres_s.when=='Other'">Other</p>
                                                        <p *ngIf="pres_s.when=='Not Related to Food'">Not Related to
                                                            Food</p>
                                                        <p *ngIf="pres_s.when.startsWith('Time')">{{pres_s.when}}</p>

                                                        <p *ngIf="pres_s.when=='AF'">AF</p>
                                                        <p *ngIf="pres_s.when=='BF'">BF</p>
                                                        <p *ngIf="pres_s.when=='WF'">WF</p>
                                                        <p *ngIf="pres_s.when=='NRF'">NRF</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;"
                                            (click)="show_block != 'adv' ? set('adv'):false">
                                            <p>
                                                <span [class.print_highlight]="show_block=='adv'"   (click)="openAdvice()" 
                                                    style="font-size:0.9rem;font-weight: 900;">Advice: </span>

                                                <span style="display: flex;flex-direction: column;">
                                                    <span
                                                        [class.selected_item]="highlight_adv&&adv_selected.length==(i+1)&&show_block=='adv'"
                                                        *ngFor="let adv_s of adv_selected; let i=index;" (click)='click6(i)' (dblclick)='doubleClick6(i)'
                                                        >
                                                        {{i+1}}. {{adv_s.instructions}}
                                                    </span>
                                                </span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;"
                                            (click)="show_block != 'follow' ? set('follow'):false">
                                            <p>
                                                <span [class.print_highlight]="show_block=='follow'"    (click)="openFollowup()"
                                                    style="font-size: 0.9rem;font-weight: 900;">Follow up: </span>
                                                <span>{{follow_text_week}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!--ICS soap template-->
                                <div style="height: calc(100vh - 24rem);overflow-y: auto;" *ngIf="preview_type==2 && ICSscreen_load"
                                    [class.full_preview]="!full">
                                    <div class="preview card"
                                        style="display: flex;flex-direction: column;width: 100%;">
                                        <!-- <p class="selectedTemp" style="color:#01C034;font-weight: 500;">Note: Double
                                            click to remove entry under all categories.
                                            To edit
                                            prescription, click once</p> -->

                                        <div [class.disable_click]="!started" style="margin-top: 1rem;">

                                            <p>
                                                <span style="font-size: 1rem;font-weight: 600;">Subjective: </span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started"
                                            (click)="show_block != 'cc' ? set('cc'):false" style="cursor: pointer;">

                                            <p>
                                                <span style="font-size: 0.9rem;font-weight: 500"  (click)="openChiefComplaints()"
                                                    [class.print_highlight]="show_block=='cc'">Chief
                                                    Complaints: </span>
                                                <span
                                                    [class.selected_item]="highlight_cc&&cc_selected.length==(i+1)&&show_block=='cc'"
                                                    style="font-size: 0.8rem;font-size: normal;" (click)='click1(i)' (dblclick)='doubleClick1(i)'
                                                    *ngFor="let cc_s of cc_selected; let i=index;">    <span *ngIf="cc_s.is_question">?</span>{{cc_s.name}}<span
                                                        *ngIf="cc_selected.length!=(i+1)">, </span></span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">

                                            <p style="word-break: break-all;" >
                                                <span [class.print_highlight]="show_block=='hpi'" (click)="openHPI()"
                                                    style="font-size: 0.9rem;font-weight: 600;">
                                                    History of Present Illness:
                                                </span>

                                                <span style="font-size: 0.8rem;">{{hpi}}</span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">

                                            <p style="word-break: break-all;">
                                                <span [class.print_highlight]="show_block=='pmh'" (click)="openPMH()"
                                                    style="font-size: 0.9rem;font-weight: 600;">Past
                                                    Medical Hx:
                                                </span><span
                                                    *ngIf="current_medication!='[]'">{{current_medication}}</span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">

                                            <p (click)="openAllergies1()">
                                                <span style="font-size: 0.9rem;font-weight: 600;">Allergies:
                                                </span>
                                            </p>

                                            <table style="width: 100%;font-size: 0.8rem;
                                            font-family: 'Poppins';" class="pres" (click)="openAllergies1()">
                                                <tr style="font-size: 0.8rem;font-weight:600">
                                                    <td>Category</td>

                                                    <td>Item</td>
                                                    <td>Reaction</td>
                                                </tr>

                                                <tr *ngFor="let a of allergyTable;let i=index">
                                                    <td>{{a.category | titlecase}}</td>
                                                    <td>
                                                        <p style="word-break:break-all;text-align: unset;">{{a.name}}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p style="word-break:break-all;text-align: unset;">
                                                            {{a.reaction}}</p>
                                                    </td>

                                                </tr>
                                            </table>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">

                                            <p style="word-break: break-all;">
                                                <span [class.print_highlight]="show_block=='sh'" (click)="openSH()"
                                                    style="font-size: 0.9rem;font-weight: 600;">Social
                                                    History: </span>
                                                <span>{{social_history}}</span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">

                                            <p style="word-break: break-all;">
                                                <span [class.print_highlight]="show_block=='cm'" (click)="openCM()"
                                                    style="font-size: 0.9rem;font-weight: 600;">Current
                                                    Medications:
                                                </span>
                                                <span *ngIf="past_medical_hx!='[]'">{{past_medical_hx}}</span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="margin-top: 1rem;">

                                            <p>
                                                <span style="font-size: 1rem;font-weight: 600;">Objective: </span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;">

                                            <p>
                                                <span style="font-size: 0.9rem;font-weight: 500;" (click)="openExamination()"
                                                    [class.print_highlight]="show_block=='ex'">Clinical Exam: </span>
                                                <span
                                                    [class.selected_item]="highlight_ex&&ex_selected.length==(i+1)&&show_block=='ex'"
                                                    style="font-size: 0.8rem;font-size: normal;"
                                                    *ngFor="let ex_s of ex_selected; let i=index;" (click)='click2(i)'
                                                    (dblclick)='doubleClick2(i)'>{{ex_s.name}}<span
                                                        *ngIf="ex_selected.length!=(i+1)">, </span></span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer">

                                            <p style="font-size: 0.9rem;font-weight: 500;">
                                                <span>HR: <input type="number" [(ngModel)]="heart_rate" maxlength="3"
                                                        (keypress)="validateNumbers($event)"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        class="vital_i">/min</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>RR:
                                                    <input type="number" [(ngModel)]="respiratory_rate" maxlength="3"
                                                        (keypress)="validateNumbers($event)"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        class="vital_i">/min</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>SaO2:
                                                    <input type="number" [(ngModel)]="spo2" maxlength="3"
                                                        (keypress)="validateNumbers($event)"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        class="vital_i">%RA</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>BP:
                                                    <input type="text" [(ngModel)]="blood_pressure" maxlength="7"
                                                        (keypress)="validateNumberAndForwardSlash($event)"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        class="vital_i">mmHg</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Wt:
                                                    <input maxlength="3" (keypress)="validateNumbers($event)"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        [(ngModel)]="weight" type="number" class="vital_i">kgs</span>
                                            </p>

                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">
                                            <p>
                                                <span style="font-size: 0.9rem;font-weight: 600" (click)="openDiagnosis()"
                                                    [class.print_highlight]="show_block=='diag'">Assessment: </span>
                                                <span
                                                    [class.selected_item]="highlight_diag&&diag_selected.length==(i+1)&&show_block=='diag'"
                                                    style="font-size: 0.8rem;font-size: normal;"
                                                    *ngFor="let diag_s of diag_selected; let i=index;"
                                                    (click)='click3(i)'
                                                    (dblclick)='doubleClick3(i)'>{{diag_s.name}}<span
                                                        *ngIf="diag_selected.length!=(i+1)">, </span></span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">
                                            <p>
                                                <span [class.print_highlight]="show_block=='adv'" (click)="openAdvice()"
                                                    style="font-size:0.9rem;font-weight: 600;">
                                                    Plan: </span>
                                                <span style="display: flex;flex-direction: column;">
                                                    <span
                                                        [class.selected_item]="highlight_adv&&adv_selected.length==(i+1)&&show_block=='adv'"
                                                        *ngFor="let adv_s of adv_selected; let i=index;"
                                                        (click)='click6(i)' (dblclick)='doubleClick6(i)'>
                                                        {{i+1}}. {{adv_s.instructions}}
                                                    </span>
                                                </span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;">
                                            <p>
                                                <span [class.print_highlight]="show_block=='follow'" (click)="openFollowup()"
                                                    style="font-size: 0.9rem;font-weight: 600;">Follow up: </span>
                                                <span>{{follow_text}}</span>
                                            </p>
                                        </div>

                                        <div [class.disable_click]="!started" style="cursor: pointer;margin-top: 1rem;">
                                            <p style="font-weight: 600;font-size: 0.9rem;" (click)="openPrescription()"
                                                [class.print_highlight]="show_block=='pres'">Prescription
                                                :</p>

                                            <table class="pres">
                                                <tr style="font-weight: 600;font-size: 0.9rem;"  (click)="openPrescription()"
                                                    *ngIf="pres_selected.length>0">
                                                    <td>
                                                        <p>S. No</p>
                                                    </td>
                                                    <td>
                                                        <p>Drug and Strength</p>
                                                    </td>
                                                    <td>
                                                        <p>Dosage, Frequency & Duration</p>
                                                    </td>
                                                    <td *ngIf="(displayTotal&&dmail!='rx122340@rxcarenet.org')&&(displayTotal&&dmail!='rx483098@rxcarenet.org')">
                                                        <p>Total</p>
                                                    </td>
                                                    <td>
                                                        <p>Time</p>
                                                    </td>
                                                </tr>

                                                <tr *ngFor="let pres_s of pres_selected;let indexOfelement=index;let i=index;"
                                                    [class.selected_item]="highlight_pres&&pres_selected.length==(i+1)&&show_block=='pres'"
                                                    (click)='click5(i)' (dblclick)='doubleClick5(i)'>
                                                    <td>
                                                        <p>{{indexOfelement+1}}</p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="drugShowType==1">{{pres_s.brand}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}</p>

                                                        <p *ngIf="drugShowType==2">{{pres_s.generic}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}</p>

                                                        <p *ngIf="drugShowType==3">{{pres_s.generic}} - {{pres_s.brand}} <span *ngIf="pres_s.dose">,</span> {{pres_s.dose}} {{pres_s.dose_option}}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {{pres_s.shortFreq}} ,
                                                            {{pres_s.duration}} {{pres_s.duration_type}}
                                                        </p>
                                                    </td>

                                                    <td *ngIf="(displayTotal&&dmail!='rx122340@rxcarenet.org')&&(displayTotal&&dmail!='rx483098@rxcarenet.org')">
                                                        <p>{{pres_s.quantity}}</p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="pres_s.when=='After Food'">AF</p>
                                                        <p *ngIf="pres_s.when=='Before Food'">BF</p>
                                                        <p *ngIf="pres_s.when=='With Food'">WF</p>
                                                        <p *ngIf="pres_s.when=='SOS'">SOS</p>
                                                        <p *ngIf="pres_s.when=='Other'">Other</p>
                                                        <p *ngIf="pres_s.when=='Not Related to Food'">NRF</p>
                                                        <p *ngIf="pres_s.when.startsWith('Time')">{{pres_s.when}}</p>

                                                        <p *ngIf="pres_s.when=='AF'">AF</p>
                                                        <p *ngIf="pres_s.when=='BF'">BF</p>
                                                        <p *ngIf="pres_s.when=='WF'">WF</p>
                                                        <p *ngIf="pres_s.when=='NRF'">NRF</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                    </div>


                                </div>

                            </div>

                            <div class="col-lg-6  col-md-6 suggestioncol">
                                <div class="suggestions">
                                    <div class="templates">
                                        <mat-menu #menu="matMenu">
                                            <div tabindex="1" (click)="$event.stopPropagation()" class="rectangle">
                                                <input (input)="filter($event.target.value)"
                                                    placeholder="Search Templates">
                                            </div>
                                            <hr class="menu_hr">

                                            <button class="menu_item" *ngFor="let template of temp"
                                                mat-menu-item>{{template}}</button>
                                            <hr class="menu_hr">
                                        </mat-menu>
                                    </div>


                                    <div *ngIf="show_block=='hpi'" class="cc_list">

                                        <p style="margin-top: 0.5rem;">History of Present Illness</p>

                                        <textarea [(ngModel)]="hpi2" maxlength="1000"
                                            style="width: 100%;margin-top: 0.5rem;height: 10rem;">

                                        </textarea>

                                        <div style="margin-top: 0.5rem;">
                                            <button class="btn btn-primary" (click)="save_add_hpi()">Save</button>
                                        </div>
                                    </div>

                                    <div *ngIf="show_block=='pmh'" class="cc_list">

                                        <div style="height: calc(100vh - 26rem);overflow:auto;margin-bottom: 0.5rem;width: 100%;"
                                            [class.full_soap_block]="!full">

                                            <div *ngFor="let p of pmhdata;let i=index" style="width: 100%;">

                                                <div *ngIf="p.question_type=='checkbox'">
                                                    <p>{{p.description}}</p>

                                                    <div style="display: flex;flex-wrap: wrap;">
                                                        <div *ngFor="let opt of p.options;let j=index"
                                                            style="width: 50%;display: flex;margin-bottom: 0.25rem;">
                                                            <mat-checkbox [checked]="opt.checkbox_value==1"
                                                                (change)="optionToggle(i,j)"
                                                                (change)="opt.checkbox_title=='None'?none(i,j):''">
                                                            </mat-checkbox>
                                                            <p style="color: #000;margin-left: 0.5rem;line-height: 16px;cursor: pointer;"
                                                                (click)="optionToggle(i,j)"
                                                                (click)="opt.checkbox_title=='None'?none(i,j):''">
                                                                {{opt.checkbox_title}}</p>

                                                        </div>
                                                    </div>


                                                </div>

                                                <div *ngIf="p.question_type=='text'">
                                                    <p>{{p.description | titlecase }}</p>
                                                    <textarea [(ngModel)]="p.answer" maxlength="1000"
                                                        style="height: 5rem;width: 100%;">

                                                </textarea>
                                                </div>

                                            </div>

                                        </div>

                                        <div><button class="btn btn-primary" (click)="savePMH()">Save</button></div>
                                    </div>

                                    <div *ngIf="show_block=='sh'" class="cc_list">
                                        <div style="height: calc(100vh - 26rem);overflow:auto;margin-bottom: 0.5rem;"
                                            [class.full_soap_block]="!full">
                                            <div *ngFor="let s of shdata;let i=index" style="width: 100%;">

                                                <div *ngIf="s.question_type=='radio_button'"
                                                    style="margin-bottom: 0.5rem;">
                                                    <p style="font-weight: 600;">{{s.description}}</p>

                                                    <div *ngFor="let o of s.options;let j=index">
                                                        <div>
                                                            <input [name]="'name'+i" [id]="'name'+i"
                                                                [value]="o.radio_button_title" [(ngModel)]="s.answer"
                                                                (change)="radioSubmit(o.radio_button_title,i,j)"
                                                                style="cursor:pointer" class="radio" type="radio">
                                                            <label>{{o.radio_button_title}} </label>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="s.question_type=='number'" style="margin-bottom: 0.5rem;">
                                                    <p style="font-weight: 600;">{{s.description}}</p>

                                                    <div>
                                                        <input type="number"
                                                            [maxlength]="s.question_id=='990d8d31-e22c-42bf-bf8f-b98fb512103e'?4:2"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                            [(ngModel)]="s.answer">
                                                    </div>

                                                </div>

                                                <div *ngIf="s.question_type=='checkbox ' || s.question_type=='checkbox'"
                                                    style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

                                                    <p>{{s.description}}</p>

                                                    <div *ngFor="let opt of s.options;let j=index">
                                                        <mat-checkbox [checked]="opt.checkbox_value==1"
                                                            (change)="optionToggle1(i,j)"
                                                            (change)="opt.checkbox_title=='None'?none1(i,j):notnone1(i,j)">
                                                            <p style="color: #000;">{{opt.checkbox_title}}</p>
                                                        </mat-checkbox>
                                                    </div>
                                                </div>

                                                <div *ngIf="s.question_type=='text'"
                                                    style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

                                                    <p style="font-weight: 600;">{{s.description}}</p>

                                                    <textarea maxlength="200" [(ngModel)]="s.answer"
                                                        style="height: 5rem;width: 100%;">

                                                    </textarea>

                                                </div>

                                                <div *ngIf="s.question_type=='checkbox+number'"
                                                    style="margin-bottom: 0.5rem;">

                                                    <div *ngFor="let opt of s.options;let j=index"
                                                        style="display: flex;margin-bottom: 0.5rem;">

                                                        <mat-checkbox [checked]="opt.checkbox_value==1"
                                                            (change)="optionToggle1(i,j)"
                                                            (change)="opt.checkbox_title=='None'?none1(i,j):''"
                                                            style="margin-right: 0.5rem;">
                                                            <p style="color: #000;">{{opt.checkbox_title}}</p>
                                                        </mat-checkbox>

                                                        <p style="color: #000;margin-right: 0.5rem;">
                                                            {{opt.number_title}}</p>

                                                        <input type="number" maxlength="2"
                                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                            style="width: 3rem;margin-right: 0.5rem;"
                                                            [(ngModel)]="opt.number_value">


                                                    </div>
                                                </div>

                                                <div *ngIf="s.question_type=='date'" style="margin-bottom: 0.5rem;">
                                                    <p style="font-weight: 600;">{{s.description}}</p>
                                                    <input tabindex="1" max="{{date4}}" [(ngModel)]="s.answer"
                                                        onkeydown="return false" (keydown.enter)="followDate()"
                                                        type="date">
                                                </div>

                                            </div>
                                        </div>


                                        <div><button class="btn btn-primary" (click)="saveSH()">Save</button></div>
                                    </div>

                                    <div *ngIf="show_block=='cm'" class="cc_list">
                                        <div style="height: calc(100vh - 26rem);overflow:auto;margin-bottom: 0.5rem;"
                                            [class.full_soap_block]="!full">
                                            <div *ngFor="let c of cmdata;let i=index" style="width: 100%;">

                                                <div *ngIf="c.question_type=='text+ text'"
                                                    style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

                                                    <div style="width: 100%" *ngIf="pres_copy_cm1.length>0">
                                                        <div class="div_keyboard">
                                                            <p>Drugs from Previous Consultations:</p>

                                                            <div class="cc_items2_box"
                                                                style="max-height: 7.5rem;overflow: auto;height: unset;">
                                                                <div class="cc_items2" infiniteScroll
                                                                    [infiniteScrollDistance]="2"
                                                                    [infiniteScrollThrottle]="50"
                                                                    (scrolled)="onScroll5()"
                                                                    [infiniteScrollDisabled]="isFullListDisplayed">

                                                                    <div #pres_item
                                                                        class="cc_item chip chip-md success-color white-text  example z-depth-2 mr-1"
                                                                        *ngFor="let pres of pres_copy_cm1;let indexOfelement=index;let i as index"
                                                                        tabindex="1"
                                                                        (click)="update_pres_cm1(indexOfelement,i)">
                                                                        {{pres.name}}
                                                                    </div>
                                                                </div>


                                                            </div>

                                                            <div class="row" *ngIf="pres_toggle=='alp'"
                                                                style="display: none;">
                                                                <input class="keyboard col-lg-11 col-md-11"
                                                                    [(ngModel)]='chars5' (keydown.enter)="save_pres()"
                                                                    (input)="filter_key5()">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p style="margin-right: 0.5rem;">{{c.description}}</p>

                                                    <table class="pres" style="width: 100%;">
                                                        <tr style="font-weight: 600;font-size: 0.9rem;">
                                                            <td>
                                                                <p>S. No</p>
                                                            </td>
                                                            <td>
                                                                <p>Drug and Strength</p>
                                                            </td>
                                                            <td>
                                                                <p>Dosage & Frequency</p>
                                                            </td>

                                                        </tr>

                                                        <tr style="cursor: pointer;"
                                                            *ngFor="let pres_s of pres_selected_soap;let indexOfelement=index;let i=index;"
                                                            [class.selected_item]="highlight_pres&&pres_selected_soap.length==(i+1)&&show_block=='pres'">
                                                            <td (click)='click_soap(i)'
                                                                (dblclick)='doubleClick_soap(i)'>
                                                                <p>{{indexOfelement+1}}</p>
                                                            </td>
                                                            <td (click)='click_soap(i)'
                                                                (dblclick)='doubleClick_soap(i)'>
                                                                <p>{{pres_s.brand}}</p>
                                                            </td>
                                                            <td (click)='click_soap(i)'
                                                                (dblclick)='doubleClick_soap(i)'>
                                                                <p>{{pres_s.dose}} {{pres_s.dose_option}}
                                                                    <span *ngIf="pres_s.dose_option"> , </span>
                                                                    {{pres_s.frequency}}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div><button class="btn btn-primary" (click)="saveCM()">Save</button></div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="labreports" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog shadow-top-right" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <div class="col-sm-4 col-md-4 col-lg-4">
                                            <p class="name">Lab Reports</p>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-6 text-center">
                                            <button type="button" class="btn btn-outline-info ml-3">ALL</button>
                                            <button type="button" class="btn btn-outline-info ml-3">UNSEEN</button>
                                            <button type="button" class="btn btn-outline-info ml-3">ABNORMAL</button>
                                        </div>
                                        <div class="col-sm-1 col-md-1 col-lg-1">
                                            <button type="button" class="btn btn-outline-info">Upload Reports</button>
                                        </div>
                                        <div class="col-sm-1 col-md-1 col-lg-1">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal-body">

                                        <br><br>
                                        <div class="row">
                                            <p *ngIf="newAttachment.length == 0" class="text-center"
                                                style="width: 100%;">No
                                                Records Found</p>
                                            <table class="table" *ngIf="newAttachment.length > 0">

                                                <tbody>
                                                    <tr>
                                                        <td class="text-secondary border_top text-center">S.No</td>
                                                        <td class="text-secondary border_top text-center">TESTS</td>
                                                        <td class="text-secondary border_top text-center">DATE & TIME
                                                        </td>
                                                        <td class="text-secondary border_top text-center">STATUS</td>
                                                        <td class="text-secondary border_top text-center">COMMENTS</td>
                                                        <td class="text-secondary border_top text-center">ATTACHMENTS
                                                        </td>
                                                    </tr>
                                                    <tr *ngFor="let data of newAttachment; index as i">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td class="text-center">{{data.labtest}}</td>
                                                        <td class="text-center">{{data.date | date:'dd MMM yyyy'}}</td>
                                                        <td class="text-center"></td>
                                                        <td class="text-center">{{data.comments}}</td>
                                                        <td class="text-center"><button type="button"
                                                                class="btn btn-warning" tabindex="1"
                                                                (click)="view_source(data.id)"
                                                                (keydown.enter))="view_source(data.id)"><span
                                                                    class="fa fa-eye"></span>&nbsp;&nbsp;View
                                                                Source</button>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="modal fade" id="history" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog shadow-top-right" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <p class="name">History</p>
                                            </div>
                                            <div class="col-sm-7 col-md-7 col-lg-7 text-center">
                                                <button type="button"
                                                    class="btn btn-outline-info ml-3">OVERVIEW</button>
                                                <button type="button" class="btn btn-outline-info ml-3">SOCIAL</button>
                                                <button type="button" class="btn btn-outline-info ml-3">MEDICAL</button>
                                                <button type="button" class="btn btn-outline-info ml-3">FAMILY</button>
                                            </div>
                                            <div class="col-sm-1 col-md-1 col-lg-1">
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="hexagon">
                                                    HEXAGON
                                                    <div class="face1"></div>
                                                    <div class="face2"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="exampleModalCenter3" role="dialog"
                            aria-labelledby="exampleModalCenter3" aria-hidden="true" data-backdrop="true"
                            style="z-index: 1;">
                            <div class="modal-dialog shadow-top-right" role="document"
                                style="top: 10% !important; left: 4% !important;box-shadow: 2px 1px 20px 5px #78cfce;">
                                <div class="modal-content" style="padding: 10px 25px 10px 25px">
                                    <div class="modal-header" style="border: 0px !important;">
                                        <div class="col-lg-4"
                                            style="margin-bottom: auto;color: black;font-size: larger;">
                                            <span style="font-weight: bolder;font-family: Poppins;">Milestones</span>
                                        </div>
                                        <div class="col-lg-4" style="margin-bottom: auto;text-align: center;">
                                            <!-- <button class="btn btn-primary" style="width: auto;margin-right: 13px;">TDSC</button>
                                    <button class="btn btn-primary" style="width: auto;margin-right: 13px;">LEST</button>
                                    <button class="btn btn-primary" style="width: auto;">NHS</button> -->
                                        </div>
                                        <div class="col-lg-4" style="margin-bottom: auto;">
                                            <button type="button" style="width: auto;" class="close"
                                                data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" style="padding-top:30px ;">
                                        <div class="col-lg-12"
                                            style="margin-bottom: auto;color: black;font-size: medium;text-align: left;">
                                            <span *ngIf="patient"
                                                style="font-weight: bolder;font-family: Poppins;color: #1F76FF;">{{patient.demographics.name_given}}'s
                                                Timeline</span>
                                        </div>
                                    </div>
                                    <div class="row" style="padding:10px 35px 35px 17px" *ngIf="!hidetimelinedata">
                                        <div class="col-lg-12"
                                            style="border: 1px solid #bac8db;height: auto;border-radius: 6px;overflow-y: scroll;">

                                            <link href='//fonts.googleapis.com/css?family=Roboto:200,400,600'
                                                rel='stylesheet' type='text/css'>
                                            <div class="history-tl-container">
                                                <ul class="tl" style="padding-top: 90px;width: 100%;">
                                                    <li class="tl-item"
                                                        *ngFor="let item of viewmilestonepatienthistory; index as i">
                                                        <div class="row onhoverhighlightbaby"
                                                            style="margin-top: -50px;width: 97%;">
                                                            <div class="col-lg-12"
                                                                style="border: 0px solid #bac8db;border-radius: 1px;padding: 25px;box-shadow: 0px 0px 9px 5px;color: #ededed">
                                                                <div class="row" data-toggle="modal"
                                                                    data-target="#exampleModalCenter3a"
                                                                    data-backdrop="false">
                                                                    <div class="col-lg-1 col-sm-1 col-md-1 col-lg-1"
                                                                        style="text-align: center;">
                                                                        <img src="assets/images/challenge-target.svg"
                                                                            height="50px" width="50px">
                                                                    </div>
                                                                    <div class="col-4 col-sm-4 col-md-4 col-lg-5"
                                                                        (click)="openviewmilestonepicture(item.sno)"
                                                                        style="margin-bottom: auto;color: black;font-size: medium;">
                                                                        <span class="item-title"
                                                                            style="color: #347F9E;font-weight: 600;">{{item.item}}</span><br>
                                                                        <div
                                                                            style="margin-top: 12px;font-size: 12px;font-weight: 600;color: grey;">
                                                                            <span>Status&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                                                                                <span
                                                                                    [ngClass]="{'timelinestatusyes': item.status == 'Yes', 'timelinestatusno' : item.status == 'Not Yet','timelinestatussometimes' : item.status == 'Some times' }">{{item.status}}</span></span>
                                                                        </div>
                                                                        <div
                                                                            style="margin-top: 10px;font-size: 12px;font-weight: 600;color: grey;">
                                                                            <span>Comments&nbsp;&nbsp;:&nbsp;&nbsp;<span
                                                                                    style="color: black;">{{item.comments}}</span></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 col-sm-3 col-md-3 col-lg-2"
                                                                        style="padding: 0">
                                                                        
                                                                    </div>
                                                                    <div class="col-2 col-sm-2 col-md-2 col-lg-2"
                                                                        (click)="openviewmilestonepicture(item.sno)"
                                                                        style="text-align: center;z-index: 1;padding: 0;">
                                                                        <br>
                                                                        <div class="row">
                                                                            <div class="col-lg-12"
                                                                                style="font-size: 12px;font-weight: 600;color: grey;padding: 0;">
                                                                                At {{item.age.years}} year,
                                                                                {{item.age.months}} month
                                                                            </div>
                                                                        </div><br>
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div
                                                                                    style="font-size: 12px;font-weight: 600;color: grey;">
                                                                                    <span>{{item.date}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2 col-sm-2 col-md-2 col-lg-2"
                                                                        *ngIf="item.latest_image == null"
                                                                        (click)="openviewmilestonepicture(item.sno)">
                                                                        <img src="assets/images/nomilestonethumb.svg"
                                                                            height="72px" width="125px"
                                                                            style="border-radius: 7.5px;">
                                                                    </div>
                                                                    <div class="col-lg-2"
                                                                        *ngIf="item.latest_image !== null"
                                                                        (click)="openviewmilestonepicture(item.sno)">
                                                                        <img [src]="'https://api.epicare.net/helyxonapi/cloud/image/upload/'+item.latest_image"
                                                                            height="72px" width="125px"
                                                                            style="border-radius: 7.5px;">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="hidetimelinedata" style="text-align: center;
                            color: red;
                            padding: 20px 20px 50px 20px;
                            font-size: 16px;font-weight: 500;">
                                        <div class="col-lg-12"
                                            style="border: 1px solid #bac8db;height: auto;min-height:100px;border-radius: 6px;padding: 50px;">
                                            <span>Milestone Questions not answered</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="exampleModalCenter6" role="dialog"
                            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog shadow-top-right" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <div class="row modal_row">
                                            <div class="col-lg-1 col-md-1">
                                                <p class="modal-title" id="exampleModalLongTitle">Growth</p>
                                            </div>
                                            <div class="col-lg-10 col-md-10 buttons">
                                                <p *ngIf="patient" class="details1">{{patient.demographics.name_given}},
                                                    {{y}}Y{{m}}M,
                                                    {{patient.demographics.gender}}
                                                <p>
                                            </div>
                                            <div class="col-lg-1 col-md-1">
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row select">
                                            <select *ngIf="month>60" (change)="changeOption()">
                                                <option>0-5 years</option>
                                                <option>5-18 years</option>
                                            </select>
                                            <select style="visibility: hidden;" *ngIf="month<=60"
                                                (change)="changeOption()">
                                            </select>
                                            <div>
                                                <i tabindex="1" (click)="printGraph()" (keydown.enter)="printGraph()"
                                                    class="fas fa-print"></i>
                                                <img tabindex="1" (click)="openGraphForm()"
                                                    (keydown.enter)="openGraphForm()" style="cursor: pointer;"
                                                    src="assets/add@2x.png">
                                            </div>
                                        </div>

                                        <div id="iframes" class="iframes">
                                            <div *ngIf="option==1">
                                                <iframe id="if1" frameborder="0" tabindex="1"
                                                    (click)="openWindow(graph1)" (keydown.enter)="openWindow(graph1)"
                                                    [src]="graph1"></iframe>
                                                <p id="p1">H/W Graph</p>
                                            </div>

                                            <div *ngIf="option==1">
                                                <iframe id="if2" frameborder="0" tabindex="1"
                                                    (click)="openWindow(graph2)" (keydown.enter)="openWindow(graph2)"
                                                    [src]="graph2"></iframe>
                                                <p id="p2">BMI Graph</p>
                                            </div>
                                            <div *ngIf="option==0">
                                                <iframe id="if3" frameborder="0" tabindex="1"
                                                    (click)="openWindow(graph3)" (keydown.enter)="openWindow(graph3)"
                                                    [src]="graph3"></iframe>
                                                <p id="p3">Height Graph</p>
                                            </div>
                                            <div *ngIf="option==0">
                                                <iframe id="if4" frameborder="0" tabindex="1"
                                                    (click)="openWindow(graph4)" (keydown.enter)="openWindow(graph4)"
                                                    [src]="graph4"></iframe>
                                                <p id="p4">Weight Graph</p>
                                            </div>
                                            <div *ngIf="option==0">
                                                <iframe id="if5" frameborder="0" tabindex="1"
                                                    (click)="openWindow(graph5)" (keydown.enter)="openWindow(graph5)"
                                                    [src]="graph5"></iframe>
                                                <p id="p5">Head Circumference Graph</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="exampleModalCenter7" role="dialog"
                            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog shadow-top-right" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <div class="row modal_row">
                                            <div class="col-lg-1 col-md-1">
                                                <p class="modal-title title" id="exampleModalLongTitle">Payments</p>
                                            </div>
                                            <div class="col-lg-10 col-md-10 buttons">
                                                <button class="btn btn-primary">All</button>
                                                <button class="btn btn-link">Due</button>
                                            </div>
                                            <div class="col-lg-1 col-md-1">
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="modal-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="th" scope="col">DATE & TIME</th>
                                                    <th class="th" scope="col">CATEGORY</th>
                                                    <th class="th" scope="col">BILL NO</th>
                                                    <th class="th" scope="col">PATIENT</th>
                                                    <th class="th" scope="col">MR NUMBER</th>
                                                    <th class="th" scope="col">DESCRIPTION</th>
                                                    <th class="th" scope="col">INVOICE VALUE</th>
                                                    <th class="th" scope="col">BILLED TO PATIENT</th>
                                                    <th class="th" scope="col">PATIENT PAYMENT</th>
                                                    <th class="th" scope="col">PENDING PAYMENT</th>
                                                    <th class="th" scope="col">PRINT</th>
                                                    <th class="th" scope="col">SEND SMS</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="payment">

                                                <tr *ngFor="let p of payment">
                                                    <td class="td blue">{{p.bill_date | date:'dd-MMM-yyyy h:mm a'}}</td>
                                                    <td class="td">{{p.category}}</td>
                                                    <td class="td">{{p.bill_no}}</td>
                                                    <td class="td">{{p.name}}</td>
                                                    <td class="td">{{p.mr_number}}</td>
                                                    <td class="td">{{p.description}}</td>
                                                    <td class="td">{{p.doctor_charges | currency:"&#8377;"}}</td>
                                                    <td class="td">{{p.bill_amount | currency:"&#8377;"}}</td>
                                                    <td class="td">{{p.fee_paid | currency:"&#8377;"}}</td>
                                                    <td class="td">{{p.fee_pending | currency:"&#8377;"}}</td>
                                                    <td class="td"><button tabindex="1" (click)="print(p.bill_no)"
                                                            (keydown.enter)="print(p.bill_no)"
                                                            class="btn btn-primary">Print</button></td>
                                                    <td class="td"><button tabindex="1" (click)="sms(p.bill_no)"
                                                            (keydown.enter)="sms(p.bill_no)"
                                                            class="btn btn-primary">Send
                                                            SMS</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="Obstgynec" role="dialog" aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true" data-backdrop="true" style="z-index: 1;">
                            <div class="modal-dialog shadow-top-right" role="document"
                                style="top: 10% !important; left: 4% !important;">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <div class="col-sm-8 col-md-8 col-lg-8" style="top: 15px;">
                                            <p class="name" style="color: #1A73FF !important; ">OBSTETRICS AND
                                                GYNECOLOGY
                                                RECORDS</p>
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3">
                                        </div>
                                        <div class="col-sm-1 col-md-1 col-lg-1">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                                (click)="clearallobsgynec()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="obstgynecpopup" style="margin-bottom: 25px;">
                                        <div class="col-lg-2" style="border-right: 1px solid #dcd7d7; height: auto;">
                                            <div class="obsrecsidemenu" style="padding: 17px 17px 17px 17px;cursor: pointer;margin-left: -16px;
                                        margin-right: -16px;"
                                                [ngClass]="{'selectobsrec': highlightObs == true, 'deselectobsrec' : highlightObs == false }"
                                                (click)="toggleonclick('obstetric');get_obsthistory();get_currentpregnancy();get_currentpregnancyRiskdetails();get_currentpregnancyRecords();get_scanreportfindingdata()">
                                                <p class="name">Obstetric Record</p>
                                            </div>

                                            <div class="obsrecsidemenu" style="padding: 17px 17px 17px 17px;cursor: pointer;margin-left: -16px;
                                        margin-right: -16px;"
                                                [ngClass]="{'selectgynecrec': highlightGynec == true, 'deselectgynecrec' : highlightGynec == false }"
                                                (click)="toggleonclick('gynecology');get_gynecologydetails();get_folliclestudydetails()">
                                                <p class="name" data-target="#Gynecrecord">Gynecology Record</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-10" *ngIf="highlightObs">
                                            <form [formGroup]="obstetricHistory" (submit)="validateobst()">
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <p class="name"
                                                            style="color: #1A73FF !important; margin-top: 18px;">
                                                            Obstetrics History</p>
                                                    </div>
                                                    <div class="col-lg-2" style="text-align: right;">
                                                        <button type="submit" style="margin-top: 16px;"
                                                            class="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <br>
                                                        <div class="row">
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">LMP</span>
                                                                <input
                                                                    [ngClass]="{'highlightlmpmissing': lmpmissing == true}"
                                                                    class="form-control" type="date"
                                                                    style="width: 156px; margin-top: 7px;"
                                                                    onkeypress="return event.charCode == 8"
                                                                    [max]="obslmpmaxDate" (change)="getEdd()"
                                                                    [ngModel]='obslmp' formControlName="obslmps">
                                                            </div>
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">EDD</span>
                                                                <input class="form-control" type="date"
                                                                    style="width: 156px; margin-top: 7px;"
                                                                    onkeypress="return event.charCode == 8"
                                                                    [min]="obseddminDate" [max]="obseddmaxDate"
                                                                    (change)="changeEdd()" [(ngModel)]='obsedd'
                                                                    formControlName="obsedds">
                                                            </div>
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">Actual Delivery</span>
                                                                <input class="form-control" type="date"
                                                                    style="width: 156px; margin-top: 7px;"
                                                                    onkeypress="return event.charCode == 8"
                                                                    [min]="obsactualminDate" [max]="obsactualmaxDate"
                                                                    [(ngModel)]='obsactualdelivery'
                                                                    formControlName="obsactualdeliverys">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row">
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">Gravida</span>
                                                                <input class="form-control" type="number"
                                                                    style="width: 147px; margin-top: 7px;"
                                                                    [(ngModel)]='obsgravida'
                                                                    formControlName="obsgravidas">
                                                            </div>
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">Para</span>
                                                                <input class="form-control" name="para" type="number"
                                                                    style="width: 147px; margin-top: 7px;" min="0"
                                                                    max="10"
                                                                    (keyup)="preventInput($event);addabortpara()"
                                                                    (keypress)="numberOnly($event)"
                                                                    [(ngModel)]='obspara' formControlName="obsparas">
                                                                &nbsp; &nbsp;<span class="obsgynecaddview"
                                                                    (click)="openaddpara()"><img
                                                                        style="cursor: pointer;height: 12px; width: 17px;"
                                                                        src="assets/obsadd.svg">&nbsp;Add</span> &nbsp;
                                                                &nbsp;
                                                                <span class="obsgynecaddview"
                                                                    (click)="openviewpara()"><img
                                                                        style="cursor: pointer;height: 10px; width: 17px;"
                                                                        src="assets/obsview.svg">&nbsp;View</span>
                                                            </div>
                                                            <div class="col-lg-4 obsgynecsubheading">
                                                                <span class="sub_headings">Abortion</span>
                                                                <input class="form-control" name="abortions"
                                                                    type="number" style="width: 147px; margin-top: 7px;"
                                                                    min="0" max="10"
                                                                    (keyup)="preventInput1($event);addabortpara()"
                                                                    (keypress)="numberOnly($event)"
                                                                    [(ngModel)]='obsabortion'
                                                                    formControlName="obsabortions">
                                                                &nbsp; &nbsp;<span class="obsgynecaddview"
                                                                    (click)="openaddAbort()"><img
                                                                        style="cursor: pointer;height: 12px; width: 17px;"
                                                                        src="assets/obsadd.svg">&nbsp;Add</span> &nbsp;
                                                                &nbsp;
                                                                <span class="obsgynecaddview"
                                                                    (click)="openviewAbort()"><img
                                                                        style="cursor: pointer;height: 10px; width: 17px;"
                                                                        src="assets/obsview.svg">&nbsp;View</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <br>
                                                        <span class="obsgynecsubheading">General Notes</span>
                                                        <textarea class="form-control" name="obshistory" cols="50"
                                                            rows="7" maxlength="2000" style="margin-top: 7px;"
                                                            [(ngModel)]='obsgeneralnotes'
                                                            formControlName="obsgeneralnotess"></textarea>
                                                    </div>
                                                </div>
                                            </form>

                                            <br><br>
                                            <hr style="margin-left: -1.3%;width: 102.5%;">
                                            <form [formGroup]="currentpreg"
                                                (submit)="save_currentpregnancydetails();save_currentpregnancyRiskdetails();updatescanreport()">
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <p class="name"
                                                            style="color: #1A73FF !important; margin-top: 10px;">
                                                            Current Pregnancy</p>
                                                    </div>
                                                    <div class="col-lg-2" style="text-align: right;">
                                                        <button type="submit" style="margin-top: 16px;"
                                                            class="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-4 obsgynecsubheading">
                                                        <br>
                                                        <span class="sub_headings">Pregnancy risk</span>
                                                        <select class="form-control"
                                                            style="height: auto !important; margin-top: 7px;width: 147px;"
                                                            (change)="riskstatusmodifiedfunc()"
                                                            [(ngModel)]='obspregrisk' formControlName="obspregrisks">
                                                            <option value="" disabled selected>Select</option>
                                                            <option value="low">Low</option>
                                                            <option value="medium">Medium</option>
                                                            <option value="high">High</option>
                                                        </select>
                                                        &nbsp;<span class="obsgynecaddview"
                                                            (click)="get_currentpregnancyRiskdetails();openviewRiskhistory()"><img
                                                                style="cursor: pointer;height: 10px; width: 17px;"
                                                                src="assets/obsview.svg">&nbsp;View Change
                                                            History</span><br><br>
                                                        &nbsp;<span class="obsgynecaddview"
                                                            (click)="openviewarchivedcpRisk()"><img
                                                                style="cursor: pointer;height: 10px; width: 17px;"
                                                                src="assets/obsview.svg">&nbsp;View Past records</span>

                                                    </div>
                                                    <div class="col-lg-5">
                                                        <br>
                                                        <span class="obsgynecsubheading">General Notes</span>
                                                        <textarea class="form-control" name="obshistory" cols="50"
                                                            rows="7" maxlength="150" style="margin-top: 7px;"
                                                            [(ngModel)]='obsgeneralnotes1'
                                                            formControlName="obsgeneralnotess1"></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <br>
                                                    <span class="obsgynecsubheading">Current Pregnancy Record</span>
                                                    <table>
                                                        <thead style="background-color: #E5F0FF;" class="tableheader">
                                                            <tr style="height: 30px;">
                                                                <th style="border: 2px solid #c8e2ff;width: 6%;"><input
                                                                        type="checkbox" [(ngModel)]="masterSelected"
                                                                        name="list_name" value="m1"
                                                                        (change)="checkUncheckAll()"></th>
                                                                <th style="border: 2px solid #c8e2ff;width: 6%;">S.No
                                                                </th>
                                                                <th style="border: 2px solid #c8e2ff;width: 13%;">Date
                                                                </th>
                                                                <th style="border: 2px solid #c8e2ff;width: 13%;">BP
                                                                </th>
                                                                <th style="border: 2px solid #c8e2ff;width: 13%;">P/A
                                                                </th>
                                                                <th style="border: 2px solid #c8e2ff">Remarks</th>
                                                                <th
                                                                    style="width: 5% !important;border: 2px solid #c8e2ff">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            src="assets/obsadd.svg"
                                                                            (click)="openaddcurrentpregRecords()"></span>
                                                                </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody style="font-size: 15px; text-align: center;">
                                                            <tr style="height: 40px;"
                                                                *ngFor="let data of viewcurrentpregRecords; let i=index;">
                                                                <td style="border: 2px solid #e3edf8"><input
                                                                        type="checkbox" [(ngModel)]="data.isSelected"
                                                                        name="list_name" (change)="isAllSelected()">
                                                                </td>
                                                                <td style="border: 2px solid #e3edf8">{{i+1}}</td>
                                                                <td style="border: 2px solid #e3edf8">{{data.date}}</td>
                                                                <td style="border: 2px solid #e3edf8">{{data.bp}}</td>
                                                                <td style="border: 2px solid #e3edf8">{{data.pa}}</td>
                                                                <td
                                                                    style="border: 2px solid #e3edf8;text-align: left;padding-left: 15px;">
                                                                    {{data.remarks}}</td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                            </tr>
                                                            <tr style="height: 40px;">
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td style="border: 2px solid #e3edf8"></td>
                                                                <td
                                                                    style="width: 50px !important;border: 2px solid #e3edf8">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            src="assets/obsadd.svg"
                                                                            (click)="openaddcurrentpregRecords()"></span>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div><br>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <span class="obsgynecaddview"
                                                        style="float: right;font-weight: bold;"
                                                        (click)="openviewarchivedcpRecords()"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;View past records</span>
                                                    <span class="obsgynecaddview"
                                                        style="float: right;font-weight: bold;"
                                                        (click)="archivecpRecords()"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                                                        &nbsp;
                                                        &nbsp;</span>
                                                </div>
                                            </div> <br><br>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <br>
                                                    <span class="obsgynecsubheading">Scan Report Findings</span>
                                                    <table>
                                                        <thead style="background-color: #E5F0FF;" class="tableheader">
                                                            <tr style="height: 30px;">
                                                                <th class="obsttableheadcolor" style="width: 6%;"><input
                                                                        type="checkbox" [(ngModel)]="masterSelected2"
                                                                        name="list_name2" value="m3"
                                                                        (change)="checkUncheckAll2()"></th>
                                                                <th class="obsttableheadcolor" style="width: 6%;">S.No
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 13%;">Date
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 13%;">
                                                                    Scanned On
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 29%;">Scan
                                                                    Report
                                                                    Findings</th>
                                                                <th class="obsttableheadcolor" style="width: 13%;">Next
                                                                    Scan
                                                                    Date</th>
                                                                <th class="obsttableheadcolor" style="width: 15%;">Set
                                                                    Followup
                                                                    Date</th>
                                                                <th class="obsttableheadcolor"
                                                                    style="width: 5% !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddscanreport()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </th>
                                                            </tr>

                                                        </thead>
                                                        <tbody style="font-size: 15px; text-align: center;"
                                                            *ngIf="emptygetscan == false">
                                                            <tr style="height: 40px;"
                                                                *ngFor="let data of viewscanreportfindingdata; let i=index;">
                                                                <td class="obsttablerowcolor"><input type="checkbox"
                                                                        [(ngModel)]="data.isSelected" name="list_name2"
                                                                        (change)="isAllSelected2()"></td>
                                                                <td class="obsttablerowcolor">{{i+1}}</td>
                                                                <td class="obsttablerowcolor">{{data.date}}</td>
                                                                <td class="obsttablerowcolor">{{data.scan_date}}</td>
                                                                <td class="obsttablerowcolor"
                                                                    style="text-align: left;padding-left: 15px;">
                                                                    {{data.scan_findings}}</td>
                                                                <td class="obsttablerowcolor">{{data.next_scan_date}}
                                                                </td>
                                                                <td class="obsttablerowcolor"><input type="checkbox"
                                                                        [disabled]="enablescandetail(data.id)"
                                                                        (click)="setfollowupdate(i+1,data.next_scan_date,data.date,data.scan_date,data.scan_findings,data.isScanSelected);togglescanfollowup(data.isScanSelected);"
                                                                        [checked]="data.follow_up == 1"></td>
                                                                <td class="obsttablerowcolor"></td>

                                                            </tr>
                                                            <tr style="height: 40px;">
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"
                                                                    style="width: 50px !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddscanreport()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody style="font-size: 15px; text-align: center;"
                                                            *ngIf="emptygetscan == true">
                                                            <tr style="height: 40px;">
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"
                                                                    style="width: 50px !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddscanreport()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div><br>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <span class="obsgynecaddview"
                                                        style="float: right;font-weight: bold;"
                                                        (click)="openviewarchivedcpscan()"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;View past records</span>
                                                    <span class="obsgynecaddview" (click)="archivecpscan()"
                                                        style="float: right;font-weight: bold;"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                                                        &nbsp;
                                                        &nbsp;</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-10" *ngIf="highlightGynec">
                                            <form [formGroup]="gynecologyHistory" (submit)="save_gynecologyhistory()">
                                                <div class="row">
                                                    <div class="col-lg-10">
                                                        <p class="name"
                                                            style="color: #1A73FF !important; margin-top: 18px;">
                                                            Gynecology History</p>
                                                    </div>
                                                    <div class="col-lg-2" style="text-align: right;">
                                                        <button type="submit" style="margin-top: 16px;"
                                                            class="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <br>
                                                        <div class="row">
                                                            <div class="col-lg-6 obsgynecsubheading">
                                                                <span class="sub_headings">LMP</span>
                                                                <input class="form-control" type="date"
                                                                    style="width: 156px; margin-top: 7px;"
                                                                    onkeypress="return event.charCode == 8"
                                                                    [max]="gyneclmpmaxDate" [(ngModel)]='gyneclmp'
                                                                    formControlName="gyneclmps">
                                                            </div>
                                                            <div class="col-lg-6 obsgynecsubheading">
                                                                <span class="sub_headings">Date of Entry</span>
                                                                <input class="form-control" disabled type="date"
                                                                    style="width: 147px; margin-top: 7px;"
                                                                    onkeypress="return event.charCode == 8"
                                                                    [(ngModel)]='gynecdoe' formControlName="gynecdoes">
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row">
                                                            <div class="col-lg-6 obsgynecsubheading">
                                                                <span class="sub_headings">Menstrual History</span>
                                                                <select class="form-control"
                                                                    style="height: auto !important; margin-top: 7px;width: 170px;"
                                                                    (change)="togglemenstrualcomplaint()"
                                                                    [(ngModel)]='gynecmenstrualhistory'
                                                                    formControlName="gynecmenstrualhistorys">
                                                                    <option value="" selected>Select</option>
                                                                    <option value="premenopausal">Premenopausal</option>
                                                                    <option value="postmenopausal">Postmenopausal
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-6 obsgynecsubheading"
                                                                *ngIf="showpremenopausalcomp == true">
                                                                <span class="sub_headings">Premenopausal
                                                                    Complaint</span>
                                                                <select class="form-control"
                                                                    style="height: auto !important; margin-top: 7px;"
                                                                    [(ngModel)]='gynecmenstrualcomplaint'
                                                                    formControlName="gynecmenstrualcomplaints1">
                                                                    <option value="" selected>Select</option>
                                                                    <option value="Subfertility">Subfertility</option>
                                                                    <option value="Polymenorrhea">Polymenorrhea</option>
                                                                    <option value="Oligomenorrhea">Oligomenorrhea
                                                                    </option>
                                                                    <option value="Menorrhagia">Menorrhagia</option>
                                                                    <option value="Dysmenorrhea">Dysmenorrhea</option>
                                                                    <option value="Dyspareunia">Dyspareunia</option>
                                                                    <option value="Pelvic Organ Descent">Pelvic Organ
                                                                        Descent
                                                                    </option>
                                                                    <option value="Pruritus">Pruritus</option>
                                                                    <option value="Vaginal Discharge">Vaginal Discharge
                                                                    </option>
                                                                    <option value="Suprapubic Pain">Suprapubic Pain
                                                                    </option>
                                                                    <option value="Intermenstrual Bleeding">
                                                                        Intermenstrual
                                                                        Bleeding</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-lg-6 obsgynecsubheading"
                                                                *ngIf="showpremenopausalcomp == false">
                                                                <span class="sub_headings">Postmenopausal
                                                                    Complaint</span>
                                                                <select class="form-control"
                                                                    style="height: auto !important; margin-top: 7px;"
                                                                    [(ngModel)]='gynecmenstrualcomplaint'
                                                                    formControlName="gynecmenstrualcomplaints2">
                                                                    <option value="" selected>Select</option>
                                                                    <option value="Bleeding">Bleeding</option>
                                                                    <option value="Vaginal Discharge">Vaginal Discharge
                                                                    </option>
                                                                    <option value="Pruritus">Pruritus</option>
                                                                    <option value="Vulval burning">Vulval burning
                                                                    </option>
                                                                    <option value="Pelvic Organ Descent">Pelvic Organ
                                                                        Descent
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <br>
                                                        <span class="obsgynecsubheading">General Notes</span>
                                                        <textarea class="form-control" name="obshistory" cols="50"
                                                            rows="7" maxlength="250" [(ngModel)]='gynecnote'
                                                            formControlName="gynecnotes"
                                                            style="margin-top: 7px;"></textarea>
                                                    </div>
                                                </div>
                                            </form>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <br>
                                                    <table>
                                                        <thead style="background-color: #E5F0FF;" class="tableheader">
                                                            <tr style="height: 30px;">
                                                                <th class="obsttableheadcolor" style="width: 2%;">S.No
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 6%;">Date
                                                                    of Entry
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 6%;">LMP
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 10%;">
                                                                    Menstrual
                                                                    History</th>
                                                                <th class="obsttableheadcolor" style="width: 12%;">
                                                                    Pre/Post
                                                                    menopausal Complaint </th>
                                                                <th class="obsttableheadcolor" style="width: 17%;">Notes
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="font-size: 15px; text-align: center;">
                                                            <tr style="height: 40px;"
                                                                *ngFor="let data of viewfgynechistorydata; let i=index;">
                                                                <td class="obsttablerowcolor">{{i+1}}</td>
                                                                <td class="obsttablerowcolor">{{data.date}}</td>
                                                                <td class="obsttablerowcolor">{{data.lmp}}</td>
                                                                <td class="obsttablerowcolor">{{data.mensural_history}}
                                                                </td>
                                                                <td class="obsttablerowcolor">{{data.complaint}}</td>
                                                                <td class="obsttablerowcolor"
                                                                    style="text-align: left;padding-left: 15px;">
                                                                    {{data.notes}}
                                                                </td>
                                                            </tr>
                                                            <tr style="height: 40px;">
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div><br><br>
                                            <hr style="margin-left: -1.3%;width: 102.5%;">
                                            <div class="row">
                                                <p class="name" style="color: #1A73FF !important; margin-top: 10px;">
                                                    Follicle
                                                    Study</p>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <br>
                                                    <table>
                                                        <thead style="background-color: #E5F0FF;" class="tableheader">
                                                            <tr style="height: 30px;">
                                                                <th class="obsttableheadcolor" style="width: 50px;">
                                                                    <input type="checkbox" [(ngModel)]="masterSelected3"
                                                                        name="list_name3" value="m4"
                                                                        (change)="checkUncheckAll3()">
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 50px">S.No
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 125px;">
                                                                    Date</th>
                                                                <th class="obsttableheadcolor" style="width: 125px;">Day
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 100px;">RT
                                                                    Ovary</th>
                                                                <th class="obsttableheadcolor" style="width: 100px;">LT
                                                                    Ovary</th>
                                                                <th class="obsttableheadcolor" style="width: 100px;">
                                                                    Endometrium
                                                                </th>
                                                                <th class="obsttableheadcolor" style="width: 350px;">
                                                                    Remarks</th>
                                                                <th class="obsttableheadcolor"
                                                                    style="width: 6% !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddfolliclestudy()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="font-size: 15px; text-align: center;">
                                                            <tr style="height: 40px;"
                                                                *ngFor="let data of viewfolliclestudydata; let i=index;">
                                                                <td class="obsttablerowcolor"><input type="checkbox"
                                                                        [(ngModel)]="data.isSelected" name="list_name3"
                                                                        (change)="isAllSelected3()"></td>
                                                                <td class="obsttablerowcolor">{{i+1}}</td>
                                                                <td class="obsttablerowcolor">{{data.date}}</td>
                                                                <td class="obsttablerowcolor">{{data.day}}</td>
                                                                <td class="obsttablerowcolor">{{data.rt_ovary}}</td>
                                                                <td class="obsttablerowcolor">{{data.lt_ovary}}</td>
                                                                <td class="obsttablerowcolor">{{data.endometrium}}</td>
                                                                <td class="obsttablerowcolor"
                                                                    style="text-align: left;padding-left: 15px;">
                                                                    {{data.comments}}</td>
                                                                <td class="obsttablerowcolor"
                                                                    style="width: 50px !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddfolliclestudy()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </td>
                                                            </tr>
                                                            <tr style="height: 40px;">
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"></td>
                                                                <td class="obsttablerowcolor"
                                                                    style="width: 50px !important">
                                                                    <span class="obsgynecaddview"><img
                                                                            style="cursor: pointer;height: 12px; width: 17px;"
                                                                            (click)="openaddfolliclestudy()"
                                                                            src="assets/obsadd.svg"></span>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div><br>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <span class="obsgynecaddview"
                                                        style="float: right;font-weight: bold;"
                                                        (click)="openviewarchivedfollicle()"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;View past records</span>
                                                    <span class="obsgynecaddview" (click)="archivefollicle()"
                                                        style="float: right;font-weight: bold;"><img
                                                            style="cursor: pointer;height: 10px; width: 17px;"
                                                            src="assets/obsview.svg">&nbsp;Archive records&nbsp; &nbsp;
                                                        &nbsp;
                                                        &nbsp;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>