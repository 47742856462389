import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrintService} from '../wrapper/services/print.service';

@Component({
  selector: 'app-obstGynpres',
  templateUrl: './obstGynpres.component.html',
  styleUrls: ['./obstGynpres.component.scss']
})
export class ObstgynprescrComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  pres: any;
  vital: any;
  doct_name: any;
  reg_no: any;
  patient_name: any;
  Dob: any;
  sex: any;
  mrNo: any;
  age: any;
  complaint: any;
  complaintissues: any;
  advice: any;
  prescrDetails: any;
  followupDate: any;
  followuptext: any;
  findings: any;
  diagnosis: any;
  testadvised: any;
  todayDate: any;
  weigth: any;
  Bp: any;
  Mac: any;
  heartRate: any;
  spo2: any;
  heigt:any;
  BMI: any;
  Hc: any;
  temprature: any;
  resRate: any;
  vaccionDate: any;
  todayVaccine: any;
  vaccionmsg: any;
  vacciondata: any;
  accountEmail: any;

  lmpEdd: any;
  lmp: any;
  edd: any;
  precRisk: any;
  riskDate: any;
  riskFrom: any;
  riskTo: any;
  currentPrec: any;
  follicleStudy: any;

  constructor(route: ActivatedRoute,
              private printService: PrintService) {}

  ngOnInit() {

    var date = new Date();
    var today = date.toString().slice(4,15).split(" ");
    this.todayDate = today[1]+'-'+today[0]+'-'+today[2];

    this.accountEmail = this.printService.getaccountEmail();
    this.vital = this.printService.getvitalPrintData();
    
      this.vital.map(res => {
      this.weigth = res.weight;
      this.Bp = res.bp;
      this.Mac = res.arm_circumference;
      this.heartRate = res.heart_rate;
      this.spo2 = res.spo2;
      this.heigt = res.height;
      this.BMI = res.bmi;
      this.Hc = res.head_circumference;
      this.temprature = res.temperature;
      this.resRate = res.respiratory_rate;
    });

    this.vacciondata = this.printService.getvaccionPrintData();
    
    this.vaccionmsg = this.vacciondata[0];
    this.vaccionDate = this.vacciondata[1];

    this.todayVaccine = this.printService.getTodayVaccineData();
    
      this.pres = this.printService.getPrintData();
     
      this.pres.map(res => {
       
       this.doct_name = res.doctor_profile.full_name;
       this.reg_no = res.doctor_profile.registration_number;
       this.patient_name = res.patient_demographics.patient_name;
       this.Dob = res.patient_demographics.dob;
       this.sex = res.patient_demographics.gender;
       this.mrNo = res.patient_demographics.mr_number;
       this.age = res.patient_demographics.age;
       this.complaint = res.complaint;
       if(this.complaint != null){ 
        this.complaintissues = res.complaint.issues;
      }
       this.advice = res.advice.advice;
       this.prescrDetails = res.prescription;
       this.followupDate = res.follow_up_date;
       this.followuptext = res.follow_up_text;
       this.findings = res.findings;
       this.diagnosis = res.diagnosis;
       this.testadvised = res.lab_tests;
       
      });
    
      this.lmpEdd = this.printService.getlmpeddData();
      this.precRisk = this.printService.getriskData();
      this.currentPrec = this.printService.getprequencyData();
      this.follicleStudy = this.printService.getfollicleData();
      if(this.lmpEdd !='' )
      this.lmpEdd.map(res => {
        this.lmp = res.lmp;
        this.edd = res.edd;
      });
      
      this.riskDate = this.precRisk.date;
      this.riskFrom = this.precRisk.risk_from;
      this.riskTo = this.precRisk.risk_to;
      
      Promise.all(this.pres)
      .then(() => this.printService.onDataReady(this.patient_name,this.mrNo));
      
  }


}
