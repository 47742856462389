<div class="row row1">
    <p class="col-lg-11 col-md-11  header">Raise Payment</p>
    <img class="col-lg-1 col-md-1 img" (click)="close()" src="assets/cancel.svg">
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">Patient Name</p>
    <p class="col-lg-6 col-md-6">{{data.data.name}}</p>
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">MR Number</p>
    <p class="col-lg-6 col-md-6">{{data.data.mrn}}</p>
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">Category</p>
    <div  class="col-lg-6 col-md-6" >
        <!-- <select (change)="categoryChange()" style="width: 100%;" [(ngModel)]='category' placeholder="Choose a category">

            <option *ngFor="let d of data.data.modules" [value]="d.module">{{d.module}}</option>
            
            <option value="consultation">Consultation</option>

            <option value="vaccination">Vaccination</option> 
        
            <option value="voice_call">Voice Call</option>

            <option value="video_call_consultation">Video Call</option>

        </select> -->

        <select (change)="categoryChange()" style="width: 100%;" *ngIf="type == 2" [(ngModel)]='category' placeholder="Choose a category">
            
            <option value="consultation">Consultation</option>

            <option value="vaccination">Vaccination</option>
        
            <option value="voice_call">Voice Call</option>

            <option value="video_call_consultation">Video Call</option>

        </select>

        <select (change)="categoryChange()" style="width: 100%;" *ngIf="type == 1" [(ngModel)]='category' placeholder="Choose a category">
            
            <!-- <option value="consultation">Consultation</option>

            <option value="vaccination">Vaccination</option>  -->
        
            <option value="voice_call">Voice Call</option>

            <option value="video_call_consultation">Video Call</option>

        </select>

    </div>
       
        <!-- <p class="col-lg-6 col-md-6">{{category}}</p> -->
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">Description</p>
    <div class="col-lg-6 col-md-6">
        <input [(ngModel)]='desc'>
    </div>
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">Wallet Balance</p>
    <p class="col-lg-6 col-md-6">RS. {{data.data.balance}}</p>
</div>
<div class="row">
    <p class="col-lg-6 col-md-6">Fees</p>
    <div class="col-lg-6 col-md-6">
        <input (input)="change()" [(ngModel)]='amount' type="number">
    </div>
</div>
<div class="row row1">
    <!-- <button [disabled]="!amount" style="color: #FFF;" (click)="waive()" class="btn btn-success">Waive Fees</button> -->
    <button [disabled]="!amount" style="color: #FFF;" (click)="raise(3)" class="btn btn-success">Wallet</button>
    <button [disabled]="!amount" style="color: #FFF;" (click)="raise(4)" class="btn btn-success">Front Desk</button>
</div>