<form [formGroup]="currentpregnancyRecords" (submit)="save_currentpregRecords()">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>Date</label><span style="color:red;">&nbsp;*</span></div>
            <div class="col-lg-5">
                <div class="input-group calendaralign" style="width: 190px;">
                    <input type="date" style="font-size: 14px;" onkeypress="return event.charCode == 8" class="form-control" [max]="obscurrentpregmaxDate" [(ngModel)]='currentpregDate' formControlName="currentpregDates" required />
                </div>
            </div>
            </div>
        </div>
    
        <div class="col-lg-6">
           <div class="row">
            <div class="col-lg-3"><label>BP</label><span style="color:red;">&nbsp;*</span></div>
            <div class="col-lg-5">
                <input type="text" name="bp"  maxlength="7" onkeypress="return event.keyCode >= 47 && event.keyCode <= 57 " class="form-control" [(ngModel)]='currentpregBp' formControlName="currentpregBps" required>
            </div>
           </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>P/A</label><span style="color:red;">&nbsp;*</span></div>
            <div class="col-lg-5">
                <input type="text" maxlength="250" class="form-control" [(ngModel)]='currentpregPa' formControlName="currentpregPas"  required>
            </div>
            </div>
        </div>
    
        <div class="col-lg-6">
            <div class="row">
                <div class="col-lg-3"><label>Remarks</label></div>
            <div class="col-lg-5">
                <textarea class="form-control" name="" cols="30" rows="3" style="width: 260px;" maxlength="250" [(ngModel)]='currentpregRemark' formControlName="currentpregRemarks"
                        placeholder="General Notes here"></textarea>
            </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container" style="border: none;">
        <div class="row">
            <div class="col-lg-12"  style="text-align: center;">
                <button type="button"  (click)="openpopupforexit()" class="btn btn-default">Cancel</button> &nbsp;&nbsp;&nbsp;&nbsp;
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</form>