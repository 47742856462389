<div class="d-flex" id="wrapper">

  <div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading"><img src="assets/EPICare.png" style="width:50px;margin-left: -6px;" /></div>
    <div class="list-group list-group-flush">
      <a class="list-group-item list-group-item-action bg-light" routerLink="appointment"
        [class.highlight]="route=='/dashboard/appointment'"><img 
          src="assets/leftmenu/today.svg" style="width:25px;height: 25px;margin-left: 10px;"><span style="margin-top: 6px;display:flex">Today</span></a>
         <a class="list-group-item list-group-item-action bg-light" routerLink="patients"><img class="menuiconAlign"
          src="assets/leftmenu/patients.svg">Patients</a>
          <a class="list-group-item list-group-item-action bg-light" routerLink="chat"><img class="menuiconAlign"
            src="assets/leftmenu/chat.svg">Chat</a>
          <a class="list-group-item list-group-item-action bg-light" routerLink="settings/aptConfiq"><img class="menuiconAlign"
            src="assets/leftmenu/settings.svg">Settings</a>
          <a class="list-group-item list-group-item-action bg-light" routerLink="reports/dailycollection"><img class="menuiconAlign"
            src="assets/leftmenu/report.svg">Reports</a>
          <a class="list-group-item list-group-item-action bg-light" routerLink="drProfile"><img class="menuiconAlign"
          src="assets/leftmenu/doctor.svg"><p class="nameelipse">{{drName | uppercase}}</p></a>
    </div>
</div>

  <div id="page-content-wrapper">
    <div class="container-fluid">

       
<!-- hide doctor wallet -->
     <!-- <div cdkDrag id="dragcontainer" [style.left]=" (transform_x) + 'px'"  [style.top]=" (transform_y) + 'px'" 
     (cdkDragEnded)="dragEnd($event)" *ngIf="isShowfloat">
        <span class="full">
          <div class="row" >
            <div class="doctorprofilewallet">
              <div class="wallettoggle">
                <div>
                  <div class="row" style="height: 46px;">
                    <div class="col-md-6 col-lg-6">
                      <div class="divprof">
                        <img class="profilebubblewhite"  [src]="drImage"
                        [ngClass]="{'profilebubblegreen':docstatus === true,'profilebubblered' : docstatus === false}" 
                        (click)="drinOutPopup=true"
                          width="100" height="100" />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <img style="margin-left: 0px;" src="assets/newgreenhighlight.svg" />
                    </div>
                  </div>
                  <div class="row" style="margin-right: 0px;margin-left: 0px;">
                    <div class="col-md-6 col-lg-6" style="padding-left: 30px;">
                      <div style="padding-top: 6px;">
                        <span
                          style="font-size: 15px;color: #00A0E3;font-weight: bold;">{{dr_name}}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="row" style="margin-right: 0px;">
                        <div>
                          <span
                          style="font-family: 'Poppins', sans-serif;font-weight: 700;font-size: 14px; ">&#8377; {{walletdetails | number : '1.2'}}
                        </span>
                        </div>
                      </div>
                      <div class="row" style="margin-right: 60px;padding-top: 10px;padding-bottom: 10px;width:150%">
                        <div>
                          <span
                          style="font-family: 'Poppins', sans-serif;font-weight: 700;font-size: 14px;color: red;">&#8377; {{floatingbalance | number : '1.2'}}
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div id="popup1" class="card popupcard" style="background-color: #ffffff;" *ngIf="drinOutPopup">
              <div class="card-body" style="padding: 2rem 1.5rem">
                <div class="container-fluid" style="width: auto;">
                  <div class="row" style="margin-top: -22px;">
                    <div class="col-md-11 col-lg-11" style="margin-left: -20px;">
                      <div style="top: 5px;" class="col-md-12">
                        <img class="profilepopup"  width="100" [src]="drImage"
                          height="100" />
                      </div>
                      <h5 class="docname"
                        style="margin-left: 100px;margin-top: -60px; font-size: large; font-weight: bolder; color: black;">
                        {{dr_name}}</h5>
                      <p class="docdegree" style="margin-left: 100px; font-style: italic; color: gray;">
                        {{dr_degree}}</p>
                    </div>
                    <div class="col-md-1 col-lg-1" style="margin-left: 18px;">
                      <button type="button" class="bootbox-close-button close" aria-hidden="true" (click)="drinOutPopup=false"
                        style="color: #00a0e3;">×</button><br><br>
                    </div>
                  </div>
                  <div style="text-align: center;">
                    <p
                      style="margin-top: 3px; padding: 0px !important; color: gray; font-size: 18px; text-shadow: 0px 1px 0px grey;">
                      {{hos_name}}</p>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-lg-6">
                      <img src="assets/popupgreenhighlight.svg" class="popupgreenhighlight" />
                      <img src="assets/wallet.svg" class="popupwallet" />
                      <span class="popupmywallettext">My Wallet Balance</span>
                      <div class="row" style="position: relative;
                      left: 30px;top:10px;
                      font-size: 22px;
                      font-weight: 600;
                      font-family: 'Poppins', sans-serif;
                      color: #00A0E3;
                      word-break: break-word;">
                        <span>&#8377; {{walletdetails}}</span>
                      </div>
                    </div>
                    <div class="col-lg-6" style="margin-top: 5px;">
                      <div style="margin-left: 20px;">
                        <span
                          style="color: red;font-size: 15px;font-weight: bold;font-family: sans-serif;">OUT&nbsp;&nbsp;</span>
                        <input type="checkbox" class="switchhome" [(ngModel)]="docstatus" (click)="toggleStatus" (change)="doctor_status(docstatus)"
                          style="top: 6px;">&nbsp;&nbsp;
                        <span
                          style="color: green;font-size: 15px;font-weight: bold;font-family: sans-serif;">IN</span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>  -->
   
      <div cdkDrag id="dragcontainer2" [style.left]=" (transform_x) + 'px'"  [style.top]=" (transform_y) + 'px'" style="background-color: #EBF3FF;border: #46B8CA 2px solid;" 
      (cdkDragEnded)="dragEnd($event)">
         <span class="full">
           <div >
            <p style="font-weight: 600;font-size: 1.25rem;padding: 0.25rem;">Patients waiting count</p>
            <!-- <p style="padding: 00.25rem;font-size: 1rem;">In-Person : {{count1}}</p>
            <p style="padding: 00.25rem;font-size: 1rem;">Tele-Consultation : {{count2}}</p> -->
            <div class="row" style="margin: 0;">

              <div class="col-lg-6 col-md-6" style="padding: 0;">
                <div style="position: relative;">
                  <img style="width: 50%;margin-left: 25%;" src="assets/in.png">
                  <span style="position: absolute;bottom: 0;right: 20%;font-size: 1.2rem;background-color: #FFF;border: #46B8CA 2px solid;padding: 5px;border-radius: 50%;width: 1.75rem;height: 1.75rem;text-align: center;">{{count1}}</span>
                </div>
                <p style="font-size: 0.75rem;text-align: center;">In-Person</p>

              </div>

              <div class="col-lg-6 col-md-6" style="padding: 0;">
                <div style="position: relative;">
                  <img style="width: 50%;margin-left: 25%;" src="assets/tele.png">
                  <span style="position: absolute;bottom: 0;right: 20%;font-size: 1.2rem;background-color: #FFF;border: #46B8CA 2px solid;padding: 5px;border-radius: 50%;width: 1.75rem;height: 1.75rem;text-align: center;">{{count2}}</span>
                </div>
                <p style="font-size: 0.75rem;text-align: center;">Tele-Consultation</p>
              </div>

           
            </div>

           </div>
         </span>
       </div>

      <router-outlet  (someEvent_float)="flottoggle()" (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
  
</div>

<!-- /#footer -->
<div class="defaultfooter">
  <footer>
    <p style="font-size: 14px;margin-left: 5%;">Copyright © 2024 - <a href="https://helyxon.com" target="_blank"> <span style="color: rgb(0, 160, 227);">HELYXON</span> </a> - <a href="https://s3-us-west-2.amazonaws.com/kidscare.co/HELYXONBusinessTermsofServiceKidsCare.pdf" target="_blank" style="color: rgb(0, 160, 227);">Terms &amp; Conditions</a> - Version <a routerLink="version"><span style="color: rgb(0, 160, 227);">{{versionData}}</span></a></p>
  </footer>
</div>

