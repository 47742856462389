import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import * as moment from 'moment';


@Component({
  selector: 'app-homevisit-followup',
  templateUrl: './homevisit-followup.component.html',
  styleUrls: ['./homevisit-followup.component.scss']
})
export class HomevisitFollowupComponent implements OnInit {
  dmail: any;
  doc_id: any;
  token: any;
  urlPoints: any;
  selected: any;
  startdateInFormat;
  enddateInFormat;
  sendstartDate;
  sendendDate;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  resportArray: any =[];


  constructor(private route: ActivatedRoute,private formBuild: FormBuilder, private toastr: ToastrService, private appService: AppService,private http: HttpClient, private fb: FormBuilder, private router: Router) {
    this.urlPoints = AppConfig.settings.EndPoints;

   }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    // this.suma()
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
  }

  datesUpdated(range) {
    const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
   
   this.sendstartDate = startDate.format('YYYY-MM-DD');
   this.sendendDate = endDate.format('YYYY-MM-DD');
  }

  getReport(){

    var params = {
      account_email : this.dmail,
      start_date : this.sendstartDate,
      end_date : this.sendendDate
    }

    this.appService.getData(this.urlPoints.gethomevisitfollowup, params, this.token).subscribe((Response: any) =>{
      console.log(Response,'homevisitfollow');
      if(Response.success == 1){
        this.resportArray = Response.data
        console.log(this.resportArray);
      }
    })
  }
}
