<div class="container-fluid" style="margin-top: 10px;">
    <div class="hospitalCard">
        <div class="row">
            <div class="col-md-6">
                <h2><span class="headerClr">Order History Report</span></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div style="display:flex">
                    <span style="margin-top: 3px;">Date :</span>
                    <input style="margin-left: 10px;" type="text" ngxDaterangepickerMd [locale]="{format: 'DD-MM-YYYY'}"
                        [(ngModel)]="selected" [maxDate]='maxDate' [showCustomRangeLabel]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                        [isInvalidDate]="isInvalidDate" [showClearButton]="true"
                        (datesUpdated)="datesUpdated($event)" placeholder="Select Date" />
                </div>
            </div>
            <label>Select Platform :</label>
            <div class="col-md-2" style="bottom: 10px;">
                    
                    <select class="custom-select" [(ngModel)]="platform" (change)="platformChange($event)">
                    <option value="All">All</option>
                    <option value="Epicare">Epicare</option>
                    <option value="obs">Online Booking Site</option>
                    <option value="mh">My+Health</option>
                    </select>
            </div>
            <div class="col-md-2">Total: &#8377;{{Total_value||0}}</div>
            <div class="col-md-3">
                <div style="display:flex;float: right;margin-right:10px;margin-top:-5px">
                    <div style="margin-top: 2px;">
                        <h4>Download : </h4>
                    </div>
                    <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()">
                        <div class="addDrTxt">XLS</div>
                    </div>
                    <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()">
                        <div class="addDrTxt">PDF</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 10px;" id="mytable">
        <tr *ngIf="ordersHistory.length <= 0">
            <span class="mgsText">Data is not available</span> 
        </tr>
        <span *ngIf="ordersHistory.length > 0">
        <table class="table table-bordered">
            <thead>
                <tr class="text-center">
                    <th>MR Number</th>
                    <th>Patient Name</th>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Product Price</th>
                    <th>Platform</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center" *ngFor="let data of ordersHistory; let i =index;">
                    <td>{{data.mr_no}}</td>
                    <td>{{data.patient_name}}</td>
                    <td>{{data.order_id}}</td>
                    <td>{{data.order_date}}</td>
                    <td>{{data.product_price}}</td>
                    <td>{{data.order_platform}}</td>
                </tr>
            </tbody>
        </table>
    </span>
    </div>
</div>