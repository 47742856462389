<!-- <table>
    <tr >
    *ngFor="let data of vitalScoraData">
        <th> {{data[0].mrno}}</th>
        <th> {{data[0].baby_name}}</th>
        <th> {{data[0].gender}}</th>
        <th> {{data[0].age}}</th>
        <th> {{data[0].score}}</th>
    </tr>
</table> -->
<!-- <div>{{vitalScoraData[0].mrno}}</div> -->
<!-- <div>{{data.message.mrno}}</div>
<div>{{data.message.baby_name}}</div>
<div>{{data.message.age}}</div>
<div>{{data.message.gender}}</div>
<div>{{data.message.score}}</div> -->

<!-- <div class="container" > -->
    <!-- <div >
        <img src="assets/vitalscore_background.svg" >
    </div> -->
    <div style="margin-top:-20px;">
        <div class="row">
            <img src="assets/vitalscore_background.svg" style="width: 100%;">
        </div>
        <div class="row">
            <!-- <div class="col-lg-4"></div> -->
            <!-- <div class="col-lg-4" style="margin-left: 20px;"> -->
                <span><img src="assets/alarm.svg" style="width: 80px;height: 60px;margin-top: -20px;margin-left: 190px;"></span>
            <!-- </div> -->
            <!-- <div class="col-lg-4"></div> -->
        </div>
    </div>

    <div  style="margin-top: 10px;">
        <div class="row" style="display: flex;justify-content: center;">
            <span style="color: #707070;font-weight: bold;font-size: 25px;">Critical Patient Alert</span>
        </div>
        <div class="row" style="text-align: center;margin-top: 20px;">
           <span style="color:#707070;font-weight: semibold;"> Dear Doctor, following patient is reported very sick and waiting for your attention in reception. </span>
        </div>
        <div class="row" style="text-align: center;color:#516C89;margin-top: 5px;">
           <!-- <span style="color: ;">[</span>  -->
           <span style="margin-left: 45px;font-size: medium;">[{{data.message.mrno}} - {{data.message.baby_name}} - {{data.message.age}} - {{data.message.gender}} - <span>Critical Score:</span>{{data.message.score}}]</span>
        </div>
        <div class="row" style="margin-top: 25px;">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-primary" style="width: 100%;" (click)="closePopup()">Ok</button>
            </div>
            <div class="col-lg-4"></div>
        </div>

    </div>
<!-- </div> -->


