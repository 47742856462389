<div>
    <p class="header">Add / Edit Chronic Disease</p>
</div>


<div style="height: 20rem;overflow: auto;">
    <div *ngFor="let d of data.chronic;let i=index">
        <div class="border1" *ngIf="d.list.length==0" style="display: flex;flex-direction: column;padding: 5px;">
            <mat-checkbox [checked]="d.status==1" (change)="optionToggle(i)" class="example-margin">
                <p class="text">{{d.disease_type}}</p>
            </mat-checkbox>
            <input *ngIf="d.textbox==1" [(ngModel)]="d.textbox_value">
        </div>

        <div class="border1" *ngIf="d.list.length>0">
            <div [class.background]="d.opened==1"
                style="display: flex;flex-direction: row;justify-content: space-between;padding: 5px;">
                <div style="display: flex;">
                    <mat-checkbox [checked]="d.status==1" (change)="optionToggle(i)" class="example-margin">
                        <p class="text">{{d.disease_type}}
                            <!-- <span style="color: #007BFF;" *ngIf="d.value!=-1">&#9679; {{d.list[d.value].name}} <span *ngIf="d.list[d.value].textbox==1 && d.list[d.value].textbox_value">&#9679; {{d.list[d.value].textbox_value}}</span></span> </p> -->
                    </mat-checkbox>

                    <p style="color: #007BFF;margin-top: 2px;">
                        <span *ngFor="let d of d.list;let j=index"> <span *ngIf="d.status==1"> &#9679; {{d.name}}
                            </span></span>
                    </p>
                </div>
                <img [src]="d.opened==0?'assets/arrow-down.svg':'assets/arrow-up.svg'"
                    style="width: 1rem;cursor: pointer;margin-left: 0.5rem;" data-toggle="collapse"
                    (click)="changeOpen(i)" [attr.data-target]="'#ccollapseExample'+(i+1)" aria-expanded="false"
                    aria-controls="ccollapseExample">
            </div>

            <div style="margin-left:36px" class="collapse" [id]="'ccollapseExample'+(i+1)">
                <!-- <mat-radio-group aria-label="Select an option" [color]="'primary'" [(ngModel)]="d.value"
                    style="display: flex;flex-direction: column;margin-top: 5px;">
                    <div *ngFor="let d of d.list;let j=index" style="display: flex;flex-direction: column;">

                        <mat-radio-button [value]="j" (change)="changeRadio(i,j)">
                           <p>{{d.name}} </p>
                        </mat-radio-button>

                        <input style="margin-left: 24px;" *ngIf="d.textbox==1" [(ngModel)]="d.textbox_value">
                    </div>

                </mat-radio-group> -->

                <div *ngFor="let d of d.list;let j=index" style="display: flex;flex-direction: column;">
                    <mat-checkbox [checked]="d.status == 1" (change)="changeRadio(i,j)" class="example-margin">
                        <p class="text">{{d.name}}</p>
                    </mat-checkbox>

                    <input style="margin-left: 24px;margin-bottom: 0.5rem;" *ngIf="d.textbox==1" [(ngModel)]="d.textbox_value">

                </div>

            </div>
        </div>

        <p *ngIf="d.list.length>0 && d.disabled==true" style="color: red;margin-bottom: 0.5rem;">Please choose a sub category</p>
    </div>
</div>

<!-- <div id="main">
    <div class="container">
        <div class="accordion" id="faq">
            
            <div class="card">
                <div class="card-header" id="faqhead3" style="margin-bottom: 0;">
                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                        aria-expanded="true" aria-controls="faq3">S.S.S</a>
                </div>

                <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                    <div class="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                        3 wolf
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
    <button class="save btn" [disabled]="disabled" (click)="save()" style="width: fit-content;">Save</button>
    <button class="cancel btn" (click)="cancel()" style="width: fit-content;">Cancel</button>

</div>