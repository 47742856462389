<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>Social History</p>


    <div style="display: flex;">

        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
            (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close" (click)="close()"></i>

    </div>

</div>

<div style="max-height: 15rem;overflow: auto;">
    <div *ngFor="let s of shdata;let i=index" style="width: 100%;">

        <div *ngIf="s.question_type=='radio_button'" style="margin-bottom: 0.5rem;">
            <p style="font-weight: 600;">{{s.description}}</p>

            <div *ngFor="let o of s.options;let j=index">
                <div>
                    <input [name]="'name'+i" [id]="'name'+i" [value]="o.radio_button_title" [(ngModel)]="s.answer"
                        (change)="radioSubmit(o.radio_button_title,i,j)" style="cursor:pointer" class="radio"
                        type="radio">
                    <label>{{o.radio_button_title}} </label>
                </div>
            </div>

        </div>

        <div *ngIf="s.question_type=='number'" style="margin-bottom: 0.5rem;">
            <p style="font-weight: 600;">{{s.description}}</p>

            <div>
                <input type="number" [maxlength]="s.question_id=='990d8d31-e22c-42bf-bf8f-b98fb512103e'?4:2"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    [(ngModel)]="s.answer">
            </div>

        </div>

        <div *ngIf="s.question_type=='checkbox ' || s.question_type=='checkbox'"
            style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

            <p>{{s.description}}</p>

            <div *ngFor="let opt of s.options;let j=index">
                <mat-checkbox [checked]="opt.checkbox_value==1" (change)="optionToggle1(i,j)"
                    (change)="opt.checkbox_title=='None'?none1(i,j):notnone1(i,j)">
                    <p style="color: #000;">{{opt.checkbox_title}}</p>
                </mat-checkbox>
            </div>
        </div>

        <div *ngIf="s.question_type=='text'" style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

            <p style="font-weight: 600;">{{s.description}}</p>

            <textarea maxlength="200" [(ngModel)]="s.answer" style="height: 5rem;width: 100%;">

            </textarea>

        </div>

        <div *ngIf="s.question_type=='checkbox+number'" style="margin-bottom: 0.5rem;">

            <div *ngFor="let opt of s.options;let j=index" style="display: flex;margin-bottom: 0.5rem;">

                <mat-checkbox [checked]="opt.checkbox_value==1" (change)="optionToggle1(i,j)"
                    (change)="opt.checkbox_title=='None'?none1(i,j):''" style="margin-right: 0.5rem;">
                    <p style="color: #000;">{{opt.checkbox_title}}</p>
                </mat-checkbox>

                <p style="color: #000;margin-right: 0.5rem;">
                    {{opt.number_title}}</p>

                <input type="number" maxlength="2"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    style="width: 3rem;margin-right: 0.5rem;" [(ngModel)]="opt.number_value">


            </div>
        </div>

        <div *ngIf="s.question_type=='date'" style="margin-bottom: 0.5rem;">
            <p style="font-weight: 600;">{{s.description}}</p>
            <input tabindex="1" max="{{date4}}" [(ngModel)]="s.answer" onkeydown="return false"
                type="date">
        </div>

    </div>
</div>