<form [formGroup]="nameForm" (submit)="save()">
    <div style="display: flex;justify-content: space-between;">
        <p style="visibility: hidden;">Enter template name:</p>

        
        <img (click)="cancel()" style="width: 1.5rem;height: 1.5rem;cursor: pointer;" src="assets/cancel.svg">
    </div>

    <div style="display: flex;flex-direction: column;">
        <div style="display: flex;">
            <p style="margin-right: 1rem;width: 10rem;">Enter template name:</p>

            <input type="text" formControlName="name">

        </div>

        <div style="display: flex;margin-top: 1rem;margin-bottom: 1rem;">
            <p style="margin-right: 1rem;width: 10rem;">Enter tag name:</p>

            <input type="text" formControlName="tag">

        </div>

    </div>

    <div style="display: flex;justify-content: center;">
        <button class="btn btn-primary" type="submit">Save</button>
    </div>
    <p
        *ngIf="(nameForm.controls.name.touched||nameForm.controls.name.dirty || submitted)&&(nameForm.controls.name.errors?.required&&submitted)">
        Template name required</p>
</form>