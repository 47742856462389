import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { PubNubAngular } from 'pubnub-angular2';
import * as HighCharts from 'highcharts';
import {FormGroup, FormControl} from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';


@Component({
  selector: 'app-activityreports',
  templateUrl: './activityreports.component.html',
  styleUrls: ['./activityreports.component.scss'],
  providers: [PubNubAngular]
})
export class ActivityReportsComponent implements OnInit {
 
  routeURL:any;
  urlPoints: any;
  pubnub: PubNubAngular;
  channel: string;

  dmail;
  token;
  doc_id;

  highcharts = HighCharts;
  title = 'myHighchart';
  data = [];
  xcategories = [];
  chartOptions:any;
  param = {};
  reportData:any;
  flagStatus:any;
  
  graphTitle = ['','Tele Consultation', 'Appointment', 'Subscription', 'Registration', 'Feedback','Revenue','Chat','Waiting','IVRS'];
  yaxisTitletxt = ['','Percentage', 'Percentage', 'Count', 'Count', 'Average Rating','Rupees','Count','Average','Count'];
  minDate: Date;
 // maxDate: Date;
  

  todayDate:any;
startdateInFormat = '-';
enddateInFormat = '-';
selected: any = { startDate: null, endDate: null };
alwaysShowCalendars: boolean;
maxDate = moment();
ranges: any = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}
invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];


  constructor(private router:Router,private route: ActivatedRoute, private location: Location, pubnub: PubNubAngular, private appService: AppService) {
    this.alwaysShowCalendars = true;
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    router.events.subscribe( (event) => ( event instanceof NavigationEnd ) && this.handleRouteChange() )
  }

  handleRouteChange = () => {
     
  };

  
  ngOnInit() {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
  

  this.flagStatus = localStorage.getItem('flag_status');

  //let date = new Date();
  
  const currentYear = new Date().getFullYear();
  let getmonth = (new Date().getMonth());
  let getday = (new Date().getUTCDate());
  
  this.minDate = new Date(currentYear - 2, 0, 1);
  //this.maxDate = new Date(currentYear, getmonth, getday);

  let date = new Date();
  let momDate = moment(date).subtract(7, 'days');
  this.todayDate = momDate.format('DD/MM/YYYY');

  let sendDate = momDate.format('YYYY-MM-DD')

  let todayformatDate = moment(date).format('YYYY-MM-DD');
   this.todayDate = momDate.format('YYYY-MM-DD');
   this.selected.startDate = moment().subtract(7, 'days');
  this.selected.endDate = moment();
   
   this.getReportapi(this.todayDate,todayformatDate);
   
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
    console.log('[rangeClicked] range is : ', range);
  }

  datesUpdated(range) {
   //console.log('[datesUpdated] range is : ', range);
   
   const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
  
   let sendstartDate = startDate.format('YYYY-MM-DD');
   let sendendDate = endDate.format('YYYY-MM-DD');
   this. getReportapi(sendstartDate,sendendDate);

  }

  getReportapi(startDate,endDate) {

    this.data = [];
    this.xcategories = [];

    this.param = {
      account_email: this.dmail,
      status: this.flagStatus,
      doctor_id: this.doc_id,
      from_date: startDate,
      to_date: endDate,
    }

    this.appService.getData(this.urlPoints.getDashboardReport, this.param, this.token).subscribe((response: any) => {
      this.reportData = response;
    
      let datavalue = {
        name: '',
        data: []
      }

      let dataTeleandChatvalue = {
        name: '',
        data: [],
      }

      let dataIvrsvoicecall = {
        name: '',
        data: [],
      }

      if((this.flagStatus == 6) || (this.flagStatus == 7) || (this.flagStatus == 9)) {

        for(var i=0;i< this.reportData.length;i++){
          if((this.reportData[i].type == 'chat received') || (this.reportData[i].type == 'total revenue') || (this.reportData[i].type == 'ivrs consultation')){
            datavalue.name = this.reportData[i].type;
            for(var j=0;j< this.reportData[i].data.length;j++){
              datavalue.data.push(this.reportData[i].data[j].data);
              this.xcategories.push(this.reportData[i].data[j].date);
            }
            this.data.push(datavalue);
          }else if((this.reportData[i].type == 'chat responded') || (this.reportData[i].type == 'tele revenue') || (this.reportData[i].type == 'ivrs video call')){
            dataTeleandChatvalue.name = this.reportData[i].type;
            for(var k=0;k< this.reportData[i].data.length;k++){
              dataTeleandChatvalue.data.push(this.reportData[i].data[k].data);
              this.xcategories.push(this.reportData[i].data[k].date);
            }
            this.data.push(dataTeleandChatvalue);
          }else if((this.reportData[i].type == 'ivrs voice call')){
            dataIvrsvoicecall.name = this.reportData[i].type;
            for(var l=0;l< this.reportData[i].data.length;l++){
              dataIvrsvoicecall.data.push(this.reportData[i].data[l].data);
              this.xcategories.push(this.reportData[i].data[l].date);
            }
            this.data.push(dataIvrsvoicecall);
          }
        }
      }
      else {

        for(var i=0;i< this.reportData.length;i++){
          datavalue.name = this.reportData[i].type;
          datavalue.data.push(this.reportData[i].data)
          this.xcategories.push(this.reportData[i].date)
          }
          this.data.push(datavalue);
      }
      console.log(this.data);
      this.loadLineSeriesReport();
    });
  }

loadLineSeriesReport() {
  this.chartOptions = {   
    chart: {
       type: "spline"
    },
    title: {
       text: this.graphTitle[localStorage.getItem('flag_status')]
    },
    xAxis:{
       categories: this.xcategories
    },
    yAxis: {          
       title:{
          text: this.yaxisTitletxt[localStorage.getItem('flag_status')]
       } 
    },
    series: this.data
  };
}

dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
 

  let month = dateRangeStart.value.split('/')[0];
  let date = dateRangeStart.value.split('/')[1];
  let year = dateRangeStart.value.split('/')[2];
  let startdate = year+'-'+month+'-'+date;

  let endmonth = dateRangeEnd.value.split('/')[0];
  let enddate = dateRangeEnd.value.split('/')[1];
  let endyear = dateRangeEnd.value.split('/')[2];
  let endDate = endyear+'-'+endmonth+'-'+enddate;

  this.getReportapi(startdate,endDate);
  
}

back() {
  this.router.navigate(['/epicare/drDashboard']);
}

} 
  



