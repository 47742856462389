<div class="container-fluid" style="margin-top: 10px;">
    <div class="hospitalCard">
        <div class="row">
            <div class="col-md-6">
                <h2> <img src="./assets/images/daily_collection/daily_collection.png" class="imgcustom"> <span
                        class="headerClr">Cash Collection Report</span></h2>
            </div>
        </div>

        <div class="row" style="margin-right: -20px;margin-top:15px;">
            <div class="col-md-8">
                <div style="display:flex">
                    <span style="margin-top: 3px;">Date :</span>
                    <input style="margin-left: 10px;" type="text" ngxDaterangepickerMd [locale]="{format: 'DD-MM-YYYY'}"
                        [(ngModel)]="selected" [maxDate]='maxDate' [showCustomRangeLabel]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                        [isInvalidDate]="isInvalidDate" [showClearButton]="true" (rangeClicked)="rangeClicked($event)"
                        (datesUpdated)="datesUpdated($event)" placeholder="Select Date" />
                </div>
            </div>

            <div class="col-md-4">
                <div style="display:flex;float: right;margin-right:10px;margin-top:-5px">
                    <div style="margin-top: 2px;">
                        <h4>Download : </h4>
                    </div>
                    <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()">
                        <div class="addDrTxt">XLS</div>
                    </div>
                    <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()">
                        <div class="addDrTxt">PDF</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="mytable">
        <div class="row">
            <div class="col-md-12">
                <div style="text-align: center;font-weight: bold;"><label>
                        <h4>Cash Collection Report</h4>
                    </label></div>
            </div>
            <div class="col-md-8">
                <label>
                    <h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5>
                </label>
            </div>
            <div class="col-md-4">
                <div style="float:right"><label>
                        <h5>Report Generated On: {{todayDate}}</h5>
                    </label></div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 search-table-outter">
                <table class="table table-bordered">
                    <thead>
                        <tr class="text-center">
                            <th>S. No</th>
                            <th>MR Number</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Change</th>
                            <th>Collected Amount</th>
                            <th>Description</th>
                            <th>Rs. 1</th>
                            <th>Rs. 2</th>
                            <th>Rs. 5</th>
                            <th>Rs. 10</th>
                            <th>Rs. 20</th>
                            <th>Rs. 50</th>
                            <th>Rs. 100</th>
                            <th>Rs. 200</th>
                            <th>Rs. 500</th>
                            <th>Rs. 2000</th>
                            <th>Source</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of reportData;let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{r.mrn}}</td>
                            <td>{{r.patient_name}}</td>
                            <td>{{r.category}}</td>
                            <td>{{r.change}}</td>
                            <td>{{r.collected_amount}}</td>
                            <td>{{r.description}}</td>
                            <td>{{r.rs_1}}</td>
                            <td>{{r.rs_2}}</td>
                            <td>{{r.rs_5}}</td>
                            <td>{{r.rs_10}}</td>
                            <td>{{r.rs_20}}</td>
                            <td>{{r.rs_50}}</td>
                            <td>{{r.rs_100}}</td>
                            <td>{{r.rs_200}}</td>
                            <td>{{r.rs_500}}</td>
                            <td>{{r.rs_2000}}</td>
                            <td>{{r.source}}</td>
                            <td>{{r.time | date:'dd - MMM - yyyy h:mma'}}</td>

                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>