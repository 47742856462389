import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string;
  isAuthenticate = new BehaviorSubject<boolean>(false);
//  isAuthenticate = false;
  constructor(private http: HttpClient) {
  }

  login(url: string, body: any): any {
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(environment.HOST + environment.PORT + url, JSON.stringify(body), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }),
        })
        .toPromise()
        .then((res) => {
          this.isAuthenticate.next(true);
          resolve(res);
        },
        msg => { // Error
          this.isAuthenticate.next(false);
          reject(msg);
        });
    });

    return promise;
  }

  logout() {
    localStorage.clear();
    this.isAuthenticate.next(false);
  }
}