<div class="container-fluid">
    <div class="row">
        <!-- <p class="col-lg-11 col-md-11">What's New</p>
        <img class="col-lg-1 col-md-1" (click)="closePopup()" src="assets/cancel.svg" > -->
        <!-- <p class="col-lg-11 col-md-11">What's New</p>
        <img class="col-lg-1 col-md-1 img" (click)="closePopup()" src="assets/cancel.svg"> -->
        <div class="col-md-10 col-lg-10">
            <h2>What's New</h2>
        </div>
        <div class="col-md-2 col-lg-2">
            <img (click)="closePopup()" src="assets/cancel.svg">
        </div>
    </div>
    <hr>
</div>

<div class="container-fluid">
    <div class="row">
        <span><span style="font-size: 20px;font-weight: bold;">Version</span> - {{whatsNewArray[0].version}}</span>
    </div>
    <div class="row">
        {{whatsNewArray[0].app_name | titlecase}}
    </div>
    <div class="row">
        {{whatsNewArray[0].package_name}}
    </div>
    <div class="row">
        {{whatsNewArray[0].release_date | date: 'dd/MM/yyyy'}}
    </div>
    <div class="row">
        {{whatsNewArray[0].version}}
    </div>
    <div class="row">
        <a [attr.href]="whatsNewArray[0].image" target="_blank">
            <!-- {{whatsNewArray[0].image}} -->
            <span>Image</span>
        </a>
    </div>
    <div class="row">
        <a href="{{whatsNewArray[0].video}}" target="_blank">
            <!-- {{whatsNewArray[0].video}} -->
            <span>Video</span>
        </a>
    </div>
    <div class="row">
        <span style="font-size: 15px;font-weight: bold;">Release Notes :</span>
    </div>
    <div class="row">
        {{whatsNewArray[0].release_notes}}
    </div>
    <div class="row">
        <button type="button" class="btn btn-primary" (click)="changeValue()">Ok</button>
    </div>
    <!-- <ul >
        <span><span style="font-size: 20px;font-weight: bold;">Version</span> - {{whatsNewArray[0].message.version}}</span>
        <li>{{whatsNewArray[0].message.app_name | titlecase}}</li>
        <li>{{whatsNewArray[0].message.package_name}}</li>
        <li>{{whatsNewArray[0].message.release_date | date: 'dd/MM/yyyy'}}</li>
        <li>{{whatsNewArray[0].message.app_name | titlecase}}</li>
        <li>{{whatsNewArray[0].message.version}}</li>
        <li><a [attr.href]="whatsNewArray[0].message.image" target="_blank">{{whatsNewArray[0].message.image}}</a></li>
        <li><a href="{{whatsNewArray[0].message.video}}" target="_blank">{{whatsNewArray[0].message.video}}</a></li>
    </ul> -->
</div>