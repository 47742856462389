<table style="margin-bottom: 2rem;" *ngIf=" data.drugs.length!=0">
    <tr style="border: 2px solid;">
        <th>S No</th>
        <th>Name</th>
        <th>Price</th>
        <th>Stock Availabilty</th>
        <th>Stock Quantity</th>
        <th>Thumbnail</th>

        <th>View Details</th>

        <th>Add</th>
      
    </tr>

    <tr style="border: 0.5px solid;" *ngFor="let d of data.drugs;let i = index">
        <td>{{(i+1)}}</td>
        <td>{{d.name}}</td>
        <td style="width: 4rem;"><span *ngIf="d.price">&#x20B9;</span>  {{d.price}}</td>
        <td [class.green]="d.stock_status=='instock'" [class.red]="d.stock_status=='outofstock'"><span *ngIf="d.stock_status=='instock'">In Stock</span> <span *ngIf="d.stock_status=='outofstock'">Out of Stock</span></td>
        <td>{{d.stock_quantity || 0}}</td>
        <td>
            <img *ngIf="d.images.length>0" style="height: 3rem;width: 3rem;" [src]="d.images[0].src">
        </td>
        <td style="display: flex;justify-content: center;"><img style="cursor: pointer;" (click)="view(d)" src="assets/eye_icon.svg"></td>

        <td><button class="btn btn-primary" [disabled]="d.stock_status=='outofstock'" (click)="addDrug(d)">Add</button></td>

    </tr>
</table>
<p *ngIf=" data.drugs.length==0" style="text-align: center;font-size: 1rem;">No products available for the drug</p>

<div style="display: flex;justify-content: center;">
    <button class="btn btn-primary" (click)="onNoClick()">Close</button>
</div>