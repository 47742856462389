<span>Hi</span>
<div class="Rtable Rtable--4cols">  
    <div class="Rtable-cell"><span>Name :&nbsp;&nbsp;</span><span class="fontWeight">{{patient_name | titlecase}}</span></div>
    <div class="Rtable-cell"> <span>MR No :&nbsp;&nbsp;</span><span class="fontWeight">{{mrNo}}</span></div>
    <div class="Rtable-cell"> <span>DOB :&nbsp;&nbsp;</span><span class="fontWeight">{{Dob | date: 'dd-MM-yyyy'}}</span></div>
    <div class="Rtable-cell"> <span><span>Date :&nbsp;&nbsp;</span><span class="fontWeight">{{todayDate}}</span></span></div>
</div>
  
<div class="Rtable Rtable--4cols">
    <div class="Rtable-cell"> <span>Sex :&nbsp;&nbsp;</span><span class="fontWeight">{{sex | titlecase}}</span></div>
    <div class="Rtable-cell"> <span><span>Age :&nbsp;&nbsp;</span><span class="fontWeight">{{age}}</span></span></div>
    <div class="Rtable-cell"> <span>LMP :&nbsp;&nbsp;</span><span class="fontWeight">{{lmp}}</span></div>
    <div class="Rtable-cell"> <span><span>EDD :&nbsp;&nbsp;</span><span class="fontWeight">{{edd}}</span></span></div>
</div>

<div style="margin-left: 0px !important; margin-right: 0px !important;margin-top: 5px;border-top:0.5px solid;"></div>

<div class="row" style="margin-top: 20px !important;"  *ngIf="precRisk!=''">
    <div class="col-md-12">
        <label id="prescription" style="font-weight: bold;">Pregnancy Risk : </label> <span style="margin-left: 10px">{{riskTo | titlecase}}</span>
    </div>
</div> 

    <div class="row" style="margin-top: 20px !important;" *ngIf="vital != 0">
        <div class="col-md-12">
            <label id="prescription" style="font-weight: bold;">Vitals : </label>
                <div class="Rtable Rtable--4cols">
                    <div class="Rtable-cell"> <span>Weight (kg) :&nbsp;&nbsp;</span><span class="fontWeight">{{weigth}}</span></div>
                    <div class="Rtable-cell"> <span><span>BP :&nbsp;&nbsp;</span><span class="fontWeight">{{Bp}}</span></span></div>
                    <div class="Rtable-cell"> <span>&nbsp;&nbsp;</span></div>
                    <div class="Rtable-cell"> <span></span></div>
                </div>
        </div>
    </div>
    <br>
    <!-- <div style="margin-left: 0px !important; margin-right: 0px !important;padding-top: 3px;border-top:0.5px solid;"></div> -->
    <div style="display: flex;">
        <div style="width: 25%;" *ngIf="complaint != null">
            <div> <span class="fontWeight">Chief Complaints :</span> </div>
            <div>
                <table *ngFor="let compissues of complaintissues">
                    <thead style="font-weight: normal !important;"><td>{{compissues.complaint_category | titlecase}}</td></thead> 
                    <tbody *ngFor="let val in compissues.details">
                        <tr *ngFor="let val1 in val.answers.split('^~^')">
                            <td style="margin-top:-15px;">{{val1}}</td>
                        </tr>       
                    </tbody>                            
                </table>
            </div>
        </div>
        <div style="width: 25%;" *ngIf="findings!='' && findings!=undefined">
            <div> <span class="fontWeight">Findings :</span> </div>
            <div><span>{{findings}}</span></div>
        </div>
        <div style="width: 25%;" *ngIf="diagnosis!='' && diagnosis!=undefined">
            <div> <span class="fontWeight">Provisional Diagnosis :</span> </div>
            <div> <span>{{diagnosis}}</span> </div>
        </div>
        <div style="width: 25%;" *ngIf="advice!=''&&advice!=undefined">
            <div> <span class="fontWeight">Advice :</span> </div>
            <div> <span>{{advice}}</span> </div>
        </div>
    </div>
    
    <div style="margin-left: 0px !important; margin-right: 0px !important;margin-top: 5px;padding-top: 3px;border-top:0.5px solid;"></div>
    
    <div class="row" style="margin-top: 0px;">
        <div class="col-md-12" *ngIf="prescrDetails != null">
            <br>
            <label id="prescription" style="font-weight: bold;">Drug Prescription : </label>
                <table class="table" id="table_width">
                    <thead>
                        <tr>
                            <th style="border: 1px solid;">S.No.</th>
                            <th style="border: 1px solid;">Formulation - Drug Generic (Composition Name) (Strength)</th>
                            <th style="border: 1px solid;">Route</th>
                            <th style="border: 1px solid;">Dosage</th>
                            <th style="border: 1px solid;">Frequency</th>
                            <th style="border: 1px solid;">Duration</th>
                            <th style="border: 1px solid;">Total</th>
                            <th style="border: 1px solid;">BF/AF</th>
                            <th style="border: 1px solid;">Special Instructions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let presdetails of prescrDetails; let i = index">
                            <td style="border: 1px solid; text-align: center;">{{i+1}} </td> 
                            <td style="border: 1px solid; text-align: center;">{{presdetails.formulation | titlecase}} <span *ngIf="presdetails.generic!=''"> - {{presdetails.generic | titlecase}}</span> <span *ngIf="presdetails.composition_name!=''"> ({{presdetails.composition_name | titlecase}})</span> <span *ngIf="presdetails.strength!='' && presdetails.strength!=null">({{presdetails.strength}})</span></td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.route}}</td>
                            <td style="border: 1px solid; text-align: center;"><span>{{presdetails.dose}}</span> {{presdetails.dose_option}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.frequency}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.duration}} {{presdetails.duration_type}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.quantity}}</td>
                            <td style="border: 1px solid; text-align: center;">{{presdetails.when}}</td>
                            <td style="border: 1px solid; text-align: left;">{{presdetails.special_instruction}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    <div class="row" style="margin-top: 5px;">
        <div class="col-md-12"> 
            <hr>
            <div class="row">
                <div class="col-md-12" style="margin-left: 12px !important; margin-right: 0px !important;">
                    <div class="row">
                        <div class="col-xs-4"></div>
                        <div class="col-xs-4"></div>
                        <div class="col-xs-4 pull-right" *ngIf="followupDate != null || followuptext != null">
                            <label><span style="font-weight: bold;">Follow Up :</span> &nbsp;&nbsp;<span>{{followupDate | date:'dd-MM-yy'}}</span>&nbsp;&nbsp;<span ng-if="followupDate&&followuptext!=''">{{followuptext | date:'dd-MM-yy'}}</span></label>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
    <div class="subpage">
        <div class="row">
            <div class="col-md-12" style="margin-left: 12px !important; margin-right: 0px !important;">
                <div class="row">
                    <div *ngIf="testadvised!=undefined && testadvised!=null && testadvised!=0">
                        <div>
                            <label id="prescription" style="font-weight:bold">Test Advised :</label><br>
                            <span *ngFor="let test of testadvised; let i = index">{{i+1}}. {{test}}<br></span>
                            <span *ngIf="testadvised=='' || testadvised==null || testadvised==0">No Tests</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div class="row" style="margin-top: 0px;" *ngIf="currentPrec!=''">
        <div class="col-md-12">
            <br>
            <label id="prescription" style="font-weight: bold;">Current Pregnancy : </label>
                <table class="table" id="table_width">
                    <thead>
                        <tr>
                            <th style="border: 1px solid; text-align: center;">Date</th>
                            <th style="border: 1px solid; text-align: center;">BP</th>
                            <th style="border: 1px solid; text-align: center;">P/A</th>
                            <th style="border: 1px solid; text-align: center;">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let currenpreq of currentPrec; let i = index">
                            <td style="border: 1px solid; text-align: center;">{{currenpreq.date}}</td>
                            <td style="border: 1px solid; text-align: center;">{{currenpreq.bp}}</td>
                            <td style="border: 1px solid; text-align: center;">{{currenpreq.pa}}</td>
                            <td style="border: 1px solid; text-align: left">{{currenpreq.remarks}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    <div class="row" style="margin-top: 0px;" *ngIf="follicleStudy!=''">
        <div class="col-md-12">
            <br>
            <label id="prescription" style="font-weight: bold;">Follicle Studies: </label>
                <table class="table" id="table_width">
                    <thead>
                        <tr>
                            <th style="border: 1px solid; text-align: center;">Date</th>
                            <th style="border: 1px solid; text-align: center;">Day</th>
                            <th style="border: 1px solid; text-align: center;">RT Ovary</th>
                            <th style="border: 1px solid; text-align: center;">LT Ovary</th>
                            <th style="border: 1px solid; text-align: center;">Endometrium</th>
                            <th style="border: 1px solid; text-align: center;">comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let folliclestdy of follicleStudy; let i = index">
                            <td style="border: 1px solid; text-align: center;">{{folliclestdy.date}}</td>
                            <td style="border: 1px solid; text-align: center;">{{folliclestdy.day}}</td>
                            <td style="border: 1px solid; text-align: center;">{{folliclestdy.rt_ovary}}</td>
                            <td style="border: 1px solid; text-align: center;">{{folliclestdy.rt_ovary}} </td>
                            <td style="border: 1px solid; text-align: center;">{{folliclestdy.endometrium}}</td>
                            <td style="border: 1px solid; text-align: left">{{folliclestdy.comments}} </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>

    
        
    