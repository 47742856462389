<div class="body">
    <div class="row">
        <div style="padding-left: 0;" class="col-lg-4 col-md-4">
            <img class="rx" src="assets/rx.svg">
        </div>

        

        <div class="col-lg-4 col-md-4">
           <!-- <p (click)="save1(3)" class="save">
                Save</p> -->
        </div> 

        <div class="col-lg-2 col-md-2">
            <p class="cancel" (click)="onNoClick()">Cancel</p>
        </div>

        <div class="col-lg-2 col-md-2">
            <p (click)="save1(4)" class="save">
                Save</p>
        </div>
    </div>

    <div class="row" style="width: 100%;margin-top: 1rem;display: flex">

        <div class="box" >
            <div>
                <p class="head" (click)="openFormulation()">Formulation</p>
                <p (click)="openFormulation()" style="cursor: pointer;width: fit-content;margin-top: 0.5rem;"
                    class="name">
                    {{formulation}}

                    <span *ngIf="formulation=='Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>
                    <span *ngIf="formulation==' Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>
                    <span *ngIf="formulation=='Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>
                    <span *ngIf="formulation==' Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>
                    <span *ngIf="formulation=='Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>
                    <span *ngIf="formulation==' Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>
                    <span *ngIf="formulation=='Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                    <span *ngIf="formulation==' Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                    <span *ngIf="formulation=='Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>
                    <span *ngIf="formulation==' Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>
                    <span *ngIf="formulation=='Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>
                    <span *ngIf="formulation==' Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>
                    <span *ngIf="formulation=='Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                    <span *ngIf="formulation==' Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                    <span *ngIf="formulation=='Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>
                    <span *ngIf="formulation==' Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>
                    <span *ngIf="formulation=='Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                    <span *ngIf="formulation==' Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                    <span *ngIf="formulation=='Drop'"><img class="pres_icon" src="assets/icon9.svg"></span>
                    <span *ngIf="formulation=='Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                    <span *ngIf="formulation==' Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                    <span *ngIf="formulation=='Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                    <span *ngIf="formulation==' Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                    <span *ngIf="formulation=='Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>
                    <span *ngIf="formulation==' Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>
                    <span *ngIf="formulation=='Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>
                    <span *ngIf="formulation==' Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>
                    <span *ngIf="formulation=='Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>
                    <span *ngIf="formulation==' Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>
                    <span *ngIf="formulation=='Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>
                    <span *ngIf="formulation==' Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>
                    <span *ngIf="formulation=='Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>
                    <span *ngIf="formulation==' Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>
                    <span *ngIf="formulation=='Others'"><img class="pres_icon" src="assets/icon17.svg"></span>
                    <span *ngIf="formulation==' Others'"><img class="pres_icon" src="assets/icon17.svg"></span>

                </p>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;">
            <div>
                <p class="head" style="margin-bottom: 0.5rem;">Drug Name</p>
                <p class="name" style="background-color: #FFF;border: none;cursor:pointer" (click)="changeDrugName()">{{name}}</p>
            </div>
        </div>
        <div *ngIf="getStockStatus" class="box" style="margin-left: 1rem;">
            <div>
                <p class="head" style="margin-bottom: 0.5rem;">Stock Status</p>
                <p class="name" [ngClass]="{'in-stock': getStockStatus === 'instock', 'out-of-stock': getStockStatus !== 'instock'}">

                    {{ getStockStatus === 'instock' ? 'In Stock' : (getStockStatus === 'not in sale' ? 'Not in Sale' : 'Out of Stock') }}

                </p>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;">
            <div>
                <p class="head">Generic</p>
                <p class="name" style="width: 10rem;">{{generic}}</p>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;">
            <div>
                <p class="head" (click)="activateStrength()">Strength</p>
                <div (click)="activateStrength()"
                    style="display: flex;flex-direction: row;justify-content: space-between;width: 7rem;;"
                    class="text-center" [class.item]="strengthActive">
                    <input style="width: 6rem;background-color: transparent;border: none;visibility: hidden;"
                        [class.visible]="strengthActive" type="number" maxlength="2" min=1 (input)="calculate()"
                        [(ngModel)]="dose">
                    
                </div>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;">
            <div>
                <p class="head">Unit</p>
            </div>
            <div>
                <p style="width: fit-content;cursor: pointer;" class="name" (click)="openSlider2()">{{dose_option}}</p>
            </div>

        </div>

        <div class="box" style="margin-left: 1rem;">
            <p class="head">Duration</p>

            <div style="display: flex;">
                <div style="display: flex;width: 100%;">
                    <div style="display: flex;margin-top: -0.5rem;">
                        <div style="display: flex;justify-content: space-between;margin-top: 0.5rem;">
                            <span class="text-center minus" (click)="minusDays()"
                                style="cursor: pointer;border-right: 2px solid #DCDCDC">-</span>
                            <input class="duration_input text-center" (input)="durationInput()" type="number" min=0
                                max=100 [(ngModel)]="duration"> <span class="text-center plus" (click)="plusDays()"
                                style="border-left: 2px solid #DCDCDC;cursor: pointer; ;">+</span>
                        </div>

                        <p [class.bold1]="duration_type=='Days'"
                            style="cursor: pointer;margin-top: 0.5rem;margin-left: 0.5rem;"
                            (click)="duration_type=='Months'?changeDurationType():false">Days&nbsp;</p>

                        <p [class.bold1]="duration_type=='Months'" style="cursor: pointer;margin-top: 0.5rem;"
                            (click)="duration_type=='Days'?changeDurationType():false">&nbsp;Months</p>

                    </div>

                    <div class="toggle_box" style="display: flex;margin-left: 0.5rem;">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="width: 100%;margin-top: 1rem;display: flex;">
        <div class="box" style="margin-right: 1rem;">
            <div style="width: 100%;">
                <p class="head">When</p>
                <div class="row when_icons" style="width: 100%;display: flex;justify-content: space-between;">
                    <div style="cursor: pointer;" *ngIf="when=='Before Food'" (click)="openSlider3()">
                        <div style="display: flex;justify-content: center;">
                            <img [src]="when=='Before Food'?'assets/bf.svg':'assets/bf1.svg'">
                        </div>
                        <p class="text-center" [class.bold]="when=='Before Food'">Before <br>Food</p>
                    </div>

                    <div style="cursor: pointer;" *ngIf="when=='After Food'" (click)="openSlider3()">
                        <div style="display: flex;justify-content: center;">
                            <img [src]="when=='After Food'?'assets/af1.svg':'assets/af.svg'">
                        </div>
                        <p class="text-center" [class.bold]="when=='After Food'">After <br>Food</p>

                    </div>

                    <div style="cursor: pointer;" *ngIf="when=='With Food'" (click)="openSlider3()">
                        <div style="display: flex;justify-content: center;">
                            <img [src]="when=='With Food'?'assets/wf1.svg':'assets/wf.svg'">
                        </div>
                        <p class="text-center" [class.bold]="when=='With Food'">With <br>Food</p>

                    </div>

                    <div style="cursor: pointer;" *ngIf="when=='Not Related to Food'" (click)="openSlider3()">
                        <div style="display: flex;justify-content: center;">
                            <img [src]="when=='Not Related to Food'?'assets/not1.svg':'assets/notrelated.svg'">
                        </div>
                        <p class="text-center" [class.bold]="when=='Not Related to Food'">Not Related<br>to Food</p>

                    </div>
                    <div style="cursor: pointer;" *ngIf="when=='-'" (click)="openSlider3()">
                        <div style="display: flex;justify-content: center;">
                            <p style="position:relative;left:10px">-</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="box">
            <div style="display: flex;">

                <input style="cursor:pointer;" value="times" id="radio2" type="radio" class="radio"
                    (change)="radioChange()" [(ngModel)]="time_block">
                <label class="head" for="radio2">&nbsp;Times of a day&nbsp;</label>

                <input style="cursor:pointer;margin-left: 0.5rem;" value="frequency" id="radio1" type="radio"
                    class="radio" (change)="radioChange()" [(ngModel)]="time_block">
                <label class="head" for="radio1">&nbsp;Frequency&nbsp;</label>

            </div>

            <div style="display: flex;" *ngIf="time_block=='frequency'">

                <div style="cursor: pointer;" (click)="openTime()" (click)="changeFreqType('time')"
                    [class.disabled1]="frequency_type!='time'">
                    <div style="display: flex;justify-content: center;">
                        <img [src]="frequency_type=='time'?'assets/time1.svg':'assets/time.svg'">
                    </div>
                    <p class="text-center" [class.bold]="frequency_type=='time'">Time <br>
                    <p class="text-center" style="visibility: hidden;" [class.bold]="frequency_type=='time'"
                        [class.visible]="frequency_type=='time'">{{time}}<br>

                        <span>
                            <input [class.bold]="frequency_type=='time'"
                                style="border:none;visibility:hidden;cursor: pointer;width: 100%;text-align: center;display: none;"
                                placeholder="Choose Time" aria-label="12hr format"
                                [class.visible]="frequency_type=='time'" #timeInput [(ngModel)]="time"
                                (ngModelChange)="test()" [ngxTimepicker]="default" readonly>
                            <ngx-material-timepicker #default></ngx-material-timepicker>
                        </span>
                    </p>
                </div>

                <div style="cursor: pointer;margin-left: 0.5rem;" (click)="changeFreqType('sos')"
                    [class.disabled1]="frequency_type!='sos'">
                    <div style="display: flex;justify-content: center;">
                        <img [src]="frequency_type=='sos'?'assets/sos1.svg':'assets/sos.svg'">
                    </div>
                    <p class="text-center" [class.bold]="frequency_type=='sos'">SOS</p>
                </div>

                <div style="margin-left: 1rem;" (click)="changeFreqType('every')"
                    [class.disabled1]="frequency_type!='every'">
                    <p style="margin-bottom:0;">Every <span
                            style="color: #00A0E3;;font-weight:600">{{every_value}}</span>
                        hours</p>
                    <mat-slider thumbLabel style="padding: 0;" min="2" max="24" step="2" [value]="every_value"
                        style="margin-top: -13px;" (ngModelChange)="everyChange()" [(ngModel)]="every_value">
                    </mat-slider>
                </div>
            </div>

            <div class="box times" *ngIf="time_block=='times'" [class.disabled1]="frequency_type!='times'"
                (click)="changeFreqType('times')">
                <div>
                    <div style="width: 100%;display: flex;">
                        <div style="margin-right: 2rem;" class="times_box">
                            <div style="display: flex;justify-content: space-between;">
                                <img src="assets/sunrise.svg">
                                <p style="margin-bottom: 0;">Morning</p>
                            </div>


                            <div
                                style="display: flex;justify-content: space-between;margin-top: 0.5rem;padding-bottom: 0.5rem;border-bottom: 2px solid #DCDCDC;">
                                <span class="text-center minus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?minusMorningDose():minusMorningDose1()"
                                    style="cursor: pointer;border-right: 2px solid #DCDCDC;">-</span>
                                <input class="dose_input" type="number" (input)="calculateDose()" [(ngModel)]="morning_dose">
                                <span class="text-center plus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?plusMorningDose():plusMorningDose1()"
                                    style="border-left: 2px solid #DCDCDC;cursor: pointer; ;">+</span>
                            </div>

                        </div>

                        <div style="margin-right: 2rem;" class="times_box">
                            <div style="display: flex;justify-content: space-between;">
                                <img src="assets/sun.svg">
                                <p style="margin-bottom: 0;">Afternoon</p>
                            </div>


                            <div
                                style="display: flex;justify-content: space-between;margin-top: 0.5rem;padding-bottom: 0.5rem;border-bottom: 2px solid #DCDCDC;">
                                <span class="text-center minus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?minusAfterNoonDose():minusAfterNoonDose1()"
                                    style="cursor: pointer;border-right: 2px solid #DCDCDC">-</span>
                                <input class="dose_input" type="number" [(ngModel)]="afternoon_dose"  (input)="calculateDose()">
                                <span class="text-center plus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?plusAfterNoonDose():plusAfterNoonDose1()"
                                    style="border-left: 2px solid #DCDCDC;cursor: pointer; ;">+</span>
                            </div>
                        </div>

                        <div style="margin-right: 2rem;" class="times_box">
                            <div style="display: flex;justify-content: space-between;">
                                <img src="assets/evening.svg">
                                <p style="margin-bottom: 0;">Evening</p>
                            </div>


                            <div
                                style="display: flex;justify-content: space-between;margin-top: 0.5rem;padding-bottom: 0.5rem;border-bottom: 2px solid #DCDCDC;">
                                <span class="text-center minus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?minusEveningDose():minusEveningDose1()"
                                    style="cursor: pointer;border-right: 2px solid #DCDCDC">-</span>
                                <input class="dose_input" type="number" [(ngModel)]="evening_dose"  (input)="calculateDose()">
                                <span class="text-center plus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?plusEveningDose():plusEveningDose1()"
                                    style="border-left: 2px solid #DCDCDC;cursor: pointer; ;">+</span>
                            </div>

                        </div>

                        <div class="times_box">
                            <div style="display: flex;justify-content: space-between;">
                                <img src="assets/night.svg">
                                <p style="margin-bottom: 0;">Night</p>
                            </div>


                            <div
                                style="display: flex;justify-content: space-between;margin-top: 0.5rem;padding-bottom: 0.5rem;border-bottom: 2px solid #DCDCDC;">
                                <span class="text-center minus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?minusNightDose():minusNightDose1()"
                                    style="cursor: pointer;border-right: 2px solid #DCDCDC">-</span>
                                <input class="dose_input" type="number" [(ngModel)]="night_dose"  (input)="calculateDose()">
                                <span class="text-center plus" (click)="formulation!='Syrup' && formulation!=' Syrup' && formulation!='Drops' && formulation!=' Drops'?plusNightDose():plusNightDose1()"
                                    style="border-left: 2px solid #DCDCDC;cursor: pointer; ;">+</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;" *ngIf="time_block=='frequency'">
            <p class="head">Quantity</p>
            <div style="display: flex;justify-content: space-between;margin-top: 0.5rem">
                <span class="text-center minus" (click)="quanMinus()"
                    style="cursor: pointer;border-right: 2px solid #DCDCDC;padding-top: 0.5rem;">-</span>
                <input
                    style="width: 100%;background-color: transparent;border: none;width: 3rem;text-align: center;padding-top: 0.5rem;    font-weight: 600;"
                    type="number" maxlength="2" min="1" [(ngModel)]="freq_quantity">
                <span class="text-center plus" (click)="quanPlus()"
                    style="border-left: 2px solid #DCDCDC;cursor: pointer;padding-top: 0.5rem;">+</span>
            </div>
        </div>

        <div class="box" style="margin-left: 1rem;">
            <div style="cursor: pointer;">
                <p class="head" (click)="openDialog()">Route</p>
                <p (click)="openDialog()" style="cursor: pointer;width: fit-content;margin-top: 1rem;" class="name">
                    {{route}}
                </p>
            </div>
        </div>

        
    </div>
    
    <div style="margin-top: 0.5rem;">
        <p>Patient weight : <span *ngIf="data.weight">{{data.weight}} kg</span> <span  *ngIf="!data.weight">N/A</span></p>
    </div>

    <div style="margin-top: 0.5rem;">

        <!-- <p *ngIf="maxDosage" style="margin-top: 0.5rem;text-align:center">Maxmimum recommended dosage : {{maxDosage}}</p>
        <p *ngIf="maxDuration" style="margin-top: 0.5rem;text-align:center">Maxmimum recommended duration in days : {{maxDuration}}</p> -->

        <p *ngIf="weightWarning" style="margin-top: 0.5rem;" class="error">Patient weight is required to calculate recommended dosage</p>
        <p *ngIf="durationWarning || doseWarning" style="margin-top: 0.5rem;text-align:center"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        The suggested maximum dosage is <span class="blue1">{{maxDosage}}</span> for <span  class="blue1">{{maxDuration}}.</span> <span style="color: unset;" *ngIf="url"> Reference : <span (click)="openUrl()" style="cursor: pointer;"  class="blue1"><u>click here</u></span></span></p>

    </div>



    <div class="row" style="display: flex;margin-top: 0.5rem">
        <div class="box" style="width: 100%">
            <p class="head">Instructions</p>

            <textarea maxlength="200" [(ngModel)]="special_instruction" class="instructions_ta"></textarea>
        </div>
    </div>

    <!-- <div class="row" *ngIf="data.addPres" style="display: flex;margin-top: 0.5rem">
        <mat-checkbox class="example-margin" [(ngModel)]="addPres">
            <p class="text">Add to consultation</p>
        </mat-checkbox>
    </div> -->
</div>