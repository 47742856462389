import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
import { AppConfig } from 'src/app/app.config';
declare let alasql;
import { saveAs } from 'file-saver';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-cash-collection',
  templateUrl: './cash-collection.component.html',
  styleUrls: ['./cash-collection.component.scss']
})
export class CashCollectionComponent implements OnInit {



  todayDate: any;
  startdateInFormat = '-';
  enddateInFormat = '-';
  selected: any;
  alwaysShowCalendars: boolean;
  maxDate = moment();
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  dmail;
  doc_id;
  token;
  reportData;

  constructor(private route: ActivatedRoute, private toastr: ToastrService, private appService: AppService, private http: HttpClient, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail = getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    let date = new Date();
    let momDate = moment(date);
    this.todayDate = momDate.format('DD/MM/YYYY');
    let sendDate = momDate.format('YYYY-MM-DD');
    this. getCollectionReport(sendDate,sendDate);
    
  }

  datesUpdated(range) {

    const startDate = moment(this.selected.startDate.toDate());
    const endDate = moment(this.selected.endDate.toDate());
    this.startdateInFormat = startDate.format('DD/MM/YYYY');
    this.enddateInFormat = endDate.format('DD/MM/YYYY');

    let sendstartDate = startDate.format('YYYY-MM-DD');
    let sendendDate = endDate.format('YYYY-MM-DD');
    this.getCollectionReport(sendstartDate, sendendDate);

  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  downloadPDF(){
    var element = document.getElementById('mytable');
 var opt = {
   margin: 0.1,
   filename: 'DailyRangeCollectionReport.pdf',
   image: { type: 'jpeg', quality: 0.98 },
   html2canvas: { scale: 1 },
   jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};
// New Promise-based usage:
html2pdf().from(element).set(opt).save();
 }

  downloadCSV(){
    let filename = 'mycsv'
    alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#mytable',{headers:true,separator:','})");
  };

  downloadXLS(){
    var blob = new Blob([document.getElementById('mytable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "DailyRangeCollectionReport.xls");
  }

  getCollectionReport(filterstartdate, filterenddate) {


    var params = {
      "account_email": this.dmail,
      "from_date": filterstartdate,
      "to_date": filterenddate,
      "doctor_id":this.doc_id
    }

    this.startdateInFormat = moment(filterstartdate).format('DD-MMM-YYYY')

    this.enddateInFormat = moment(filterenddate).format('DD-MMM-YYYY')


    this.appService
      .getData(AppConfig.settings.EndPoints.getCashReport, params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        console.log(response.message)

        this.reportData = response.message
      });
  }

  rangeClicked(range) {
  }



}
