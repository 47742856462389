<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>History of Present Illness</p>


    <div style="display: flex;">
        
        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary" (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close" (click)="close()"></i>

    </div>

 
</div>

<textarea [(ngModel)]="hpi" maxlength="1000"
style="width: 100%;margin-top: 0.5rem;height: 10rem;">

</textarea>