<div>
    <p class="header">Chronic Disease</p>
</div>

<div style="max-height: 20rem;overflow: auto;">
    <div *ngFor="let d of categoriesData;let i=index">
        <div class="border1" style="display: flex;flex-direction: row;padding: 5px;justify-content: space-between;">
            
            <p>

                <span style="font-weight: 600;color: #000;">{{d.name}}</span>

                <span style="color: #007BFF;" *ngFor="let d of d.value;let j=index">  &#9679; {{d}}</span>
            </p>

            <img style="width: 1.25rem;margin-left: 0.5rem;visibility: hidden;" src="assets/shape1.svg">

        </div>
    </div>

    <p *ngIf="data.chronic.length==0">No chronic diseases added for the patient</p>
</div>

<div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
    <button class="save btn"  (click)="add()" style="width: fit-content;">Add / Edit</button>
    <button class="cancel btn" (click)="close()" style="width: fit-content;">Cancel</button>
</div>