<!-- aug 20 -->

<div class="row mt-1">
    <div class="col-sm-12 col-md-12 col-lg-12"> 
         <span><i class="fas fa-arrow-left  ml-4" style="cursor: pointer;" (click)="gotoconsultation()"></i></span>
         <span class="stylename">Name : <strong>{{patientdata.full_name + " "}}</strong></span>
         <span class="stylename">DOB : <strong>{{patientdata.bday | date:'dd-MMM-yyyy' + " "}}</strong></span>
         <span class="stylename">Gender : <strong>{{patientdata.gender + " "}}</strong></span>
         <span class="stylename">Age : <strong>{{age + " "}}</strong></span>
         <span class="stylename">Last Vaccinated Date : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine_date | date:'dd-MMM-yyyy' + " "}}</strong></span>
         <span class="stylename">Last Vaccinated Name : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine}}</strong></span>
         <span class="stylename">Given : <strong *ngIf="adherence">{{adherence.done_vaccine}}</strong></span>
         <span class="stylename">Total : <strong *ngIf="adherence">{{adherence.total_vaccine}}</strong></span>
         <span class="stylename">Adherence : <strong *ngIf="adherence">{{adherence.adherence_percentage}}%</strong></span>  
         <span class="float-right p-1"><button class="btn btn-sm btn-primary" (click)="printvac()">Print</button></span> 
    </div>
</div>

<!-- <div class="row mb-1">
    <div class="col-sm-6 col-md-4 col-lg-4" style="margin-left: 25px;"><span *ngIf="adherence" style="float:right !important;">Immunization Adherence :</span></div>
    <div class="col-sm-6 col-md-4 col-lg-4">
        <span class="progress" *ngIf="adherence">
            <span class="progress-bar bg-success" role="progressbar" [style.width]="adherence.adherence_percentage +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{adherence.adherence_percentage}}%</span>
        </span>
    </div>
</div> -->
<!-- *ngFor="let data of verticalarray[i]" -->
<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 pl-3 pr-1 pt-0 mt-1">
        <div class="scroll">
        <div class="col-sm-4 col-md-6 col-lg-4 col-xl-1  ml-1" *ngFor="let group of agegroup; index as i">
            <!-- <span style="float:right" [ngClass]="{'draw-line': ageinmonths[i] == 1  , 'draw_line': ageinmonths[i] !=  0}"></span> -->
        </div>
       </div>
    </div>
</div>

<div class="row" >
    <div class="col-sm-12 col-md-12 col-lg-12 p-3 pt-0 cardcss mt-1">
        <div class="scroll">
          
            <div class="col-sm-4 col-md-6 col-lg-4 col-xl-1 card1 cardrow p-1 ml-1"  *ngFor="let group of agegroup; index as i" [class.border]="ageinmonths[i] == 1" [class.mar]="group == '15-18 Years'">
                <div class="headercard" *ngIf="group != ''">
                    <button type="button" [ngClass]="{'btn-design':agestatus[i] == 0 , 'btn_design':agestatus[i] == 1}" class="btn btn-outline-none " (click)="selectedgroup(group)"><img [src]="'assets/images/header/'+i+'.svg'" height="15" width="15" />&nbsp;&nbsp;{{group | titlecase }} <br> {{agedate[i]}}</button>
                </div>

                <!-- [class.disabled]="vac.status == 'Administered' || vac.status == 'adminsitrated-1'" -->
                
                <div class="card2" *ngFor="let vac of subchild[i]" [class.disabled]="vac.status == ('Deferred' || 'deffered')">
                    <div [ngClass]="{'card-reschedule':vac.status == 'rescheduled' ,'card-Deferred':vac.status == 'Deferred', 'card-deffered':vac.status == 'deffered' , 'card-Administered':vac.status == 'Administered' ,'card-givenbyother':vac.status == 'adminsitrated-1','card-Pending':vac.status == 'Pending','card-empty':vac.status == ''}" 
                     *ngIf="vac.drug_name != ''">
                        <!-- <div class="row">
                            <div class="col-sm-3 col-md-3 col-lg-3 pt-4 pl-4 text-center">
                                <div *ngIf="((vac.status != 'adminsitrated-1')&&(vac.status != ('Deferred' || 'deffered')))">
                                <input class="checkbox_style" type="checkbox" *ngIf="showcheckbox" [checked]="check" (change)="checkedbox(vac,vac.drug_name,$event)"/>
                                </div>
                                <img [src]="'assets/images/'+vac.site+'.svg'" id="imagetag"/>
                            </div>
                            <div class="col-sm-8 col-md-8 col-lg-8 pt-2" (click)="card_related(vac)">
                                <h4 class="card_content">{{vac.drug_name}}</h4>
                                <h4 class="card_content">{{vac.due_date | date: 'dd-MMM-yyyy'}}</h4>
                            </div>
                        </div> -->
                        <div class="row">
                           
                            <div class="col-md-12 col-xl-12">
                                <div *ngIf="((vac.status != 'adminsitrated-1')&&(vac.status != ('Deferred' || 'deffered')))">
                                    <input class="checkbox_style" type="checkbox" *ngIf="showcheckbox" [checked]="check" (change)="checkedbox(vac,vac.drug_name,$event)"/>
                                </div>
                                <div *ngIf="((vac.status != 'adminsitrated-1')&&(vac.status != ('Deferred' || 'deffered')))">
                                    <input class="checkbox_style" type="checkbox" *ngIf="showcheckboxdeffer" [checked]="check" (change)="checkedboxdeffer(vac,vac.drug_name,$event)"/>
                                </div>
                                <div class="text-center">
                                    <img [src]="'assets/images/'+vac.site+'.svg'" height="42" width="42" id="imagetag" [ngClass]="{'card_reschedule':vac.status == 'rescheduled' ,'card_Deferred':vac.status == 'Deferred', 'card_deffered':vac.status == 'deffered' , 'card_Administered':vac.status == 'Administered' ,'card_givenbyother':vac.status == 'adminsitrated-1','card_Pending':vac.status == 'Pending','card_empty':vac.status == ''}" (click)="card_related(vac)"/>
                                    <h5 id="cardtext"  data-toggle="tooltip" data-placement="top" [title]=vac.drug_name [ngClass]="{'cardreschedule':vac.status == 'rescheduled' ,'cardDeferred':vac.status == 'Deferred', 'carddeffered':vac.status == 'deffered' , 'cardAdministered':vac.status == 'Administered' ,'cardgivenbyother':vac.status == 'adminsitrated-1','cardPending':vac.status == 'Pending','cardempty':vac.status == ''}" (click)="card_related(vac)">{{vac.drug_name}}</h5>    
                                </div>
                           </div>
                            <!-- <div class="col-md-8 col-xl-8" (click)="card_related(vac)" > -->
                               <!-- <h4 [ngClass]="{'cardreschedule':vac.status == 'rescheduled' ,'cardDeferred':vac.status == 'Deferred', 'carddeffered':vac.status == 'deffered' , 'cardAdministered':vac.status == 'Administered' ,'cardgivenbyother':vac.status == 'adminsitrated-1','cardPending':vac.status == 'Pending','cardempty':vac.status == ''}" >{{vac.due_date | date: 'dd-MMM-yyyy'}}</h4> -->
                            <!-- </div> -->
                           
                        </div>
                    </div>
                </div>
   
              </div>
        </div>
    </div>


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="row">
    <div class="col-sm-5 col-md-5 col-lg-4 col-xl-3 sidebar p-3" *ngIf="closesidebar" style="z-index: 200 !important;">
        <div class="example-button-row">
          <span class="float-right"><i class="fas fa-times" style="font-size: 20px;" (click)="close_btn()"></i></span>
        </div>
         <br>
         <div class="text-center"><h2>{{drugname}}</h2></div>
        
         <div *ngIf="!hideall">
             <div *ngIf="!showdeffer">
             <strong>Master Vaccine Details :</strong><br>
             <div>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{{vacccinedetail.master_vaccine[0].drug_name}}</strong></div>
             <div>Due Date &nbsp;&nbsp;&nbsp;: <strong>{{vacccinedetail.master_vaccine[0].due_date}} </strong></div>
             <div>Given Date : <strong>{{vacccinedetail.master_vaccine[0].date_given}}</strong></div>
             <div>Brand Name : <strong>{{vacccinedetail.master_vaccine[0].brand_name}}</strong></div>
             <br>
             <div >Dependency :  <strong>{{dependency}}</strong></div>

             <br>
             <div *ngIf="imageandcomment.comments != ''">
                 <div  *ngIf="imageandcomment.comments != null">
                    <strong>Comments :</strong>
                    <p>{{imageandcomment.comments}}</p>
                 </div>
            </div>
            <div *ngIf="imageandcomment.image != null">
                <div *ngIf="imageandcomment.image != ''">
                 <strong>Batch Image :</strong><br>
                    <img [src]="imgenvironment+'/helyxonapi/cloud/image/upload/'+ imageandcomment.image" height="150" width="150"/>    
                </div>
            </div>
            <br>
             <strong>Composite Vaccine :</strong>
             <br>
             <div>
                 <div *ngFor="let data of subvac">
                    <span class="subvaccine m-1"><span>{{data}}</span></span> 
                 </div>
             </div>
            </div>
            <div *ngIf="showdeffer">
                <br>
                <div>
                    <button class="btn btns m-1" id="one" (click)="givenbyother('Given',selectedvaccine)">Given</button>
                    <button class="btn btns m-1" id="two" (click)="givenonduedate()">Not Given</button>
                    <button class="btn btns m-1" id="three" (click)="rescheduled(selectedvaccine , 'reschedule')">Deferred</button>
                    <button class="btn btns m-1" id="four" (click)="callpending()">Pending</button>
                    <button class="btn btns m-1" id="five" (click)="calldefeered()">Not Required</button>
                </div>
                <br>
                <strong>Master Vaccine Details :</strong><br>
                <div>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <strong>{{vacccinedetail.master_vaccine[0].drug_name}}</strong></div>
                <div>Due Date &nbsp;&nbsp;&nbsp;: <strong>{{vacccinedetail.master_vaccine[0].due_date}} </strong></div>
                <div>Brand Name : <strong>{{vacccinedetail.master_vaccine[0].brand_name}}</strong></div>
                <br>
                <div >Dependency :  <strong>{{dependency}}</strong></div>
   
                <br>
                <div *ngIf="imageandcomment.comments != ''">
                    <div  *ngIf="imageandcomment.comments != null">
                       <strong>Comments :</strong>
                       <p>{{imageandcomment.comments}}</p>
                    </div>
               </div>
               <div *ngIf="imageandcomment.image != null">
                   <div *ngIf="imageandcomment.image != ''">
                    <strong>Batch Image :</strong><br>
                       <img [src]="imgenvironment+'/helyxonapi/cloud/image/upload/'+ imageandcomment.image" height="150" width="150"/>    
                   </div>
               </div>
               <br>
                <strong>Composite Vaccine :</strong>
                <br>
                <div>
                    <div *ngFor="let data of subvac">
                       <span class="subvaccine m-1"><span>{{data}}</span></span> 
                    </div>
                </div>
            </div>
        </div>

         <div *ngIf="hideall">

            <div>
                <button class="btn btns m-1" id="one" (click)="givenbyother('Given',selectedvaccine)">Given</button>
                <button class="btn btns m-1" id="two" (click)="givenonduedate()">Not Given</button>
                <button class="btn btns m-1" id="three" (click)="rescheduled(selectedvaccine , 'reschedule')">Deferred</button>
                <button class="btn btns m-1" id="four" (click)="callpending()">Pending</button>
                <button class="btn btns m-1" id="five" (click)="calldefeered()">Not Required</button>
            </div>



            <!-- <div>
              <input type="radio" id="css" name="fav_language" value="CSS" [checked]="selectedvaccine.status == ('adminsitrated-1' || 'Administered')" (click)="givenbyother('Given',selectedvaccine)">
              &nbsp;&nbsp;<label for="css">Given</label>
            </div>
            <div>
              <input type="radio" id="javascript" name="fav_language" value="JavaScript" [checked]="selectedvaccine.status == ''" (click)="givenonduedate()">
              &nbsp;&nbsp;<label for="javascript">Not Given</label>
            </div>
            <div>
              <input type="radio" id="css" name="fav_language" value="CSS" [checked]="selectedvaccine.status == ('rescheduled')" (click)="rescheduled(selectedvaccine , 'reschedule')">
              &nbsp;&nbsp;<label for="css">Deferred</label>
            </div>
            <div>
              <input type="radio" id="javascript" name="fav_language" value="JavaScript" [checked]="selectedvaccine.status == 'Pending'" (click)="callpending()">
              &nbsp;&nbsp;<label for="javascript">Pending</label>
            </div>
            <div>
              <input type="radio" id="javascript" name="fav_language" value="JavaScript" [checked]="selectedvaccine.status == 'Deferred'" (click)="calldefeered()">
              &nbsp;&nbsp;<label for="javascript">Not Required</label>
            </div> -->
          </div>
        
        <br>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <span *ngIf="showimageupload"><img src="assets/images/add.svg" height="20" width="20" />&nbsp;Upload Batch Image &nbsp;<mat-slide-toggle [(ngModel)]="mymodel" (change)="togglebatch()" color="primary">&nbsp;Enter Text&nbsp;<img src="assets/images/type.svg" height="20" width="20"/></mat-slide-toggle></span>
            </div>
        </div>

      <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12"  *ngIf="batchimageshow">
        <div class="row" *ngIf="!batch_image">
            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="imagegiven">
            <form [formGroup]="image_Form">
                  <!-- given imageform datepicker -->
                <mat-form-field appearance="fill" class="mt-3">
                  <mat-label>Select Given Vaccinated Date</mat-label>
                  <input matInput [matDatepicker]="picker2"
                  formControlName="imagegivendate" placeholder="MM/DD/YYYY" [max]="maxDate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            <div class="error" *ngIf="(image_Form.controls.imagegivendate.touched||image_Form.controls.imagegivendate.dirty)&&image_Form.controls.imagegivendate.errors?.required">
             Date Required
            </div>
           <div class="mt-3">
            <input type="file" id="file" (change)="selectfile($event)" accept="image/*" formControlName="imagegiven"/>
            <label for="file" id="labels">Choose Batch image &nbsp;<img src="assets/images/cloud-computing.svg" height="20" width="20" class="float-right"/>&nbsp;</label>
            <div class="error" *ngIf="(image_Form.controls.imagegiven.touched||image_Form.controls.imagegiven.dirty)&&image_Form.controls.imagegiven.errors?.required">
                Date Required
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-1" *ngIf="url != ''">
                <div *ngIf="showimageupload">
                    <span><img [src] = "url" width="160"/></span> 
                    <i class="fas fa-times" style="font-size: 15px;margin-left: 4px;position: absolute;" (click)="removeimage()"></i>
                </div>
            </div>
           </div>
            
           <div class="mt-2">
              <h4 class="m-0" *ngIf="mybrand != ''" >Change Brand : {{branddata}}</h4> 
              <div style="height: 115px;overflow-y: auto;" *ngIf="mybrand != ''">
               <button type="button" class="btn btn-sm btn-outline-primary p-1 m-1" (click)="add_vaccine(brand)" *ngFor="let brand of mybrand; index as i" >{{brand}}</button>
              </div>
              <span style="color: red;" *ngIf="textselectbrand">select brand</span>
           </div>

           <div class="mt-2">
               <button type="button" class="btn btn-outline-secondary" (click)="addcomposite()"><img src="assets/images/syringe.png" height="17" width="17"/> {{showcheckbox ? 'Close' : 'Add'}} Composite Vaccination</button>
               <div style="height: 85px;overflow-y: auto;" class="mt-1">
                   <button type="button" class="btn btn-sm btn-outline-primary p-1 m-1" *ngFor="let subvac of subvaccines;" >{{subvac.drug_name}}&nbsp;&nbsp;<img src="assets/images/cancel.png" height="10" width="10" (click)="removecomposite(subvac)"/></button>
               </div>
           </div>

           <div class="mt-2">
            <div class="col-sm-12 col-md-12 col-lg-12"><span><button type="button" style="float:right" class="btn btn-outline-primary mt-1" type="submit" (click)="savegivenimage()">Save</button></span></div>
           </div>

           </form>
        </div>
        </div>

        <div class="row" *ngIf="batch_image">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <form [formGroup]="textForm">
                   <!-- given textform datepicker -->
                <mat-form-field appearance="fill" class="mt-3">
                    <mat-label>Select Given Vaccinated Date</mat-label>
                    <input matInput [matDatepicker]="picker3"
                    formControlName="textgivendate" placeholder="MM/DD/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="picker3" [max]="maxDate"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
                <div class="mt-2">
                    <input type="text" formControlName="texthere" class="form-control" placeholder=" Enter Text" maxlength="60"/> 
                    <div class="error" *ngIf="(textForm.controls.texthere.touched||textForm.controls.texthere.dirty)&&textForm.controls.texthere.errors?.required">
                       Text Required
                    </div>
                </div>
              
                <div class="mt-2">
                    <h4 class="m-0" *ngIf="mybrand != ''">Change Brand : {{branddata}}</h4> 
                    <div style="height: 115px;overflow-y: auto;" *ngIf="mybrand != ''">
                     <button type="button" class="btn btn-sm btn-outline-primary p-1 m-1" (click)="add_vaccine(brand)" *ngFor="let brand of mybrand; index as i">{{brand}}</button>
                    </div>
                    <span style="color: red;" *ngIf="textselectbrand">select brand</span>
                </div>
               
                <div class="mt-2">
                    <button type="button" class="btn btn-outline-secondary" (click)="addcomposite()"><img src="assets/images/syringe.png" height="17" width="17"/> {{showcheckbox ? 'Close' : 'Add'}} Composite Vaccination</button>
                    <div style="height: 85px;overflow-y: auto;">
                        <button type="button" class="btn btn-sm btn-outline-primary p-1 m-1" *ngFor="let subvac of subvaccines;" >{{subvac.drug_name}}&nbsp;&nbsp;<img src="assets/images/cancel.png" height="10" width="10" (click)="removecomposite(subvac)"/></button>
                    </div>
                </div>

                <div class="mt-2">
                    <div class="col-sm-12 col-md-12 col-lg-12"><span><button type="button" style="float:right" class="btn btn-outline-primary mt-1" type="submit" (click)="savegiventext()">Save</button></span></div> 
                </div>

            </form> 
            </div>
        </div>

  
    </div>
</div>      
       


<form [formGroup]="reschedulevaccine" *ngIf="deferreddate">
    <mat-form-field appearance="fill">
        <mat-label>Select Deferred Date</mat-label>
        <input matInput [min]="minDate" [matDatepicker]="picker2"
        formControlName="reschedulevaccinedate" placeholder="MM/DD/YYYY" >
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <div class="error" *ngIf="(reschedulevaccine.controls.reschedulevaccinedate.touched||reschedulevaccine.controls.reschedulevaccinedate.dirty)&&reschedulevaccine.controls.reschedulevaccinedate.errors?.required">
        Date Required
    </div>
    <br>
    <div class="mt-2">
        <button type="button" class="btn btn-outline-secondary" (click)="addcompositedeffer()"><img src="assets/images/syringe.png" height="17" width="17"/> {{showcheckboxdeffer ? 'Close' : 'Add'}} Composite Vaccination</button>
        <div style="height: 85px;overflow-y: auto;" class="mt-1">
            <button type="button" class="btn btn-sm btn-outline-primary p-1 m-1" *ngFor="let subvac of sub_vaccines;" >{{subvac.drug_name}}&nbsp;&nbsp;<img src="assets/images/cancel.png" height="10" width="10" (click)="remove_composite(subvac)"/></button>
        </div>
    </div>
    <br>
    <div class="col-sm-12 col-md-12 col-lg-12"><span><button type="button" style="float:right" class="btn btn-outline-primary mt-1" type="submit" (click)="saverescheduledate()">Save</button></span></div> 
</form>









<!-- bottom bar -->
<section style="margin-top:100px;font-family: Poppins !important;">

            <span><img src="assets/images/indicators/green.svg" height="20" width="20"/>&nbsp;Given&nbsp;</span>
            <span><img src="assets/images/indicators/grey.svg" height="20" width="20"/>&nbsp;Not Given&nbsp;</span>
            <span><img src="assets/images/indicators/orange.svg" height="20" width="20"/>&nbsp;Deferred&nbsp;</span>
            <span><img src="assets/images/indicators/red.svg" height="20" width="20"/>&nbsp;Pending&nbsp;</span>
            <span><img src="assets/images/indicators/black.svg" height="20" width="20"/>&nbsp;Not Required&nbsp;</span>
    
        </section>
    </div>
</div>
</div>


<div id="print_duevactable" style="display: none;">
  
    <div class="row" style="padding: 10px 10px 10px 10px!important;background-color:white !important;transform: rotate(180deg);">
        <br>
        <div id="hospital" class="col-md-12 text-center">
            <h3 style="text-align: center;">{{hospital}}</h3>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12"> 
            <span class="stylename">Name : <strong>{{patientdata.full_name + " "}}</strong></span>
            <span class="stylename">DOB : <strong>{{patientdata.bday | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Gender : <strong>{{patientdata.gender + " "}}</strong></span>
            <span class="stylename">Age : <strong>{{age + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Date : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine_date | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Name : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine}}</strong></span>
            <span class="stylename">Given : <strong *ngIf="adherence">{{adherence.done_vaccine}}</strong></span>
            <span class="stylename">Total : <strong *ngIf="adherence">{{adherence.total_vaccine}}</strong></span>
            <span class="stylename">Adherence : <strong *ngIf="adherence">{{adherence.adherence_percentage}}%</strong></span>  
       </div>
       <br>
    <table>
        <tr>
          <th *ngFor="let group of agegroup; index as i">{{group}} <br> {{agedate[i]}}</th>
        </tr>
        <th *ngFor="let group of agegroup; index as i" style="padding: 0px !important;">
            <tr *ngFor="let vac of subchild[i]" class="text-center">
                <td [ngClass]="{'card___reschedule':vac.status == 'rescheduled' ,'card___Deferred':vac.status == 'Deferred', 'card___deffered':vac.status == 'deffered' , 'card____Administered':vac.status == 'Administered' ,'card____givenbyother':vac.status == 'adminsitrated-1','card____Pending':vac.status == 'Pending','card___empty':vac.status == ''}">{{vac.drug_name}}</td>
            </tr>
        </th>
      </table>
      <br>
      <div class="row" style="margin-top: 5px !important;" id="allspan">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <button style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #FF3727;text-align:center">Pending</button>
        </div>
        <div class="col-md-1"></div>
   </div>
    </div>
</div>

<div id="printdueimg" style="display:none">
    <img src={{duevacimg}}   style="transform: rotate(90deg);" id="duemyimage">
 </div>



<div id="print_givenvactable" style="display: none;">

    <div class="row" style="padding: 10px 10px 10px 10px!important;background-color:white !important;transform: rotate(180deg);">
        <br>
        <div id="hospital" class="col-md-12 text-center">
            <h3 style="text-align: center;">{{hospital}}</h3>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12"> 
            <span class="stylename">Name : <strong>{{patientdata.full_name + " "}}</strong></span>
            <span class="stylename">DOB : <strong>{{patientdata.bday | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Gender : <strong>{{patientdata.gender + " "}}</strong></span>
            <span class="stylename">Age : <strong>{{age + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Date : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine_date | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Name : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine}}</strong></span>
            <span class="stylename">Given : <strong *ngIf="adherence">{{adherence.done_vaccine}}</strong></span>
            <span class="stylename">Total : <strong *ngIf="adherence">{{adherence.total_vaccine}}</strong></span>
            <span class="stylename">Adherence : <strong *ngIf="adherence">{{adherence.adherence_percentage}}%</strong></span>  
       </div>
       <br>
    <table>
        <tr>
          <th *ngFor="let group of agegroup; index as i">{{group}} <br> {{agedate[i]}}</th>
        </tr>
        <th *ngFor="let group of agegroup; index as i" style="padding: 0px !important;">
            <tr *ngFor="let vac of subchild[i]" class="text-center">
                <td [ngClass]="{'card___reschedule':vac.status == 'rescheduled' ,'card___Deferred':vac.status == 'Deferred', 'card___deffered':vac.status == 'deffered' , 'card___Administered':vac.status == 'Administered' ,'card___givenbyother':vac.status == 'adminsitrated-1','card___Pending':vac.status == 'Pending','card___empty':vac.status == ''}">{{vac.drug_name}}</td>
            </tr>
        </th>
      </table>
      <br>
      <div class="row" style="margin-top: 5px !important;" id="allspan">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <button style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #12AC68;text-align:center">Given</button>
        </div>
        <div class="col-md-1"></div>
   </div>
    </div>
</div>

<div id="printgivenimg" style="display:none">
    <img src={{givenvacimg}}   style="transform: rotate(90deg);" id="givenmyimage">
 </div>

<div id="print_vactable" style="display: none;">
    <div class="row" style="padding: 10px 10px 10px 20px!important;background-color:white !important;transform: rotate(180deg);" >
        <br>
        <div id="hospital" class="col-md-12 text-center">
            <h3 style="text-align: center;">{{hospital}}</h3>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12"> 
            <span class="stylename">Name : <strong>{{patientdata.full_name + " "}}</strong></span>
            <span class="stylename">DOB : <strong>{{patientdata.bday | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Gender : <strong>{{patientdata.gender + " "}}</strong></span>
            <span class="stylename">Age : <strong>{{age + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Date : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine_date | date:'dd-MMM-yyyy' + " "}}</strong></span>
            <span class="stylename">Last Vaccinated Name : <strong *ngIf="hidepatientheader">{{patientheader.last_vaccine}}</strong></span>
            <span class="stylename">Given : <strong *ngIf="adherence">{{adherence.done_vaccine}}</strong></span>
            <span class="stylename">Total : <strong *ngIf="adherence">{{adherence.total_vaccine}}</strong></span>
            <span class="stylename">Adherence : <strong *ngIf="adherence">{{adherence.adherence_percentage}}%</strong></span>  
       </div>
       <br>
        <table>
            <tr>
              <th *ngFor="let group of agegroup; index as i">{{group}} <br> {{agedate[i]}}</th>
            </tr>
            <th *ngFor="let group of agegroup; index as i" style="padding: 0px !important;">
                <tr *ngFor="let vac of subchild[i]" class="text-center">
                    <td [ngClass]="{'card__reschedule':vac.status == 'rescheduled' ,'card__Deferred':vac.status == 'Deferred', 'card__deffered':vac.status == 'deffered' , 'card__Administered':vac.status == 'Administered' ,'card__givenbyother':vac.status == 'adminsitrated-1','card__Pending':vac.status == 'Pending','card__empty':vac.status == ''}">{{vac.drug_name}}</td>
                </tr>
            </th>
            
          </table>

          <div class="row" style="margin-top: 5px !important;" id="allspan">
               <div class="col-md-1"></div>
               <div class="col-md-10">
                   <span style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #12AC68;text-align:center">&nbsp;&nbsp;Given</span>
                   <span style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #868F90;text-align:center">&nbsp;&nbsp;Not Given</span>
                   <span style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #FE9800;text-align:center">&nbsp;&nbsp;Deferred</span>
                   <span style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #FF3727;text-align:center">&nbsp;&nbsp;Pending</span>
                   <span style="padding: 7px;margin: 14px 7px 7px 14px;background-color: #000;text-align:center;color: white">&nbsp;&nbsp;Not Required</span>
               </div>
               <div class="col-md-1"></div>
          </div>
     </div> 
</div>

<div id="printallimg" style="display:none">
    <img src={{vacimg}}   style="transform: rotate(90deg);" id="myimage">
 </div>

<div style="margin-top: 10px !important;"></div>

