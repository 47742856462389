<div class="container">
    <i class="fa fa-times closeTab" style="float: right;" (click)="closeTab()" aria-hidden="true"></i>
    <div class="row">
        <div class="col-2 mat_style_">
                <mat-form-field>
                  <mat-label>Start Date</mat-label>
                  <input matInput [matDatepicker]="start" (dateChange)="startDateChange($event)" disabled>
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="start"></mat-datepicker-toggle>
                  <mat-datepicker #start disabled="false"></mat-datepicker>
                </mat-form-field>
              
        </div>        
        <div class="col-2 mat_style_">
                <mat-form-field>
                  <mat-label>End Date</mat-label>
                  <input matInput [matDatepicker]="end" (dateChange)="endDateChange($event)" disabled>
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
                  <mat-datepicker #end disabled="false"></mat-datepicker>
                </mat-form-field>
              
        </div>        
    </div>
<table style="width:100%;margin-top: 20px;">
    <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
        <tr style="height: 30px;">
            <th style="border: 2px solid #c8e2ff;width: 6%;">S.No
            </th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">Name
            </th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">MR Number
            </th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">Due Date</th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">LMP Date</th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">Phone Number</th>
            <!-- <th style="border: 2px solid #c8e2ff;width: 13%;">Message</th> -->
            <th style="border: 2px solid #c8e2ff;width: 13%;">Plan Day</th>
            <th style="border: 2px solid #c8e2ff;width: 13%;">Initiate Now</th>
            
        </tr>

    </thead>
    <tbody style="font-size: 15px; text-align: center;">
        <tr style="height: 40px;" *ngFor="let data of report; let i =index;">
    
            <td style="border: 2px solid #e3edf8">{{i+1}}</td>
            <td style="border: 2px solid #e3edf8">{{data.name}}</td>
            <td style="border: 2px solid #e3edf8">{{data.mr_number}}</td>
            <td style="border: 2px solid #e3edf8">{{data.due_date}}</td>
            <td style="border: 2px solid #e3edf8">{{data.lmp_date}}</td>
            <td style="border: 2px solid #e3edf8">{{data.ph_number}}</td>
            <!-- <td style="border: 2px solid #e3edf8">{{data.content}}</td> -->
            <td style="border: 2px solid #e3edf8">{{data.plan_day}}</td>
            <td style="border: 2px solid #e3edf8">
                <img src="assets/images/ivr.png" (click)="getIdx(i)">
            </td>
        </tr>
    
<!-- <img *ngIf="ivrSend" src="assets/images/ivrend.png" (click)="sendIdx(i)" -->
    </tbody>
</table>
<p style="height: 40px; text-align: center;" *ngIf="report.length ==0">No Data Found!</p>
</div>