
<div class="form" [formGroup]="vitalForm">
    <div class="box" style="display: flex;">
        <p class="label">Height (cm):</p> <input formControlName="height"  type="number" (keypress)="omit_number($event)" class="form">
    </div>

    <div class="error"
        *ngIf="(vitalForm.controls.height.touched||vitalForm.controls.height.dirty)&&(vitalForm.controls.height.invalid)">
        Check the height. Allowed limits: {{this.allowedLimits.heightMin}} - {{this.allowedLimits.heightMax}}</div>

    <div class="box" style="display: flex;">
        <p class="label">Weight (kg):</p> <input formControlName="weight" type="number" (keypress)="omit_number($event)" class="form">
    </div>

    <div class="error"
    *ngIf="(vitalForm.controls.weight.touched||vitalForm.controls.weight.dirty)&&(vitalForm.controls.weight.invalid)">
    Check the weight. Allowed limits: {{this.allowedLimits.weightMin}} - {{this.allowedLimits.weightMax}}</div>

    <div class="box" style="display: flex;">
        <p class="label">Arm Circumference:</p> <input formControlName="arm_circumference" (keypress)="omit_number($event)" type="number"  class="form">
    </div>

    <div class="error"
        *ngIf="(vitalForm.controls.arm_circumference.touched||vitalForm.controls.arm_circumference.dirty)&&(vitalForm.controls.arm_circumference.invalid)">
        Please check the arm circumference. Allowed limits: 1 - 20</div>

    <div class="box" style="display: flex;">
        <p class="label">Head Circumference:</p> <input formControlName="head_circumference" (keypress)="omit_number($event)"  type="number"  class="form">
    </div>

    <div class="error"
    *ngIf="(vitalForm.controls.head_circumference.touched||vitalForm.controls.head_circumference.dirty)&&(vitalForm.controls.head_circumference.invalid)">
    Check the head circumference. Allowed limits: {{this.allowedLimits.headMin}} - {{this.allowedLimits.headMax}}</div>


    <div class="box" style="display: flex;">
        <p class="label">Chest Cirumference:</p> <input formControlName="chest_circumference" (keypress)="omit_number($event)"  [max]="date" type="number"  class="form">
    </div>

    <div class="error"
        *ngIf="(vitalForm.controls.chest_circumference.touched||vitalForm.controls.chest_circumference.dirty)&&(vitalForm.controls.chest_circumference.invalid)">
        Please check the chest circumference. Allowed limits: 1 - 110</div>

    <div class="box" style="display: flex;">
        <p class="label">Measurement Date:</p> <input formControlName="date" type="date" onkeydown="return false" [max]="date" class="form">
    </div>

</div>

<div style="display: flex;justify-content: space-around;margin-top: 1rem;">

    <button style="color: #FFF;" class="btn btn-success" [class.disabled]="vitalForm.invalid || ( (vitalForm.value.height=='' || !vitalForm.value.height) && (vitalForm.value.weight=='' || !vitalForm.value.weight) && (vitalForm.value.arm_circumference=='' || !vitalForm.value.arm_circumference) && (vitalForm.value.chest_circumference=='' || !vitalForm.value.chest_circumference) && (vitalForm.value.head_circumference=='' || !vitalForm.value.head_circumference) )"
        (click)="save()">Save</button>
    <button style="color: #FFF;" class="btn btn-danger" (click)="close()">Cancel</button>

</div>