<!-- right Side Toggle -->
<div id="sidebar-wrapper1">
  <app-bookappointment (someEvent_close)="closeToggle()"></app-bookappointment>
</div>
  
  <div class="d-flex" id="wrapper">
      <!-- Left Sidebar -->
      
      <div id="page-content-wrapper">
        <nav class="navbar navbar-expand-sm navbar-light border-bottom" id="navbar">
         
          <div class="collapse navbar-collapse" id="navbarSupportedContent"  style="height: 5rem;">

            <div class="row" style="margin-top: 5px;"  [class.disabled]="startstatus">
              
                <div class="tabselect" id="id_0" style="text-align: center;" (click)="apptabs(0)">
                    ALL
                    <p class="appcount">{{allCount}}</p>
                </div>
                <div class="tabselect" id="id_1" style="margin-left: 25px;text-align: center;" (click)="apptabs(1)">
                    CONFIRMED
                    <p class="appcount">{{confirmedCount}}</p>
                </div>
                <div class="tabselect" id="id_2" style="margin-left: 25px;text-align: center;" (click)="apptabs(2)">
                    ARRIVED
                    <p class="appcount">{{arrivedCount}}</p>
                </div>
                <!-- <div class="tabselect" id="id_4" style="margin-left: 25px;text-align: center;" (click)="apptabs(4)">
                  UNPAID
                  <p class="appcount">0</p>
                </div> -->
                <div class="tabselect" id="id_3" style="margin-left: 25px;text-align: center;" (click)="apptabs(3)">
                  CLOSED
                  <p class="appcount">{{closedCount}}</p>
                </div>

                <div style="margin-left: 25px;text-align: center;display: flex;">  
                  <img style="cursor: pointer;width:20px" src="assets/right-arrow-3-copy.svg" (click)="minusDay()">
                  <p style="width:100px;padding-top: 20px;">{{date | date : 'dd-MMM-yyyy' }}</p>
                  <img style="cursor: pointer;width:20px" src="assets/right-arrow-3.svg" (click)="addDay()">
                </div>
            
                <img *ngIf="emergency_indication" style="margin-top: 3px;margin-left: 10px;height:40px" src="assets/emergency.gif">
                <img *ngIf="emergency_indication" style="margin-top: 10px;margin-left: 8px;height:15px;width:100px;" src="assets/emegeny_text.gif">
            </div>

            <ul class="navbar-nav ml-auto mt-2 mt-lg-0"  >
              
              <!-- <li class="nav-item active" style="margin-right: 1rem;">
                <button class="btn btn-primary" (click)="Gynec()" >Gynec Engagement</button>
              </li> -->
              <li class="nav-item active" style="margin-right: 1rem;" [class.disabled]="startstatus">
                <button class="btn btn-primary" (click)="scanOpt()" >Scan QR Code</button>
              </li>
              <li class="nav-item active" style="margin-right: 1rem;" [class.disabled]="startstatus">
                <button class="btn btn-primary" id="menu-toggle">Book Appointment</button>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
              </li>
              <li class="nav-item active">
                <img  [class.disabled]="startstatus" *ngIf="checkVitallAccessStatus==1 || checkVitallAccessStatus==3" style="height: 35px;cursor: pointer;margin-top: 2px;" matTooltip="Vitall" src="assets/vitall_icon.svg" (click)="goToVitall();">
                <img  [class.disabled]="startstatus" style="height: 35px;cursor: pointer;margin-left: 15px;" matTooltip="Dashboard" src="assets/dashboard active.svg" (click)="goToDashboard();">
                <img style="height: 35px;cursor: pointer;margin-left: 15px;" matTooltip="Prescriptions" (click)="getPrintCount1()" [src]="fileSrc">

              </li>
              <li class="nav-item active">
                
              </li>
             
            </ul>
          </div>
        </nav>
        
      </div>
      
    </div>

 <!-- body -->
    <div class="row">
      <div class="appointmnetList">
        <app-chief-complaint [appindex]="appindex" [selecteddate]="date" [queueList]="queueList"  (someEvent_full)="toggle_full()" (someEvent_print)="getPrintCount()" (startStatus)="started($event)" (emergencyIndication)="emerInd($event)" (receivemsg)="receivemsg($event)" [update]="update"></app-chief-complaint>
      </div>
    </div>
    
    <!-- (someEvent_vitalScore)="vitalScore()" -->
  
  