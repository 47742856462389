<!--  -->
<div class="conTaniner">
  <div class="row titleContainer">
      <div class="col-sm-3 col-md-3 col-lg-3 containerTop">
          <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 containerTop" style="text-align:center;">
          <p class="headText">{{!vitallData?'Vital History':'Vital Data'}}</p>
      </div>
      <div class="col-sm-3 col-md-3 col-lg-3 containerTop">
        <label class="vitalLabel">Vital History</label> 
        <label class="switch">
            <input type="checkbox" (click)="getVitallDatas()">
            <span class="slider"></span>
        </label>
        <label class="vitalLabel" [ngStyle]="{'color':vitallData ? '#45c2c5':'inherit'}">Vital Data</label> 
      </div>
  </div>  
  <div class="contaniner" *ngIf="vitallData">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3" style="margin-top: 50px;">
          From :
          <div>
               <mat-form-field appearance="fill">
                   <mat-label>Choose a date</mat-label>
                   <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange) = "vitallDate($event)">
                   <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                   <mat-datepicker #picker startView="month" [startAt]="startDate"></mat-datepicker>
               </mat-form-field>
          </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-3" style="margin-top: 50px;">
          To :
          <div>
           <mat-form-field appearance="fill">
               <mat-label>Choose a date</mat-label>
               <input matInput [matDatepicker]="picker1" [(ngModel)]="endDate" (dateChange) = "vitallDate($event)">
               <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
               <mat-datepicker #picker1 startView="month" [endAt]="EndDate"></mat-datepicker>
             </mat-form-field>              
          </div>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1" style="margin-top: 50px;">
          <select (change)="onSelectChange_()" [(ngModel)]="seconds" style="top: 25px;border-radius: 5px;padding: 7px 10px;position: relative;">
            <option value="5">5 Seconds</option>
            <option value="10">10 Seconds</option>
            <option selected value="20">20 Seconds</option>
            <option value="60">1 Minute</option>
            <option value="300">5 Minutes</option>
            <option value="600">10 Minutes</option>
            <option value="3600">1 Hour</option>
          </select>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-5" style="margin-top: 50px;">
        <select *ngIf="dropdowndate && dropdowndate.length > 0" style="top: 25px;margin-left: 20px;border-radius: 5px;padding: 7px 10px;position: relative;" (change)="onSelectChange($event)" [(ngModel)]="dropDate">
        <option *ngFor="let d of dropdowndate" [value]="d.date" style="padding: 00.5rem;">{{d.date1}}</option>
    </select>
        <!-- <i class="fa fa-file-excel-o" (click)="export()" style="font-size: 32px;text-align: center;top: 26px;position: relative;padding: 0px 10px 0px 35px;" aria-hidden="true" title="Export"></i> -->
      </div>
   </div>
    <table class="table table-bordered" style="text-align:center; margin-top:10px;" id="dataTable">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">Date</th>
          <th rowspan="2">Time</th>
          <th rowspan="2">O2(%)</th>
          <th rowspan="2">HR(PPM)</th>
          <th rowspan="2">PI(%)</th>
          <th rowspan="2">RR(RPM)</th>
          <th colspan="2">Temperature(&#176;C)</th>
          <th rowspan="2">Hub Name</th>
        </tr>
        <tr>
          <th>Temp</th>
          <th>Temp Offer</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tableData;let i = index">
          <td>{{i+1}}</td>
          <td>{{data.timeStamp*1000 | date:'dd-MM-yyyy'}}</td>
          <td>{{data.timeStamp*1000 | date:'hh:mm:ss a'}}</td>
          <td>{{data.o2}}</td>
          <td>{{data.bpm}}</td>
          <td>{{data.pi}}</td>
          <td>{{data.rr}}</td>
          <td>{{data.temp}}</td>
          <td>{{data.tempoffset}}</td>
          <td>{{data.hub_name}}</td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="spinner" class="d-flex justify-content-center">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <tr *ngIf="!spinner && this.tableData.length == 0" style="text-align: center;display: block;">
      No Data Found!
    </tr>
  </div>
<span *ngIf="!vitallData">
  <div class="row">
     <div class="col-lg-2 col-md-2 col-sm-3" style="margin-top: 50px;">
         From :
         <div>
              <mat-form-field appearance="fill">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange) = "onDateChange($event)">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="month" [startAt]="startDate"></mat-datepicker>
              </mat-form-field>  
              <!-- <span class="float-right text-primary sub_headings_right">Select Date</span>
              <input
              [ngClass]="{'highlightlmpmissing': lmpmissing == true}"
              class="form-control dateInput" type="date"
              onkeypress="return event.charCode == 8" (change)="getEdd()"
              [max]="obslmpmaxDate" [ngModel]='obslmp' formControlName="obslmps"
              >             -->
         </div>
     </div>
     <div class="col-lg-2 col-md-2 col-sm-3" style="margin-top: 50px;">
         To :
         <div>
          <mat-form-field appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker1" [(ngModel)]="endDate" (dateChange) = "onDateChange($event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 startView="month" [endAt]="EndDate"></mat-datepicker>
            </mat-form-field>              
         </div>
     </div>
     <div class="col-lg-6 col-md-6 col-sm-3" ></div>
     <div class="col-lg-2 col-md-2 col-sm-3" style="margin-top: 50px; display: flex;">
         <span class="Graph"> &nbsp;List&nbsp;
          <mat-slide-toggle [(ngModel)] = "vital" (change) = "slideToggleChange(vital)" >&nbsp;Graph&nbsp;</mat-slide-toggle>
         </span>
         
         <!-- <div class="List"></div> -->
     </div>
  </div>

  <div class="row" *ngIf="vitalHistory == '' && vitaltoggle == false" style="text-align: center;margin-top: 1rem;">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div> No Data Found</div>
    </div>
  </div>

  <div class="row" *ngIf="vitalHistory !== '' && vitaltoggle == false" style="margin-top: 1rem;">
      <div class="col-lg-12 col-md-12 col-sm-12 ">
          <mat-expansion-panel hideToggle *ngFor="let data of Vital_history;let j=index" >
            <mat-expansion-panel-header>
              <mat-panel-title>
              {{j+1}}
              </mat-panel-title>
              <mat-panel-description>
              {{data.date | date: 'dd-MMM-yyyy'}} 
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="datasource1[j]" class="mat-elevation-z8">

                  <!-- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
                
                  <!-- Position Column -->
                  <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> S.No. </th>
                    <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.postion}} </td>
                    
                  </ng-container>
                
                  <!-- Time Column -->
                   <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Date </th>
                    <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.date}} </td>
                  </ng-container> 

                  <!-- Weight Column -->
                  <ng-container matColumnDef="weight">
                    <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Weight <br> (KG) </th>
                    <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.weight}} </td>
                  </ng-container>
                
                  <!-- Height Column -->
                   <ng-container matColumnDef="height">
                    <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Height <br> (CM) </th>
                    <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.height}} </td>
                  </ng-container>

                  <!-- BMI Column -->
                  <ng-container matColumnDef="bmi">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> BMI <br> (Kg/m2) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.bmi}} </td>
                    </ng-container> 

                    <!-- Head circumference Column  -->
                    <ng-container matColumnDef="head">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Head Circumference <br> (CM) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.head}} </td>
                    </ng-container>

                    <!-- Temperature Column  -->
                     <ng-container matColumnDef="temperature">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Temperature <br> (F) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.temperature}} </td>
                    </ng-container> 

                    <!-- Heart Rate Column  -->
                    <ng-container matColumnDef="heart">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Heart Rate <br> (BPM) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.heart}} </td>
                    </ng-container> 

                    <!-- Respiratory rate Column  -->
                    <ng-container matColumnDef="respiratory">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Respiratory Rate <br> (RPM) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.respiratory}} </td>
                    </ng-container> 

                    <!-- Bp systolic Column -->
                     <ng-container matColumnDef="bp_systolic">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> BP systolic <br> (mmHg) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.bp_systolic}} </td>
                    </ng-container> 

                    <!-- Bp Diastolic Column  -->
                     <ng-container matColumnDef="bp_diastolic">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Bp Diastolic </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.bp_diastolic}} </td>
                    </ng-container> 

                    <!-- SpO2 Column   -->
                     <ng-container matColumnDef="spo2">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> SpO2 <br> (%) </th>
                      <td mat-cell *matCellDef="let element" style="font-weight: bold;text-align: center;"> {{element.spo2}} </td>
                    </ng-container> 

                    <!-- Gulcometer Column  -->
                     <ng-container matColumnDef="glucometer">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold;text-align: center;"> Glucometer <br> (mmol/L) </th>
                      <td mat-cell *matCellDef="let element"  style="font-weight: bold;text-align: center;"> {{element.glucometer}} 
                        <span class="fa fa-caret-square-o-up" (click) = "openGlucometerPopup(element.glucometer_array)" ></span>
                      </td>
                    </ng-container> 
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>  
            
            
          </mat-expansion-panel>
              <!-- <mat-expansion-panel (opened)="panelOpenState = true"
                                   (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Self aware panel
                  </mat-panel-title>
                  <mat-panel-description>
                    Currently I am {{panelOpenState ? 'open' : 'closed'}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>I'm visible because I am open</p>
              </mat-expansion-panel> -->
          
      </div>
  </div>

  <div class="row" *ngIf="vitaltoggle == true" style="text-align: center;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
      <div style="display: flex; flex-direction: column; align-items: center;">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12" style="background: #FAD1E6;margin-top: 15px;padding: 2px;"
         *ngIf = "potchartdataseries?.length>0">
         <div id = "vitalBpChart"></div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12" *ngIf = "potchartdataseries?.length == 0">
          No Data Found
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12" style="background: #FAD1E6;margin-top: 15px;padding: 2px;"
         *ngIf = "gulcochartdataseries?.length>0 ">
         <div id = "vitalGulcometerChart"></div>
        </div>
        <!-- <div class="col-lg-12 col-md-6 col-sm-6" *ngIf = "gulcochartdataseries?.length == 0 ">
          No Data Found
        </div> -->
      </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="vitaltoggle == false" >
    <div id = "vitalBpChart"></div> 
  </div> -->
</span>
</div>