<div class="row">
    <div class="col-lg-8 col-md-8">
        <p class="save" style="font-size: 1.2rem;">Today Prescriptions</p>
    </div>

    <div class="col-lg-2 col-md-2">
        <!-- <p class="cancel" (click)="onNoClick()">Cancel</p> -->
    </div>

    <div class="col-lg-2 col-md-2">
        <p style="margin-bottom: 0;" (click)="onNoClick()"><img style="height: 1.2rem;width: 1.2rem;float: right;" src="assets/cancel.svg"></p>
    </div>
</div>

<table *ngIf=" data.printData.length!=0">
    <tr style="border: 2px solid;">
        <th>S No</th>

        <th>MR Number</th>

        <th>Name</th>

        <th>Token</th>

        <th>Date</th>

        <th>Print</th>
      
    </tr>

    <tr style="border: 0.5px solid;" *ngFor="let d of data.printData;let i = index">
        <!-- <td>Hi</td> -->
        <td>{{(i+1)}}</td>
        <td>{{d.mr_number}}</td>
        <td>{{d.name_given}}</td>
        <td>{{d.token}}</td>
        <td>{{d.date | date:'dd MMM yyyy'}}</td>
        <td style="display: flex;justify-content: center;"><img style="cursor: pointer;" (click)="view(d)" src="assets/print1.svg"></td>

        <!-- <td style="width: 4rem;"><span *ngIf="d.price">&#x20B9;</span>  {{d.price}}</td>
        <td [class.green]="d.stock_status=='instock'" [class.red]="d.stock_status=='outofstock'"><span *ngIf="d.stock_status=='instock'">In Stock</span> <span *ngIf="d.stock_status=='outofstock'">Out of Stock</span></td>
        <td>
            <img *ngIf="d.images.length>0" style="height: 3rem;width: 3rem;" [src]="d.images[0].src">
        </td>
        <td style="display: flex;justify-content: center;"><img style="cursor: pointer;" (click)="view(d)" src="assets/eye_icon.svg"></td>

        <td><button class="btn btn-primary" [disabled]="d.stock_status=='outofstock'" (click)="addDrug(d)">Add</button></td> -->

    </tr>
</table>