<div style="display: flex;justify-content: space-between;">
    <p class="header">Change Formulation</p>

    <img style="width:1.5rem" (click)="onNoClick()" src="assets/cancel.svg">

</div>

<table>
    <tr>
        <td [class.highlight]="formulation=='Injection'">
            <div class="box" (click)="setFormulation('Injection')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/syringe.svg">
                </div>

                <p class="text-center icontext">Injection</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Lotion'">
            <div class="box" (click)="setFormulation('Lotion')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/lotion.svg">
                </div>

                <p class="text-center icontext">Lotion</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Ointment'">
            <div class="box" (click)="setFormulation('Ointment')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/cosmetic.svg">
                </div>

                <p class="text-center icontext">Ointment</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Soap'">
            <div class="box" (click)="setFormulation('Soap')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/soap.svg">
                </div>

                <p class="text-center icontext">Soap</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Tablet'">
            <div class="box" (click)="setFormulation('Tablet')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/tablet.svg">
                </div>

                <p class="text-center icontext">Tablet</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Suspension'">
            <div class="box" (click)="setFormulation('Suspension')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/Suspension.svg">
                </div>

                <p class="text-center icontext">Suspension</p>

            </div>
        </td>
    </tr>

    <tr>
        <td [class.highlight]="formulation=='Cream'">
            <div class="box" (click)="setFormulation('Cream')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/cream.svg">
                </div>

                <p class="text-center icontext">Cream</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Capsules'">
            <div class="box" (click)="setFormulation('Capsules')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/capsules.svg">
                </div>

                <p class="text-center icontext">Capsules</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Drops'">
            <div class="box" (click)="setFormulation('Drops')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/eye-dropper.svg">
                </div>

                <p class="text-center icontext">Drops</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Oil'">
            <div class="box" (click)="setFormulation('Oil')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/massage-oil.svg">
                </div>

                <p class="text-center icontext">Oil</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Gel'">
            <div class="box" (click)="setFormulation('Gel')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/alcohol-gel.svg">
                </div>

                <p class="text-center icontext">Gel</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Powder'">
            <div class="box" (click)="setFormulation('Powder')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/baby-powder.svg">
                </div>

                <p class="text-center icontext">Powder</p>

            </div>
        </td>
    </tr>

    <tr>
        <td [class.highlight]="formulation=='Syrup'">
            <div class="box" (click)="setFormulation('Syrup')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/syrup.svg">
                </div>

                <p class="text-center icontext">Syrup</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Sachet'">
            <div class="box" (click)="setFormulation('Sachet')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/sachet.svg">
                </div>

                <p class="text-center icontext">Sachet</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Vaccine'">
            <div class="box" (click)="setFormulation('Vaccine')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/vaccine.svg">
                </div>

                <p class="text-center icontext">Vaccine</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Inhaler'">
            <div class="box" (click)="setFormulation('Inhaler')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/inhaler.svg">
                </div>

                <p class="text-center icontext">Inhaler</p>

            </div>
        </td>

        <td [class.highlight]="formulation=='Others'">
            <div class="box" (click)="setFormulation('Others')">
                <div style="display: flex;justify-content: center;">
                    <img class="icon" src="assets/medicine.svg">
                </div>

                <p class="text-center icontext">Others</p>
            </div>
        </td>



    </tr>
</table>

<div style="display:flex;flex-direction:column;margin-top: 1rem;">
    <mat-checkbox class="example-margin" style="margin-left: 1rem;" (change)="emptyFormulation()" [(ngModel)]="create">
        <p class="text">Create new</p>
    </mat-checkbox>

    <input *ngIf="create" style="margin-left: 1rem;margin-right: 1rem;" [(ngModel)]="formulation">
</div>

<div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
    <button class="save btn" [disabled]="formulation==''" (click)="save()" style="width: fit-content;">Save</button>
    <button class="cancel btn" (click)="onNoClick()" style="width: fit-content;">Cancel</button>

</div>