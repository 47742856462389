<div style="padding:1rem">
    <p *ngIf="checkstatus == 'add'" style=color:#0063FF;font-weight:600>Add New</p>
    <p *ngIf="checkstatus == 'update'" style=color:#0063FF;font-weight:600>Edit</p>
    <p *ngIf="checkstatus == 'delete'" style=color:#0063FF;font-weight:600>Delete</p>

    <div style="display: flex;justify-content: space-between;font-size: 1rem;">
        
    <span>#</span> &nbsp;&nbsp;
    <input *ngIf="checkstatus == 'add' || checkstatus == 'update'" [(ngModel)]="newCare" (input)=deduct() maxlength="120">
    <input *ngIf="checkstatus == 'delete'" [(ngModel)]="newCare" readonly disabled>
        <div style="display: flex;">
    
            <button *ngIf="checkstatus == 'add'" style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
                (click)="ok()">Save</button>
            <button *ngIf="checkstatus == 'update'" style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
                (click)="ok()">Update</button>
            <button *ngIf="checkstatus == 'delete'" style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
                (click)="ok()">Delete</button>
    
            <i style="margin-left:1.5rem;margin-top:-60px;font-size: 1.5rem;color:#0063FF;cursor: pointer;" class="fa fa-close" (click)="close()"></i>
    
        </div>
    
    </div><br>
    <span *ngIf="checkerror" style="color:red;margin-top: -10px;display: block;margin-left: 25px;">Please Enter Tag</span>
    <span *ngIf="newCare?.length >= 120" style="color:red;margin-top: -10px;display: block;margin-left: 25px;">Maximum 120 characters only allowed</span>
</div>