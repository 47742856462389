import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppService } from 'src/app/app.service';
import { ChildToParentService } from 'src/app/child-to-parent.service';

import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces/index';
import {base64ToFile} from '../image-cropper/utils/blob.utils';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Toast, ToastrService } from 'ngx-toastr';
import { Md5 } from 'ts-md5/dist/md5';


export interface DialogData_croppedimage {
  selectedimg:any;
}


@Component({
  selector: 'app-drProfile',
  templateUrl: './drProfile.component.html',
  styleUrls: ['./drProfile.component.scss']
})
export class DrprofileComponent implements OnInit {
  @Output() someEvent_float = new EventEmitter<any>();

  doctorProfileForm: FormGroup;
  changepasswordForm: FormGroup;
  submitted = false;
  submitted1 = false;
  urlPoints;
  dmail;
  token;
  
  doc_id;
  dr_name;
  dr_degree;
  hos_name;
  last_login;
  docData;
  drPofileFormShow = false;
  drprofiletxt = false;
  updatebutShowstatus = false;
  changePasswordScreen = false;

  first_Name;
  reg_No;
  phone_Num;
  Alter_No;
  _address;
  _city;
  hospital_Name;
  _biography;
  _summary;
  _speciality;
  _degree;
  _email;
  _state;
  _code;
  _vision;
  _mission;
  uploadCoverDrImage = 'assets/stethoscope1.jpg';
  uploadCoverImageFile;
  sendCoverImgFile;
  sendAvaterFile;
  imageUploadUrl;
  accountdet;
  p_icon = "remove_red_eye"
  mismatchpass = false;

  imageChangedEvent: any = '';
  croppedImage: any = 'assets/defaultmaledr.jpg';
  isShowfloat=false
  //hide doctor wallet
  @Input() receivecroppedimg:any;

  constructor(private appService: AppService,private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService,private childToParentService: ChildToParentService,public dialog: MatDialog) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
   }

  ngOnInit(): void {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dr_name = getauthdetails.doctor_name;
    this.dr_degree = getauthdetails.doctor_degree;
    this.hos_name = getauthdetails.hospital_name;
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    this.last_login = moment(getauthdetails.account_info.Account.lastLoginAt).format('DD-MMM-YYYY h:mm a');

    this.doctorProfileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      regNo:['', Validators.required],
      phoneNum: ['', Validators.required],
      AlterNo:[''],
      address:['', [Validators.required, Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)]],
      city:[''],
      hospitalName:['',Validators.required],
      biography:[''],
      summary:[''],
      speciality:[''],
      degree:['',Validators.required],
      email:['',[Validators.required, Validators.email]],
      state:[],
      code:['',Validators.required],
      vision:[''],
      mission:['']
    });
    this.getdrProfile();

    this.changepasswordForm = this.formBuilder.group({
      existingpassword:['', Validators.required],
      newpassword:['', [Validators.required, DrprofileComponent.patternValidator(/\d/, { hasNumber: true }), 
        DrprofileComponent.patternValidator(/^(?=[^a-z]*[a-z])/, { haslowercase: true }),
        DrprofileComponent.patternValidator(/^(?=[^A-Z]*[A-Z])/, { hasuppercase: true }),
        DrprofileComponent.patternValidator(/^(?=.*?[#?!@$%^&*-])/, { hasspecial: true }),
        DrprofileComponent.patternValidator(/^(?=.{6,})/, { hasminchar: true })]],
      confirmpassword: ['', Validators.required],
    });
}

static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }
    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);
    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

changepasswordtoggle(){
  this.drPofileFormShow = false;
  this.changePasswordScreen =true;
  this.drprofiletxt = true;
}

changerprofiletoggle(){
  this.drprofiletxt = false;
  this.drPofileFormShow = false;
  this.changePasswordScreen = false;
}

flottoggle(){
 // alert()
 this.isShowfloat = !this.isShowfloat 
//  alert(this.isShowfloat)
 this.someEvent_float.next(this.isShowfloat)
 this.childToParentService.onFloatChanged$.next(this.isShowfloat);

}



getdrProfile(){
  var params = {
    account_email: this.dmail,
    doctor_id: this.doc_id
  }

  this.appService.getData(this.urlPoints.getDoctorProfile, params, this.token).subscribe((response: any) => {
    this.docData = response.doctors.profile;

    this.accountdet = this.docData.account;
  this.first_Name = this.docData.display_name;
  this.reg_No = this.docData.registration;
  this.phone_Num = this.docData.contact_number_1;
  this.Alter_No = this.docData.contact_number_2;
  this._address = this.docData.address;
  this._city = this.docData.city;
  this.hospital_Name = this.docData.hospital_name;
  this._biography = this.docData.biography;
  this._summary = this.docData.summary;
  this._speciality = this.docData.speciality;
  this._degree = this.docData.degree;
  this._email = this.docData.email;
  this._state = this.docData.state;
  this._code = this.docData.pin_code;
  this._vision = this.docData.vision;
  this._mission = this.docData.mission;
  this.uploadCoverDrImage = this.imageUploadUrl+ this.docData.cover_art;
  this.croppedImage = this.imageUploadUrl+ this.docData.avatar;

  this.sendCoverImgFile = this.docData.cover_art;
  this.sendAvaterFile = this.docData.avatar;
  localStorage.setItem('dr_name', this.first_Name);
  });
}
// convenience getter for easy access to form fields
get f() { return this.doctorProfileForm.controls; }

get k() { return this.changepasswordForm.controls; }

toggle(id) {
  if ((document.getElementById(id) as HTMLInputElement).type == "password") {
    (document.getElementById(id) as HTMLInputElement).type = "text"
  }
  setTimeout(()=>{                         
    (document.getElementById(id) as HTMLInputElement).type = "password"
}, 500);
  // else {
  //   (document.getElementById("confirmpassword") as HTMLInputElement).type = "password"
  // }
}

changePassword(){
  this.submitted1 =true;
  this.mismatchpass = false;
  if (this.changepasswordForm.invalid) {
    return;
}

let md5 = new Md5();
let mdfive = new Md5();

let expass = this.changepasswordForm.get('existingpassword').value;
let newpass = this.changepasswordForm.get('newpassword').value;
let confpass = this.changepasswordForm.get('confirmpassword').value;

if(newpass != confpass){
  this.mismatchpass = true;
  return;
}
else{
  let params = {
    "account_email": this.dmail,
    "current_password": md5.appendStr(expass).end(),
    "new_password": mdfive.appendStr(newpass).end(),
    "doctor_id": this.doc_id
  }
  this.appService
    .postData(AppConfig.settings.EndPoints.changepasswd, params, this.token)
    .subscribe((response: any = []) => {
      if (response.doctors.success == 1) {
       this.toastr.success("Password changed successfully!");
       this.cancelpassword();
      } else {
        this.toastr.error(response.doctors.message);
      }
    });
}

}

cancelpassword(){
  this.drPofileFormShow = false;
  this.drprofiletxt = false;
  this.updatebutShowstatus = false;
  this.changePasswordScreen = false;
  this.submitted1 = false;
  this.changepasswordForm.reset();
}

drpofileEdit(){
  this.drPofileFormShow = true;
  this.updatebutShowstatus = true;
  this.changePasswordScreen =false;

  this.doctorProfileForm.get("hospitalName").setValue(this.hospital_Name);
  this.doctorProfileForm.get("state").setValue(this._state);
  this.doctorProfileForm.get("city").setValue(this._city);
  this.doctorProfileForm.get("address").setValue(this._address);
  this.doctorProfileForm.get("code").setValue(this._code);
  this.doctorProfileForm.get("firstName").setValue(this.first_Name);
  this.doctorProfileForm.get("regNo").setValue(this.reg_No);
  this.doctorProfileForm.get("phoneNum").setValue(this.phone_Num);
  this.doctorProfileForm.get("AlterNo").setValue(this.Alter_No);
  this.doctorProfileForm.get("biography").setValue(this._biography);
  this.doctorProfileForm.get("summary").setValue(this._summary);
  this.doctorProfileForm.get("speciality").setValue(this._speciality);
  this.doctorProfileForm.get("degree").setValue(this._degree);
  this.doctorProfileForm.get("email").setValue(this._email);
  this.doctorProfileForm.get("vision").setValue(this._vision);
  this.doctorProfileForm.get("mission").setValue(this._mission);
}

drpofileUpdate(){
  this.submitted = true;
  // stop here if form is invalid
  if (this.doctorProfileForm.invalid) {
    return;
}
  
    let params = {
      "account_email": this.dmail,
      "doctor_id": this.doc_id,
      "profile": {
      "pin_code": this.doctorProfileForm.value.code,
      "email": this.doctorProfileForm.value.email,
      "state": this.doctorProfileForm.value.state,
      "account": this.accountdet,
      "hospital_name": this.doctorProfileForm.value.hospitalName,
      "registration": this.doctorProfileForm.value.regNo,
      "mission": this.doctorProfileForm.value.mission,
      "biography": this.doctorProfileForm.value.biography,
      "degree": this.doctorProfileForm.value.degree,
      "contact_number_2": this.doctorProfileForm.value.AlterNo,
      "vision": this.doctorProfileForm.value.vision,
      "summary": this.doctorProfileForm.value.summary,
      "city": this.doctorProfileForm.value.city,
      "address": this.doctorProfileForm.value.address,
      "avatar": this.sendAvaterFile,
      "cover_art": this.sendCoverImgFile,
      "contact_number_1": this.doctorProfileForm.value.phoneNum,
      "speciality": this.doctorProfileForm.value.speciality,
      "display_name": this.doctorProfileForm.value.firstName,
      "name": this.doctorProfileForm.value.firstName,
      "fullname": this.doctorProfileForm.value.firstName,
      "role": "1",
      "force_wallet": "0"
      }
    }
    // display form values on success
    this.appService
    .postData(AppConfig.settings.EndPoints.updatedoctorprofile, params, this.token)
    .subscribe((response: any = []) => {
      if (response.doctors.success == 1) {
       this.toastr.success(response.doctors.message+'!');
       this.getdrProfile();
       this.cancelEdit();
      } else {
        this.toastr.error(response.doctors.message+'!');
      }
    });
}

cancelEdit(){
  this.drPofileFormShow = false;
}

uploadCoverImage(event: any){
  const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
  this.uploadCoverImageFile = file;
  reader.addEventListener('load', (e: any) => {
   this.uploadCoverDrImage = e.target.result;
    //this.uploadCoverDrImage = new ImageSnippet(e.target.result, file);
  });

  reader.readAsDataURL(file);
  this.uploadCoverImagee();
 }

uploadCoverImagee(){
  this.appService
  .postwithoutPortImgFile(AppConfig.settings.EndPoints.uploadImage, this.uploadCoverImageFile, this.token)
  .subscribe((event: HttpEvent<any>) => { 
    switch (event.type) {
      case HttpEventType.Response:
        this.sendCoverImgFile = event.body.image[0].filename;
      }
})
}

fileChangeEvent(event: any): void {
  this.imageChangedEvent = event;
  
  const dialogRef = this.dialog.open(Dialog_CroppedImage, {
  width: '800px',
  data: { selectedimg: this.imageChangedEvent }
});

    dialogRef.afterClosed().subscribe(result => {
        this.croppedImage = result.croppedimage;
        this.sendAvaterFile = result.fileName;
      })
    
}

logout(){
  this.router.navigate(['']);
  sessionStorage.clear();
  //localStorage.clear();
  window.onpopstate = function (e) { window.history.forward(); }
}

omit_special_char(event) {
  var k;
  k = event.charCode;  //         k = event.keyCode;  (Both can be used)
  if (event.key === '/' || event.key === '@'|| event.key === ',') {
    return;
}else if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)){
  return;
}
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
}

}


@Component({
  selector: 'croppedimage',
  templateUrl: 'croppedimage.html',
  styleUrls: ['croppedimage.scss']
})

export class Dialog_CroppedImage {

  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  croppedImage;
  imageChangedEvent;
  getAvaterFile;
  token

  @Output() receivecroppedimg = new EventEmitter<any>();
  
  constructor(
    public dialogRef: MatDialogRef<Dialog_CroppedImage>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_croppedimage,private appService: AppService) {
      
     
  }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.selectedimg;
    
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.token = getauthdetails.token;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.receivecroppedimg = this.croppedImage;
  }
  
  imageLoaded() {
    this.showCropper = true;
  }
  
  cropperReady(sourceImageDimensions: Dimensions) {
  }
  
  loadImageFailed() {
  }

  saveDrProfile() {
    this.receivecroppedimg = this.croppedImage;
     this.appService
      .postwithoutPortImgbase64(AppConfig.settings.EndPoints.uploadImagebase64, this.croppedImage, this.token)
      .subscribe((event: HttpEvent<any>) => { 
        switch (event.type) {
          case HttpEventType.Response:
            
            this.getAvaterFile = event.body.image[0].filename;
            

            var send ={
            croppedimage: this.receivecroppedimg,
            fileName: this.getAvaterFile
          }
            this.dialogRef.close(send);
          }
          
      })
      
  }

  onCloseDrprof() {
    this.dialogRef.close();
  }

  
}
