<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true"
                        style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Vital Threshold</p>
        </div>
    </div>

    <div class="row" style="margin: 0;margin-top: 50px;">
        <table class="table">
            <thead>
              <tr>
                <th>Units</th>
                <th>Blood Pressure (Systolic) </th>
                <th>Blood Pressure (Diastolic) </th>
                <th>Temperature ( °C )</th>

                <th>Heart/Pulse rate ( PPM )</th>
                <th>Blood Glucose</th>
                <!-- <th>SPO2 ( % )</th>
                <th>Respiratory Rate ( RPM )</th>
                <th>ROX ( % )</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td>Threshold High</td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" /></td>
                  <td><input type="text" height="60" width="15" /></td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" ></td>
              </tr>
              <tr>
                  <td>Threshold Low</td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" ></td>
                  <td><input type="text" height="60" width="15" ></td>
                 
              </tr>
              
            </tbody>
          </table>
    </div>
</div>