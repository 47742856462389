<!-- <div class="container">
    <i class="fa fa-times" aria-hidden="true" (click)="close()"></i>
    <h1>From care net...</h1>
</div> -->
<div style="padding:1rem">
    <div style="display: flex;justify-content: space-between;font-size: 1rem;margin-bottom: 1rem;margin-top: 0.5rem;">
        <p style=color:#000000;font-size:18px;font-weight:600>CARE NET</p>
         <div style="display: flex;margin-right:1rem">
            <!-- <img class="icon" src="assets/keyboard.svg">
            <img class="icon" style="margin-left: 00.5rem;" src="assets/mute.svg"> -->
            <div style="border: 2px solid #7D7D7D;height: 1.6rem;margin-left: 00.5rem;border-radius:5px">
                <input style="border: none;outline: none;width: 10rem;" [(ngModel)]="carenetsearch" (keyup)="search1($event)"
                    placeholder="Search"> <i style="margin-right: 0.5rem;cursor:pointer" class="fa fa-search" aria-hidden="true"></i>
    
            </div>
            <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
                (click)="saveOK()">Save</button>

            <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;cursor: pointer;" class="fa fa-close" (click)="close()"></i>

         </div>
    </div>
    
    <div style="margin-top:15px">
        <div style="color:#415577;font-weight: 600;">Selected</div>
        <br>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 5px;">
            <p *ngFor="let selectedarr of selectedcarenetArr;let i=index" style="cursor: pointer;border: 2px solid #D9D9D9;padding: 0.5rem;margin-bottom: 0.5rem;
            width: fit-content;margin-right: 0.5rem;color:#474747">
                <span style="font-weight: 600;">&#35;{{selectedarr.tag}}</span>
                <span style="margin-left: 10px;"> <i class="fa fa-close"
                        style="color:#FF2424;margin-left: 0.5rem;font-size: 1rem;"  (click)="deletetag(selectedarr.tag_id,i)"></i>
                </span>
            </p>
        </div>
        <hr style="margin-top: 12px;">
        <div style="margin-top: 20px;" *ngIf="(doc_id!=4&&dmail=='rx4@rxcarenet.org')&&(dmail!='rx389462@rxcarenet.org')">
            <div style="color:#415577;font-weight: 600;">Frequently Used</div><br>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 5px;">
                <p *ngFor="let getfreq of getfreqData;let i=index" style="background-color:#F2F7FF;cursor: pointer;border: 2px solid #D9D9D9;padding: 0.4rem 0.8rem;;margin-bottom: 0.5rem;
                width: fit-content;margin-right: 0.5rem;color:#474747">
                   <span (click)="tagselected(getfreq.tag,getfreq.id)">&#35;{{getfreq.tag}}</span>
                   <!-- &nbsp;&nbsp;&nbsp;<img (click)="openaddnew(getfreq,'update')" style="width:1rem;margin-top: -3px;" src="assets/noun-edit.svg"> &nbsp;&nbsp;<img (click)="openaddnew(getfreq,'delete')" style="width:1rem;margin-top: -3px;" src="assets/noun-delete.svg"> -->
                </p>
            </div>
        </div>
        <hr style="margin-top: 12px;">
        <div style="margin-top: 20px;">
            <div style="color:#415577;font-weight: 600;">All</div> <div (click)="openaddnew('','add')" style="margin-top:-20px;float: right;cursor:pointer;"><i style="color:#0063FF;" class="fa fa-plus-circle" aria-hidden="true"></i></div><br>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 5px;">
                <p *ngFor="let gettag of gettagData;let i=index" style="cursor: pointer;border: 2px solid #D9D9D9;padding: 0.4rem 0.8rem;;margin-bottom: 0.5rem;
                width: fit-content;margin-right: 0.5rem;color:#474747">
                     <span (click)="tagselected(gettag.tag,gettag.id)">&#35;{{gettag.tag}}</span> &nbsp;&nbsp;&nbsp;<img (click)="openaddnew(gettag,'update')" style="width:1rem;margin-top: -3px;" src="assets/noun-edit.svg"> &nbsp;&nbsp;<img (click)="openaddnew(gettag,'delete')" style="width:1rem;margin-top: -3px;" src="assets/noun-delete.svg">
                </p>
            </div>
            <div *ngIf="gettagData==''" style="text-align:center">No Data Found</div>
        </div>
    </div>

    <div style="float: right;margin-top: 25px;margin-right: 20px;">
        <app-paginate style="margin-top: 1rem;" [items]="pagelist" [pages]="count" (changePage)="onChangePage($event)"></app-paginate>
      </div>
</div>
