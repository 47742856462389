<div class="row">
    <div class="col-lg-8 col-md-8">
        <p class="header">Lab Reports Attachment</p>
    </div>

    <div class="col-lg-2 col-md-2">
        <p class="header" (click)="cancel() " style="cursor: pointer;">Cancel</p>

    </div>

    <div class="col-lg-2 col-md-2">
        <p class="header" (click)="save() " style="cursor: pointer;">Save</p>

    </div>
</div>



<div>
    <p style="font-weight:600">Date</p>
    <p>{{date}}</p>
</div>

<div>
    <p style="font-weight:600">Title</p>
    <p>{{data.title}}</p>
</div>

<div  style="margin-top: 0.5rem;margin-bottom: 0.5rem;">
    <input style="cursor:pointer" type="checkbox" id="abnormal" name="abnormal" [(ngModel)]="abnormal">
    <label for="abnormal">Abnormal</label>
</div>

<div style="display: flex;margin-top: 0.5rem;margin-bottom: 0.5rem;">
    <p>Test taken date:</p>
    <input type="date" style="margin-left: 0.5rem;" [(ngModel)]="test_taken_on" value="date" max="{{dateMax}}">
</div>

<div>
    <p style="font-weight: 600;">File Name</p>

    <textarea maxlength="200" name="fileName" [(ngModel)]="fileName"></textarea>
</div>

<div>
    <p style="font-weight: 600;">Impression</p>

    <textarea maxlength="200" name="description" [(ngModel)]="description"></textarea>
</div>

<div>
    <p style="font-weight: 600;">Comments</p>
    <textarea [(ngModel)]="comments"></textarea>
</div>

<!-- <div>
    <p style="font-weight:600">Attachment</p>
</div>
<div style="display: flex">

    <div class="bg">

        <label style="width: 100%;">
            <div style="display: flex;justify-content: center;">
                <img src="assets/cloud-computing.svg">

            </div>
            <p class="text-center">Upload Image</p>
            <input type="file" style="display: none;" id="files" multiple accept="image/*" style="display: none;"
                (change)="GetFile($event)">
        </label>


    </div>



    <div class="bg" style="margin-left: 10%;">

        <label style="width: 100%;">
            <div style="display: flex;justify-content: center;">
                <img src="assets/surface1.svg">
            </div>
            <p class="text-center">Upload Document</p>
            <input type="file" id="files" multiple accept="application/pdf,application/vnd.ms-excel"
                style="display: none;" (change)="GetFile($event)">
        </label>



    </div>
</div>

<div style="margin-top: 1rem;margin-bottom: 1rem;">
    <div class="row image_size" *ngFor="let item of imgURL; let i= index;" style="margin-bottom:0.5rem">
        <div style="width: 30%;">
            <div class="text-center" style="position: relative;">
                <img *ngIf="item?.type != 'pdf'" src="" imgPreview [src]="item.url" class="image">
                <img *ngIf="item?.type === 'pdf'" imgPreview src="assets/pdf.png" class="image">
            </div>
        </div>
        <div style="width: 50%;">
            {{ item?.name }}
        </div>
        <div style="width: 20%;">
            <i class="fa fa-times-circle" (click)="removePicture(item, i);"></i>
        </div>
    </div>

</div> -->