<div class="row">
    <div class="col-lg-8 col-md-8">
        <p class="save">Add new drug</p>
    </div>

    <div class="col-lg-2 col-md-2">
        <p class="cancel" (click)="onNoClick()">Cancel</p>
    </div>

    <div class="col-lg-2 col-md-2">
        <p class="save" *ngIf="!status" (click)="save()" [class.disabled]="(!drugname) || (!compname) || (!formulation) || (!strength) || (!duration)">Save</p>
        <p class="save" *ngIf="status" (click)="save()" >Save</p>
    </div>
</div>

<div style="display: flex;">
    <div style="width: 22rem;">
        <!-- only for srinivas doc start -->
        <div *ngIf="status">
        
        <div>
            <p class="heading">Drug Name </p>
            <input class="input" [(ngModel)]="drugname">
        </div>

        <div>
            <p class="heading">Composition/Generic Name </p>
            <input class="input" [(ngModel)]="compname">
        </div>

        <div>
            <p class="heading">Manufacturer Name</p>
            <input class="input" [(ngModel)]="generic">
        </div>
    </div>
        <!-- end -->
        <div *ngIf="!status">
        <div>
            <p class="heading">Drug Name *</p>
            <input class="input" [(ngModel)]="drugname">
        </div>

        <div>
            <p class="heading">Composition/Generic Name *</p>
            <input class="input" [(ngModel)]="compname">
        </div>

        <div>
            <p class="heading">Manufacturer Name</p>
            <input class="input" [(ngModel)]="generic">
        </div>
    </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <p class="heading">Strength</p>
                <div class="divborder">
                    <span class="plusminus" (click)="strengthMinus()">-</span>
                    <input class="input1" type="number" [(ngModel)]="strength">
                    <span class="plusminus" (click)="strengthPlus()">+</span>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-6">
                <p class="heading">Unit</p>
                <input class="input" [(ngModel)]="unit">
            </div> -->
            <div class="col-lg-6 col-md-6">
                <p class="heading">Unit</p>
                <p class="value" (click)="openSlider2()">{{dose_option}}</p>
            </div>

        </div>

        <div class="row">
            <!-- <div class="col-lg-6 col-md-6">
                <p class="heading">Dose</p>
                <div class="divborder">
                    <span class="plusminus" (click)="doseMinus()">-</span>
                    <input class="input1" type="number" [(ngModel)]="dose">
                    <span class="plusminus" (click)="dosePlus()">+</span>
                </div>
            </div> -->

            
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <p class="heading">Route</p>
                <p class="value" (click)="openDialog()">{{route}}</p>
            </div>

            <div class="col-lg-6 col-md-6">
                <p class="heading">When</p>
                <p class="value" (click)="openSlider3()">{{when}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">

                <p class="heading">Frequency</p>
                <p class="value" (click)="openFrequency()">{{frequency}}</p>
            </div>

        </div>
    </div>

    <div style="margin-left: 1rem;">
        <p class="heading">Formulation *</p>
        <table>
            <tr>

                <td [class.highlight]="formulation=='Tablet'">
                    <div class="box" (click)="setFormulation('Tablet')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/tablet.svg">
                        </div>
        
                        <p class="text-center icontext">Tablet</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Capsules'">
                    <div class="box" (click)="setFormulation('Capsules')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/capsules.svg">
                        </div>
        
                        <p class="text-center icontext">Capsules</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Syrup'">
                    <div class="box" (click)="setFormulation('Syrup')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/syrup.svg">
                        </div>
        
                        <p class="text-center icontext">Syrup</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Drops'">
                    <div class="box" (click)="setFormulation('Drops')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/eye-dropper.svg">
                        </div>
        
                        <p class="text-center icontext">Drops</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Suspension'">
                    <div class="box" (click)="setFormulation('Suspension')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/Suspension.svg">
                        </div>
        
                        <p class="text-center icontext">Suspension</p>
        
                    </div>  
                </td>

                <td [class.highlight]="formulation=='Sachet'">
                    <div class="box" (click)="setFormulation('Sachet')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/sachet.svg">
                        </div>
        
                        <p class="text-center icontext">Sachet</p>
        
                    </div>
                </td>

                

                

                

              

                

                
            </tr>

            <tr>

                <td [class.highlight]="formulation=='Injection'">
                    <div class="box" (click)="setFormulation('Injection')" >
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/syringe.svg">
                        </div>
        
                        <p class="text-center icontext">Injection</p>
        
                    </div>
                </td>

                

                <td [class.highlight]="formulation=='Vaccine'">
                    <div class="box" (click)="setFormulation('Vaccine')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/vaccine.svg">
                        </div>
        
                        <p class="text-center icontext">Vaccine</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Inhaler'">
                    <div class="box" (click)="setFormulation('Inhaler')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/inhaler.svg">
                        </div>
        
                        <p class="text-center icontext">Inhaler</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Ointment'">
                    <div class="box" (click)="setFormulation('Ointment')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/cosmetic.svg">
                        </div>
        
                        <p class="text-center icontext">Ointment</p>
        
                    </div>
                </td>
                

                <td [class.highlight]="formulation=='Cream'">
                    <div class="box"  (click)="setFormulation('Cream')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/cream.svg">
                        </div>
        
                        <p class="text-center icontext">Cream</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Gel'">
                    <div class="box" (click)="setFormulation('Gel')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/alcohol-gel.svg">
                        </div>
        
                        <p class="text-center icontext">Gel</p>
        
                    </div>
                </td>

               

               


                

                

                
            </tr>

            <tr>
                

                <td [class.highlight]="formulation=='Powder'">
                    <div class="box" (click)="setFormulation('Powder')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/baby-powder.svg">
                        </div>
        
                        <p class="text-center icontext">Powder</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Soap'">
                    <div class="box" (click)="setFormulation('Soap')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/soap.svg">
                        </div>
        
                        <p class="text-center icontext">Soap</p>
        
                    </div>
                </td>

                <td [class.highlight]="formulation=='Oil'">
                    <div class="box" (click)="setFormulation('Oil')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/massage-oil.svg">
                        </div>
        
                        <p class="text-center icontext">Oil</p>
        
                    </div>
                </td>

                <td  [class.highlight]="formulation=='Lotion'">
                    <div class="box" (click)="setFormulation('Lotion')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/lotion.svg">
                        </div>
        
                        <p class="text-center icontext">Lotion</p>
        
                    </div>
                </td>
                

                <td [class.highlight]="formulation=='Others'">
                    <div class="box" (click)="setFormulation('Others')">
                        <div style="display: flex;justify-content: center;">
                            <img class="icon" src="assets/medicine.svg">
                        </div>
        
                        <p class="text-center icontext">Others</p>
                    </div>
                </td>


               
            </tr>
        </table>

        <div class="row">
            <div class="col-lg-3 col-md-3">
                <p class="heading">Duration</p>
                <!-- <p class="value">{{duration}}</p> -->
                <div class="divborder" >
                    <span class="plusminus" (click)="durationMinus()">-</span>
                    <input class="input1" type="number" [(ngModel)]="duration">
                    <span class="plusminus" (click)="durationPlus()">+</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <p class="heading">Duration Type</p>
                <!-- <p class="value">{{duration_type}}</p> -->
                <div style="display: flex;">
                    <span [class.value]="duration_type=='Days'" (click)="setDurationType('Days')" style="padding: 0.25rem;cursor: pointer;">Days</span>

                    <span [class.value]="duration_type=='Months'" (click)="setDurationType('Months')" style="margin-left: 0.5rem;padding: 0.25rem;cursor: pointer;">Months</span>

                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-3">
                <p class="heading">Instructions</p>
                <input class="input" [(ngModel)]="instructions">

            </div> -->
        </div>

        <div class="row"  *ngIf="!status">
            <div class="col-lg-6 col-md-6">
                <p class="heading">Minimum Buyable Quantity</p>
                <div class="divborder"  style="width: calc(50% - 15px);">
                    <span class="plusminus" (click)="minMinus()">-</span>
                    <input class="input1" type="number" [(ngModel)]="min_qty">
                    <span class="plusminus" (click)="minPlus()">+</span>
                </div>
            </div>

           
        </div>
        
    </div>
</div>

<!-- <p style="font-size: 1rem;margin-top: 0.5rem;text-align: center;">Note : All the fields are mandatory</p> -->