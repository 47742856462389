<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Milestone</p>
        </div>
    </div>

    <div style="margin-top: 60px;">
      <div class="row">
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:#03C653"></i> Yes
        </span>
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:#FF5733"></i> Not Yet
        </span>
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:#FEC617"></i> Sometimes
        </span>
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:#DB7093"></i> Future
        </span>
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:#B2B2B2"></i> To be answered
        </span>
        <span class="col-sm-2" style="padding-left: 25px;">
            <i class="fa fa-square" aria-hidden="true" style="color:black"></i> Rejected
        </span>
    </div>

    <div class="row" style="margin-top: 20px;">
        <div class="col-lg-12" style="overflow-x: scroll;">
            <div>
              <figure class="highcharts-figure">
                    <div id="milestones" style="height: 800px !important"></div>
                </figure>
            </div>
        </div>
      </div>

      <div class="row" style="padding-top:30px ;">
        <div class="col-lg-12"
            style="margin-bottom: auto;color: black;font-size: medium;text-align: left;">
            <span
                style="font-weight: bolder;font-family: Poppins;color: #1F76FF;">{{data.patientDetails.demographics.name_given}}'s
                Timeline</span>
        </div>
    </div>

    <div class="row" style="padding:10px 35px 35px 17px" *ngIf="!hidetimelinedata">
      <div class="col-lg-12"
          style="border: 1px solid #bac8db;height: auto;border-radius: 6px;overflow-y: scroll;">

          <link href='//fonts.googleapis.com/css?family=Roboto:200,400,600' rel='stylesheet'
              type='text/css'>
          <div class="history-tl-container">
              <ul class="tl" style="padding-top: 120px;width: 100%;">
                  <li class="tl-item"
                      *ngFor="let item of viewmilestonepatienthistory; index as i">
                      <div class="row onhoverhighlightbaby"
                          style="margin-top: -50px;width: 97%;">
                          <div class="col-lg-12"
                              style="border: 0px solid #bac8db;border-radius: 1px;padding: 25px;box-shadow: 0px 0px 9px 5px;color: #ededed">
                              <div class="row" data-toggle="modal"
                                  data-target="#exampleModalCenter3a" data-backdrop="false">
                                  <div class="col-lg-1 col-sm-1 col-md-1 col-lg-1"
                                      style="text-align: center;">
                                      <img src="assets/images/challenge-target.svg"
                                          height="50px" width="50px">
                                  </div>
                                  <div class="col-4 col-sm-4 col-md-4 col-lg-5"
                                      style="margin-bottom: auto;color: black;font-size: medium;">
                                      <span class="item-title"
                                          style="color: #347F9E;font-weight: 600;">{{item.item}}</span><br>
                                      <div
                                          style="margin-top: 12px;font-size: 12px;font-weight: 600;color: grey;">
                                          <span>Status&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; 
                                            <span
                                            [ngClass]="{'timelinestatusyes': item.status == 'Yes', 'timelinestatusno' : item.status == 'Not Yet','timelinestatussometimes' : item.status == 'Sometimes' }">
                                            {{item.status}} &nbsp; &nbsp; <a *ngIf="item.doctor_approval_status == null"  style="color: #007bff;" (click)="openstatusedit(i)"><u style="color: unset;">Edit</u></a>
                                        </span>
                                                </span>
                                      </div>
                                      <div
                                          style="margin-top: 10px;font-size: 12px;font-weight: 600;color: grey;word-break: break-word;">
                                          <span>Comments&nbsp;&nbsp;:&nbsp;&nbsp;<span
                                                  style="color: black;">{{item.comments}}</span></span>
                                      </div>
                                  </div>
                                  <div class="col-3 col-sm-3 col-md-3 col-lg-2"
                                      style="padding: 0">
                                      <div class="row" *ngIf="item.doctor_approval_status == null">
                                          <div class="col-lg-12 drstatusalign">
                                              <div class="row" style="padding: 9px 0 0 0">
                                                  <div class="col-lg-12"
                                                      style="text-align: center;color: black;font-size: 13px;padding: 0;font-weight: bolder;">
                                                      <span>DOCTOR APPROVAL</span>
                                                  </div>
                                              </div>
                                              <div class="row"
                                                  style="padding-top: 14px;padding-left: 10px;padding-right: 18px;">
                                                  <div class="col-lg-6"
                                                      style="text-align: right;">
                                                      <span
                                                          (click)="milestonechangedrstatus(item.tracker_id,item.id,'yes')"
                                                          style="padding: 4px 6px 4px 6px;background-color: #e5f6eb;font-size: 13px;color: #00A73D;font-weight: bold;">Yes</span>
                                                  </div>
                                                  <div class="col-lg-6"
                                                      style="text-align: left;">
                                                      <span
                                                          (click)="milestonechangedrstatus(item.tracker_id,item.id,'No')"
                                                          style="padding: 4px 8px 4px 8px;background-color: #ffdfdb;color: #FF4934;font-size: 13px;font-weight: bold;">No</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-2 col-sm-2 col-md-2 col-lg-2"
                                      (click)="openviewmilestonepicture(item.sno)"
                                      style="text-align: center;z-index: 1;padding: 0;"><br>
                                      <div class="row">
                                          <div class="col-lg-12"
                                              style="font-size: 12px;font-weight: 600;color: grey;padding: 0;">
                                              At {{item.age.years}} year, {{item.age.months}}
                                              month
                                          </div>
                                      </div><br>
                                      <div class="row">
                                          <div class="col-lg-12">
                                              <div
                                                  style="font-size: 12px;font-weight: 600;color: grey;">
                                                  <span>{{item.date}}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-2 col-sm-2 col-md-2 col-lg-2"
                                      *ngIf="item.latest_image == null"
                                      (click)="openviewmilestonepicture(item.sno)">
                                      <img src="assets/images/nomilestonethumb.svg"
                                          height="72px" width="125px"
                                          style="border-radius: 7.5px;">
                                  </div>
                                  <div class="col-lg-2" *ngIf="item.latest_image !== null"
                                      (click)="openviewmilestonepicture(item.sno)">
                                      <img [src]="'https://api.epicare.net/helyxonapi/cloud/image/upload/'+item.latest_image"
                                          height="72px" width="125px"
                                          style="border-radius: 7.5px;">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row" *ngIf="hidetimelinedata" style="text-align: center;
  color: red;
  padding: 20px 20px 50px 20px;
  font-size: 16px;font-weight: 500;">
      <div class="col-lg-12"
          style="border: 1px solid #bac8db;height: auto;min-height:100px;border-radius: 6px;padding: 50px;">
          <span style="color:red">Milestone Questions not answered</span>
      </div>
  </div>
 
</div>
   
</div>


