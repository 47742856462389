import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-wlhc-female',
  templateUrl: './wlhc-female.component.html',
  styleUrls: ['./wlhc-female.component.scss']
})
export class WlhcFemaleComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }
  @Input() chartData;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.drawChart(this.chartData)
  }

  drawChart(data) {
    var vitalData: any = data

    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 440 - margin.top - margin.bottom;
    var width = 960 - margin.left - margin.right;

    var heightData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.height.length; i++) {
        if (vitalData.height[i].x < 61) {
          heightData.push(vitalData.height[i]);
        }
      }
    }

    var weightData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.weight.length; i++) {
        if (vitalData.weight[i].x < 61) {
          weightData.push(vitalData.weight[i]);
        }
      }
    }

    var headData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.hc.length; i++) {
        if (vitalData.hc[i].x < 61) {
          headData.push(vitalData.hc[i]);
        }
      }
    }

    var set1 = [{ x: 0, y: 2.3 }, { x: 1, y: 3 }, { x: 2, y: 3.8 }, { x: 3, y: 4.4 }, { x: 4, y: 4.8 }, { x: 5, y: 5.2 }, { x: 6, y: 5.5 }, { x: 7, y: 5.8 },
    { x: 8, y: 6 }, { x: 9, y: 6.2 }, { x: 10, y: 6.4 }, { x: 11, y: 6.6 }, { x: 12, y: 6.8 }, { x: 13, y: 6.9 }, { x: 14, y: 7.1 }, { x: 15, y: 7.3 },
    { x: 16, y: 7.4 }, { x: 17, y: 7.6 }, { x: 18, y: 7.8 }, { x: 19, y: 7.9 }, { x: 20, y: 8.1 }, { x: 21, y: 8.2 }, { x: 22, y: 8.4 },
    { x: 23, y: 8.5 }, { x: 24, y: 8.7 }, { x: 25, y: 8.9 }, { x: 26, y: 9 }, { x: 27, y: 9.2 }, { x: 28, y: 9.3 }, { x: 29, y: 9.5 }, { x: 30, y: 9.6 },
    { x: 31, y: 9.7 }, { x: 32, y: 9.9 }, { x: 33, y: 10 }, { x: 34, y: 10.1 }, { x: 35, y: 10.3 }, { x: 36, y: 10.4 }, { x: 37, y: 10.5 },
    { x: 38, y: 10.6 }, { x: 39, y: 10.8 }, { x: 40, y: 10.9 }, { x: 41, y: 11 }, { x: 42, y: 11.1 }, { x: 43, y: 11.3 }, { x: 44, y: 11.4 },
    { x: 45, y: 11.5 }, { x: 46, y: 11.6 }, { x: 47, y: 11.7 }, { x: 48, y: 11.8 }, { x: 49, y: 11.9 }, { x: 50, y: 12.1 }, { x: 51, y: 12.2 },
    { x: 52, y: 12.3 }, { x: 53, y: 12.4 }, { x: 54, y: 12.5 }, { x: 55, y: 12.6 }, { x: 56, y: 12.7 }, { x: 57, y: 12.8 }, { x: 58, y: 12.9 },
    { x: 59, y: 13.1 }, { x: 60, y: 13.2 }];

    var set2 = [{ x: 0, y: 2.5 }, { x: 1, y: 3.3 }, { x: 2, y: 4.1 }, { x: 3, y: 4.7 }, { x: 4, y: 5.2 }, { x: 5, y: 5.6 }, { x: 6, y: 6 }, { x: 7, y: 6.3 }, { x: 8, y: 6.5 },
    { x: 9, y: 6.8 }, { x: 10, y: 7 }, { x: 11, y: 7.2 }, { x: 12, y: 7.3 }, { x: 13, y: 7.5 }, { x: 14, y: 7.7 }, { x: 15, y: 7.9 }, { x: 16, y: 8.1 },
    { x: 17, y: 8.2 }, { x: 18, y: 8.4 }, { x: 19, y: 8.6 }, { x: 20, y: 8.7 }, { x: 21, y: 8.9 }, { x: 22, y: 9.1 }, { x: 23, y: 9.2 }, { x: 24, y: 9.4 },
    { x: 25, y: 9.6 }, { x: 26, y: 9.8 }, { x: 27, y: 9.9 }, { x: 28, y: 10.1 }, { x: 29, y: 10.2 }, { x: 30, y: 10.4 }, { x: 31, y: 10.5 }, { x: 32, y: 10.7 },
    { x: 33, y: 10.8 }, { x: 34, y: 11 }, { x: 35, y: 11.1 }, { x: 36, y: 11.3 }, { x: 37, y: 11.4 }, { x: 38, y: 11.6 }, { x: 39, y: 11.7 }, { x: 40, y: 11.8 },
    { x: 41, y: 12 }, { x: 42, y: 12.1 }, { x: 43, y: 12.2 }, { x: 44, y: 12.4 }, { x: 45, y: 12.5 }, { x: 46, y: 12.6 }, { x: 47, y: 12.8 }, { x: 48, y: 12.9 },
    { x: 49, y: 13 }, { x: 50, y: 13.2 }, { x: 51, y: 13.3 }, { x: 52, y: 13.4 }, { x: 53, y: 13.5 }, { x: 54, y: 13.7 }, { x: 55, y: 13.8 }, { x: 56, y: 13.9 },
    { x: 57, y: 14 }, { x: 58, y: 14.2 }, { x: 59, y: 14.3 }, { x: 60, y: 14.4 }];

    var set3 = [{ x: 0, y: 3.2 }, { x: 1, y: 4.2 }, { x: 2, y: 5.1 }, { x: 3, y: 5.8 }, { x: 4, y: 6.4 }, { x: 5, y: 6.9 }, { x: 6, y: 7.3 }, { x: 7, y: 7.6 }, { x: 8, y: 7.9 },
    { x: 9, y: 8.2 }, { x: 10, y: 8.5 }, { x: 11, y: 8.7 }, { x: 12, y: 8.9 }, { x: 13, y: 9.2 }, { x: 14, y: 9.4 }, { x: 15, y: 9.6 }, { x: 16, y: 9.8 },
    { x: 17, y: 10 }, { x: 18, y: 10.2 }, { x: 19, y: 10.4 }, { x: 20, y: 10.6 }, { x: 21, y: 10.9 }, { x: 22, y: 11.1 }, { x: 23, y: 11.3 }, { x: 24, y: 11.5 },
    { x: 25, y: 11.7 }, { x: 26, y: 11.9 }, { x: 27, y: 12.1 }, { x: 28, y: 12.3 }, { x: 29, y: 12.5 }, { x: 30, y: 12.7 }, { x: 31, y: 12.9 }, { x: 32, y: 13.1 },
    { x: 33, y: 13.3 }, { x: 34, y: 13.5 }, { x: 35, y: 13.7 }, { x: 36, y: 13.9 }, { x: 37, y: 14 }, { x: 38, y: 14.2 }, { x: 39, y: 14.4 }, { x: 40, y: 14.6 },
    { x: 41, y: 14.8 }, { x: 42, y: 15 }, { x: 43, y: 15.2 }, { x: 44, y: 15.3 }, { x: 45, y: 15.5 }, { x: 46, y: 15.7 }, { x: 47, y: 15.9 }, { x: 48, y: 16.1 },
    { x: 49, y: 16.3 }, { x: 50, y: 16.4 }, { x: 51, y: 16.6 }, { x: 52, y: 16.8 }, { x: 53, y: 17 }, { x: 54, y: 17.2 }, { x: 55, y: 17.3 }, { x: 56, y: 17.5 },
    { x: 57, y: 17.7 }, { x: 58, y: 17.9 }, { x: 59, y: 18 }, { x: 60, y: 18.2 }];

    var set4 = [{ x: 0, y: 4.2 }, { x: 1, y: 5.4 }, { x: 2, y: 6.5 }, { x: 3, y: 7.4 }, { x: 4, y: 8.1 }, { x: 5, y: 8.7 }, { x: 6, y: 9.2 }, { x: 7, y: 9.6 }, { x: 8, y: 10 },
    { x: 9, y: 10.4 }, { x: 10, y: 10.7 }, { x: 11, y: 11 }, { x: 12, y: 11.3 }, { x: 13, y: 11.6 }, { x: 14, y: 11.9 }, { x: 15, y: 12.2 }, { x: 16, y: 12.5 },
    { x: 17, y: 12.7 }, { x: 18, y: 13 }, { x: 19, y: 13.3 }, { x: 20, y: 13.5 }, { x: 21, y: 13.8 }, { x: 22, y: 14.1 }, { x: 23, y: 14.3 }, { x: 24, y: 14.6 },
    { x: 25, y: 14.9 }, { x: 26, y: 15.2 }, { x: 27, y: 15.4 }, { x: 28, y: 15.7 }, { x: 29, y: 16 }, { x: 30, y: 16.2 }, { x: 31, y: 16.5 }, { x: 32, y: 16.8 },
    { x: 33, y: 17 }, { x: 34, y: 17.3 }, { x: 35, y: 17.6 }, { x: 36, y: 17.8 }, { x: 37, y: 18.1 }, { x: 38, y: 18.4 }, { x: 39, y: 18.6 }, { x: 40, y: 18.9 },
    { x: 41, y: 19.2 }, { x: 42, y: 19.5 }, { x: 43, y: 19.7 }, { x: 44, y: 20 }, { x: 45, y: 20.3 }, { x: 46, y: 20.6 }, { x: 47, y: 20.8 }, { x: 48, y: 21.1 },
    { x: 49, y: 21.4 }, { x: 50, y: 21.7 }, { x: 51, y: 22 }, { x: 52, y: 22.2 }, { x: 53, y: 22.5 }, { x: 54, y: 22.8 }, { x: 55, y: 23.1 }, { x: 56, y: 23.3 },
    { x: 57, y: 23.6 }, { x: 58, y: 23.9 }, { x: 59, y: 24.2 }, { x: 60, y: 24.4 }];
    // End of Weight data

    // Start of Head Circumference data
    var set5 = [{ x: 0, y: 31.7 }, { x: 1, y: 34.3 }, { x: 2, y: 36 }, { x: 3, y: 37.2 }, { x: 4, y: 38.2 }, { x: 5, y: 39 }, { x: 6, y: 39.7 }, { x: 7, y: 40.4 },
    { x: 8, y: 40.9 }, { x: 9, y: 41.3 }, { x: 10, y: 41.7 }, { x: 11, y: 42 }, { x: 12, y: 42.3 }, { x: 13, y: 42.6 }, { x: 14, y: 42.9 },
    { x: 15, y: 43.1 }, { x: 16, y: 43.3 }, { x: 17, y: 43.5 }, { x: 18, y: 43.6 }, { x: 19, y: 43.8 }, { x: 20, y: 44 }, { x: 21, y: 44.1 },
    { x: 22, y: 44.3 }, { x: 23, y: 44.4 }, { x: 24, y: 44.6 }, { x: 25, y: 44.7 }, { x: 26, y: 44.8 }, { x: 27, y: 44.9 }, { x: 28, y: 45.1 },
    { x: 29, y: 45.2 }, { x: 30, y: 45.3 }, { x: 31, y: 45.4 }, { x: 32, y: 45.5 }, { x: 33, y: 45.6 }, { x: 34, y: 45.7 }, { x: 35, y: 45.8 },
    { x: 36, y: 45.9 }, { x: 37, y: 45.9 }, { x: 38, y: 46 }, { x: 39, y: 46.1 }, { x: 40, y: 46.2 }, { x: 41, y: 46.2 }, { x: 42, y: 46.3 },
    { x: 43, y: 46.4 }, { x: 44, y: 46.4 }, { x: 45, y: 46.5 }, { x: 46, y: 46.5 }, { x: 47, y: 46.6 }, { x: 48, y: 46.7 }, { x: 49, y: 46.7 },
    { x: 50, y: 46.8 }, { x: 51, y: 46.8 }, { x: 52, y: 46.9 }, { x: 53, y: 46.9 }, { x: 54, y: 47 }, { x: 55, y: 47 }, { x: 56, y: 47.1 },
    { x: 57, y: 47.1 }, { x: 58, y: 47.2 }, { x: 59, y: 47.2 }, { x: 60, y: 47.2 }];

    var set6 = [{ x: 0, y: 33.9 }, { x: 1, y: 36.5 }, { x: 2, y: 38.3 }, { x: 3, y: 39.5 }, { x: 4, y: 40.6 }, { x: 5, y: 41.5 }, { x: 6, y: 42.2 }, { x: 7, y: 42.8 },
    { x: 8, y: 43.4 }, { x: 9, y: 43.8 }, { x: 10, y: 44.2 }, { x: 11, y: 44.6 }, { x: 12, y: 44.9 }, { x: 13, y: 45.2 }, { x: 14, y: 45.4 }, { x: 15, y: 45.7 },
    { x: 16, y: 45.9 }, { x: 17, y: 46.1 }, { x: 18, y: 46.2 }, { x: 19, y: 46.4 }, { x: 20, y: 46.6 }, { x: 21, y: 46.7 }, { x: 22, y: 46.9 }, { x: 23, y: 47 },
    { x: 24, y: 47.2 }, { x: 25, y: 47.3 }, { x: 26, y: 47.5 }, { x: 27, y: 47.6 }, { x: 28, y: 47.7 }, { x: 29, y: 47.8 }, { x: 30, y: 47.9 }, { x: 31, y: 48 },
    { x: 32, y: 48.1 }, { x: 33, y: 48.2 }, { x: 34, y: 48.3 }, { x: 35, y: 48.4 }, { x: 36, y: 48.5 }, { x: 37, y: 48.6 }, { x: 38, y: 48.7 }, { x: 39, y: 48.7 },
    { x: 40, y: 48.8 }, { x: 41, y: 48.9 }, { x: 42, y: 49 }, { x: 43, y: 49 }, { x: 44, y: 49.1 }, { x: 45, y: 49.2 }, { x: 46, y: 49.2 }, { x: 47, y: 49.3 },
    { x: 48, y: 49.3 }, { x: 49, y: 49.4 }, { x: 50, y: 49.4 }, { x: 51, y: 49.5 }, { x: 52, y: 49.5 }, { x: 53, y: 49.6 }, { x: 54, y: 49.6 }, { x: 55, y: 49.7 },
    { x: 56, y: 49.7 }, { x: 57, y: 49.8 }, { x: 58, y: 49.8 }, { x: 59, y: 49.9 }, { x: 60, y: 49.9 }];

    var set7 = [{ x: 0, y: 36.1 }, { x: 1, y: 38.8 }, { x: 2, y: 40.5 }, { x: 3, y: 41.9 }, { x: 4, y: 43 }, { x: 5, y: 43.9 }, { x: 6, y: 44.6 }, { x: 7, y: 45.3 },
    { x: 8, y: 45.9 }, { x: 9, y: 46.3 }, { x: 10, y: 46.8 }, { x: 11, y: 47.1 }, { x: 12, y: 47.5 }, { x: 13, y: 47.7 }, { x: 14, y: 48 }, { x: 15, y: 48.2 },
    { x: 16, y: 48.5 }, { x: 17, y: 48.7 }, { x: 18, y: 48.8 }, { x: 19, y: 49 }, { x: 20, y: 49.2 }, { x: 21, y: 49.4 }, { x: 22, y: 49.5 }, { x: 23, y: 49.7 },
    { x: 24, y: 49.8 }, { x: 25, y: 49.9 }, { x: 26, y: 50.1 }, { x: 27, y: 50.2 }, { x: 28, y: 50.3 }, { x: 29, y: 50.5 }, { x: 30, y: 50.6 }, { x: 31, y: 50.7 },
    { x: 32, y: 50.8 }, { x: 33, y: 50.9 }, { x: 34, y: 51 }, { x: 35, y: 51.1 }, { x: 36, y: 51.2 }, { x: 37, y: 51.3 }, { x: 38, y: 51.3 }, { x: 39, y: 51.4 },
    { x: 40, y: 51.5 }, { x: 41, y: 51.6 }, { x: 42, y: 51.6 }, { x: 43, y: 51.7 }, { x: 44, y: 51.8 }, { x: 45, y: 51.8 }, { x: 46, y: 51.9 }, { x: 47, y: 51.9 },
    { x: 48, y: 52 }, { x: 49, y: 52.1 }, { x: 50, y: 52.1 }, { x: 51, y: 52.2 }, { x: 52, y: 52.2 }, { x: 53, y: 52.3 }, { x: 54, y: 52.3 }, { x: 55, y: 52.4 },
    { x: 56, y: 52.4 }, { x: 57, y: 52.5 }, { x: 58, y: 52.5 }, { x: 59, y: 52.6 }, { x: 60, y: 52.6 }];
    // End of Head Circumference data

    // Start of Height/Length data
    var set8 = [{ x: 0, y: 44.8 }, { x: 1, y: 49.1 }, { x: 2, y: 52.3 }, { x: 3, y: 54.9 }, { x: 4, y: 57.1 }, { x: 5, y: 58.9 }, { x: 6, y: 60.5 }, { x: 7, y: 61.9 },
    { x: 8, y: 63.2 }, { x: 9, y: 64.5 }, { x: 10, y: 65.7 }, { x: 11, y: 66.9 }, { x: 12, y: 68 }, { x: 13, y: 69.1 }, { x: 14, y: 70.1 }, { x: 15, y: 71.1 },
    { x: 16, y: 72.1 }, { x: 17, y: 73 }, { x: 18, y: 74 }, { x: 19, y: 74.8 }, { x: 20, y: 75.7 }, { x: 21, y: 76.5 }, { x: 22, y: 77.3 }, { x: 23, y: 78.1 },
    { x: 24, y: 78.9 }, { x: 25, y: 79 }, { x: 26, y: 79.7 }, { x: 27, y: 80.4 }, { x: 28, y: 81.1 }, { x: 29, y: 81.8 }, { x: 30, y: 82.5 }, { x: 31, y: 83.1 },
    { x: 32, y: 83.8 }, { x: 33, y: 84.4 }, { x: 34, y: 85 }, { x: 35, y: 85.6 }, { x: 36, y: 86.2 }, { x: 37, y: 86.8 }, { x: 38, y: 87.4 }, { x: 39, y: 87.9 },
    { x: 40, y: 88.5 }, { x: 41, y: 89 }, { x: 42, y: 89.6 }, { x: 43, y: 90.1 }, { x: 44, y: 90.7 }, { x: 45, y: 91.2 }, { x: 46, y: 91.7 }, { x: 47, y: 92.2 },
    { x: 48, y: 92.7 }, { x: 49, y: 93.2 }, { x: 50, y: 93.7 }, { x: 51, y: 94.2 }, { x: 52, y: 94.7 }, { x: 53, y: 95.2 }, { x: 54, y: 95.6 }, { x: 55, y: 96.1 },
    { x: 56, y: 96.6 }, { x: 57, y: 97 }, { x: 58, y: 97.5 }, { x: 59, y: 97.9 }, { x: 60, y: 98.4 }];

    var set9 = [{ x: 0, y: 45.6 }, { x: 1, y: 50 }, { x: 2, y: 53.2 }, { x: 3, y: 55.8 }, { x: 4, y: 58 }, { x: 5, y: 59.9 }, { x: 6, y: 61.5 }, { x: 7, y: 62.9 }, { x: 8, y: 64.3 },
    { x: 9, y: 65.6 }, { x: 10, y: 66.8 }, { x: 11, y: 68 }, { x: 12, y: 69.2 }, { x: 13, y: 70.3 }, { x: 14, y: 71.3 }, { x: 15, y: 72.4 }, { x: 16, y: 73.3 },
    { x: 17, y: 74.3 }, { x: 18, y: 75.2 }, { x: 19, y: 76.2 }, { x: 20, y: 77 }, { x: 21, y: 77.9 }, { x: 22, y: 78.7 }, { x: 23, y: 79.6 }, { x: 24, y: 80.3 },
    { x: 25, y: 80.4 }, { x: 26, y: 81.2 }, { x: 27, y: 81.9 }, { x: 28, y: 82.6 }, { x: 29, y: 83.4 }, { x: 30, y: 84 }, { x: 31, y: 84.7 }, { x: 32, y: 85.4 },
    { x: 33, y: 86 }, { x: 34, y: 86.7 }, { x: 35, y: 87.3 }, { x: 36, y: 87.9 }, { x: 37, y: 88.5 }, { x: 38, y: 89.1 }, { x: 39, y: 89.7 }, { x: 40, y: 90.3 },
    { x: 41, y: 90.8 }, { x: 42, y: 91.4 }, { x: 43, y: 92 }, { x: 44, y: 92.5 }, { x: 45, y: 93 }, { x: 46, y: 93.6 }, { x: 47, y: 94.1 }, { x: 48, y: 94.6 },
    { x: 49, y: 95.1 }, { x: 50, y: 95.7 }, { x: 51, y: 96.2 }, { x: 52, y: 96.7 }, { x: 53, y: 97.2 }, { x: 54, y: 97.6 }, { x: 55, y: 98.1 }, { x: 56, y: 98.6 },
    { x: 57, y: 99.1 }, { x: 58, y: 99.6 }, { x: 59, y: 100 }, { x: 60, y: 100.5 }];

    var set10 = [{ x: 0, y: 49.1 }, { x: 1, y: 53.7 }, { x: 2, y: 57.1 }, { x: 3, y: 59.8 }, { x: 4, y: 62.1 }, { x: 5, y: 64 }, { x: 6, y: 65.7 }, { x: 7, y: 67.3 }, { x: 8, y: 68.7 },
    { x: 9, y: 70.1 }, { x: 10, y: 71.5 }, { x: 11, y: 72.8 }, { x: 12, y: 74 }, { x: 13, y: 75.2 }, { x: 14, y: 76.4 }, { x: 15, y: 77.5 }, { x: 16, y: 78.6 },
    { x: 17, y: 79.7 }, { x: 18, y: 80.7 }, { x: 19, y: 81.7 }, { x: 20, y: 82.7 }, { x: 21, y: 83.7 }, { x: 22, y: 84.6 }, { x: 23, y: 85.5 }, { x: 24, y: 86.4 },
    { x: 25, y: 86.6 }, { x: 26, y: 87.4 }, { x: 27, y: 88.3 }, { x: 28, y: 89.1 }, { x: 29, y: 89.9 }, { x: 30, y: 90.7 }, { x: 31, y: 91.4 }, { x: 32, y: 92.2 },
    { x: 33, y: 92.9 }, { x: 34, y: 93.6 }, { x: 35, y: 94.4 }, { x: 36, y: 95.1 }, { x: 37, y: 95.7 }, { x: 38, y: 96.4 }, { x: 39, y: 97.1 }, { x: 40, y: 97.7 },
    { x: 41, y: 98.4 }, { x: 42, y: 99 }, { x: 43, y: 99.7 }, { x: 44, y: 100.3 }, { x: 45, y: 100.9 }, { x: 46, y: 101.5 }, { x: 47, y: 102.1 },
    { x: 48, y: 102.7 }, { x: 49, y: 103.3 }, { x: 50, y: 103.9 }, { x: 51, y: 104.5 }, { x: 52, y: 105 }, { x: 53, y: 105.6 }, { x: 54, y: 106.2 },
    { x: 55, y: 106.7 }, { x: 56, y: 107.3 }, { x: 57, y: 107.8 }, { x: 58, y: 108.4 }, { x: 59, y: 108.9 }, { x: 60, y: 109.4 }];

    var set11 = [{ x: 0, y: 52.7 }, { x: 1, y: 57.4 }, { x: 2, y: 60.9 }, { x: 3, y: 63.8 }, { x: 4, y: 66.2 }, { x: 5, y: 68.2 }, { x: 6, y: 70 }, { x: 7, y: 71.6 }, { x: 8, y: 73.2 },
    { x: 9, y: 74.7 }, { x: 10, y: 76.1 }, { x: 11, y: 77.5 }, { x: 12, y: 78.9 }, { x: 13, y: 80.2 }, { x: 14, y: 81.4 }, { x: 15, y: 82.7 }, { x: 16, y: 83.9 },
    { x: 17, y: 85 }, { x: 18, y: 86.2 }, { x: 19, y: 87.3 }, { x: 20, y: 88.4 }, { x: 21, y: 89.4 }, { x: 22, y: 90.5 }, { x: 23, y: 91.5 }, { x: 24, y: 92.5 },
    { x: 25, y: 92.8 }, { x: 26, y: 93.7 }, { x: 27, y: 94.6 }, { x: 28, y: 95.6 }, { x: 29, y: 96.4 }, { x: 30, y: 97.3 }, { x: 31, y: 98.2 }, { x: 32, y: 99 },
    { x: 33, y: 99.8 }, { x: 34, y: 100.6 }, { x: 35, y: 101.4 }, { x: 36, y: 102.2 }, { x: 37, y: 103 }, { x: 38, y: 103.7 }, { x: 39, y: 104.5 },
    { x: 40, y: 105.2 }, { x: 41, y: 106 }, { x: 42, y: 106.7 }, { x: 43, y: 107.4 }, { x: 44, y: 108.1 }, { x: 45, y: 108.8 }, { x: 46, y: 109.5 },
    { x: 47, y: 110.2 }, { x: 48, y: 110.8 }, { x: 49, y: 111.5 }, { x: 50, y: 112.1 }, { x: 51, y: 112.8 }, { x: 52, y: 113.4 }, { x: 53, y: 114.1 },
    { x: 54, y: 114.7 }, { x: 55, y: 115.3 }, { x: 56, y: 116 }, { x: 57, y: 116.6 }, { x: 58, y: 117.2 }, { x: 59, y: 117.8 }, { x: 60, y: 118.4 }];


    var rawSvg = this.elementRef.nativeElement.querySelector('svg');
    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    var wrap = d3.select(rawSvg).selectAll('d.d3line').data([heightData]);
    var gEnter = wrap.enter().append('g').attr('class', 'd3line').append('g');

    var wrap2 = d3.select(rawSvg).selectAll('d.d3line').data([weightData]);
    var gEnter2 = wrap2.enter().append('g').attr('class', 'd3line').append('g');

    var wrap3 = d3.select(rawSvg).selectAll('d.d3line').data([headData]);
    var gEnter3 = wrap3.enter().append('g').attr('class', 'd3line').append('g');

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      }).curve(d3.curveBundle.beta(0.7));


    var datalineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      }).curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xScale0 = d3.scaleLinear().domain([60.5, 0]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([60.5, 0]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([0, 131]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([0, 131]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(0, 60.5, 5)).tickFormat(function (d: any) {
      return d % 10 == 0 ? d : '';
    }).tickSizeInner(-height)
      .tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(0, 60.5, 5)).tickFormat(function (d: any) {
      return d % 10 == 0 ? d : '';
    });
    var yAxisLeft = d3.axisLeft(yScale1).ticks(17).tickValues(d3.range(0, 131, 5)).tickFormat(function (d: any) {
      return d % 10 == 0 ? d : '';
    }).tickSizeInner(-width)
      .tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(17).tickValues(d3.range(0, 131, 5)).tickFormat(function (d: any) {
      return d % 10 == 0 ? d : '';
    }).tickSizeInner(50)
      .tickSizeOuter(50).tickPadding(10);

    var age = function (data) {
      if (data <= 24) {
        return data.toString().split('.')[0] + " months";
      }
      if (data <= 60) {
        var mon = data / 12;
        var result = mon.toFixed(1).split('.');
        var mod = data % 12;
        var res = mod.toString().split('.');
        return result[0].toString() + " years " + res[0] + " months";
      }
    };

    svg.selectAll("circle.heightData")
      .data(heightData)
      .enter()
      .append("circle")
      .attr("cx", function (d: any) {
        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;
      }).attr('fill', 'red')
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;
      })
      .attr("r", function (d: any) {
        return 5;
      }).append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + " cm" });


    svg.selectAll("circle.weightData")
      .data(weightData)
      .enter()
      .append("circle").attr('fill', 'red')
      .attr("cx", function (d: any) {
        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;
      })
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;
      })
      .attr("r", function (d: any) {
        return 5;
      }).append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + " kg" });

    svg.selectAll("circle.headData")
      .data(headData)
      .enter()
      .append("circle")
      .attr("cx", function (d: any) {
        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;
      })
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;
      })
      .attr("r", function (d: any) {
        return 5;
      })
      .attr('fill', 'red').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + " cm" });

    // x-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g").attr("opacity", "1")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -30)
      .style("text-anchor", "middle")
      .text("WHO Girls Height, Weight, Head Charts 0-5 years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 35)
      .style("text-anchor", "middle")
      .text("Age in Months");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 50)
      .style("text-anchor", "middle")
      .style("font-size", "10px")
      .text("Ref: IAP Growth Charts. Indian Academy of Pediatrics. [updated 2013 18 Oct, cited 2016 14 Jan] Available from: http://www.iapindia.org/page.php?id=79");

    svg.append("text")
      .attr("x", -200)
      .attr("y", -35)
      .attr("transform", "translate(0,0) rotate(-90)")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text("WHO MGRS 2006 Charts; Acta Paediatrics Suppl. 450:2006");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 350)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("1 (-3)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 340)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 330)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 310)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 650)
      .attr("y", 300)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("Weight");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 250)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 238)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 228)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 650)
      .attr("y", 220)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("Head");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 100)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("1 (-3)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 90)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 60)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 770)
      .attr("y", 40)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 600)
      .attr("y", 40)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("Height/Length");

    // y-axis
    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g").attr("opacity", "1")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);

    // path lines
    svg.append("path")
      .datum(set1)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set2)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "yellow")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set3)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "yellow")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set4)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set5)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set6)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set7)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set8)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set9)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "yellow")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set10)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set11)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(heightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(weightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(headData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");
  }

}
