<button _ngcontent-mbq-c101="" type="button" data-dismiss="modal" aria-label="Close" class="close" (click)="onNoClick()"><span _ngcontent-mbq-c101="" aria-hidden="true" id="close_btn">×</span></button>
<br>
<body style="overflow-y: auto !important;height: 700px;padding:5px">
<form [formGroup]="vaccinationForm" (submit)="save_vaccination(data)">
<table class="table table-borderless">
    <thead>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Vaccine Name</th>
        <th scope="col"><p>{{data.obj.drug_name}}</p></th>
      </tr>
      <br>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Administration Date</th>
        <th scope="col"><input class="form-control" type="date" formControlName="administration" /></th>
      </tr>
      <br>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Brand Name</th>
        <th scope="col"><input class="form-control" type="text" placeholder="Enter Brand Name" formControlName="brandname" /></th>
      </tr>
      <br>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Route of site</th>
        <th scope="col">
            <select name="site" id="site" class="form-control" formControlName="site">
                <option value="Intramuscular(IM)">Intramuscular(IM)</option>
                <option value="Oral">Oral</option>
                <option value="Subcutaneous (SC)">Subcutaneous (SC)</option>
            </select>
        </th>
      </tr>
      <br>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Batch ID</th>
        <th scope="col"><input class="form-control" type="text" placeholder="Enter Batch ID" formControlName="batch"/></th>
      </tr>
      <br>
      <tr style="margin-bottom: 5px;">
        <th scope="col">Comments</th>
        <th scope="col"><textarea  class="form-control" formControlName="textarea"></textarea></th>
      </tr>
    </thead>
    <br>
    <div style="justify-content:center;"><button class="btn btn-primary" type="submit">Administered</button></div>
  </table>
</form>

<hr>


  <div style="text-align: center;"><strong>Upcoming Vaccination</strong></div>
  <table class="table table-borderless">
      <thead>
        <tr *ngFor="let d of data.nextvaccine;let i = index">
          <th>{{i+1}}</th>
          <th scope="col" class="upcoming_vaccine"><h4>{{d.vaccine_name}}</h4></th>
          <th scope="col" class="upcoming_vaccine"><input class="form-control" type="date"  [(ngModel)]="data.nextvaccine[i].date_given" /></th>
          <button class="btn btn-primary" (click)="update_monthvaccine(d)" [disabled]="data.nextvaccine[i].date_given == undefined">Add</button>
        </tr>
      </thead>
    </table>
 
</body>