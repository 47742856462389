<div style="display: flex;flex-direction: row;justify-content: space-between;">
    <p style="font-size: 1rem;font-weight: 600;color: #0063FF;">Add a new vaccine</p>
    <div>
        <button class="btn btn-primary" [class.disabled]="name==''" style="padding-top: 0;padding-bottom: 0;" (click)="save()">Save</button>

        <!-- <img style="height: 1rem; width: 1rem;cursor: pointer;" src="assets/add.png"> -->
        <img (click)="close()" style="width: 1rem;cursor: pointer;margin-left: 00.5rem;" src="assets/cancel.svg">

    </div>
</div>

<table style="width: 100%;">
    <tr>
        <td>Age Category *</td>
        <td >
            <select [(ngModel)]="ageCategory" style="width: 100%;">
                <option value="Birth">Birth</option>
                <option value="6 Weeks">6 Weeks</option>
                <option value="10 Weeks">10 Weeks</option>
                <option value="14 Weeks">14 Weeks</option>
                <option value="6 Months">6 Months</option>
                <option value="7-8 Months">7-8 Months</option>
                <option value="9-12 Months">9-12 Months</option>
                <option value="15-18 Months">15-18 Months</option>
                <option value="18-24 Months">18-24 Months</option>
                <option value="4-6 Years">4-6 Years</option>
                <option value="10 Years">10 Years</option>
                <option value="11-14 Years">11-14 Years</option>
                <option value="15-18 Years">15-18 Years</option>

            </select>
        </td>
    </tr>

    <tr style="margin-top: 00.5rem;">
        <td >Vaccination name *</td>
        <td><input [(ngModel)]="name" style="width: 100%;margin-top: 00.5rem;" type="text"></td>

    </tr>

    <tr style="margin-top: 00.5rem;">
        <td>Gender *</td>
        <td >
            <select [(ngModel)]="gender" style="width: 100%;margin-top: 00.5rem;">
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="both">Both</option>

            </select>
        </td>
    </tr>

    <tr style="margin-top: 00.5rem;">
        <td>Site *</td>
        <td >
            <select [(ngModel)]="site" style="width: 100%;margin-top: 00.5rem;">
                <option value="Intramuscular (IM)">Intramuscular (IM)</option>
                <option value="Oral">Oral</option>
                <option value="Subcutaneous (SC)">Subcutaneous (SC)</option>

            </select>
        </td>
    </tr>
</table>