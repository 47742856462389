import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoiceRecognitionService } from '../wrapper/service/voice-recognition.service';
import { AppConfig } from 'src/app/app.config';
import { AppService } from '../app.service';
import { Toast, ToastrService } from 'ngx-toastr';

export interface DialogData_Confirm {
  name: any
}

export interface DialogData_notes {
  dmail: any,
  email: any,
  doctor_id: any,
  token: any
}

export interface DialogData_labnotes {
  dmail: any,
  email: any,
  doctor_id: any,
  token: any
}

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  @Input() text;
  @Input() started;
  @Input() muted;
  @Input() dmail;
  @Input() drID;
  @Input() email;
  @Input() token;
  @Input() full;
  @Input() clear;

  @Output() someEvent = new EventEmitter<Boolean>();
  @Output() someEvent_enter = new EventEmitter<any>();
  @Output() someEvent_input = new EventEmitter<any>();
  @Output() someEvent_key = new EventEmitter<Boolean>();
  @Output() someEvent_click = new EventEmitter<any>();

  supported;
  osName;
  muteButton;
  timeoutHandler: any;
  held = false

  constructor(public service: VoiceRecognitionService, public dialog: MatDialog, private toastr: ToastrService) {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      if (this.getOS() != 'iOS' && this.getOS() != "Mac OS") {
        this.service.init();
        this.supported = true
      }
      else {
        this.supported = false
      }

    } else {
      this.supported = false
    }
  }

  ngOnInit(): void {
    this.muteButton = 'assets/mute.svg'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.muted) {
      this.muteButton = 'assets/mute.svg'
    }
    else {
      this.muteButton = 'assets/unmute.svg'
    }

    if (changes.clear) {
      this.text = ''
    }

  }


  enter() {
    this.someEvent_enter.next(this.text);
  }

  inputClick() {
    this.someEvent_click.next();

  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    this.osName = os

    return os;
  }

  input(event) {
    this.someEvent_input.next(event.target.value)
  }

  toggle_key() {
    //someEvent_key
    this.someEvent_key.next();
  }

  toggle() {
    // this.muted = !this.muted
    // if (this.muted) {
    //   this.muteButton = 'assets/mute.svg'
    //   this.someEvent.next(false);

    // }
    // else {
    //   this.muteButton = 'assets/unmute.svg'
    //   this.someEvent.next(true);

    // }
  }


  public mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);

      this.timeoutHandler = null;

    }

    if (this.held) {
      this.held = false
      this.someEvent.next(false);
      this.muteButton = 'assets/mute.svg'
    }

  }

  public mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.someEvent.next(true);

      this.held = true

      this.muteButton = 'assets/unmute.svg'

      this.timeoutHandler = null;
    }, 500);
  }

  doctorNotes() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_doctornotes, {
      width: '600px',
      data: { dmail: this.dmail, email: this.email, doctor_id: this.drID, token: this.token }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  labNotes() {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_labnotes, {
      width: '600px',
      data: { dmail: this.dmail, email: this.email, doctor_id: this.drID, token: this.token }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

}

@Component({
  selector: 'doctornotes',
  templateUrl: 'doctornotes.html',
  styleUrls: ['doctornotes.scss']
})

export class DialogOverviewExampleDialog_doctornotes {
  block = 1;
  newNote = '';
  disabled = false
  submitted = false
  urlPoints: any;
  noteList;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_doctornotes>, private appService: AppService, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_notes) {
    this.urlPoints = AppConfig.settings.EndPoints;


  }
  ngOnInit(): void {
    this.getNotes()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  setBlock(i) {
    this.block = i
    this.newNote = ''
    this.submitted = false
  }

  remove(id, notes) {
    var params = {
      account_email: this.data.dmail,
      notes: notes,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id,
      status: 0,
      notes_id: id
    }

    this.appService.postData(this.urlPoints.saveNotes, params, this.data.token).subscribe((response: any) => {
      this.toastr.success("Note deleted!")
      this.block = 1
      this.getNotes()
    });
  }

  getNotes() {
    var params = {
      account_email: this.data.dmail,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id
    }

    this.appService.getData(this.urlPoints.getNotes, params, this.data.token).subscribe((response: any) => {
      this.noteList = response.details
      this.noteList.reverse()
    });
  }

  save() {

    this.submitted = true;

    var params = {
      account_email: this.data.dmail,
      notes: this.newNote,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id
    }

    if (this.newNote != '') {
      this.disabled = true
      this.appService.postData(this.urlPoints.saveNotes, params, this.data.token).subscribe((response: any) => {
        this.toastr.success("Note added!")
        this.block = 1
        this.getNotes()
        this.disabled = false
      });
    }
  }
}

@Component({
  selector: 'labnotes',
  templateUrl: 'labnotes.html',
  styleUrls: ['labnotes.scss']
})

export class DialogOverviewExampleDialog_labnotes {
  block = 1;
  newNote = '';
  disabled = false
  noteList;
  submitted = false
  urlPoints: any;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog_labnotes>, private appService: AppService, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData_labnotes) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.getNotes()
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  setBlock(i) {
    this.block = i
    this.newNote = ''
    this.submitted = false
  }

  save() {

    this.submitted = true;

    var params = {
      account_email: this.data.dmail,
      lab_note: this.newNote,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id
    }

    if (this.newNote != '') {
      this.disabled = true
      this.appService.postData(this.urlPoints.saveLabNotes, params, this.data.token).subscribe((response: any) => {
        this.toastr.success("Note added!")
        this.block = 1
        this.getNotes()
        this.disabled = false
      });
    }
  }

  getNotes() {
    var params = {
      account_email: this.data.dmail,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id
    }

    this.appService.getData(this.urlPoints.getLabNotes, params, this.data.token).subscribe((response: any) => {
      this.noteList = response.details
      this.noteList.reverse()

    });
  }

  remove(id, notes) {
    var params = {
      account_email: this.data.dmail,
      lab_note: notes,
      patient_email: this.data.email,
      doctor_id: this.data.doctor_id,
      status: 0,
      lab_id: id
    }

    this.appService.postData(this.urlPoints.saveLabNotes, params, this.data.token).subscribe((response: any) => {
      this.toastr.success("Note deleted!")
      this.block = 1
      this.getNotes()
    });
  }

}