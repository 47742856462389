<div class="container">
    <div class="row" style="margin-top: 1rem;">
        <div class="col-lg-11" style="text-align: center;font-size: x-large;font-weight: 500;">
            {{data.type}} Download Options
        </div>
        <div class="col-lg-1">
            <img (click)="close()" style="width: 1rem;cursor: pointer;margin-left: 00.5rem;" src="assets/cancel.svg">
        </div>
    </div>
    <div class="row" style="margin-top: 1rem;">
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="Name"  [(ngModel)]="Name" (change)="selectOption(Name)"> Name
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="mrNO" [(ngModel)]="mrNO" (change)="selectOption(mrNO)"> MR NO
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="dob" [(ngModel)]="dob" (change)="selectOption(dob)"> DOB
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="gender" [(ngModel)]="gender" (change)="selectOption(gender)"> Gender
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="phNo" [(ngModel)]="phNo" (change)="selectOption(phNo)"> Phone Number
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="height" [(ngModel)]="height" (change)="selectOption(height)"> Height
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [disabled]="weight" [(ngModel)]="weight" (change)="selectOption(weight)"> Weight
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="Father" (change)="selectOption(Father)"> Father Name
        </div>
        <!-- <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="mother" (change)="selectOption(mother)"> Mother Name
        </div> -->
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="rDate" (change)="selectOption(rDate)"> Registred Date
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="heart" (change)="selectOption(heart)"> Heart Rate
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="sys" (change)="selectOption(sys)"> Systolic
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="dia" (change)="selectOption(dia)"> Diastolic
        </div>
        <div class="col-lg-4" *ngIf="data.type!='XLS'">
            <input type="checkbox" [(ngModel)]="mmol" (change)="selectOption(mmol)"> Glucometer mmol
        </div>
        <div class="col-lg-4" *ngIf="data.type!='XLS'">
            <input type="checkbox" [(ngModel)]="mgol" (change)="selectOption(mgol)"> Glucometer mgol
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="oxy" (change)="selectOption(oxy)"> Oxygen Saturation
        </div>
        <div class="col-lg-4">
            <input type="checkbox" [(ngModel)]="tem" (change)="selectOption(tem)"> Temperature
        </div>
    </div>

    <div class="row" style="margin-top: 1.5rem;">
        <div class="col-lg-3"></div>
        <div class="col-lg-6" style="text-align: center;">
            <button class="btn btn-primary" (click)="print()">
                Proceed
            </button>
        </div>
        <div class="col-lg-3"></div>
    </div>


</div>