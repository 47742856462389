<div class="d-flex" id="wrapper">
    <!-- Left Sidebar -->
    
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light border-bottom">
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
          <h2 style="margin-top:10px">Dashboard</h2>
        </div>
        <!-- <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li><span > What's New </span></li>
        </ul> -->
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
                <img style="height: 30px;cursor: pointer;" title="Appointments" src="assets/appointment active.svg" (click)="goToAppointment();">
            </li>
        </ul>
      </nav>
      
        <!-- /#footer -->
    </div>

  </div>

  <div class="bodycontainer">
    <div class="row" *ngIf="setaccount_email != 'rx3@rxcarenet.org'">

        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 marTop">

            <div class="row">

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Waiting</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#4D007E;" (click)="goToReports(8)">{{totalWaiting}}</div>
                                    <mat-progress-bar class="waiting-progress" mode="determinate" [value]="totalWaiting" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#4D007E;">
                                        <img src="assets/images/stopwatch/stopwatch.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Avg mins</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{avg_waiting}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Max mins</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{max_waiting}}</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Tele Consultation</div>
                                    <div class="subcountfont" style="margin-top: 12px;color: #0088FF;" (click)="goToReports(1)">{{totalTeleConsult}}</div>
                                    <mat-progress-bar class="tele-progress" mode="determinate" [value]="totalTeleConsult" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color: #0088FF;">
                                        <img src="assets/images/video-calling/video-calling.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Done</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{done_tele}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Total</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{total_tele}}</span> </div>
                                    </div>
                            </div>                       
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Chat</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#3E3EF4;" (click)="goToReports(7)">{{totalChatCount}}</div>
                                    <mat-progress-bar class="chat-progress" mode="determinate" [value]="totalChatCount" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#3E3EF4;">
                                        <img src="assets/images/chat/chat.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>UnResponed</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{unresponed_chat}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Responed</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{received_chat}}</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Appointment</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#FA2E2E;" (click)="goToReports(2)">{{totalapt}}</div>
                                    <mat-progress-bar class="app-progress" mode="determinate" [value]="totalapt" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#FA2E2E;">
                                        <img src="assets/images/Flat/Flat.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-md xsLeftWidth">
                                    <div>Completed</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{completedapt}}</span> </div>
                                    </div>
                            </div>
                            <!-- <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6">
                                    <div>Responed</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6">
                                        <div><span class="subcountfont">{{received_chat}}</span> </div>
                                    </div>
                            </div> -->
                        </div>
                    </div>
                </div> 

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Vaccination</div>
                                    <div class="subcountfont" style="margin-top: 12px;color: #CD5668;cursor: default;">{{totalVaccine}}</div>
                                    <mat-progress-bar class="vacc-progress" mode="determinate" [value]="totalVaccine" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color: #CD5668;">
                                        <img src="assets/images/syringe/syringe.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Booked Session</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{booked_vaccine}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Done</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{done_vacine}}</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">eShop</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#FFC107;cursor: default">0</div>
                                    <mat-progress-bar class="eshop-progress" mode="determinate" [value]="0" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#FFC107;">
                                        <img src="assets/images/cart/cart.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Sent</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">0</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Total</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">0</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Revenue</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#21B3A9;" (click)="goToReports(6)">{{totalRevenue}}</div>
                                    <mat-progress-bar class="rev-progress" mode="determinate" [value]="0" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#21B3A9;">
                                        <img src="assets/images/money-currency/money-currency.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="revenue" style="display: flex; flex-direction: column;">
                                <div class="row" style="margin-top: 8px;">
                                    <div class="col-lg-8 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                        <div>Previous</div>
                                    </div>
                                        <div class="col-lg-4 col-md-3 col-xs-6 col-sm-6 xsRightwidth" >
                                            <div><span class="bottomcountfont">{{previusdayRevenue}}</span> </div>
                                        </div>
                                </div>
                                <div class="row" style="margin-top: 5px;">
                                    <div class="col-lg-8 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                        <div>Last Week</div>
                                    </div>
                                          <div class="col-lg-4 col-md-3 col-xs-6 col-sm-6 xsRightwidth" >
                                            <div><span class="bottomcountfont">{{lastweekRevenue}}</span> </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Subscription</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#1567FF;" (click)="goToReports(3)">{{totalsubtraction}}</div>
                                    <mat-progress-bar class="sub-progress" mode="determinate" [value]="totalsubtraction" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#1567FF;">
                                        <img src="assets/images/subscription/subscr.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div> 
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop"> 
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Patient</div>
                                    <div class="subcountfont" style="margin-top: 12px;color: #FF7700;cursor: default">{{totalPatient}}</div>
                                    <mat-progress-bar class="pat-progress"mode="determinate" [value]="totalPatient" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color: #FF7700 #F83D74;">
                                        <img src="assets/images/chemotherapy/chemotherapy.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>New Patient</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{new_patient}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-6 xsLeftWidth">
                                    <div>Old Patient</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6 xsRightwidth">
                                        <div><span class="bottomcountfont">{{old_patient}}</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop"> 
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Registration</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#F83D74;" (click)="goToReports(4)">{{totalreg}}</div>
                                    <mat-progress-bar class="reg-progress" mode="determinate" [value]="totalreg" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#F83D74;">
                                        <img src="assets/images/register/register.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>                            
                        </div> 
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop"> 
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">Feedback</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#35A872;" (click)="goToReports(5)">{{totalfeedback}}</div>
                                    <mat-progress-bar class="feed-progress" mode="determinate" [value]="totalfeedback" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#35A872;">
                                        <img src="assets/images/positive-feedback/positive-feedback.png" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-xs-12 col-sm-6 marTop">
                    <div class="DrCard">
                        <div>
                            <div class="row rowstyle">
                                <div class="col-xs xsLeftWidth">
                                    <div class="countfont">IVRS</div>
                                    <div class="subcountfont" style="margin-top: 12px;color:#3599a8" (click)="goToReports(9)">{{uniqueivrs}}</div>
                                    <mat-progress-bar class="waiting-progress" mode="determinate" [value]="uniqueivrs" style="margin-top: 15px;"></mat-progress-bar>
                                </div>
                                <div class="col-xs xsRightwidth">
                                    <div class="roundedDiv" style="border-color:#3599a8;">
                                        <img src="assets/images/stopwatch/ivrsapt.svg" class="imgPadding" /> 
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 8px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-9 xsLeftWidth">
                                    <div>IVRS appointment</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-3 xsRightwidth">
                                        <div><span class="bottomcountfont">{{ivrs_appointment}}</span> </div>
                                    </div>
                            </div>
                            <div class="row" style="margin-top: 5px;">
                                <div class="col-lg-9 col-md-9 col-xs-6 col-sm-9 xsLeftWidth">
                                    <div>IVRS No appointment</div>
                                </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6 col-sm-3 xsRightwidth">
                                        <div><span class="bottomcountfont">{{ivrs_noappt}}</span> </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>

        </div>

    </div>

    <div class="row" *ngIf="releaseNotes == 'False'">
        <div class="card remcard">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <h2>New Updates Available!</h2>
              </div>
              <div class="row">
                <div class="col-md-5 col-lg-5">
                  <button type="button" class="btn btn-outline-primary" (click)="openPopup()">Read More </button>
                </div>
                <div class="col-md-7 col-lg-7"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div *ngIf="setaccount_email == 'rx3@rxcarenet.org'">
        <div class="row">
            <div class="col-sm-9 col-md-9 col-lg-9">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div></div>
                </div>
                <div>
                    <h1 style="font-weight: 700;">Multi Center Dashboard</h1>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h3 style="color: black;font-weight:700;">Intervention Block</h3>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="card_1">
                        <div class="progress_1" id="progress1"></div>
                        <div class="content_1">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding:10px;">
                                    <div class="roundedDiv" style="border-color: #00A53C ;">
                                        <img src="assets/images/img/baby-boy.svg" class="imgPadding">
                                    </div>
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8" style="text-align:right">
                                    <h6 style="font-size:14px;color: #213331;font-weight: 600;">Readmitted Babies</h6>
                                    <!-- <h6 style="font-size:14px;color:#213331">This week</h6> -->
                                    <h4 style="color: #00A53C;font-weight: 600 !important;">TODAY - {{readmit_today}}</h4>
                                    <h6 style="font-size:14px;color: #6D8784;">Last Week - {{readmit_lastweek}}</h6>
                                </div>
                            </div>
                            <br>
                            <p class="percentage_1">{{readmit_today}}%</p>
                            <div style="bottom: 0;">
                                <mat-progress-bar style="color: #00A53C;" class="chat-progress" mode="determinate" [value]="readmit_today">{{readmit_today}}</mat-progress-bar>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="card_1">
                        <div class="progress_2" id="progress2"></div>
                        
                        <div class="content_2">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding:10px;">
                                    <div class="roundedDiv" style="border-color: #744C9F;">
                                        <img src="assets/images/img/community.svg" class="imgPadding" />
                                </div>
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8" style="text-align:right">
                                    <h6 style="font-size:14px;color: #213331;font-weight: 600;font-weight: 600;">Community visit adherence</h6>
                                    <h4 style="color: #744C9F;font-weight: 600 !important;">Completed - {{completed}}</h4>
                                    <h6 style="font-size:14px;color: #6D8784;">Planned - {{planned}}</h6>
                                </div>
                            </div>
                            <br>
                            <p class="percentage_1">{{community_percentage}}%</p>
                            <div style="bottom: 0;">
                                <mat-progress-bar style="color: #00A53C;" class="chat-progress" mode="determinate" [value]="community_percentage">{{community_percentage}}</mat-progress-bar>
                            </div>
                
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="card_1">
                        <div class="progress_3" id="progress3"></div>
                    
                        <div class="content_3">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding:10px;">
                                    <div class="roundedDiv" style="border-color: #744C9F;">
                                        <img src="assets/images/img/hospital.svg" class="imgPadding" />
                                </div>
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8" style="text-align:right">
                                    <h6 style="font-size:14px;color: #213331;font-weight: 600;">Institution visit adherence</h6>
                                    <!-- <h6 style="font-size:14px;color:#213331">adherence - This Week</h6> -->
                                    <h4 style="color: #F44949;font-weight: 600 !important;">Completed - {{institutional_compled}}</h4>
                                    <h6 style="font-size:14px;color: #6D8784;">Planned - {{institutional_planned}}</h6>
                                </div>
                            </div>
                            <br>
                            <p class="percentage_1">{{institutional_percentage}}%</p>
                            <div style="bottom: 0;">
                                <mat-progress-bar style="color: #00A53C;" class="chat-progress" mode="determinate" [value]="institutional_percentage">{{institutional_percentage}}</mat-progress-bar>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="card_1">
                        <div class="progress_4" ></div>
                        
                        <div class="content_4">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: center;padding:10px;">
                                    <div class="roundedDiv" style="border-color: #744C9F;">
                                        <img src="assets/images/img/baby.svg" class="imgPadding" />
                                </div>
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8" style="text-align:right">
                                    <h6 style="font-size:14px;color: #213331;font-weight: 600;">Total Mortality</h6>
                                    <!-- <h6 style="font-size:14px;color:#213331">adherence - This Week</h6> -->
                                    <h4 style="color: #8B0064;font-weight: 600 !important;">This Week - {{this_week_mortality}}</h4>
                                    <h6 style="font-size:14px;color: #6D8784;">Last Week - {{last_week_mortality}}</h6>
                                </div>
                            </div>
                            <!-- <br>
                            <p class="percentage_1">{{this_week_mortality}}%</p>
                            <div style="bottom: 0;">
                                <mat-progress-bar style="color: #00A53C;" class="chat-progress" mode="determinate" [value]="this_week_mortality">{{this_week_mortality}}</mat-progress-bar>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>

            </div>
            <div class="col-sm-3 col-md-3 col-lg-3" style="background-color: #F1F1FB;height:95vh;margin-top: -20px;">
                <div class="row" style="padding: 40px;text-align:center;">
                    <img src="assets/images/img/illustration.svg" height="300" width="300"  style="margin-top: 8px;"/>
                </div>
            </div>
        </div>
    
        <style>
            /* progress 1*/
            .card_1 {
                background-color:#FFFFFF;
                box-sizing:border-box;
                float:left;
                height:11em;
                margin:.5em;
                position:relative;
                width:22em;
                border-radius: 12px;
                box-shadow: 0px 0px 20px #00000029;
            }
            
            .progress_1 {
                bottom:0;
                left:0;
                position:absolute;
                top:0;
            }
            
            .progress_1:before {
                animation:slideIn 2s ease-out;
                background-color: #E6FFEF;
                border-radius: 12px;
                bottom:0;
                left:0;
                content:"";
                position:absolute;
                top:0;
                width:100%;
            }
            
            .Progress_1 {
                bottom:0;
                left:0;
                position:absolute;
                animation:slideIn 2s ease-out;
                top: auto !important;
                background-color: #00A53C !important;
                height: 5px !important;
                border-radius: 12px !important;
            }
        
            .content_1 {
                position:relative;
                padding-left: 15px;
                padding-top: 10px;
                padding-right: 16px;
            }
            
            .percentage_1 {
                text-align:right;
                margin-top: -20px;
            }
            
            @keyframes slideIn {
                0% {
                background-color: #E6FFEF;
                width:0;
                }
                100% {
                background-color: #FFFFFF;
                width:100%;
                }
            }
        
            /* progress 2*/   
            .progress_2 {
                bottom:0;
                left:0;
                position:absolute;
                top:0;
            }
            
            .progress_2:before {
                animation:slideIn 2s ease-out;
                background-color: #F5ECFF;
                border-radius: 12px;
                bottom:0;
                left:0;
                content:"";
                position:absolute;
                top:0;
                width:100%;
            }
            
            .Progress_2 {
                bottom:0;
                left:0;
                position:absolute;
                animation:slideIn 2s ease-out;
                top: auto !important;
                background-color: #744C9F !important;
                height: 5px !important;
                border-radius: 12px !important;
            }
        
            .content_2 {
                position:relative;
                padding-left: 15px;
                padding-top: 10px;
                padding-right: 16px;
            }
            
            .percentage_2 {
                text-align:right;
                margin-top: -20px;
            }
            
            @keyframes slideIn {
                0% {
                background-color: #E6FFEF;
                width:0;
                }
                100% {
                background-color: #FFFFFF;
                width:100%;
                }
            }
        
            /* progress 3*/   
            .progress_3 {
                bottom:0;
                left:0;
                position:absolute;
                top:0;
            }
            
            .progress_3:before {
                animation:slideIn 2s ease-out;
                background-color: #FFF5EE;
                border-radius: 12px;
                bottom:0;
                left:0;
                content:"";
                position:absolute;
                top:0;
                width:100%;
            }
            
            .Progress_3 {
                bottom:0;
                left:0;
                position:absolute;
                animation:slideIn 2s ease-out;
                top: auto !important;
                background-color: #F44949 !important;
                height: 5px !important;
                border-radius: 12px !important;
            }
        
            .content_3 {
                position:relative;
                padding-left: 15px;
                padding-top: 10px;
                padding-right: 16px;
            }
            
            .percentage_3 {
                text-align:right;
                margin-top: -20px;
            }
            
            @keyframes slideIn {
                0% {
                background-color: #E6FFEF;
                width:0;
                }
                100% {
                background-color: #FFFFFF;
                width:100%;
                }
            }
        
                /* progress 4*/   
                .progress_4 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    top:0;
                }
                
                .progress_4:before {
                    animation:slideIn 2s ease-out;
                    background-color: #FFE6F8;
                    border-radius: 12px;
                    bottom:0;
                    left:0;
                    content:"";
                    position:absolute;
                    top:0;
                    width:100%;
                }
                
                .Progress_4 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    animation:slideIn 2s ease-out;
                    top: auto !important;
                    background-color: #8B0064 !important;
                    height: 5px !important;
                    border-radius: 12px !important;
                }
            
                .content_4 {
                    position:relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-right: 16px;
                }
                
                .percentage_4 {
                    text-align:right;
                    margin-top: -20px;
                }
                
                @keyframes slideIn {
                    0% {
                    background-color: #E6FFEF;
                    width:0;
                    }
                    100% {
                    background-color: #FFFFFF;
                    width:100%;
                    }
                }
        
                /* progress 5*/   
                .progress_5 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    top:0;
                }
                
                .progress_5:before {
                    animation:slideIn 2s ease-out;
                    background-color: #EFEFEF;
                    border-radius: 12px;
                    bottom:0;
                    left:0;
                    content:"";
                    position:absolute;
                    top:0;
                    width:100%;
                }
                
                .Progress_5 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    animation:slideIn 2s ease-out;
                    top: auto !important;
                    background-color: #37403E !important;
                    height: 5px !important;
                    border-radius: 12px !important;
                }
            
                .content_5 {
                    position:relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-right: 16px;
                }
                
                .percentage_5 {
                    text-align:right;
                    margin-top: -20px;
                }
                
                @keyframes slideIn {
                    0% {
                    background-color: #E6FFEF;
                    width:0;
                    }
                    100% {
                    background-color: #FFFFFF;
                    width:100%;
                    }
                }
        
                    /* progress 6*/   
                .progress_6 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    top:0;
                }
                
                .progress_6:before {
                    animation:slideIn 2s ease-out;
                    background-color: #FFF5DB;
                    border-radius: 12px;
                    bottom:0;
                    left:0;
                    content:"";
                    position:absolute;
                    top:0;
                    width:100%;
                }
                
                .Progress_6 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    animation:slideIn 2s ease-out;
                    top: auto !important;
                    background-color: #FF0066 !important;
                    height: 5px !important;
                    border-radius: 12px !important;
                }
            
                .content_6 {
                    position:relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-right: 16px;
                }
                
                .percentage_6 {
                    text-align:right;
                    margin-top: -20px;
                }
                
                @keyframes slideIn {
                    0% {
                    background-color: #E6FFEF;
                    width:0;
                    }
                    100% {
                    background-color: #FFFFFF;
                    width:100%;
                    }
                }
            
                    /* progress 7*/   
                .progress_7 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    top:0;
                }
                
                .progress_7:before {
                    animation:slideIn 2s ease-out;
                    background-color: #FFF5DB;
                    border-radius: 12px;
                    bottom:0;
                    left:0;
                    content:"";
                    position:absolute;
                    top:0;
                    width:100%;
                }
                
                .Progress_7 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    animation:slideIn 2s ease-out;
                    top: auto !important;
                    background-color: #FFB600 !important;
                    height: 5px !important;
                    border-radius: 12px !important;
                }
            
                .content_7 {
                    position:relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-right: 16px;
                }
                
                .percentage_7 {
                    text-align:right;
                    margin-top: -20px;
                }
                
                @keyframes slideIn {
                    0% {
                    background-color: #E6FFEF;
                    width:0;
                    }
                    100% {
                    background-color: #FFFFFF;
                    width:100%;
                    }
                }
        
                        /* progress 8*/   
                .progress_8 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    top:0;
                }
                
                .progress_8:before {
                    animation:slideIn 2s ease-out;
                    background-color: #EEFCFF;
                    border-radius: 12px;
                    bottom:0;
                    left:0;
                    content:"";
                    position:absolute;
                    top:0;
                    width:100%;
                }
                
                .Progress_8 {
                    bottom:0;
                    left:0;
                    position:absolute;
                    animation:slideIn 2s ease-out;
                    top: auto !important;
                    background-color: #00C8EC !important;
                    height: 5px !important;
                    border-radius: 12px !important;
                }
            
                .content_8 {
                    position:relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-right: 16px;
                }
                
                .percentage_8 {
                    text-align:right;
                    margin-top: -20px;
                }
                
                @keyframes slideIn {
                    0% {
                    background-color: #E6FFEF;
                    width:0;
                    }
                    100% {
                    background-color: #FFFFFF;
                    width:100%;
                    }
                }
        </style>
    </div>
  </div>




