<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true"
                        style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 containerTop">
            <p class="headText">Basic Details</p>
        </div>
        <div class="col-sm-1 col-md-1 col-lg-1 containerTop" style="float:right">
            <div (click)="editDetails()" *ngIf="!editStatus&&abhanumber==null" style="color:#007bff;cursor: pointer;">Edit</div>
        </div>
    </div>

    <div *ngIf="editStatus" style="margin-top: 50px;">
        <form [formGroup]="profileForm" class="row" style="width:100%">

            <div class="col-sm-6 col-md-6 col-lg-6 leftBorder">
                <div class="row">
                    <div class="col-6">
                        <div class="container" style="padding-top: 0;margin-left: -50px;">

                            <div class="avatar-upload">
                                <div class="avatar-preview">
                                    <div id="imagePreview" class="profile-image" style="margin-top:2px"
                                        [ngStyle]="{'background-image': 'url('+ uploadCoverDrImage + ')'}">
                                    </div>
                                </div>
                                <div class="avatar-edit">
                                    <input type="file" name="file" id="file" class="inputfile" accept="image/*"
                                        (change)="uploadCoverImage($event);" />
                                    <label for="file"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="heading">PERSONAL DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Name <span style="color:red">*</span></span>
                            <input class="form-control" type="text" placeholder="FirstName . MiddleName . LastName"
                                formControlName="Name" pattern="[a-zA-Z ]*" maxlength="20"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            <div class="error"
                                *ngIf="(profileForm.controls.Name.touched||profileForm.controls.Name.dirty || submitted)&&profileForm.controls.Name.errors?.required">
                                Name required</div>
                            <div class="error"
                                *ngIf="(profileForm.controls.Name.touched||profileForm.controls.Name.dirty || submitted)&&profileForm.controls.Name.errors?.minlength">
                                Minimum 3 characters required</div>
                            <div class="error"
                                *ngIf="(profileForm.controls.Name.touched||profileForm.controls.Name.dirty || submitted)&&profileForm.controls.Name.errors?.pattern">
                                Only letters are allowed</div>
                        </div>
                        <br>
                        <div class="row" style="margin-left: 0px;margin-top: -10px;">

                            <span class="sub_headings">Gender</span>
                            <div class="btn-group btn-group-toggle" data-toggle="buttons"
                                style="margin-top: 32px;margin-left: -40px;">
                                <label class="btn btn-light" style="width:13vw;"
                                    [ngClass]="{'active': (gender==='male')}">
                                    <input type="radio" name="gender" value="male" formControlName="gender" for="male">
                                    Male
                                </label>
                                <label class="btn btn-light" style="width:13vw;"
                                    [ngClass]="{'active': (gender==='female')}">
                                    <input type="radio" name="gender" value="female" formControlName="gender"
                                        for="female"> Female
                                </label>
                            </div>
                        </div>
                        <br>
                        <span class="sub_headings">Date of Birth <span style="color:red">*</span></span><span
                            class="float-right text-primary sub_headings_right">Expected
                            Birth</span>
                        <input id="dob" class="form-control" type="date" [min]="patientmindate" [max]="patientmaxdate" 
                            placeholder="DD . MMM . YYYY" formControlName="DOB">
                        <div class="error"
                            *ngIf="(profileForm.controls.DOB.touched||profileForm.controls.DOB.dirty || submitted)&&profileForm.controls.DOB.errors?.required">
                            DOB required</div>
                    </div>
                </div>

                <div class="row" style="margin-top: 25px;">
                    <div class="col-md-12">
                        <span class="heading">PARENT DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div class="row">
                            <div class="col-md-6">
                                <div>
                                    <span class="sub_headings">Father First Name</span>
                                    <input class="form-control" type="text" placeholder="FirstName"
                                        formControlName="fathername" pattern="[a-zA-Z ]*" maxlength="20"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fathername.touched||profileForm.controls.fathername.dirty || submitted)&&profileForm.controls.fathername.errors?.required">
                                        Father Name required</div>
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fathername.touched||profileForm.controls.fathername.dirty || submitted)&&profileForm.controls.fathername.errors?.minlength">
                                        Minimum 3 characters required</div>
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fathername.touched||profileForm.controls.fathername.dirty || submitted)&&profileForm.controls.fathername.errors?.pattern">
                                        Only letters are allowed</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <span class="sub_headings">Father Last Name</span>
                                    <input class="form-control" type="text" placeholder="LastName"
                                        formControlName="fatherlastname" pattern="[a-zA-Z ]*" maxlength="20"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fatherlastname.touched||profileForm.controls.fatherlastname.dirty || submitted)&&profileForm.controls.fatherlastname.errors?.required">
                                        Father Name required</div>
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fatherlastname.touched||profileForm.controls.fatherlastname.dirty || submitted)&&profileForm.controls.fatherlastname.errors?.minlength">
                                        Minimum 3 characters required</div>
                                    <div class="error"
                                        *ngIf="(profileForm.controls.fatherlastname.touched||profileForm.controls.fatherlastname.dirty || submitted)&&profileForm.controls.fatherlastname.errors?.pattern">
                                        Only letters are allowed</div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="sub_headings">Mother First Name</span>
                                <input class="form-control" type="text" placeholder="FirstName"
                                    formControlName="mothername" pattern="[a-zA-Z ]*" maxlength="20"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                <div class="error"
                                    *ngIf="(profileForm.controls.mothername.touched||profileForm.controls.mothername.dirty || submitted)&&profileForm.controls.mothername.errors?.required">
                                    Mother Name required</div>
                                <div class="error"
                                    *ngIf="(profileForm.controls.mothername.touched||profileForm.controls.mothername.dirty || submitted)&&profileForm.controls.mothername.errors?.minlength">
                                    Minimum 3 characters required</div>
                                <div class="error"
                                    *ngIf="(profileForm.controls.mothername.touched||profileForm.controls.mothername.dirty || submitted)&&profileForm.controls.mothername.errors?.pattern">
                                    Only letters are allowed</div>
                            </div>
                            <div class="col-md-6">
                                <span class="sub_headings">Mother Last Name</span>
                                <input class="form-control" type="text" placeholder="LastName"
                                    formControlName="motherlastname" pattern="[a-zA-Z ]*" maxlength="20"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                <div class="error"
                                    *ngIf="(profileForm.controls.motherlastname.touched||profileForm.controls.motherlastname.dirty || submitted)&&profileForm.controls.motherlastname.errors?.required">
                                    Mother Name required</div>
                                <div class="error"
                                    *ngIf="(profileForm.controls.motherlastname.touched||profileForm.controls.motherlastname.dirty || submitted)&&profileForm.controls.motherlastname.errors?.minlength">
                                    Minimum 3 characters required</div>
                                <div class="error"
                                    *ngIf="(profileForm.controls.motherlastname.touched||profileForm.controls.motherlastname.dirty || submitted)&&profileForm.controls.motherlastname.errors?.pattern">
                                    Only letters are allowed</div>
                            </div>
                        </div>
                        <br>
                        <span class="sub_headings">Father Date of Birth</span><span
                            class="float-right text-primary sub_headings_right">Enter
                            Age</span>
                        <input class="form-control" type="date" [min]="patientmindate" [max]="patientmaxdate" placeholder="DD . MMM . YYYY"
                            formControlName="fatherdob" (keydown)="disableDate()">
                        <br>
                        <span class="sub_headings">Mother Date of Birth</span><span
                            class="float-right text-primary sub_headings_right">Enter
                            Age</span>
                        <input class="form-control" type="date" [min]="patientmindate" [max]="patientmaxdate" placeholder="DD . MMM . YYYY"
                            formControlName="motherdob" (keydown)="disableDate()">


                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="row">
                    <div class="col-md-12">
                        <span class="heading">CONTACT DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Phone Number <span style="color:red">*</span></span><br>
                            <input required class="form-control" 
                                placeholder="Contact Number" formControlName="phonenumber" maxlength="10" (keypress)="keyPress($event)" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div class="error" *ngIf="f.phonenumber.errors.required">Phone number is required</div>
                                    <div class="error" *ngIf="f.phonenumber.errors.pattern || f.phonenumber.errors.maxlength || f.phonenumber.errors.minlength">Phone number must be at least 10 numbers</div>
                                </div>
                        </div>
                        <br>
                        <span class="sub_headings">Alternate Number</span><br>
                        <input class="form-control" (keypress)="keyPress($event)" [ngClass]="{ 'is-invalid': submitted && f.alternatenumber.errors }"
                            placeholder="Alternate Number" formControlName="alternatenumber" maxlength="10">
                            <div *ngIf="submitted && f.alternatenumber.errors" class="invalid-feedback">
                                <div class="error" *ngIf="f.alternatenumber.errors.pattern || f.alternatenumber.errors.maxlength || f.alternatenumber.errors.minlength">Alternate number must be at least 10 numbers</div>
                            </div>
                        <br>
                        <span class="sub_headings">Email Address</span>
                        <input class="form-control" type="text" placeholder="Email Address" [class.is-invalid] = "profileForm.get('Emailaddress').invalid && profileForm.get('Emailaddress').touched"
                            formControlName="Emailaddress">
                            <div *ngIf="submitted && f.Emailaddress.errors" class="invalid-feedback">
                                <div class="error" *ngIf="f.Emailaddress.errors.pattern">Email address is Invalid</div>
                            </div>
                        <br>
                        <span class="sub_headings">UHID</span>
                        <input class="form-control" placeholder="UHID" formControlName="UHID" maxlength="10" [ngClass]="{ 'is-invalid': submitted && f.UHID.errors }">
                        <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                            <div class="error" *ngIf="f.UHID.errors.pattern || f.UHID.errors.maxlength || f.UHID.errors.minlength">UHID must be at least 10 numbers</div>
                        </div>
                        <br>
                        <span *ngIf="getGender=='female'">
                        <span class="sub_headings">PICME / RCHID</span>
                        <input class="form-control" placeholder="PICME / RCHID" (input)="onInputChange($event)" formControlName="RCHID" maxlength="20" pattern="[0-9a-zA-Z]*">
                        </span>
                        <br>
                        <span class="sub_headings">ABHA Number</span>
                        <input class="form-control" placeholder="abhanumber" formControlName="abhanumber" readonly>
                        <!-- <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                            <div class="error" *ngIf="f.UHID.errors.pattern || f.UHID.errors.maxlength || f.UHID.errors.minlength">UHID must be at least 10 numbers</div>
                        </div> (keypress)="keyPress($event)" -->
                        <br>
                        <span class="sub_headings">ABHA Address</span>
                        <input class="form-control" placeholder="abhaaddress" formControlName="abhaaddress" readonly>
                        <!-- <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                            <div class="error" *ngIf="f.UHID.errors.pattern || f.UHID.errors.maxlength || f.UHID.errors.minlength">UHID must be at least 10 numbers</div>
                        </div> -->
                         <br>
                        <span class="heading">ADDRESS DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Address</span>
                            <input class="form-control" type="text" (keypress)="omit_special_char($event)"
                                placeholder="Address" formControlName="address">
                        </div>
                        <br>
                        <div>
                            <span class="sub_headings">Region</span>
                            <input class="form-control" type="text" (keypress)="omit_special_char($event)"
                                placeholder="Region" formControlName="region">
                        </div>
                        <br>
                        <span class="sub_headings">City</span>
                        <input class="form-control" type="text" placeholder="City"
                            (keypress)="omit_special_char($event)" formControlName="city">
                        <br>
                        <span class="sub_headings">State</span>
                        <input class="form-control" type="text" placeholder="State"
                            (keypress)="omit_special_char($event)" formControlName="state">
                        <br>
                        <span class="sub_headings">Country</span>
                        <input class="form-control" type="text"
                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123 || event.charCode==32);"
                            placeholder="Country" formControlName="country">
                        <br>
                        <span class="sub_headings">Postal Code</span>
                        <input class="form-control" type="text" placeholder="Code" formControlName="pincode"
                            maxlength="6" pattern="[0-9]*"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                        <div class="error"
                            *ngIf="(profileForm.controls.pincode.touched||profileForm.controls.pincode.dirty || submitted)&&profileForm.controls.pincode.errors?.minlength">
                            Minimum 6 characters required</div>
                        <div class="error"
                            *ngIf="(profileForm.controls.pincode.touched||profileForm.controls.pincode.dirty || submitted)&&profileForm.controls.pincode.errors?.pattern">
                            Only numbers are allowed</div>
                       
                    </div>
                </div>
            </div>

            <div class="col-md-12" style="margin-top: 10px;">
                <div style="padding: 15px;text-align: center;">
                    <button class="btn btn-primary customButton" style="color:#ffff" (click)="cancelDetails()"><i class="fa fa-times" aria-hidden="true" style="color:#fff"></i> Cancel</button>
                    <button class="btn btn-primary customButton" (click)="save_profile()"
                        style="color:#ffff;margin-left: 15px;"><i class="fa fa-save" aria-hidden="true" style="color:#fff"></i> Save</button>

                </div>
            </div>
        </form>
    </div>

    <div *ngIf="!editStatus" class="row" style="width:100%;margin-top: 50px;">
        <form [formGroup]="profileForm" class="row" style="width:100%">

            <div class="col-sm-6 col-md-8 col-lg-6 leftBorder">
                <div class="row">
                    <div class="col-md-6">
                        <div class="avatar-upload">
                            <div class="avatar-preview">
                                <div id="imagePreview" class="profile-image" style="margin-top: 2px;"
                                    [ngStyle]="{'background-image': 'url('+ uploadCoverDrImage + ')'}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <span class="heading">PERSONAL DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Name</span>
                            <div>{{Nme}}</div>
                        </div>
                        <br>
                        <div class="row" style="margin-left: 0px;margin-top: -10px;">
                            <span class="sub_headings">Gender</span>
                            <div class="btn-group btn-group-toggle" data-toggle="buttons"
                                style="margin-top: 32px;margin-left: -40px;">
                                <label class="btn btn-light" style="width:13vw;cursor: none;"
                                    [ngClass]="{'active': (gender==='male')}">
                                    <input disabled type="radio" name="gender" value="male" formControlName="gender">
                                    Male
                                </label>
                                <label class="btn btn-light" style="width:13vw;cursor: none;"
                                    [ngClass]="{'active': (gender==='female')}">
                                    <input disabled type="radio" name="gender" value="female" formControlName="gender">
                                    Female
                                </label>
                            </div>
                        </div>
                        <br>
                        <span class="sub_headings">Date of Birth</span>
                        <div>{{DOB}}</div>
                    </div>

                    <div class="row" style="margin-top: 25px;width: 96%;margin-left: 20px">
                        <div class="col-md-12">
                            <span class="heading">PARENT DETAILS</span>
                            <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="sub_headings">Father First Name</span>
                                    <div *ngIf="fathername!=''">{{fathername}}</div>
                                    <div *ngIf="fathername==''">-</div>
                                </div>
                                <div class="col-md-6">
                                    <span class="sub_headings">Father Last Name</span>
                                    <div *ngIf="fatherlastname!=''">{{fatherlastname}}</div>
                                    <div *ngIf="fatherlastname==''">-</div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="sub_headings">Mother First Name</span>
                                    <div *ngIf="mothername!=''">{{mothername}}</div>
                                    <div *ngIf="mothername==''">-</div>
                                </div>
                                <div class="col-md-6">
                                    <span class="sub_headings">Mother Last Name</span>
                                    <div *ngIf="motherlastname!=''">{{motherlastname}}</div>
                                    <div *ngIf="motherlastname==''">-</div>
                                </div>
                            </div>

                            <br>
                            <span class="sub_headings">Father Date of Birth</span>
                            <div>{{fatherdob}}</div>
                            <div *ngIf="fatherdob==null">-</div>
                            <br>
                            <span class="sub_headings">Mother Date of Birth</span>
                            <div>{{motherdob}}</div>
                            <div *ngIf="motherdob==null">-</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-6">
                <div class="row">
                    <div class="col-md-12">
                        <span class="heading">CONTACT DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Phone Number</span><br>
                            <div>{{phonenumber}}</div>
                        </div>
                        <br>
                        <span class="sub_headings">Alternate Number</span><br>
                        <div>{{alternatenumber}}</div>
                        <div *ngIf="alternatenumber==''">-</div>
                        <br>
                        <span class="sub_headings">Email Address</span>
                        <div>{{Emailaddress}}</div>
                        <div *ngIf="Emailaddress==''">-</div>
                        <br>
                        <span class="sub_headings">UHID</span>
                        <div>{{UHID}}</div>
                        <div *ngIf="UHID==''">-</div>

                        <br>
                        <span *ngIf="getGender=='female'">
                        <span class="sub_headings">PICME / RCHID</span>
                        <div>{{RCHID}}</div>
                        <div *ngIf="RCHID==''">-</div>
                    </span>
                        <br>
                        <span class="sub_headings">ABHA Number</span>
                        <div>{{abhanumber}}</div>
                        <div *ngIf="abhanumber==null">-</div>
                        <br>
                        <span class="sub_headings">ABHA Address</span>
                        <div>{{abhaaddress}}</div>
                        <div *ngIf="abhaaddress==null">-</div>
                        <br>
                         <br>
                        <span class="heading">ADDRESS DETAILS</span>
                        <hr style="margin-top: 6px;border-top: 2px dotted #CBDBF3;">
                        <div>
                            <span class="sub_headings">Address</span>
                            <div>{{street}}</div>
                            <div *ngIf="street==''">-</div>
                        </div>
                        <br>
                        <div>
                            <span class="sub_headings">Region</span>
                            <div>{{region}}</div>
                            <div *ngIf="region==''">-</div>
                        </div>
                        <br>
                        <span class="sub_headings">City</span>
                        <div>{{city}}</div>
                        <div *ngIf="city==''">-</div>
                        <br>
                        <span class="sub_headings">State</span>
                        <div>{{state}}</div>
                        <div *ngIf="state==''">-</div>
                        <br>
                        <span class="sub_headings">Country</span>
                        <div>{{country}}</div>
                        <div *ngIf="state==''">-</div>
                        <br>

                        <span class="sub_headings">Postal Code</span>
                        <div>{{pincode}}</div>
                        <div *ngIf="pincode==''">-</div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>