<div class="container">
    <div class="row" style="margin-top: 1rem;">
        <div class="col-lg-5">
            <div style="display:flex">
                <span style="margin-top: 3px;font-size: x-large;">Date :</span>
                <input style="margin-left: 10px;width: 55%;" type="text"
                ngxDaterangepickerMd
                [locale]="{format: 'DD-MM-YYYY'}"
                [(ngModel)]="selected"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [isInvalidDate] = "isInvalidDate"
                [showClearButton]="true"
                (rangeClicked)="rangeClicked($event)"
                (datesUpdated)="datesUpdated($event)"
                placeholder="Select Date"/>
            </div>  
        </div>
        <div class="col-lg-4" >
            <!-- *ngIf="getreportData.length>0">
            <button class="btn btn-primary" style="width: 48%;" (click)="chooseType(1)">Last Value</button>
            <button class="btn btn-primary" style="width: 48%;margin-left: 5px;" (click)="chooseType(2)">Lastest Value</button> -->
        </div>
        <div class="col-lg-3"style="display:flex;" *ngIf="getreportDatamain.length>0">
            <!-- <div > -->
                <div style="margin-top: 2px;font-size: x-large;"><h4>Download :</h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF('PDF')"><div class="addDrTxt">PDF</div></div>
            <!-- </div> -->
        </div>
    </div>

    <div class="row" *ngIf="getreportDatamain.length==0" style="margin-top: 2rem;">
        <div class="col-lg-12" style="text-align: center;font-size: large;">
            No Data Found
        </div>
    </div>
    
    <div class="row" *ngIf="getreportDatamain.length>0" style="margin-top: 2rem;">
        <div class="col-lg-12" id="mytable1" *ngIf="showType==1" style="width: 100%;overflow-x: scroll;">
            <table class="table tablefix table-bordered" style="margin-top: 20px;" >
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>MR NO</th>
                        <th style="padding: 30px !important;">Name</th>
                        <th style="padding: 30px !important;">DOB</th>
                        <th>Gender</th>
                        <th>Phone Number</th>
                        <th>Father Name</th>
                        <!-- <th>Mother Name</th> -->
                        <th>Registred Date</th>
                        <th>Heart Rate</th>
                        <!-- <th>Respiratory Rate</th> -->
                        <th>Systolic</th>
                        <th>Diastolic</th>
                        <th>Glucometer mmol value</th>
                        <th>Glucometer mgol value</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <!-- <th>Head Circumference</th>
                        <th>Chest Circumference</th>
                        <th>Arm Circumference</th> -->
                        <th>Oxygen Saturation</th>
                        <th>Temperature</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of getreportDatamain;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{d.mr_number}}</td>
                        <td>{{d.name}}</td>
                        <td>{{d.dob|date:'dd-MMM-yyyy'}}</td>
                        <td>{{d.gender}}</td>
                        <td>{{d.phone_number}}</td>
                        <td>{{d.father_name}}</td>
                        <!-- <td>{{d.mother_name}}</td> -->
                        <td>{{d.registred_date|date:'dd-MMM-yyyy'}}</td>
                        <td>{{d.heart_rate}}</td>
                        <!-- <td>{{d.respiratory_rate}}</td> -->
                        <td>{{d.bp_systolic}}</td>
                        <td>{{d.bp_diastolic}}</td>
                        <td><span *ngIf="d.glucometer.length>0">{{d.glucometer[0].glucometer.mmol_value}}</span><span *ngIf="d.glucometer.length==0">0</span></td>
                        <td><span *ngIf="d.glucometer.length>0">{{d.glucometer[0].glucometer.mgol_value}}</span><span *ngIf="d.glucometer.length==0">0</span></td>
                        <td>{{d.height}}</td>
                        <td>{{d.weight}}</td>
                        <!-- <td>{{d.head_circumference}}</td>
                        <td>{{d.chest_circumference}}</td>
                        <td>{{d.arm_circumference}}</td> -->
                        <td>{{d.oxygen_saturation}}</td>
                        <td>{{d.temperature}}</td>
                      </tr>
                    
                </tbody>
            </table>
        </div>

        <!-- <div class="col-lg-12" id="mytable" *ngIf="showType==2" style="width: 100%;overflow-x: scroll;">
            <table class="table tablefix table-bordered" style="margin-top: 20px;" >
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>MR NO</th>
                        <th>Name</th>
                        <th>DOB</th>
                        <th>Gender</th>
                        <th>Phone Number</th>
                        <th>Father Name</th>
                        <th>Mother Name</th>
                        <th>Registred Date</th>
                        <th>Heart Rate</th>
                        <th>Respiratory Rate</th>
                        <th>Systolic</th>
                        <th>Diastolic</th>
                        <th>Glucometer mmol value</th>
                        <th>Glucometer mgol value</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <th>Head Circumference</th>
                        <th>Chest Circumference</th>
                        <th>Arm Circumference</th>
                        <th>Oxygen Saturation</th>
                        <th>Temperature</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of getreportData;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{d.mr_number}}</td>
                        <td>{{d.name}}</td>
                        <td>{{d.dob}}</td>
                        <td>{{d.gender}}</td>
                        <td>{{d.phone_number}}</td>
                        <td>{{d.father_name}}</td>
                        <td>{{d.mother_name}}</td>
                        <td>{{d.registred_date|date:'dd-MMM-yyyy'}}</td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].heart_rate}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].respiratory_rate}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].bp_systolic}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].bp_diastolic}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0&&d.latest_vital_record.glucometer!=null&&d.latest_vital_record.glucometer!=''">{{d.latest_vital_record[0].glucometer.mmol_value}}</span><span *ngIf="d.latest_vital_record.length==0||d.latest_vital_record==null||d.latest_vital_record==''">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0&&d.latest_vital_record.glucometer!=null&&d.latest_vital_record.glucometer!=''">{{d.latest_vital_record[0].glucometer.mgol_value}}</span><span *ngIf="d.latest_vital_record.length==0||d.latest_vital_record==null||d.latest_vital_record==''">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].height}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].weight}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].head_circumference}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].chest_circumference}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].arm_circumference}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].oxygen_saturation}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                        <td><span *ngIf="d.latest_vital_record.length>0">{{d.latest_vital_record[0].temperature}}</span><span *ngIf="d.latest_vital_record.length==0">0</span></td>
                      </tr>
                    
                </tbody>
            </table>
        </div> -->
    </div>

    <div class="row" *ngIf="getreportDatamain.length>0" style="margin-top: 2rem;opacity: 0;">
        <div class="col-lg-12" id="mytable" *ngIf="showType==1" style="width: 100%;overflow-x: scroll;">
            <table class="table tablefix table-bordered" style="margin-top: 20px;" >
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th *ngIf="mrNO">MR NO</th>
                        <th *ngIf="Name" style="padding: 30px !important;">Name</th>
                        <th *ngIf="dob" style="padding: 30px !important;">DOB</th>
                        <th *ngIf="gender">Gender</th>
                        <th *ngIf="phNo">Phone Number</th>
                        <th *ngIf="height">Height</th>
                        <th *ngIf="weight">Weight</th>
                        <th *ngIf="Father">Father Name</th>
                        <!-- <th>Mother Name</th> -->
                        <th *ngIf="rDate">Registred Date</th>
                        <th *ngIf="heart">Heart Rate</th>
                        <!-- <th>Respiratory Rate</th> -->
                        <th *ngIf="sys">Systolic</th>
                        <th *ngIf="dia">Diastolic</th>
                        <th *ngIf="mmol">Glucometer mmol value</th>
                        <th *ngIf="mgol">Glucometer mgol value</th>
                        <!-- <th>Head Circumference</th>
                        <th>Chest Circumference</th>
                        <th>Arm Circumference</th> -->
                        <th *ngIf="oxy">Oxygen Saturation</th>
                        <th *ngIf="tem">Temperature</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let d of getreportDatamain;let i=index">
                        <td>{{i+1}}</td>
                        <td *ngIf="mrNO">{{d.mr_number}}</td>
                        <td *ngIf="Name">{{d.name}}</td>
                        <td *ngIf="dob">{{d.dob|date:'dd-MMM-yyyy'}}</td>
                        <td *ngIf="gender">{{d.gender}}</td>
                        <td *ngIf="phNo">{{d.phone_number}}</td>
                        <td *ngIf="height">{{d.height}}</td>
                        <td *ngIf="weight">{{d.weight}}</td>
                        <td *ngIf="Father">{{d.father_name}}</td>
                        <!-- <td>{{d.mother_name}}</td> -->
                        <td *ngIf="rDate">{{d.registred_date|date:'dd-MMM-yyyy'}}</td>
                        <td *ngIf="heart">{{d.heart_rate}}</td>
                        <!-- <td>{{d.respiratory_rate}}</td> -->
                        <td *ngIf="sys">{{d.bp_systolic}}</td>
                        <td *ngIf="dia">{{d.bp_diastolic}}</td>
                        <td *ngIf="mmol"><span *ngIf="d.glucometer.length>0">{{d.glucometer[0].glucometer.mmol_value}}</span><span *ngIf="d.glucometer.length==0">0</span></td>
                        <td *ngIf="mgol"><span *ngIf="d.glucometer.length>0">{{d.glucometer[0].glucometer.mgol_value}}</span><span *ngIf="d.glucometer.length==0">0</span></td>
                        <!-- <td>{{d.head_circumference}}</td>
                        <td>{{d.chest_circumference}}</td>
                        <td>{{d.arm_circumference}}</td> -->
                        <td *ngIf="oxy">{{d.oxygen_saturation}}</td>
                        <td *ngIf="tem">{{d.temperature}}</td>
                      </tr>
                    
                </tbody>
            </table>
        </div>

    </div>

</div>