<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-6">
            <h2> <img src="./assets/images/XMLID_561_/XMLID_561_.png" class="imgcustom"> <span class="headerClr"> Wallet Transaction Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-right: -20px;margin-top:25px;">
        <div class="col-md-8">  
            <div style="display:flex">
            <span style="margin-top: 3px;">Date :</span>
            <input style="margin-left: 10px;" type="text"
            ngxDaterangepickerMd
            [locale]="{format: 'DD-MM-YYYY'}"
            [(ngModel)]="selected"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="true"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            placeholder="Select Date"/>
        </div>   
        </div>
        <div class="col-md-4">
            <div style="display:flex;float: right;margin-right:10px;margin-top:-5px">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr />
    <div style="text-align:center;color:red" *ngIf="getWalletTransList.length == 0">Data is not available......</div>

    <div id="mytable" *ngIf="getWalletTransList.length != 0">
        <div class="row">
            <div class="col-md-12">
                <div style="text-align: center;font-weight: bold;"><label><h4 style="font-weight: 600;">{{hos_name}}</h4></label></div>
                <div style="text-align: center;font-weight: bold;"><label><h4 style="font-weight: 600;">Wallet Transaction Report/Statement</h4></label></div>
            </div>
            <table style="width: 100%;">
                <tr>
                    <th style="width:30%">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="text-align: center;background-color: #E7E9EB;">Period of Transaction</th>
                                </tr>
                            </thead>
                            <tbody  style="text-align: center;">
                                <tr>
                                    <td style="font-weight: normal;">{{startdateInFormat}} to {{enddateInFormat}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </th>
                    <th style="width:40%">
                    
                    </th>
                    <th style="width:30%">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="text-align: center;background-color: #E7E9EB;">Generated On</th>
                                </tr>
                            </thead>
                            <tbody  style="text-align: center;">
                                <tr>
                                    <td style="font-weight: normal;">{{todayDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </th>
                </tr>
            </table>
            <table style="width: 100%;">
                <th style="width:35%"></th>
                <th style="width:35%"></th>
                <th style="width:30%;font-weight: normal;">Generated By: {{dr_name}}</th>
            </table>
        </div>
        <hr style="border-top:3px dotted rgba(0,0,0,.1) "/>
        <div class="row">
            <div class="col-md-12">
                <h4 style="font-weight: 500;">Wallet Transaction Overview</h4>
            </div>

            <table style="width: 100%;">
                <tr>
                    <th style="width:35%">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="text-align: center;background-color: #E7E9EB;">Gross Wallet Credit</th>
                                    <th style="text-align: center;font-weight: normal;">{{getWalletList.total_credit | currency:"&#8377;&nbsp;"}}</th>
                                </tr>
                            </thead>
                        </table>
                    </th>
                    <th style="width:35%"></th>
                    <th style="width:30%">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="text-align: center;background-color: #E7E9EB;">Gross Wallet Debit</th>
                                    <th style="text-align: center;font-weight: normal;">{{getWalletList.total_debit | currency:"&#8377;&nbsp;"}}</th>
                                </tr>
                            </thead>
                        </table>
                    </th>
                </tr>
            </table>
        </div>

        <div class="row">
            <table style="width: 100%;">
                <tr>
                    <th style="width:35%;">
                        <table class="table table-bordered" style="margin-top: -6rem;">
                            <tbody  style="text-align: left;">
                                <tr>
                                    <td style="background-color: #E7E9EB;">Payment Gateway</td>
                                    <td style="font-weight: normal;">{{getWalletList.total_gateway | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">Front Desk</td>
                                    <td style="font-weight: normal;">{{getWalletList.total_fd_credit | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">Refunds</td>
                                    <td style="font-weight: normal;">{{getWalletList.total_refund | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </th>
                    <th style="width:35%">
                        
                    </th>
                    <th style="width:30%">
                        <table class="table table-bordered">
                            <tbody  style="text-align: left;">
                                <tr>
                                    <td style="background-color: #E7E9EB;">FrontDesk Deduction</td>
                                    <td style="font-weight: normal;">{{getWalletList.fd_subscription | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">MH Deductions</td>
                                    <td style="font-weight: normal;">{{getWalletList.mh_debit | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">MH Subscription</td>
                                    <td style="font-weight: normal;">{{getWalletList.mh_subscription | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">eshop Payments</td>
                                    <td style="font-weight: normal;">{{getWalletList.total_eshop_debit | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">EC Deductions</td>
                                    <td style="font-weight: normal;">{{getWalletList.kc_debit | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                <tr>
                                    <td style="background-color: #E7E9EB;">KCE Deductions</td>
                                    <td style="font-weight: normal;">{{getWalletList.kce_debit | currency:"&#8377;&nbsp;"}}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </th>
                    
                </tr>
            </table>
        </div>
        <hr style="border-top:3px dotted rgba(0,0,0,.1) " />

        <div class="row">
            <div class="col-md-12">
                <h4 style="font-weight: 500;">Wallet Transaction Summary</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-bordered" style="table-layout: fixed;">
                    <thead>
                        <tr style="background-color: #E7E9EB;">
                            <th style="text-align: center;">S.No</th>
                            <th style="text-align: center;">Date</th>
                            <th style="text-align: center;width:20%">Description</th> 
                            <th style="text-align: center;">MR Id</th>
                            <th style="text-align: center;">Patient Name</th>
                            <th style="text-align: center;width:10%">Contact Number</th>
                            <th style="text-align: center;width:16%">Reference No</th>
                            <th style="text-align: center;">Opening Balance</th>
                            <th style="text-align: center;">Debit</th>
                            <th style="text-align: center;">Credit</th>
                            <th style="text-align: center;">Closing Balance</th>
                            <!-- <th style="text-align: center;">Transaction Type</th> -->
                        </tr>                                 
                    </thead>
                    <tbody>
                        <tr *ngFor="let WalletTrans of getWalletTransList;let i=index;" style="border: 1px solid #E7E9EB;;">
                            <td style="text-align: center;">{{i+1}}</td>
                            <td style="text-align: center;word-break: break-all;">{{WalletTrans.date}}<br>{{WalletTrans.timestamp | date:'h:mma'}}</td>
                            <td style="text-align:center;word-break: break-all;width:20%">{{WalletTrans.message}}</td>
                            <td style="text-align: center;">{{WalletTrans.mrid}}</td>
                            <td style="text-align: center;word-break: break-all;">{{WalletTrans.patient_name}}</td>
                            <td style="text-align: center;word-break: break-all;width:10%">{{WalletTrans.mobile}}</td>
                            <td style="text-align:center;word-break: break-all;width:16%">{{WalletTrans.bill_no}}</td>
                            <td style="text-align: center;">&#8377;&nbsp; {{WalletTrans.opening_balance}}</td>
                            <td style="text-align: center;">&#8377;&nbsp; {{WalletTrans.debit}}</td>
                            <td style="text-align: center;">&#8377;&nbsp; {{WalletTrans.credit}}</td>
                            <td style="text-align: center;">&#8377;&nbsp; {{WalletTrans.closing_balance}}</td>
                            <!-- <td>{{WalletTrans.trans_name}}</td> -->
                        </tr>    
                    </tbody>                          
                </table>
            </div>
        </div>
    </div>

    <!-- <div id="mytable" *ngIf="getWalletTransList.length != 0">
    <div class="row">
        <div class="col-md-12">
            <div style="text-align: center;font-weight: bold;"><label><h4>Wallet Transaction Report</h4></label></div>
        </div>
        <div class="col-md-8">
            <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label>
        </div>
        <div class="col-md-4">
            <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
        </div>   
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5>Consolidated:</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th style="text-align: center;">Total Debit</th>
                        <th style="text-align: center;">Total Credit</th>
                        <th style="text-align: center;">Total Refund</th>
                        <th style="text-align: center;">Total Gateway</th>
                        <th style="text-align: center;">Total FD credit</th>
                        <th style="text-align: center;">Total eshop Debit</th>
                        <th style="text-align: center;">FD Subscription</th>
                        <th style="text-align: center;">MH Subscription</th>
                        <th style="text-align: center;">MH Debit</th>
                        <th style="text-align: center;">KC Debit</th>
                        <th style="text-align: center;">KCE Debit</th>
                    </tr>
                </thead>
                <tbody  style="text-align: center;" ng-hide="chartHide">
                    <tr>
                        <td>{{getWalletList.total_debit | currency:"&#8377;&nbsp;"}}</td>
                        <td>{{getWalletList.total_credit | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.total_refund | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.total_gateway | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.total_fd_credit | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.total_eshop_debit | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.fd_subscription | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.mh_subscription | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.mh_debit | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.kc_debit | currency:"&#8377;&nbsp;"}}</td> 
                        <td>{{getWalletList.kce_debit | currency:"&#8377;&nbsp;"}}</td> 
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5>Break Up:</h5>
            <table class="table table-bordered" style="table-layout: fixed;">
                <thead>
                    <tr>
                        <th style="text-align: center;">S.No</th>
                        <th style="text-align: center;">MR Id</th>
                        <th style="text-align: center;">Child Name</th>
                        <th style="text-align: center;">Mobile</th>
                        <th style="text-align: center;">Bill No</th>
                        <th style="text-align: center;">Date</th>
                        <th style="text-align: center;">Debit</th>
                        <th style="text-align: center;">Opening Balance</th>
                        <th style="text-align: center;">Closing Balance</th>
                        <th style="text-align: center;">Credit</th>
                        <th style="text-align: center;">Transaction Type</th>
                        <th style="text-align: center; display: none;">Transaction Type value</th> 
                        <th style="text-align: center;">Message</th>      
                    </tr>                                 
                </thead>
                <tbody style="text-align: center;">
                    <tr *ngFor="let WalletTrans of getWalletTransList;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{WalletTrans.mrid}}</td>
                        <td>{{WalletTrans.patient_name}}</td>
                        <td>{{WalletTrans.mobile}}</td>
                        <td style="text-align:center;word-break: break-all;">{{WalletTrans.bill_no}}</td>
                        <td style="word-break: break-all;">{{WalletTrans.date}}<br>{{WalletTrans.timestamp | date:'h:mma'}}</td>
                        <td>{{WalletTrans.debit | currency:"&#8377;&nbsp;"}}</td>
                        <td>{{WalletTrans.opening_balance | currency:"&#8377;&nbsp;"}}</td>
                        <td>{{WalletTrans.closing_balance | currency:"&#8377;&nbsp;"}}</td>
                        <td>{{WalletTrans.credit | currency:"&#8377;&nbsp;"}}</td>
                        <td>{{WalletTrans.trans_name}}</td>
                        <td style="display: none;">{{WalletTrans.trans_type}}</td>
                        <td style="text-align:center;word-break: break-all;">{{WalletTrans.message}}</td>
                    </tr>    
                </tbody>                          
            </table>
        </div>
    </div>
    </div> -->
  </div>
</div>

