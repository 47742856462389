import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-wlhc-male-print',
  templateUrl: './wlhc-male-print.component.html',
  styleUrls: ['./wlhc-male-print.component.scss']
})
export class WlhcMalePrintComponent implements OnInit {

  @Input() chartData;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
   
    this.drawChart(this.chartData)
  }

  drawChart(data) {
    var vitalData: any = data

    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 1000 - margin.top - margin.bottom;
    var width = 870 - margin.left - margin.right;

    var heightData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.height.length; i++) {
        if (vitalData.height[i].x < 61) {
          heightData.push(vitalData.height[i]);
        }
      }
    }

    var weightData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.weight.length; i++) {
        if (vitalData.weight[i].x < 61) {
          weightData.push(vitalData.weight[i]);
        }
      }
    }

    var headData = [];

    if (vitalData.height) {
      for (var i = 0; i < vitalData.hc.length; i++) {
        if (vitalData.hc[i].x < 61) {
          headData.push(vitalData.hc[i]);
        }
      }
    }

    var set1 = [{ x: 0, y: 2.3 }, { x: 1, y: 3.2 }, { x: 2, y: 4.1 }, { x: 3, y: 4.8 }, { x: 4, y: 5.4 }, { x: 5, y: 5.8 }, { x: 6, y: 6.1 }, { x: 7, y: 6.4 }, { x: 8, y: 6.7 },
    { x: 9, y: 6.9 }, { x: 10, y: 7.1 }, { x: 11, y: 7.3 }, { x: 12, y: 7.5 }, { x: 13, y: 7.6 }, { x: 14, y: 7.8 }, { x: 15, y: 8 }, { x: 16, y: 8.1 },
    { x: 17, y: 8.3 }, { x: 18, y: 8.4 }, { x: 19, y: 8.6 }, { x: 20, y: 8.7 }, { x: 21, y: 8.9 }, { x: 22, y: 9 }, { x: 23, y: 9.2 }, { x: 24, y: 9.3 },
    { x: 25, y: 9.5 }, { x: 26, y: 9.6 }, { x: 27, y: 9.7 }, { x: 28, y: 9.9 }, { x: 29, y: 10 }, { x: 30, y: 10.1 }, { x: 31, y: 10.3 }, { x: 32, y: 10.4 },
    { x: 33, y: 10.5 }, { x: 34, y: 10.6 }, { x: 35, y: 10.7 }, { x: 36, y: 10.8 }, { x: 37, y: 11 }, { x: 38, y: 11.1 }, { x: 39, y: 11.2 }, { x: 40, y: 11.3 },
    { x: 41, y: 11.4 }, { x: 42, y: 11.5 }, { x: 43, y: 11.7 }, { x: 44, y: 11.8 }, { x: 45, y: 11.9 }, { x: 46, y: 12 }, { x: 47, y: 12.1 }, { x: 48, y: 12.2 },
    { x: 49, y: 12.3 }, { x: 50, y: 12.4 }, { x: 51, y: 12.5 }, { x: 52, y: 12.6 }, { x: 53, y: 12.7 }, { x: 54, y: 12.9 }, { x: 55, y: 13 }, { x: 56, y: 13.1 },
    { x: 57, y: 13.2 }, { x: 58, y: 13.3 }, { x: 59, y: 13.4 }, { x: 60, y: 13.5 }];

    var set2 = [{ x: 0, y: 2.5 }, { x: 1, y: 3.4 }, { x: 2, y: 4.4 }, { x: 3, y: 5.1 }, { x: 4, y: 5.6 }, { x: 5, y: 6.1 }, { x: 6, y: 6.4 }, { x: 7, y: 6.7 }, { x: 8, y: 7 },
    { x: 9, y: 7.2 }, { x: 10, y: 7.5 }, { x: 11, y: 7.7 }, { x: 12, y: 7.8 }, { x: 13, y: 8 }, { x: 14, y: 8.2 }, { x: 15, y: 8.4 }, { x: 16, y: 8.5 },
    { x: 17, y: 8.7 }, { x: 18, y: 8.9 }, { x: 19, y: 9 }, { x: 20, y: 9.2 }, { x: 21, y: 9.3 }, { x: 22, y: 9.5 }, { x: 23, y: 9.7 }, { x: 24, y: 9.8 },
    { x: 25, y: 10 }, { x: 26, y: 10.1 }, { x: 27, y: 10.2 }, { x: 28, y: 10.4 }, { x: 29, y: 10.5 }, { x: 30, y: 10.7 }, { x: 31, y: 10.8 }, { x: 32, y: 10.9 },
    { x: 33, y: 11.1 }, { x: 34, y: 11.2 }, { x: 35, y: 11.3 }, { x: 36, y: 11.4 }, { x: 37, y: 11.6 }, { x: 38, y: 11.7 }, { x: 39, y: 11.8 }, { x: 40, y: 11.9 },
    { x: 41, y: 12.1 }, { x: 42, y: 12.2 }, { x: 43, y: 12.3 }, { x: 44, y: 12.4 }, { x: 45, y: 12.5 }, { x: 46, y: 12.7 }, { x: 47, y: 12.8 }, { x: 48, y: 12.9 },
    { x: 49, y: 13 }, { x: 50, y: 13.1 }, { x: 51, y: 13.3 }, { x: 52, y: 13.4 }, { x: 53, y: 13.5 }, { x: 54, y: 13.6 }, { x: 55, y: 13.7 }, { x: 56, y: 13.8 },
    { x: 57, y: 13.9 }, { x: 58, y: 14.1 }, { x: 59, y: 14.2 }, { x: 60, y: 14.3 }];

    var set3 = [{ x: 0, y: 3.3 }, { x: 1, y: 4.5 }, { x: 2, y: 5.6 }, { x: 3, y: 6.4 }, { x: 4, y: 7 }, { x: 5, y: 7.5 }, { x: 6, y: 7.9 }, { x: 7, y: 8.3 }, { x: 8, y: 8.6 },
    { x: 9, y: 8.9 }, { x: 10, y: 9.2 }, { x: 11, y: 9.4 }, { x: 12, y: 9.6 }, { x: 13, y: 9.9 }, { x: 14, y: 10.1 }, { x: 15, y: 10.3 }, { x: 16, y: 10.5 },
    { x: 17, y: 10.7 }, { x: 18, y: 10.9 }, { x: 19, y: 11.1 }, { x: 20, y: 11.3 }, { x: 21, y: 11.5 }, { x: 22, y: 11.8 }, { x: 23, y: 12 }, { x: 24, y: 12.2 },
    { x: 25, y: 12.4 }, { x: 26, y: 12.5 }, { x: 27, y: 12.7 }, { x: 28, y: 12.9 }, { x: 29, y: 13.1 }, { x: 30, y: 13.3 }, { x: 31, y: 13.5 }, { x: 32, y: 13.7 },
    { x: 33, y: 13.8 }, { x: 34, y: 14 }, { x: 35, y: 14.2 }, { x: 36, y: 14.3 }, { x: 37, y: 14.5 }, { x: 38, y: 14.7 }, { x: 39, y: 14.8 }, { x: 40, y: 15 },
    { x: 41, y: 15.2 }, { x: 42, y: 15.3 }, { x: 43, y: 15.5 }, { x: 44, y: 15.7 }, { x: 45, y: 15.8 }, { x: 46, y: 16 }, { x: 47, y: 16.2 }, { x: 48, y: 16.3 },
    { x: 49, y: 16.5 }, { x: 50, y: 16.7 }, { x: 51, y: 16.8 }, { x: 52, y: 17 }, { x: 53, y: 17.2 }, { x: 54, y: 17.3 }, { x: 55, y: 17.5 }, { x: 56, y: 17.7 },
    { x: 57, y: 17.8 }, { x: 58, y: 18 }, { x: 59, y: 18.2 }, { x: 60, y: 18.3 }];

    var set4 = [{ x: 0, y: 4.3 }, { x: 1, y: 5.7 }, { x: 2, y: 7 }, { x: 3, y: 7.9 }, { x: 4, y: 8.6 }, { x: 5, y: 9.2 }, { x: 6, y: 9.7 }, { x: 7, y: 10.2 }, { x: 8, y: 10.5 },
    { x: 9, y: 10.9 }, { x: 10, y: 11.2 }, { x: 11, y: 11.5 }, { x: 12, y: 11.8 }, { x: 13, y: 12.1 }, { x: 14, y: 12.4 }, { x: 15, y: 12.7 }, { x: 16, y: 12.9 },
    { x: 17, y: 13.2 }, { x: 18, y: 13.5 }, { x: 19, y: 13.7 }, { x: 20, y: 14 }, { x: 21, y: 14.3 }, { x: 22, y: 14.5 }, { x: 23, y: 14.8 }, { x: 24, y: 15.1 },
    { x: 25, y: 15.3 }, { x: 26, y: 15.6 }, { x: 27, y: 15.9 }, { x: 28, y: 16.1 }, { x: 29, y: 16.4 }, { x: 30, y: 16.6 }, { x: 31, y: 16.9 }, { x: 32, y: 17.1 },
    { x: 33, y: 17.3 }, { x: 34, y: 17.6 }, { x: 35, y: 17.8 }, { x: 36, y: 18 }, { x: 37, y: 18.3 }, { x: 38, y: 18.5 }, { x: 39, y: 18.7 }, { x: 40, y: 19 },
    { x: 41, y: 19.2 }, { x: 42, y: 19.4 }, { x: 43, y: 19.7 }, { x: 44, y: 19.9 }, { x: 45, y: 20.1 }, { x: 46, y: 20.4 }, { x: 47, y: 20.6 }, { x: 48, y: 20.9 },
    { x: 49, y: 21.1 }, { x: 50, y: 21.3 }, { x: 51, y: 21.6 }, { x: 52, y: 21.8 }, { x: 53, y: 22.1 }, { x: 54, y: 22.3 }, { x: 55, y: 22.5 }, { x: 56, y: 22.8 },
    { x: 57, y: 23 }, { x: 58, y: 23.3 }, { x: 59, y: 23.5 }, { x: 60, y: 23.8 }];
    // End of Weight data

    // Start of Head data
    var set5 = [{ x: 0, y: 32.1 }, { x: 1, y: 35.1 }, { x: 2, y: 36.9 }, { x: 3, y: 38.3 }, { x: 4, y: 39.4 }, { x: 5, y: 40.3 }, { x: 6, y: 41 }, { x: 7, y: 41.7 }, { x: 8, y: 42.2 },
    { x: 9, y: 42.6 }, { x: 10, y: 43 }, { x: 11, y: 43.4 }, { x: 12, y: 43.6 }, { x: 13, y: 43.9 }, { x: 14, y: 44.1 }, { x: 15, y: 44.3 }, { x: 16, y: 44.5 },
    { x: 17, y: 44.7 }, { x: 18, y: 44.9 }, { x: 19, y: 45 }, { x: 20, y: 45.2 }, { x: 21, y: 45.3 }, { x: 22, y: 45.4 }, { x: 23, y: 45.6 }, { x: 24, y: 45.7 },
    { x: 25, y: 45.8 }, { x: 26, y: 45.9 }, { x: 27, y: 46 }, { x: 28, y: 46.1 }, { x: 29, y: 46.2 }, { x: 30, y: 46.3 }, { x: 31, y: 46.4 }, { x: 32, y: 46.5 },
    { x: 33, y: 46.6 }, { x: 34, y: 46.6 }, { x: 35, y: 46.7 }, { x: 36, y: 46.8 }, { x: 37, y: 46.9 }, { x: 38, y: 46.9 }, { x: 39, y: 47 }, { x: 40, y: 47 },
    { x: 41, y: 47.1 }, { x: 42, y: 47.2 }, { x: 43, y: 47.2 }, { x: 44, y: 47.3 }, { x: 45, y: 47.3 }, { x: 46, y: 47.4 }, { x: 47, y: 47.4 }, { x: 48, y: 47.5 },
    { x: 49, y: 47.5 }, { x: 50, y: 47.5 }, { x: 51, y: 47.6 }, { x: 52, y: 47.6 }, { x: 53, y: 47.7 }, { x: 54, y: 47.7 }, { x: 55, y: 47.7 }, { x: 56, y: 47.8 },
    { x: 57, y: 47.8 }, { x: 58, y: 47.9 }, { x: 59, y: 47.9 }, { x: 60, y: 47.9 }];

    var set6 = [{ x: 0, y: 34.5 }, { x: 1, y: 37.3 }, { x: 2, y: 39.1 }, { x: 3, y: 40.5 }, { x: 4, y: 41.6 }, { x: 5, y: 42.6 }, { x: 6, y: 43.3 }, { x: 7, y: 44 }, { x: 8, y: 44.5 },
    { x: 9, y: 45 }, { x: 10, y: 45.4 }, { x: 11, y: 45.8 }, { x: 12, y: 46.1 }, { x: 13, y: 46.3 }, { x: 14, y: 46.6 }, { x: 15, y: 46.8 }, { x: 16, y: 47 },
    { x: 17, y: 47.2 }, { x: 18, y: 47.4 }, { x: 19, y: 47.5 }, { x: 20, y: 47.7 }, { x: 21, y: 47.8 }, { x: 22, y: 48 }, { x: 23, y: 48.1 }, { x: 24, y: 48.3 },
    { x: 25, y: 48.4 }, { x: 26, y: 48.5 }, { x: 27, y: 48.6 }, { x: 28, y: 48.7 }, { x: 29, y: 48.8 }, { x: 30, y: 48.9 }, { x: 31, y: 49 }, { x: 32, y: 49.1 },
    { x: 33, y: 49.2 }, { x: 34, y: 49.3 }, { x: 35, y: 49.4 }, { x: 36, y: 49.5 }, { x: 37, y: 49.5 }, { x: 38, y: 49.6 }, { x: 39, y: 49.7 }, { x: 40, y: 49.7 },
    { x: 41, y: 49.8 }, { x: 42, y: 49.9 }, { x: 43, y: 49.9 }, { x: 44, y: 50 }, { x: 45, y: 50.1 }, { x: 46, y: 50.1 }, { x: 47, y: 50.2 }, { x: 48, y: 50.2 },
    { x: 49, y: 50.3 }, { x: 50, y: 50.3 }, { x: 51, y: 50.4 }, { x: 52, y: 50.4 }, { x: 53, y: 50.4 }, { x: 54, y: 50.5 }, { x: 55, y: 50.5 }, { x: 56, y: 50.6 },
    { x: 57, y: 50.6 }, { x: 58, y: 50.7 }, { x: 59, y: 50.7 }, { x: 60, y: 50.7 }];

    var set7 = [{ x: 0, y: 36.9 }, { x: 1, y: 39.5 }, { x: 2, y: 41.3 }, { x: 3, y: 42.7 }, { x: 4, y: 43.9 }, { x: 5, y: 44.8 }, { x: 6, y: 45.6 }, { x: 7, y: 46.3 }, { x: 8, y: 46.9 },
    { x: 9, y: 47.4 }, { x: 10, y: 47.8 }, { x: 11, y: 48.2 }, { x: 12, y: 48.5 }, { x: 13, y: 48.8 }, { x: 14, y: 49 }, { x: 15, y: 49.3 }, { x: 16, y: 49.5 },
    { x: 17, y: 49.7 }, { x: 18, y: 49.9 }, { x: 19, y: 50 }, { x: 20, y: 50.2 }, { x: 21, y: 50.4 }, { x: 22, y: 50.5 }, { x: 23, y: 50.7 }, { x: 24, y: 50.8 },
    { x: 25, y: 50.9 }, { x: 26, y: 51.1 }, { x: 27, y: 51.2 }, { x: 28, y: 51.3 }, { x: 29, y: 51.4 }, { x: 30, y: 51.6 }, { x: 31, y: 51.7 }, { x: 32, y: 51.8 },
    { x: 33, y: 51.9 }, { x: 34, y: 52 }, { x: 35, y: 52 }, { x: 36, y: 52.1 }, { x: 37, y: 52.2 }, { x: 38, y: 52.3 }, { x: 39, y: 52.4 }, { x: 40, y: 52.4 },
    { x: 41, y: 52.5 }, { x: 42, y: 52.6 }, { x: 43, y: 52.7 }, { x: 44, y: 52.7 }, { x: 45, y: 52.8 }, { x: 46, y: 52.8 }, { x: 47, y: 52.9 }, { x: 48, y: 53 },
    { x: 49, y: 53 }, { x: 50, y: 53.1 }, { x: 51, y: 53.1 }, { x: 52, y: 53.2 }, { x: 53, y: 53.2 }, { x: 54, y: 53.3 }, { x: 55, y: 53.3 }, { x: 56, y: 53.4 },
    { x: 57, y: 53.4 }, { x: 58, y: 53.5 }, { x: 59, y: 53.5 }, { x: 60, y: 53.5 }];
    // End of Head data

    // Start of Height/Length data
    var set8 = [{ x: 0, y: 45.5 }, { x: 1, y: 50.2 }, { x: 2, y: 53.8 }, { x: 3, y: 56.7 }, { x: 4, y: 59 }, { x: 5, y: 61 }, { x: 6, y: 62.6 }, { x: 7, y: 64.1 }, { x: 8, y: 65.5 },
    { x: 9, y: 66.8 }, { x: 10, y: 68 }, { x: 11, y: 69.1 }, { x: 12, y: 70.2 }, { x: 13, y: 71.3 }, { x: 14, y: 72.3 }, { x: 15, y: 73.3 }, { x: 16, y: 74.2 },
    { x: 17, y: 75.1 }, { x: 18, y: 76 }, { x: 19, y: 76.8 }, { x: 20, y: 77.7 }, { x: 21, y: 78.4 }, { x: 22, y: 79.2 }, { x: 23, y: 80 }, { x: 24, y: 80.7 },
    { x: 25, y: 80.7 }, { x: 26, y: 81.4 }, { x: 27, y: 82.1 }, { x: 28, y: 82.8 }, { x: 29, y: 83.4 }, { x: 30, y: 84 }, { x: 31, y: 84.6 }, { x: 32, y: 85.2 },
    { x: 33, y: 85.8 }, { x: 34, y: 86.4 }, { x: 35, y: 86.9 }, { x: 36, y: 87.5 }, { x: 37, y: 88 }, { x: 38, y: 88.5 }, { x: 39, y: 89.1 }, { x: 40, y: 89.6 },
    { x: 41, y: 90.1 }, { x: 42, y: 90.6 }, { x: 43, y: 91.1 }, { x: 44, y: 91.6 }, { x: 45, y: 92.1 }, { x: 46, y: 92.6 }, { x: 47, y: 93.1 }, { x: 48, y: 93.6 },
    { x: 49, y: 94 }, { x: 50, y: 94.5 }, { x: 51, y: 95 }, { x: 52, y: 95.5 }, { x: 53, y: 95.9 }, { x: 54, y: 96.4 }, { x: 55, y: 96.9 }, { x: 56, y: 97.3 },
    { x: 57, y: 97.8 }, { x: 58, y: 98.3 }, { x: 59, y: 98.7 }, { x: 60, y: 99.2 }];

    var set9 = [{ x: 0, y: 46.3 }, { x: 1, y: 51.1 }, { x: 2, y: 54.7 }, { x: 3, y: 57.6 }, { x: 4, y: 60 }, { x: 5, y: 61.9 }, { x: 6, y: 63.6 }, { x: 7, y: 65.1 }, { x: 8, y: 66.5 },
    { x: 9, y: 67.7 }, { x: 10, y: 69 }, { x: 11, y: 70.2 }, { x: 12, y: 71.3 }, { x: 13, y: 72.4 }, { x: 14, y: 73.4 }, { x: 15, y: 74.4 }, { x: 16, y: 75.4 },
    { x: 17, y: 76.3 }, { x: 18, y: 77.2 }, { x: 19, y: 78.1 }, { x: 20, y: 78.9 }, { x: 21, y: 79.7 }, { x: 22, y: 80.5 }, { x: 23, y: 81.3 }, { x: 24, y: 82.1 },
    { x: 25, y: 82.1 }, { x: 26, y: 82.8 }, { x: 27, y: 83.5 }, { x: 28, y: 84.2 }, { x: 29, y: 84.9 }, { x: 30, y: 85.5 }, { x: 31, y: 86.2 }, { x: 32, y: 86.8 },
    { x: 33, y: 87.4 }, { x: 34, y: 88 }, { x: 35, y: 88.5 }, { x: 36, y: 89.1 }, { x: 37, y: 89.7 }, { x: 38, y: 90.2 }, { x: 39, y: 90.8 }, { x: 40, y: 91.3 },
    { x: 41, y: 91.9 }, { x: 42, y: 92.4 }, { x: 43, y: 92.9 }, { x: 44, y: 93.4 }, { x: 45, y: 93.9 }, { x: 46, y: 94.4 }, { x: 47, y: 94.9 }, { x: 48, y: 95.4 },
    { x: 49, y: 95.9 }, { x: 50, y: 96.4 }, { x: 51, y: 96.9 }, { x: 52, y: 97.4 }, { x: 53, y: 97.9 }, { x: 54, y: 98.4 }, { x: 55, y: 98.8 }, { x: 56, y: 99.3 },
    { x: 57, y: 99.8 }, { x: 58, y: 100.3 }, { x: 59, y: 100.8 }, { x: 60, y: 101.2 }];

    var set10 = [{ x: 0, y: 49.9 }, { x: 1, y: 54.7 }, { x: 2, y: 58.4 }, { x: 3, y: 61.4 }, { x: 4, y: 63.9 }, { x: 5, y: 65.9 }, { x: 6, y: 67.6 }, { x: 7, y: 69.2 }, { x: 8, y: 70.6 },
    { x: 9, y: 72 }, { x: 10, y: 73.3 }, { x: 11, y: 74.5 }, { x: 12, y: 75.7 }, { x: 13, y: 76.9 }, { x: 14, y: 78 }, { x: 15, y: 79.1 }, { x: 16, y: 80.2 }, { x: 17, y: 81.2 },
    { x: 18, y: 82.3 }, { x: 19, y: 83.2 }, { x: 20, y: 84.2 }, { x: 21, y: 85.1 }, { x: 22, y: 86 }, { x: 23, y: 86.9 }, { x: 24, y: 87.8 }, { x: 25, y: 88 }, { x: 26, y: 88.8 },
    { x: 27, y: 89.6 }, { x: 28, y: 90.4 }, { x: 29, y: 91.2 }, { x: 30, y: 91.9 }, { x: 31, y: 92.7 }, { x: 32, y: 93.4 }, { x: 33, y: 94.1 }, { x: 34, y: 94.8 }, { x: 35, y: 95.4 },
    { x: 36, y: 96.1 }, { x: 37, y: 96.7 }, { x: 38, y: 97.4 }, { x: 39, y: 98 }, { x: 40, y: 98.6 }, { x: 41, y: 99.2 }, { x: 42, y: 99.9 }, { x: 43, y: 100.4 }, { x: 44, y: 101 },
    { x: 45, y: 101.6 }, { x: 46, y: 102.2 }, { x: 47, y: 102.8 }, { x: 48, y: 103.3 }, { x: 49, y: 103.9 }, { x: 50, y: 104.4 }, { x: 51, y: 105 }, { x: 52, y: 105.6 },
    { x: 53, y: 106.1 }, { x: 54, y: 106.7 }, { x: 55, y: 107.2 }, { x: 56, y: 107.8 }, { x: 57, y: 108.3 }, { x: 58, y: 108.9 }, { x: 59, y: 109.4 }, { x: 60, y: 110 }];

    var set11 = [{ x: 0, y: 53.4 }, { x: 1, y: 58.4 }, { x: 2, y: 62.2 }, { x: 3, y: 65.3 }, { x: 4, y: 67.8 }, { x: 5, y: 69.9 }, { x: 6, y: 71.6 }, { x: 7, y: 73.2 },
    { x: 8, y: 74.7 }, { x: 9, y: 76.2 }, { x: 10, y: 77.6 }, { x: 11, y: 78.9 }, { x: 12, y: 80.2 }, { x: 13, y: 81.5 }, { x: 14, y: 82.7 }, { x: 15, y: 83.9 },
    { x: 16, y: 85.1 }, { x: 17, y: 86.2 }, { x: 18, y: 87.3 }, { x: 19, y: 88.4 }, { x: 20, y: 89.5 }, { x: 21, y: 90.5 }, { x: 22, y: 91.6 }, { x: 23, y: 92.6 },
    { x: 24, y: 93.6 }, { x: 25, y: 93.8 }, { x: 26, y: 94.8 }, { x: 27, y: 95.7 }, { x: 28, y: 96.6 }, { x: 29, y: 97.5 }, { x: 30, y: 98.3 }, { x: 31, y: 99.2 },
    { x: 32, y: 100 }, { x: 33, y: 100.8 }, { x: 34, y: 101.5 }, { x: 35, y: 102.3 }, { x: 36, y: 103.1 }, { x: 37, y: 103.8 }, { x: 38, y: 104.5 },
    { x: 39, y: 105.2 }, { x: 40, y: 105.9 }, { x: 41, y: 106.6 }, { x: 42, y: 107.3 }, { x: 43, y: 108 }, { x: 44, y: 108.6 }, { x: 45, y: 109.3 },
    { x: 46, y: 109.9 }, { x: 47, y: 110.6 }, { x: 48, y: 111.2 }, { x: 49, y: 111.8 }, { x: 50, y: 112.5 }, { x: 51, y: 113.1 }, { x: 52, y: 113.7 },
    { x: 53, y: 114.3 }, { x: 54, y: 115 }, { x: 55, y: 115.6 }, { x: 56, y: 116.2 }, { x: 57, y: 116.8 }, { x: 58, y: 117.4 }, { x: 59, y: 118.1 },
    { x: 60, y: 118.7 }];

    var rawSvg = this.elementRef.nativeElement.querySelector('svg');

    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    var wrap = d3.select(rawSvg).selectAll('d.d3line').data([heightData]);
    var gEnter = wrap.enter().append('g').attr('class', 'd3line').append('g');

    var wrap2 = d3.select(rawSvg).selectAll('d.d3line').data([weightData]);
    var gEnter2 = wrap2.enter().append('g').attr('class', 'd3line').append('g');

    var wrap3 = d3.select(rawSvg).selectAll('d.d3line').data([headData]);
    var gEnter3 = wrap3.enter().append('g').attr('class', 'd3line').append('g');


    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // var lineFunc = d3.svg.line()
    //         .x(function (d) {
    //           return xScale0(d.x);
    //         })
    //         .y(function (d) {
    //           return yScale0(d.y);
    //         })
    //         .interpolate("bundle")
    //         .tension(.7);

    // var datalineFunc = d3.svg.line()
    //         .x(function (d) {
    //           return xScale0(d.x);
    //         })
    //         .y(function (d) {
    //           return yScale0(d.y);
    //         })
    //         .interpolate("linear");

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveBundle.beta(0.7));

    var datalineFunc = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xScale0 = d3.scaleLinear().domain([60.5, 0]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([60.5, 0]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([0, 131]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([0, 131]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(0, 60.5, 1)).tickFormat(function (d: any) {
      return d % 2 == 0 ? d : '';
    }).tickSizeInner(-height)
      .tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(0, 60.5, 1)).tickFormat(function (d: any) {
      return d % 2 == 0 ? d : '';
    });
    var yAxisLeft = d3.axisLeft(yScale1).ticks(17).tickValues(d3.range(0, 131, 3)).tickFormat(function (d: any) {
      return d % 3 == 0 ? d : '';
    }).tickSizeInner(-width)
      .tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(17).tickValues(d3.range(0, 131, 3)).tickFormat(function (d: any) {
      return d % 3 == 0 ? d : '';
    }).tickSizeInner(50)
      .tickSizeOuter(50).tickPadding(10);

    var age = function (data) {
      if (data <= 24) {
        return data.toString().split('.')[0] + " months";
      }
      if (data <= 60) {
        var mon = data / 12;
        var result = mon.toFixed(1).split('.');
        var mod = data % 12;
        var res = mod.toString().split('.');
        return result[0].toString() + " years " + res[0] + " months";
      }
    };

    // plot user data points
    // var heightpoint = wrap.selectAll('circle')
    //   .data(function (d) { return d; })
    //   .enter().append('circle')
    //   .attr('cx', function (d) { return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100; })
    //   .attr('cy', function (d) { return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100; })
    //   .attr('r', 5)
    //   .attr('fill', 'red')
    //   .on("mouseover", function (d) {
    //     div.transition()
    //       .duration(200)
    //       .style("opacity", .9);
    //     div.html(age(d.x) + "<br/>" + (d.y).toString() + " cm")
    //       .style("left", (d3.event.pageX) + "px")
    //       .style("top", (d3.event.pageY - 28) + "px");
    //   })
    //   .on("mouseout", function (d) {
    //     div.transition()
    //       .duration(500)
    //       .style("opacity", 0);
    //   });

    svg.selectAll("circle.heightData")
      .data(heightData)
      .enter()
      .append("circle")
      .attr("cx", function (d: any) {

        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;
      }).attr('fill', 'red')
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;
      })
      .attr("r", function (d: any) {
        return 5;
      }).on("mouseover", function (event, d: any) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html(age(d.x) + "<br/>" + (d.y).toString() + " cm")
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function (d: any) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });


    // var weightpoint = wrap2.selectAll('circle')
    //   .data(function (d) { return d; })
    //   .enter().append('circle')
    //   .attr('cx', function (d) { return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100; })
    //   .attr('cy', function (d) { return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100; })
    //   .attr('r', 5)
    //   .attr('fill', 'red')
    //   .on("mouseover", function (d) {
    //     div.transition()
    //       .duration(200)
    //       .style("opacity", .9);
    //     div.html(age(d.x) + "<br/>" + (d.y).toString() + " kg")
    //       .style("left", (d3.event.pageX) + "px")
    //       .style("top", (d3.event.pageY - 28) + "px");
    //   })
    //   .on("mouseout", function (d) {
    //     div.transition()
    //       .duration(500)
    //       .style("opacity", 0);
    //   });

    svg.selectAll("circle.weightData")
      .data(weightData)
      .enter()
      .append("circle").attr('fill', 'red')
      .attr("cx", function (d: any) {
        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;;
      })
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;;
      })
      .attr("r", function (d: any) {
        return 5;
      }).on("mouseover", function (event, d: any) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html(age(d.x) + "<br/>" + (d.y).toString() + " kg")
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // var headpoint = wrap3.selectAll('circle')
    //   .data(function (d) { return d; })
    //   .enter().append('circle')
    //   .attr('cx', function (d) { return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100; })
    //   .attr('cy', function (d) { return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100; })
    //   .attr('r', 5)
    //   .attr('fill', 'red')
    //   .on("mouseover", function (d) {
    //     div.transition()
    //       .duration(200)
    //       .style("opacity", .9);
    //     div.html(age(d.x) + "<br/>" + (d.y).toString() + " cm")
    //       .style("left", (d3.event.pageX) + "px")
    //       .style("top", (d3.event.pageY - 28) + "px");
    //   })
    //   .on("mouseout", function (d) {
    //     div.transition()
    //       .duration(500)
    //       .style("opacity", 0);
    //   });

    svg.selectAll("circle.headData")
      .data(headData)
      .enter()
      .append("circle")
      .attr("cx", function (d: any) {
        return d.y < 120 && d.y > 0 ? xScale0(d.x) : -100;
      })
      .attr("cy", function (d: any) {
        return d.y < 120 && d.y > 0 ? yScale0(d.y) : -100;
      })
      .attr("r", function (d: any) {
        return 5;
      })
      .attr('fill', 'red').on("mouseover", function (event, d: any) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html(age(d.x) + "<br/>" + (d.y).toString() + " cm")
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY - 28) + "px");
      })

    // x-axis
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .text("WHO Boys Height, Weight, Head Charts 0-5 years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 35)
      .style("text-anchor", "middle")
      .text("Age in Months");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 50)
      .style("text-anchor", "middle")
      .style("font-size", "10px")
      .text("Ref: IAP Growth Charts. Indian Academy of Pediatrics. [updated 2013 18 Oct, cited 2016 14 Jan] Available from: http://www.iapindia.org/page.php?id=79");

    svg.append("text")
      .attr("x", -350)
      .attr("y", -35)
      .attr("transform", "translate(0,0) rotate(-90)")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text("WHO MGRS 2006 Charts; Acta Paediatrics Suppl. 450:2006");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 240)
      .style("text-anchor", "start")
      .text("1 (-3)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 225)
      .style("text-anchor", "start")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 160)
      .style("text-anchor", "start")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 95)
      .style("text-anchor", "start")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 650)
      .attr("y", 95)
      .style("text-anchor", "start")
      .text("Height/Length");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 610)
      .style("text-anchor", "start")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 585)
      .style("text-anchor", "start")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 565)
      .style("text-anchor", "start")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 650)
      .attr("y", 565)
      .style("text-anchor", "start")
      .text("Head");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 855)
      .style("text-anchor", "start")
      .text("1 (-3)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 840)
      .style("text-anchor", "start")
      .text("3 (-2)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 810)
      .style("text-anchor", "start")
      .text("50 (0)");

    svg.append("text")
      .attr("x", 790)
      .attr("y", 770)
      .style("text-anchor", "start")
      .text("97 (+2)");

    svg.append("text")
      .attr("x", 650)
      .attr("y", 770)
      .style("text-anchor", "start")
      .text("Weight");

    // y-axis
    svg.append("g")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);

    svg.append("path")
      .datum(set1)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set2)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set3)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set4),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);


    svg.append("path")
      .datum(set4)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set5)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set6)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set7),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);

    svg.append("path")
      .datum(set7)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set8)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set9)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(set10)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");


    svg.append("path")
      .datum(set11)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(heightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

    svg.append("path")
      .datum(weightData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");


    svg.append("path")
      .datum(headData)
      .attr("class", "path")
      .attr("d", datalineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

  }
}
