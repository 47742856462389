<!-- -->

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Clinical History</p>
        </div>
    </div>
    <div *ngIf="!defPage">
      <!-- <div class="row titleContainer" style="width:99%;margin-top: 30px">
        <div style="width:99%;">
          <div style="float: right;visibility: hidden;">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [color]="'primary'"
                [(ngModel)]="clinicalType">
                <mat-radio-button class="example-radio-button" [value]="0" (click)="typechange(0);" style="margin-right: 10px;">Complaint</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1" (click)="typechange(1);">Prescription</mat-radio-button>
              </mat-radio-group>
          </div>
        </div>
      </div> -->

      <div class="row" style="margin-top: 3rem;">
        <div class="col-lg-11" style="text-align:center;">
          <!-- <h2>Prescription :</h2> -->
        </div>
        <div class="col-lg-1">
          <img style="cursor:pointer;width: 40px;" title="External Details" src="assets/external-link.png" (click)="getExternalClinhistory()">
        </div>
      </div>

      <!-- <div class="row" *ngIf="formcheckFlag" style="margin-left: 6px;margin-top: 30px;width:99%">
        
        <div *ngIf="complaintData == ''" style="width:99%;margin-top:10%;text-align: center;"><div>No Data Found</div></div>

        <div class="col-lg-5 col-md-5 col-sm-5" style="margin-top: 10px;">
          <span *ngIf="complaintData.length>0"><h2>Prescription :</h2></span>
        </div> 
        <div class="col-lg-7 col-md-7 col-sm-7" style="margin-top: 10px;">
          <div class="row">
            <div style="width:99%;margin-top: 15px;" class="col-sm-3 col-md-3 col-lg-3" *ngFor="let complaintdetails of complaintData;let i=index">
              <div *ngIf="complaintdetails.compliant_img?.length>0">
                <div  style="margin-left: 20px;">
                    <div  style="margin-top: 15px;" *ngFor="let fileName of complaintdetails.compliant_img;let j=index">
                      <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                        <div>
                          <img *ngIf="fileName.split('.')[1] == 'png'" src="assets/jpg-file-format.svg" style="width:35px;"  />
                          <img *ngIf="fileName.split('.')[1] == 'pdf'" src="assets/pdf-file-format.svg" style="width:35px;"  />File Name : {{complaintdetails.file_name}}<br>
                          <span style="margin-left: 35px;">Prescription Date : {{complaintdetails.prescription_date | date: "dd-MMM-yyyy"}}</span>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <img  src="assets/eye.svg" title="View" style="float:right;width:20px;cursor: pointer;" (click)="showcompliantData(fileName)"/>
                            </div>
                        </div>
                      </div> 
                    </div>
                </div>
              </div>
                  
            </div>
          </div>
        </div>
        
      </div> -->

      <div class="row" *ngIf="formcheckFlag" style="margin-left: 6px;margin-top: 30px;width:99%">
        <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 10px;">
          <div *ngIf="complaintData == ''" style="width:99%;margin-top:10%;text-align: center;"><div>No Data Found</div></div>

          <div *ngIf="complaintData.length>0">
            <table class="table labtable">
              <tr style="background-color: #E5F0FF;text-align: center;width: 100%;">
                <th style="font-weight: 500;border-right: 2px solid #c8e2ff;" class="border_top">S.No</th>
        
                <th style="font-weight: 500;border-right: 2px solid #c8e2ff;" class="border_top">Prescription Date</th>
        
                <th style="font-weight: 500;border-right: 2px solid #c8e2ff;" class="border_top">File Name</th>
        
                <!-- <div style="display:flex;justify-content: center;"> -->
                  <th style="font-weight: 500;border-right: 2px solid #c8e2ff;display: flex;justify-content: space-around;" class="border_top">
                    <span>File Type</span>
                    <!-- <span style="border-right: 2px solid #c8e2ff;"></span> -->
                    <span >View</span>
                  </th>
        
                  <!-- <th style="font-weight: 500;border-right: 2px solid #c8e2ff;" class="border_top">View</th> -->
                <!-- </div> -->
              </tr>
          
              <tr *ngFor="let complaintdetails of complaintData;let i=index" class="attachmentRow">
                <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;text-align:center;">{{i+1}}</td>
                
                <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;text-align:center;">{{complaintdetails.prescription_date | date: "dd-MMM-yyyy"}}</td>
                
                <td style="border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;text-align:center;">{{complaintdetails.file_name}}</td>
                
                <div style="display:flex;" *ngFor="let fileName of complaintdetails.compliant_img;let j=index">
                  <td style="width: 50%;margin-top: 5px;border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;text-align:center;" >
                    <img *ngIf="fileName.split('.')[1] == 'png'" src="assets/jpg-file-format.svg" style="width:35px;"  />
                    <img *ngIf="fileName.split('.')[1] == 'pdf'" src="assets/pdf-file-format.svg" style="width:35px;"  />
                  </td>
                
                  <td style="width: 50%;margin-top: 5px;border-right: 2px solid #c8e2ff;border-bottom: 2px solid #c8e2ff;text-align:center;" >
                    <img style="cursor: pointer;" (click)="showcompliantData(fileName)" src="assets/eye.svg" title="View">
                  </td>  
                </div>      
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div class="row" *ngIf="!formcheckFlag" style="margin-left: 6px;margin-top: 30px;width:99%">
        
        <div *ngIf="prescData == ''" style="width:99%;margin-top:11.5%;text-align: center;"><div>No Data Found</div></div>

        
        <div style="width:99%;margin-top: 30px;" *ngFor="let prescdetails of prescData;let i=index">
              <div style="font-weight: 800;"><span>{{i+1}})</span> <span style="margin-left: 10px;">{{prescdetails.date | date: "dd-MMM-yyyy"}}</span></div>
              <hr class="hrStyle">
              <div class="row" style="margin-left: 20px;">
                  <div class="col-sm-3 col-md-3 col-lg-3" style="margin-top: 15px;" *ngFor="let fileName of prescdetails.file_name;let j=index">
                    <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                      <div>
                        <img *ngIf="fileName.split('.')[1] == 'png'" src="assets/jpg-file-format.svg" style="width:35px;"  />
                        <img *ngIf="fileName.split('.')[1] == 'pdf'" src="assets/pdf-file-format.svg" style="width:35px;"  /> 
                        {{fileName}}
                      </div>
                      <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <img  src="assets/eye.svg" title="View" style="float:right;width:20px;cursor: pointer;" (click)="showData(fileName)"/>
                          </div>
                      </div>
                    </div> 
                  </div>
              </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="defPage" style="width:99%;margin-top: 5rem;margin-left: 10px;">
      <table style="width: 100%;" *ngIf="conHistorydata.length!=0">
        <tr style="border: 2px solid;">
            <th style="font-weight: 500;border-right: 2px solid #000000;">S.No</th>
    
            <th style="font-weight: 500;border-right: 2px solid #000000;">Date</th>
    
            <th style="font-weight: 500;border-right: 2px solid #000000;">Patient Number</th>
    
            <th style="font-weight: 500;border-right: 2px solid #000000;">Patient Name</th>
    
            <th style="font-weight: 500;border-right: 2px solid #000000;">Type</th>
    
            <th style="font-weight: 500;border-right: 2px solid #000000;">Description</th>

            <th>Doctor</th>
        </tr>

        <tr style="border: 0.5px solid;" *ngFor="let c of conHistorydata;let i = index">
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{i+1}}</td>
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{c.Date | date:'dd MMM yyyy'}}</td>
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{c.Patient_Number}}</td>
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{c.Patient_Name}}</td>
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{c.Type}}</td>
            <td style="font-weight: 500;border-right: 2px solid #000000;">{{c.Description}}</td>
            <td>{{c.Doctor}}</td>
        </tr>
      </table>
      <div *ngIf="conHistorydata.length == 0" style="width:100%;margin-top:10%;text-align: center;"><div>No Clinical Found</div></div>
    </div>
  <br>
</div>


