<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-12">
            <h2><img src="./assets/images/file/file.png" class="imgcustom"> <span class="headerClr"> Patient Registration Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-top:20px">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-6">
            <form [formGroup]="filterregisration">
                <div class="form-row">
                    <div class="form-group col-3">
                        <label>District</label>
                        <select class="custom-select" (change)="ondistrictChange($event)" formControlName="distName">
                        <option value="">select</option>
                        <option *ngFor="let getdistrictList of getdistrict; let i = index" [ngValue]="getdistrictList.id">{{getdistrictList.districtname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>Block</label>
                        <select class="custom-select" (change)="onblockChange($event)" formControlName="distName">
                        <option value="">select</option>
                        <option *ngFor="let getblockList of getblock; let i = index" [ngValue]="getblockList.id">{{getblockList.blockname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>VHN</label>
                        <select class="custom-select" (change)="onvhnChange($event)" formControlName="distName">
                        <option [ngValue]="0">All</option>
                        <option *ngFor="let staffprofileList of staffprofile; let i = index" [ngValue]="staffprofileList.staffid">{{staffprofileList.staffname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-3">
                        <label>Village</label>
                        <select class="custom-select" (change)="onvilageChange($event)" formControlName="distName">
                        <option [ngValue]="0">All</option>
                        <option *ngFor="let staffvillageList of staffvillage; let i = index" [ngValue]="staffvillageList.village_id">{{staffvillageList.villagename}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row" style="margin-top:20px">
                    <div class="form-group col-8">
                        <div style="display:flex">
                            <span style="margin-top: 3px;">Date :</span>
                            <input style="margin-left: 10px;" type="text"
                            ngxDaterangepickerMd
                            [maxDate]='maxDate'
                            [locale]="{format: 'DD-MM-YYYY'}"
                            [(ngModel)]="selected" [ngModelOptions]="{standalone: true}"
                            [showCustomRangeLabel]="true"
                            [alwaysShowCalendars]="true"
                            [ranges]="ranges"
                            [linkedCalendars]="true"
                            [isInvalidDate] = "isInvalidDate"
                            [showClearButton]="true"
                            (rangeClicked)="rangeClicked($event)"
                            (datesUpdated)="datesUpdated($event)"
                            placeholder="Select Date"/>

                            <button class="buttonOutside" style="width:100px;margin-left: 20px;" (click)="getReport()"><div class="buttonInside">Get Report</div></button>   
                        </div>
                        
                    </div>
                    <div class="col-md-4">
                        <div style="display:flex;float: right;margin-right:10px;">
                            <div style="margin-top: 2px;"><h4>Download : </h4></div>
                            <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                            <!-- <div style="margin-left:10px;" class="addDr" (click)="downloadCSV()"><div class="addDrTxt">CSV</div></div> -->
                            <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    
    </div>
    <hr />
    
    <div id="printtable">
            <div class="row">
                <div class="col-md-12">
                    <div style="text-align: center;font-weight: bold;"><label><h4>Patient Registration Report</h4></label></div>
                </div>
                <div class="col-md-8">
                    <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label>
                </div>
                <div class="col-md-4">
                    <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
                </div>   
            </div>
            <div class="row">
                <div class="col-md-12 search-table-outter">
                    <h5>Consolidated:</h5>
                        <table class="table table-bordered" style="table-layout: fixed;">
                                    <thead style="text-align: center">
                                        <tr>
                                            <th>S.No</th>
                                            <th>District Name</th>
                                            <th>Block Name</th>
                                            <th>Staff Name</th>
                                            <th>Village Name</th>
                                            <th>Male</th>
                                            <th>Female</th>
                                            <th>Total</th>      
                                        </tr>                                 
                                    </thead>
                                    <tbody style="text-align: center;">
                                        <tr *ngFor="let regReoprtlist of regReoprt;let i=index;">
                                            <td>{{i+1}}</td>
                                            <td>{{regReoprtlist.districtname}}</td>
                                            <td>{{regReoprtlist.blockname}}</td>
                                            <td>{{regReoprtlist.staffname}}</td>
                                            <td>{{regReoprtlist.villagename}}</td>
                                            <td>{{regReoprtlist.male}}</td>
                                            <td>{{regReoprtlist.female}}</td>
                                            <td>{{regReoprtlist.total}}</td>
                                        </tr>
                                        <tr style="text-align: center; font-size:16px; font-weight:bolder;">
                                            <td colspan="5">Grand Total</td>
                                            <td>{{grandTotalMale}}</td>
                                            <td>{{grandTotalFemale}}</td>
                                            <td>{{grandTotal}}</td>
                                        </tr>      
                                    </tbody>                          
                        </table>
                </div>
            </div>
    </div>
  </div>
</div>

