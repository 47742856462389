<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>Current Medications</p>


    <div style="display: flex;">
        
        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary" (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close" (click)="close()"></i>

    </div>

 
</div>

<div style="max-height: 15rem;overflow: auto;">

    <!-- <div *ngIf="cmdata.length==0">
        No data found for the patient
    </div> -->
    
    <div *ngFor="let c of cmdata;let i=index" style="width: 100%;">

        <div *ngIf="c.question_type=='text+ text'"
            style="margin-bottom: 0.5rem;display: flex;flex-wrap: wrap;">

            <div style="width: 100%" *ngIf="pres_copy_cm1.length==0">
                <!-- <p>Drugs from Previous Consultations:</p> -->
                <p>No data found for the patient</p>
            </div>

            <div style="width: 100%" *ngIf="pres_copy_cm1.length>0">
                <div class="div_keyboard">
                    <p>Drugs from Previous Consultations:</p>

                    <div class="cc_items2_box"
                        style="max-height: 7.5rem;overflow: auto;height: unset;">
                        <div class="cc_items2" >

                            <div #pres_item
                                class="cc_item chip chip-md success-color white-text  example z-depth-2 mr-1"
                                *ngFor="let pres of pres_copy_cm1;let indexOfelement=index;let i as index"
                                tabindex="1"
                                (click)="update_pres_cm1(indexOfelement,i)">
                                {{pres.name}}
                            </div>
                        </div>


                    </div>
            </div>

            <p style="margin-right: 0.5rem;">{{c.description}}</p>

            <table class="pres" style="width: 100%;">
                <tr style="font-weight: 600;font-size: 0.9rem;">
                    <td>
                        <p>S. No</p>
                    </td>
                    <td>
                        <p>Drug and Strength</p>
                    </td>
                    <td>
                        <p>Dosage & Frequency</p>
                    </td>

                </tr>

                <tr style="cursor: pointer;"
                    *ngFor="let pres_s of pres_selected_soap;let indexOfelement=index;let i=index;">
                    <td (click)='click_soap(i)'
                        (dblclick)='doubleClick_soap(i)'>
                        <p>{{indexOfelement+1}}</p>
                    </td>
                    <td (click)='click_soap(i)'
                        (dblclick)='doubleClick_soap(i)'>
                        <p>{{pres_s.brand}}</p>
                    </td>
                    <td (click)='click_soap(i)'
                        (dblclick)='doubleClick_soap(i)'>
                        <p>{{pres_s.dose}} {{pres_s.dose_option}}
                            <span *ngIf="pres_s.dose_option"> , </span>
                            {{pres_s.frequency}}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>