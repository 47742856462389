<div>
<table style="margin-bottom: 2rem;" *ngIf=" data.drugs.length!=0">
    <tr style="border: 2px solid;">
        <th>S No</th>
        <th>Name</th>
        <th>Price</th>
        <th>Stock Availabilty</th>
        <th>Quantity</th>
        <th>Thumbnail</th>
        <th>Delete</th>
    </tr>

    <tr style="border: 0.5px solid;" *ngFor="let d of data.drugs;let i = index">
        <td>{{(i+1)}}</td>
        <td>
            <ng-container *ngIf="d.product_name; else fallback">
            {{d.product_name}}
        </ng-container>
        <ng-template #fallback>{{ d.name }}</ng-template>
        
        </td>
        <td style="width: 4rem;">
            <ng-container *ngIf="d.product_price; else fallbackPrice">
            <span *ngIf="d.product_price">&#x20B9;</span>
             {{d.product_price}}

            </ng-container>
            <ng-template #fallbackPrice>
                <span *ngIf="d.price">&#x20B9;</span>
                {{ d.price }}
            </ng-template>
        </td>
        
        <td [class.green]="d.stock_status=='instock'" [class.red]="d.stock_status=='outofstock'"><span
                *ngIf="d.stock_status=='instock'">In Stock</span> <span *ngIf="d.stock_status=='outofstock'">Out of
                Stock</span></td>
        <td>

            <div
                style="display: flex;justify-content: space-between;margin-top: 0.5rem;padding-bottom: 0.5rem;">
                <span class="text-center minus" style="cursor: pointer;border-right: 2px solid #DCDCDC" (click)="minus(i)">-</span>
                <input class="dose_input" style="width: 2rem;border: none;" [(ngModel)]="d.quantity">
                <span class="text-center plus" style="border-left: 2px solid #DCDCDC;cursor: pointer;" (click)="plus(i)">+</span>
                <!-- <span class="text-center plus" style="border-left: 2px solid #DCDCDC;cursor: pointer; "(click)="d.quantity < d.stock_quantity ? plus(i) : null">+</span> -->
            </div>
        </td>
        <td>
            <img *ngIf="d.images &&d.images.length>0" style="height: 3rem;width: 3rem;" [src]="d.images[0].src">
        </td>
        <td><img style="cursor: pointer;" (click)="delete(i)" src="assets/delete-icon.svg"></td>
    </tr>
</table>
<p *ngIf=" data.drugs.length!=0" class="showPriceCls">Total:&nbsp;&#8377;{{showPrice}}</p>
</div>
<p *ngIf=" data.drugs.length==0">No drugs have been added</p>

<div *ngIf=" data.drugs.length>0" style="display: flex;justify-content: center;">
    <button class="btn btn-primary" style="margin-right: 0.5rem;" (click)="save()">Add to cart</button>

    <button class="btn btn-primary" (click)="onNoClick()">Close</button>
</div>