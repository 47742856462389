<div>
    <div style="text-align: center;font-size: 20px;font-weight: bold;">Receipt</div>
    <div class="Rtable Rtable--3cols">  
        <div class="Rtable-cell"> <span><span>Date :&nbsp;&nbsp;</span><span class="fontWeight">{{date}}</span></span></div>
       <div class="Rtable-cell" style="text-align: center;"><span>&nbsp;&nbsp;</span></div>
        <div class="Rtable-cell"> <span>Receipt No :&nbsp;&nbsp;</span><span class="fontWeight">{{recepiNum}}</span></div>
    </div>
    
    <div class="Rtable Rtable--3cols">
        <div class="Rtable-cell"><span>MR Number :&nbsp;&nbsp;</span><span class="fontWeight">{{mrNum}}</span></div>
        <div class="Rtable-cell"> </div>
        <!-- <div class="Rtable-cell"></div> -->
        <div class="Rtable-cell"> <span><span>Invoice No :&nbsp;&nbsp;</span><span class="fontWeight">{{invoiceNum}}</span></span></div>
    </div>
    <div class="Rtable Rtable--1cols" style="margin-top: 10px;">
        <p class="Rtable-cell">Received the amount of Rs. <span class="fontWeight">{{amount}}</span> from <b>Mr/Ms/Mrs.</b><span class="fontWeight">{{patientName | titlecase}}</span> for the child, <span class="fontWeight">{{parentName | titlecase}}</span>
        aged <span class="fontWeight">{{aged}}</span> towards the professional / Vaccination charges.</p>
    </div>
    <div class="Rtable Rtable--2cols">
        <div class="Rtable-cell"> <span>Thank You.</span></div>
        <div class="Rtable-cell"> 
            <div>Signature:</div>
            <div>Name:  <span>{{drName}}</span> <span style="margin-left: 5px;">{{drdegree}}</span> </div>
            <div>Reg No: {{drRegnum}}</div>
        </div>
    </div>

</div>

    