<div class="list-group list-group-flush">
     <div class="txtbap">Book an appointments 
       <span id="menu-close" class="closetoggle" (click)="bookingcancel()">Close</span>
      </div>

      <div class="searchContainer"  *ngIf="searchpatient">
          
          <div class="selctpat">Select Patient</div><br/>
          
          <div class="quesearch list-group-item list-group-item-action bgClr listAlign" style="height: 100px;">
            <label style="font-size: 10px;">Search Patient Name or MR number or Phone number</label><br/>
            <input style="font-size: 14px;" class="form-control" autofocus="false" placeholder="Search" autocomplete="off"
            [(ngModel)]="searchTxt" id="srch-term" type="text">
            <span class="input-group-append searchbutton">
              <button class="btn btn-outline-secondary border-start-0 border-bottom-1 border" type="button" (click)="search()">
                  <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <div *ngIf="loader" style="margin-top: 10px;"><div style="text-align: center;"><span class="fa fa-spinner fa-2x fa-spin spinnerClr"></span></div></div>
          <div class="listContainar" *ngIf="list.length!=0">
            <div *ngFor="let l of list;let i = index" class="list-group-item list-group-item-action bgClr listAlign" (click)="selectedPatient(l)">
              <div>
                <img 
                *ngIf="(l.image == 'No profile picture' || l.image == null || l.image == 'null' || l.image == '' || l.image == 'None')&&(l.gender == 'male')" 
                style="display: inline-block;" src="assets/avatar-male.png" class="tableavatar"> 

                <img 
                *ngIf="(l.image == 'No profile picture' || l.image == null || l.image == 'null' || l.image == '' || l.image == 'None')&&(l.gender == 'female')" 
                style="display: inline-block;" src="assets/avatar-female.png" class="tableavatar"> 
                
                <img *ngIf="l.image != 'No profile picture' && l.image != null && l.image != 'null' && l.image != '' && l.image != 'None'" style="display: inline-block;" src="{{imageUploadUrl}}{{l.image}}" class="tableavatar"> 
                
                <span class="listedItem">{{l.name_given}}</span></div>
              <div style="margin-left: 60px;">{{l.mr_number}}</div>
            </div>
          </div>
      </div>

      <div *ngIf="selectedpatientDetails">
        <div class="selctpat">Select Appointment</div><br/>
        <div class="patiConta">
          <div style="color: #1a73ff;">Patient</div>
          <div style="display: flex;margin-top:10px;">
              <div style="width:70%">
                <div>
                  <img 
                    *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'male')" 
                    style="display: inline-block;" src="assets/avatar-male.png" class="tableavatar"> 
    
                    <img 
                    *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'female')" 
                    style="display: inline-block;" src="assets/avatar-female.png" class="tableavatar"> 
                    
                    <img *ngIf="patImage != 'No profile picture' && patImage != null && patImage != 'null' && patImage != '' && patImage != 'None'" style="display: inline-block;" src="{{imageUploadUrl}}{{patImage}}" class="tableavatar">  
                  
                  <span class="listedItem">{{patientName}}</span></div>
                <div style="margin-left: 60px;">{{mrno}}</div>
              </div>
              <div style="width:30%">
                <div>{{mobNum}}</div>
                <div>{{gender | titlecase}}</div>
              </div>
          </div>
          <hr class="hrline" />
          <div style="color: #1a73ff;margin-top: 10px;">Date</div>
          
            <div style="margin-top:10px;">
                <div>{{TodayDateUI}}</div>
                
                <div style="margin-top:10px;" *ngIf="followup_date != null && followup_date != 'SOS'">
                  <mat-radio-group aria-label="Select an option" [color]="'primary'" (click)="followupdateChange()">
                    <mat-radio-button [checked]="followupstatus" value="1"> Select as Followup Date</mat-radio-button>
                   </mat-radio-group>
                 </div>
                 <div *ngIf="followStatus" class="confirmpopup">
                   <div>
                     Are you Sure? You want set us Followup Date at {{follow_date_display}}
                   </div>
                   <div style="display: flex;justify-content: center;">
                     <div><button  class="cancel btn" (click)="nofollowup()"
                      style="width: fit-content;">No</button></div> 
                      <div><button  class="cancel btn" (click)="yesfollowup()"
                        style="width: fit-content;">yes</button></div>
                   </div>
                 </div>
                <div style="margin-top:10px;width:50%">
                  <mat-form-field>
                    <input readonly matInput [matDatepicker]="picker" [(ngModel)]="maxDate" (dateChange)="onDateChange($event)" placeholder="Choose Alternate Date" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month" [startAt]="minDate"></mat-datepicker>
                  </mat-form-field>
                </div>
            
          </div>

          <hr class="hrline" style="margin-top:6px"/>
         
          <div style="color: #1a73ff;">Appointment Type</div>
           <div style="display: flex;margin-top:10px;">
            <div style="width:100%">
              <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group"
                  [color]="'primary'"
                  [(ngModel)]="apptType">
                  <mat-radio-button class="example-radio-button" [value]="0">Consultation</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="1">Vaccination</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="2">Voice Call</mat-radio-button>
                  <mat-radio-button class="example-radio-button" [value]="3" style="margin-left: 26px;">Video Call</mat-radio-button>
              </mat-radio-group>
            </div>
           </div>
           
           <div style="margin-top:10px;float:right;margin-right:20px">
            <button  class="cancel btn" (click)="backapptselection()"
                    style="width: fit-content;">Back</button>
             <button class="next btn" (click)="gotoapptbook()">
               <span style="margin-top: -1px;display:block">Next</span>
              </button>
            </div>
        </div>

      </div>

      <div *ngIf="bookingSlots">
        <div class="selctpat">Select Token's</div><br/>
          <div class="sloatContainer">
            <div style="color: #1a73ff;">Patient</div>
            
            <div style="display: flex;margin-top:10px;">
                <div style="width:70%">
                  <div>
                    <img 
                    *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'male')" 
                    style="display: inline-block;" src="assets/avatar-male.png" class="tableavatar"> 
    
                    <img 
                    *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'female')" 
                    style="display: inline-block;" src="assets/avatar-female.png" class="tableavatar"> 
                    
                    <img *ngIf="patImage != 'No profile picture' && patImage != null && patImage != 'null' && patImage != '' && patImage != 'None'" style="display: inline-block;" src="{{imageUploadUrl}}{{patImage}}" class="tableavatar">   
                    
                    <span class="listedItem">{{patientName}}</span></div>
                  <div style="margin-left: 60px;">{{mrno}}</div>
                </div>
                <div style="width:30%">
                  <div>{{mobNum}}</div>
                  <div>{{gender | titlecase}}</div>
                </div>
            </div>
            <hr class="hrline" />
            <div style="display: flex;">
              <div style="width:60%">
                <div style="color: #1a73ff;">Date</div>
                <div style="margin-top:5px;">
                  <!-- <div>{{TodayDate}}</div> -->
                  <div>{{TodayDateUI}}</div>
                </div>
              </div>
              <div style="width:40%">
                <div style="color: #1a73ff;">Appointment</div>
                  <div style="margin-top:5px;">{{type}}</div>
              </div>
            </div>
            <hr class="hrline" />

          <div>
            <div *ngFor="let slot of slotArray">
              <div style="display: flex;margin-top: 10px;">
                  <div style="width:80%;font-weight: 600;">{{slot[0].start | date : 'shortTime':'GMT+11'}} - {{slot[slot.length-1].start | date :
                    'shortTime':'GMT+11'}}
                  </div>
                  <div  style="width:20%;float: right;font-weight: 600;">{{slot[0].token}} - {{slot[slot.length-1].token}}
                  </div>
                </div>
                <div class="slotStyle">
                  <div *ngFor="let s of slot">
                    <button style="margin-left: 8px;margin-top: 10px;" [class.booked]="s.title!=null" [class.past]="s.title==null&&s.past" (click)="select(s)"
                            [class.selected]="s.selected" class="btn slot">{{s.token}}</button>
                  </div>
                </div>
            </div>
            
          <p style="width: 100%;text-align: center;" *ngIf="slots&&slots.length==0">No slots available</p>
        
        
            <div style="display: flex;flex-direction: row;width: 100%;justify-content: center;font-size:11px;margin-top: 2rem;">
                <div style="display: flex;">
                    <img class="slot_img" src="assets/green.svg">
                    <p class="slot_p">Available</p>
                </div>
                <div style="display: flex;">
                    <img class="slot_img" src="assets/red.svg">
                    <p class="slot_p">Booked</p>
                </div>
                <div style="display: flex;">
                  <div class="slot_img" style="background-color: #ddd;border-color: gray;height: 0.8rem;margin-top: 2px;"></div>
                  <p class="slot_p">Expired</p>
                </div>
                <div style="display: flex;">
                    <img class="slot_img" src="assets/blue.svg">
                    <p class="slot_p">Selected</p>
                </div>
            </div>
        
            <div style="display:flex;flex-direction:row;margin-top: 1rem;float: right;margin-right: 20px;">
                <button  class="cancel btn" (click)="backslot()"
                    style="width: fit-content;">Back</button>
                <button class="next btn" (click)="next()" style="width: fit-content;">Next</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="bookingConfirmation">
        <div class="selctpat">Booking Confirmation</div><br/>
        <div class="sloatContainer">
          <div style="color: #1a73ff;">Patient</div>
          
          <div style="display: flex;margin-top:10px;">
              <div style="width:70%">
                <div>
                  <img 
                  *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'male')" 
                  style="display: inline-block;" src="assets/avatar-male.png" class="tableavatar"> 
  
                  <img 
                  *ngIf="(patImage == 'No profile picture' || patImage == null || patImage == 'null' || patImage == '' || patImage == 'None')&&(gender == 'female')" 
                  style="display: inline-block;" src="assets/avatar-female.png" class="tableavatar"> 
                  
                  <img *ngIf="patImage != 'No profile picture' && patImage != null && patImage != 'null' && patImage != '' && patImage != 'None'" style="display: inline-block;" src="{{imageUploadUrl}}{{patImage}}" class="tableavatar">   
                  
                  <span class="listedItem">{{patientName}}</span></div>
                <div style="margin-left: 60px;">{{mrno}}</div>
              </div>
              <div style="width:30%">
                <div>{{mobNum}}</div>
                <div>{{gender | titlecase}}</div>
              </div>
          </div>
          <hr class="hrline" />
          <div style="font-weight: normal;">You have Selected</div>
          <div style="display: flex;">
            <div style="margin-top: 10px;width:70%;">
              <div style="color: #1a73ff;">Appointment</div>
              <div style="font-weight: 600;margin-top:5px;">{{type}}</div>
              <div style="color: #1a73ff;margin-top: 8px;">Date & Time</div>
              <div style="margin-top:5px;">
                <div style="font-weight: 600;">{{maxDate | date : 'dd-MM-yyyy'}} at {{time | date :'shortTime':'GMT+11' }}</div>
              </div>
              </div>
            <div style="margin-top: 5px;width:30%">
              <div style="color: #1a73ff;">Token's</div>
              <div *ngFor="let selected of selectedTokens">
                <button style="margin-top: 10px;" class="btn slot">{{selected}}</button>
              </div>
            </div>
          </div>
          
          
          <hr class="hrline"/>

          <div style="display:flex;flex-direction:row;justify-content:center;margin-top: 1rem;">
            <button  class="cancel btn"
                style="width: fit-content;" (click)="backconfirm()">Back</button>
            <button  class="cancel btn" 
                style="width: fit-content;" (click)="bookingcancel()">Cancel</button>
            <button class="next btn" style="width: fit-content;" [disabled]="disabled" (click)="confirmBooking()">Confirm</button>
          </div>

        </div>
        
      </div>

</div>
  
  
  
    
    
  
  