 <div class="row">
    <p class="col-lg-11 col-md-11">Print Template</p>
    <img class="col-lg-1 col-md-1" style="cursor: pointer;height: 1.6rem;" (click)="close()" src="assets/cancel.svg">
</div>

<div class="row" style="height:90%">
    <div class="col-md-12">
        <div style="height:100%">
            <iframe id="print1" frameborder="0" tabindex="1" [src]="templatesrc" height="100%" width="100%"></iframe>
        </div>
    </div>
</div>

