<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-12">
            <h2><img src="./assets/images/newspaper/newspaper.png" class="imgcustom"> <span class="headerClr"> MH users Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-right: -20px;margin-top:25px;">
        <div class="col-md-8">  
            <label><input type="radio" style="cursor:pointer;" [(ngModel)]="userType" value="MH users" (click)="mhUserReport('mhusers')"/> MH Users </label> &nbsp;&nbsp;                        
            <label><input type="radio" style="cursor:pointer;" [(ngModel)]="userType" value="Non users" (click)="mhUserReport('nonusers')"/> Non Users</label>                         
            <!-- <div style="display:flex">
                <span style="margin-top: 3px;">Date :</span>
                <input style="margin-left: 10px;" type="text"
                ngxDaterangepickerMd
                [locale]="{format: 'DD-MM-YYYY'}"
                [(ngModel)]="selected"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [isInvalidDate] = "isInvalidDate"
                [showClearButton]="true"
                (rangeClicked)="rangeClicked($event)"
                (datesUpdated)="datesUpdated($event)"
                placeholder="Select Date please..."/>

                <button class="buttonOutside" style="width:100px;margin-left: 20px;" (click)="getWalletReport()"><div class="buttonInside">Get Report</div></button>   
            </div>    -->
        </div>
        <div class="col-md-4">
            <div style="display:flex;float: right;margin-right:10px;">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr />
    <div style="text-align:center;color:red" *ngIf="mhusers.length == 0">Data is not available......</div>
    <div id="printtable" *ngIf="mhusers.length != 0">
    <div class="row">
        <div class="col-md-12">
            <div style="text-align: center;font-weight: bold;"><label><h4>{{userType}} Reports</h4></label></div>
        </div>
        <div class="col-md-8">
            <!-- <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label> -->
        </div>
        <div class="col-md-4">
            <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
        </div>   
    </div>
    <div class="row">
        <div class="col-md-12 search-table-outter">
            <table class="table table-bordered" style="table-layout: fixed;">
                <thead style="text-align: center;">
                    <tr>
                        <th>S.No</th>
                        <th>Patient Name</th>
                        <th>Gender</th>
                        <th>MR No.</th>
                        <th>Email</th>
                        <th>Registration Date</th>
                        <th>last Login</th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr *ngFor="let mhUsers of mhusers;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{mhUsers.name_given}}</td>
                        <td>{{mhUsers.gender}}</td>
                        <td>{{mhUsers.mr_number}}</td>
                        <td>{{mhUsers.email}}</td>
                        <td>{{mhUsers.registration_date}}</td>
                        <td>{{mhUsers.last_login}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
  </div>
</div>

