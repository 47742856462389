<div class="container">
    <form [formGroup]="abortionDetails" (submit)="save_abortiondetails()">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Type</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <select class="form-control" name="Type" style="width: 190px;font-size: 14px;"
                            formControlName="abortType" required>
                            <option value="" disabled>Select</option>
                            <option value="Spontaneous">Spontaneous</option>
                            <option value="Induced">Induced</option>
                            <option value="Ectopic Pregnancy">Ectopic Pregnancy </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Term</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <select class="form-control" name="Term" style="width: 190px;font-size: 14px;"
                            formControlName="abortTerm" required>
                            <option value="" disabled>Select</option>
                            <option value="1st Trimester">1st Trimester</option>
                            <option value="2nd Trimester">2nd Trimester</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4">
                <div class="col-lg-6"><label>DDL</label><span style="color:red;">&nbsp;*</span></div>
                <div class="col-lg-6">
                    <select class="form-control" name="Term" style="width: 125px; margin-left: -8px;" formControlName="abortddl">
                        <option value="" disabled>Select</option>
                        <option value="1st Trimester">1st Trimester</option>
                        <option value="2nd Trimester">2nd Trimester</option>
                    </select>
                </div>
            </div>         -->
        </div>

        <br>

        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>Date of Abortion</label><span style="color:red;">&nbsp;*</span></div>
                    <div class="col-lg-5">
                        <div class="input-group calendaralign" style="width: 190px;font-size: 14px;">
                            <input type="date" onkeypress="return event.charCode == 8"
                                style="font-size: 14px;" class="form-control" [max]="obsabortmaxDate"
                                formControlName="abortdate" required />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-5"><label>General Notes</label></div>
                    <div class="col-lg-6">
                        <textarea class="form-control" name="" maxlength="2000" cols="20" rows="6"
                            style="font-size: 14px;width: 190px;" placeholder="Notes here" formControlName="abortnotes"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <div class="container" style="border: none;">
            <div class="row" style="text-align: center;">
                <div class="col-lg-12">
                    <button type="button" (click)="openpopupforexit()" class="btn btn-default">Cancel</button> &nbsp;
                    &nbsp; &nbsp; &nbsp;
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </form>
</div>