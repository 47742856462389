<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-6">
            <h2> <img src="./assets/images/video-web/video-web.png" class="imgcustom"> <span class="headerClr"> Videocall Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-right: -20px;margin-top:25px;">
        <div class="col-md-8">  
            <div style="display:flex">
            <span style="margin-top: 3px;">Date :</span>
            <input style="margin-left: 10px;" type="text"
            ngxDaterangepickerMd
            [locale]="{format: 'DD-MM-YYYY'}"
            [(ngModel)]="selected"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="true"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            placeholder="Select Date"/>
        </div>   
        </div>
        <div class="col-md-4">
            <div style="display:flex;float: right;margin-right:10px;margin-top:-15px;">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr />

    <div style="text-align:center;color:red" *ngIf="getvideocallTransStatus == 0">Data is not available......</div>
    <div id="mytable" *ngIf="getvideocallTransStatus == 1">
    <div class="row">
        <div class="col-md-12">
            <div style="text-align: center;font-weight: bold;"><label><h4>Video Call Report</h4></label></div>
        </div>
        <div class="col-md-8">
            <label><h5>Report Generated For the period of : {{startdateInFormat}} to {{enddateInFormat}}</h5></label>
        </div>
        <div class="col-md-4">
            <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
        </div>   
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5>Consolidated:</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th style="text-align: center;">Total Calls</th>
                        <th style="text-align: center;">Scheduled Calls</th>
                        <th style="text-align: center;">Emergency Calls</th>
                        <th style="text-align: center;">Connected</th>
                        <th style="text-align: center;">Missed</th>
                    </tr>
                </thead>
                <tbody  style="text-align: center;" ng-hide="chartHide">
                    <tr>
                        <td>{{getVideocallList.total_calls }}</td>
                        <td>{{getVideocallList.scheduled_calls }}</td> 
                        <td>{{getVideocallList.emergency_calls}}</td> 
                        <td >{{getVideocallList.connected}}</td> 
                        <td>{{getVideocallList.missed}}</td>   
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5>Break Up:</h5>
            <table class="table table-bordered" style="table-layout: fixed;">
                <thead>
                    <tr>
                        <th style="text-align: center;">S.No</th>
                        <th style="text-align: center;">MR Id</th>
                        <th style="text-align: center;width: 20%;">Name</th>
                        <th style="text-align: center;width: 20%;">Mode</th>
                        <th style="text-align: center;">Date</th>
                        <th style="text-align: center;">Time</th>
                        <th style="text-align: center;">Status</th>
                        <!-- <th style="width: 30rem;text-align: center;"><h5>Room Id</h5></th>       -->
                    </tr>                                 
                </thead>
                <tbody style="text-align: center;">
                    <tr *ngFor="let videocallTrans of getvideocallTransList;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{videocallTrans.mrid}}</td>
                        <td style="width: 20%;">{{videocallTrans.name}}</td>
                        <td style="width: 20%;">{{videocallTrans.mode}}</td>
                        <td>{{videocallTrans.date}}</td>
                        <td>{{videocallTrans.timestamp | date:'h:mma'}}</td>
                        <td [ngStyle]="{ color: videocallTrans.call_connect_status == 'connected' ? 'green' : 'red' }">{{videocallTrans.call_connect_status}}</td>
                    </tr>    
                </tbody>                          
            </table>
        </div>
    </div>
    </div>
  </div>
</div>

