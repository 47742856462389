<div class="example-header">
    <button mat-icon-button (click)="previousClicked('year')" title="Previous Year">
      <mat-icon>{{picon}}</mat-icon>
    </button>
    <button mat-icon-button (click)="previousClicked('month')" title="Previous Month">
      <mat-icon>{{picon1}}</mat-icon>
    </button>
    <span class="example-header-label">{{periodLabel}}</span>
    <button mat-icon-button (click)="nextClicked('month')" title="Next Month">
      <mat-icon>{{picon2}}</mat-icon>
    </button>
    <button mat-icon-button (click)="nextClicked('year')" title="Next Year">
      <mat-icon>{{picon3}}</mat-icon>
    </button>
  </div>