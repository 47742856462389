import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { PubNubAngular } from 'pubnub-angular2';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-activitydashboard',
  templateUrl: './activitydashboard.component.html',
  styleUrls: ['./activitydashboard.component.scss'],
  providers: [PubNubAngular]
})
export class ActivityDashboardComponent implements OnInit {
  routeURL:any;

  dmail;
  token;
  doc_id;
  dr_name;
  dr_degree;
  hos_name;

  getaccount_email: any;
  getdrID:any;
  getauthToken: any;
  setaccount_email: any;
  setdrID:any;
  setauthToken: any;
  urlPoints: any;
  authToken: any;
  dashboardData: any;
  dashboardParam: object;
  totalWaiting: number;
  community_visit:number
  max_waiting:number;
  avg_waiting: number;
  totalTeleConsult: number;
  total_tele:number;
  done_tele: number;
  totalChatCount: number;
  received_chat:number;
  unresponed_chat: number;
  totalVaccine: number;
  booked_vaccine:number;
  done_vacine: number;
  totalPatient: number;
  new_patient:number;
  old_patient: number;
  pubnub: PubNubAngular;
  channel: string;
  uuid: any;
  msg: any;
  progressValue: any;
  teleprogressValue: any;
  vaccinprogressValue: any;
  eshopprogressValue: any;
  totalapt: any;
  completedapt: any;
  totalsubtraction: any;
  totalreg: any;
  totalfeedback: any;
  uniqueivrs: any;
  ivrs_appointment: any;
  ivrs_noappt: any;
  lastweekRevenue:any;
  previusdayRevenue:any;
  totalRevenue:any;

  getdischarge:any ;
  completed:any;
  planned:any;

  readmit:any
  readmit_today:any;
  readmit_lastweek:any;

  institutional:any;
  institutional_planned:any;
  institutional_compled:any;

  total_mortality:any;
  this_week_mortality:any;
  last_week_mortality:any;
  isShown = false;

  institutional_percentage:any;
  community_percentage:any;
  getVersionArray;
  getVersionArray1: any = [];
  imageUploadUrl;
  vhnimageUploadUrl;
  releaseNotes : any ;
  is_seenUpdate: any;
  current_versionUpdate: any;
  getReleaseNotesData;
  latest_version: any;
  releaseNotesData;

  constructor(private router:Router,private route: ActivatedRoute, pubnub: PubNubAngular, private appService: AppService, public dialog: MatDialog, private _changeDetectorRef: ChangeDetectorRef) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    router.events.subscribe( (event) => ( event instanceof NavigationEnd ) && this.handleRouteChange() )
  }

  handleRouteChange = () => {
     
  };

  
  ngOnInit() {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dr_name = getauthdetails.doctor_name;
    this.dr_degree = getauthdetails.doctor_degree;
    this.hos_name = getauthdetails.hospital_name;
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    this.progressValue = 80;
    this.teleprogressValue = 20;
    this.vaccinprogressValue = 18;
  this.eshopprogressValue = 5;


    this.getDRDasboard();
    this.getVersion();
    this.getReleaseNotes();
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    // this.getRelease();
  }

  getDRDasboard() {
    
        this.setaccount_email = this.dmail;
        this.setdrID = this.doc_id;
        this.setauthToken = this.token;

        this.channel = 'room_'+this.setaccount_email+'_'+this.setdrID;
        this.uuid = this.setaccount_email;
        this.autoRefresh(this.channel)
        this.getdashboardCount();
  }

  autoRefresh(channelName:any)
  {
   this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: this.uuid
  });
    this.pubnub.subscribe({
      channels: [channelName],
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channelName, (msg) => {
      this.msg = msg;
      this.msg = this.msg.message.message;
      this.getdashboardCount();
    });
    
  }

  getdashboardCount() {
  

    this.dashboardParam = {
      account_email: this.setaccount_email,
      doctor_id: this.setdrID,
    }
     
    var params = {
      "account_email":this.setaccount_email
    }
    
    this.appService.getData(this.urlPoints.getdischargedashboard, params, this.setauthToken).subscribe((response: any) => {
        this.getdischarge = response.data

        this.completed = this.getdischarge.community_data.compled[0].count;
        this.community_visit = this.getdischarge.community_data.compled[0].count;
        this.planned = this.getdischarge.community_data.planned[0].count;
       
        this.community_percentage = (parseInt(this.completed)+parseInt(this.planned))/2
        document.getElementById('progress2').style.width = parseInt(this.community_percentage)+'%';
        

        this.readmit = this.getdischarge.today_readmitted[0].count;;
        this.readmit_lastweek = this.getdischarge.last_week_readmitted[0].count;
        this.readmit_today = this.getdischarge.today_readmitted[0].count;
        document.getElementById('progress1').style.width = parseInt(this.readmit_today)+'%';

        this.institutional_planned = this.getdischarge.institutional_data.planned[0].count
        this.institutional_compled = this.getdischarge.institutional_data.compled[0].count
        this.institutional = this.getdischarge.institutional_data.compled[0].count

        this.institutional_percentage = (parseInt(this.institutional_planned)+parseInt(this.institutional_compled))/2
       

        document.getElementById('progress3').style.width = parseInt(this.institutional_percentage)+'%';

        this.total_mortality = this.getdischarge.total_mortality[0].count
        this.this_week_mortality =  this.getdischarge.this_week_mortality[0].count
        this.last_week_mortality =  this.getdischarge.last_week_mortality[0].count
        // document.getElementById('progress4').style.width = parseInt(this.this_week_mortality )+'%';

        if(this.setaccount_email == 'rx3@rxcarenet.org'){
          this.isShown = false
        }else {
          this.isShown = true
        }
        
        

    });

    this.appService.getData(this.urlPoints.drDashboard, this.dashboardParam, this.setauthToken).subscribe((response: any) => {
      this.dashboardData = response.data;
      
      this.totalWaiting = this.dashboardData.total_waiting_in_reception;
      this.max_waiting = this.dashboardData.max_weight_time;
      this.avg_waiting = this.dashboardData.avg_wait_time;
      this.totalTeleConsult = this.dashboardData.total_tele_booked_today;
      this.total_tele = this.dashboardData.total_tele_booked_session;
      this.done_tele = this.dashboardData.total_tele_completed_today;
      this.totalChatCount = this.dashboardData.total_chat_received_today;
      this.received_chat = this.dashboardData.total_chat_replied_today;
      this.unresponed_chat = this.dashboardData.total_chat_unrespond;
      this.totalVaccine = this.dashboardData.total_vacc_booked_today;
      this.booked_vaccine = this.dashboardData.total_vacc_booked_session;
      this.done_vacine = this.dashboardData.total_vacc_completed_session;
      this.totalPatient = this.dashboardData.total_patient_count;
      this.new_patient = this.dashboardData.total_new_patient;
      this.old_patient = this.dashboardData.total_old_patient;
      this.totalapt = this.dashboardData.total_appt_booked;
      this.completedapt = this.dashboardData.total_appt_completed;
      this.totalsubtraction = this.dashboardData.total_subscription;
      this.totalreg = this.dashboardData.total_registration;
      this.totalfeedback = this.dashboardData.total_feedback;
      this.uniqueivrs = this.dashboardData.unique_ivrs_call
      this.ivrs_appointment = this.dashboardData.ivrs_appointment
      this.ivrs_noappt = this.dashboardData.ivrs_noappt;
      this.lastweekRevenue = this.dashboardData.last_week_revenue.toFixed(2);
      this.previusdayRevenue = this.dashboardData.previous_day_revenue.toFixed(2);
      this.totalRevenue = this.dashboardData.total_revenue;
    })

  }
  goToAppointment(){
    this.router.navigate(['epicare/appointment'])
  }

  goToReports(status) {
    localStorage.setItem('flag_status',status);
    this.router.navigate(['epicare/activityReports'])
  }


  getVersion() {

    let data = {
      account_email: this.dmail,
      package_name: 'com.helyxon.epicare',
      status: '0'    }
    this.appService.getData(this.urlPoints.version, data, this.token).subscribe((Response: any) =>{
      console.log(Response);
      console.log(Response.message.image);
      
      this.getVersionArray = Response.message.version
      console.log(this.getVersionArray);
      
      this.getVersionArray1.push({
        app_name: Response.message.app_name,
        package_name: Response.message.package_name,
        release_date: Response.message.release_date,
        version: Response.message.version,
        video: this.vhnimageUploadUrl + Response.message.video,
        image: this.imageUploadUrl + Response.message.image,
        release_notes: Response.message.release_notes
      })
    });

  }

  getReleaseNotes(){

    let data = {
      account_email: this.dmail,
      package_name: 'com.helyxon.epicare',
      current_version: sessionStorage.getItem('version'),
      doctor_id: this.doc_id,
      is_seen: 'False',
      platform: 'Web'
    }

    this.appService.getData(this.urlPoints.getcurrentrelease, data, this.token).subscribe((Response: any) =>{
      console.log(Response);
      this.releaseNotes = Response.message.is_seen;
      console.log(this.releaseNotes);
      
    })
  }

  openPopup() {

    this.releaseNotes = 'True';
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_whatsNewPopup, {
      height: '450px',
      width: '350px',
      data: this.getVersionArray1,
      disableClose: true

    });
    console.log('data:', this.getVersionArray1)

    dialogRef.afterClosed().subscribe(result => {
      // if(result){
      //   this.releaseNotes = true
      // }
      // this.latest_version = 'v2.0.1'
      // this.releaseNotes = 'True'
      // let data = {
      //   account_email: this.dmail,
      //   package_name: 'com.helyxon.epicare',
      //   current_version: this.latest_version,
      //   doctor_id: this.doc_id,
      //   is_seen: this.releaseNotes,
      //   platform: 'Web' 
      // }

      // this.appService.getData(this.urlPoints.getcurrentrelease, data, this.token).subscribe((Response: any) =>{
      //   console.log(Response);
      //   this.getReleaseNotesData = Response
      //   console.log(this.getReleaseNotesData,'getReleaseNotesData');
      // })
    })
  }

  // getRelease(){
  //   let data = {
  //     doctor_id: this.doc_id,
  //     account_email: this.dmail,
  //     package_name: 'com.helyxon.epicare',
  //     is_seen: this.is_seenUpdate,
  //     current_version: this.current_versionUpdate
  //   }
  //   this.appService.getData(this.urlPoints.getcurrentrelease, data, this.token).subscribe((response: any) => {
  //     console.log(response);
  //   })
  // }

}




@Component({
  selector: 'whats-new',
  templateUrl: 'whats-new.html',
  styleUrls: ['whats-new.scss']
})

export class DialogOverviewExampleDialog_whatsNewPopup{
  whatsNewArray:any = [];
  is_seen;
  doc_id;
  dmail;
  current_version;
  urlPoints: any;
  token;
  current_versionUpdate;
  is_seenUpdate;

  constructor (public dialogRef: MatDialogRef<DialogOverviewExampleDialog_whatsNewPopup>, @Inject(MAT_DIALOG_DATA) public data: any, private appService: AppService) {
    this.urlPoints = AppConfig.settings.EndPoints;
  }

  ngOnInit () {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.doc_id = getauthdetails.doctor_id;
    this.dmail =  getauthdetails.account_id;
    this.token = getauthdetails.token;
    this.whatsNewArray = this.data
    console.log(this.data);
    console.log(this.data[0].image);
    console.log(this.whatsNewArray);
    console.log(this.whatsNewArray[0].image);
  }  

  // console.log(this.data,'data');

  
  closePopup() :void {
    this.dialogRef.close();

  }

  changeValue(){
    this.is_seenUpdate = 'True',
    this.current_versionUpdate = this.data[0].version,
    this.getRelease();
    this.dialogRef.close();
    // this.current_version = 
  }

  getRelease(){
    let data = {
      doctor_id: this.doc_id,
      account_email: this.dmail,
      package_name: 'com.helyxon.epicare',
      is_seen: this.is_seenUpdate,
      current_version: this.current_versionUpdate,
      platform: 'web'
    }
    this.appService.getData(this.urlPoints.getcurrentrelease, data, this.token).subscribe((response: any) => {
      console.log(response);
    
    })
  }

  
}
