import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({

  imports: [
    CommonModule,
    FormsModule, // ADDED HERE
    NgxDaterangepickerMd.forRoot()
  ],



  exports: [
    CommonModule,
    FormsModule,
   ReactiveFormsModule,
  ],

  providers: [
    DatePipe,
    DecimalPipe,
  ],

})
export class SharedModule { }
