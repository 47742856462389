<div class="container-fluid" style="margin-top: 10px;">
    
    <div class="hospitalCard">
    <div class="row">
        <div class="col-md-6">
            <h2> <img src="./assets/images/vaccination/vaccination.png" class="imgcustom"> <span class="headerClr"> Vaccination Report</span></h2>
        </div>
    </div>

    <div class="row" style="margin-right: -20px;margin-top:25px;">
        <div class="col-md-8">  
            <div style="display:flex">
            <span style="margin-top: 3px;">Date :</span>
            <input style="margin-left: 10px;" type="text"
            ngxDaterangepickerMd
            [locale]="{format: 'DD-MM-YYYY'}"
            [(ngModel)]="selected"
            [singleDatePicker]="true"
            [alwaysShowCalendars]="true"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="true"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            placeholder="Select Date"/>
        </div>   
        </div>
        <div class="col-md-4">
            <div style="display:flex;float: right;margin-right:10px;margin-top:-15px;">
                <div style="margin-top: 2px;"><h4>Download : </h4></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadXLS()"><div class="addDrTxt">XLS</div></div>
                <div style="margin-left:10px;" class="addDr" (click)="downloadPDF()"><div class="addDrTxt">PDF</div></div>
            </div>
        </div>
    </div>
    <hr />
    <div id="mytable">
    <div class="row">
        <div class="col-md-12">
            <div style="text-align: center;font-weight: bold;"><label><h4>Vaccination Report</h4></label></div>
        </div>
        <div class="col-md-8">
            <label><h5>Report Generated For the period of : {{startdateInFormat}} </h5></label>
        </div>
        <div class="col-md-4">
            <div style="float:right"><label><h5>Report Generated On: {{todayDate}}</h5></label></div>
        </div>   
    </div>
    

    <div class="row" style="margin-top: 10px;">
        <div class="col-md-12">
        <table class="table table-bordered">
            <thead class="text-center">
                <tr>
                    <th rowspan="2">S.No</th>
                    <th rowspan="2">MR Number</th>
                    <th rowspan="2">Child Name</th>       
                    <th rowspan="2">Age</th>
                    <th rowspan="2">Gender</th>
                    <th rowspan="2">DOB</th>
                    <th rowspan="2">Mobile Number</th>       
                    <th rowspan="2">Due Date</th>
                    <th rowspan="2">Vaccination Name</th>
                </tr>
           </thead>

            <tbody class="text-center">
                <tr *ngFor="let collectionRsult of getCollectionRsultList;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{collectionRsult.mrid}}</td>
                        <td>{{collectionRsult.patient}}</td>
                        <td>{{collectionRsult.age}}</td>                   
                        <td>{{collectionRsult.gender}}</td>
                        <td>{{collectionRsult.dob}}</td>
                        <td>{{collectionRsult.mobile}}</td>
                        <td>{{collectionRsult.duedate}}</td>
                        <td>{{collectionRsult.vaccination}}</td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</div>

