import { Injectable } from '@angular/core';


declare var webkitSpeechRecognition: any; 


@Injectable({
  providedIn: 'root'
})

export class VoiceRecognitionService {

  recognition;
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords;
  text1='';
  textArray = [];
  length=0;
  supported;
  listening=false;

  constructor() { 
    
  }
// For advice start
isStoppedVoiceRecog = false;
voiceRecognition =  new webkitSpeechRecognition();
public voiceText = '';
adviceTempWords;

adviceInit(){
  this.voiceRecognition.interimResults = true;
  this.voiceRecognition.lang = 'en-US';
  this.voiceRecognition.addEventListener('result', (e) => {
    const transcript = Array.from(e.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join('');
    this.adviceTempWords = transcript;
    console.log("adviceTempWords:::",transcript);
  });
}

startVoice() {
  this.isStoppedVoiceRecog = false;
  this.voiceRecognition.start();
  console.log("Speech recognition started")
  this.voiceRecognition.addEventListener('end', (condition) => {
    if (this.isStoppedVoiceRecog) {
      this.voiceRecognition.stop();
      console.log("End speech recognition")
    } else {
      this.voiceWordConcat()
      this.voiceRecognition.start();
    }
  });
}

stopVoice() {
  this.voiceText =''
  this.isStoppedVoiceRecog = true;
  this.voiceWordConcat()
  this.voiceRecognition.stop();
  console.log("End speech recognition")
}

voiceWordConcat() {
  console.log("adviceTempWords::::",this.adviceTempWords)
  console.log("voiceText::::",this.voiceText)
  this.voiceText = this.voiceText + ' ' + this.adviceTempWords + ' ';
  this.adviceTempWords = '';
}
// For advice end
  init() {
    this.recognition = new ((window as any).SpeechRecognition || (window as any).webkitSpeechRecognition || (window as any).mozSpeechRecognition || (window as any).msSpeechRecognition)();
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';
    this.recognition.grammars.addFromString('Helexa');

    this.recognition.addEventListener('result', (e) => {
      this.listening=true

      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      this.text1 = transcript
    });
  }

  start() {
    this.isStoppedSpeechRecog = false;
    this.recognition.start();

    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
      } else {
        this.wordConcat()
        this.recognition.start();
      }
    });
  }
  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();
  }

  wordConcat() {
    
    this.listening=false
    if(this.tempWords!='')
    {
      this.textArray.push(this.tempWords.split('.').join(""));
      this.length=this.textArray.length;
    }
    this.tempWords = '';
  }
  
}
