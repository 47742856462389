<!-- <p>{{data.payment_type}}</p> -->

<div *ngIf="data.payment_type==3">

<div class="row row1">
    <p class="col-lg-10 col-md-10  header">Payment Confirmation</p>
    <div *ngIf="data.urlstatus===0" class="col-lg-1 col-md-1 img"><img  (click)="close(0)" src="assets/cancel.svg"></div>
    <div *ngIf="data.urlstatus===1" class="col-lg-1 col-md-1 img"><img  (click)="close(1)" src="assets/cancel.svg"></div>
</div>

<div class="row">
    <p class="col-lg-6 col-md-6">Bill No</p>
    <p class="col-lg-6 col-md-6">{{data.data.bill_no}}</p>
</div>

<div class="row">
    <p class="col-lg-6 col-md-6">Name</p>
    <p class="col-lg-6 col-md-6">{{data.name}}</p>
</div>

<div class="row">
    <p class="col-lg-6 col-md-6">MR Number</p>
    <p class="col-lg-6 col-md-6">{{data.mrn}}</p>
</div>

<div class="row">
    <p class="col-lg-6 col-md-6">Category</p>
    <p class="col-lg-6 col-md-6">{{data.data.category}}</p>
</div>

<div class="row">
    <p class="col-lg-6 col-md-6">Description</p>
    <p class="col-lg-6 col-md-6">{{data.data.description}}</p>
</div>

<table>
    <tr>
        <td>Pre Wallet Balance</td>
        <td>Fees</td>
        <td>Current Wallet Balance</td>
    </tr>

    <tr>
        <td>{{pre}}</td>
        <td>{{data.data.amount}}</td>
        <td>{{data.data.wallet}}</td>
    </tr>
</table>

</div>

<div *ngIf="data.payment_type==4">
    <div class="row row1">
        <p class="col-lg-10 col-md-10  header">Payment Confirmation</p>
        <div *ngIf="data.urlstatus===0" class="col-lg-1 col-md-1 img"><img  (click)="close(0)" src="assets/cancel.svg"></div>
        <div *ngIf="data.urlstatus===1" class="col-lg-1 col-md-1 img"><img  (click)="close(1)" src="assets/cancel.svg"></div>
    </div>

    <div>
        <p>Front desk cash collected successfully</p>
    </div>
</div>