<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Social History</p>
        </div>
    </div>

    <div class="row" style="margin-top: 50px;">
        <div *ngFor="let q of questionData;let i = index" class="col-lg-6 col-md-6 col-sm-6" style="margin-bottom: 10px;">

            <div *ngIf="q.question_type=='radio_button' ">
                <p><span style="font-weight: 600;">Question : </span> <span>{{q.description}}</span></p>

                <p><span style="font-weight: 600;">Answer : </span> <span *ngIf="q.answer==''">NA</span> <span *ngIf="q.answer=='Y'">Yes</span> <span *ngIf="q.answer=='N'">No</span> <span *ngIf="q.answer!='' && q.answer!='Y' && q.answer!='N'">{{q.answer}}</span></p>

            </div>

            <div *ngIf="q.question_type=='text' ">
                <p><span style="font-weight: 600;">Question : </span> <span>{{q.description}}</span></p>

                <p><span style="font-weight: 600;">Answer : </span> <span>{{q.answer}}</span> <span *ngIf="q.answer==''">NA</span> </p>

            </div>

            <div *ngIf="q.question_type=='number' ">
                <p><span style="font-weight: 600;">Question : </span> <span>{{q.description}}</span></p>

                <p><span style="font-weight: 600;">Answer : </span> <span>{{q.answer}}</span> <span *ngIf="q.answer==''">NA</span> </p>

            </div>

            <div *ngIf="q.question_type=='date' ">
                <p><span style="font-weight: 600;">Question : </span> <span>{{q.description}}</span></p>

                <p><span style="font-weight: 600;">Answer : </span> <span>{{q.answer | date : 'dd-MMM-yyyy'}}</span> <span *ngIf="q.answer==''">NA</span> </p>

            </div>
            
            <div *ngIf="q.question_type=='checkbox+number' ">
                <p><span style="font-weight: 600;">Question : </span> <span *ngIf="q.title=='type_of_tobacco_usage'">Type of tobacco usage</span></p>

                <p>
                    <span style="font-weight: 600;">Answer : </span> 

                    <span *ngIf="q.answer.length>0">
                        <span *ngFor="let a of q.answer;let j=index"> {{a}} <span *ngIf="j<q.answer.length-1">,</span> </span> 
                    </span>

                    <span *ngIf="q.answer.length == 0">
                        <span>NA</span> 
                    </span>
                </p>

            </div>

            <div *ngIf="q.question_type=='checkbox ' ">
                <p><span style="font-weight: 600;">Question : </span> <span>{{q.description}}</span></p>

                <p>
                    <span style="font-weight: 600;">Answer : </span> 

                    <span *ngIf="q.answer.length>0">
                        <span *ngFor="let a of q.answer;let j=index"> {{a}} <span *ngIf="j<q.answer.length-1">,</span> </span> 
                    </span>

                    <span *ngIf="q.answer.length == 0">
                        <span>NA</span> 
                    </span>
                </p>

            </div>
        </div>
    </div>
</div>

