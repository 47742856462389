import { Component, OnInit, ElementRef, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-combined-chart-male-small',
  templateUrl: './combined-chart-male-small.component.html',
  styleUrls: ['./combined-chart-male-small.component.scss']
})
export class CombinedChartMaleSmallComponent implements OnInit {


  constructor(private elementRef: ElementRef) {

  }

  @Input() chartData;


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.drawChart(this.chartData)
  }
  

  drawChart(data) {
    var margin = { top: 20, right: 100, bottom: 40, left: 100 };
    var height = 250 - margin.top - margin.bottom;
    var width = 370 - margin.left - margin.right;

    var heightData = [];
    var weightData = [];

    var vitalData: any = data

    if (vitalData.height) {
      for (var i = 0; i < vitalData.height.length; i++) {
        if (vitalData.height[i].x > 59 && vitalData.height[i].x < 217) {
          heightData.push(vitalData.height[i]);
        }
      }
    }

    if (vitalData.weight) {
      for (var i = 0; i < vitalData.weight.length; i++) {
        if (vitalData.weight[i].x > 59 && vitalData.weight[i].x < 217) {
          weightData.push(vitalData.weight[i]);
        }
      }
    }

    var set1 = [{ x: 60, y: 99 }, { x: 66, y: 101.6 }, { x: 72, y: 104.2 }, { x: 78, y: 106.8 }, { x: 84, y: 109.3 }, { x: 90, y: 111.8 },
    { x: 96, y: 114.3 }, { x: 102, y: 116.7 }, { x: 108, y: 119 }, { x: 114, y: 121.3 }, { x: 120, y: 123.6 }, { x: 126, y: 125.9 }, { x: 132, y: 128.2 },
    { x: 138, y: 130.7 }, { x: 144, y: 133.2 }, { x: 150, y: 135.7 }, { x: 156, y: 138.3 }, { x: 162, y: 140.9 }, { x: 168, y: 143.4 }, { x: 174, y: 145.8 },
    { x: 180, y: 148 }, { x: 186, y: 150 }, { x: 192, y: 151.8 }, { x: 198, y: 153.4 }, { x: 204, y: 155 }, { x: 210, y: 156.6 }, { x: 216, y: 158.1 }];

    var set2 = [{ x: 60, y: 102.3 }, { x: 66, y: 105 }, { x: 72, y: 107.7 }, { x: 78, y: 110.4 }, { x: 84, y: 113 }, { x: 90, y: 115.7 },
    { x: 96, y: 118.2 }, { x: 102, y: 120.8 }, { x: 108, y: 123.2 }, { x: 114, y: 125.6 }, { x: 120, y: 128.1 }, { x: 126, y: 130.5 }, { x: 132, y: 133 },
    { x: 138, y: 135.6 }, { x: 144, y: 138.3 }, { x: 150, y: 141 }, { x: 156, y: 143.7 }, { x: 162, y: 146.4 }, { x: 168, y: 149 }, { x: 174, y: 151.5 },
    { x: 180, y: 153.7 }, { x: 186, y: 155.7 }, { x: 192, y: 157.4 }, { x: 198, y: 159.1 }, { x: 204, y: 160.6 }, { x: 210, y: 162.1 }, { x: 216, y: 163.6 }];

    var set3 = [{ x: 60, y: 105.6 }, { x: 66, y: 108.4 }, { x: 72, y: 111.2 }, { x: 78, y: 114 }, { x: 84, y: 116.8 }, { x: 90, y: 119.6 }, { x: 96, y: 122.3 },
    { x: 102, y: 124.9 }, { x: 108, y: 127.5 }, { x: 114, y: 130 }, { x: 120, y: 132.6 }, { x: 126, y: 135.2 }, { x: 132, y: 137.8 }, { x: 138, y: 140.6 },
    { x: 144, y: 143.3 }, { x: 150, y: 146.2 }, { x: 156, y: 149 }, { x: 162, y: 151.8 }, { x: 168, y: 154.5 }, { x: 174, y: 157 },
    { x: 180, y: 159.2 }, { x: 186, y: 161.2 }, { x: 192, y: 162.9 }, { x: 198, y: 164.5 }, { x: 204, y: 165.9 }, { x: 210, y: 167.3 }, { x: 216, y: 168.7 }];

    var set4 = [{ x: 60, y: 108.9 }, { x: 66, y: 111.9 }, { x: 72, y: 114.8 }, { x: 78, y: 117.8 }, { x: 84, y: 120.7 }, { x: 90, y: 123.5 }, { x: 96, y: 126.4 },
    { x: 102, y: 129.1 }, { x: 108, y: 131.8 }, { x: 114, y: 134.5 }, { x: 120, y: 137.2 }, { x: 126, y: 139.9 }, { x: 132, y: 142.7 }, { x: 138, y: 145.5 },
    { x: 144, y: 148.4 }, { x: 150, y: 151.4 }, { x: 156, y: 154.3 }, { x: 162, y: 157.2 }, { x: 168, y: 159.9 }, { x: 174, y: 162.3 },
    { x: 180, y: 164.5 }, { x: 186, y: 166.5 }, { x: 192, y: 168.1 }, { x: 198, y: 169.6 }, { x: 204, y: 171 }, { x: 210, y: 172.3 }, { x: 216, y: 173.6 }];

    var set5 = [{ x: 60, y: 112.4 }, { x: 66, y: 115.4 }, { x: 72, y: 118.5 }, { x: 78, y: 121.6 }, { x: 84, y: 124.6 }, { x: 90, y: 127.6 }, { x: 96, y: 130.5 },
    { x: 102, y: 133.4 }, { x: 108, y: 136.3 }, { x: 114, y: 139.1 }, { x: 120, y: 141.9 }, { x: 126, y: 144.7 }, { x: 132, y: 147.6 }, { x: 138, y: 150.5 },
    { x: 144, y: 153.5 }, { x: 150, y: 156.5 }, { x: 156, y: 159.5 }, { x: 162, y: 162.4 }, { x: 168, y: 165.1 }, { x: 174, y: 167.6 },
    { x: 180, y: 169.7 }, { x: 186, y: 171.6 }, { x: 192, y: 173.1 }, { x: 198, y: 174.5 }, { x: 204, y: 175.8 }, { x: 210, y: 177 }, { x: 216, y: 178.2 }];

    var set6 = [{ x: 60, y: 115.9 }, { x: 66, y: 119 }, { x: 72, y: 122.2 }, { x: 78, y: 125.4 }, { x: 84, y: 128.6 }, { x: 90, y: 131.7 }, { x: 96, y: 134.8 },
    { x: 102, y: 137.8 }, { x: 108, y: 140.7 }, { x: 114, y: 143.7 }, { x: 120, y: 146.6 }, { x: 126, y: 149.5 }, { x: 132, y: 152.5 }, { x: 138, y: 155.6 },
    { x: 144, y: 158.6 }, { x: 150, y: 161.7 }, { x: 156, y: 164.7 }, { x: 162, y: 167.6 }, { x: 168, y: 170.3 }, { x: 174, y: 172.7 },
    { x: 180, y: 174.8 }, { x: 186, y: 176.5 }, { x: 192, y: 178 }, { x: 198, y: 179.3 }, { x: 204, y: 180.4 }, { x: 210, y: 181.5 }, { x: 216, y: 182.5 }];

    var set7 = [{ x: 60, y: 119.4 }, { x: 66, y: 122.7 }, { x: 72, y: 126 }, { x: 78, y: 129.3 }, { x: 84, y: 132.6 }, { x: 90, y: 135.9 }, { x: 96, y: 139.1 },
    { x: 102, y: 142.2 }, { x: 108, y: 145.3 }, { x: 114, y: 148.3 }, { x: 120, y: 151.4 }, { x: 126, y: 154.4 }, { x: 132, y: 157.5 }, { x: 138, y: 160.6 },
    { x: 144, y: 163.7 }, { x: 150, y: 166.8 }, { x: 156, y: 169.9 }, { x: 162, y: 172.7 }, { x: 168, y: 175.4 }, { x: 174, y: 177.7 },
    { x: 180, y: 179.7 }, { x: 186, y: 181.4 }, { x: 192, y: 182.7 }, { x: 198, y: 183.8 }, { x: 204, y: 184.8 }, { x: 210, y: 185.8 }, { x: 216, y: 186.7 }];

    var set8 = [{ x: 60, y: 13.2 }, { x: 66, y: 13.8 }, { x: 72, y: 14.5 }, { x: 78, y: 15.3 }, { x: 84, y: 16 }, { x: 90, y: 16.7 }, { x: 96, y: 17.5 },
    { x: 102, y: 18.3 }, { x: 108, y: 19.1 }, { x: 114, y: 19.9 }, { x: 120, y: 20.7 }, { x: 126, y: 21.6 }, { x: 132, y: 22.6 }, { x: 138, y: 23.8 },
    { x: 144, y: 24.9 }, { x: 150, y: 26.1 }, { x: 156, y: 27.5 }, { x: 162, y: 29 }, { x: 168, y: 30.7 }, { x: 174, y: 32.6 },
    { x: 180, y: 34.5 }, { x: 186, y: 36.1 }, { x: 192, y: 37.5 }, { x: 198, y: 38.7 }, { x: 204, y: 39.8 }, { x: 210, y: 40.8 }, { x: 216, y: 41.8 }];

    var set9 = [{ x: 60, y: 14.3 }, { x: 66, y: 15 }, { x: 72, y: 15.8 }, { x: 78, y: 16.8 }, { x: 84, y: 17.6 }, { x: 90, y: 18.5 }, { x: 96, y: 19.5 },
    { x: 102, y: 20.5 }, { x: 108, y: 21.5 }, { x: 114, y: 22.4 }, { x: 120, y: 23.5 }, { x: 126, y: 24.6 }, { x: 132, y: 25.9 }, { x: 138, y: 27.3 },
    { x: 144, y: 28.7 }, { x: 150, y: 30.2 }, { x: 156, y: 31.8 }, { x: 162, y: 33.6 }, { x: 168, y: 35.5 }, { x: 174, y: 37.7 },
    { x: 180, y: 39.8 }, { x: 186, y: 41.6 }, { x: 192, y: 43.1 }, { x: 198, y: 44.4 }, { x: 204, y: 45.6 }, { x: 210, y: 46.7 }, { x: 216, y: 47.7 }];

    var set10 = [{ x: 60, y: 15.6 }, { x: 66, y: 16.5 }, { x: 72, y: 17.4 }, { x: 78, y: 18.6 }, { x: 84, y: 19.6 }, { x: 90, y: 20.7 }, { x: 96, y: 21.9 },
    { x: 102, y: 23.2 }, { x: 108, y: 24.3 }, { x: 114, y: 25.6 }, { x: 120, y: 26.9 }, { x: 126, y: 28.3 }, { x: 132, y: 29.8 }, { x: 138, y: 31.6 },
    { x: 144, y: 33.3 }, { x: 150, y: 35.1 }, { x: 156, y: 37 }, { x: 162, y: 39.1 }, { x: 168, y: 41.3 }, { x: 174, y: 43.7 },
    { x: 180, y: 45.9 }, { x: 186, y: 47.9 }, { x: 192, y: 49.5 }, { x: 198, y: 50.9 }, { x: 204, y: 52.1 }, { x: 210, y: 53.2 }, { x: 216, y: 54.3 }];

    var set11 = [{ x: 60, y: 17.1 }, { x: 66, y: 18.2 }, { x: 72, y: 19.3 }, { x: 78, y: 20.7 }, { x: 84, y: 21.9 }, { x: 90, y: 23.3 }, { x: 96, y: 24.8 },
    { x: 102, y: 26.4 }, { x: 108, y: 27.9 }, { x: 114, y: 29.4 }, { x: 120, y: 31.1 }, { x: 126, y: 32.8 }, { x: 132, y: 34.7 }, { x: 138, y: 36.9 },
    { x: 144, y: 39 }, { x: 150, y: 41.2 }, { x: 156, y: 43.3 }, { x: 162, y: 45.7 }, { x: 168, y: 48.2 }, { x: 174, y: 50.8 },
    { x: 180, y: 53.1 }, { x: 186, y: 55.2 }, { x: 192, y: 56.8 }, { x: 198, y: 58.2 }, { x: 204, y: 59.5 }, { x: 210, y: 60.6 }, { x: 216, y: 61.6 }];

    var set12 = [{ x: 60, y: 19 }, { x: 66, y: 20.3 }, { x: 72, y: 21.7 }, { x: 78, y: 23.3 }, { x: 84, y: 24.9 }, { x: 90, y: 26.6 }, { x: 96, y: 28.5 },
    { x: 102, y: 30.5 }, { x: 108, y: 32.3 }, { x: 114, y: 34.3 }, { x: 120, y: 36.3 }, { x: 126, y: 38.5 }, { x: 132, y: 40.9 }, { x: 138, y: 43.5 },
    { x: 144, y: 46 }, { x: 150, y: 48.6 }, { x: 156, y: 51.1 }, { x: 162, y: 53.8 }, { x: 168, y: 56.4 }, { x: 174, y: 59.1 },
    { x: 180, y: 61.6 }, { x: 186, y: 63.6 }, { x: 192, y: 65.2 }, { x: 198, y: 66.6 }, { x: 204, y: 67.8 }, { x: 210, y: 68.7 }, { x: 216, y: 69.7 }];

    var set13 = [{ x: 60, y: 21.3 }, { x: 66, y: 22.9 }, { x: 72, y: 24.6 }, { x: 78, y: 26.6 }, { x: 84, y: 28.6 }, { x: 90, y: 30.8 }, { x: 96, y: 33.2 },
    { x: 102, y: 35.7 }, { x: 108, y: 38 }, { x: 114, y: 40.5 }, { x: 120, y: 43 }, { x: 126, y: 45.8 }, { x: 132, y: 48.7 }, { x: 138, y: 51.8 },
    { x: 144, y: 54.8 }, { x: 150, y: 57.8 }, { x: 156, y: 60.7 }, { x: 162, y: 63.6 }, { x: 168, y: 66.3 }, { x: 174, y: 69.1 },
    { x: 180, y: 71.5 }, { x: 186, y: 73.4 }, { x: 192, y: 74.8 }, { x: 198, y: 76.1 }, { x: 204, y: 77.1 }, { x: 210, y: 77.8 }, { x: 216, y: 78.6 }];

    var set14 = [{ x: 60, y: 24.2 }, { x: 66, y: 26.1 }, { x: 72, y: 28.3 }, { x: 78, y: 30.8 }, { x: 84, y: 33.4 }, { x: 90, y: 36.2 }, { x: 96, y: 39.4 },
    { x: 102, y: 42.6 }, { x: 108, y: 45.5 }, { x: 114, y: 48.6 }, { x: 120, y: 51.8 }, { x: 126, y: 55.2 }, { x: 132, y: 58.7 }, { x: 138, y: 62.5 },
    { x: 144, y: 66.1 }, { x: 150, y: 69.5 }, { x: 156, y: 72.6 }, { x: 162, y: 75.6 }, { x: 168, y: 78.3 }, { x: 174, y: 80.9 },
    { x: 180, y: 83.1 }, { x: 186, y: 84.7 }, { x: 192, y: 85.8 }, { x: 198, y: 86.8 }, { x: 204, y: 87.5 }, { x: 210, y: 88 }, { x: 216, y: 88.4 }];
    
    var rawSvg = this.elementRef.nativeElement.querySelector('svg');

    var svg = d3.select(rawSvg)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    var wrapheight = d3.select(rawSvg).selectAll('d.d3line').data([heightData]);
    var wrapweight = d3.select(rawSvg).selectAll('d.d3line').data([weightData]);
    var gEnterheight = wrapheight.enter().append('g').attr('class', 'd3line').append('g');
    var gEnterweight = wrapweight.enter().append('g').attr('class', 'd3line').append('g');

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var lineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveCardinal);

    var datalineFunc: any = d3.line()
      .x(function (d: any) {
        return xScale0(d.x);
      })
      .y(function (d: any) {
        return yScale0(d.y);
      })
      .curve(d3.curveLinear);

    var g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var xVal = [5, 5.6, 6, 6.6, 7, 7.6, 8, 8.6, 9, 9.6, 10, 10.6, 11, 11.6, 12, 12.6, 13, 13.6, 14, 14.6, 15, 15.6, 16, 16.6, 17, 17.6, 18];

    var xScale0 = d3.scaleLinear().domain([216, 60]).range([width, 0]);
    var xScale1 = d3.scaleLinear().domain([216, 60]).range([width, 0]);

    var yScale0 = d3.scaleLinear().domain([0, 190]).range([height, 0]);
    var yScale1 = d3.scaleLinear().domain([0, 190]).range([height, 0]);

    var xAxisBottom = d3.axisBottom(xScale1).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d: any) {
      return d / 12 % 1 ? '' : (d / 12) as any;
    }).tickSizeInner(-height).tickSizeOuter(0);
    var xAxisTop = d3.axisTop(xScale0).ticks(27).tickValues(d3.range(60, 217, 6)).tickFormat(function (d) {
      return '';
    }).tickSizeInner(-height).tickSizeOuter(0);
    var yAxisLeft = d3.axisLeft(yScale1).ticks(26).tickValues(d3.range(0, 190, 20)).tickFormat(function (d: any) {
      return d % 20 == 0 ? d : '';
    }).tickSizeInner(-width).tickSizeOuter(0);
    var yAxisRight = d3.axisRight(yScale0).ticks(26).tickValues(d3.range(0, 190, 20)).tickFormat(function (d) {
      return '';
    }).tickSizeInner(-width).tickSizeOuter(0);

    var age = function (data) {
      var mon = data / 12;
      var result = mon.toFixed(1).split('.');
      var mod = data % 12;
      var res = mod.toString().split('.');
      return result[0].toString() + " years ";
    };

    svg.selectAll("circle.heightData")
      .data(heightData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'green').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + " cm"});

    svg.selectAll("circle.weightData")
      .data(weightData)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale0(d.x);
      })
      .attr("cy", function (d) {
        return yScale0(d.y);
      })
      .attr("r", function (d) {
        return 5;
      })
      .attr('fill', 'red').append("title").text(function (d) { return age(d.x) + "\n" + (d.y).toString() + "Kg"});

    svg.append("g").attr("opacity","1")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisBottom);

    svg.append("g").attr("opacity","1")
      .attr("class", "x axis")
      .attr("transform", "translate(0,0)")
      .call(xAxisTop);

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + 30)
      .style("text-anchor", "middle")
      .text("Age in Years");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -7)
      .style("text-anchor", "middle")
      .text("IAP Boys Height & Weight Chart");

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height)
      .attr("transform", "translate(-970,1250) rotate(-90)")
      .style("text-anchor", "middle")
      .text("WEIGHT(Kg)");

    svg.append("text")
      .attr("x", 300)
      .attr("y", height - 10)
      .attr("transform", "rotate(90,575,1150)")
      .style("text-anchor", "middle")
      .text("WEIGHT(Kg)");
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height)
      .attr("transform", "translate(-970,450) rotate(-90)")
      .style("text-anchor", "middle")
      .text("STATURE(CM)");

    svg.append("text")
      .attr("x", -500)
      .attr("y", height - 10)
      .attr("transform", "rotate(90,575,1150)")
      .style("text-anchor", "middle")
      .text("STATURE(CM)");

    svg.append("text")
      .attr("x", 690)
      .attr("y", 740)
      .style("text-anchor", "start").style("font-size", "10px").style("font-size", "10px")

      .text("3");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 710)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("10");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 680)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("25");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 640)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("50");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 600)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("75");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 555)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("90");

    svg.append("text")
      .attr("x", 680)
      .attr("y", 500)
      .style("text-anchor", "start").style("font-size", "10px")
      .text("97");

    svg.append("g").attr("opacity","1")
      .attr("class", "y axis")
      .call(yAxisLeft);

    svg.append("g").attr("opacity","1")
      .attr("class", "y axis")
      .attr("transform", "translate(" + width + " ,0)")
      .call(yAxisRight);


    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set1),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);

    svg.append("path")
      .datum(set1)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set2),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set2)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set3),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set3)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set4),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set4)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set5),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set5)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set6),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set6)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set7),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set7)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set8),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set8)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set9),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set9)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set10),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set10)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set11),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set11)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set12),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);

    svg.append("path")
      .datum(set12)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set13),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(set13)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: lineFunc(set14),
    //     "stroke": "black",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);

    svg.append("path")
      .datum(set14)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "2");
    // user data
    // svg.append("svg:path")
    //   .attr({
    //     d: datalineFunc(heightData),
    //     "stroke": "green",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);

    svg.append("path")
      .datum(heightData)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "green")
      .style("stroke-width", "2");

    // svg.append("svg:path")
    //   .attr({
    //     d: datalineFunc(weightData),
    //     "stroke": "red",
    //     "stroke-width": 2,
    //     "fill": "none",
    //     "class": "path"
    //   } as any);
    svg.append("path")
      .datum(weightData)
      .attr("class", "path")
      .attr("d", lineFunc as any)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");
  }
}
