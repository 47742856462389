import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, timer, BehaviorSubject, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFileUploaderComponent } from 'angular-file-uploader';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";


@Injectable({
  providedIn: 'root',
})
export class AppService {
  queueLists;
  fromQR;
  fromQRData;
  private subjectName = new Subject<any>();
  private subjectName0 = new Subject<any>();
  // private cartItemsSubject = new Subject<any[]>();
  private cartItemsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient, private router: Router) { 
    this.startTrackingInactivity();
   }

cartItems$=this.cartItemsSubject.asObservable();
private userActivitySubject = new Subject<void>();

timer = 1000 * 60 * 15
private activityTimer$ = timer(0, 1000 * 60);
private userActivity$ = fromEvent(document, 'mousemove').pipe(debounceTime(this.timer));

startTrackingInactivity() {
  this.userActivity$.subscribe(() => {
    console.log("Inactive user:::")
    const currentUrl = this.router.url;
    const urlParts = currentUrl.split('/');
    const lastPart = urlParts.includes("appointment");
    let status = sessionStorage.getItem('consultation')
    console.log("status>>>",status);
    if ( status=='false' && currentUrl!='/') {
      this.logout();
    }
    this.userActivitySubject.next();
  });

  this.activityTimer$.subscribe(() => {
    console.log("Active user:::")
    this.userActivitySubject.next();
  });
}
logout(){
  alert('Your session had expired. Please login.')
  this.router.navigate(['']);
  sessionStorage.clear();
  localStorage.clear();
  window.onpopstate = function (e) { window.history.forward(); }
  window.location.reload();
}
addToCartItems(item:any[]){
  this.cartItemsSubject.next(item);
}

  // httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     }),
  //   };

  sendObsteddData(message: any) {
    this.subjectName.next({ text: message });
  }

  getObsteddData(): Observable<any> {
    return this.subjectName.asObservable();
  }

  sendCpRiskData(message: any) {
    this.subjectName0.next({ text: message });
  }

  getCpRiskData(): Observable<any> {
    return this.subjectName0.asObservable();
  }
  getDataVitalData(url: string, params): Observable<any> {
    return this.http.get('https://ivital.co' + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      params,
    });
  }
  getData(url: string, params, token): Observable<any> {
    return this.http.get(environment.HOST + environment.PORT + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      params,
    });
  }

  getDataVital(url: string, params, token): Observable<any> {
    return this.http.get('https://ivital.co/ivital/api/v1.5/web_login.php', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      params,
    });
  }


  getDicom(): Observable<any> 
  {
    // alert()
    return this.http.get("http://pacs.epicare.net:8042/instances/2cdb7e60-5f614281-f7caa86e-29a54af0-42684d4e/file", {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        Accept: "application/dicom+json",
        Authorization: "Basic ZWNkaWNvbTohQGI6cyxla0okVmgpTTJD",
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      
      // params,
    });
  }

  getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  getSample() {
    return this.http.get("https://api.epicare.net:443/wrapper/service/getconsultationcompliant?account_email=rx4@rxcarenet.org&page_no=1&alphabet=S");
  }

  getQueue(url: string, token): Observable<any> {
    return this.http.get(environment.HOST + environment.PORT + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      //    params,
    });
  }

  getRestobj(url: string, params, pagecount: any, token?: string): Observable<any> {

    return this.http.get(environment.HOST + environment.PORT + url + '?' + params + '/' + pagecount, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      }),
    });
  }

  getRestobjaccess(url: string, params, token?: string): Observable<any> {

    return this.http.get(environment.HOST + environment.PORT + url + params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      }),
    });
  }

  getGraph(url: string, params): Observable<any> {
    return this.http.get(environment.HOST + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      params,
    });
  }

  getTags(url: string, params): Observable<any> {
    return this.http.get(environment.HOST + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
        //Authorization: 'b2F1dGhfdG9rZW49UEZneXZ3M1FueXRra2I4cUVmOWkmb2F1dGhfdG9rZW5fc2VjcmV0PTBnZnJ5MFhYTmRCT2E5MzluYXMzJmFjY291bnRfaWQ9cngxNSU0MHJ4Y2FyZW5ldC5vcmc=',
      }),
      params,
    });
  }

  postData(url: string, body: any, token?: string): Observable<any> {
    return this.http.post(environment.HOST + environment.PORT + url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }

  putData(url: string, body: any, token?: string): Observable<any> {
    return this.http.put(environment.HOST + environment.PORT + url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }

  postDataIvital(url: string, body: any, token?: string): Observable<any> {
    return this.http.post("https://ivital.co"+ url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      })
    });
  }


  postLogin(url: string, body: any): Observable<any> {
    return this.http.post(environment.HOST + environment.PORT + url, JSON.stringify(body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    });
  }

  postimgData(url: string, body: any, token?: string): Observable<any> {
    var data = new FormData();
    data.append('photo', body);

    return this.http.post(environment.HOST + environment.imgurl, data, {
      reportProgress: true,
      observe: 'events',
    });

  }

  postwithoutPortImgFile(url: string, imgfile: any, token?: string): Observable<any> {
    var data = new FormData();
    data.append('photo', imgfile);
    return this.http.post(environment.HOST + url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  postwithoutPortPDFFile(url: string, imgfile: any, token?: string): Observable<any> {
    var data = new FormData();
    data.append('pdf', imgfile);
    return this.http.post(environment.HOST + url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  postwithoutPortImgbase64(url: string, imgfile: any, token?: string): Observable<any> {
    return this.http.post(environment.HOST + url, imgfile, {
      reportProgress: true,
      observe: 'events'
    });
  }

  postimgData2(url: string, body: any, token: string, type): Observable<any> {
    var data = new FormData();
    data.append(type == 1 ? 'photo' : 'pdf', body);

    return this.http.post(environment.HOST + url, data, {
      reportProgress: true,
      observe: 'events',
    });

  }

  jsonToExcel(jsonData: any[], fileName: any) {
    // const blob = new Blob([this.jsonToCsv(jsonData)], { type: 'text/csv;charset=utf-8;' });
    // saveAs(blob, fileName);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  private jsonToCsv(jsonData: any[]): any {
    const csvData = [];
    const headers = Object.keys(jsonData[0]);
    csvData.push(headers.join(','));

    // jsonData.forEach((item) => {
    //   const values = headers.map((header) => item[header]);
    //   csvData.push(values.join(','));
    // });

    return csvData.join('\n');
  }

}