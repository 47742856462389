<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>Follow Up</p>


    <div style="display: flex;">

        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
            (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;cursor: pointer;" class="fa fa-close" (click)="close()"></i>

    </div>


</div>

<div>
    <p style="color: #7D7D7D;font-size: 0.9rem;">When to come Again / Revisit?</p>

</div>

<div style="display: flex;margin-top: 0.5rem;">
    <p style="cursor:pointer;font-size: 1rem;margin-top:0.5rem"  [class.hide]="!sos" [class.checked]="sos" (click)="changeSOS()">SOS <img
            class="icon" style="margin-left: 0.5rem;" [src]="sos?'assets/provtrue.svg':'assets/provfalse.svg'"></p>

    <button style="border: 1px solid #7D7D7D;margin-left: 1rem;" (click)="setType(1)" [class.btn-primary]="type==1"
        class="btn">Enter Manually <img style="margin-left: 0.5rem;height: 1rem;" *ngIf="type==1"
            src="assets/feather-check.svg"></button>

    <button style="border: 1px solid #7D7D7D;margin-left: 1rem;" (click)="setType(2)" [class.btn-primary]="type==2"
        class="btn">Choose Date <img style="margin-left: 0.5rem;width: 1rem;" *ngIf="type==2"
            src="assets/feather-check.svg"></button>

</div>

<hr style="color: #7D7D7D;margin-top: 1rem;">


<div style="margin-top: 1rem;" *ngIf="type==1">
    <div style="display: flex;">
        <input style="border: 1px solid #7D7D7D;" (input)="changeDate()" [(ngModel)]="d">

        <button style="border: 1px solid #7D7D7D;margin-left: 0.5rem;" (click)="setUnit(1)"
            [class.btn-primary]="unit==1" class="btn">Days</button>

        <button style="border: 1px solid #7D7D7D;margin-left: 0.5rem;" (click)="setUnit(2)"
            [class.btn-primary]="unit==2" class="btn">Weeks</button>

        <button style="border: 1px solid #7D7D7D;margin-left: 0.5rem;" (click)="setUnit(3)"
            [class.btn-primary]="unit==3" class="btn">Months</button>

    </div>

    <div class="followbtns followbtns1" style="display: flex;justify-content: space-between;margin-top: 1rem;">
        <button class="btn btn-primary" (click)="add_d(1)">
            1
        </button>

        <button class="btn btn-primary" (click)="add_d(2)">
            2
        </button>

        <button class="btn btn-primary" (click)="add_d(3)">
            3
        </button>

        <button class="btn btn-primary" (click)="add_d(4)">
            4
        </button>

        <button class="btn btn-primary" (click)="add_d(5)">
            5
        </button>

        <button class="btn btn-primary" (click)="add_d(6)">
            6
        </button>

        <button class="btn btn-primary" (click)="add_d(7)">
            7
        </button>

        <button class="btn btn-primary" (click)="add_d(8)">
            8
        </button>

        <button class="btn btn-primary" (click)="add_d(9)">
            9
        </button>

        <button class="btn btn-primary" (click)="add_d(0)">
            0
        </button>

        <button class="btn btn-primary" style="background-color:  #415577;" (click)="backspace()">
            Backspace
        </button>
    </div>
</div>

<div style="margin-top: 1rem;display: flex;" *ngIf="type==2">
    <!-- <mat-form-field>
        <input readonly matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" placeholder="Choose Alternate Date" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
      </mat-form-field> -->

    <p style="display: flex;font-size: 1rem;align-self: center;">Date</p>

    <div style="display: flex;border: 1px solid #7D7D7D;padding: 0.5rem;width: fit-content;margin-left:0.5rem">


        <p style="font-size: 1rem;">{{selectedDate | date : 'dd-MM-yyyy'}}</p>

        <img style="height: 1rem;width: 1rem;margin-left: 0.5rem;cursor: pointer;height:1rem;width:1rem"
            (click)="picker.open()" src="assets/metro-calendar.svg">

        <mat-form-field style="width:0px !important;visibility:hidden;height: 0px;">
            <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)"
                [min]="minDate">
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>


</div>

<div *ngIf="(type==1 || type==2)" style="margin-top: 1rem;">

    <p>To fix appointment for follow up date, please select from options</p>

    <div style="display: flex;flex-wrap: wrap;">

        <div *ngIf="consultation" style="margin-right: 0.5rem;">
            <input style="cursor:pointer;margin-right: 0.5rem;" id="consultation" name="group2" class="radio2"
                type="radio" [checked]="event=='consultation'" (click)="setConsultationType('consultation')">
            <label style="margin-right: 0.5rem;" for="consultation">Consultation</label>
        </div>

        <div *ngIf="consultation" style="margin-right: 0.5rem;">
            <input style="cursor:pointer;margin-right: 0.5rem;" id="vaccination" name="group2"
                (click)="setConsultationType('vaccination')" class="radio2" type="radio"
                [checked]="event=='vaccination'">
            <label style="margin-right: 0.5rem;" for="vaccination">Vaccination</label>
        </div>

        <div *ngIf="voice_call" style="margin-right: 0.5rem;">
            <input style="cursor:pointer;margin-right: 0.5rem;" id="voice_call" name="group2"
                (click)="setConsultationType('voice_call')" class="radio2" type="radio" [checked]="event=='voice_call'">
            <label style="margin-right: 0.5rem;" for="voice_call">Voice
                Call</label>
        </div>

        <div *ngIf="video_call" style="margin-right: 0.5rem;">
            <input style="cursor:pointer;margin-right: 0.5rem;" id="video_call" name="group2"
                (click)="setConsultationType('video_call')" class="radio2" type="radio" [checked]="event=='video_call'">
            <label style="margin-right: 0.5rem;" for="video_call">Video
                Call</label>
        </div>

    </div>

</div>