
<div class="closeIcon" style="margin: 0;top: -21px;right: -15px;float: right;position: relative;">
  <i class="fa fa-times" aria-hidden="true" (click)="close()"></i>
</div>
<div class="container">

  
<p *ngIf="!isProgress">LOADING...</p>
<!-- <div *ngIf="closeScan && showDetails === 'showScan'" class="camera-container" style="width: 250px; height: auto;"> -->
<div class="camera-container" style="width: 250px; height: auto;">
  <div class="scanner-shell" [hidden]="!hasDevices">
<zxing-scanner [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
  [formats]="formatsEnabled"(permissionResponse)="onHasPermission($event)"
  (camerasFound)="onCamerasFound($event)"></zxing-scanner>
</div>
</div>
<div class="footerSection" style="top: 10px;">
<!-- <button mat-raised-button class="btn scan" (click)="scanBtn()">
    {{closeScan?'Off':'Scan'}}
  </button> -->
<!-- &nbsp;&nbsp;<button mat-raised-button class="btn" (click)="clearResult()">Delete Result</button>
&nbsp;&nbsp;<span class="value">MR Number: {{qrResultString||'Not Available'}}</span> -->
</div>
</div>