<br><br>
<div class="row">
    <div class="col-lg-1">

    </div>
    <div class="col-lg-10" >
        <div class="row">
            <div class="col-lg-12">
                <span  style="color: #347F9E;font-weight: 600;font-size: medium;font-family: Poppins;">{{patienthistory.item}}</span>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-lg-10">
                <div class="row" style="font-size: 14px;">
                    <div class="col-lg-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" style="cursor: pointer;" type="radio"
                                name="inlineRadioOptions" id="inlineRadio1" value="Yes" [checked]="milestoneoption == 'Yes'" [(ngModel)]="milestoneoption">
                            <label class="form-check-label" style="padding-left: 4px;cursor: pointer;font-family: 'Poppins', sans-serif;"
                                for="inlineRadio1">Yes</label>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" style="cursor: pointer;" type="radio"
                                name="inlineRadioOptions" id="inlineRadio2" value="Sometimes" [checked]="milestoneoption == 'Sometimes'" [(ngModel)]="milestoneoption">
                            <label class="form-check-label" style="padding-left: 4px;cursor: pointer;font-family: 'Poppins', sans-serif;"
                                for="inlineRadio2">Sometimes</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" style="cursor: pointer;" type="radio"
                                name="inlineRadioOptions" id="inlineRadio3" value="Not Yet" [checked]="milestoneoption == 'Not Yet'" [(ngModel)]="milestoneoption">
                            <label class="form-check-label" style="padding-left: 4px;cursor: pointer;font-family: 'Poppins', sans-serif;"
                                for="inlineRadio3">Not
                                yet</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div><br>

</div>

<br><br>
<div class="row">
    <div class="col-lg-7">
        
    </div>
    <div class="col-lg-2">
        <button class="btn btn-default" (click)="closepopuppicture()" style="float: right;font-size: 14px;">Cancel</button>
    </div>
    <div class="col-lg-2">
        <button class="btn btn-primary" (click)="savestatus()" style="float: right;font-size: 14px;">Save</button>
    </div>
    
    <div class="col-lg-1"></div>
</div>
