<div style="height: 27.5rem;width: 30rem;overflow: hidden;">
    <div class="slider-wrapper" style="width: 5rem;height: 5rem;">
        <div id="slider1">
        </div>
    
        <div>
            <img style="width: 25rem;height:25rem;transform: rotate(67.5deg);border-radius: 50%;margin-left: 2.5rem;"
            [class.oral]="slider_value==0" [class.inhale]="slider_value==45"
            [class.subcutaneous]="slider_value==90" [class.intravenous]="slider_value==135"
            [class.perrectal]="slider_value==180" [class.topical]="slider_value==225"
            [class.pervaginal]="slider_value==270" [class.other]="slider_value==315"

                [src]="imgSrc">
        </div>
    
        <div class='container'>
            <span style="display: flex;flex-direction: column;cursor: pointer;" (click)="changeSliderValue(45)">
                <div style="display: flex;justify-content: center;"> <img [src]="slider_value==45?'assets/route2.svg':' assets/inhalator.svg'">
                </div>
                <span [class.bold]="slider_value==45">Inhale</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(90)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==90?'assets/route3.svg':' assets/intramascular_&_subcutaneous.svg'">
                </div>
    
                <span [class.bold]="slider_value==90">Subcutaneous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(135)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==135?'assets/route4.svg':' assets/patient.svg'">

                </div>
    
                <span  [class.bold]="slider_value==135">Intravenous</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(180)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==180?'assets/route5.svg':'assets/Butt.svg'">

                </div>
    
                <span  [class.bold]="slider_value==180">Perrectal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(225)">
                <div style="display: flex;justify-content: center;">
                     <!-- <img src="assets/cream.svg"> -->
                     <img [src]="slider_value==225?'assets/route6.svg':' assets/cream.svg'">

                </div>
    
                <span  [class.bold]="slider_value==225">Topical</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(270)">
                <div style="display: flex;justify-content: center;">
                     <img [src]="slider_value==270?'assets/route7.svg':' assets/vagina.svg'">

                </div>
    
                <span [class.bold]="slider_value==270">Pervaginal</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(315)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==315?'assets/route8.svg':' assets/menu.svg'">

                </div>
    
                <span [class.bold]="slider_value==315">Other</span>
            </span>
            <span style="display: flex;flex-direction: column;cursor: pointer"  (click)="changeSliderValue(0)">
                <div style="display: flex;justify-content: center;"> 
                    <img [src]="slider_value==0?'assets/route1.svg':' assets/mouth.svg'">

                </div>
    
                <span [class.bold]="slider_value==0">Oral</span>
            </span>
        </div>
    </div>
</div>

<div style="display: flex;justify-content: space-around; justify-content: space-around;position: relative">
    <button (click)="close()" class="btn cancel">Cancel</button>
    <button (click)="ok()" class="btn ok">OK</button>
</div>