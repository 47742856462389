<div>
    <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;float:right;cursor :pointer;" class="fa fa-close" (click)="cancel()"></i>

</div>

<!-- {{data.block}}

{{record}} -->

<form [formGroup]="nameForm" (submit)="save()">
    <!-- (input)="filter($event.target.value)" placeholder="Add Manually"> -->
<!-- <button class="btn btn-primary" type="submit">Save</button> -->
<p>{{data.text}}:</p> 

<div>
    <input type="text" formControlName="name">
    <button class="btn btn-primary" type="submit">Save</button>
    <!-- <button class="btn btn-primary" type="reset" (click)="cancel()">Cancel</button> -->
</div>

<div *ngIf="data.block=='sign' || data.block=='diag' || data.block=='inv' || data.block=='adv' || data.block =='cc' ">
    <mat-checkbox [(ngModel)]="record"  (change)="recordToggle()">
    <p style="color: #000;margin:0">Add to records</p>
</mat-checkbox>
</div>
<p *ngIf="(nameForm.controls.name.dirty || submitted)&&nameForm.controls.name.errors?.required">Name required</p>
</form>